
._GB_viplist {
    position: relative;
    top: 35px;
    @media only screen and (max-width: 1024px){
      top: 0;
    }
    .rvip_wrap {
        border: 1px solid rgba(59, 77, 94, 0.5);
        border-top-left-radius: 18px;
        padding: 50px 30px;
        border-bottom: none;
        opacity: var(--Corner-radius-xs, 1);

        @media only screen and (max-width: 767px) {
          padding: 16px 15px 50px;
        }
        @media only screen and (max-width: 1024px) {
          border: none;
        }
        
        @media only screen and (min-width: 1366px){
            padding: 50px 0;
        }
        // background-image: url("../images/blastbanner.png");
        
      .rvip_fullSection {

        .rvip_title_section
        {
            .title
            {
                    color: #FFF;
                    font-family: "Gilroy-Medium";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    margin-bottom: 23px;
                    @media only screen and (max-width: 767px){
                      font-size: 20px;
                      line-height: 24px;
                    }
            }
        }

      }

      .rvip_topSection {
        justify-content: space-between;
        margin-bottom: 30px;
        @media only screen and (max-width: 1200px) {
          flex-direction:column;
          gap:40px;
        }

        @media only screen and (max-width: 767px) {
          margin-bottom: 0px;
          gap:0px;
        }
        .rvip_search_section 
        {
          @media only screen and (max-width: 1200px) {
          width: 100%;
          }

          @media only screen and (max-width: 767px) {
              order:2;
          }
            .rvip_top_inner{
                width: 100%;
                max-width: 648px;
                // justify-content: space-between;

                .rvip-head-text
                {
                    color: #FFF;
                    font-family: "Gilroy-Medium";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 30px; /* 187.5% */
                    @media only screen and (max-width: 767px) {
                      margin-bottom: 10px;
                      font-size: 18px;
                    }
                }

                .rvip_tag{

                    color: var(--Background---grey-Grey---300---GB, #5D7893);
                    font-family: "Gilroy-Medium";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; /* 185.714% */

                    @media only screen and (max-width: 767px) {
                      font-size: 14px;
                      line-height: 21px;
                    }
                }

                .rvip-searchbar
                {
                    margin-top: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    opacity: var(--Corner-radius-xs, 1);
                    background: var(--Background---grey-Grey---500---GB, #181F26);
                    border-radius: 24px !important;


                    @media only screen and (max-width: 767px) {
                      margin-bottom: 32px;
                    }


                    .rvip-searchbar-form-wrap
                    {
                        flex: 0 0 75%;
                        max-width: 75%;
                        @media only screen and (max-width: 767px) {
                          flex: 0 0 71%;
                          max-width: 71%;
                        }


                        .rvip-searchbar-form-inner
                        {
        
                            border-radius: var(--Spacing-2xl, 24px);
                            opacity: var(--Corner-radius-xs, 1);
                            background: var(--Background---grey-Grey---500---GB, #181F26);
                            height: 76px;
                            border-radius: 24px !important;
                            display: flex;
                            padding: 15px 20px;
                            position: relative;
                            align-items: center;

                            @media only screen and (max-width: 767px) {
                              padding: 15px 3px;
                              height: 48px;
                            }

                            .mf-searchbar-form
                            {
                             
                                opacity: var(--Corner-radius-xs, 1);
                                background: var(--Background---grey-Grey---500---GB, #181F26);
                                border: none;
                                box-shadow: none;
                                outline: none;
                                border-radius: 10px !important;
                                height: 100%;
                                color: var(--Color-Neutral-400, #9296A1);
                                font-family: "Gilroy-Medium";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px; /* 133.333% */

                                @media only screen and (max-width: 767px) {
                                  font-size: 12px;
                                }
                                @media only screen and (max-width: 350px) {
                                  padding: 6px;
                                }
                               
                            }

                            .mf-close-img {
                                padding: 8px;
                                cursor: pointer;
                                height:32px;
                            }

                            .my-search-list {
                                padding: 10px;
                                position: absolute;
                                top: 56px;
                                left: 0px;
                                background: #000;
                                width: 100%;
                                border: solid 1px;
                                border-radius: 10px !important;
                                z-index: 99999;

                                .my-search-list-items-wrap {
                                    height: 300px;
                                    overflow-y: scroll;
                                    &::-webkit-scrollbar {
                                        width: 3px;
                                      }
                                    
                                      /* Track */
                                      &::-webkit-scrollbar-track {
                                        background: #181818;
                                      }
                                    
                                      /* Handle */
                                      &::-webkit-scrollbar-thumb {
                                        background: #e52420;
                                      }
                                    
                                      /* Handle on hover */
                                      &::-webkit-scrollbar-thumb:hover {
                                        background: #e52420;
                                      }
                                    .my-search-list-items {
                                        display: flex;
                                        align-items: center;
                                        gap: 15px;
                                        padding: 10px;
                                            cursor: pointer;
                                        .my-search-list-img  {
                                          img {
                                              border-radius: 25px;
                                              width: 40px;
                                              height: 40px;
                                              object-fit: cover;
                                            }
                                          }
                                        .my-search-list-name {
                                            color:#fff;
                                            font-family: "Gilroy-Bold";
                                            font-size: 16px;
                                          }
                                      }
                                      .nousermessage {
                                        color: #fff;
                                        /* text-align: center; */
                                        padding: 10px;
                                      }
                                  }
                            }
        
                        }


                    }
                }

              
                .gb-white-btn
                {
                    text-transform: capitalize;
                    padding: 13px 8px;
                    text-decoration: none;
                    letter-spacing: 0.04em;
                    display: inline-block;
                    margin-right: 10px;
                    flex: 0 0 19%;
                    max-width: 123px; 
                    border-radius: var(--Spacing-2xl, 24px);
                    border: 1px solid rgba(78, 255, 97, 0.40);
                    background: var(--Primary-Green---50---GB, #F1FEEC);
                    color: var(--Background---grey-Grey---950---GB, #020303);
                    font-family: "Gilroy-Medium";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 32px; /* 200% */

                    @media only screen and (max-width: 767px) {
                      padding: 5px 8px;
                      font-size: 12px;
                      flex: 0 0 27%;
                      border-radius: 16px;
                      line-height: 24px;
                    }
                    @media only screen and (max-width: 360px) {

                    flex: 0 0 22%;
                    min-width: 79px;
                    }

                    &:hover 
                    {
                      opacity: 0.5;
                      color: #020303;
                  
                    }
                }


             


            }
        }


        .rvip_invites_section {
          @media only screen and (max-width: 1200px) {
            width: 100%;
            }
            @media only screen and (max-width: 767px) {
              order:1;
          }
            .aff_invites {
                // margin-right: 30px;
                // margin-left: 30px;
                    box-sizing: border-box;
                    padding: 20px 30px;
                    border-radius: var(--Spacing-2xl, 24px);
                    border: 1px solid rgba(241, 254, 236, 0.30);
                    opacity: var(--Corner-radius-xs, 1);
                    background: var(--Background---grey-Grey---500---GB, #181F26);
                    min-height: 150px;
                @media only screen and (max-width: 1365px) {
                  padding: 20px 20px;
                }
        
                @media only screen and (max-width: 767px) {
                  // flex: 0 0 100%;
                  max-width: 100%;
                  margin-bottom: 21px;
                  margin-left: 0;
                  margin-right: 0;
                  padding: 8px 11.5px;
                  min-height: auto;
                }

                .aff_top_inner {
                    text-align: right;
                    

                    p {
                        color: var(--Background---grey-Grey---300---GB, #5D7893);
                        font-family: "Gilroy-Medium";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        margin-bottom: 0px;

                        @media only screen and (max-width: 767px) {
                          font-size: 12px;
                        }
                    }

                    .aff_bold{
                        color: #FFF;
                        font-family: "Gilroy-Regular";
                        font-size: 26px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 40px; /* 153.846% */

                        @media only screen and (max-width: 767px) {
                          font-size: 24px;
                          line-height: 26px; 
                        }
                    }
                }

                .aff_bottom_inner {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 16px;

                    .aff_text_inner {

                        display: flex;
                        justify-content: space-between;

                        @media only screen and (max-width: 1534px) {
                          flex-direction: column;
                        }
                    }
                    .mf-head-text-invites {
                        color: #FFF;
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;

                        @media only screen and (max-width: 767px) {
                        width: 100px;
                        line-height: 16px;
                        }

                        @media only screen  and (min-width: 1200px) and (max-width: 1560px) {
                          width: 100px;
                        }
                    }

                    .mf-text-green {
                        cursor: pointer;
                        opacity: var(--Corner-radius-xs, 1);
                        color: var(--Primary-Green---500---GB, #74F33B);
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        padding-left: 4px;
                        @media only screen and (max-width: 1365px) {
                          padding-left: 0px;
                        }

                    }

                    .rvip_avatar
                    {
                    
                    
                            transform: rotateY(180deg);
                        
                        img {
                          margin-right: -7px;
                          height: 40px;
                          width: 40px;
                          min-width: 40px;
                          border-radius: 50%;
                          border: 1.5px solid #FFF;
                          opacity: var(--Corner-radius-xs, 1);
                          background: lightgray 50% / cover no-repeat, var(--Color-Primary-color-500, #00D2A0);
                          object-fit: cover;
                          object-position: top;
                  
                          @media only screen  and (min-width: 1200px) and (max-width: 1599px) {
                          height: 27px;
                          width: 27px;
                          min-width: 27px;
                          }
                          @media only screen and (max-width: 767px) {
                          height: 30px;
                          width: 30px;
                          min-width: 30px;
                          }
                        }
                    }
                }
              }
        
          
            
        }

        

      }
       
      .rvip_bottomSection {
      ._affiliate_table{
        margin-top: 64px;
         @media only screen and (max-width: 767px) {
         margin-top: 0px;
         }
        .table_head{
            color: var(--Background---grey-Grey---300---GB, #5D7893);
            /* Subtitle/Subtitle 30/$Subtitle 30-medium */
            font-family: "Gilroy-Medium";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px; /* 162.5% */
            margin-bottom: 16px;
        }
        #score_Table{
            border-bottom: none !important;
            // white-space: nowrap;
            thead{
              @media only screen and (max-width: 767px) {
                // display: none;
              }
                tr{
                    th{
                        background-color: #141A1F;
                        font-size: 14px;
                        line-height: 24px;
                        font-family: 'Inter-Medium';
                        color: #51E20E;
                        border: none !important;
                        font-weight: 500;
                        padding: 11px 12px;
                        text-transform: capitalize;

                        &:first-child{
                            border-top-left-radius: 18px;
                            // text-align: center;
                        }
                        &:last-child{
                            border-top-right-radius: 18px;
                        }
                        img{
                            margin-left: 10px;
                        }

                        @media only screen and (max-width: 767px){
                          display: table-cell;
                          text-align: -webkit-match-parent;
                          min-width:150px;
                          font-size: 14px;
                           }

                           &.gb-first-col {
                            @media only screen and (max-width: 767px){
                            min-width:150px;
                            }
                        }
                        &.gb-sec-col {
                            @media only screen and (max-width: 767px){
                            min-width:250px;
                            }
                        }
                        &.gb-third-col {
                            @media only screen and (max-width: 767px){
                            min-width:150px;
                            }
                            
                          }
                    }
                }
            }
            tbody{
                tr{
                    border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
                    td{
                        color: #DBFFDF;
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        line-height: 24px;
                        text-align: left;
                        height: 45px;
                        vertical-align: middle;
                        padding: 9px 13px;

                       
                        @media only screen and (max-width: 767px) {
                          height: auto;
                          border: none;
                        }
                        @media only screen and (max-width: 479px) {
                          padding: 10px 10px;
                        }
                        .table-cont {
          
                          @media only screen and (max-width: 479px) {
                            padding-top: 30px;
                            font-size: 12px;
                          }


                        }
                        
                       .playerInfo
                       {


                        .profile-info{

                         
                          @media only screen and (max-width: 767px) {
                            font-size: 12px;
                          }
                   
                            
                            img{
                              border: none;
                              border-radius: 100%;
                              box-sizing: border-box;
                              height: 32px;
                              margin-right: 10px;
                              object-fit: cover;
                              width: 32px;
                          
                            }
                      
                        }

                      }
                        &.red_unfollow{
                          
                            font-family: "Gilroy-Medium";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px; /* 150% */
                            

                            @media only screen and (max-width: 767px) {
                              font-size: 14px;
                            }

                            .unfollowfriend
                            {
                              
                              color: var(--Color-Error-500, #FF3C35);
                              text-decoration-line: underline;
                            }
                        }

                        span {
                          .aff-img {
                            margin-right: 10px ;
                            margin-bottom: 10px;
                       
                        }
                        }

                        // &:before {
                        //   @media only screen and (max-width: 767px) {
                        //     content: attr(data-label);
                        //     font-weight: bold;
                        //     text-transform: uppercase;
                        //     top: 1px;
                        //     color: #51E20E;
                        //     font-size:14px;;
                        //   }
                      
                        //   @media only screen and (max-width: 479px) {
                        //     position: absolute;
                        //   }
                        // }

                        &.GB_device_td{
                          @media only screen and (max-width: 767px)
                          {
                          // align-items: center;
                          display: table-cell;
                          // justify-content: space-between;
                          // padding-left: 10px !important;
                          // padding-right: 10px !important;
                          // position: relative;
                          }

                        }
                      
                    }
                    .playerDetails{
                      white-space: nowrap;
                    }
                }
            }  
        }
    }

  }
   
      
     
    }
    


}

