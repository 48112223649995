._gbAlertWrap {
  padding: 20px;
  position: fixed;
  top: 50px;
  right: 30px;
  z-index: 99999999999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: var(--Spacing-2xl, 24px);
  border: 1.5px solid rgba(45, 198, 86, 0.3);
  background: linear-gradient(272deg, rgba(45, 198, 86, 0.32) 1.53%, rgba(45, 198, 86, 0.03) 46.47%), #141A1F;
  box-shadow: 0px 56px 112px 0px rgba(45, 198, 86, 0.08), 0px 2px 4px 0px rgba(45, 198, 86, 0.04);
  backdrop-filter: blur(24px);
  width: 375px;
  padding: 12px 12px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 767px) {
    top: 0;
    right: 0;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    width: 100%;
  }

  ._gbAlertStatus {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    cursor: pointer;
    ._gbAlertIcon {
      border: 2px solid rgba(45, 198, 86, 0.3);
      background: linear-gradient(180deg, rgba(45, 198, 86, 0) 0%, rgba(45, 198, 86, 0.12) 100%);
      display: flex;
      max-width: 48px;
      width: 100%;
      height: 48px;
      justify-content: center;
      align-items: center;
      border-radius: var(--Spacing-xl, 16px);

    }



    ._gbAlertMessage {
      color: var(--Colors-Success-Surface-Default, #2DC656);
      font-family: "Gilroy-SemiBold";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }



  &.failure {
    border: 1.5px solid rgba(255, 60, 53, 0.60) !important;
    background: linear-gradient(90deg, rgba(255, 60, 53, 0.10) 0.1%, rgba(255, 60, 53, 0.00) 99.91%) !important;

    ._gbAlertIcon {
      border-radius: var(--Spacing-2xl, 24px);
      border: 1.5px solid rgba(255, 60, 53, 0.60) !important;

      background: linear-gradient(90deg, rgba(255, 60, 53, 0.10) 0.1%, rgba(255, 60, 53, 0.00) 99.91%) !important;

      /* Blur/xs - b:64 */
      backdrop-filter: blur(32px);

      img {
        width: 30px;
      }
    }

    ._gbAlertMessage {
      color: #fff;
    }
  }

  ._gbAlertAction {
    cursor: pointer;
  }
}

.rpagination-full-section {
  margin: 40px 0px;

  @media only screen and (max-width: 767px) {
    margin: 20px 0px;
  }

  .paginate {
    margin: 0px 13px;
    background: transparent;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 4px 4px;
    justify-content: center;
    align-items: center;

    ul {
      margin-bottom: 0px;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;

      li {
        list-style-type: none;
        margin-right: 10px;

        @media only screen and (max-width: 767px) {
          display: none !important;
        }

        a {
          font-family: "Gilroy-Medium";
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #92A6BA;
          background-color: transparent;
          border: none;

          &:focus {
            box-shadow: none;
          }

          @media only screen and (max-width: 767px) {
            line-height: 33px;
          }
        }

        &.previous {
          position: absolute;
          left: -35px;

          @media only screen and (max-width: 767px) {
            display: block !important;
          }
          a {
            background: url(../../images/redo/pagination_arrowL_white.png) 0px 0px no-repeat;
            height: 25px;
            width: 50px;
        }
        &.disabled{
            a {
                background: url(../../images/redo/pagination_arrowL_dark.png) 0px 0px no-repeat;
                height: 25px;
                width: 50px;
            }

        }
        }

        &.next {
          position: absolute;
          right: -61px;

          @media only screen and (max-width: 767px) {
            display: block !important;
          }

      
          a {
            background: url(../../images/redo/pagination_arrowR_white.png) 0px 0px no-repeat;
            height: 25px;
            width: 50px;
          }
        &.disabled{
            a {
                background: url(../../images/redo/pagination_arrowR_dark.png) 0px 0px no-repeat;
                height: 25px;
                width: 50px;
            }

        }
        }

        &.active {
          // background-image: url("../../images/redo/mob_activebg.png");
          // background-repeat: no-repeat;
          height: 40px;
          width: 40px;
          // vertical-align: middle;
          align-items: center;
          justify-content: center;
          display: flex;
          filter: drop-shadow(0px 7px 18px #74F33B);
          animation: pulseAnimation 2s infinite;
          border-radius: 50%;

          a {
            color: #DBFFDF !important;
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        &:first-child {
          margin-right: 0px;
        }

        &:nth-last-child(2) {
          margin-right: 0px;
        }
      }
    }
  }
}


.redo_lb_mob_table {
  display: block;
  color: #DBFFDF;

  ._each_lb {
    background: #141A1F;
    border-radius: 15px;
    padding: 10px;
    margin: 15px 0;
    color: #fff;

    ._each_lb_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      .table-cont {
        .GbplayerCol {
          .challenge_failed {
            width: 24px;
          }

          .usermatchdetailswrap {
            .trx_red_desc{
              color: #c80000 !important;
              font-family: 'Inter-Medium';
              font-size: 12px;
              line-height: 12px;
            }
            .trx_normal_desc {
              font-size: 12px;
              line-height: 12px;
              font-family: 'Inter-Medium';
            }
          }
        }
      }

      ._each_lb_left {
        flex: 0 0 60%;
        max-width: 60%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 5px 0;

        ._rankBox {
          color: #fff;
          align-items: center;
          border-radius: 10px;
          display: flex;
          font-family: "Gilroy-Bold";
          font-size: 18px;
          height: 32px;
          justify-content: center;
          line-height: 100%;
          text-align: center;
          width: 32px;
        }

        ._redBox {
          background: #74F33B;
        }

        ._goldBox {
          background: #fda829;
        }

        ._silverBox {
          background: #444444;
        }

        ._bronzeBox {
          background: #d99246;
        }

        ._proimgBox img {
          border: 2px solid #fff;
          border-radius: 100%;
          box-sizing: border-box;
          height: 45px;
          object-fit: cover;
          width: 45px;

          @media only screen and (max-width: 767px) {
            height: 35px;
            width: 40px;
          }
        }
      }

      ._each_lb_right {
        flex: 0 0 40%;
        max-width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        ._streamBox img {
          width: 25px;
        }

        ._flagBox {
          img {
            width: 45px;
          }
        }
      }
    }

    ._each_lb_score {
      background: #0E1216;
      border-radius: 8px;
      color: #fff;
      text-transform: uppercase;

      ._each_score_left {
        color: #74F33B;
        font-size: 14px;
        line-height: 24px;
        font-family: 'Inter-Medium';
      }

      ._each_score_right {
        font-size: 14px;
        line-height: 24px;
        font-family: 'Inter-Regular';

        .view_ticket-img {
          .bh_ticketimg {
            margin-right: 0px !important;
          }
        }
      }

      ._each_lb_scoreline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #3B4D5E;
        padding: 8px 15px;

        &._each_lb_assists {
          border: none;
        }
      }
    }

    .nodata_inlist {
      color: #DBFFDF;
    }
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.redo_lb_web_table {
  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.dashAjaxLoader {
  font-size: 18px;
  line-height: 24px;
  color: #5D7893;
  font-family: "Gilroy-Bold";
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  height: 300px;

  &.bgIt {
    padding: var(--Spacing-md, 50px);
    align-items: center;
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.5);
    background: var(--Background---grey-Grey---500---GB, #181f26);
  }

  &.sidebar {
    height: 0px !important
  }
}


// Retheme Wallet -----------Start------------------//
.redo-currency-wallet-main {
  #dropdown-redo-curr-wallet {
    background: none;
    padding: 0;
    border: none;
    box-shadow: none;

    &::after {
      display: none;
    }
  }

  .redo-curr-wallet-dropdown {

    width: 398px;
    border: 1px solid rgba(59, 77, 94, 0.5);
    background: var(--Background---grey-Grey---500---GB, #101418);

    .redo-curr-wallet-wrap {
      .redo-curr-wallet-option {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        .redo-curr-wallet-btn {
          border-radius: var(--Spacing-xl, 20px);
          // border: 1.5px solid rgba(255, 255, 255, 0.1);
          border: none;
          border: 1.5px solid #181F26;
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---500---GB, #181F26);
          padding: 15px 20px;
          flex: 0 0 48%;
          max-width: 48%;
          font-size: 14px;
          line-height: 24px;
          font-family: "Gilroy-Medium";
          color: #fff;
          @media only screen and (max-width: 1110px){
            padding: 15px 10px;
          }
          &.active,
          &:hover {
            background-color: #181F26;
            border: 1.5px solid rgba(255, 255, 255, 0.1);
            background-image: linear-gradient(360deg, #1a3016, #181F26), radial-gradient(46.09% 46.09% at 50% 92.19%, rgba(116, 243, 59, 0.2) 0%, rgba(116, 243, 59, 0) 100%), linear-gradient(274.8deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.16) 99.76%);
          }
        }
      }

      .redo-curr-wallet-balance {
        // background-image: url("../../images/redo/graph_img.png");
        // background-repeat: no-repeat;
        // background-position: right;
        padding: 20px;
        margin: 15px 0;

        .balanceSec {
          color: #5D7893;
          font-size: 13px;
          line-height: 18px;
          font-family: "Gilroy-Medium";
          margin-bottom: 10px;

          span {
            font-family: "Gilroy-Bold";
          }
        }

        .balanceAmount {
          color: #FFF;
          font-family: "Gilroy-SemiBold";
          font-size: 32px;
          font-style: normal;
          line-height: 48px;
          cursor: pointer;

          // @media only screen and (max-width: 1400px){
          //     font-size: 35px;
          //     line-height: 43px;
          // } 
          img {
            margin-left: 12px;
          }
        }

        .convertAmount {
          margin-top: 16px;

          .amountInSolano {
            color: #FFF;
            font-family: "Gilroy-Medium";
            font-size: 12px;
            line-height: 22px;

          }

          .upOrdown {
            span {
              color: #B2F35F;
              font-family: "Gilroy-Medium";
              font-size: 12px;
              line-height: 22px;
            }
          }
        }
      }

      .redo-curr-wallet-grids {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        color: #fff;
        justify-content: space-between;
        padding: 5px;
        margin: 15px 0;

        .curr-wallet-links-items {
          border: solid 1px;
          padding: 10px;
          height: 100px;
          display: flex !important;
          flex-direction: column;
          justify-content: center !important;
          gap: 10px;
          flex: 0 0 30%;
          max-width: 30%;
          cursor: pointer;
          border-radius: var(--Spacing-xl, 20px);
          border: 1.5px solid rgba(255, 255, 255, 0.1);
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---500---GB, #181F26);

          .item-text {
            color: #fff;
            font-size: 14px;
            line-height: 24px;
            font-family: "Gilroy-Medium";
          }

        }

      }

      .redo-curr-wallet-connect {
        margin-bottom: 5px;

        .wallet-adapter-dropdown {
          background: none !important;
          margin-bottom: 0 !important;
          padding: 0 !important;
          border-radius: var(--Spacing-xl, 20px);
          // border: 1.5px solid #3b4d5e;
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---500---GB, #181F26);

          .wallet-adapter-button-trigger {
            border-radius: var(--Spacing-xl, 20px);
            border: 1.5px solid #3b4d5e;
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---500---GB, #181F26) !important;
            margin-bottom: 5px;
          }
        }

        .wallet-adapter-button-trigger {
          border-radius: var(--Spacing-xl, 20px);
          border: 1.5px solid #3b4d5e;
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---500---GB, #181F26) !important;
          margin-bottom: 10px;
        }

        .item-text {
          color: #fff;
          font-size: 14px;
          line-height: 24px;
          font-family: "Gilroy-Medium" !important;
          font-weight: normal !important;
        }


        .rd-wallet-adapter-dropdown-list {
          border-radius: var(--Spacing-xl, 20px);
          border: 1.5px solid #3b4d5e;
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---500---GB, #181F26);
        }

      }

      .redo-curr-wallet-coins {
        .redo-curr-wallet-coins-inner {
          .redo-curr-wallet-coins-items {
            background: #181F26;
            padding: 12px 20px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: var(--Spacing-xl, 20px);
            border: 1.5px solid #3b4d5e;
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---500---GB, #181F26);

            .item-token-type {
              display: flex;
              align-items: center;
              gap: 10px;

              .coin-img {
                width: 24px;
                display: flex;
                align-items: center;
              }

              .coin-name {
                color: #fff;
                font-size: 14px;
                line-height: 24px;
                font-family: "Gilroy-Medium";
                display: flex;
                align-items: center;

                &.fetch_text {
                  color: #5D7893;
                  font-size: 15px;
                  line-height: 18px;
                  font-family: "Gilroy-Medium";
                }
              }
            }

            .item-token-amount {
              display: flex;
              align-items: center;
              gap: 10px;

              .coin-amount {
                color: #fff;
                font-size: 14px;
                line-height: 24px;
                font-family: "Gilroy-Medium";
                text-transform: uppercase;
                display: flex;
                align-items: center;
              }

              .coin-sel {
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }
          }
          &.needScroll{
            height: 180px;
            overflow-y: scroll;
                padding: 0 8px 0 3px;
            &::-webkit-scrollbar {
                width: 3px;
              }
            
              /* Track */
              &::-webkit-scrollbar-track {
                background: #181F26;
                border-radius: 9px;
              }
            
              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #74F33B;
                border-radius: 9px;
              }
            
              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #74F33B;
              }
          }
        }
      }
    }
  }


}


.rd-wallet-popup {
  .modal-dialog {
    .modal-content {
      border: 2px solid #454545 !important;
      border-radius: 24px;
      background: #181818;
      padding: 50px 40px;
      border: 1px solid rgba(59, 77, 94, 0.5) !important;
      background: var(--Background---grey-Grey---500---GB, #101418);
      border-radius: 24px;

      .modal-header {
        border: none !important;

        .btn-close {
          box-shadow: none;
          outline: none;
          background: url("../../images/redo/bet-close-circle.png") no-repeat;
          opacity: 1;
        }
      }


      .rd-wallet-content {

        .rd-wallet-content-title {
          font-size: 18px;
          line-height: 24px;
          color: #fff;
          font-family: "Gilroy-SemiBold";
          padding: 0 5px;
        }

        .rd-wallet-content-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          color: #fff;
          justify-content: space-between;
          padding: 5px;
          margin: 15px 0;

          .rd-wallet-content-grid-item {
            padding: 10px;
            height: 100px;
            display: flex !important;
            flex-direction: column;
            justify-content: center !important;
            align-items: center;
            text-align: center;
            gap: 10px;
            flex: 0 0 31%;
            max-width: 31%;
            cursor: pointer;
            border-radius: var(--Spacing-xl, 20px);
            border: 1.5px solid rgba(255, 255, 255, 0.1);
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---500---GB, #181F26);

            .rd-wallet-content-grid-item-text {
              color: #5D7893;

              font-size: 14px;
              line-height: 24px;
              font-family: "Gilroy-Medium";
            }

            .wal-grey {
              display: block;
            }

            .wal-green {
              display: none;
            }

            &.active {
              border: 3px solid rgba(26, 46, 22, 0.8);

              .wal-grey {
                display: none;
              }

              .wal-green {
                display: block;
              }

              .rd-wallet-content-grid-item-text {
                color: #fff;
              }
            }
          }
        }

        .gb_loader {
          border-radius: var(--Spacing-xl, 20px);
          border: 1.5px solid rgba(255, 255, 255, 0.1);
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---500---GB, #181F26);
          padding: 30px 25px;

          .gb_loader_text {
            color: #5D7893;
            font-size: 14px;
            line-height: 24px;
            font-family: "Gilroy-Medium";
          }
        }

        .swap_loader {
          margin-top: 15px;
        }

        .rd-wallet-content-withdraw {
          padding: 5px;

          .rd-wallet-content-withdraw-inner {
            display: flex;
            flex-direction: column;
            border-radius: var(--Spacing-xl, 20px);
            border: 1.5px solid rgba(255, 255, 255, 0.1);
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---500---GB, #181F26);
            padding: 15px 20px;

            .rd-wallet-content-withdraw-head {
              display: flex;
              justify-content: space-between;
              align-items: center;
              color: #5D7893;

              font-size: 14px;
              line-height: 24px;
              font-family: "Gilroy-Medium";
            }

            .rd-wallet-content-withdraw-input-wrap {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 10px;

              .rd-wallet-select {
                flex: 0 0 25%;
                max-width: 25%;

                #withdraw-dropdown {
                  display: flex;
                  align-items: center;
                  background: none;
                  border: none;
                  padding: 0;
                  gap: 10px;

                  &::after {
                    border-top: 0.5em solid;
                    border-right: 0.5em solid transparent;
                    border-left: 0.5em solid transparent;
                  }
                }

                .rd-wallet-item-token {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  font-family: "Gilroy-SemiBold";
                  font-size: 16px;
                  line-height: 24px;
                }

                .dropdown-item {
                  padding: 10px;
                  font-size: 12px;
                  line-height: 24px;
                  font-family: "Gilroy-SemiBold";
                  color: #fff !important;
                }

                .rd-wallet-item-token-list {
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  font-family: "Gilroy-SemiBold";
                  font-size: 16px;
                  line-height: 24px;
                }


                .dropdown-menu {
                  border-radius: var(--Spacing-xl, 20px);
                  border: 1.5px solid rgba(255, 255, 255, 0.1);
                  opacity: var(--Corner-radius-xs, 1);
                  background: var(--Background---grey-Grey---500---GB, #181F26);

                  .dropdown-item {
                    &:hover {
                      background: #3B4D5E;
                    }
                  }
                }

              }

              .rd-wallet-send-btn {
                padding: 10px 15px;
                border-left: 2.5px solid rgba(255, 255, 255, 0.1);

                .rd-withdraw-transfer-sendall {
                  display: flex;
                  gap: 10px;

                  button {
                    background: #3B4D5E;
                    border: none;
                    padding: 8px 15px;
                    border-radius: 16px;
                    color: #fff;
                    font-family: "Gilroy-SemiBold";
                  }
                }
              }

              .rd-wallet-input {
                input {
                  background: none;
                  border: none;
                  color: #fff;
                  box-shadow: none;
                  outline: none;
                  text-align: right;
                  color: #fff;
                  font-family: "Gilroy-SemiBold";
                  font-size: 18px;
                }
              }
            }
          }

          .withdraw-deposit-swap {
            margin: 10px 0;
            align-items: end;

            .fromto-swap-inner {
              background: none;
              margin: 0;
              padding: 12px 0;

              .fromto-swap-head {
                font-family: "Gilroy-SemiBold";
                color: #fff;
              }

              #fromto-dropdown {
                border-radius: 20px;
                border: 1.5px solid #181818;
                padding: 15px;
                background: var(--Background---grey-Grey---500---GB, #181F26);

                .coin-name {
                  font-family: "Gilroy-SemiBold";
                }
              }
            }

            .fromto-select {
              input {
                color: #5D7893;
                font-size: 14px;
                line-height: 24px;
                font-family: "Gilroy-Medium";
                background: none;
              }
            }

            .from-to-sep {
              margin-bottom: 22px;

              img {
                border-radius: var(--Spacing-xl, 20px);
                border: 1px solid rgba(255, 255, 255, 0.1);
                opacity: var(--Corner-radius-xs, 1);
                background: var(--Background---grey-Grey---500---GB, #181F26);
                padding: 15px;
              }
            }
          }
        }

        .rd-paypal-disclaimer {
          color: #FFBF00;
          font-size: 12px;
          line-height: 16px;
          font-family: "Gilroy-Medium";
          padding: 0 40px 15px 40px;
          text-align: center;
        }

        .rd-wallet-content-action {

          .rd-wallet-content-action-btn {
            border-radius: var(--Spacing-2xl, 24px);
            background-color: #F1FEEC;
            color: #0E1216;
            width: 100%;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 15px;
            line-height: 24px;
            font-family: "Gilroy-SemiBold";
            border: none;

            &::after {
              content: "";
              background-image: url("../../images/redo/arrow-right.png");
              background-repeat: no-repeat;
              height: 26px;
              width: 32px;
              margin-left: 3px;
            }

            &:hover {
              &::after {
                content: "";
                background-image: url("../../images/redo/arrow-right.png");
                background-repeat: no-repeat;
                height: 26px;
                width: 32px;
              }
            }

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }

            .text-dark {
              color: #181f26 !important;
              margin-right: 5px !important;
            }

          }
        }

        .rd-wallet-content-action-title {
          font-size: 24px;
          line-height: 30px;
          color: #fff;
          font-family: "Gilroy-SemiBold";
          text-align: center;
          padding: 15px;
        }

        .rd-wallet-content-wait {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 30px;
          padding: 30px 50px;
          text-align: center;
          height: 350px;

          .wait_head {
            color: #fff;
            font-size: 18px;
            line-height: 24px;
            font-family: "Gilroy-Medium";
          }

          .wait_text {
            color: #92A6BA;
            font-size: 16px;
            line-height: 24px;
            font-family: "Gilroy-Medium";
          }

          .wait_image {
            background-repeat: no-repeat;
            background-position: center;
            &.swapping-img{
              background-image: url("../../images/redo/wallet/rd-wall-swapping-svg.svg") !important;

            }
            &.wall-wait-img{
              background-image: url("../../images/redo/wallet/rd-wall-wait-svg.svg") !important;

            }
            &.warning-img{
              background-image: url("../../images/redo/wallet/rd-wall-warning-svg.svg") !important;

            }
            &.error-img{
              background-image: url("../../images/redo/wallet/rd-wall-error-svg.svg") !important;

            }
            &.success-img{
              background-image: url("../../images/redo/wallet/rd-wall-success-svg.svg") !important;
            }
            .rotate {
               animation: rotate 90s linear infinite;
            }
          }
        }

        .rd-swap-type-buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
          margin: 20px 0;

          .rd-swap-type-btn {
            border-radius: var(--Spacing-xl, 20px);
            border: none;
            border: 1.5px solid #181F26;
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---500---GB, #181F26);
            padding: 15px 20px;
            flex: 0 0 48%;
            max-width: 48%;
            font-size: 14px;
            line-height: 24px;
            font-family: "Gilroy-Medium";
            color: #fff;

            &.active,
            &:hover {
              background-color: #181F26;
              border: 1.5px solid rgba(255, 255, 255, 0.1);
              background-image: linear-gradient(360deg, #1a3016, #181F26), radial-gradient(46.09% 46.09% at 50% 92.19%, rgba(116, 243, 59, 0.2) 0%, rgba(116, 243, 59, 0) 100%), linear-gradient(274.8deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.16) 99.76%);
            }
          }
        }

        .rd-swap-trx-wrap {
          display: flex;
          flex-direction: column;
          border-radius: var(--Spacing-xl, 20px);
          border: 1.5px solid rgba(255, 255, 255, 0.1);
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---500---GB, #181F26);
          padding: 15px 20px;
          margin: 15px 0;

          .rd-wallet-content-swap-head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #5D7893;

            font-size: 14px;
            line-height: 24px;
            font-family: "Gilroy-Medium";
          }

          .rd-wallet-content-swap-input-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .rd-wallet-select {
              flex: 0 0 25%;
              max-width: 25%;

              #swap-dropdown {
                display: flex;
                align-items: center;
                background: none;
                border: none;
                padding: 0;
                gap: 10px;

                &::after {
                  border-top: 0.5em solid;
                  border-right: 0.5em solid transparent;
                  border-left: 0.5em solid transparent;
                }
              }

              .rd-wallet-item-token {
                display: flex;
                align-items: center;
                gap: 5px;
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 24px;
              }

              .dropdown-item {
                padding: 10px;
                font-size: 12px;
                line-height: 24px;
                font-family: "Gilroy-SemiBold";
                color: #fff !important;
              }

              .rd-wallet-item-token-list {
                display: flex;
                align-items: center;
                gap: 10px;
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 24px;
              }


              .dropdown-menu {
                border-radius: var(--Spacing-xl, 20px);
                border: 1.5px solid rgba(255, 255, 255, 0.1);
                opacity: var(--Corner-radius-xs, 1);
                background: var(--Background---grey-Grey---500---GB, #181F26);

                .dropdown-item {
                  &:hover {
                    background: #3B4D5E;
                  }
                }
              }

            }

            .rd-wallet-send-btn {
              padding: 10px 15px;
              border-left: 2.5px solid rgba(255, 255, 255, 0.1);

              .rd-swap-transfer-sendall {
                display: flex;
                gap: 10px;

                button {
                  background: #3B4D5E;
                  border: none;
                  padding: 8px 15px;
                  border-radius: 16px;
                  color: #fff;
                  font-family: "Gilroy-SemiBold";
                }
              }
            }

            .rd-wallet-input {
              input {
                background: none;
                border: none;
                color: #fff;
                box-shadow: none;
                outline: none;
                text-align: right;
                color: #fff;
                font-family: "Gilroy-SemiBold";
                font-size: 18px;
                width: 100%;
              }
            }
          }
        }

        .rd-swap-img-section {
          display: flex;
          justify-content: center;

          .swaper {
            background: var(--Background---grey-Grey---500---GB, #181f26);
            border: 1px solid hsla(0, 0%, 100%, .1);
            border-radius: var(--Spacing-xl, 20px);
            opacity: var(--Corner-radius-xs, 1);
            padding: 15px;
            cursor: pointer;
          }
        }

        .rd-swap-exc-wrap {
          .rd-swap-exc-inner {
            display: flex;
            font-family: 'Gilroy-SemiBold';
            color: #92A6BA;
            justify-content: space-between;
            font-size: 12px;
            padding: 0 10px 10px 10px;
          }
        }

      }

      .rd-act-btn-wrap {
        display: flex;
        width: 100%;
        gap: 15px;

        .rd-act-btn {
          border-radius: var(--Spacing-2xl, 24px);
          background-color: #F1FEEC;
          color: #0E1216;
          width: 100%;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          font-size: 15px;
          line-height: 24px;
          font-family: "Gilroy-SemiBold";
          border: none;

          &.close {
            background-color: #3B4D5E;
            color: #F1FEEC;
          }

          &.hasicon {
            &::after {
              content: "";
              background-image: url("../../images/redo/arrow-right.png");
              background-repeat: no-repeat;
              height: 26px;
              width: 32px;
            }
          }
        }
      }

      .connect_wallet_popup {
        min-height: 500px;

        &.min_auto {
          min-height: auto;
        }

        background: #181F26;
        border-radius: 24px;
        padding: 30px;
        margin-bottom: 15px;

        .rd-con-wall-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 15px;
          padding: 35px 0;
          text-align: center;
          width: 250px;
          margin: auto;

          .rd-con-wall-title {
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            font-family: "Gilroy-SemiBold";
          }

          .rd-con-wall-subtitle {
            font-size: 14px;
            line-height: 24px;
            color: #92A6BA;
            font-family: "Gilroy-Medium";
          }
        }

        .wallet-adapter-modal-wrapper {
          box-shadow: none;
          background: #141A1F;

          .wallet-adapter-button {
            border: none !important;
            padding: 10px 20px;
            height: 64px;
            box-shadow: none;

            &:hover {
              background-color: #141A1F !important;
            }
          }
        }

      }

      .connectwallettab {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10px;
        font-family: "Gilroy-Bold";
        font-size: 16px;
        color: #fff;
        padding: 15px;
      }

      .marketValueNote {
        color: #92a6ba;
        font-family: Gilroy-Medium;
        font-size: 14px;
        line-height: 24px;
      }

      .wallet-wrap {
        border-radius: 15px;
        padding: 10px 20px;
        background: #141a1f;
        box-shadow: none;
        font-family: 'Gilroy-SemiBold';

        button {
          background: none !important;
          border: none !important;
        }
      }

      .token_dollar {
        font-family: "Gilroy-SemiBold";
      }

      ._conwallet_refresh {
        cursor: pointer;
      }

    }
  }
}


.rd-wallet-main-menu {
  .rd-wallet-items-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    color: #fff;
    justify-content: space-between;
    padding: 5px;
    margin: 15px 0;
    border-bottom: 1.5px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 20px;

    .rd-wallet-items {
      flex: 0 0 30%;
      max-width: 30%;
      cursor: pointer;

      .rd-wallet-items-inner {
        display: flex;
        align-items: center;
        justify-content: center !important;
        gap: 10px;
        padding: 15px 30px;
        cursor: pointer;
        border-radius: var(--Spacing-xl, 20px);
        opacity: var(--Corner-radius-xs, 1);
        background: var(--Background---grey-Grey---500---GB, #181F26);
        font-size: 16px;
        line-height: 24px;
        font-family: "Gilroy-Medium";
        color: #5D7893;
      }

      &.active {
        .rd-wallet-items-inner {
          font-family: "Gilroy-Bold";
          color: #fff;
          background-color: #181F26;
          border: 1.5px solid rgba(255, 255, 255, 0.1);
          background-image: linear-gradient(360deg, #1a3016, #181F26), radial-gradient(46.09% 46.09% at 50% 92.19%, rgba(116, 243, 59, 0.2) 0%, rgba(116, 243, 59, 0) 100%), linear-gradient(274.8deg, rgba(255, 255, 255, 0.02) 0%, rgba(255, 255, 255, 0.16) 99.76%);
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
// Retheme Wallet -----------End------------------//

.pageNotFound_innerWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  padding: 100px;

  .title {
    font-family: "Gilroy-Medium";
    color: #fff;
    font-size: 25px;
  }

  .sub_title {
    font-family: "Gilroy-Regular";
    color: #92A6BA;
    font-size: 16px;
  }

  .go_to {
    font-family: "Gilroy-Bold";
    color: #51e20e;
    text-decoration: none;
    font-size: 18px;
  }
}

body {
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #51e20e;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #92A6BA;
  }
}

.GB_page_loader {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  span {
    color: #92A6BA;
    font-size: 22px;
    line-height: 24px;
    font-family: "Gilroy-Bold";
  }
}


.modal {
  top: 0 !important;
}

@keyframes pulseAnimation {
  0% {
    box-shadow: 0 0 0 0px rgba(116, 243, 59, 0.4);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(116, 249, 59, 0);
  }
}

._GB_RedoSync {
  .redo-godashbtn {
    border-radius: var(--Spacing-2xl, 24px);
    background-color: #F1FEEC;
    // max-width: 360px;
    width: 300px;
    margin: auto;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #0E1216;
    font-size: 14px;
    line-height: 24px;
    border: none;
    font-family: "Gilroy-SemiBold";

    @media only screen and (max-width: 767px) {
      border-radius: 16px;
    }

    &:hover {


      background: var(--Background---grey-Grey---400---GB, #3B4D5E);
      color: #fff;


    }

    &.hasArrow {

      &::after {
        content: "";
        background-image: url("../../images/redo/arrow-right.png");
        background-repeat: no-repeat;
        height: 26px;
        width: 32px;
        margin-left: 3px;
      }

      &:hover {
        &::after {
          content: "";
          background-image: url("../../images/redo/arrow-right.png");
          background-repeat: no-repeat;
          height: 26px;
          width: 32px;
        }
      }
    }

    &.btnAjaxLoading {
      padding: 10px;

      .text-light {
        color: #181f26 !important;
        margin-right: 5px !important;
      }
    }
  }

  .redo_sync_item {
    border: 1px solid rgba(59, 77, 94, 0.5);
    background: #181f26 !important;
  }

  .redo_sync_process {
    @media only screen and (min-width: 1366px) {
      max-width: 767px;
      margin: auto;
    }
  }

  .redo-searchAgain {
    background: none;

    input {
      background: none;
    }
  }

  .redo-errornamewrap {
    background: linear-gradient(90deg,
        rgba(255, 191, 0, 0.1) 0.1%,
        rgba(255, 191, 0, 0) 99.91%) !important;
    backdrop-filter: blur(32px);
  }

  .sync_section_inner {
    border: 1px solid #3b4d5e;
  }

  .sync_title {
    font-size: 30px;
    font-family: "Gilroy-SemiBold";
  }

  .sync_p {
    font-size: 16px;
    line-height: 24px;
    font-family: "Gilroy-Medium";
  }
}

.convertAmount {
  margin-top: 16px;

  .amountInSolano {
    color: #FFF;
    font-family: "Gilroy-Medium";
    font-size: 12px;
    line-height: 22px;

  }

  .upOrdown {
    span {
      color: #B2F35F;
      font-family: "Gilroy-Medium";
      font-size: 12px;
      line-height: 22px;
    }
  }
}

.adm-misedlink {
  padding: 15px 0;

  a {
    color: #e52420;
  }
}

.adm-mis {
  max-width: 100% !important;

  @media only screen and (min-width: 768px) {
    max-width: 80% !important;
  }

  .modal-content {
    max-width: 100% !important;

    .adm-mis-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      border: 2px solid #2d2d2d;
      margin: 10px;
      padding: 20px;
      border-radius: 20px;
      gap: 10px;
      font-family: "Gilroy-Medium";
      .adm-mis-fields {
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex: 0 0 100%;
        max-width: 100%;

        @media only screen and (min-width: 768px) {
          flex: 0 0 48;
          max-width: 48%;
        }

        @media only screen and (min-width: 1024px) {
          flex: 0 0 20%;
          max-width: 20%;
        }

        label {
          color: #fff;
        }

        input[type="date"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
          filter: invert(0.8);
        }

        input[type="time"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
          filter: invert(0.8);
        }

        .adm-mis-fields-split {
          display: flex;
        }
        .adm-mis-fields-error {
          color: #e52420;
          font-family: "Gilroy-Bold";
        }
      }
    }
  }

  .modal-footer {
    justify-content: center !important;
    gap: 10px;
  }
}
_GB_RedoCron {
  @media only screen and (min-width: 1025px) {
    position: relative;
    top: 35px;
  }

  .crontestbanner {
    height: 600px;

    @media only screen and (max-width: 767px) {
      height: 300px;
    }
  }
}
.hideAdminMenu{
  display: none !important;
}
.crypto-flex-wallet-only{
  display: block !important;
  position: relative;
  .crypto-left{
    position: absolute;
    max-width: 100% !important;
  }
  .crypto-right-wallet-only {
    max-width: 100% !important;
      .redo-cryto-chart {
        flex-direction: column;
        position: relative;
        .redo-cryto-chart-graph{
          position: relative;
          top: 30px
        }
        .upOrdown{
          position: absolute;
          right: 5px;
          top: 20px;
        }
      }
    }
}

.crypto-flex-mobile-only {
  @media only screen and (max-width: 1024px) {
    display: block !important;
        width: 100%;
  }
  .crypto-left {
    @media only screen and (max-width: 1024px) {
        position: absolute;
      }
  }
  .crypto-right-mobile-only{
    @media only screen and (max-width: 1024px) {
      position: relative;
      top: 30px;
    }
    .redo-cryto-chart{
      @media only screen and (max-width: 1024px) {
        display: block !important;
        position: relative;
      }
      .upOrdown {
        @media only screen and (max-width: 1024px) {
          position: absolute;
          top: 0;
          right: 5px;
        }
      }
    }
  }
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.tdt-space {
  padding: 10px 0;
}

.rd_td_big {
  @media only screen and (min-width: 1366px) {
    width: 20% !important;
  }
}
.red-text{
  color: #e52420;
}
.hcInitModal{
.hcPopHeadImg {
    display: flex;
    justify-content: center;
        padding: 0px 20px 20px;
    background-repeat: no-repeat;
    

    &.hc-aura-bg {
      background-image: url("../../images/redo/hc-head-icon.png") !important;
          background-position: center top !important;

      img {
        animation: rotateRight 90s linear infinite;
      }
    }
    &.dis-aura-bg {
      background-image: url("../../images/redo/discordiconcenter.png") !important;
          background-position: 50% -22px !important;

      img {
        animation: rotateRight 90s linear infinite;
      }
    }
  }

  .hcPopContent {
    margin-bottom: 0 !important;
    margin: 10px;
        padding: 10px 20px !important;
    .hcContent {
      text-align: left !important;
      flex-direction: column;
      justify-content: start;
      line-height: 30px !important;
      padding: 0 !important;
      align-items: start !important;

      .hcContent-p {
        text-align: left !important;
        color: #92A6BA;
      }

      .green {
        color: #51E20E;
      }

      .red {
        color: #AC0000;
      }

      ul {
        padding-left: 20px;

        li {
          font-size: 16px;
          line-height: 24px;
    margin-bottom: 10px;
          &::marker {
            display: none;
          }
        }
      }
    }
  }
     .hcContent-p1 {
       padding: 12px 36px;
       color: #92A6BA;
       font-family: "Gilroy-Regular";
       font-size: 16px;
       line-height: 24px;
      .green {
          color: #51E20E;
        }
     }
     .betPopFooter{
      margin-top: 0 !important;
          padding: 0 10px !important;
     }
}

.hc_sub_title{
  font-size: 24px !important;
    line-height: 30px !important;
}

.hcPopHeadTitle {
  color: #92A6BA !important;
  font-family: "Gilroy-Regular" !important;
  font-size: 18px !important;
}

.discord-oauth-wrap {
  background: #181f26;
  padding: 20px;
  // max-width: 600px;
  // margin: auto;
border: 1px solid rgba(59, 77, 94, 0.5);
  .discord-oauth-response {
      padding: 20px;
      color: #92a6ba;
      font-size: 18px;
      text-align: center;
      font-family: "Gilroy-SemiBold";
      width: 100%;
      border-top: 1px solid rgba(59, 77, 94, 0.5);
      border-bottom: 1px solid rgba(59, 77, 94, 0.5);
      .discord-oauth-response-data {
          span {
            color: #fff;
            font-size: 20px;
          }
        }

    }
         .discNextBtn {
           padding: var(--Spacing-lg, 16px) var(--Spacing-2xl, 24px);
           border-radius: var(--Spacing-xl, 20px);
           background: var(--Primary-Green---50---GB, #F1FEEC);
           box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.04), 0px 24px 48px -8px rgba(241, 254, 236, 0.12);
          width: 300px;
           color: var(--Background---grey-Grey---950---GB, #020303);
           font-family: "Gilroy-Medium";
           font-size: 18px;
           font-style: normal;
           font-weight: 400;
           line-height: 24px;
           display: flex;
           align-items: center;
           gap: 8px;
           justify-content: center;
           border: none;
    
           @media only screen and (max-width: 767px) {
             font-size: 14px;
           }
    
                     margin-top: 20px;
         }
                 .joindisc a {
                  color: #51E20E;
                      text-decoration: none;
                 }
}
.trx-bonus-eraning-sect{
      border-top: 1px solid #3B4D5E;
        padding-top: 10px;
}
.gb-discord-icon {
  width: 45px;
  height: 45px;
  background: #5865f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  svg{
    fill: #fff;
    width: 30px;
    height: 30px;
  }
}

.hc_active_icon {
  img {
    border: none !important;
    border-radius: 0 !important;
    padding-left: 10px;
  }
}

.adm-act-badge{
  cursor: pointer;
}
.adm-red-btn{
  background-color: red !important;
  background: red !important;
}
.adm-green-btn {
  background-color: green !important;
  background: green !important;
}
.tourHCActivated {
  color: #74F33B;
  font-family: "Gilroy-Regular";
  font-size: 14px;
  padding: 20px 0 0;
}
.tourCostSpan {
  color: #74F33B !important;
  &.tourHCFeeSpan{
    padding-left: 5px;
  }
}
.admin-avg-wrap{
  .avg-row {
    font-family: "Gilroy-Regular";
    color: #fff;
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #2d2d2d;
    border-radius: 10px;
    margin-bottom: 10px;
    .avg-col-left{
      flex: 0 0 40%;
      max-width: 40%;
    } 
    .avg-col-right {
      flex: 0 0 60%;
        max-width: 60%;
        color: gold;
        display: flex;
        gap: 20px;
        align-items: center;
        .crit-col {
            background: #000;
            border: 1px solid #2d2d2d;
                padding: 15px 20px;
            width: 100%;
            font-family: "Gilroy-Bold";
                color: #51E20E;
          }
    }
    &.head{
      padding: 5px 10px;
      .avg-col-left{
        font-family: "Gilroy-Bold";
        color: gold;
      }
    }
  }
    .crit-col-head {
      padding: 5px 20px;
      width: 100%;
      font-family: "Gilroy-Bold";
    }
}
.adm-scanned-matches {
  font-family: "Gilroy-Bold";
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
  justify-content: flex-end;
}
.GB_sim_device_th {
  color: gold;
  font-family: "Poppins-SemiBold";
}
.GB_sim_device_td {
  color: #fff;
      font-family: "Gilroy-Regular";
}
.simulation-label {
      font-family: "Poppins-SemiBold";
  font-size: 14px;
  color: gold;
}
.filtered-search-wrap{
  display: flex;
  align-items: center;
  .filtered-search{
    color: #fff;
      font-family: "Gilroy-Bold";
      font-size: 20px;
  }
  .filtered-search-val {
    color: #51E20E;
      background: #000;
      border: 1px solid #2d2d2d;
      padding: 15px 20px;
      font-family: "Gilroy-Bold";
      width: 100%;
      display: block;
      text-align: center;
  }
  
}

.sim-outcome-wrap {
  display: flex;
  gap: 10px;
      justify-content: center;
  .sim-outcome-col {
      flex: 0 0 48%;
      max-width: 48%;
      padding: 0 20px;
    }
}
.sim-chall-name {
  padding: 15px 20px;
  display: block;
}
.sim-filtered-val {
  color: #51E20E;
  background: #000;
  border: 1px solid #2d2d2d;
  padding: 15px 20px;
  font-family: "Gilroy-Bold";
  display: block;
  text-align: center;
}

.sim-chall-list {
  &.hideIt {
    display: none;
  }
}
.sim-loader {
  display: flex;
  align-items: center;
  color: #fff;
  gap: 10px;
  background: #000;
  border: 1px solid #2d2d2d;
  padding: 15px 20px;
  font-family: "Poppins-SemiBold";
}
.sim-sub-loader{
      display: flex;
        align-items: center;
        color: #fff;
        gap: 10px;
        // background: #000;
        // border: 1px solid #2d2d2d;
        padding: 15px 20px;
        // justify-content: center;
        width: 80%;
        font-family: "Poppins-SemiBold";
}
.tool-sim-warp {
  .tool-sim-label {
    color: gold;
    font-family: "Gilroy-Bold";
  }

  .tool-sim-val {
    background: #000;
    border: 1px solid #2d2d2d;
    padding: 15px 20px;
    width: 100%;
    font-family: "Gilroy-Bold";
    color: #51E20E;
  }
}
.dbdImg {
  width: 60px;
}