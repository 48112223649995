._GB_Banner_Top {
  .user_device_menu {
    .tooth-device-menu {
      .item-burger {
        z-index: 99999998 !important;
      }
    }
    #mobileSidebar {
      z-index: 99999999 !important;
    }
  }
}
._GB_Home_Banner {
  height: 650px !important;
  min-height: 650px !important;

  @media only screen and (max-width: 767px) {
    margin-top: -40px !important;
  }
  .bannerSec {
    @media only screen and (max-width: 767px) {
      margin-top: 85px !important;
    }
    @media only screen and (max-width: 375px) {
      margin-top: 120px !important;
      padding-top: 20px;
    }
    .banner_subtitle {
      max-width: 676px;
      margin: auto;
      @media only screen and (max-width: 767px){
        font-size: 14px !important;
        line-height: 17px !important;
      }
      span {
        font-family: "Gilroy-Bold";
        font-size: 20px;
        line-height: 27px;
        @media only screen and (max-width: 767px){
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }
    }
  }

  .banner-layout {
    ._Banner_Bottom {
      display: none;
    }
  }

  .banner_BtnSec {
    margin-top: 20px !important;
  }

  .banner_game_details {
    .detail_box {
      &:last-child {
        .box-item {
          img {
            width: 55px !important;
          }
        }
      }
      .box-item {
        img {
          width: 65px !important;
        }
      }
    }
  }
}

.GB_homeChallengeHistory {
  padding: 50px 0;

  .earn_title {
    font-family: "Poppins-Bold";
    font-size: 50px;
    line-height: 84px;
    color: #ffffff;
    text-align: center;

    @media only screen and (max-width: 1024px) {
      font-size: 38px;
      line-height: 45px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 30px;
      line-height: 35px;
    }

    img {
      margin-right: 10px;
    }
  }

  .sub_title {
    font-size: 18px;
    line-height: 28px;
    font-family: "Gilroy-Medium";
    max-width: 769px;
    margin: auto;
    text-align: center;
    color: #ffffff;

    @media only screen and (max-width: 767px) {
      font-size: 17px;
      line-height: 22px;
      margin-top: 15px;
    }
  }

  .homeChallengeHistory_inner {
    .GB_Challengetitles {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 35px 0px;

      @media only screen and (max-width: 991px) {
        flex-direction: column;
        justify-content: left;
        align-items: center;
        width: 100%;
        max-width: 768px;
      }

      .title {
        padding: 0 !important;
        font-size: 40px;
        line-height: 46px;
        font-family: "Gilroy-Bold";
        color: #fff;
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }

      .titles {
        h2 {
          font-size: 40px;
          line-height: 46px;
          font-family: "Gilroy-Bold";
          color: #fff;

          @media only screen and (max-width: 991px) {
            font-size: 20px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 30px;
            line-height: 36px;
          }
        }
      }

      .titles_games {
        display: flex;
        align-items: center;
    gap: 8px;
        @media only screen and (max-width: 991px) {
          width: 100%;
          justify-content: center;
          margin: 10px 0;
        }

        .all-btn {
          width: 80px;
          height: 50px;
          border: 2px solid #484848;
          border-radius: 8px;
          cursor: pointer;
          padding: 4px;
          margin-right: 5px;
          font-family: "Gilroy-Bold";

          &:hover {
            background: rgba(200, 0, 0, 0.5) !important;
            border-color: #c80000;
          }

          &.active {
            background: rgba(200, 0, 0, 0.5) !important;
            border-color: #c80000;
          }
        }

        .summary_logo {
          display: flex;
          margin-right: 5px;
gap: 5px;
          img {
            border: 2px solid #484848;
            border-radius: 8px;
            cursor: pointer;
            height: 50px;
            margin: 2px;
            padding: 4px;
            width: 50px;
          }
        }
      }

      .live-sec {
        background: #181818;
        border: 1px solid #52dd21;
        border-radius: 7px;
        color: #52dd21;
        width: 85px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;

        @keyframes blink {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 0.75;
          }

          100% {
            opacity: 1;
          }
        }

        img {
          margin-right: 5px;
          animation: blink 2s linear infinite;
        }
      }
    }

    .challengehistory_table {
      background: #121212;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      padding: 20px 33px 10px;
      @media only screen and (max-width: 767px) {
        padding: 15px;
      }
      thead {
        tr {
          background: #1e1e1e;

          .GB_device_th {
            background: #1e1e1e;
            @media only screen and (max-width: 767px) {
              display: none;
            }
            &:first-child {
              border-top-left-radius: 10px;
            }

            &:last-child {
              border-top-right-radius: 10px;
            }
          }
        }
      }

      tbody {
        tr {
          .GB_device_td {
            background: #161616;

            &:before {
              @media only screen and (max-width: 767px) {
                content: attr(data-label);
                font-weight: bold;
                text-transform: uppercase;
                top: 10px;
                color: #777;
              }

              @media only screen and (max-width: 479px) {
                position: relative;
              }
            }
          }

          &:nth-child(even) {
            td {
              border-color: #000;
              background-color: #212121;
            }
          }

          &:nth-child(odd) {
            td {
              border-color: #000;
              background-color: #161616;
            }
          }

          td {
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
            vertical-align: middle;
            padding: 10px 8px;
            box-shadow: none;
            position: relative;
            text-align: left;

            @media only screen and (max-width: 991px) {
                flex-direction: column;
                justify-content: left;
                align-items: center;
                width: 100%;
                max-width: 768px;
            }

            .title {
                padding: 0 !important;
                font-size: 40px;
                line-height: 46px;
                font-family: "Gilroy-Bold";
                color: #fff;
                display: flex;
                align-items: center;

                img {
                    margin-right: 10px;
                }
            }

            .titles {
                h2 {
                    font-size: 40px;
                    line-height: 46px;
                    font-family: "Gilroy-Bold";
                    color: #fff;

                    @media only screen and (max-width: 991px) {
                        font-size: 20px;
                    }

                    @media only screen and(min-width: 1366px) and(max-width: 1919px) {
                        font-size: 30px;
                        line-height: 36px;
                    }
                }
            }

            .titles_games {
                display: flex;
                align-items: center;
gap: 8px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    justify-content: center;
                    margin: 10px 0;
                }

                .all-btn {
                    width: 80px;
                    height: 50px;
                    border: 2px solid #484848;
                    border-radius: 8px;
                    cursor: pointer;
                    padding: 4px;
                    margin-right: 5px;
                    font-family: "Gilroy-Bold";

                    &:hover {
                        background: rgba(200, 0, 0, 0.5) !important;
                        border-color: #c80000;
                    }

                    &.active {
                        background: rgba(200, 0, 0, 0.5) !important;
                        border-color: #c80000;
                    }
                }

                .summary_logo {
                    display: flex;
                    margin-right: 5px;

                    img {
                        border: 2px solid #484848;
                        border-radius: 8px;
                        cursor: pointer;
                        height: 50px;
                        margin: 2px;
                        padding: 4px;
                        width: 50px;
                    }
                }
            }

            .live-sec {
                background: #181818;
                border: 1px solid #52DD21;
                border-radius: 7px;
                color: #52DD21;
                width: 85px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;

                @keyframes blink {
                    0% {
                        opacity: 0;
                    }

                    50% {
                        opacity: .75;
                    }

                    100% {
                        opacity: 1;
                    }
                }

                img {
                    margin-right: 5px;
                    animation: blink 2s linear infinite;
                }
            }
        }

        .challengehistory_table {
            background: #121212;
            border: 2px solid #2d2d2d;
            border-radius: 20px;
            padding: 20px 33px 10px;
            @media only screen and (max-width: 767px){
                padding: 15px;
            }
            thead {
                tr {
                    background: #1E1E1E;

                    .GB_device_th {
                        background: #1E1E1E;
                        @media only screen and (max-width: 767px){
                            display: none;
                        }
                        &:first-child{
                            border-top-left-radius: 10px;
                        }

                        &:last-child {
                            border-top-right-radius: 10px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    .GB_device_td {
                        background: #161616;

                        &:before {
                            @media only screen and (max-width: 767px) {
                                content: attr(data-label);
                                font-weight: bold;
                                text-transform: uppercase;
                                top: 10px;
                                color: #777;
                            }

                            @media only screen and (max-width: 479px) {
                                position: relative;
                            }
                        }
                        .outcome{
                            ._each_score_right{
                                color: #52DD21;
                            }
                        }
                    }

                    &:nth-child(even) {
                        td {
                            border-color: #000;
                            background-color: #212121;
                        }
                    }

                    &:nth-child(odd) {
                        td {
                            border-color: #000;
                            background-color: #161616;
                        }
                    }

                    td {
                        font-family: "Gilroy-Bold";
                        font-size: 16px;
                        line-height: 100%;
                        color: #ffffff;
                        vertical-align: middle;
                        padding: 10px 8px;
                        box-shadow: none;
                        position: relative;
                        text-align: left;

                        @media only screen and (max-width: 991px) {
                            font-size: 14px;
                        }
                    }
                }
            }
          }
        }
      }
    }
    ._lb_mob_table {
      #seasonResult_Table {
        thead {
          tr {
            background-color: #1e1e1e;
            th {
              font-size: 11px;
              line-height: 15px;
              &:first-child {
                border-top-left-radius: 10px;
                // width: 50%;
              }
              &:last-child {
                border-top-right-radius: 10px;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              font-size: 11px;
              line-height: 15px;
              padding: 10px 5px;
              // &:first-child{
              //   width: 60%;
              // }
              .challengerplayerhome{
                img{
                  width: 35px !important;
                  height: 35px !important;
                  margin-right: 5px !important;
                  @media only screen and (max-width: 374px){
                    width: 25px !important;
                    height: 25px !important;
                  }
                }
              }
              .Outcome{
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}

.mobile_subsec_logged {
  @media only screen and(max-width : 767px) {
    display: none !important;
  }
}

._GB_AvailableGamesBeta {
  @media only screen and (max-width: 375px) {
    margin-top: 65px;
  }
  ._GB_available_Inner {
    .available_top {
      .top_left {
        h2 {
          @media only screen and (max-width: 1024px) {
            font-size: 38px;
            line-height: 45px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 28px;
            line-height: 35px;
          }
        }
      }
    }
  }
  .available_Bg {
    .slick-slider {
      .slick-prev {
        @media only screen and (max-width: 767px) {
          top: -25%;
          left: 85%;
        }
        @media only screen and (max-width: 374px) {
          left: 80%;
        }
        &::before {
          @media only screen and (min-width: 768px) and (max-width: 991px){
            left: -15% !important;
          }
          @media only screen and (min-width: 768px) {
            content: url("../images/game_slider_left.png") !important;
            top: -15px;
            position: absolute;
          }
        }
      }
      .slick-next {
        @media only screen and (min-width: 991px) and (max-width: 1024px){
          right: 0%;
        }
        @media only screen and (max-width: 767px) {
          top: -25%;
          right: 3%;
        }
        @media only screen and (max-width: 374px) {
          right: 5%;
        }

        &::before {
          @media only screen and (min-width: 1025px){
            right: 25%;
          }
          @media only screen and (min-width: 768px) {
            content: url("../images/game_slider_right.png") !important;
            top: -15px;
            position: absolute;
          }
          @media only screen and (max-width: 767px) {
          }
        }
      }

      .slick-list {
        .available_gamebox {
          border: none;
          outline: none;
          @media only screen and (max-width: 767px) {
            width: 100%;
          }

          img {
            max-width: 169px;
            margin: auto;
            height: 108px;
                border-radius: 8px;
            @media only screen and (max-width: 767px) {
              max-width: 110px;
              margin: auto;
              height: 69px;
            }
            &:hover {
              border: 2px solid #52dd21;
              border-radius: 10px;
            }
          }
          .upcoming {
            position: relative !important;
            &:hover {
              .available_coming {
                display: block;
              }
            }
            img {
              opacity: 0.5;
            }
            .available_coming {
              position: absolute !important;
              display: none;
              color: #ffffff;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              left: 28%;
              top: 40%;
              @media only screen and (max-width: 767px){
                font-size: 11px;
                left: 24%;
              }
            }
          }
        }
      }
      // .slick-dots{
      //     li{
      //         button{
      //             &::before{
      //                 content: url("../images/slide_icon.png");
      //             }
      //         }
      //     }
      // }
    }
  }
}

.bet_current {
  ._each_lb_score {
    .Challenge_title {
      text-transform: none;
    }
    ._each_lb_scoreline {
      font-size: 11px;
    }
  }
}


.Toastify__toast-container{
  margin-top: 48px;
  z-index: 99999999999 !important;
}


.aeo4nameslist {
  max-width: 230px;
  margin-top: 15px;
}
.aoesrachimg {
  width: 19px;
  margin-left: 10px;
  cursor: pointer;
}
.ssname {
  text-transform: capitalize;
}
// ------- New header start-----------
.notif-menu {
  svg {
    font-size: 26px;
    color: #fff;
  }

  #dropdown-notification {
    background: none;
    border: none !important;

    .notifMain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50px;

      .notifWrap {
        position: relative;

        .notifCount {
          display: block;
          position: absolute;
          color: #fff;
          top: -10px;
          right: -5px;
          background: red;
          border-radius: 5px;
          font-size: 12px;
          padding: 0px 5px;
          font-family: "Gilroy-Bold";
        }
      }

      .notifDDimg {
        width: 15px;
        height: 10px;
        margin-top: 3px;
      }
    }

    &::after {
      display: none !important;
    }
  }

  .notifListWrap {
    @media only screen and (min-width: 1025px) {
      width: 320px;
    }

    .notifItem {
      &:hover {
        background: none !important;
      }

      .notifList {
        display: flex;
        align-items: center;
        border-bottom: solid 1px #2d2d2d;
        padding-bottom: 10px;

        .notifImg {
          padding: 5px 10px;
        }

        .notifContent {
          white-space: normal !important;

          .notifTime {
            color: rgba(235, 235, 245, 0.6);
          }
        }
      }

      &:last-child {
        .notifList {
          border: none;
        }
      }

    }

    .notifListMore {
      background-color: #000;
      padding: 10px;
      text-align: center;
      border-radius: 10px;
      color: rgba(235, 235, 245, 0.6);
      font-family: "Gilroy-Bold";
    }
  }

  .notifListMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 20px 10px;
    color: #fff;
    font-family: "Gilroy-Bold";

    .nomessageimg {
      svg {
        font-size: 40px;
        transform: rotate(-35deg);
      }
    }
  }
}

#dropdown-profile {
  &::after {
    display: none !important;
  }

  .menu_profile_name {
    .menu_profile_name_dd {
      width: 15px;
      height: 10px;
      margin-left: 10px;
    }
  }
}

#dropdown-curr-wallet img {
  width: 15px !important;
  height: 10px !important;
}

.menu_seperator {
  display: block;
  width: 2px;
  height: 40px;
  background: #2d2d2d;
  margin: 0px 15px 0 0;
}

#dropdown-help {
  margin-right: 20px;

  &::after {
    display: none !important;
  }

  .menu_help {
    .menu_help_dd {
      width: 15px;
      height: 10px;
      margin-left: 10px;
    }
  }
}

#dropdown-language {
  border: solid 3px #2d2d2d !important;

  &::after {
    display: none !important;
  }

  .menu_lang {
    .menu_lang_dd {
      width: 15px;
      height: 10px;
      margin-left: 10px;
    }
  }
}

// ---------- New header end-----------

// ---------- My Friend start-----------
.mf-top-section{
  .mf-top-inner{
    min-height: 270px;
  }
  .mf-head-text{
    color: #fff;
    font-family: "Gilroy-Bold";
    font-size: 20px;
      line-height: 32px;
      &.mf-head-text-invites{
        font-size: 34px;
        line-height: 40px;
      }
  }
     .mf-tag {
      color: rgba(235, 235, 245, 0.6);
       font-family: "Gilroy-Bold";
          font-size: 16px;
            line-height: 28px;
        margin-top: 10px;
     }
  .mf-searchbar {
    margin-top: 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    .mf-searchbar-form-wrap {
        flex: 0 0 75%;
          max-width: 75%;
        .mf-searchbar-form-inner{
          background: #000 !important;
          border: 2px solid #2d2d2d;
          height: 56px;
          border-radius: 10px !important;
          display: flex;
          padding: 10px;
          position: relative;
            .mf-searchbar-img {
                padding: 5px;
              }
        .mf-searchbar-form{
              background: #000 !important;
                border: none;
                box-shadow: none;
                outline: none;
                border-radius: 10px !important;
                height: 100%;
                    //color: rgba(235, 235, 245, 0.6);
                    color: #fff;
                    font-family: "Gilroy-Bold";
        }
                .mf-close-img {
                  padding: 8px;
                  cursor: pointer;
                }
                                .my-search-list {
                                  padding: 10px;
                                  position: absolute;
                                  top: 56px;
                                  left: 0px;
                                  background: #000;
                                  width: 100%;
                                  border: solid 1px;
                                  border-radius: 10px !important;
                                  z-index: 99999;
                                  .my-search-list-items-wrap {
                                      height: 300px;
                                      overflow-y: scroll;
                                      &::-webkit-scrollbar {
                                          width: 3px;
                                        }
                                      
                                        /* Track */
                                        &::-webkit-scrollbar-track {
                                          background: #181818;
                                        }
                                      
                                        /* Handle */
                                        &::-webkit-scrollbar-thumb {
                                          background: #e52420;
                                        }
                                      
                                        /* Handle on hover */
                                        &::-webkit-scrollbar-thumb:hover {
                                          background: #e52420;
                                        }
                                      .my-search-list-items {
                                          display: flex;
                                          align-items: center;
                                          gap: 15px;
                                          padding: 10px;
                                              cursor: pointer;
                                          .my-search-list-img  {
                                            img {
                                                border-radius: 25px;
                                                width: 40px;
                                                height: 40px;
                                                object-fit: cover;
                                              }
                                            }
                                          .my-search-list-name {
                                              color:#fff;
                                              font-family: "Gilroy-Bold";
                                              font-size: 16px;
                                            }
                                        }
                                        .nousermessage {
                                          color: #fff;
                                          /* text-align: center; */
                                          padding: 10px;
                                        }
                                    }
                                 
                                }
              }
    }

    .mf-searchbar-btn {
      flex: 0 0 20%;
      max-width: 20%;
      &::after{
        display: none;
      }
    }
  }
}
.friend-img{
  border-radius: 25px;
  width: 45px;
  height: 45px;
  object-fit: cover;
  margin-right: 20px;
}
.unfollowfriend {
  color: #c80000;
  cursor: pointer;
}
.mf-text-green {
  color: #52dd21;
  font-family: "Gilroy-Bold";
  font-size: 30px;
  cursor: pointer;
}

// ---------- My Friend end-----------

.yellow-timeout {
  color: #ffc52e;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
}

.withdraw-deposit-paypal-popup{
  .modal-content{
    @media only screen and (min-width: 768px) {
      max-width: 620px !important;
    }
    .swap-type-btn{
      @media only screen and (min-width: 768px) {
        flex: none!important;
        max-width: none!important;
      }
    }
    .withdraw_crypto {
      color: #FFC01F;
    }
    .withdraw_paypal_img{
      margin-left: 5px;
    }
    .coin-email {
      input {
          background-color: #181818;
          border: none;
          outline: none;
          padding: 0 5px;
          width: 100%;
          color: #fff;
        }
    }
  }
}
.GB_device_vip{
  vertical-align: middle !important;
}
.notifProfileImg {
  border-radius: 45px;
  width: 45px;
  height: 45px;
  border: 2px solid #2d2d2d;
}
.errorMsg{
  a{
    color: #c80000;
  }
}
.withdraw_crypto_admin{
  color: #FFC01F;
}
.err_email_paypal #fromto-dropdown {
  border: solid 2px red !important;
}