/*-------------------- Tournaments Page Start------------------*/
._GB_Tournaments {
  background-color: #000;

  ._GB_Banner_Top {
    .container {}
  }

  .tournament-banner {
    // background-image: url("../images/tournament-banner.png");
    background-repeat: no-repeat;
    background-position: center center;
    height: 847px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    @media only screen and (max-width: 767px) {
      height: auto;
      background-position: center center;
      background-size: cover;
    }

    &.dota2-bg {
      background-image: url("../images/dota2_background.png");
    }

    &.pubg-bg {
      background-image: url("../images/pubg_background.png");
    }

    &.warzone-bg {
      background-image: url("../images/warzone_background.png");
    }

    &.aoe4-bg {
      background-image: url("../images/aoe4_leaderboardbg.jpg");
    }

    .slected-game {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;

      @media only screen and (max-width: 575px) {
        gap: 15px;
      }

      img {
        width: 57px;
        height: 57px;

        &.tournament-logo {
          width: auto;
          height: auto;
        }
      }
    }

    .tournament-title {
      font-family: "Gilroy-Bold";
      color: #ffffff;
      font-size: 30px;
      line-height: 40px;

      @media only screen and (min-width: 991px) {
        font-size: 38px;
        line-height: 50px;
      }

      @media only screen and (min-width: 1366px) {
        font-size: 65px;
        line-height: 65px;
        margin-bottom: 20px;
      }
    }

    .tournament-Subtitle {
      font-family: "Gilroy-Bold";
      color: #ffffff;
      font-size: 25px;
      line-height: 37px;
      max-width: 760px;
      width: 100%;
      margin: auto;

      @media only screen and (max-width: 767px) {
        font-size: 17px;
        line-height: 19px;
      }
    }

    .countdown-sec {
      display: flex;
      justify-content: center;
      margin-top: 35px;
      gap: 15px;

      .countdown-Box {
        background-color: #181818;
        border: 2px solid #2d2d2d;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 149px;
        height: 110px;

        @media only screen and (min-width: 768px) {
          height: 150px;
        }

        @media only screen and (min-width: 1200px) {
          height: 180px;
        }

        @media only screen and (max-width: 767px) {
          border: 2px solid #000000;
        }

        .countdown-digit {
          color: #fff;
          font-family: "Gilroy-Bold";
          color: #ffffff;
          font-size: 30px;
          line-height: 40px;

          @media only screen and (min-width: 991px) {
            font-size: 38px;
            line-height: 50px;
          }

          @media only screen and (min-width: 1366px) {
            font-size: 65px;
            line-height: 65px;
            margin-bottom: 20px;
          }
        }

        .countdown-words {
          color: rgba(235, 235, 245, 0.6);
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .partBtn-sec {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 35px 0;

      .joinBtn {
        max-width: 171px;
        width: 100%;
        height: 56px;
        color: #fff;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      .joinedBtn,
      .nogameBtn {
        max-width: 300px;
        width: 100%;
        height: 56px;
        color: #fff;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          opacity: 0.7;
        }

        @media only screen and (max-width: 374px) {
          font-size: 13px;
        }
      }

      .reward_popup {
        width: 100%;

        .nogameBtn {
          max-width: 450px;
          margin: auto;
        }
      }
    }
  }

  .prize-section {
    padding-top: 20px;

    .prize-title {
      font-family: "Gilroy-Bold";
      color: #ffffff;
      font-size: 35px;
      line-height: 52px;

      @media only screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 45px;
      }

      img {
        margin-right: 15px;
        width: 60px;
      }
    }

    .prize-details {
      display: flex;
      gap: 10px;
      margin-top: 20px;
      flex-wrap: wrap;

      @media only screen and (min-width: 1920px) {
        justify-content: space-between;
      }

      @media only screen and (max-width: 767px) {
        justify-content: center;
      }

      @media only screen and (max-width: 374px) {
        justify-content: center;
      }

      .prize-box {
        background-color: #181818;
        border: 2px solid #2d2d2d;
        max-width: 220px;
        width: 100%;
        border-radius: 20px;
        padding: 20px 25px;

        @media only screen and (max-width: 767px) {
          max-width: 170px;
          padding: 15px;
          flex: 0 0 31%;
        }

        @media only screen and (max-width: 400px) {
          max-width: 120px !important;
          height: 102px !important;
          padding: 10px 8px;
        }

        @media only screen and (min-width: 768px) {
          height: 146px;
        }

        .won-coin {
          font-family: "Gilroy-Bold";
          color: #ffffff;
          font-size: 22px;
          line-height: 26px;
          margin: 20px 0 5px;

          @media only screen and (max-width: 767px) {
            font-size: 22px;
          }

          @media only screen and (max-width: 574px) {
            font-size: 12px;
            line-height: 15px;
            margin: 5px 0;
          }

          &.first-prize {
            color: #ffc01f;
          }

          &.second-prize {
            color: #d0d0d0;
          }

          &.third-prize {
            color: #d99246;
          }
        }

        .convert-coin {
          color: rgba(235, 235, 245, 0.6);
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 25px;

          @media only screen and (max-width: 374px) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }

      &.dota2prizelist {
        .prize-box {
          background-color: #181818;
          border: 2px solid #2d2d2d;
          max-width: 180px;
          width: 100%;
          border-radius: 20px;
          padding: 20px 25px;

          @media only screen and (max-width: 767px) {
            max-width: 170px;
            padding: 15px;
            flex: 0 0 31%;
          }

          @media only screen and (max-width: 400px) {
            max-width: 120px !important;
            height: 102px !important;
            padding: 10px 8px;
          }

          @media only screen and (min-width: 768px) {
            height: 146px;
          }

          .won-coin {
            font-family: "Gilroy-Bold";
            color: #ffffff;
            font-size: 18px;
            line-height: 26px;
            margin: 20px 0 5px;

            @media only screen and (max-width: 767px) {
              font-size: 22px;
            }

            @media only screen and (max-width: 574px) {
              font-size: 12px;
              line-height: 15px;
              margin: 5px 0;
            }

            &.first-prize {
              color: #ffc01f;
            }

            &.second-prize {
              color: #d0d0d0;
            }

            &.third-prize {
              color: #d99246;
            }
          }

          .convert-coin {
            color: rgba(235, 235, 245, 0.6);
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 25px;

            @media only screen and (max-width: 400px) {
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }

      .lb-rank-range {
        background: #c80000;
        font-family: "Gilroy-Bold";
        color: #ffffff;
        padding: 5px;
        border-radius: 8px;
        margin-bottom: 0px;
        display: block;
        max-width: 55px;
        text-align: center;
      }
    }
  }

  .rules-section {
    padding: 40px 0;

    @media only screen and (max-width: 767px) {
      padding: 20px 0;
    }

    .rules-title {
      font-family: "Gilroy-Bold";
      color: #ffffff;
      font-size: 35px;
      line-height: 52px;

      @media only screen and (max-width: 767px) {
        font-size: 28px;
        line-height: 45px;
      }

      img {
        margin-right: 15px;
      }

      &::after {
        vertical-align: middle !important;
      }
    }

    .rules-details {
      display: flex;
      align-items: center;
      background-color: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      padding: 25px 30px 25px 20px;
      margin-top: 20px;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
      }

      .rule-box {
        flex: 0 0 40%;
        max-width: 40%;

        @media only screen and (max-width: 1200px) {
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
        }

        ul {
          margin-bottom: 0;

          li {
            font-family: "Gilroy-Bold";
            color: #ffffff;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 5px;

            @media only screen and (max-width: 767px) {
              font-size: 16px;
            }
          }
        }
      }

      .rules-btn {
        flex: 0 0 20%;
        max-width: 20%;
        display: flex;
        justify-content: flex-end;

        @media only screen and (max-width: 1200px) {
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
        }

        @media only screen and (max-width: 767px) {
          justify-content: center;
        }

        .readBtn {
          cursor: pointer;
          max-width: 253px;
          width: 100%;
          height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .season_Scoreboard {
    padding: 0px 0px 50px 0px;
  }
}

#_GB_MainMenu {
  #dropdown-tournament {
    background: none;
    border-color: transparent;
    box-shadow: none;
    font-family: "Gilroy-Bold";
    display: flex;
    align-items: center;
    font-size: 16px;

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      font-size: 12px;
    }

    &::after {
      content: url("../images/dd_down.png");
      border-top: none;
    }

    a {
      text-decoration: none;
      color: #fff;
    }
  }

  .dropdown-menu {
    .dropdown-item {
      &:hover {
        color: #e52420 !important;
      }
    }

    .tournament_games {
      color: #fff;
      text-decoration: none;
      // padding-left: 10px !important;
      padding: 0;

      &:hover {
        color: #e52420 !important;

        a {
          color: #e52420 !important;
        }
      }

      a {
        color: #fff;
        text-decoration: none;
        width: 100%;
        display: block;
        padding: 10px !important;

        &:hover {
          color: #e52420 !important;
        }
      }

      .tlogo {
        margin-right: 10px;
        width: 32px;
      }

      &.comingsoonlink {
        opacity: 0.5;
        color: #fff !important;

        &:hover {
          color: #fff !important;
        }

        a {
          color: #fff !important;
        }
      }
    }
  }
}

/*-------------------- Tournaments Page End------------------*/

/*-------------------- SeasonResults Page Start------------------*/
._GB_SeasonResult {
  padding: 0;

  .seasonResult_game {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    .pubg-logo {
      @media only screen and (max-width: 767px) {
        width: 80px;
        height: 80px;
      }
    }

    .seasonName {
      font-family: "Gilroy-Bold";
      color: #ffffff;
      font-size: 35px;
      line-height: 52px;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    .participate_Section {
      @media only screen and (max-width: 575px) {
        margin-bottom: 15px;
      }

      #participation_mode {
        background-color: #181818;
        box-shadow: none;
        font-family: "Gilroy-Bold";
        font-size: 16px;
        line-height: 18px;
        border: 2px solid #2d2d2d;
        border-radius: 16px;
        width: 165px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mode_item {
          .select_mode {
            margin-left: 10px;
          }
        }
      }

      .dropdown-menu {
        .dropdown-item {
          .select_mode {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .seasonResult_table {
    background: #121212;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    padding: 20px 33px 10px;

    @media only screen and (max-width: 767px) {
      padding: 15px;
    }

    .top-section {
      margin-bottom: 15px;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }

      .count {
        font-family: "Gilroy-Bold";
        font-size: 16px;
        line-height: 19px;
        color: rgba(235, 235, 245, 0.6);
      }

      .remaining_time {
        font-family: "Gilroy-Bold";
        font-size: 16px;
        line-height: 19px;
        color: rgba(235, 235, 245, 0.6);
      }
    }

    #seasonResult_Table {
      thead {
        @media only screen and (max-width: 767px) {
          display: none;
        }

        tr {
          border-bottom: none;

          th {

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            // &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              text-align: center;
            }

            &:nth-child(6) {
              text-align: left;
            }

            &:first-child {
              border-top-left-radius: 10px;
              text-align: center;
            }

            &:last-child {
              border-top-right-radius: 10px;
            }

            &.ticket-info {
              text-align: left;
            }


          }
        }
      }

      tbody {
        tr {
          td {
            color: #ffffff;
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 100%;
            vertical-align: middle;
            height: 60px;

            @media only screen and(min-width: 1366px) and(max-width: 1919px) {
              height: 45px;
            }

            &:nth-child(2) {
              text-align: left;
            }

            &.rankNo {
              .rankBox {
                border-radius: 10px;
                display: flex;
                font-family: "Gilroy-Bold";
                font-size: 18px;
                height: 32px;
                justify-content: center;
                align-items: center;
                line-height: 100%;
                text-align: center;
                width: 32px;

                @media only screen and (min-width: 768px) {
                  margin: auto;
                }

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .redBox {
                background: #e52420;
                color: #fff;
              }

              .goldBox {
                background: #fda829;
              }

              .silverBox {
                background: #444444;
              }

              .bronzeBox {
                background: #d99246;
              }
            }

            &.action-td {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.playerDetails {
              .playerInfo {

                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                // @media only screen and (max-width: 374px){
                //   flex-direction: column;
                //   align-items: flex-end;
                // }
                .profile-info {
                  display: flex;
                  align-items: center;

                  img {
                    border: 2px solid #fff;
                    border-radius: 100%;
                    box-sizing: border-box;
                    height: 50px;
                    margin-right: 20px;
                    object-fit: cover;
                    width: 50px;
                  }

                  .playerInfo-inner {
                    display: flex;
                    flex-direction: column;

                    span {
                      color: gold !important;
                      font-size: 14px;
                      padding-top: 5px;
                    }
                  }
                }

                .streamer-sec {
                  img {
                    @media only screen and (max-width: 767px) {
                      margin-left: 5px;
                    }
                  }
                }
              }
            }

            // &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            // &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              text-align: center;
            }

            &.GB_device_td {
              &::before {
                position: relative;
                top: 0px;
              }
            }

            &:nth-child(6) {
              text-align: left;
            }

            &.ticket-details {
              .playerInfo {
                justify-content: center;
              }
            }

            .bet-in-progress {
              color: #ffffff;
              opacity: 0.6;
            }

            .bet-failed {
              color: #c80000;
            }

            .show-result-btn {
              background: #31ff1f;
              text-decoration: none;
              border-radius: 6px;
              padding: 1px 9px;
              line-height: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 23px;
              color: #000000;
              text-transform: uppercase;
              font-family: "Gilroy-Bold";
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }

    #streamerBetHistory_Table {
      thead {
        @media only screen and (max-width: 767px) {
          display: none;
        }

        tr {
          border-bottom: none;

          th {

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              text-align: center;
            }

            // &:nth-child(6) {
            //   text-align: left;
            // }

            &:first-child {
              border-top-left-radius: 10px;
              text-align: center;
            }

            &:last-child {
              border-top-right-radius: 10px;
            }

            &.ticket-info {
              text-align: left;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            color: #ffffff;
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 100%;
            vertical-align: middle;
            height: 60px;

            @media only screen and(min-width: 1366px) and(max-width: 1919px) {
              height: 45px;
            }

            &:nth-child(2) {
              text-align: left;
            }

            &.rankNo {
              .rankBox {
                border-radius: 10px;
                display: flex;
                font-family: "Gilroy-Bold";
                font-size: 18px;
                height: 32px;
                justify-content: center;
                align-items: center;
                line-height: 100%;
                text-align: center;
                width: 32px;

                @media only screen and (min-width: 768px) {
                  margin: auto;
                }

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .redBox {
                background: #e52420;
                color: #fff;
              }

              .goldBox {
                background: #fda829;
              }

              .silverBox {
                background: #444444;
              }

              .bronzeBox {
                background: #d99246;
              }
            }

            &.action-td {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.playerDetails {
              .playerInfo {

                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                // @media only screen and (max-width: 374px){
                //   flex-direction: column;
                //   align-items: flex-end;
                // }
                .profile-info {
                  display: flex;
                  align-items: center;

                  img {
                    border: 2px solid #fff;
                    border-radius: 100%;
                    box-sizing: border-box;
                    height: 50px;
                    margin-right: 20px;
                    object-fit: cover;
                    width: 50px;
                  }

                  .playerInfo-inner {
                    display: flex;
                    flex-direction: column;

                    span {
                      color: gold !important;
                      font-size: 14px;
                      padding-top: 5px;
                    }
                  }
                }

                .streamer-sec {
                  img {
                    @media only screen and (max-width: 767px) {
                      margin-left: 5px;
                    }
                  }
                }
              }
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              text-align: center;
            }

            .green-text {
              color: #31ff1f;
            }

            .red-text {
              color: #c80000;
            }

            &.GB_device_td {
              &::before {
                position: relative;
                top: 0px;
              }
            }

            // &:nth-child(6) {
            //   text-align: left;
            // }

            .bet-in-progress {
              color: #ffffff;
              opacity: 0.6;
            }

            .bet-failed {
              color: #c80000;
            }

            .show-result-btn {
              background: #31ff1f;
              text-decoration: none;
              border-radius: 6px;
              padding: 1px 9px;
              line-height: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 23px;
              color: #000000;
              text-transform: uppercase;
              font-family: "Gilroy-Bold";
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .pagination-full-section {
      margin: 40px 0px;

      @media only screen and (max-width: 767px) {
        margin: 20px 0px;
      }

      .paginate {
        margin: 0px 13px;
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid #4f4f4f;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 4px 4px;
        justify-content: center;
        align-items: center;

        ul {
          margin-bottom: 0px;
          align-items: center;
          justify-content: center;
          display: flex;
          position: relative;

          li {
            list-style-type: none;
            margin-right: 10px;

            a {
              font-family: "Gilroy-Bold";
              font-size: 18px;
              line-height: 100%;
              text-align: center;
              color: #ffffff;
              background-color: transparent;
              border: none;

              &:focus {
                box-shadow: none;
              }
            }

            &.previous {
              position: absolute;
              left: -61px;

              a {
                background: url(../images/rightarrow.png) 0px 0px no-repeat;
                height: 50px;
                width: 50px;
              }
            }

            &.next {
              position: absolute;
              right: -61px;

              a {
                background: url(../images/leftarrow.png) 0px 0px no-repeat;
                height: 50px;
                width: 50px;
              }
            }

            &.active {
              background: #e52420;
              border-radius: 8px;
              height: 42px;
              width: 42px;
              vertical-align: middle;
              align-items: center;
              justify-content: center;
              display: flex;
            }

            &:last-child {
              margin-right: 0px;
            }

            &:first-child {
              margin-right: 0px;
            }

            &:nth-last-child(2) {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

/*-------------------- SeasonResults Page End------------------*/
.modal {
  backdrop-filter: brightness(40%);
}

.join-popup-modal {
  .modal-content {
    width: 528px;
    height: auto;
    padding-bottom: 20px;
    background: #181818;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    border-bottom: none;

    @media only screen and (max-width: 767px) {
      height: 630px;
    }
  }

  .modal-header {
    color: #fff;
    padding: 25px;
    border-bottom: none;

    @media only screen and (max-width: 767px) {
      padding: 15px 20px 0px 0px;
    }

    .btn-close {
      color: #fff;
      outline: none;
      background: url(../images/popupclose.png) no-repeat center center;
    }
  }

  .modal-body {
    padding: 0 40px;

    @media only screen and (max-width: 767px) {
      padding: 0 25px;
    }

    .content-sect {
      .title {
        color: #fff;
        text-align: center;
        font-family: "Gilroy-Bold";
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 35px;

        @media only screen and (max-width: 767px) {
          font-size: 30px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }

      .balance-sec {
        margin-bottom: 15px;

        p {
          text-align: center;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 25px;
          color: #9c9ca3;
          margin-bottom: 5px;
        }

        .balance-box {
          background: #000000;
          border: 2px solid #2d2d2d;
          border-radius: 12px;
          display: flex;
          align-items: center;
          padding: 15px 30px;
          gap: 10px;

          @media only screen and (max-width: 767px) {
            padding: 15px;
          }

          @media only screen and (max-width: 374px) {
            justify-content: center;
          }

          img {
            width: 60px;
          }

          .claim-coins {
            .claimed-gbcoin {
              color: #ffc01f;
              font-family: "Gilroy-Bold";
              font-size: 22px;
              line-height: 25px;
            }

            .convert-coin {
              color: #40ab00;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
            }
          }
        }
      }

      .notification-sec {
        color: #9c9ca3;
        font-family: "Gilroy-Bold";
        font-size: 16px;
        line-height: 22px;
        text-align: center;

        // display: flex;
        // flex-direction: column;
        span {
          color: #ffffff;
        }

        .rulesTour {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .btn-sec {
        max-width: 417px;
        width: 100%;
        height: 56px;
        color: #fff;
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 20px;

        &:hover {
          opacity: 0.5;
        }

        @media only screen and (max-width: 767px) {
          margin: 15px auto 0 auto;
        }
      }

      .confirmBtn {
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }
      }

      .hidearrowcb {
        &::after {
          display: none;
        }

        span {
          padding-left: 10px;
        }
      }

      .cancelBtn {
        background-color: #181818;
      }
    }
  }
}

#mobileSidebar {
  .dropdown-item {
    &:hover {
      #dropdown-tournament {
        background-color: #000;
        border-color: #000;
      }
    }
  }

  .mobile-tournament {
    display: flex;
    flex-direction: column;
    padding: 10px;

    .mobilbgamelist {

      // margin-left: 30px;
      a {
        padding: 10px;
      }
    }

    img {
      margin-right: 10px;
      width: 30px !important;
      height: auto;
      image-rendering: -webkit-optimize-contrast;
    }

    .dropdown-menu {
      left: 60px !important;
    }
  }

  #dropdown-tournament {
    background-color: #181818;
    border-color: #181818;
    box-shadow: none;
    color: #979797;
    position: relative;

    &::after {
      position: absolute;
      right: -100px;
      bottom: 10px;
      vertical-align: middle;
      content: url("../images/dd_down.png") !important;
    }
  }

  .nav-link {
    #dropdown-tournament {
      padding: 0;
      font-size: 16px;
      color: #979797 !important;
      font-family: "Gilroy-SemiBold";

      .dropdown-menu {
        left: 60px !important;
      }
    }
  }
}

.entranceMsg {
  margin-top: 15px;

  .alert-success {
    background: green;
    color: #fff;
    border: none;
    text-align: center;
    font-family: "Poppins-SemiBold";
  }

  .alert-danger {
    background: red;
    color: #fff;
    border: none;
    text-align: center;
    font-family: "Poppins-SemiBold";
  }

  .resendcompeteemail {
    text-decoration: underline;
    cursor: pointer;
  }
}

.welcome-tour-modal {
  .modal-header {
    border-bottom: none;

    .btn-close {
      color: #fff;
      outline: none;
      background: url(../images/popupclose.png) no-repeat center center;
    }
  }

  .modal-content {
    background: #181818;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    border-bottom: none;

    @media only screen and (max-width: 767px) {
      height: 630px;
    }

    .welcome-head-img {
      .wel-1 {
        width: 100px;
        padding: 10px;
      }

      .wel-2 {
        width: 200px;
        padding: 10px;
      }
    }

    .welcontent {
      padding-top: 20px;
      padding-bottom: 20px;

      p {
        text-align: center;
        font-family: "Gilroy-Bold";
        font-size: 18px;
        line-height: 25px;
        color: #fff;
        margin-bottom: 5px;
      }

      .btn-sec {
        max-width: 250px;
        width: 100%;
        height: 56px;
        color: #fff;
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 20px auto;

        &:hover {
          opacity: 0.5;
        }

        @media only screen and (max-width: 767px) {
          margin: 15px auto 0 auto;
        }
      }

      .confirmBtn {
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }
      }
    }
  }
}

// Home page re-do

.available_details {
  .items_inner {
    position: relative;

    .available_coming {
      position: absolute;
      top: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-family: "Poppins-Bold";
      font-size: 30px;
      line-height: 30px;
      color: #fff;
      width: 100%;
      text-align: center;

      @media only screen and (max-width: 767px) {
        font-size: 17px;
      }

      @media only screen and (min-width: 768px) and (max-width: 1199px) {
        font-size: 20px;
      }
    }
  }
}

._GB_TopEarners {
  .earner_left {
    h2 {
      font-size: 58px !important;
      line-height: 70px !important;
      font-family: "Poppins-Bold" !important;

      @media only screen and (max-width: 767px) {
        font-size: 28px !important;
        line-height: 38px !important;
        text-align: center !important;
      }

      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 27px !important;
        line-height: 60px !important;
        text-align: left !important;
      }

      @media only screen and (min-width: 1025px) and (max-width: 1199px) {
        font-size: 46px !important;
      }

      span {
        color: #ff0404;
      }

      .Toplogo_img {
        vertical-align: -4px;

        @media only screen and (max-width: 767px) {
          width: 50%;
        }
      }
    }
  }

  .mob_viewgames {
    @media only screen and (max-width: 991px) {
      text-align: center;
      display: block !important;
    }

    @media only screen and (min-width: 320px) {
      gap: 20px;
    }

    .hand_out {
      height: 56px;
      color: #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border-radius: 10px;
      border: 2px solid #ffffff;
      font-family: "Poppins-Bold";
      font-size: 17px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding: 18px 35px;
      text-decoration: none;

      @media only screen and (min-width: 768px) and (max-width: 991px) {
        padding: 18px 9px;
      }

      &:after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .mob-earners {
    @media only screen and (min-width: 768px) and (max-width: 1365px) {
      flex: auto;
    }

    @media only screen and (max-width: 1365px) {
      display: flex;
      justify-content: center;
    }

    @media only screen and (max-width: 374px) {
      display: block;
    }
  }
}

// .slick-prev:before{
//     background-image: url("../images/news-arow.png");
// }
// .slick-next:before{
//   background-image: url("../images/news-arow.png");
// }
.GB_BettingHistory {

  // @media only screen and (min-width: 1920px){
  //     max-width: 1920px;
  //     margin: auto;
  // }
  .GB_BettingScoreboardinner {

    //  @media only screen and (min-width: 1920px) {
    //   display: block;
    // }
    @media only screen and (max-width: 767px) {
      display: block;
    }

    @media only screen and (min-width: 1025px) {
      display: flex;
    }

    padding-top: 34px;

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      padding-top: 9px;
    }

    .column-right {
      .GB_BettingScoreboardtable {
        padding: 5px 25px;

        @media only screen and (max-width: 767px) {
          padding: 0px 25px;
        }

        @media only screen and (min-width: 1366px) and (max-width: 1919px) {
          padding: 5px 10px;
        }

        td {
          @media only screen and (max-width: 480px) {
            font-size: 11px !important;
          }
        }

        .title {
          display: flex;
          gap: 10px;
          padding: 20px 0px;
          align-items: center;

          @media only screen and (max-width: 767px) {
            justify-content: center;
            padding: 0px 0px 20px 0px;
          }

          h2 {
            font-size: 40px;
            line-height: 46px;
            font-family: "Gilroy-Bold";
            color: #fff;

            @media only screen and (max-width: 767px) {
              font-size: 28px;
              line-height: 46px;
            }
          }
        }

        .GB_device_th {
          font-size: 16px;
          line-height: 16px;
          font-family: "Gilroy-Bold";
          color: rgba(235, 235, 245, 0.6);
        }

        .playerDetails {
          .playerInfo {
            display: flex;
            align-items: center;
          }
        }

        .pointDetails {
          color: #ffc01f !important;

          .playerInfo {
            @media only screen and (max-width: 767px) {
              display: block;
            }

            img {
              margin-right: 10px;
            }
          }
        }

        .kills {
          color: #31ff1f !important;

          .playerInfo {
            @media only screen and (max-width: 767px) {
              display: block;
            }

            img {
              margin-right: 10px;
            }
          }
        }
      }

      .GB_Chat {
        padding: 25px;

        .bets_first {
          .stream-live {
            .chat_image {
              width: 100%;

              @media only screen and(min-width: 1300px) {
                height: 828px;
                object-fit: cover;
                border: 2px solid #2d2d2d;
                border-radius: 20px;
              }
            }
          }

          @media only screen and(max-width: 1299px) {
            width: 100%;
          }
        }

        .bets_sec {
          border: 2px solid #2d2d2d;
          border-radius: 20px;
          background: #181818;
          padding: 0;

          @media only screen and(max-width: 1299px) {
            width: 100%;
            max-width: 395px;
            margin: 30px auto;
          }

          .place_Bets {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 20px 9px 20px;

            @media only screen and(max-width: 374px) {
              padding: 13px 10px 9px 10;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 13px 10px 9px 10;
            }

            h3 {
              font-size: 18px;
              line-height: 22px;
              font-family: "Gilroy-Bold";
              color: #fff;
              margin: 0;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }

            .place_Betsright {
              background: #0c0c0c;
              padding: 3px 13px;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              border-color: #000000;

              img {
                margin-right: 10px;
              }

              span {
                font-size: 16px;
                line-height: 18px;
                font-family: "Gilroy-Bold";
                color: #ffffff;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }
              }
            }

            .dropdown-menu {
              min-width: 93px !important;
              transform: translate(0px, 34.5556px) !important;
              width: 100%;

              .dropdown-item {
                margin-right: 0px;

                img {
                  margin: 0px 5px 0px 8px;
                }
              }
            }
          }

          .bets_profile {
            display: flex;
            justify-content: space-between;
            padding: 8.5px 30px;
            border-top: 1px solid #2d2d2d;
            align-items: center;

            @media only screen and(max-width: 374px) {
              padding: 8.5px 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 8.5px 10px;
            }

            .bets_profileleft {
              display: flex;
              align-items: center;
              gap: 10px;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                gap: 5px;
              }

              p {
                font-size: 14px;
                line-height: 16px;
                font-family: "Gilroy-Bold";
                color: rgba(235, 235, 245, 0.6);
                margin: 0;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }

                .no {
                  color: #e52420;
                }

                .yes {
                  color: #52dd21;
                }
              }
            }

            .bets_profileright {
              img {
                margin-right: 10px;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  margin-right: 5px;
                }
              }

              span {
                font-size: 14px;
                line-height: 16px;
                font-family: "Gilroy-Bold";
                color: #ffc01f;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }
              }
            }
          }

          .bets_win {
            padding: 12px 30px;
            border-top: 1px solid #2d2d2d;

            @media only screen and(max-width: 374px) {
              padding: 12px 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 12px 10px;
            }

            .win_left {
              font-size: 14px;
              line-height: 16px;
              font-family: "Gilroy-Bold";
              color: #ffc01f;
              display: flex;
              gap: 5px;
              align-items: center;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }

              span {
                color: #52dd21;
                cursor: pointer;
              }
            }

            p {
              font-size: 14px;
              line-height: 16px;
              font-family: "Gilroy-Bold";
              color: rgba(235, 235, 245, 0.6);
              margin-top: 3px;
              margin-bottom: 0px;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }
          }

          .bets_alert {
            padding: 11px 30px;
            border-top: 1px solid #2d2d2d;

            @media only screen and(max-width: 374px) {
              padding: 11px 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 11px 10px;
            }

            p {
              font-size: 14px;
              line-height: 16px;
              font-family: "Gilroy-Bold";
              color: #e52420;
              margin: 0;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }
          }

          .bets_Timer {
            background-color: #52dd21;
            padding: 7px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media only screen and(max-width: 374px) {
              padding: 7px 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 7px 10px;
            }

            .bets_count {
              ul {
                // text-align: center;
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                font-size: 29px;
                line-height: 33px;
                font-family: "Gilroy-Bold";

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 15px;
                }

                li {
                  display: inline-block;
                  list-style-type: none;
                  font-size: 10px;
                  line-height: 11px;
                  font-family: "Gilroy-Bold";
                  color: #000000;
                  padding: 0px 3px;

                  @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                    font-size: 8px;
                  }

                  span {
                    display: block;
                    font-size: 29px;
                    line-height: 33px;
                    font-family: "Gilroy-Bold";
                    color: #000000;

                    @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                      font-size: 21px;
                    }

                    @media only screen and(max-width: 375px) {
                      font-size: 25px;
                    }
                  }
                }
              }
            }

            .bets_Timeright {
              .bets_Timersub {
                font-size: 13px;
                line-height: 20px;
                font-family: "Poppins-SemiBold";
                color: #000000;
                margin: 0;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 10px;
                }

                @media only screen and(max-width: 375px) {
                  font-size: 10px;
                }
              }

              .bets_Timersupersub {
                font-size: 10px;
                line-height: 10px;
                font-family: "Poppins-SemiBold";
                color: #000000;
                margin: 0;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 8px;
                }

                @media only screen and(max-width: 375px) {
                  font-size: 8px;
                }
              }
            }
          }

          .bets_placingbtn {
            text-align: center;
            border-top: 1px solid #2d2d2d;

            a {
              background-color: #e52420;
              background-image: linear-gradient(90deg,
                  #6d1513,
                  #e52420,
                  #6d1513);
              border: 2px solid #fff;
              border-radius: 10px;
              color: #fff;
              cursor: pointer;
              font-family: "Poppins-Bold";
              font-size: 17px;
              letter-spacing: 0.04em;
              line-height: 17px;
              margin: auto;
              padding: 17px 10px;
              text-align: center;
              text-decoration: none;
              text-transform: uppercase;
              width: 100%;
              max-width: 178px;
              margin: auto;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }

              &:after {
                content: url("../images/arrow.png");
                padding-left: 10px;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }

          .bets_progressbar {
            background-color: #000000;
            padding: 17px 20px;
            margin: 14px 20px 18px 20px;
            border-radius: 10px;

            @media only screen and(max-width: 374px) {
              padding: 17px 10px;
              margin: 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 17px 10px;
              margin: 10px;
            }

            h4 {
              font-size: 16px;
              line-height: 18.75px;
              font-family: "Gilroy-Bold";
              color: #ffffff;
              text-align: center;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }

            .progress {
              background-color: #e52420;
              height: 5px;

              .progress-bar {
                background-color: #52dd21;
              }
            }

            .progressbar_Top {
              font-size: 13px;
              line-height: 13px;
              font-family: "Poppins-SemiBold";
              color: #52dd21;
              display: flex;
              justify-content: space-between;
              padding-bottom: 5px;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }

              span {
                color: #e52420;
              }
            }

            .progressbar_bottom {
              display: flex;
              justify-content: space-between;
              padding-top: 5px;

              .progressbar_bottomleft {
                font-size: 13px;
                line-height: 13px;
                font-family: "Poppins-SemiBold";
                color: #97979d;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }

                span {
                  color: #52dd21;
                }
              }

              .progressbar_bottomright {
                font-size: 13px;
                line-height: 13px;
                font-family: "Poppins-SemiBold";
                color: #97979d;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }

                span {
                  color: #e52420;
                }
              }
            }
          }

          .bets_feed {
            display: flex;
            justify-content: space-between;
            padding: 0px 20px 12px 20px;
            align-items: center;

            @media only screen and(max-width: 374px) {
              padding: 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 10px;
            }

            .bets_feedleft {
              font-size: 20px;
              line-height: 23.44px;
              font-family: "Gilroy-Bold";
              color: #ffffff;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }

            .bets_feedright {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              align-items: center;

              .bets_feedrightnumber {
                font-size: 14px;
                line-height: 14px;
                font-family: "Gilroy-Bold";
                color: #ffffff;
                background-color: #000000;
                padding: 7px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                gap: 7px;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }

                img {
                  cursor: pointer;
                }

                span {
                  cursor: pointer;
                }
              }

              .bets_feedrightpeople {
                font-size: 14px;
                line-height: 13px;
                font-family: "Gilroy-Bold";
                color: #ffffff;
                background-color: #000000;
                padding: 7px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                gap: 7px;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }
              }
            }
          }

          .needScroll {
            height: 450px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 10px;

              @media only screen and (max-width: 767px) {
                width: 5px;
              }
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #313131;
              border-radius: 6px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #535353;
              border-radius: 6px;
              max-height: 131px !important;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #535353;
            }
          }
        }
      }
    }

    &.GB_BettingScoreboardinnersummary {
      .betting-left-container {
        @media only screen and (min-width: 1366px) {
          position: absolute;
          top: auto;
        }
      }

      @media only screen and (min-width: 1366px) {
        display: block !important;
      }

      @media only screen and(min-width: 1366px) and(max-width: 1600px) {
        padding-top: 9px;
      }
    }

    //betting summary remove next day
    &.cymmexpanded {
      .challenge-megamenu {
        @media only screen and (min-width: 1360px) and (max-width: 1919px) {
          position: absolute;
          width: 100%;
        }

        .challenge-right {
          @media only screen and (min-width: 1360px) and (max-width: 1919px) {
            padding-left: 280px;
          }
        }
      }
    }

    &.cymmcollapsed {
      .challenge-megamenu {
        @media only screen and (min-width: 1360px) and (max-width: 1919px) {
          position: absolute;
          width: 100%;
        }

        .challenge-right {
          @media only screen and (min-width: 1360px) and (max-width: 1919px) {
            padding-left: 140px;
          }
        }
      }
    }
  }
}

.alreadyJoined {
  padding: 20px;
}

._GB_LeaderBoard_ADM {
  .user-details-lb {
    display: flex;
    align-items: center;

    .uslbername {
      padding-right: 15px;
    }
  }

  .lbtimer {
    font-family: "Gilroy-Bold";
    font-size: 18px;
    line-height: 100%;
    margin-bottom: 20px;
    color: gold !important;
  }

  .actwrap {
    padding-left: 5px;

    .labelText {
      line-height: 20px;
    }
  }

  .adm_lb_left {
    text-align: left !important;
  }

  .adm_lb_center {
    text-align: center !important;
  }

  .rank_image {
    padding-left: 0 !important;
  }

  .rankBox {
    border-radius: 10px;
    display: flex;
    font-family: "Gilroy-Bold";
    font-size: 18px;
    height: 32px;
    justify-content: center;
    align-items: center;
    line-height: 100%;
    text-align: center;
    width: 32px;

    @media only screen and (min-width: 768px) {
      margin: auto;
    }
  }

  .redBox {
    background: #e52420;
    color: #fff;
  }

  .goldBox {
    background: #fda829;
  }

  .silverBox {
    background: #444444;
  }

  .bronzeBox {
    background: #d99246;
  }
}

.customrakImg {
  background: #e52420;
  color: #fff;
  padding: 20px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 34px;
  height: 30px;
  top: 0px !important;
  left: 0px !important;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy-Bold";
  font-size: 16px;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  display: block;
}

.connect_wallet_popup {
  min-height: 300px;
}

.modal-dialog.modal-lg.connect_popup {
  padding-top: 45px;
}

.wallet-adapter-modal-container {
  .wallet-adapter-modal-list {
    .wallet-adapter-button {
      font-weight: 400;
      border-radius: 10px;
      font-size: 18px;
      border: 2px solid #454545 !important;
    }
  }

  .wallet-adapter-modal-wrapper {
    max-width: 100%;
  }
}

.lbtokend {
  text-align: right;
  padding: 5px 0;
}

.welcontent span.addons-earnings {
  cursor: default !important;
}

.GB_Bettingtitles {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    width: 100%;
    max-width: 768px;
    margin: auto;
  }

  // @media only screen and (min-width: 768px) and (max-width: 991px) {
  //   justify-content: space-around;
  // }
  .summary_logo {
    display: flex;
  }

  .title {
    padding: 0 !important;
  }

  .titles {
    h2 {
      font-size: 40px;
      line-height: 46px;
      font-family: "Gilroy-Bold";
      color: #fff;

      @media only screen and (max-width: 991px) {
        font-size: 20px;
      }

      @media only screen and(min-width: 1366px) and(max-width: 1919px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }

  .titles_games {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 767px) {
      justify-content: center;
    }

    @media only screen and (max-width: 991px) {
      justify-content: space-between;
      width: 100%;
    }

    .btn.show {
      background: transparent;
      border: none;
    }

    img {
      width: 50px;
      height: 50px;
      border: 2px solid #484848;
      border-radius: 8px;
      cursor: pointer;
      padding: 4px;
      margin: 2px;

      &:hover {
        background: rgba(200, 0, 0, 0.5);
        border-color: #c80000;
      }

      &.active {
        background: rgba(200, 0, 0, 0.5);
        border-color: #c80000;
      }

      &.summary_filter {
        margin-left: 15px;
        padding: 12px;
      }

      @media only screen and(min-width: 1366px) and(max-width: 1919px) {
        width: 40px;
        height: 40px;
      }
    }

    .qrcode {
      width: auto;
      height: auto;
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 0;
    }

    .qrcode-item {
      p {
        margin-bottom: 0;
      }
    }

    .dropdown-menu {
      transform: translate(-93px, 68px) !important;

      @media only screen and(max-width: 991px) {
        transform: translate(0px, 64px) !important;
      }
    }

    .dropdown-item {
      padding: 8px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.active {
        background: #c80000;
        border-radius: 14px;
      }
    }

    .btn-primary {
      background: transparent !important;
      padding: 0;
    }

    .summary_logofilter {
      .dropdown {
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.GB_BettingSummary {
  padding: 5px 25px;

  @media only screen and (min-width: 1920px) {
    padding: 16px 0;
  }

  @media only screen and (max-width: 991px) {
    padding: 0px 20px 14px 20px;
    margin-bottom: 0px;
  }

  @media only screen and(min-width: 1366px) and(max-width: 1919px) {
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  margin-bottom: 30px;

  .box_wh {
    border-bottom: 1px solid #2d2d2d;
    padding-bottom: 30px;

    @media only screen and(min-width: 1366px) and(max-width: 1919px) {
      padding-bottom: 15px;
    }

    @media only screen and (max-width: 767px) {
      display: block;
    }

    @media only screen and (max-width: 991px) {
      border-bottom: 0px solid #2d2d2d;
      padding-bottom: 0px;
    }

    .sum_mob {
      @media only screen and (max-width: 991px) {
        width: 100%;
        max-width: 100%;
        margin: 10px auto;
      }

      .items_inner {
        background: #181818;
        border: 2px solid #2d2d2d;
        border-radius: 20px;
        width: 100%;
        padding: 25px;
        min-height: 322px;
        justify-content: center;

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          min-height: 230px;
          padding: 15px 20px;
          justify-content: space-between;
        }

        @media only screen and (max-width: 991px) {
          min-height: 84px;
        }

        @media only screen and (min-width: 992px) and (max-width: 1316px) {
          min-height: 340px;
        }

        @media only screen and (max-width: 991px) {
          flex-direction: row !important;
          padding: 5px;
          max-width: 768px;
          margin: auto;
          justify-content: space-between;
          padding: 5px 20px;
        }
      }

      .desc_sec {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        min-height: 200px;
        justify-content: space-between;

        @media only screen and (max-width: 991px) {
          flex-direction: row !important;
          min-height: auto;
          margin: 0;
          text-align: left;
        }

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          min-height: 100px;
          justify-content: center;
          margin-bottom: 12px;
        }

        img {
          height: 68px;
          margin-bottom: 20px;
          width: 68px;

          @media only screen and (max-width: 991px) {
            height: 38px;
            width: 38px;
            margin: 0;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            height: 46px;
            width: 46px;
            margin-bottom: 14px;
          }
        }

        .title {
          font-family: "Gilroy-SemiBold";
          font-size: 20px;
          line-height: 24px;
          color: #fff;

          @media only screen and (min-width: 1025px) and (max-width: 1050px) {
            font-size: 16px;
          }

          @media only screen and (max-width: 991px) {
            font-size: 12px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 14px;
            margin-bottom: 7px;
          }
        }

        .tokens {
          font-family: "Gilroy-SemiBold";
          font-size: 30px;
          line-height: 36px;
          color: #fff;
          margin: 5px;

          @media only screen and (max-width: 991px) {
            font-size: 16px;
            line-height: 10px;
            margin: 0px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 24px;
            line-height: 13px;
          }
        }

        .token_dollar {
          font-family: "Gilroy-SemiBold";
          font-size: 30px;
          line-height: 36px;
          color: #eca418;

          @media only screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 24px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 14px;
          }
        }
      }

      .game_drop {
        background-color: #050505;
        border-radius: 16px;
        border-color: #050505;
        color: #fff;
        padding: 10px 15px;
        font-family: "Gilroy-SemiBold";
        font-size: 20px;
        line-height: 24px;
        width: 90%;

        @media only screen and (min-width: 1025px) and (max-width: 1050px) {
          width: 100%;
        }

        @media only screen and (max-width: 991px) {
          padding: 2px 9px;
          font-size: 12px;
          width: 100%;
          max-width: 110px;
        }

        @media only screen and (min-width: 1366px) and (max-width: 1600px) {
          padding: 10px 10px;
          font-size: 12px;
        }
      }
    }
  }
}

.mob_tokens {
  @media only screen and (max-width: 991px) {
    margin-left: 20px;
  }
}

._GB_bettingsummarytop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 25px;

  @media only screen and (max-width: 1024px) {
    padding: 0px 0px;
  }

  @media only screen and (min-width: 1920px) {
    padding: 16px 0;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    padding: 16px 10px 0px;
  }

  gap: 20px;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
  }

  .bettingsummary_topleft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background: #121212;
    border: 2px solid #2d2d2d;
    border-radius: 10px;
    width: 100%;
    padding: 2px 3px;
    margin-bottom: 10px;

    @media only screen and (max-width: 991px) {
      display: block;
      text-align: left;
      max-width: 768px;
      padding: 6px;
      width: 90% !important;
    }

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    // @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    //   padding: 0px 3px;
    //   // margin-bottom: 0px;
    // }
    &.topleft_success {
      background: #52dd21;
    }

    .bettingsummary_topleftonemain {
      .bettingsummary_topleftone {
        font-family: "Gilroy-Bold";
        font-size: 18px;
        line-height: 18px;

        @media only screen and (min-width: 992px) and (max-width: 1260px) {
          font-size: 14px;
        }

        @media only screen and (min-width: 1366px) and (max-width: 1600px) {
          font-size: 14px;
          line-height: 15px;
        }

        @media only screen and (max-width: 991px) {
          font-size: 12px;
        }

        &.one_success {
          color: #000000;
        }

        .bettingsummary_topleftonesub {
          display: flex;
          gap: 10px;
          align-items: center;

          @media only screen and (max-width: 991px) {
            justify-content: left;
            padding: 0px;
            max-width: 100%;
            margin: auto;
          }

          img {
            @media only screen and (min-width: 1366px) and (max-width: 1919px) {
              height: 26px;
            }
          }

          p {
            margin: 0;

            @media only screen and (max-width: 991px) {
              line-height: 14px;
            }

            span {
              font-family: "Gilroy-SemiBold";
              font-size: 14px;
              line-height: 14px;

              @media only screen and (max-width: 991px) {
                justify-content: left;
                padding: 0px;
                font-size: 10px;
                //max-width: 350px;
                //margin: auto;
              }

              @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                font-size: 12px;
                line-height: 12px;
              }
            }

            @media only screen and (max-width: 374px) {
              zoom: 83%;
            }
          }
        }
      }
    }

    .bettingsummary_toplefttwomain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 50%;

      @media only screen and (max-width: 991px) {
        // display: block;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }

      @media only screen and (max-width: 374px) {
        zoom: 80%;
      }

      .bettingsummary_toplefttwo {
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 15px;

        @media only screen and (max-width: 991px) {
          font-size: 10px;
          font-family: "Gilroy-Bold";
        }

        &.two_success {
          color: #000000;
        }

        @media only screen and (min-width: 992px) and (max-width: 1260px) {
          font-size: 12px;
        }

        @media only screen and (min-width: 1301px) and (max-width: 1600px) {
          font-size: 12px;
        }
      }

      .bettingsummary_topleftthree {
        font-family: "Gilroy-Bold";
        font-size: 16px;
        line-height: 17px;
        background-color: #000000;
        padding: 5px 10px 5px 7px;
        border-radius: 15px;

        @media only screen and (max-width: 991px) {
          font-size: 12px;
        }

        // &.three_success {
        //   opacity: 0.7;
        // }

        @media only screen and (min-width: 992px) and (max-width: 1260px) {
          font-size: 10px;
        }

        @media only screen and (min-width: 1301px) and (max-width: 1400px) {
          font-size: 12px;
        }

        img {
          margin-right: 6px;
        }

        @media only screen and (max-width: 991px) {
          max-width: 110px;
          margin: 10px auto;
        }

        @media only screen and (max-width: 991px) {
          margin: 0px auto;
        }
      }

      .bettingsummary_topleftfour {
        .result_btn {
          max-width: 232px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 10px 22px;
          text-decoration: none;
          margin-left: 15px;

          @media only screen and (max-width: 991px) {
            font-size: 10px;
            padding: 5px 6px;
            margin-left: 0px;
          }

          @media only screen and (min-width: 1366px) and (max-width: 1600px) {
            font-size: 11px;
            padding: 10px 12px;
          }

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;

            @media only screen and (max-width: 991px) {
              position: relative;
              top: 2px;
            }
          }

          &:hover {
            opacity: 0.6;
          }

          &.four_success {
            background-color: #000000;
            background-image: none;
            border: none;

            &:hover {
              opacity: 0.6;
            }

            @media only screen and (min-width: 992px) and (max-width: 1919px) {
              font-size: 11px !important;
              padding: 6px 9px !important;
            }
          }

          @media only screen and (min-width: 992px) and (max-width: 1260px) {
            font-size: 11px;
            padding: 11px 8px;
          }

          @media only screen and (min-width: 1301px) and (max-width: 1400px) {
            font-size: 11px;
            padding: 9px 12px;
          }
        }
      }

      &.right_zero {
        justify-content: end;

        @media only screen and (max-width: 991px) {
          justify-content: center;
        }
      }
    }
  }

  &.sp-summarymob {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }

  &.summarytop_desk {
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.betting_right_inner {
  // @media only screen and (min-width: 1500px) {
  //   max-width: 1430px;
  // }

  @media only screen and (min-width: 1921px) {
    // margin: auto;
  }

  //  &.collapsed{
  //   margin: auto;
  //  }
}

.summary_drop {
  font-size: 0;
  background: transparent;
  border: none;

  &:hover {
    background: transparent;
    border: none;
  }
}

.summary_Extent {
  margin-bottom: 90px;

  .seasonResult_table {
    padding: 40px 33px 40px;

    @media only screen and (max-width: 991px) {
      padding: 20px;
      width: 100%;
      max-width: 768px;
      margin: auto;
    }

    @media only screen and(min-width: 1366px) and(max-width: 1919px) {
      padding: 20px 33px 20px;
    }

    .table-dark {
      background: #121212;
      --bs-table-bg: #1e1e1e;
      --bs-table-striped-bg: #161616;
      --bs-table-border-color: #000000;
      margin: 0;

      thead {
        border-style: hidden;
      }
    }
  }

  @media only screen and (min-width: 1920px) {
    padding: 0px 0px !important;
  }

  .title {
    h2 {
      @media only screen and (max-width: 991px) {
        font-size: 20px !important;
      }

      img {
        @media only screen and (max-width: 991px) {
          width: 13%;
        }
      }

      @media only screen and(min-width: 1366px) and(max-width: 1919px) {
        font-size: 30px !important;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 0px 20px !important;
  }
}

.desc_four {
  .items_inner {
    justify-content: center !important;

    @media only screen and (max-width: 991px) {
      justify-content: left !important;
    }
  }
}

.challenge_tops {
  .mob_sidebar {
    margin-top: 56px !important;

    @media only screen and(min-width: 768px) and (max-width: 1024px) {
      margin-top: 80px !important;
    }
  }

  .GB_createChallenge {
    @media only screen and (max-width: 991px) {
      padding: 30px 20px !important;
    }
  }
}

.disputeButton {
  border: solid 1px #31ff1f;
  color: #777;
  padding: 4px 12px;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;

  &.resolved {
    border: solid 1px #31ff1f !important;
    background: #31ff1f;
    color: #000 !important;
    cursor: default;
  }

  &.placed {
    border: solid 1px #ffc01f !important;
    background: #ffc01f;
    color: #000 !important;
    cursor: default;
  }
}

#dispute-tooltip {
  font-family: "Gilroy-Regular";
  font-size: 13px;
  max-width: 235px;
}

.available_details {
  .items_inner {
    position: relative;

    &:hover {
      cursor: pointer;
    }

    &.cs-games {
      img {
        opacity: 0.6;
      }

      .available_coming {
        position: absolute;
        top: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-family: "Poppins-Bold";
        font-size: 30px;
        line-height: 30px;
        color: #fff;
        width: 100%;
        text-align: center;

        @media only screen and (max-width: 767px) {
          font-size: 17px;
        }

        @media only screen and (min-width: 768px) and (max-width: 1199px) {
          font-size: 20px;
        }

        display: none;
      }

      &:hover {
        .available_coming {
          display: block;
        }
      }
    }
  }
}