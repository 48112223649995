
._GB_rTournaments{
  .common_wrapper{
    @media only screen and (min-width: 768px) {
      background-image: url("../../images/redo/tournament_bg.png") !important;
      background-repeat: no-repeat !important;
      background-position: 100% 30%;
      background-size: 110%;
      animation: wave 10s linear infinite alternate;
    }
    .rtournaments_wrap{
      @media only screen and (max-width: 767px) {
        background-image: none !important;
      }
     

    }

      background-color:  #0E1216;
      
  }
}
._GB_Redo_Tour_Main{
  position: relative;
  top: 35px;
  @media only screen and (max-width: 1024px) {
    top: 0px;
  }
  .common_Inner{
    @media only screen and (max-width: 1400px) {
      max-width: 100% !important;
    }
  }
}
._GB_Redo_Main_Content{
  
    // .rtournament_inner{
      // background-image: url("../../images/redo/tournament_bg.png") !important;
      // background-repeat: no-repeat !important;
      // background-position: top center;
      // background-size: 100%;
      // background-color: #0E1216;

      
    // }
    .rtournaments_wrap {
        border: 1px solid rgba(59, 77, 94, 0.5);
        border-top-left-radius: 18px;
        padding: 50px 30px;
        border-bottom: none;
        opacity: var(--Corner-radius-xs, 1);
        position: relative;
        @media only screen and (min-width: 1600px){
            padding: 50px 0;
        }
        @media only screen and (max-width: 767px){
          padding: 16px 20px 50px;
        }
        @media only screen and (max-width: 1024px) {
          border: none;
        }
        // background-image: url("../images/blastbanner.png");
        
        &.dota2 {
            background-image: url("../../images/redo/Dota2_single_bg.png");
            background-position: top right;
            background-repeat: no-repeat;
        }
      
        &.pubg {
          background-image: url("../../images/redo/pubg_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
    
        &.pubg_normal_squad_fpp {
          background-image: url("../../images/redo/pubg_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
    
        &.pubg_normal_squad_tpp {
          background-image: url("../../images/redo/pubg_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
    
        &.pubg_ranked_squad {
          background-image: url("../../images/redo/pubg_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
    
        &.dota2_captain {
          background-image: url("../../images/redo/dota_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
      
        &.warzone {
          background-image: url("../../images/warzone_background.png");
          background-position: top right;
          background-repeat: no-repeat;
        }
      
        &.aoe4 {
          background-image: url("../../images/redo/aoeII_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
    
        &.aoe2 {
          background-image: url("../../images/redo/aoeIV_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
    
        &.starcraft2 {
          background-image: url("../../images/redo/starcraft_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
    
        &.tf2 {
          background-image: url("../../images/redo/tft_banner.png");
          background-position: top right;
          background-repeat: no-repeat;
          background-size: 150%;
        }
        .rtournaments_wrap_inner {
            max-width: 1276px;
            width: 100%;
            margin: auto;
        }
    .rt_top_head {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 600px){
          flex-direction: column;
          gap:25px;
        }
        .tour_tab{
          display: flex;
          flex-wrap: wrap;
          padding: 8px;
          border: 1.5px solid #3b4d5e80;
          border-radius: 20px;
          gap: 16px;
          align-items: center;
          @media only screen and (max-width: 600px){
            justify-content: space-around;
          }
          span.tab_border {
            width: 3px;
            height: 18px;
            background: #5d7893;
        }
          a.rt_title{
            color: #5D7893;
            padding: 16px 32px;
            line-height: 18px;
            text-decoration: none;
            @media only screen and (max-width: 767px){
              padding: 16px 0px;
              font-size: 16px;
              margin-bottom: 0px;
            }
            &:hover{
              opacity: 0.5;
            }
            &.active{
              color:#fff;
              font-family: "Gilroy-Bold";
              position: relative;
              &::before {
                content: url("../../images/redo/tab_sel-icon.png");
                bottom: -29px;
                left: 41%;
                position: absolute;
                animation: pulseAnimation 2s infinite;
                border-radius: 50%;
                height:32px;
                @media only screen and (max-width: 767px) {
                  bottom: -29px;
                }
              }
            }
          }

        }
        .rt_title {
                color: #FFF;
                font-family: "Gilroy-Medium";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                @media only screen and (max-width: 400px){
                  text-align: center;
                  margin-bottom: 10px;
                }
            }
        
            .rt_addon {
              cursor: pointer;
              display: flex;
              padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
              justify-content: center;
              align-items: center;
              gap: var(--Spacing-sm, 8px);
              border-radius: var(--Spacing-xl, 20px);
              background: var(--Primary-Green---50---GB, #F1FEEC);
              box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.04), 0px 24px 48px -8px rgba(241, 254, 236, 0.12);
              color: var(--Background---grey-Grey---950---GB, #020303);
              font-family: "Gilroy-Medium";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              margin-right: 20px;
              max-height: 48px;
              &:hover{
                opacity: 0.5;
              }
              @media only screen and (max-width: 767px){
                font-size: 12px;
                img{
                  max-width: 20px;
                }
              }
            }
            .mobile_filter_part{
               display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              
              .rt_filter_btn{
                @media only screen and (min-width: 768px){
                  display: none;
                }
                cursor: pointer;
                color: #FFF;
                font-family: "Gilroy-Medium";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                display: flex;
                align-items: center;
                gap: 5px;

              }
            }

    }

        
    
        .rt_list_section {
            display: flex;
            flex-wrap: wrap;
            margin-top: 24px;
            gap: 20px;
            @media only screen and (min-width: 1365px){
                gap: 15px;
            }

            .rt_filter {
                flex: 0 0 20%;
                max-width: 20%;
                color: #fff;
                @media only screen and (max-width: 767px){
                  display: none;
                }
                @media only screen and (max-width: 1365px){
                  flex: 0 0 28%;
                  max-width: 28%;
                }

                .rt_filter_inner {
                    border-radius: var(--Spacing-3xl, 32px);
                    opacity: var(--Corner-radius-xs, 1);
                    background: var(--Background---grey-Grey---600---GB, #141A1F);
                    padding: 20px;
                    height: 100%;
                    @media only screen and (max-width: 1600px){
                        padding: 15px;
                    }
                    .rt_filter_head {
                        display: flex;
                        justify-content: space-between;
    
                        .rt_filter_left {
                            color: #FFF;
                            font-family: "Gilroy-Medium";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            @media only screen and (max-width: 1600px){
                                font-size: 14px;
                            }
                        }
    
                        .rt_filter_clear {
                            border-radius: var(--Spacing-md, 12px);
                            background: var(--Background---grey-Grey---400---GB, #3B4D5E);
                            display: flex;
                            max-width: 76px;
                            width: 100%;
                            padding: var(--Spacing-sm, 8px) var(--Spacing-xl, 20px);
                            justify-content: center;
                            align-items: center;
                            gap: var(--Spacing-sm, 8px);
                            flex-shrink: 0;
                            color: #FFF;
                            cursor: pointer;
                            /* Caption/Caption 10/$caption 10-medium */
                            font-family: "Gilroy-Medium";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                            &:hover{
                              opacity: 0.5;
                            }
                            /* 166.667% */
                        }
                    }
    
                    .rt_filter_list {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: var(--Spacing-4xl, 40px);
                        margin-top: 30px;
                        @media only screen and (max-width: 1600px){
                            margin-top: 20px;
                            gap: var(--Spacing-4xl, 20px);
                        }
                        .rt_filter_list_each {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: var(--Spacing-2xl, 15px);
                            width: 100%;
    
                            .rt_filter_list_head {
                                color: var(--Background---grey-Grey---200---GB, #92A6BA);
                                font-family: "Gilroy-Medium";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                cursor: pointer;
                                @media only screen and (max-width: 1600px){
                                    font-size: 14px;
                                }
                                .rt_da{
                                    cursor: pointer;
                                    &.opened {
                                            transform: rotate(180deg);
                                        }
                                }
                                   
                            }
    
                            .rt_filter_list_search {
                                border-bottom: var(--Spacing-3xs, 2px) solid var(--Colors-Grayscale-Border-Disable, #DCDEE8);
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 10px;
    
                                input {
                                    flex: 0 0 85%;
                                    max-width: 85%;
                                    opacity: var(--Corner-radius-xs, 1);
                                    color: var(--Colors-Grayscale-Text-icon-Subtitle, #767A87);
                                    font-family: "Gilroy-Regular";
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px;
                                    border: none;
                                    background: transparent;
                                    box-shadow: none;
                                    outline: none;
                                }
                            }
    
                            .rt_filter_list_items {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                gap: var(--Spacing-2xl, 10px);
                                @media only screen and (max-width: 1365px){
                                  // flex-direction: row;
                                  flex-wrap: wrap;
                                }
                                .rt_filter_list_item {
                                    color: #FFF;
                                    font-family: "Gilroy-Medium";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px;
                                    display: flex;
                                    align-items: center;
                                    gap: 15px;
                                    cursor: pointer;
                                    word-break: break-all;
                                    @media only screen and (max-width: 767px){
                                        font-size: 13px;
                                    }
                                    .rt_cb {
                                        cursor: pointer;
                                        img{
                                          width: 18px;
                                        }
                                    }
                                }
                            }
                            &.tourFilterScroll{
                              .rt_filter_list_items_wrap{
                                width: 100%;
                                height: 170px;
                                overflow-y: scroll;
                                &::-webkit-scrollbar {
                                    width: 6px;
                                }
                              
                                /* Track */
                                &::-webkit-scrollbar-track {
                                  background: #181F26;
                                  border-radius: 9px;
                                }
                              
                                /* Handle */
                                &::-webkit-scrollbar-thumb {
                                  background: #74F33B;
                                  border-radius: 9px;
                                }
                              
                                /* Handle on hover */
                                &::-webkit-scrollbar-thumb:hover {
                                  background: #74F33B;
                                }
                              }
                            }
                        }
                    }
                }
            }
    
            .rt_list {
                flex: 0 0 78%;
                max-width: 78%;
                @media only screen and (max-width: 1365px){
                  flex: 0 0 69%;
                  max-width: 69%;
                }
                @media only screen and (max-width: 767px){
                  flex: 0 0 100%;
                  max-width: 100%;
                }
                .rt_items {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    @media only screen and (min-width: 1365px){
                        gap: 15px;
                    }
    
                    .rt_item {
                        color: #fff;
                        flex: 0 0 32%;
                        max-width: 32%;
                        @media only screen and (max-width: 1365px){
                          flex: 0 0 48%;
                          max-width: 48%;
                        }
                        @media only screen and (max-width: 540px){
                          flex: 0 0 100%;
                          max-width: 100%;
                        }
                        .rt_item_inner {
                            border-radius: 24px;
                            background: rgba(13, 20, 23, 0.25);
    
                            .subtag {
                                overflow: hidden;
                                color: #DBFFDF;
                                text-overflow: ellipsis;
                                font-family: "Gilroy-Medium";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                @media only screen and (max-width: 1600px){
                                    font-size: 12px;
                                }
                            }
    
                            .tag {
                                overflow: hidden;
                                color: var(--Base-White, var(--Colors-Grayscale-Text-icon-Negative, #FBFCFD));
                                text-overflow: ellipsis;
                                font-family: "Gilroy-Bold";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }
    
                            .rt_item_img {
                                position: relative;
    
                                .rt_bg {
                                    width: 100%;
                                      border-top-left-radius: 24px;
                                      border-top-right-radius: 24px;
                                      height: 200px;
                                      object-fit: cover;
                                      object-position: top center;
                                      opacity: 0.7;
                                }
    
                                .rt_timer {
                                    position: absolute;
                                    width: 100%;
                                    opacity: var(--Corner-radius-xs, 1);
                                    background: rgba(20, 26, 31, 0.10);
                                    backdrop-filter: blur(16px);
                                    padding: 10px;
                                    bottom: 0;
                                    display: flex;
                                    padding: var(--Spacing-sm, 10px) var(--Spacing-lg, 16px);
                                    justify-content: space-between;
                                    align-items: center;
                                    height: 60px;
    
                                    .timercontent {
                                        text-align: right;
                                    }
                                }
                            }
    
                            .rt_item_body {
                                padding: 12px;
                                background: url("../../images/redo/tour_cont_bg.png");
                                background-position: bottom;
                                background-repeat: no-repeat;
                                background-size: cover;
                                @media only screen and (max-width: 1600px){
                                    padding: 10px;
                                }
                                .rt_item_content {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 20px;
    
    
    
                                    .rt_item_tag {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        .rt_part_wrap_single img {
                                          margin-left: 0 !important;
                                      }
    
                                        .left {
                                            .rt_tournament_name {
                                                color: var(--Base-White, var(--Colors-Grayscale-Text-icon-Negative, #FBFCFD));
                                                font-family: "Gilroy-SemiBold";
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                @media only screen and (max-width: 1600px){
                                                    font-size: 14px;
                                                }
                                            }
                                            .subtag{
                                                color: var(--Neutral-Gray-100, #F2F4F7);
                                            }
                                        }
    
                                        .right {
                                            .rt_part_wrap {
                                                display: flex;
                                                align-items: center;
    
                                                img {
                                                    margin-left: -15px;
                                                    width: var(--Spacing-3xl, 32px);
                                                    height: var(--Spacing-3xl, 32px);
                                                    border-radius: var(--Corner-radius-pill, 9999px);
                                                    border: 3px solid var(--Background---grey-Grey---700---GB, #0E1216);
                                                    opacity: var(--Corner-radius-xs, 1);
                                                }
    
                                                .rt_part_count {
                                                    color: var(--Primary-Green---600---GB, #51E20E);
                                                    text-align: center;
                                                    font-family: "Gilroy-Medium";
                                                    font-size: 14px;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    line-height: 18px;
                                                    border-radius: var(--Corner-radius-pill, 9999px);
                                                    opacity: var(--Corner-radius-xs, 1);
                                                    background: var(--Background---grey-Grey---500---GB, #181F26);
                                                    display: flex;
                                                    width: var(--Spacing-3xl, 30px);
                                                    height: var(--Spacing-3xl, 30px);
                                                    align-items: center;
                                                    justify-content: center;
                                                    margin-left: -15px;
                                                }
                                            }
    
                                        }
                                    }
    
                                    .rt_item_players {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
    
                                        .left {
                                            .subtag{
                                                color: #DBFFDF;
                                            }
                                            &.left-flex{
                                                display: flex;
                                                  justify-content: space-between;
                                                  flex: 0 0 100%;
                                                  // .left-list{
                                                  //   @media only screen and (max-width: 767px){
                                                  //   &:last-child{
                                                  //     display: none;
                                                  //   }
                                                  // }

                                                  // }

                                            }
                                        }
    
                                        // .right {}
                                    }
    
                                    .rt_item_mode_reward {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
    
                                        .left{
                                            .subtag{
                                                color:#DBFFDF;
                                            }
                                        }
    
                                        .right {
                                            text-align: right;
                                            .subtag{
                                                color: #DBFFDF;
                                            }
                                            .rw_prize {
                                                color: var(--Base-White, var(--Colors-Grayscale-Text-icon-Negative, #FBFCFD));
                                                text-overflow: ellipsis;
                                                font-family: "Gilroy-Medium";
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                display: flex;
                                                align-items: center;
                                                gap: 5px;
                                                @media only screen and (max-width: 1600px){
                                                    font-size: 14px;
                                                }
                                                img{
                                                    width: 24px;
                                                    height: 24px;
                                                }
                                            }
                                        }
                                    }
                                }
    
                                .rt_item_action {
                                    display: flex;
                                    gap: 10px;
                                    margin-top: 15px;
                                    justify-content: center;
                                    align-items: center;
                                    @media only screen and (max-width: 1600px){
                                        gap: 5px;
                                    }
                                    button {
                                        border-radius: var(--Spacing-lg, 16px);
                                        flex: 0 0 50%;
                                        max-width: 50%;
                                        padding: 10px 5px;
                                        font-family: "Gilroy-SemiBold";
                                        border: none;
                                        &:hover{
                                          opacity: 0.5;
                                        }
                                        @media only screen and (min-width: 1430px){
                                            padding: 10px;
                                        }
                                        &.rule-bt {
                                            background: var(--Background---grey-Grey---400---GB, #3B4D5E);
                                            color: #FFF;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 22px;
                                            font-family: 'Inter-Medium';

                                            @media only screen and (max-width: 1600px){
                                                font-size: 12px;
                                            }
    
                                        }
    
                                        &.join-bt {
                                            border-radius: var(--Spacing-lg, 16px);
                                            // border: 1.5px solid #FFF;
                                            background: var(--Primary-Green---50---GB, #F1FEEC);
                                            box-shadow: 0px 2px 4px 0px rgba(78, 255, 97, 0.02), 0px 16px 56px -4px rgba(78, 255, 97, 0.24);
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 22px;
                                            font-family: 'Inter-Medium';
                                            @media only screen and (max-width: 1600px){
                                                font-size: 12px;
                                            }
                                        }
                                    }
    
                                }
                            }
    
                        }
                    }
                                        .no-tour-list {
                                          font-family: "Gilroy-Medium";
                                          font-size: 16px;
                                          line-height: 36px;
                                              color: var(--Background---grey-Grey---200---GB, #92A6BA);
                                          text-align: center;
                                          // display: flex;
                                          // flex-wrap: wrap;
                                          width: 100%;
                                          padding: var(--Spacing-md, 50px);
                                          align-items: center;
                                          border-radius: var(--Spacing-xl, 20px);
                                          border: 1px solid rgba(59, 77, 94, 0.5);
                                          background: var(--Background---grey-Grey---500---GB, #181f26);
                                          a {
                                              font-family: "Gilroy-Bold";
                                              display: inline-block;
                                              color: #74F33B !important;
                                              &:hover{
                                                opacity: 0.5 !important;
                                              }
                                            }
                                        }
                }
            }
        }
        .rtournament-banner{
            width: 100%;
            .tournament-banner-top{
              flex-wrap: wrap;
              @media only screen and (max-width: 767px){
                flex-direction: column;
              }
              .tournament-banner-top-left{
                max-width: 65%;
                flex: 0 0 65%;
                @media only screen and (max-width: 767px){
                  order: 1;
                  max-width: 100%;
                  flex: 0 0 100%;
                }
                .breadCrumb{
                  color: #767A87;
                  font-size: 14px;
                  line-height: 22px;
                  font-family: "Gilroy-Medium";
                  a{
                    color: #767A87;
                    font-size: 14px;
                    line-height: 22px;
                    font-family: "Gilroy-Medium";
                    text-decoration: none;
                    &:hover{
                      color: #F1FEEC;
                    }
                  }
                  span{
                      color: #F1FEEC;
                      img{
                          margin: 0px 8px ;
                      }
                  }
                }
                .tournament-title{
                    font-size: 18px;
                    line-height: 32px;
                    color: #fff;
                    margin-top: 32px;
                    font-family: "Gilroy-Medium";
                    @media only screen and (max-width: 767px){
                      margin-top: 16px;
                    }

                  display: flex;
                  align-items: center;
                  gap: 10px;
                  .tour-land-logo{
                    img{
                      width: 75px;
                      height: 75px;
                      background-color: #2d2d2d;
                      border: 2px solid rgba(59, 77, 94, 0.5);
                      border-radius: 100%;
                      object-fit: contain;
                      object-position: center;
                    }
                  }
                }
                .match_details{
                    gap: 35px;
                    margin: 10px 0;
                    flex-wrap: wrap;
                    @media only screen and (max-width: 767px){
                    margin: 16px 0 16px 0px;
                    // gap:16px;

                    }
                    @media only screen and (max-width: 340px){
                       gap:16px;
  
                      }

                    .tournament-Subtitle{
                        color: #FFF;
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; 
                        span{
                            color: var(--Primary-Green---500---GB, #74F33B);
                            font-family: "Gilroy-Medium";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;

                            @media only screen and (max-width: 767px){
                             display: block;
                            }
                        }
                    }
                }
                .match_other_details{

                  @media only screen and (max-width: 767px){
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 16px;
                  }
                    .timeLeft{
                        color: var(--Background---grey-Grey---200---GB, #92A6BA);
                        font-family: "Gilroy-Medium";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        border-right: 2px solid #3B4D5E;
                        padding-right: 32px;

                        @media only screen and (max-width: 767px){
                          font-size: 14px;
                          flex: 0 0 42%;
                          max-width: 42%;
                          padding-right: 17px;
                          font-size: 14px;
                         }
                        .countdown-sec{
                            color: #FFF;
                            font-family: "Gilroy-Bold";
                            font-size: 16px;
                            font-style: normal;
                            line-height: 24px; 
                            margin-top: 8px;
                            gap: 10px;

                            @media only screen and (max-width: 767px){
                              margin-top: 13px;
                             }
                        }
                    }
                    .host_finished{
                        display: flex;
                        flex-direction: column;
                        color: var(--Background---grey-Grey---200---GB, #92A6BA);
                        font-family: "Gilroy-Medium";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        border-right: 2px solid #3B4D5E;
                        padding-right: 32px;
                        @media only screen and (max-width: 767px){
                          padding-right: 16px;
                          margin-right: 16px;
                        }
                        span{
                            color: #FFF;
                            font-family: "Gilroy-Bold";
                            font-size: 16px;
                            font-style: normal;
                            line-height: 24px; 
                            margin-top: 8px;
                        }
                    }
                                        .host_name {
                                          display: flex;
                                          flex-direction: column;
                                          color: var(--Background---grey-Grey---200---GB, #92A6BA);
                                          font-family: "Gilroy-Medium";
                                          font-size: 12px;
                                          font-style: normal;
                                          font-weight: 400;
                                          line-height: normal;
                                          padding-left: 32px;
                                          border-right: 2px solid #3B4D5E;
                                          padding-right: 32px;
                    
                                          span {
                                            color: #FFF;
                                            font-family: "Gilroy-Bold";
                                            font-size: 16px;
                                            font-style: normal;
                                            line-height: 24px;
                                            margin-top: 8px;
           
                                          }
                                          @media only screen and (max-width: 767px){
                                            // flex: 0 0 42%;
                                            // max-width: 42%;
                                            padding-right: 25px;
                                            margin-right: 25px;
                                            padding-left: 0px;

                                            span 
                                            {
                                              text-decoration: underline;
                                              position: relative;
                                              &::after{
                                                content:"";
                                                background: url("../../images/redo/mobile-arrow.png")center right  no-repeat;
                                                background-repeat: no-repeat;
                                                height: 20px;
                                                width: 20px;
                                                position: absolute;
                                                margin-left: 7px;
                                              }
                                            }
                                           }
                                        }
                    .entry-free{
                        display: flex;
                        flex-direction: column;
                        color: var(--Background---grey-Grey---200---GB, #92A6BA);
                        font-family: "Gilroy-Medium";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        padding-left: 32px;
                        span{
                            color: #FFF;
                            font-family: "Gilroy-Bold";
                            font-size: 16px;
                            font-style: normal;
                            line-height: 24px; 
                            margin-top: 8px;
                        }

                        @media only screen and (max-width: 767px){
                          // flex: 0 0 40%;
                          // max-width: 40%;
                          padding-left: 0px;
                          font-size: 14px;
                         }
                    }
                    
                }
                .redo-tourfinished-text{
                  font-size: 18px;
                  line-height: 26px;
                  color: #DBFFDF;
                  margin: 15px 0px;
                }
              }
              .tournament-banner-top-right{
                max-width: 35%;
                flex: 0 0 35%;
                gap: 8px;
                justify-content: flex-end;
                @media only screen and (max-width: 767px){
                  order: 2;
                  max-width: 100%;
                  flex: 0 0 100%;
                  justify-content: flex-start;
                  margin-bottom: 16px;
                  margin-top: 16px;
                }
                .tour-live-sec{
                  height: 56px;
                  max-width: 100px;
                  width: 100%;
                  background-color: #74F33B;
                  color: #020303;
                  font-size: 16px;
                  line-height: 24px;
                  font-family: "Gilroy-Medium";
                  border-radius: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border: none;
                  @media only screen and (max-width: 767px){
                    font-size: 12px;
                    height: 44px;
                    max-width: 54px;
                  }
                  img {
                    margin-right: 5px;
                    animation: blink 2s linear infinite;
                  }
                  @keyframes blink {
                    0% {
                      opacity: 0;
                    }
          
                    50% {
                      opacity: 0.75;
                    }
          
                    100% {
                      opacity: 1;
                    }
                  }
                }
                .people_join{
                  color: var(--Background---grey-Grey---50---GB, #E3E8ED);
                  height: 56px;
                  max-width: 255px;
                  width: 100%;
                  border-radius: var(--Spacing-lg, 16px);
                  opacity: var(--Corner-radius-xs, 1);
                  background: var(--Background---grey-Grey---600---GB, #141A1F);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 16px;
                  line-height: 24px;
                  font-family: "Gilroy-Medium";
                  img{
                    margin-right: 5px;
                  }

                  @media only screen and (max-width: 767px){
                    font-size: 12px;
                    height: 44px;
                    max-width: 190px;
                  }
                  @media only screen and (min-width: 768px) and (max-width: 990px){
                    padding: 3px;
                  }
                }
                               
              }
            }
            
        }
        .rprize-section{
            max-width: 1146px;
            width: 100%;
            .prize-details{
                flex-wrap: wrap;
                margin-top: 20px;
                gap: 16px;
                align-items: flex-end;
                @media only screen and (max-width: 490px){
                  align-items: center;
                  // justify-content: center;
                }
                @media only screen and (max-width: 767px){
                  // justify-content: space-around;
                gap: 0;
                margin-top: 0px;
                justify-content: space-between;
                }
                .prize-box{
                    flex-direction: column;
                    border-radius: var(--Spacing-lg, 16px);
                    border: 1px solid rgba(116, 243, 59, 0.70);
                    opacity: var(--Corner-radius-xs, 1);
                    background: linear-gradient(180deg, rgba(116, 243, 59, 0.00) 0%, rgba(116, 243, 59, 0.10) 100%);
                    /* Blur/lg - b:48 */
                    backdrop-filter: blur(24px);
                    max-width: 150px;
                    width: 100%;
                    height: 145px;
                    align-items: center !important;
                    justify-content: center;

                    @media only screen and (max-width: 767px){
                      height: 150px;
                      max-width: 49% !important;
                      flex: 0 0 49%;
                      margin-bottom: 8px;
                      }


                    .medal_bg{
                      background-image: url("../../images/redo/prize_no_bg.png");
                      background-repeat: no-repeat;
                      background-position: center center;
                      height: 75px;
                      width: 63px;
                      color: var(--Primary-Green---500---GB, #74F33B);
                      stroke-width: 1px;
                      stroke: rgba(241, 254, 236, 0.80);
                      opacity: var(--Corner-radius-xs, 1);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 25px;
                      line-height: 40px;
                      font-family: "Gilroy-Bold";
                      padding-bottom: 15px;
                    }
                    img{
                        height: 80px;
                    }
                    .won-coin{
                        color: #fff;
                        font-size: 14px;
                        line-height: 20px;
                        font-family: "Gilroy-Bold";
                    }
                    .convert-coin{
                        font-size: 14px;
                        line-height: 20px;
                        color: #92A6BA;
                        font-family: "Gilroy-Medium";
                    }
                    .mobile_adjust {
                      text-align: center;
                      @media only screen and (max-width: 767px){
                      display: flex;
                      flex-direction: column;
                      }


                  }
                    &.gold{

                      height: 227px;
                      max-width: 150px !important;
                      width: 100%;
                      background-image: url("../../images/redo/prize_bg.png");
                      background-position: bottom center; 
                      background-repeat: no-repeat;
                      background-size: 100% 38%;
                      background-color: rgba(2, 3, 3, 0.05);
                      justify-content: flex-start;
                      @media only screen and (max-width: 767px){

                        height: 128px;
                        max-width: 100%!important;
                        flex-direction: row;
                        flex: 0 0 100%;
                        justify-content: center;  
                        .mobile_adjust {
                          text-align: left;
                        }
                      }

                                      
          
                        img{
                            height: 135px !important;
                        }


                        
                    }
                    &.silver{
                        height: 200px;
                        max-width: 150px;
                        width: 100%;
                        justify-content: flex-start;
                        img{
                          height: 135px;
                        }

                        @media only screen and (max-width: 767px){

                          height: 200px;
                          max-width: 49%!important;
                          flex: 0 0 49%;
                       
                        }
  
                    }
                    &.bronze{
                        height: 177px;
                        max-width: 150px;
                        width: 100%;
                        img{
                            height: 115px !important;
                        }

                        @media only screen and (max-width: 767px){
                          height: 200px;
                          max-width: 49%!important;
                          flex: 0 0 49%;
                       
                        }
                    }
                    
                    
                }
            }
        }
        .tournament_BtnSec{
            gap: 16px;
            margin-top: 24px;
            padding-bottom: 30px;
            @media only screen and (max-width: 490px){
              align-items: center;
              justify-content: center;
            }
            @media only screen and (max-width: 767px){
              margin-top: 0px;
              // justify-content: space-around;
              flex-direction: column;
              padding-bottom: 0px;
            }
            .rules-btn{
                background-color: #3B4D5E;
                height: 56px;
                max-width: 250px;
                width: 100%;
                border-radius: var(--Spacing-2xl, 24px);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                line-height: 24px;
                color: #fff;
                cursor: pointer;
                &:hover{
                  opacity: 0.5;
                }
                @media only screen and (max-width: 767px){
                  flex: auto;
                  max-width: 100%;
                  width: 100%;
                }

            }
            .partBtn-sec {
                max-width: 250px;
                width: 100%;
                @media only screen and (max-width: 767px) {
                  max-width: 100%;
                  width: 100%;
                  .rules-btn {
                    margin-top: 0px;
                    &:hover{
                      opacity: 0.5;
                    }
                }
                }
              .joinBtn{
                height: 56px;
                border-radius: var(--Spacing-2xl, 24px);
                border: 1.5px solid #FFF;
                background: var(--Primary-Green---50---GB, #F1FEEC);
                box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.02), 0px 16px 56px -4px rgba(241, 254, 236, 0.08);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                line-height: 24px;
                color: #020303;
                cursor: pointer;
                &.noSpotBtn{
                  max-width: 100% !important;
                }
                @media only screen and (max-width: 767px) {
                  max-width: 100% !important;
                  width: 100%;
                }
              } 
            }
        }
        .rsearch_sec {
            display: flex;
            justify-content: space-between;
            padding-top: 50px;
            @media only screen and (max-width: 767px) {
              flex-direction: column;
              padding-top: 0px;
            }
        
            .upload-sec {
              max-width: 100%;
              width: 100%;
              height: 46px;
              color: #fff;
              border-radius: 10px;
              font-family: "Gilroy-SemiBold";
              font-size: 17px;
              line-height: 17px;
              text-transform: uppercase;
              letter-spacing: 0.04em;
              text-decoration: none;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 20px;
              margin-bottom: 20px;
              cursor: pointer;
              gap: 10px;
        
              &.up {
                background-color: #3B4D5E;
                &::before {
                  content: url("../../images/redo/upload_icon.png");
                  padding-left: 10px;
                }
               
              }
        
              &.mesg {
                background: #F1FEEC;
                color: #020303;
                input{
                  color: #020303;
                }
                &::before {
                  content: url("../../images/redo/mesg_icon_new.png");
                  padding-left: 10px;
                }
              }
        
              input {
                background: none;
                border: 0;
                color: #fff;
                font-family: "Gilroy-SemiBold";
                font-size: 17px;
                line-height: 17px;
              }
            }
        
            .aff_top_inner {
              background: rgba(14, 18, 22, 0.20);
              border: 2px solid rgba(59,77,94,0.5);
              box-sizing: border-box;
              border-radius: 20px;
              padding: 30px;
              margin-top: 70px;
              display: flex;
              flex-direction: column;

              @media only screen and (max-width: 767px) {
                margin-top: 30px;
              }
        
              .aff_copy_save {
                display: block;
        
                @media only screen and (min-width: 768px) {
                  display: flex;
                  flex-wrap: wrap;
                }
        
                .aff_clip_wrap {
                  flex: 0 0 80%;
                  max-width: 80%;
                  background: #000 !important;
                  border: 1px solid rgba(59,77,94,0.5);
                  border-radius: 15px;
                  height: 56px;
                  width: 100%;
                  display: flex;
                  align-items: center;
        
                  @media only screen and (max-width: 767px) {
                    max-width: 100%;
                  }
        
                  input {
                    background: none;
                    border: none;
                    box-shadow: none;
                    color: #fff !important;
                    font-family: "Gilroy-Bold";
                    font-size: 17px;
                    line-height: 19px;
                  }
        
                  .cpy-img {
                    padding: 0 20px;
        
                    img {
                      cursor: pointer;
                    }
                  }
                }
        
                .aff_copysave {
                  flex: 0 0 18%;
                  max-width: 18%;
                  margin-left: 2%;
                  height: 56px;
                  padding: 0px;
        
                  &::after {
                    display: none;
                  }
        
                  @media only screen and (max-width: 767px) {
                    max-width: 100%;
                    padding: 10px 20px;
                    margin-top: 10px;
                    margin-left: 0;
                  }
                }
              }
        
              label {
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 20px;
                line-height: 32px;
              }
        
              .aff_bold {
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 50px;
                line-height: 55px;
              }
        
              p {
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 28px;
                opacity: 0.5;
              }
        
              select {
                background-color: #050505;
                border-radius: 10px;
                border-color: #050505;
                color: #fff;
                min-width: 150px;
                padding: 10px 15px;
                width: 100%;
                cursor: pointer;
              }
        
              .successMsg {
                color: #40ab00;
                font-family: "Gilroy-Bold";
                font-size: 17px;
                line-height: 19px;
                padding: 10px 0;
                text-align: left;
              }
        
              .errorMsg {
                color: red;
                font-family: "Gilroy-Bold";
                font-size: 17px;
                line-height: 19px;
                padding: 10px 0;
                text-align: left;
              }
            }
        
            .mf-head-text {
              color: #fff;
              font-family: "Gilroy-Medium";
              font-size: 20px;
              line-height: 28px;
        
              &.mf-head-text-invites {
                font-size: 34px;
                line-height: 40px;
              }
            }
        
            .mf-tag {
              border: 1px solid rgba(59,77,94,0.5);
              margin-top: 20px;
            }
        
            .mf-searchbar {
              margin-top: 15px;
              display: flex;
              align-items: center;
              gap: 10px;
        
              .mf-searchbar-form-wrap {
                flex: 0 0 75%;
                max-width: 75%;
                margin-top: 10px;
        
                @media only screen and (max-width: 991px) {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
        
                .mf-searchbar-form-inner {
                  background: #181f26 !important;
                  border: 2px solid rgba(59,77,94,0.5);
                  height: 56px;
                  border-radius: 10px !important;
                  display: flex;
                  padding: 10px;
                  position: relative;
        
                  .mf-searchbar-img {
                    padding: 5px;
                  }
        
                  .mf-searchbar-form {
                    background: #181f26 !important;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    border-radius: 10px !important;
                    height: 100%;
                    //color: rgba(235, 235, 245, 0.6);
                    color: #fff;
                    font-family: "Gilroy-SemiBold";
        
                    &::-webkit-input-placeholder {
                      color: #fff;
                    }
                  }
        
                  .mf-close-img {
                    padding: 8px;
                    cursor: pointer;
                  }
        
                  .my-search-list {
                    padding: 10px;
                    position: absolute;
                    top: 56px;
                    left: 0px;
                    background: #000;
                    width: 100%;
                    border: solid 1px;
                    border-radius: 10px !important;
                    z-index: 99999;
        
                    .my-search-list-items-wrap {
                      height: 300px;
                      overflow-y: scroll;
        
                      &::-webkit-scrollbar {
                        width: 3px;
                      }
        
                      /* Track */
                      &::-webkit-scrollbar-track {
                        background: #181818;
                      }
        
                      /* Handle */
                      &::-webkit-scrollbar-thumb {
                        background: #e52420;
                      }
        
                      /* Handle on hover */
                      &::-webkit-scrollbar-thumb:hover {
                        background: #e52420;
                      }
        
                      .my-search-list-items {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        padding: 10px;
                        cursor: pointer;
        
                        .my-search-list-img {
                          img {
                            border-radius: 25px;
                            width: 40px;
                            height: 40px;
                            object-fit: cover;
                          }
                        }
        
                        .my-search-list-name {
                          color: #fff;
                          font-family: "Gilroy-Bold";
                          font-size: 16px;
                        }
                      }
        
                      .nousermessage {
                        color: #fff;
                        /* text-align: center; */
                        padding: 10px;
                      }
                    }
                  }
                }
        
                .note-text {
                  font-family: "Gilroy-SemiBold";
                  font-size: 14px;
                  line-height: 16px;
                  color: #ebebf599;
                }
              }
        
              .mf-searchbar-btn {
                flex: 0 0 20%;
                max-width: 20%;
        
                &::after {
                  display: none;
                }
              }
            }
        
            .left_search {
              width: 68%;
        
              @media only screen and (max-width: 991px) {
                width: 60%;
              }
        
              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            }
        
            .right_search {
              width: 30%;
        
              @media only screen and (max-width: 991px) {
                width: 39%;
              }
        
              @media only screen and (max-width: 767px) {
                width: 100%;
              }
            }
            .playerInfoScroll{
              height: 260px;
                overflow-y: scroll;
              
                &::-webkit-scrollbar {
                  width: 3px;
                }
              
                /* Track */
                &::-webkit-scrollbar-track {
                  background: #181818;
                }
              
                /* Handle */
                &::-webkit-scrollbar-thumb {
                  background: #51E20E;
                }
              
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                  background: #51E20E;
                }
            }

        
            .profile-info {
              color: #ffffff;
              font-family: "Gilroy-SemiBold";
              font-size: 15px;
              line-height: 20px;
              display: flex;
              align-items: center;
              margin-top: 20px;
        
              img {
                height: 50px;
                object-fit: cover;
                width: 50px;
                &.prof-circle {
                  margin-right: 15px;
                  border: 2px solid #fff;
                  border-radius: 100%;
                  box-sizing: border-box;
                }
        
                &.prof-flag {
                  margin-left: 30px;
                }
              }
        
              .playerInfo-inner {
                display: flex;
                flex-direction: column;

              }
            }
          }

          .gbt_ms_wrap
          {
            @media only screen and (max-width: 767px) {
              margin-bottom: 30px;
            }
          }
    }
}
._GB_Redo_MyTour{
 
  .common_Inner{
    @media only screen and (max-width: 1400px) {
      max-width: 100% !important;
    }
  }
  .gameList{
    max-width: 200px;
    width: 100%;
    .gameDrop{
        border: 1px solid #3B4D5E;
        border-radius: 16px;
        height: 46px;
        max-width: 200px;
        width: 100%;
        background-color: #0E1216;
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        font-family: "Gilroy-Medium";
        padding: 5px;
        cursor: pointer;
    }
    select{
        option {
            background: transparent;
            color: #fff;
            &:hover{
                color: #74F33B;
            }
          }
    } 
}
  #mytournament_Table{
    margin-top: 30px;
    @media only screen and (max-width: 767px) {
      .mobile_hide{
        display: none;
      }
   
    }
    thead {

      tr {
          th {
            background-color: #141A1F;
            font-size: 14px;
            line-height: 24px;
            font-family: 'Inter-Medium';
            font-style: normal;
            color: #51E20E;
            border: none !important;
            text-transform: capitalize;
            @media only screen and (max-width: 767px){
              display: table-cell;
              text-align: -webkit-match-parent;
              min-width:100px;
 
              &.game{
                min-width: 72px;
              }
              &.status_mob{

                  min-width: 100px;
                  text-align: center;
                  
  
              }

               }
            &:first-child {
              border-top-left-radius: 20px;
            }
            &.players_count{
              @media only screen and (max-width: 767px){
              border-top-right-radius: 20px;
              display: none;
              }
            }
            &.players_count_mob{
              // border-top-right-radius: 20px;
              min-width: 50px;
              text-align: center;
              @media only screen and (min-width: 768px){
                display: none;
                }

            }

          
            &:last-child {
              border-top-right-radius: 20px;
            }
            
          }
      }
      
  }
  tr{
    td{
        color: #DBFFDF;
    }
    &.deleted {
      td {
          text-decoration: line-through;
          opacity: 0.5;
        }
    }
   }
   tbody {
    // @media only screen and (max-width: 767px) {
    //   display: block;
    // }
    border: none;
    tr {
      border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
      background-color: rgba(14, 18, 22, 0.20);
      // @media only screen and (max-width: 767px) {
      //   display: block;
      //   border:none;
      //   margin-bottom: 16px;
      // }

      
      td {
        color: #DBFFDF;
        font-family: 'Inter-Regular';
        font-size: 14px;
        line-height: 24px;
        vertical-align: middle;
        height: 89px;
        text-transform: capitalize;
        border-bottom: none;
        &.GB_device_td{
          @media only screen and (max-width: 767px)
          {
          // align-items: center;
          display: table-cell;
          
          
          }
        }
        a{
          color: #DBFFDF;
          text-decoration: none;
          &:hover{
            opacity: 0.5;
          }

        }
        &.status_mob{
          @media only screen and (max-width: 767px){
            text-align: center;
          }
          
        }
        &.status{
          span.cancel-tournament-user {
            cursor: pointer;
            margin-left: 5px;
        }
          &.pending{
             color:yellow;
          }
          &.active{
            color:#51E20E;
         }
         &.finished{
          color:#DBFFDF;
       }
       &.canceled{
        color:#FF3C35;
       }

        }
        &.rank_tier{
          word-break: break-word;
        }
        // @media only screen and (max-width: 767px) {
        //   display: block;
        //   position: relative;
        //   text-align: right !important;
        //   font-family: "Gilroy-Medium";
        //   font-size: 12px;
        //   height: auto;
        //   padding-left: 54%;
        //   display: flex;
        //   align-items: center;
        //   justify-content: flex-end;
        //   height: 64px;
        //   border-bottom: 1px solid #3b4d5e;
        //   border-bottom-left-radius: 25px;
        //   &.loader-table{
        //     padding-left: 0px !important;
        //     border:none;
        //     &::before{
        //       display: none;
        //       content:"";
        //     }
        //   }
        //   &:before {           
        //     content: attr(data-title); 
        //     position: absolute;
        //     /* Top/left values mimic padding */
        //     top: -2px;
        //     left: 0px;
        //     width: 51%; 
        //     padding-right: 10px; 
        //     white-space: nowrap;
        //     font-family: 'Inter-Regular';
        //     font-size: 14px;
        //     line-height: 24px;
        //     text-align:left;
        //     color:#51E20E;
        //     font-weight: bold;
        //     background: #141a1f;
        //     padding: 21px 16px;
        //     border-top: 1px solid #0E1216;
        //   }


        //       &:first-child{
        //     &:before {   
        //       border-top-left-radius: 20px;    
        //       border-top: 0px; 
        //     }


        // }

        //         &:last-child{
        //           &:before {   
        //             border-bottom-left-radius: 20px;     
        //           }
        //         }

              

          
         
         
        // }
        &:first-child{
            text-align: left;
            color: #fff;
        }
        &.no-tournament{
          text-align: center;
        }
        &.amount_players{
          @media only screen and (max-width: 767px){
          text-align: center;
          }
        }


        // &.logo_name_sec{
        //   display: flex;
        //   flex-wrap: wrap;
        //   align-items: center;
        //   gap: 10px;
        // }
        // .trx_normal_desc{
        //     color: #DBFFDF;
        //     font-size: 14px;
        //     line-height: 19px;
        //     font-family: 'Inter-SemiBold';
        // }


        }
       
    }
}
  }
      
}

.mobileMyTournament{
  tbody{
    tr{
      td{
        @media only screen and (max-width: 767px){
          justify-content: center !important;
          padding-left: 0 !important;
        }
        &::before{
          @media only screen and (max-width: 767px){
            display: none !important;
          }
        }
      }
    }
  }
}

.rulesModal {
    backdrop-filter: brightness(40%);

    .modal-content {
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.50);
        opacity: var(--Corner-radius-xs, 1);
        background: var(--Background---grey-Grey---500---GB, #181F26);

        .modal-header {
            border: none !important;
        }

        .rulesHeadImg {
            display: flex;
            justify-content: center;
            padding: 20px;
            background-image: url("../../images/redo/rules-head-icon-svg.svg") !important;
            background-repeat: no-repeat;
            background-position: 50% -17%;
            img{
              animation: rotateRight 90s linear infinite;
        
          }
        }

        .rulesHeadTitle {
            color: #FFF;
            text-align: center;
            font-family: "Gilroy-Medium";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .rulesHeadTag {
            color: var(--Background---grey-Grey---200---GB, #92A6BA);
            text-align: center;
            font-family: "Gilroy-Regular";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            padding: 8px 15px 10px;
        }

        .rulesContent {
            border-radius: var(--Spacing-lg, 16px);
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---700---GB, #0E1216);
            padding: 20px 20px 20px 0;
            margin: 15px;

            .rulesContentInner {
                height: 283px;
                overflow-y: scroll;
                padding-right: 10px;

                &::-webkit-scrollbar {
                    width: 3px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #181818;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #4EFF61;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #4EFF61;
                }

                .rulesList {
                    li {
                        color: var(--Background---grey-Grey---100---GB, #C7D1DB);
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        padding: 10px;
                        border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
                    }
                }
            }
        }

        .rulesFooter {
            padding: 10px;
            @media only screen and (max-width: 575px){
              padding: 10px 0px;
            }
            .rulesCloseBtn {
                padding: var(--Spacing-lg, 13px) var(--Spacing-2xl, 24px);
                border-radius: var(--Spacing-xl, 20px);
                background: var(--Primary-Green---50---GB, #F1FEEC);
                box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.04), 0px 24px 48px -8px rgba(241, 254, 236, 0.12);
                width: 100%;
                color: var(--Background---grey-Grey---950---GB, #020303);
                font-family: "Gilroy-Medium";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                border: none;
            }
        }
    }
}



.rjoin-popup-modal {
  .modal-content {
    width: 470px;
    height: auto;
    padding-bottom: 20px;
    background: var(--Background---grey-Grey---500---GB, #181F26);
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    border-bottom: none;

    @media only screen and (max-width: 767px) {
      height: 630px;
    }
  }

  .modal-header {
    color: #fff;
    padding: 25px;
    border-bottom: none;
    display: flex;
    align-items: flex-start;
    @media only screen and (max-width: 767px) {
      padding: 15px 20px 10px 0px;
    }
    .modal-title{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 95%;
      flex: 0 0 95%;
      .title{
        font-size: 18px;
        line-height: 24px;
        color: #fff;
        font-family: "Gilroy-Medium";
      }
      p{
        font-size: 16px;
        line-height: 16px;
        color: #92A6BA;
        margin-bottom: 0;
      }
    }
    .btn-close {
      color: #fff;
      outline: none;
      background: url("../../images/redo/popup_close.png") no-repeat center center;
      margin-left: 0;
    }
  }

  .modal-body {
    padding: 0 40px;

    @media only screen and (max-width: 767px) {
      padding: 0 25px;
    }

    .content-sect {
      .title {
        color: #fff;
        text-align: center;
        font-family: "Gilroy-Bold";
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 35px;

        @media only screen and (max-width: 767px) {
          font-size: 30px;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }

      .balance-sec {
        margin-bottom: 15px;

        p {
          text-align: center;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 25px;
          color: #9c9ca3;
          margin-bottom: 5px;
        }

        .balance-box {
          background: #000000;
          border-radius: 12px;
          display: flex;
          align-items: center;
          padding: 12px !important;
          gap: 10px;

          @media only screen and (max-width: 767px) {
            padding: 15px;
          }

          @media only screen and (max-width: 374px) {
            justify-content: center;
          }
          .balance-box_item{
            border-radius: var(--Spacing-lg, 16px);
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---500---GB, #181F26);
            &.active{
              border-radius: var(--Spacing-lg, 16px);
              border: 1.5px solid #74F33B;
              opacity: var(--Corner-radius-xs, 1);
              background: linear-gradient(273deg, rgba(116, 243, 59, 0.20) -1.9%, rgba(116, 243, 59, 0.00) 73.75%), var(--Background---grey-Grey---500---GB, #181F26);
            }
            &:hover{
              border-radius: var(--Spacing-lg, 16px);
              border: 1.5px solid #74F33B;
              opacity: var(--Corner-radius-xs, 1);
              background: linear-gradient(273deg, rgba(116, 243, 59, 0.20) -1.9%, rgba(116, 243, 59, 0.00) 73.75%), var(--Background---grey-Grey---500---GB, #181F26);
            }
          }
          .claim-coins {
            .claimed-gbcoin {
              color: #fff;
              font-family: "Gilroy-Medium";
              font-size: 14px;
              line-height: 24px;
            }

            .convert-coin {
              color: #fff;
              font-family: "Gilroy-Medium";
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
      }

      .notification-sec {
        color: #92A6BA;
        font-family: "Gilroy-Regular";
        font-size: 16px;
        line-height: 18px;
        text-align: center;

        // display: flex;
        // flex-direction: column;
        span {
          color: #ffffff;
        }

        .rulesTour {
          cursor: pointer;
          text-decoration: underline;
        }
      }

      .btn-sec {
        max-width: 417px;
        width: 100%;
        height: 56px;
        border-radius: 10px;
        font-family: "Gilroy-Medium";
        font-size: 15px;
        line-height: 17px;
        letter-spacing: 0.04em;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 20px;
        &:hover {
          opacity: 0.5;
        }

        @media only screen and (max-width: 767px) {
          margin: 15px auto 0 auto;
        }
      }

      .confirmBtn {
        border-radius: var(--Spacing-2xl, 24px);
        background: var(--Primary-Green---50---GB, #F1FEEC);
        box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.04), 0px 24px 48px -8px rgba(241, 254, 236, 0.12);
        color: var(--Background---grey-Grey---950---GB, #020303);
        &::after {
          content: url("../../images/redo/arrow-right.png");
          padding-left: 10px;
        }
      }

      .hidearrowcb {
        &::after {
          display: none;
        }

        span {
          padding-left: 10px;
        }
      }

      .cancelBtn {
        color: #fff;
        border-radius: var(--Spacing-2xl, 24px);
        background: var(--Background---grey-Grey---400---GB, #3B4D5E);
      }
    }
  }
}

.com-popup-modal {
  &.common-confirm {
    max-width: 475px;

    .modal-content {
      @media only screen and (max-width: 767px) {
        padding: 15px;
      }

      .modal-header {
        padding-bottom: 1rem;
      }

      .modal-body {
        text-align: left;

        @media only screen and (min-width: 768px) {
          padding: 0 25px;
        }

        .content-sect {
          p {
            color: #fff;
            margin-bottom: 0;
          }
        }
      }

      .modal-footer {
        padding: 0;

        @media only screen and (min-width: 768px) {
          padding: 20px 25px 20px;
        }

        button {
          &:after {
            display: none;
          }

          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  }

  &.delete-game {
    .modal-content {
      background: var(--Background---grey-Grey---500---GB, #181F26);
      border: 2px solid #2d2d2d;
      max-width: 470px;
      width: 100%;
      .modal-header{
          display: flex;
          justify-content: center;
          align-items: flex-start;
        .modal-title{
          max-width: 95%;
          flex: 0 0 95%; 
          background-image: url("../../images/redo/exit_icon_svg.svg") !important;
          background-repeat: no-repeat;
          background-position: 52% 25%;
          margin-bottom: 24px;
          img{
              animation: rotateRight 90s linear infinite;
        
          }
        }
        .btn-close {
          color: #fff;
          outline: none;
          background: url("../../images/redo/popup_close.png") no-repeat center center;
          margin-left: 0;
          padding-right: 10px;
        }
      }
      
      .modal-body {
        .title {
          text-align: center;
          color: #fff;
          font-family: "Gilroy-Medium";
          font-size: 24px;
          line-height: 24px;
        }

        .sub-title {
          text-align: center;

          h3 {
            color: var(--Background---grey-Grey---200---GB, #92A6BA);
            font-family: "Gilroy-Regular";
            font-size: 24px;
            line-height: 26px; 
            @media only screen and (max-width: 767px){
              font-size: 16px;
              line-height: 24px;
            }
          }

          
        }
      }

      .modal-footer {
        justify-content: space-between;
        display: flex;
        button{
          max-width: 200px;
          width: 100%;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-transform: none;
          margin-right: 0px !important;
          border: none !important;
          &:hover{
            opacity: 0.5;
          }
        }
        .exitBtn{
          border-radius: var(--Spacing-2xl, 24px);
          background: var(--Color-Error-500, #FF3C35);
          box-shadow: 0px 2px 4px 0px rgba(78, 255, 97, 0.02), 0px 16px 56px -4px rgba(78, 255, 97, 0.08);
        }
        .noBtn{
          border-radius: var(--Spacing-2xl, 24px);
          background: var(--Background---grey-Grey---400---GB, #3B4D5E);
        }
      }
    }
  }
}


._GB_RSeasonResult {
  padding: 0;

  .rseasonResult_game {
    gap: 15px;
    margin-bottom: 10px;

    .pubg-logo {
      @media only screen and (max-width: 767px) {
        width: 80px;
        height: 80px;
      }
    }

    .seasonName {
      font-family: "Gilroy-Medium";
      color: #ffffff;
      font-size: 18px;
      line-height: 24px;

      @media only screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    .playerratio{
        color: #5D7893;
        font-family: "Gilroy-Medium";
        font-size: 18px;
        line-height: 24px;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
    }

    .participate_Section {
      @media only screen and (max-width: 575px) {
        margin-bottom: 15px;
      }

      #participation_mode {
        background-color: #181818;
        box-shadow: none;
        font-family: "Gilroy-Bold";
        font-size: 16px;
        line-height: 18px;
        border: 2px solid #2d2d2d;
        border-radius: 16px;
        width: 165px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mode_item {
          .select_mode {
            margin-left: 10px;
          }
        }
      }

      .dropdown-menu {
        .dropdown-item {
          .select_mode {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .seasonResult_table {

    ._lb_web_table
    {
      @media only screen and (max-width: 767px) {
        display:block;
      }
    }

    .pt-2
    {
      @media only screen and (max-width: 767px) {
        align-items: center;
      }
    }
    border: none;
    .season_title {
      @media only screen and (max-width: 767px) {
      // padding: 0px 0;
      }
      .seasonName{
        color: #fff;
        font-size: 18px;
        line-height: 24px;
        font-family: "Gilroy-Medium";

        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .saveTMBtn {
      cursor: pointer;
      max-width: 248px;
      width: 100%;
      height: 48px;
      color: #020303;
      border-radius: var(--Spacing-xl, 20px);
      background: var(--Primary-Green---50---GB, #F1FEEC);
      box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.04), 0px 24px 48px -8px rgba(241, 254, 236, 0.12);
      font-family: "Gilroy-Medium" !important;
      font-size: 16px;
      line-height: 16px;
      text-transform: none;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
        padding: 8px 15px;

      }
   
      &::after {
        content: url("../../images/redo/arrow-right.png");
        padding-left: 5px;
      }
   
      &:hover {
        opacity: 0.5;
      }
    }
    #seasonResult_Table {
      border-bottom: none !important;  

      thead {
        @media only screen and (max-width: 767px) {
          display: table-header-group;
        }

        tr {
          th {
            background-color: #141A1F;
            font-size: 14px;
            line-height: 24px;
            font-family: 'Inter-Medium';
            color: #51E20E;
            border: none !important;
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            // &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              text-align: center;
            }

            &:nth-child(6) {
              text-align: left;
            }

            &:first-child {
              border-top-left-radius: 10px;
              text-align: center;
            }

            &:last-child {
              border-top-right-radius: 10px;
            }

            &.ticket-info {
              text-align: left;
            }
            @media only screen and (max-width: 767px) {
              font-size: 12px;
             }
            

                
                &.GB_device_th {
                  @media only screen and (max-width: 767px)
                {
                display: table-cell;
                }
              }
            //   &.gb-first-col {
            //     @media only screen and (max-width: 767px){
            //     min-width:230px;
            //     }
            // }
//             &.gb-sec-col {
//                 @media only screen and (max-width: 767px){
//                 min-width:100px;
//                 }
//             }
            &.gb-third-col {
                @media only screen and (max-width: 767px){
                  display:none !important;
                }
}
              

          }
        }
      }

      tbody {
        border: none;
        tr {
          border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
          &:last-child{
            border: none;
            td{
              &:first-child{
                border-bottom-left-radius: 16px;
              }
              &:last-child{
                border-bottom-right-radius: 16px;
              }
            }
          }
          td {
            color: #DBFFDF;
            font-family: 'Inter-Regular';
            font-size: 14px;
            line-height: 24px;
            vertical-align: middle;
            height: 56px;
            background-color: rgba(14, 18, 22, 0.20);
            border-bottom: none;
            @media only screen and(min-width: 1366px) and(max-width: 1919px) {
              height: 45px;
            }
            .ms-popup-tr{
              cursor: pointer;
            }
            .dota2rankiconlb.rank_tier{
              cursor: pointer;
            }
            &.country-sec{
              @media only screen and (max-width: 767px)
              {
              display:none !important;
              }

            }
            
            &:nth-child(2) {
              text-align: left;
            }

            &.rankNo {
              .rankBox {
                border-radius: 10px;
                display: flex;
                font-family: 'Inter-Regular';
                font-size: 18px;
                height: 32px;
                justify-content: center;
                align-items: center;
                line-height: 100%;
                text-align: center;
                width: 32px;

                @media only screen and (min-width: 768px) {
                  margin: auto;
                }

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .redBox {
                background: #51E20E;
                color: #fff;
              }

              .goldBox {
                background: #fda829;
              }

              .silverBox {
                background: #444444;
              }

              .bronzeBox {
                background: #d99246;
              }
            }
            &.country-sec{
              .user-saved-country{
                img{
                  width: 40px;
                  height: 40px;
                }
              }
            }
            &.action-td {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.playerDetails {
              .playerInfo {

                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                // @media only screen and (max-width: 374px){
                //   flex-direction: column;
                //   align-items: flex-end;
                // }
                .profile-info {
                  display: flex;
                  align-items: center;
                  @media only screen and (max-width: 767px) {
                    justify-content: center;
                    img{
                      margin-right: 0px !important;
                    }
                   }

                  img {
                    border: 2px solid #fff;
                    border-radius: 100%;
                    box-sizing: border-box;
                    height: 40px;
                    margin-right: 20px;
                    //object-fit: cover;
                    width: 40px;
                    object-fit: contain;
                    background-color: #0E1216;
                    @media only screen and (max-width: 389px) {
                      height:32px;
                      width:32px;
                   }
 
                  }

                  .playerInfo-inner {
                    display: flex;
                    flex-direction: column;
                    @media only screen and (max-width: 767px) {
                      display: none
                     }

                    span {
                      color: gold !important;
                      font-size: 14px;
                      padding-top: 5px;
                    }
                  }
                }

                .streamer-sec {
                  img {
                    @media only screen and (max-width: 767px) {
                      margin-left: 5px;
                    }
                  }
                }
              }
            }

            // &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            // &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              text-align: center;
            }

            &.GB_device_td {
              &::before {
                position: relative;
                top: 0px;
                @media only screen and (max-width: 767px)
              {
                content: "";

              }
              }
              @media only screen and (max-width: 767px)
              {
              display: table-cell;

              }
            }

            &:nth-child(6) {
              text-align: left;
            }

            &.ticket-details {
              .playerInfo {
                justify-content: center;
              }
            }

            .bet-in-progress {
              color: #ffffff;
              opacity: 0.6;
            }

            .bet-failed {
              color: #c80000;
            }

            .show-result-btn {
              background: #31ff1f;
              text-decoration: none;
              border-radius: 6px;
              padding: 1px 9px;
              line-height: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 23px;
              color: #000000;
              text-transform: uppercase;
              font-family: "Gilroy-Bold";
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }

    #streamerBetHistory_Table {
      thead {
        @media only screen and (max-width: 767px) {
          display: none;
        }

        tr {
          border-bottom: none;

          th {

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              text-align: center;
            }

            // &:nth-child(6) {
            //   text-align: left;
            // }

            &:first-child {
              border-top-left-radius: 10px;
              text-align: center;
            }

            &:last-child {
              border-top-right-radius: 10px;
            }

            &.ticket-info {
              text-align: left;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            color: #ffffff;
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 100%;
            vertical-align: middle;
            height: 60px;

            @media only screen and(min-width: 1366px) and(max-width: 1919px) {
              height: 45px;
            }

            &:nth-child(2) {
              text-align: left;
            }

            &.rankNo {
              .rankBox {
                border-radius: 10px;
                display: flex;
                font-family: "Gilroy-Bold";
                font-size: 18px;
                height: 32px;
                justify-content: center;
                align-items: center;
                line-height: 100%;
                text-align: center;
                width: 32px;

                @media only screen and (min-width: 768px) {
                  margin: auto;
                }

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .redBox {
                background: #e52420;
                color: #fff;
              }

              .goldBox {
                background: #fda829;
              }

              .silverBox {
                background: #444444;
              }

              .bronzeBox {
                background: #d99246;
              }
            }

            &.action-td {
              display: flex;
              align-items: center;
              justify-content: center;
            }

            &.playerDetails {
              .playerInfo {

                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                // @media only screen and (max-width: 374px){
                //   flex-direction: column;
                //   align-items: flex-end;
                // }
                .profile-info {
                  display: flex;
                  align-items: center;

                  img {
                    border: 2px solid #fff;
                    border-radius: 100%;
                    box-sizing: border-box;
                    height: 50px;
                    margin-right: 20px;
                    object-fit: cover;
                    width: 50px;
                  }

                  .playerInfo-inner {
                    display: flex;
                    flex-direction: column;

                    span {
                      color: gold !important;
                      font-size: 14px;
                      padding-top: 5px;
                    }
                  }
                }

                .streamer-sec {
                  img {
                    @media only screen and (max-width: 767px) {
                      margin-left: 5px;
                    }
                  }
                }
              }
            }

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7),
            &:nth-child(8) {
              text-align: center;
            }

            .green-text {
              color: #31ff1f;
            }

            .red-text {
              color: #c80000;
            }

            &.GB_device_td {
              &::before {
                position: relative;
                top: 0px;
              }
            }

            // &:nth-child(6) {
            //   text-align: left;
            // }

            .bet-in-progress {
              color: #ffffff;
              opacity: 0.6;
            }

            .bet-failed {
              color: #c80000;
            }

            .show-result-btn {
              background: #31ff1f;
              text-decoration: none;
              border-radius: 6px;
              padding: 1px 9px;
              line-height: 1px;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 23px;
              color: #000000;
              text-transform: uppercase;
              font-family: "Gilroy-Bold";
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
    }

    // .pagination-full-section {
    //   margin: 40px 0px;

    //   @media only screen and (max-width: 767px) {
    //     margin: 20px 0px;
    //   }

    //   .paginate {
    //     margin: 0px 13px;
    //     background: rgba(0, 0, 0, 0.5);
    //     border: 1px solid #4f4f4f;
    //     box-sizing: border-box;
    //     border-radius: 10px;
    //     padding: 4px 4px;
    //     justify-content: center;
    //     align-items: center;

    //     ul {
    //       margin-bottom: 0px;
    //       align-items: center;
    //       justify-content: center;
    //       display: flex;
    //       position: relative;

    //       li {
    //         list-style-type: none;
    //         margin-right: 10px;

    //         a {
    //           font-family: "Gilroy-Bold";
    //           font-size: 18px;
    //           line-height: 100%;
    //           text-align: center;
    //           color: #ffffff;
    //           background-color: transparent;
    //           border: none;

    //           &:focus {
    //             box-shadow: none;
    //           }
    //         }

    //         &.previous {
    //           position: absolute;
    //           left: -61px;

    //           a {
    //             background: url(../../images/rightarrow.png) 0px 0px no-repeat;
    //             height: 50px;
    //             width: 50px;
    //           }
    //         }

    //         &.next {
    //           position: absolute;
    //           right: -61px;

    //           a {
    //             background: url(../../images/leftarrow.png) 0px 0px no-repeat;
    //             height: 50px;
    //             width: 50px;
    //           }
    //         }

    //         &.active {
    //           background: #e52420;
    //           border-radius: 8px;
    //           height: 42px;
    //           width: 42px;
    //           vertical-align: middle;
    //           align-items: center;
    //           justify-content: center;
    //           display: flex;
    //         }

    //         &:last-child {
    //           margin-right: 0px;
    //         }

    //         &:first-child {
    //           margin-right: 0px;
    //         }

    //         &:nth-last-child(2) {
    //           margin-right: 0px;
    //         }
    //       }
    //     }
    //   }
    // }
    .redo_lb_mob_table
    {
     @media only screen and (max-width: 767px) {
   display: none;
     }
    }
   
  }
}

._GB_CB_Container input:checked~.checkmark {
  background-color: #31ff1f;
  border: 2px solid #31ff1f;
}

// --------------------------------------------Create Tournament-----------------------------------------------------


._GBredoTournament {
  display: flex;
  position: relative;
  top: 35px;
  @media only screen and (max-width: 1024px) {
    top: 0px;
  }
  
  .common_wrapper {
    width: 100%;
    max-width: 100%;
    background-image: url("../../images/redo/betting_bg.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 33% 100%;
    background-color: #0E1216;
    @media only screen and (max-width: 767px){
      background-image: none;
    }

    .tournament_wrap {
        border: 1px solid rgba(59, 77, 94, 0.5);
        border-top-left-radius: 18px;
        border-bottom: none;
        @media only screen and (max-width: 1024px) {
          border: none;
        }

        .rtournament_Inner {
            max-width: 100%;
            width: 100%;

            @media only screen and (min-width: 1366px) {
                max-width: 978px;
                width: 100%;
                margin: auto;
            }
        }
    }
}
._GB_customtournament{


  .step_details,
  .step_right,
  .step_secondRight,
  .step_threeRight {
    max-width: 560px !important;

  }
  .dash_no_games{
    @media only screen and (max-width: 500px) {
      flex-direction: column;
      .dash_no_games_right {
        width: 100%;
        button{
          border: none;
        }
    }
      }
      .dash_no_games_left {
        @media only screen and (min-width: 1024px) {
        flex: 0 0 70%;
        }
    }

  }
  .dashAjaxLoader.bgIt.mt-2 {
    @media only screen and (min-width: 768px) and (max-width:1365px){
      max-width: 95%;
      margin: auto;
     }

}
  margin-top: 40px;
  padding-bottom: 60px;
  @media only screen and (max-width: 767px) {
   padding:16px 20px;
   margin-top: 0px;
   }

   .customtournament_firstSec{
    .customtournament_left {
      max-width: 164px;
      width: 100%;
      @media only screen and (max-width: 767px) {
        display: none;
       }
  }

  .customtournament_right {
  //   &.no_game {
  //     margin: auto;
  // }

      .customtournament_title {
          font-size: 24px;
          line-height: 26px;
          color: #fff;
          margin-bottom: 5px;
          font-family: "Gilroy-Medium";
          @media only screen and (max-width: 1024px) {
            font-size: 20px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 8px;
           }
      }

      .customtournament_SubTitle {
          color: #92A6BA;
          font-size: 16px;
          line-height: 24px;
          font-family: "Gilroy-Regular";
          @media only screen and (max-width: 767px) {
            font-size: 14px;
           }
      }

         
  }

   }

      .customtournament_step {
        width: 100%;
        @media only screen and (min-width: 768px) and (max-width: 1350px) {
          padding-right:20px;
          }
        .CmnWidth {
          max-width: 719px!important;
         }

        .step_one {
            margin-top: 40px;
            @media only screen and (max-width: 767px) {
              margin-top: 24px;
             }

            .stepNum {
                margin-left: 35px;
                margin-right: 15px;
                padding-right: 5px;
                // @media only screen and (min-width: 768px) and (max-width:1024px){
                //   margin-left: 50px;
                //  }
            }

            .selection_tab {
                img {
                    margin-top: 41px;
                }
            }
            .step_right {
              max-width: 731px;
              width: 100%;
              margin-left: 24px;
              position: relative;
              @media only screen and (max-width: 767px) {
                margin-left: 0px;
               }
  
              &::before {
                  border-left: 3px dashed #3B4D5E;
                  content: "";
                  height: 100%;
                  position: absolute;
                  left: -36px;
                  top: 72px;
                  @media only screen and (max-width: 767px) {
                    display: none;
                   }
                  //  @media only screen and (min-width: 768px) and (max-width:1024px){
                  //   height: 96%;
                  //   top: 65px;
                    
                  //  }
                  //  @media only screen and (min-width: 1025px) and (max-width:1320px){
                  //   top: 61px !important;
                  //  }
              }
  
              &.activeStep {
                  &::before {
                      border-left: 3px dashed #3B4D5E;
                      content: "";
                      height: 96%;
                      position: absolute;
                      left: -36px;
                      top: 72px;
                      @media only screen and (max-width: 767px) {
                        display: none;
                       }
                  }
              }
              .step_details {
                border: 1px solid rgba(59, 77, 94, 0.5);
                border-radius: 24px;
                height: 60px;
                max-width: 731px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 24px 32px;
                color: #fff;
                font-size: 20px;
                line-height: 30px;
                font-family: "Gilroy-Medium";
                @media only screen and (max-width: 767px) {
                  height: 64px;
                  font-size: 16px;
                  line-height: 24px;
                }

                &.pointerEV {
                    cursor: pointer;
                }


                // .list_icon{
                //     cursor: pointer;
                // }

            }

          }

            

        }
        .step_two {
            .stepNum {
                margin-left: 35px;
                margin-right: 15px;
            }

            .selection_tab {
                img {
                    margin-top: 41px;
                }
            }

            .step_secondRight {
                max-width: 731px;
                width: 100%;
                margin-left: 24px;
                margin-top: 22px;
                position: relative;
                @media only screen and (max-width: 767px) {
                  margin-left: 0px;
                  margin-top: 16px;
                 }

                &::before {
                  border-left: 3px dashed #3B4D5E;
                  content: "";
                  height: 100% !important;
                  position: absolute;
                  left: -36px;
                  top: 42px !important;
                  @media only screen and (max-width: 767px) {
                    display: none;
                   }
                   @media only screen and (min-width: 768px) and (max-width:1024px){
                    left: -34px;
                    top: 39px !important;
                   }
                   @media only screen and (min-width: 1025px) and (max-width:1320px){
                    left: -34px;
                    top: 40px !important;
                   }
                }

                &.activeStep {
                    &::before {
                      border-left: 3px dashed #3B4D5E;
                      content: "";
                      height: 96% !important;
                      position: absolute;
                      left: -36px;
                      top: 43px !important;
                      @media only screen and (max-width: 767px) {
                        display: none;
                       }
                    }
                }

                .step_details {
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    border-radius: 24px;
                    height: 60px;
                    max-width: 731px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 24px 32px;
                    color: #fff;
                    font-size: 20px;
                    line-height: 30px;
                    font-family: "Gilroy-Medium";
                    @media only screen and (max-width: 767px) {
                      height: 64px;
                      font-size: 16px;
                      line-height: 24px;
                    }

                    &.pointerEV {
                        cursor: pointer;
                    }

                    // .list_icon{
                    //     cursor: pointer;
                    // }
                }


            }

        }
        .step_three {
            .stepNum {
                margin-left: 35px;
                margin-right: 15px;
            }

            .selection_tab {
                img {
                    margin-top: 41px;
                }
            }

            .step_threeRight {
                max-width: 731px;
                width: 100%;
                margin-left: 24px;
                margin-top: 40px;
                position: relative;
                @media only screen and (max-width: 767px) {
                  margin-left: 0px;
                  margin-top: 16px;
                 }
                .step_details {
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    border-radius: 24px;
                    height: 60px;
                    max-width: 731px;
                    width: 100%;
                    align-items: center;
                    padding: 24px 32px;
                    color: #fff;
                    font-size: 20px;
                    line-height: 30px;
                    font-family: "Gilroy-Medium";
                    @media only screen and (max-width: 767px) {
                      height: 64px;
                      font-size: 16px;
                      line-height: 24px;
                    }

                    &.pointerEV {
                        cursor: pointer;
                    }


                }

               
            }
        }

        .tournamentBtn_sec {
            width: 100%;
            margin-bottom: 40px;

            .tournamentBtn_left {
                max-width: 161px;
                width: 100%;
                @media only screen and (max-width: 767px) {
                  display: none;
                 }
            }

            .tournamentBtn_right {
                width: 100%;
                margin-left: 0px;
                gap: 8px;
                  max-width: 560px !important;
                  margin-top: 40px;
                  @media only screen and (max-width: 767px) {
                    margin-top: 24px;
                    
                   }


                .tournamentCommonBtn {
                    border-radius: var(--Spacing-2xl, 24px);
                    background: var(--Background---grey-Grey---400---GB, #3B4D5E);            
                    width: 100%;
                    height: 51px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 10px;
                    cursor: pointer;
                    color: #fff;
                    font-size: 14px;
                    line-height: 24px;
                    font-family: "Gilroy-SemiBold";
                    @media only screen and (max-width: 767px) {
                      font-size: 16px;
                      border-radius: 16px;
                     }

                    &:hover {
                        opacity: 0.5;
                    }

                    &.selectedBtn {
                        background-color: #F1FEEC;
                        color: #0E1216;

                        &::after {
                            content: "";
                            background-image: url("../../images/redo/arrow-right.png") !important;
                            background-repeat: no-repeat;
                            height: 24px;
                            width: 25px;
                            background-size: contain;
                        }
                    }

                    &.continueBtn {
                        background-color: #F1FEEC;
                        color: #0E1216;
                        padding: 10px;
                        @media only screen and (max-width: 767px) {
                          gap:8px;
                         }

                    }

                    &.hasArrow {

                        &::after {
                            content: "";
                            background-image: url("../../images/redo/arrow-right.png");
                            background-repeat: no-repeat;
                            height: 26px;
                            width: 32px;
                            margin-left: 3px;
                        }

                        &:hover {
                            &::after {
                                content: "";
                                background-image: url("../../images/redo/arrow-right.png");
                                background-repeat: no-repeat;
                                height: 26px;
                                width: 32px;
                            }
                        }
                    }

                    &.btnAjaxLoading {
                        .text-light {
                            color: #181f26 !important;
                            margin-right: 5px !important;
                        }
                    }
                }

            }
        }

    }
    .stepNum {
      @media only screen and (max-width: 767px) {
        display: none;
       }
  }

  .selection_tab {
    @media only screen and (max-width: 767px) {
      display: none;
     }
  }
  .gbt-err-text {
    color: #e52420;
    font-family: "Gilroy-Medium";
    padding: 0px 5px;
    font-size: 14px;
    margin-top: 5px;
}
.rdBetgames {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 10px 20px;

  .rdBetgame {
      //border: 1px solid rgba(59, 77, 94, 0.5);
      //border-radius: 8px;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    // flex: 0 0 20%;
    //   max-width: 20%;
      @media only screen and (max-width: 767px) {
        flex: 0 0 20%;
        max-width: 20%;
       }
      //background: rgba(64, 78, 55, 0.3);
            .whiteImg {
              display: block;
            }
      
            .blackImg {
              display: none;
            }
      &:hover,
      &.active {
          //background: rgba(116, 243, 59, 0.5);
          border-radius: var(--Spacing-lg, 8px);
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Primary-Green---600---GB, #51E20E);
          box-shadow: 0px 2px 4px 0px rgba(116, 243, 59, 0.04), 0px 24px 48px -8px rgba(116, 243, 59, 0.12);

          .whiteImg {
              display: none;
            }
          
            .blackImg {
              display: block;
            }
      }
  }
}
.no-games{
  color: #e52420;
  font-family: "Gilroy-Medium";
  padding: 10px 0px;
  font-size: 16px;

}
.fileBtns{
  display: flex;
  flex-wrap: wrap;
  gap:10px

}


  // .allSteps {
  //   &::before {
  //     border-left: 3px dashed #3B4D5E !important;
  //     content: "" !important;
  //     height: 96% !important;
  //     position: absolute !important;
  //     left: -64px !important;
  //     top: 70px !important;
  //   }


  // }

  // .step_secondRight::before {
  //   border-left: 3px dashed #3B4D5E;
  //   content: "";
  //   height: 90% !important;
  //   position: absolute;
  //   left: -64px;
  //   top: 52px !important;
  // }

  .floating-label-group {
    position: relative;

    .floating-label {
      position: absolute;
      pointer-events: none;
      top: 36px;
      left: 16px;
      transition: all 0.1s ease;
      color: #9296A1;
      // padding: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      font-family: "Gilroy-Medium";
      @media only screen and (max-width: 767px) {
        font-size: 12px;
       }

      span {
        color: #4EFF61
      }

    }

    input:focus~.floating-label,
    select:focus~.floating-label,
    input:not(:focus):valid~.floating-label,
    select:not(:focus):valid~.floating-label {
      top: 25px !important;
      bottom: 0px;
      left: 16px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      font-family: "Gilroy-Medium";
      color: #9296A1;
    }
  }
  .entry_info{
    @media only screen and (max-width: 375px){
      flex-direction: column;
    }
  }
  .inputData {
    border-radius: 24px;
    height: 60px;
    background-color: #181F26;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff !important;
    font-size: 14px;
    line-height: 26px;
    padding: 21px 16px 10px;
    font-family: "Gilroy-Medium";
    border: none;
    box-shadow: none;
    margin-top: 16px;
    position: relative;
    &.logoUpload{
      display: flex;
      align-items: center;
      padding: 0 16px ;
      @media only screen and (max-width: 767px) {
        height: auto;
        padding:16px;
       }

    }

    &:focus-visible {
      outline: none !important;
    }
    @media only screen and (max-width: 767px) {
      font-size: 15px;
     }
  }

  select.inputData {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: #181F26 url("../../images/redo/arrow-down.png") 94% 50% no-repeat;
    background-size: 20px;
    @media only screen and (max-width: 400px) {
      background-size: 16px;

     }

  }
  .list_icon{
    cursor: pointer;

    @media only screen and (min-width: 768px) {
      max-width: 20px;
     }

  }

  .formControl {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    &.specialSec{
      margin-bottom: 26px;
      .not_allowed {
        opacity: .4;
        pointer-events: none;
    }
    }

    .nameSec {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      h6 {
        margin-bottom: 0px;
          font-size: 14px;
          word-break: break-word;
          max-width: 340px;

         
        ;
      }
    }

    .formSec {
      flex: 0 0 47%;
      max-width: 47%;
      @media only screen and (min-width: 1201px){
        flex: 0 0 49%;
        max-width: 49%;
    }

      p {
        color: #F1FEEC;
        font-family: 'Inter-Regular';
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0px;
        margin-top: 5px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

  }


  input[type=date]:required:invalid::-webkit-datetime-edit {
    color: transparent;
  }

  input[type=date]:focus::-webkit-datetime-edit {
    color: #9296A1 !important;
  }

  [type="date"] {
    background: #181F26 url("../../images/redo/calendar.png") 94% 50% no-repeat;
  }

  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  [type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
  .stepOne_details {
    margin-top: 14px;
    .seclabel {
      color: #fff;
      font-size: 16px;
      line-height: 26px;
      font-family: "Gilroy-Medium";
      margin-top: 24px;
      margin-bottom:8px;
      @media only screen and (max-width: 767px){
        font-size: 14px;
        line-height: 24px;
      }
      span {
        color: #4EFF61
      }
  }

    .matchTypes {
      gap: 80px;

      @media only screen and (max-width: 767px) {
        gap: initial;
       }
      .matchOpt {
        max-width: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        gap:10px;
        img{
          cursor: pointer;
          border-radius: 50%;
          background: transparent;
        }

        input {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }

        label {
          color: #E3E8ED;
          font-size: 16px;
          line-height: 24px;
          font-family: 'Inter-Regular';
          @media only screen and (max-width: 767px){
            font-size: 14px;
            line-height: 24px;
          }

        }

      }
    }


    .logoAddBtn {
      color: #153A04;
      font-family: "Gilroy-Bold" !important;
      font-size: 10px;
      line-height: 21px;
      padding: 4px 8px;
      border-radius: 12px;
      border: 1.5px solid #C9FAB2;
      background: #74F33B;

      &:hover {
        opacity: 0.5;
      }

    }

    input[type="radio"] {
      background-color: #3b4d5e !important;
      appearance: none;
      -webkit-appearance: none;
      border-radius: 50%;
      cursor: pointer;
    }

    input[type=radio]:checked {
      background-color: #E0FFF8 !important;
      border: 6px solid #74F33B !important;
    }

    &::-webkit-input-placeholder {
      color: #fff;
    }

    .searchSec {
      max-width: 100% !important;
    }

  }
  .stepTwo_details {
    margin-top: 24px;
    .content {
      margin-top: 30px;
        border: none !important;
        padding: 0px !important;
    
      h5 {
        color: #FFF;
        font-family: "Gilroy-Medium";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      p {
        color: #74F33B;
        font-family: "Gilroy-Medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 0px;
      }
    }
  }
  .stepthree_details{
    margin-top: 30px;
    @media only screen and (max-width: 767px){
      margin-top: 22px;
      justify-content: flex-start;
      }
    .disabled-input{
      position: relative;
      margin-bottom: 8px !important;
      p{
        margin:0px !important
      }
      .label-top {
        position: absolute;
        top: 3px;
        bottom: 0px;
        left: 16px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        font-family: "Gilroy-Medium";
        color: #9296A1;
      }
    }
    .places-section{
      .formControl{
        @media only screen and (min-width: 1201px){
        justify-content: flex-start;
        }
      }
      .formThrid{
        flex:0 0 30.9%;
        max-width: 30.9%;
        @media only screen and (min-width: 1201px){
          flex:0 0 32.1%;
          max-width: 32.1%;
      }
      }
      .inputData{
        &.input-err{
          border:1px solid #e52420;
        }
      }
    }
  }
  .tournament_gbt_form_field_date{
    .start_date_field_display{
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
.rdrDateRangePickerWrapper {
      display: block !important;
  }
  ._gbt_start_end {
      .range-calander {
    bottom: -1px;
}

  }

    .rdrDefinedRangesWrapper {
      display: none !important;
  }
    ._gbt_start,._gbt_end{
      flex:0 0 49%;
      gap: 10px;
      border-radius: 24px;
      height: 60px;
      background-color: #181F26;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff !important;
      font-size: 14px;
      line-height: 26px;
      padding: 21px 16px 10px;
      font-family: "Gilroy-Medium";
      border: none;
      box-shadow: none;
      margin-top: 16px;
      position: relative;
      @media only screen and (max-width: 767px) {
        flex:0 0 100%;
       }
       .start_date_field_cal {
        cursor: pointer;
    }

      .search-admin-form-control {
        background: #181f26 !important;
        border: 2px solid #181f26;
        line-height: 24px;
        font-size: 14px;
        height: 47px;
        font-family: "Gilroy-Medium";
        &:focus-visible{
          outline: none !important;
        }
      }
      .floating-label {
        position: absolute;
        pointer-events: none;
        top: 12px;
        left: 16px;
        transition: all 0.1s ease;
        color: #9296A1;
        // padding: 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        font-family: "Gilroy-Medium";
        @media only screen and (max-width: 767px) {
          font-size: 12px;
         }
  
        span {
          color: #4EFF61
        }
  
      }
    }
  }


}
}
.tournamentlogoupload_Modal{
  .modal-content {
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.50);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---500---GB, #181F26);
    .modal-header h5 {
      padding: 15px 15px 0;
      color: #FFF;
      text-align: center;
      font-family: Gilroy-Medium;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
  }
  .modal-header{
    border: none !important;
  }
  .modal-footer{
    border: none !important;
    button.btn.btn-secondary {
      display: none;
  }
  }
  .dzu-dropzone {
    overflow: auto !important;
    border: 2px solid #fff0;
    border-radius: var(--Spacing-lg, 16px);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---700---GB, #0E1216);
    .dzu-previewContainer {
      border: none!important;
      justify-content: space-evenly!important;
      padding: 5px 15px!important;
      @media only screen and (max-width: 575px) {
        gap: 10px;
      }
      span.dzu-previewButton {
        background: url("../../images/redo/bet-close-circle.png") no-repeat !important;
        width: 24px;
        height: 24px;
        &:hover{
          opacity: 0.6;
        }
      }
      progress::-webkit-progress-value {
        background-color: red  !important;
     }
  }
}
.btn-close {
  box-shadow: none;
  outline: none;
  background: url("../../images/redo/bet-close-circle.png") no-repeat;
  opacity: 1;
  &:hover{
    opacity: 0.6;
  }
}
button.dzu-submitButton {
  padding: 7px 20px;
  border-radius: 20px;
  background: #F1FEEC;
  box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.04), 0px 24px 48px -8px rgba(241, 254, 236, 0.12);
  width: 100%;
  color: #020303;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  &:hover{
    opacity: 0.6;
  }
}
 .dzu-inputLabel {
  color: #ffffff;
  font-family: "Gilroy-Bold";
}
  }
}
.tourRulesModel{
  .modal-dialog.modal-dialog-centered {
    max-width: 450px;
    padding: 0px !important;
    margin: auto;
}
.modal-header {
    justify-content: flex-end;
    padding-bottom: 0px;
}
.modal-body{
  @media only screen and (max-width: 575px) {
    padding-top: 0px;
  }
  

}
.modal-content {
  @media only screen and (max-width: 575px) {
    margin: 10px;;
  }
  .rulesCloseBtn{
    @media only screen and (max-width: 575px) {
      font-size: 16px !important;
      padding: var(--Spacing-lg, 10px) var(--Spacing-2xl, 24px) !important;
    }

  }
}
  .btn-close {
    box-shadow: none;
    outline: none;
    height: 24px;
    width:24px;
    margin:0px;
    padding: 0px;
    background: url("../../images/redo/bet-close-circle.png") no-repeat;
    opacity: 1;
    &:hover{
      opacity: 0.6;
    }  
  }
  .rulesContent{
    margin: 15px 10px !important;
    @media only screen and (max-width: 575px) {
      margin: 0px !important;
    }
  }
  .rulesHeadImg {
    // padding: 0px 20px !important;

    background-position: 50% 39%  !important;


}

  .tourRules{
    padding: 0px 10px 10px;
    @media only screen and (max-width: 575px) {
      padding: 16px 0px 10px;
    }
   input:checked ~ .checkmark:after {
      border: solid #181f26 !important;
      border-width: 0 2px 2px 0 !important;
      top: 3px;
      left: 7px;
      width: 8px;
      height: 12px;
      @media only screen and (max-width: 575px) {
        top: 2px;
      }
  
  }
  .gbt_agree_label {
    font-family: "Gilroy-Medium";
    font-size: 14px;
    color: #92A6BA;
    @media only screen and (max-width: 575px) {
      font-size: 12px;
    }
  
  }
  .checkmark {
    top: 4px;
    left: 0;
    height: 26px;
    width: 26px;
    border-radius: 10px;
    background: #181f26;
    @media only screen and (max-width: 575px) {
      height: 24px;
      width: 24px;
    }
    &:after{
      border: solid #00000000;
    }
  }


  }

}
.tourConfirmModal{
  .modal-dialog.modal-dialog-centered {
    max-width: 450px;
    margin: auto;
}
&.tourThanksModel{
  .rulesParaTag{
    color: var(--Background---grey-Grey---200---GB, #92A6BA);
    text-align: center;
    font-family: "Gilroy-Medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    max-width: 350px;

  }
  .rulesHeadImg{
    display: flex;
    justify-content: center;
    background-image: url("../../images/redo/tour_thanks_icon-svg.svg") !important;
    background-position: 51% 43% !important;
    background-repeat: no-repeat;
    margin-bottom: 24px;
    @media only screen and (max-width: 575px) {
      background-image: url("../../images/redo/tour_thanks_green_svg.svg") !important;
      background-position: 54% 23% !important;
    }
    img{
      animation: rotateRight 90s linear infinite;
    }

    .desktopImg{
      display: block;
      margin-left: 15px;
    }
    .mobileImg{
      display: none;
    }
    @media only screen and (max-width: 575px) {
      .mobileImg{
        display: block;
      }
      .desktopImg{
        display: none;
      }
    }
  }
 .rulesFooter a {
  padding: 13px 69px;
  border-radius: 20px;
  font-family: 'Inter-Medium';
  font-size: 14px;
  line-height: 24px;
  border:none;
  color: #0E1216;
  background: #fff;
  width: 100%;
  text-align: center;
  text-decoration: none;
  &.mobileBtn{
    display: none;
  }
  @media only screen and (max-width: 575px) {
    padding: 10px 69px; ;
    font-size: 12px;
    &.mobileBtn{
      display: block;
    }
    &.desktopBtn{
      display: none;
    }
  }
    &:hover{
      opacity: 0.6;
    }
  }
}
  .modal-content {
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.5);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---500---GB, #181F26);
    @media only screen and (max-width: 575px) {
      margin: 16px;;
    }
}
.modal-header {
  justify-content: flex-end;
  padding-bottom: 0px;
  border:none;
}
.btn-close {
  box-shadow: none;
  outline: none;
  height: 24px;
  width:24px;
  margin:0px;
  padding: 0px;
  background: url("../../images/redo/bet-close-circle.png") no-repeat;
  opacity: 1;
  &:hover{
    opacity: 0.6;
  }  
}
.modal-body {
  padding: 0px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

.rulesHead {
  text-align: center;
  
  .rulesHeadImg.tourRule{
    background-image: url("../../images/redo/tour_warning-svg.svg") !important;
    background-repeat: no-repeat;
    background-position: 53% 36%;
    margin-bottom: 24px;
    img{
      animation: rotateRight 90s linear infinite;
    }
  }
.rulesHeadTitle {
    color: #FFF;
    text-align: center;
    font-family: "Gilroy-Medium";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 16px;
}
.rulesHeadTag {
  color:#92A6BA;
  text-align: center;
  font-family: "Gilroy-Bold";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (max-width: 575px) {
    font-size: 14px;
  }
  
  span{
    color:#92A6BA;
    text-align: center;
    font-family: "Gilroy-Regular";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

  }

}
}
.rulesFooter {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  button{
    flex:0 0 49%;
    padding: 13px 69px;
    border-radius: 20px;
    font-family: 'Inter-Medium';
    font-size: 14px;
    line-height: 24px;
    border:none;
    @media only screen and (max-width: 575px) {
      padding: 10px 35px;
      font-size: 12px;
    }
    &:hover{
      opacity: 0.6;
    }
    &.tourConfirmBtn {
      color: #0E1216;
  }
  &.tourCancelBtn {
    background: #3B4D5E;
    color: #fff;
    text-transform: capitalize;
}

  
  }
}
}
}

.range-calander{
  .rdrCalendarWrapper {
    background: #0e1216 !important;
  }
  .rdrDateDisplay {
      display: none !important;
  }
  button.rdrDay.rdrDayDisabled {
    background-color: rgba(0, 0, 0, 0.1) !important;
    cursor: default !important;
  }
  .calander-close {
    right: 4%;
    top: 2%;
  }
  }
 .tour-land-info {
      cursor: pointer;
        display: flex;
        align-items: center;
 }
 .redo-gbt-not-approved-text {
   color: var(--Background---grey-Grey---200---GB, #92A6BA);
   font-size: 38px;
   line-height: 38px;
   font-family: "Gilroy-SemiBold";
   padding: 15px 0 10px 0;
   @media only screen and (max-width: 767px){
    font-size: 24px;
    line-height: 24px;
    padding: 8px 0;
   }
 }
 .active-tour-image {
   flex: 0 0 22%;
   max-width: 22%;
   display: flex;
   align-items: center;
   justify-content: center;
 }


 .redo_lb_mob_table
 {
  @media only screen and (max-width: 767px) {
display: none;
  }
 }
 @keyframes pulseAnimation {
  0% {
    box-shadow: 0 0 0 0px rgba(116,243,59, 0.4);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(116,249,59,0);
  }
}
@keyframes wave {
  0% {
    background-position: 0 30%;
  }
  100% {
    background-position: 100% 30%;
  }
}
@keyframes rotateRight {
  0% {
      transform: rotate(0deg);
  }
  50% {
      transform: rotate(180deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
.filterModelMobile{
  .modal-content {
    border-radius: var(--Spacing-3xl, 32px);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---600---GB, #141A1F);
    margin: 8px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
flex-wrap: wrap;
border-bottom: var(--bs-modal-header-border-width) solid #3B4D5E !important;
  .rt_filter_left {
      color: #FFF;
      font-family: "Gilroy-Medium";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
      gap: 5px;
      flex:0 0 57%;
      @media only screen and (max-width: 350px){
        flex:0 0 52%;
      }
  }
  .btn-close{
    color: #fff;
    outline: none;
    background: url("../../images/redo/popup_close.png") no-repeat center center;
    margin-left: 0;
    opacity: 1;

  }


  .rt_filter_clear {
      border-radius: var(--Spacing-md, 12px);
      background: var(--Background---grey-Grey---400---GB, #3B4D5E);
      display: flex;
      max-width: 76px;
      width: 100%;
      padding: var(--Spacing-sm, 8px) var(--Spacing-xl, 20px);
      justify-content: center;
      align-items: center;
      gap: var(--Spacing-sm, 8px);
      flex-shrink: 0;
      color: #FFF;
      cursor: pointer;
      /* Caption/Caption 10/$caption 10-medium */
      font-family: "Gilroy-Medium";
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      &:hover{
        opacity: 0.5;
      }
      /* 166.667% */
  }
}
  .rt_list_section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @media only screen and (min-width: 1025px){
        gap: 15px;
    }
    .rt_filter {
        flex: 0 0 20%;
        max-width: 20%;
        color: #fff;
        @media only screen and (max-width: 1365px){
          flex: 0 0 100%;
          max-width: 100%;
        }
        .rt_filter_inner {
            // border-radius: var(--Spacing-3xl, 32px);
            // opacity: var(--Corner-radius-xs, 1);
            // background: var(--Background---grey-Grey---600---GB, #141A1F);
            padding: 20px;
            height: 100%;
            @media only screen and (max-width: 1600px){
              padding: 0px;
              height: 450px;
              overflow-y: scroll;
              padding-right: 10px;
              &::-webkit-scrollbar {
                width: 6px;
            }
          
            /* Track */
            &::-webkit-scrollbar-track {
              background: #181F26;
              border-radius: 9px;
            }
          
            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #74F33B;
              border-radius: 9px;
            }
          
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #74F33B;
            }
            }


            .rt_filter_list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: var(--Spacing-4xl, 40px);
                margin-top: 30px;
                @media only screen and (max-width: 1600px){
                    margin-top: 20px;
                    gap: var(--Spacing-4xl, 20px);
                }
                .rt_filter_list_each {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--Spacing-2xl, 15px);
                    width: 100%;

                    .rt_filter_list_head {
                        color: var(--Background---grey-Grey---200---GB, #92A6BA);
                        font-family: "Gilroy-Medium";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        @media only screen and (max-width: 1600px){
                            font-size: 14px;
                        }
                        .rt_da{
                            cursor: pointer;
                            &.opened {
                                    transform: rotate(180deg);
                                }
                        }
                           
                    }

                    .rt_filter_list_search {
                        border-bottom: var(--Spacing-3xs, 2px) solid var(--Colors-Grayscale-Border-Disable, #DCDEE8);
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 10px;

                        input {
                            flex: 0 0 85%;
                            max-width: 85%;
                            opacity: var(--Corner-radius-xs, 1);
                            color: var(--Colors-Grayscale-Text-icon-Subtitle, #767A87);
                            font-family: "Gilroy-Regular";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            border: none;
                            background: transparent;
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    .rt_filter_list_items {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: var(--Spacing-2xl, 10px);
                        @media only screen and (max-width: 1365px){
                          flex-direction: row;
                          flex-wrap: wrap;
                        }
                        .rt_filter_list_item {
                            color: #FFF;
                            font-family: "Gilroy-Medium";
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            gap: 15px;
                            cursor: pointer;
                            word-break: break-all;
                            @media only screen and (max-width: 1600px){
                                font-size: 14px;
                                flex: 0 0 100%;
                            }
                            .rt_cb {
                                cursor: pointer;
                                img{
                                  width: 18px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }


}
.rulesFooter{
  text-align: center;
  button.rulesCloseBtn {
    padding: 13px 69px;
    border-radius: 20px;
    font-family: 'Inter-Medium';
    font-size: 14px;
    line-height: 24px;
    border: none;
    margin-top: 20px;
    color: #0E1216;
  }
}
}
.redo_tour_land_end_date {
  display: flex;
  align-items: center;
  color: var(--Background---grey-Grey---200---GB, #92A6BA);
  font-family: "Gilroy-Medium";
  font-size: 16px;
  font-style: normal;
  gap: 10px;
  padding: 10px 0;
  @media only screen and (max-width: 767px){
    font-size: 14px;
  }
  @media only screen and (max-width: 340px){
    gap: 1px;
  }
}
.missing-input-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
      width: 100%;
        gap: 10px;
.missing-input-label {
    color: var(--Background---grey-Grey---200---GB, #92A6BA);
    font-family: "Gilroy-Medium";
    font-size: 14px;
    font-style: normal;
  }

  .missing-input-text {
    border-radius: 20px;
    background: #181f26;
    box-shadow: none;
    outline: none;
    border: none;
    height: 45px;
    padding: 10px 20px;
    color: var(--Background---grey-Grey---200---GB, #92A6BA);
    font-family: "Gilroy-Medium";
    font-size: 14px;
    font-style: normal;
    width: 100%;
  }
}
.matchtag_popup{
  @media only screen and (min-width: 992px){

  max-width: 897px;
  }
  @media only screen and (min-width: 700px) and (max-width: 991px){

    max-width: 700px;
    }
    @media only screen and (max-width: 599px){
      max-width: 400px;
    }
  .modal-content{
    padding-top: 10px;
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.5);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---600---GB, #141A1F);
    .modal-header {
      border: none !important;
      padding-bottom: 0px;

      .btn-close {
        box-shadow: none;
        outline: none;
        background: url("../../images/redo/bet-close-circle.png") no-repeat;
        opacity: 1;

        &:hover {
          opacity: 0.5;
        }
      }
    }
    .modal-body{
      padding-top: 0px;
      .title,.match-gamertag {
        color: #FFF;
        text-align: center;
        font-family: "Gilroy-Medium";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 10px;
    }
    .adt-matches-list{
      .adt-matches-items-1,.adt-matches-items-3{
        @media only screen and (max-width: 599px){
          display: none;
        }

      }
      .adt-matches-items-4 {
        @media only screen and (max-width: 599px){
        flex: 0 0 20%;
        max-width: 20%;
        }
    }
      .adt-matches-items{
        background: transparent;
        border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
        height: 50px;
        &:first-child{
          background: #181F26;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          border-bottom: 0px;
          @media only screen and (max-width: 991px){

            height: 60px;
            }
          .adt-matches-items-th {
            background: transparent;
          }
        }
        .adt-matches-items-th {
          background-color: #141A1F;
          font-size: 14px;
          line-height: 24px;
          font-family: 'Inter-Medium' !important;
          font-style: normal;
          color: #51E20E !important;
          border: none !important;
          &.adt-matches-items-2 {
            flex: 0 0 20%;
            max-width: 20%;
        }
      }
      .adt-matches-items-td {
        color: #fff;
        font-family: "Gilroy-Medium";
        text-align: left;
        color: #DBFFDF;
        font-family: 'Inter-Regular';
        font-size: 14px;
        line-height: 24px;
        vertical-align: middle;
        /* height: 89px; */
        text-transform: capitalize;
        border-bottom: none;
        &.adt-matches-items-2 {
          flex: 0 0 20%;
          max-width: 20%;
      }
    }
      }
    }
    }
    .modal-footer {
      text-align: center;
      justify-content: center;
      border: none;
      button.btn.btn-primary {
        padding: var(--Spacing-lg, 11px) var(--Spacing-2xl, 24px);
        border-radius: var(--Spacing-2xl, 24px);
        border: 1.5px solid #FFF;
        background: var(--Primary-Green---50---GB, #F1FEEC);
        box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.02), 0px 16px 56px -4px rgba(241, 254, 236, 0.08);
        width: 100%;
        color: var(--Background---grey-Grey---950---GB, #020303);
        font-family: "Gilroy-SemiBold";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        max-width: 400px;
        &:hover{
          opacity: 0.5;
        }
    }
  
  }

  }
}   
#payout-popup {
  z-index: 9999;
  border: 1.5px solid #51E20E !important;
  border-radius: 12px;

  .popover-body {
    background: #51E20E !important;
  }
}
.cancel-user-tournament{
  margin-right: auto !important;
  margin-left: auto !important;
  .modal-footer{
    justify-content: center !important;
    gap: 7px;
    button{
      margin: 0px;
    }
  }
}
.rulesHeadTagConInner {
  padding-left: 3px;
}