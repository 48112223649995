.Toastify {
  z-index: 99999 !important;
  position: absolute;
}

.betting-top-container {
  background-color: #000;
  height: auto;
  width: 100%;
  // position: fixed;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #454545;

  // @media only screen and (min-width:1025px) {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  // }
  @media only screen and (max-width: 991px) {
    border-bottom: 0px solid #454545;
  }

  .logo-section {
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    border-bottom: 2px solid #45454569;

    @media only screen and (min-width: 992px) {
      max-width: 220px;
    }

    @media only screen and (min-width: 992px) {
      padding-left: 30px;
    }

    @media only screen and (min-width: 1600px) {
      max-width: 240px;
    }

    @media only screen and (min-width: 1700px) {
      max-width: 265px;
    }

    &.expanded-bg {
      background-color: #0c0c0c;
    }

    &.collapsed-bg {
      background-color: #000;
    }
  }

  .header-menu {
    border-bottom: 2px solid #45454569;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 30px;

    .header-nav {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 30px;
      margin-bottom: 0px;

      li {
        font-family: "Gilroy-Bold";
        color: #ffffff;
        font-size: 18px;
        cursor: pointer;

        img {
          margin-right: 15px;
        }

        .dropdown-menu {
          .dropdown-item {
            font-size: 14px;
            padding-left: 10px;
          }
        }

        .dropdown-toggle {
          background: #000;
          border: none;

          .profile {
            border: 3px solid #e52420;
            border-radius: 25px;
          }
        }

        .dropdown-app,
        #dropdown-language {
          background-color: #000;
          border: 2px solid #2d2d2d;
          box-shadow: none;
        }

        .dropdown-app {
          &:after {
            display: none;
          }

          &:hover {
            border: 2px solid #e52420;
          }
        }

        .dropdown-item {
          .appicon {
            width: 23px;
            height: 23px;
            object-fit: contain;
          }
        }

        .qrcode-item {
          &:hover {
            background: none;
          }

          p {
            font-family: "Gilroy-Bold";
            color: #a9a9a9;
            font-size: 13px;
            text-align: center;
            padding-top: 6px;
          }
        }
      }
    }
  }
}

.modal-open {
  .betting-top-container {
    z-index: 0;
  }
}

.main-menu {
  width: 100%;
  display: none;
  position: relative;

  @media only screen and (min-width: 1025px) {
    display: flex;
  }
}

.golive_sec {
  position: fixed;
  right: 0;
  top: 37%;
  width: 70px;
  z-index: 99;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.challenge_sec {
  position: fixed;
  right: 0;
  top: 45%;
  width: 70px;
  z-index: 99;
  cursor: pointer;

  @media only screen and (min-width: 1920px) {
    top: 44%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.followers-section {
  h3 {
    font-family: "Gilroy-Bold";
    color: #ffffff;
    font-size: 18px;
    padding: 20px 20px;
  }

  .followers-list {
    list-style: none;
    padding-left: 0;
    padding: 0px 10px;

    li {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      background-color: #000;
      border-radius: 10px;
      padding: 6px;
      margin-bottom: 10px;

      p {
        font-family: "Gilroy-Bold";
        font-size: 14px;
        color: #ffffff;
        margin-bottom: 0px;
      }

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;

        img {
          border-radius: 25px;
        }
      }

      .col2 span {
        font-family: "Gilroy-Medium";
        color: #ebebf5;
        font-size: 12px;
        opacity: 0.6;
      }

      .col3 span {
        font-family: "Gilroy-Medium";
        color: #ebebf5;
        font-size: 14px;

        &.red-tick {
          &::before {
            content: url("../images/sidebar/redRound.png");
            width: 9px;
            height: 9px;
          }
        }

        &.green-tick {
          &::before {
            content: url("../images/sidebar/greenRound.png");
            width: 9px;
            height: 9px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .manage-followers {
    font-family: "Gilroy-Bold";
    color: #a9a9a9;
    font-size: 13px;
    padding: 0px 20px;
    cursor: pointer;

    &::before {
      content: url("../images/sidebar/settings.png");
      margin-right: 5px;
    }
  }
}

.betting-left-container {
  // background-color: #181818;
  background-color: #161515;
  height: 100vh;
  padding-right: 0px;
  display: none;
  width: 100%;
  max-width: 240px;
  position: sticky;
  top: 6%;
  left: 0;
  z-index: 999;
  height: 100vh;

  @media only screen and (min-width: 991px) {
    display: block;
  }

  .collapse-btn {
    position: absolute;
    top: 12px;
    right: -10px;
    cursor: pointer;
  }

  .menu-section {
    border-bottom: 2px solid #45454569;

    .menu-nav {
      list-style: none;
      padding-left: 0px;
      padding: 0px 0px;

      li {
        position: relative;

        a {
          font-family: "Gilroy-Bold";
          color: #ffffff;
          font-size: 15px;
          padding: 20px 20px;
          height: 52px;
          display: flex;
          align-items: center;
          gap: 20px;
          cursor: pointer;
          text-decoration: none;

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            gap: 10px;
            padding: 9px 13px;
            font-size: 14px;
            height: 39px;
          }

          // .sidebar-icon {
          //   @media only screen and (min-width: 1366px) and (max-width: 1919px) {
          //     width: 21px;
          //   }
          // }
          &:hover {
            background-color: #2d2d2d;
          }

          &.menuactive {
            background-color: #2d2d2d !important;
          }

          &.deactive {
            background-color: #161515;
            opacity: 0.5;
          }

          .mega-menu {
            img {
              margin-left: 30px;

              @media only screen and (min-width: 1300px) and (max-width: 1365px) {
                margin-left: 6px;
              }
            }
          }
        }

        .competition-menu {
          background: #121212 !important;
          border: 1px solid #2d2d2d !important;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
          border-radius: 8px !important;
          padding: 10px !important;
          position: absolute;
          top: 0;
          right: -255px;
          display: none;
          cursor: pointer;

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            right: -230px;
          }

          a {
            color: #fff;

            &:hover {
              color: #e52420 !important;
              background: #000000;
              border-radius: 8px;
            }
          }

          .menu-item {
            color: #fff;
            padding: 10px !important;
            font-family: "Gilroy-Bold";
            font-size: 14px;

            img {
              margin-right: 10px;
              width: 32px;

              @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                width: 21px;
              }
            }
          }
        }

        &:hover {
          .competition-menu {
            display: block;
          }
        }
      }
    }
  }

  &.expanded {
    transition: all 0.4s ease-out;

    //max-width: 240px;
    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      max-width: 200px;
    }

    .slow {
      -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    @-webkit-keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &.collapsed {
    transition: all 0.5s ease-out;
    max-width: 4%;
    background-color: #212121;

    @media only screen and (max-width: 1500px) {
      max-width: 6%;
    }

    .menu-section {
      border-bottom: none;
      margin-bottom: 50px;

      .menu-nav {
        padding: 8px 0px;

        li {
          margin-bottom: 10px;
          cursor: pointer;

          &:hover {
            width: 200px;
            height: auto;
            background-color: #181818;
            border-radius: 10px;
            margin-left: 20px;
            z-index: 99;

            img {
              margin: 0px 0px 0px 10px;
            }

            span {
              display: block;
              font-family: "Gilroy-Bold";
              color: #ffffff;
              font-size: 18px;
              line-height: 18px;
            }

            a {
              margin-left: 0px;
            }
          }

          a {
            width: 40px;
            height: 40px;
            padding: 0px;
            background-color: #181818;
            margin: 0 auto;
            border-radius: 8px;

            span {
              display: none;
            }

            img {
              margin: 0 auto;
            }
          }
        }
      }
    }

    .followers-section {
      .follow-heart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: #181818;
        border-radius: 8px;
        margin: 0 auto 10px;
      }

      .followers-list {
        li {
          width: 36px;
          height: 36px;
          margin: 0 auto 10px;
          padding: 0px;
          background-color: #212121;
          position: relative;
          z-index: 9999;
          min-height: 52px;

          img {
            border-radius: 0;
          }

          .col2,
          .col3 {
            display: none;
          }

          &:hover {
            width: 200px;
            height: auto;
            background-color: #181818;
            padding: 2px;
            margin-bottom: 10px;
            margin-left: 10px;

            @media only screen and (max-width: 1700px) {
              margin-left: 0px;
            }

            img {
              border-radius: 25px;
            }

            .col2,
            .col3 {
              display: block;
            }
          }
        }
      }
    }

    .competition {
      width: auto !important;

      .competition-menu {
        right: -215px !important;

        a {
          width: auto !important;
          height: auto !important;
          border-radius: 0 !important;
          background-color: #121212 !important;
        }

        .menu-item {
          &:hover {
            color: #e52420 !important;
            background: #000000;
            border-radius: 8px;
          }

          img {
            margin-left: 0 !important;
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}

.column-right {
  position: relative;
}

.video-straming-inner {
  background-color: #000;
  display: flex;
  height: auto;

  .betting-right-container {
    display: flex;
    padding: 30px 50px;

    .straming-left {
      width: 100%;
      max-width: 70%;

      .top-bar {
        width: 100%;
        height: 50px;
        background: #181818;
        border-radius: 10px;
      }
    }

    .straming-right {
      width: 100%;
      max-width: 30%;
    }
  }
}

.betting-sidebar-mobile {
  @media only screen and (min-width: 1025px) {
    display: none;
  }

  .nav-link {
    font-family: "Gilroy-Bold";
    color: #ffffff;
    font-size: 14px;
    padding: 10px 10px;
    cursor: pointer;

    img {
      margin-right: 15px;
    }

    .dropdown-menu {
      .dropdown-item {
        font-size: 12px;
        padding-left: 10px;
      }
    }

    .dropdown-toggle {
      background: transparent;
      border: none;
      font-size: 14px;
      padding: 0px;

      .profile {
        border: 3px solid #e52420;
        border-radius: 25px;
      }
    }

    .dropdown-app,
    #dropdown-language {
      background-color: transparent;
      box-shadow: none;
    }

    .dropdown-app {
      &:after {
        display: none;
      }

      &:hover {
        border: 2px solid #e52420;
      }
    }
  }

  .followers-section {
    .followers-list {
      li {
        max-width: 300px;
      }
    }

    padding-bottom: 30px;
  }
}

/*********personal deatils modal***********/
.modal-personal-details {
  padding: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e52420;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e52420;
  }

  .betting-top-container {
    z-index: 0;
  }

  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    //margin: 8% auto !important;

    @media only screen and (min-width: 768px) {
      padding: 0px !important;
      max-width: 528px;
    }

    .modal-content {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      margin-bottom: 20%;

      .modal-header {
        border: none;

        .btn-close {
          background: url("../images/close_button.png") no-repeat;
          position: relative;
          top: 10px;
        }
      }

      .modal-body {
        padding: 0px 30px 30px;

        // @media only screen and (min-width:991px) {
        //     padding: 0px 79px 40px
        // }

        @media only screen and (min-width: 991px) {
          padding: 0px 40px 10px;
        }

        .heading-bar {
          width: 100%;
          margin: 0 auto;

          @media only screen and (min-width: 991px) {
            max-width: 395px;
          }

          h2 {
            font-family: "Gilroy-Bold";
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            color: #fff;
            margin-bottom: 12px;

            @media only screen and (min-width: 991px) {
              font-size: 30px;
              line-height: 30px;
            }
          }

          p {
            font-family: "Gilroy-Bold";
            max-width: 314px;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: center;
            color: #9c9ca3;
            margin: auto auto 20px auto;
          }
        }

        .personal-form {
          border-top: 2px solid #2d2d2d;
          padding: 20px 0px 0px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .form-group {
            width: 100%;
            position: relative;

            label {
              font-family: "Gilroy-Medium";
              font-size: 14px;
              line-height: 14px;
              letter-spacing: 0em;
              text-align: left;
              color: #9c9ca3;
              margin-bottom: 5px;

              &.hide {
                visibility: hidden;

                @media only screen and (max-width: 400px) {
                  display: none;
                }
              }

              span {
                color: #e52420;
              }
            }

            .form-field-error {
              position: absolute;
              color: #e52420;
              bottom: -4px;
              font-size: 14px;
            }

            .form-control {
              width: 100%;
              height: 44px;
              margin-bottom: 10px;
              background-color: #000000 !important;
              border: 2px solid #262627;
              border-radius: 12px;
              font-family: "Gilroy-Medium";
              font-size: 14px;
              line-height: 14px;
              color: #fff;

              &::placeholder {
                color: #9c9ca3;
              }

              &:focus {
                border: 2px solid #e52420;
                box-shadow: none;
              }

              &:disabled {
                background-color: #1b1b1b !important;
                border: 2px solid #262627;
                color: #9c9ca3;
              }
            }

            select {
              background: url("../images/sidebar/down.png") no-repeat right center;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
              width: 90px;
              text-indent: 0.01px;
              text-overflow: "";
              background-position: 92%;
            }

            // @media only screen and (min-width:768px) {
            &.md-3 {
              // max-width: 145px;
              flex: 0 0 32%;
              max-width: 32%;

              @media only screen and (max-width: 400px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 0px !important;
              }
            }

            &.md-2 {
              // max-width: 220px;
              flex: 0 0 48%;
              max-width: 48%;

              @media only screen and (max-width: 400px) {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }

            // }

            .btn-danger {
              background-color: #e52420;
              background-image: linear-gradient(90deg,
                  #6d1513,
                  #e52420,
                  #6d1513);
              border: 2px solid #fff;
              border-radius: 10px;
              color: #fff;
              cursor: pointer;
              font-family: "Poppins-Bold";
              font-size: 15px;
              letter-spacing: 0.04em;
              line-height: 15px;
              padding: 17px 10px;
              text-align: center;
              text-decoration: none;
              text-transform: uppercase;
              width: 100%;
              max-width: 178px;
              display: block;
              margin: 0px auto;

              &:after {
                content: url("../images/arrow.png");
                padding-left: 10px;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }

          .betting-rules {
            background: #000000;
            border: 2px solid #262627;
            border-radius: 12px;
            padding: 5px 15px 20px 15px;

            // @media only screen and (min-width:991px) {
            //     padding: 5px 40px 20px 40px;
            // }

            ul {
              height: 260px;
              overflow: auto;
              padding-right: 1em;

              &::-webkit-scrollbar {
                width: 8px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #181818;
                border-radius: 8px;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #535353;
                border-radius: 8px;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #e52420;
              }

              li {
                font-family: "Gilroy-SemiBold";
                font-size: 12px;
                line-height: 15px;
                list-style: number;
                color: #9c9ca3;
                border-bottom: 2px solid #2d2d2d;
                padding: 15px 0px;
              }
            }

            .agreeToUserAgreements {
              position: relative;
              margin-bottom: 15px;

              ._GB_st_enable {
                font-family: "Gilroy-SemiBold";
                font-size: 15px;
                color: #9c9ca3;
                margin-left: 33px;
              }

              input {
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
              }

              .checkmark {
                position: absolute;
                top: 2px;
                left: 0;
                height: 21px;
                width: 21px;
                background-color: #262627;
                border: 2px solid #5c5c5c;
                border-radius: 3px;
                cursor: pointer;
              }

              /* On mouse-over, add a grey background color */
              .container:hover input~.checkmark {
                background-color: #ccc;
              }

              /* When the checkbox is checked, add a blue background */
              input:checked~.checkmark {
                background-color: #c80000;
              }

              /* Create the checkmark/indicator (hidden when not checked) */
              .checkmark:after {
                content: "";
                position: absolute;
                display: none;
              }

              input:checked~.checkmark:after {
                display: block;
              }

              /* Style the checkmark/indicator */
              .checkmark:after {
                left: 6px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);


              }
            }
          }
        }
      }
    }
  }
}

.stepperWrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  max-width: 200px;
  margin: 0 auto 10px;

  &.challenge-page {
    justify-content: flex-end !important;
    margin: 0 0px;

    .stepperItem {
      @media only screen and (min-width: 768px) {
        align-items: flex-end;

        &::before,
        &::after {
          left: 99%;
        }
      }
    }
  }
}

.stepperItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  &::before {
    position: absolute;
    content: "";
    border-bottom: 1px dashed #d9d9d9;
    width: 74%;
    top: 11px;
    left: 63%;
    z-index: 2;
    opacity: 0.5;
  }

  &::after {
    position: absolute;
    content: "";
    border-bottom: 1px dashed #d9d9d9;
    width: 74%;
    top: 11px;
    left: 64%;
    z-index: 2;
    opacity: 0.5;
  }

  .stepCounter {
    cursor: pointer;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #181818;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    color: #ffffff;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    margin-bottom: 6px;

    &.one {
      border: 2px solid #52dd21;
    }

    &.active {
      background-color: #52dd21;
      border: 2px solid #52dd21;
      color: #000000;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepperItem:last-child::before {
  content: none;
}

.stepperItem:last-child::after {
  content: none;
}

.product-list-container {
  display: grid;
  grid-template-rows: 55px calc(100vh - 55px);
  grid-template-columns: 220px auto;
  grid-template-areas:
    "filter header"
    "filter products";
}

/* styling for just the main list of products displayed on screen */
.product-list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: header;
}

.products-container {
  grid-area: products;
  overflow: auto;
}

.product-list-product-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

/* styling for the filters */
.filter-wrapper {
  padding: 10px;
  background-color: #ffcfae;
  grid-area: filter;
  height: 100vh;
}

.filter-title {
  font-weight: 600;
}

.filter-data {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-top: 2px solid #012025;
}

.filter-item {
  margin: 8px 16px;
}

.filter-checkbox {
  margin-left: 10px;
}

/****************create challenge*************/
.modal-challenges {
  padding: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e52420;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e52420;
  }

  .betting-top-container {
    z-index: 0;
  }

  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 20px;

    @media only screen and (min-width: 768px) {
      padding: 0px !important;
      max-width: 540px;
    }

    .modal-content {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      margin-bottom: 20%;

      .modal-header {
        border: none;
        padding: 10px;

        .btn-close {
          background: url("../images/close_button.png") no-repeat;
          position: relative;
          top: 10px;
          box-shadow: none;
        }
      }

      .modal-body {
        padding: 0px 30px 10px;

        @media only screen and (min-width: 1700px) {
          padding: 0px 25px 10px;
        }

        @media only screen and (max-width: 374px) {
          padding: 0px 20px 10px;
        }

        .GB_challenges {
          h2 {
            font-family: "Gilroy-Bold";
            font-size: 30px;
            line-height: 30px;
            text-align: center;
            color: #ffffff;
          }

          .games {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            @media only screen and (max-width: 991px) {
              flex-direction: column;
              margin-top: 30px;
            }

            .left-section {
              width: 100%;
              max-width: 50%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              @media only screen and (max-width: 990px) {
                max-width: 100%;
                justify-content: center;
              }

              .game-type {
                width: 100%;
                max-width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #181818;
                border-radius: 8px;
                border: 2px solid #2d2d2d;
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                color: #ffffff;
                text-decoration: none;
                height: 36px;
                cursor: pointer;

                &.active {
                  border: 2px solid #ffc01f;
                }

                &.private {
                  color: rgba(255, 255, 255, 0.3);
                }

                &.public {
                  &:hover {
                    border: 2px solid #ffc01f;
                  }
                }
              }
            }

            .right-section {
              display: flex;
              align-items: center;
              width: 100%;
              max-width: 40%;
              justify-content: space-between;

              @media only screen and (max-width: 990px) {
                flex-wrap: wrap;
                max-width: 100%;
                justify-content: center;
                gap: 10px;
              }

              p {
                font-family: "Gilroy-SemiBold";
                font-size: 14px;
                line-height: 16px;
                text-align: right;
                text-transform: capitalize;
                color: #ffffff;
                margin-bottom: 0px;
                margin-right: 10px;

                @media only screen and (max-width: 990px) {
                  text-align: center;
                  margin: 20px 0px;
                }

                @media only screen and (max-width: 375px) {
                  width: 100%;
                  margin: 20px 0px 10px;
                }
              }

              .game-list {
                width: 40px;
                height: 40px;
                background: #000000;
                border: 1.5px solid #2d2d2d;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover,
                &.active {
                  background: #640000;
                  border: 1.5px solid #c80000;
                }

                img {
                  width: 100%;
                  max-width: 30px;
                  height: 30px;
                }
              }
            }
          }

          .challenge-types {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0px;

            @media only screen and (max-width: 767px) {
              flex-wrap: wrap;
              justify-content: center;
              gap: 10px;
            }

            .before-coin {
              width: 159px;
              height: 42px;
              background: #000000;
              border: 2px solid #2d2d2d;
              border-radius: 12px;
              font-family: "Gilroy-SemiBold";
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              color: #ffffff;
              text-decoration: none;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              cursor: pointer;

              &:hover,
              &.active {
                border: 2px solid #e52420;
                box-shadow: 0px 0px 9px rgba(229, 36, 32, 0.25);
              }

              &::before {
                content: url("../images/sidebar/coin.png");
                margin-top: 8px;
              }

              &.disabled {
                cursor: not-allowed;
              }
            }
          }

          .challnges-list-container {
            background: #262627;
            padding: 15px 15px;
            border-radius: 8px;
            max-height: 249px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 767px) {
              padding: 15px 15px 15px 0px !important;
            }
          }

          .bet-amount-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 50px;

            @media only screen and (max-width: 767px) {
              flex-direction: column;
              gap: 15px;
              padding: 12px 30px;
            }

            .initial-bet {
              h3 {
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 100%;
                letter-spacing: 0.04em;
                color: #ffffff;
                opacity: 0.88;
              }

              p {
                font-family: "Gilroy-Bold";
                font-size: 10px;
                line-height: 100%;
                letter-spacing: 0.04em;
                color: #ffffff;
                opacity: 0.88;
                margin-bottom: 0px;
              }

              @media only screen and (max-width: 767px) {
                text-align: center;
              }
            }

            .bet-amount {
              .form-control {
                background: #000000;
                border: 2px solid #2d2d2d;
                border-radius: 12px;
                width: 123px;
                height: 50px;
                font-family: "Gilroy-Bold";
                font-size: 26px;
                line-height: 100%;
                text-align: center;
                color: #fe5242;

                @media only screen and(min-width: 1366px) and(max-width: 1919px) {
                  font-size: 20px;
                }

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .bet-amount-unit {
              p {
                font-family: "Gilroy-SemiBold";
                font-size: 13px;
                line-height: 15px;
                color: rgba(255, 255, 255, 0.5);
                margin-bottom: 4px;

                &::before {
                  content: url("../images/betting/up-down-arrow.png");
                  transform: rotate(90deg);
                  display: inline-block;
                  margin-right: 10px;
                  margin-left: 5px;
                }
              }

              .dropdown-toggle {
                background: #262627;
                border-radius: 8px;
                width: fit-content;
                height: 32px;
                border: none;
                font-family: "Gilroy-Bold";
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;

                &:after {
                  content: url("../images/betting/drop-arrow.png");
                  width: 12px;
                  border: none;
                }

                &.before-coin {
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  &::before {
                    content: url("../images/betting/coin-gamebeef.png");
                    top: 1px;
                    position: relative;
                  }
                }
              }

              .dropdown-menu {
                .dropdown-item {
                  padding: 5px 10px;

                  @media only screen and (max-width: 575px) {
                    min-width: 98px;
                  }
                }

                .before-coin {
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  &::before {
                    content: url("../images/betting/coin-gamebeef.png");
                    top: 3px;
                    position: relative;
                  }
                }
              }
            }
          }

          .selected-game-challegs {
            width: 100%;
            max-height: 225px;
            overflow: auto;
            padding: 0px 15px 0px 0px;

            @media only screen and (max-width: 620px) {
              padding: 0px 10px;
            }

            @media only screen and (max-width: 374px) {
              padding: 0px;
            }

            &::-webkit-scrollbar {
              width: 8px;
              margin-right: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #000000;
              border-radius: 8px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #535353;
              border-radius: 8px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #e52420;
            }

            .item {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              .box-description {
                background: #000000;
                border: 2px solid #2d2d2d;
                border-radius: 20px;
                width: 100%;
                max-width: 535px;
                padding: 11px 20px;
                margin-bottom: 5px;
                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: 374px) {
                  padding: 11px 15px;
                }

                .box-left {
                  width: 100%;
                  max-width: 70%;

                  @media only screen and (max-width: 620px) {
                    flex-direction: column;
                  }

                  @media only screen and (max-width: 420px) {
                    max-width: 100%;
                  }

                  .top {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    @media only screen and (max-width: 620px) {
                      flex-direction: column;
                      align-items: flex-start;
                    }

                    h3 {
                      font-family: "Gilroy-Bold";
                      font-size: 14px;
                      line-height: 16px;
                      letter-spacing: 0.04em;
                      color: #52dd21;
                      margin-bottom: 0px;
                    }

                    span {
                      font-family: "Gilroy-Bold";
                      font-size: 14px;
                      line-height: 100%;
                      letter-spacing: 0.04em;
                      color: #ffc01f;
                    }
                  }

                  p {
                    font-family: "Gilroy-Bold";
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    color: #ffffff;
                    margin-bottom: 0px;
                    margin-top: 5px;
                  }
                }

                @media only screen and (max-width: 420px) {
                  flex-direction: column-reverse;
                }

                img {
                  object-fit: contain;

                  @media only screen and (max-width: 420px) {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 10px;
                  }
                }
              }

              /* The container */
              .btn-container {
                display: block;
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                @media only screen and (max-width: 767px) {
                  padding-left: 0px;
                }

                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                }
              }

              /* Create a custom radio button */
              .checkmark {
                position: absolute;
                top: -15px;
                left: 7px;
                height: 22px;
                width: 22px;
                background-color: #000000;
                border-radius: 50%;
                border: 2px solid #2d2d2d;

                &::after {
                  content: "";
                  position: absolute;
                  display: none;
                }
              }

              /* When the radio button is checked, add a blue background */
              .btn-container input:checked~.checkmark {
                background-color: #000000;
              }

              /* Show the indicator (dot/circle) when checked */
              .btn-container input:checked~.checkmark:after {
                display: block;
              }

              /* Style the indicator (dot/circle) */
              .btn-container .checkmark:after {
                top: 5px;
                left: 4px;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #40ab00;
              }
            }

            .spinner-grow {
              display: block;
              margin: 0 auto;
            }
          }

          .btn-danger {
            background-color: #e52420;
            background-image: linear-gradient(90deg, #6d1513, #e52420, #6d1513);
            border: 2px solid #fff;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            font-family: "Poppins-Bold";
            font-size: 14px;
            letter-spacing: 0.04em;
            line-height: 15px;
            margin: auto;
            padding: 17px 20px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
            max-width: fit-content;
            display: block;
            margin: 30px auto !important;

            &:after {
              content: url("../images/arrow.png");
              padding-left: 10px;
            }

            &:hover {
              opacity: 0.5;
            }

            &.waiting {
              display: flex;
              align-items: center;

              .spinner-border {
                margin-right: 8px;
              }

              &::after {
                display: none;
              }
            }
          }
        }

        .challenges-step-two {
          padding: 30px 15px;
        }
      }
    }
  }
}

.gif-rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**********join competition popup **********/
.modal-join-competition {
  padding: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e52420;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e52420;
  }

  .betting-top-container {
    z-index: 0;
  }

  .modal-dialog {
    width: 100%;
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      padding: 0px !important;
    }

    .modal-content {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      margin-bottom: 20%;

      .modal-header {
        border: none;
        padding: 10px;

        .btn-close {
          background: url("../images/close_button.png") no-repeat;
          position: relative;
          top: 10px;
          box-shadow: none;
        }
      }

      .modal-body {
        padding: 0px 30px 10px;

        .competition-body {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          gap: 20px;

          h2 {
            font-family: "Gilroy-Bold";
            font-size: 22px;
            line-height: 100%;
            color: #ffffff;

            @media only screen and (min-width: 991px) {
              font-size: 30px;
            }
          }

          p {
            font-family: "Gilroy-Bold";
            font-size: 14px;
            line-height: 25px;
            text-align: center;
            color: #ffffff;

            @media only screen and (min-width: 991px) {
              font-size: 16px;
            }
          }

          h3 {
            font-family: "Gilroy-Bold";
            font-size: 18px;
            line-height: 32px;
            text-align: center;
            color: #fa3d3f;

            @media only screen and (min-width: 991px) {
              font-size: 29px;
            }
          }

          .games {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .game-list {
              width: 54px;
              height: 54px;
              background: #000000;
              border: 1.5px solid #2d2d2d;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &:hover,
              &.active {
                background: #640000;
                border: 1.5px solid #c80000;
              }

              img {
                width: 100%;
                max-width: 37px;
                height: 37px;
                object-fit: contain;
              }
            }
          }

          .green-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;
            max-width: 239px;
            height: 55px;
            background: #52dd21;
            border-radius: 10px;
            font-family: "Poppins-Bold";
            color: #000;
            font-size: 15px;
            letter-spacing: 0.04em;
            text-transform: uppercase;

            &:hover {
              opacity: 0.5;
            }

            &::after {
              content: url("../images/btn-black-arrow.png");
            }

            @media only screen and (max-width: 768px) {
              font-size: 14px;
              height: 38px;
            }
          }
        }
      }
    }
  }
}

/****************** Challenge Sidebar Start *************/
.challenge-megamenu {
  position: relative;

  @media only screen and (max-width: 1024px) {
    display: none;
  }

  .close-btn {
    position: absolute;
    top: 7px;
    right: 9px;
    cursor: pointer;
  }

  .challenge-right {
    background-color: #121212;
    display: flex;
    gap: 50px;
    align-items: center;
    height: 314px;
    padding: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .challenge-yourself {
      background-image: url("../images/betting/challenge_yourself.png");
      background-repeat: no-repeat;

      &:hover {
        background-image: url("../images/betting/challenge_ygreen.png");
      }
    }

    .challenge-another {
      background-image: url("../images/betting/challenge_another.png");
      background-repeat: no-repeat;

      &:hover {
        background-image: url("../images/betting/challenge_agreen.png");
      }

      span {
        max-width: 247px;
      }
    }

    a {
      border: 1px solid #707070;
      max-width: 494px;
      height: 214px;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      text-decoration: none;

      .challenge-box {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 494px;
        height: 100%;
        width: 100%;
        border-radius: 10px;

        span {
          font-family: "Gilroy-Bold";
          font-size: 30px;
          line-height: 35px;
          color: #fff;
          text-align: center;
        }

        &:hover {
          background-color: rgba(49, 255, 31, 0.5);
          border: 1px solid rgba(49, 255, 31, 0.5);
        }

        .challenge-alert {
          position: absolute;
          top: 7px;
          right: 7px;
        }

        &.deactive {
          background-color: rgba(0, 0, 0, 0.9);

          &:hover {
            border: 1px solid #707070;
          }

          .coming_soon {
            display: none;
          }

          &:hover {
            span {
              opacity: 0.4;
            }

            .coming_soon {
              display: block;
              font-family: "Gilroy-Bold";
              font-size: 30px;
              line-height: 35px;
              color: #fff;
              position: absolute;
              top: 85px;
              left: 150px;
            }
          }
        }

        .beta-btn {
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 19px;
          color: #000;
          background: #ffc01f;
          position: absolute;
          top: 11px;
          left: 7px;
          width: 100%;
          max-width: 52px;
          height: 19px;
          text-align: center;
          background: #ffc01f;
          border-radius: 3px;
          z-index: 99999;
        }
      }
    }
  }
}

.cymmexpanded {
  .challenge-right {
    @media only screen and (min-width: 1920px) {
      padding-left: 280px;
    }
  }
}

.cymmcollapsed {
  .challenge-right {
    @media only screen and (min-width: 1920px) {
      padding-left: 145px;
    }
  }
}

#alert-popup {
  z-index: 9999;
  border: 1.5px solid #c80000;
  border-radius: 12px;

  &.beta-alert {
    width: 100%;
    max-width: 395px;

    .popover-body {
      max-width: 100%;
    }
  }

  .popover-body {
    max-width: 232px;
    background: #640000;
    border-radius: 12px;
    color: #ffffff;
    font-family: "Gilroy-Bold";
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }

  .popover-arrow {
    &::after {
      content: url("../images/tooltip_arrow.png");
      border-top: none;
      top: -5px;
      left: -8px;
    }
  }
}

/****************** Challenge Sidebar End *************/

/***************** PlayToEarn Dashboard Start ****************/
.GB_playToEarn {
  background-color: #000000;

  .playToEarn_inner {
    @media only screen and (min-width: 768px) {
      display: flex;
      padding-top: 36px;
    }
    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      padding-top: 9px;
    }

    .playToEarn_right {
      background-color: #000000;

      // @media only screen and (min-width: 1025px) and (max-width: 1414px) {
      //   max-width: 83%;
      // }

      // @media only screen and (min-width: 1415px) and (max-width: 1470px) {
      //   max-width: 84%;
      // }

      // @media only screen and (min-width: 1025px) and (max-width: 1919px) {
      //   max-width: 70%;
      //   margin: auto;
      // }

      @media only screen and (max-width: 767px) {
        padding: 20px 0px 25px;
      }

      .container {
        // max-width: 1255px;
        width: 100%;

        @media only screen and (max-width: 767px) {
          max-width: 100%;
          padding: 0;
        }

        // &:first-child{
        //   @media only screen and (min-width: 768px) and (max-width: 991px) {
        //     padding: 0;
        //   }
        // }
        @media only screen and (min-width: 1600px) and (max-width: 1919px) {
          max-width: 1380px;
        }

        @media only screen and (min-width: 1920px) {
          max-width: 1430px;
        }

        &.top-container {
          padding: 0;
        }
      }

      ._GB_Dashboard_Banner {

        // @media only screen and (min-width: 1600px){
        //     max-width: 1320px;
        // }
        ._GB_Dashboard_Inner {
          .profile {
            @media only screen and (max-width: 1400px) {
              margin-bottom: 0;
            }
          }

          ._beta_banner_top {
            @media only screen and (max-width: 1600px) {
              padding: 0 15px;
            }

            @media only screen and (min-width: 992px) and (max-width: 1100px) {
              flex-direction: column;
            }            
            @media only screen and (max-width: 991px) {
              justify-content: flex-end;
            }
          }

          .profile_details {
            @media only screen and (max-width: 1200px) {
              flex-direction: column;
            }

            .left_section {
              @media only screen and (max-width: 1200px) {
                width: 100%;
                margin: auto;
              }

              @media only screen and (max-width: 767px) {
                order: 2;
                width: 98%;
              }

              .game_details {
                padding-top: 10px;

                @media only screen and (max-width: 1600px) {
                  padding-top: 0px;
                }

                .total {
                  @media only screen and (min-width: 768px) and (max-width: 1600px) {
                    flex: 0 0 31%;
                    max-width: 31%;
                    margin: 1% !important;
                  }

                  .items_inner {
                    @media only screen and (min-width: 768px) and (max-width: 1365px) {
                      min-height: 270px;
                    }

                    // @media only screen and (min-width: 1441px) and (max-width: 1600px) {
                    //   width: 200px;
                    // }

                    .desc-sect {
                      img {
                        @media only screen and (max-width: 1600px) {
                          height: 40px;
                        }

                        @media only screen and (max-width: 767px) {
                          height: 38px;
                        }
                      }
                    }
                  }
                }
              }

              ._Dashboard_Fortnite_Stats {
                padding: 50px 0px 0px;

                @media only screen and (max-width: 767px) {
                  padding: 0px !important;
                }

                @media only screen and (min-width: 1300px) and (max-width: 1600px) {
                  padding: 20px 0px 0px !important;
                }

                ._Fortnite_Stats_inner {
                  ._Fortnite_Stats_content {
                    .reward_session {
                      .second-section {
                        // @media only screen and (min-width: 1301px) and (max-width: 1600px) {
                        //   flex-direction: column;
                        // }
                      }
                    }
                  }
                }
              }
            }

            .right_section {
              @media only screen and (max-width: 1200px) {
                width: 85%;
                margin: auto;
              }

              @media only screen and (max-width: 767px) {
                width: 95%;
              }

              @media only screen and (max-width: 490px) {
                width: 100%;
                margin: auto;
              }

              .gameModeWrap {
                .most_played {
                  img {
                    height: auto;

                    @media only screen and (min-width: 1601px) {
                      height: 400px !important;
                    }
                  }
                }
              }
            }
          }

          .title-top {
            font-family: "Gilroy-Bold";
            color: #fff;
            font-size: 25px;
            line-height: 40px;

            @media only screen and (max-width : 490px) {
              font-size: 23px;
              line-height: 29px;
            }

            @media only screen and (min-width: 991px) {
              font-size: 40px;
              line-height: 50px;
            }

            @media only screen and (min-width: 1366px) and (max-width: 1600px) {
              font-size: 30px;
              line-height: 35px;
            }
          }

          ._Fortnite_Stats_inner {
            ._Fortnite_Stats_content {
              .left_section {
                display: flex;

                @media only screen and (max-width: 390px) {
                  flex-direction: column;
                }

                .today_session {
                  flex: 0 0 46%;
                  margin-right: 2%;
                  max-width: 46%;

                  @media only screen and (max-width: 767px) {
                    flex: 0 0 48%;
                    max-width: 48%;
                    margin: auto;
                    min-height: 140px;
                  }

                  @media only screen and (max-width: 490px) {
                    margin-left: 0;
                  }

                  @media only screen and (max-width: 390px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin: auto auto 15px;
                  }

                  .title {
                    .gameTitle {
                      font-size: 16px;

                      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                        font-size: 14px;
                        line-height: 16px;
                      }

                      @media only screen and (max-width: 767px) {
                        font-size: 12px;
                        line-height: 14px;
                      }
                    }
                  }
                }

                .reward_session {
                  flex: 0 0 52%;
                  max-width: 52%;
                  margin-bottom: 20px;

                  @media only screen and (max-width: 767px) {
                    flex: 0 0 48%;
                    max-width: 48%;
                    margin: auto;
                    min-height: 140px;
                  }

                  @media only screen and (max-width: 490px) {
                    margin-right: 0;
                  }

                  @media only screen and (max-width: 390px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                  }

                  .title-section {
                    ._title {
                      font-size: 16px;

                      @media only screen and (max-width: 767px) {
                        font-size: 12px;
                      }
                    }
                  }

                  .second-section {
                    @media only screen and (min-width: 391px) and (max-width: 767px) {
                      flex-direction: column;
                    }

                    @media only screen and (min-width: 1300px) and (max-width: 1365px) {
                      flex-direction: column !important;
                    }

                    ._Timer_left {
                      p {
                        font-size: 16px;

                        @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                          font-size: 12px;
                          line-height: 14px;
                          margin-left: 0;
                        }

                        @media only screen and (max-width: 767px) {
                          font-size: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ._beta_banner_top {
            justify-content: space-between;
          }

          ._Game_Stats_inner {
            .gameTitle {
              display: none;
            }

            ._Game_Stats_top {
              .change_game {
                .games_list {
                  img {
                    @media only screen and (max-width: 1365px) {
                      height: 50px;
                      width: 50px;
                    }

                    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                      height: 40px;
                      width: 40px;
                    }
                  }
                }

                .reward_popup {
                  .add_games_list {
                    img {
                      @media only screen and (max-width: 1365px) {
                        height: 50px !important;
                        width: 50px !important;
                      }

                      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                        height: 40px !important;
                        width: 40px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      ._GameStatsBeta {

        // padding: 0 30px 50px 30px;
        @media only screen and (min-width: 768px) {
          padding: 0 0 30px;
        }

        .pteec {
          max-width: 1200px;

          @media only screen and (min-width: 1600px) {
            max-width: 1320px;
          }
        }
      }
    }

    .betting-left-container {
      @media only screen and (min-width: 1366px) {
        position: absolute;
        top: auto;
      }

      .menu-section {
        .menu-nav {
          li {
            a {
              .slow {
                width: 95px;
              }
            }
          }
        }
      }
    }
  }
}

.betting-left-container {
  .menu-section {
    .menu-nav {
      li {
        a {
          .slow {
            width: 95px;
          }
        }
      }
    }
  }
}

/***************** PlayToEarn Dashboard End ****************/

/*****************betting create challenge new page***********/
.GB_createChallenge {
  padding-top: 30px;
  padding-bottom: 100px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    padding-bottom: 50px;
  }

  @media only screen and (min-width: 1360px) {
    max-width: 1010px;
  }

  @media only screen and (min-width: 1550px) {
    max-width: 1153px;
  }

  .GB_headingBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2d2d2d;
    padding-bottom: 25px;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
      border-bottom: none;
      padding-bottom: 0px;
    }

    .titles {
      width: 100%;

      @media only screen and (min-width: 768px) {
        max-width: 50%;
      }

      h2 {
        font-family: "Gilroy-Bold";
        font-size: 40px;
        line-height: 47px;
        text-transform: capitalize;
        color: #ffffff;

        @media only screen and (max-width: 767px) {
          font-size: 25px;
          line-height: 25px;
          text-align: center;
        }

        @media only screen and(min-width: 1366px) and(max-width: 1600px) {
          font-size: 30px;
        }
      }
    }

    .btns {
      width: 100%;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      @media only screen and (max-width: 767px) {
        max-width: 100%;
        justify-content: center;
      }

      .game-type {
        width: 100%;
        max-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #181818;
        border-radius: 8px;
        border: 2px solid #2d2d2d;
        font-family: "Gilroy-SemiBold";
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #ffffff;
        text-decoration: none;
        height: 36px;
        cursor: pointer;

        &.active {
          border: 2px solid #ffc01f;
        }

        &.private {
          color: rgba(255, 255, 255, 0.3);
        }

        &.public {
          &:hover {
            border: 2px solid #ffc01f;
          }
        }
      }
    }
  }

  .challenge-tab-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px 0px;
    border-bottom: 2px solid #2d2d2d;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      grid-area: 10px;
      border-bottom: none;
    }

    .tabs-left {
      width: 100%;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }

      @media only screen and (min-width: 768px) {
        max-width: 50%;
      }

      .tab-challenge {
        width: 177px;
        height: 49px;
        background: #000000;
        border-radius: 8px 8px 0px 0px;
        font-family: "Poppins-SemiBold";
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        position: relative;
        top: 4px;
        border-bottom: 2px solid #2d2d2d;
        cursor: pointer;

        @media only screen and(min-width: 1366px) and(max-width: 1600px) {
          font-size: 14px;
        }

        @media only screen and (max-width: 990px) {
          width: 50%;
        }

        @media only screen and (max-width: 767px) {
          font-size: 14px;
          line-height: 16px;
        }

        &.active,
        &:hover {
          background: #e5242082;
          border-bottom: 2px solid #e52420;

        }

        &:nth-child(1) {
          margin-right: 4px;
        }
      }
    }

    .btns-right {
      @media only screen and (max-width: 767px) {
        width: 100%;
        display: flex;
        align-items: flex-start;
      }
    }

    .titles_games {
      display: flex;
      align-items: center;
      position: relative;
      bottom: 6px;

      @media only screen and (max-width: 767px) {
        justify-content: center;
      }

      .btn.show {
        background: transparent;
        border: none;
      }

      img {
        width: 50px;
        height: 50px;
        border: 2px solid #484848;
        border-radius: 8px;
        cursor: pointer;
        padding: 4px;
        margin: 2px;

        &:hover,
        &.active {
          background: rgba(200, 0, 0, 0.5);
          border-color: #c80000;
        }

        &.summary_filter {
          margin-left: 15px;
          padding: 12px;
        }
      }

      .btn-primary {
        background: transparent !important;
        padding: 0;
      }
    }
  }

  .challenge-listing-container {
    margin: 40px 0px 24px 0px;
    background: #181818;
    border: 2px solid #454545;
    border-radius: 14px;
    padding: 15px;

    @media only screen and(min-width: 1366px) and(max-width: 1919px) {
      margin: 13px 0px;
    }

    @media only screen and (max-width: 767px) {
      margin: 15px 0px;
    }

    .challenge-table-outer {
      height: 300px;
      overflow: auto;
      padding-right: 15px;

      @media only screen and (max-width: 767px) {
        padding-right: 10px;
      }

      .challenge-table {
        .spinner-grow {
          display: block;
          margin: 20px auto;
        }

        .head-row {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;
          background: #1e1e1e;
          border-radius: 10px 10px 0px 0px;
          height: 35px;
          padding: 10px;

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            height: 25px;
          }

          @media only screen and (max-width: 767px) {
            display: none;
          }

          .reward {
            color: rgba(235, 235, 245, 0.6);
          }

          .payout {
            color: rgba(235, 235, 245, 0.6);

            .payout-popover {
              width: 15px;
              height: 15px;
              border: 1px solid #ffc01f;
              border-radius: 25px;
              font-family: "Gilroy-Bold";
              font-size: 11px;
              line-height: 100%;
              text-align: center;
              color: #ffc01f;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin-left: 3px;
            }
          }
        }

        .body-row {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          background: #121212;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 10px;

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            padding: 11px 10px;
            margin: 0;
            border-bottom: 1px solid #000000;
          }

          .mobile-head-text {
            display: none;
          }

          @media only screen and (max-width: 990px) {
            gap: 15px;
            padding: 15px 10px;
          }

          @media only screen and (max-width: 767px) {
            flex-direction: column;
            gap: 5px;

            .mobile-head-text {
              display: block;
              color: rgba(235, 235, 245, 0.6);
              margin-right: 10px;
            }
          }

          .reward {
            color: #ffffff;

            // @media only screen and (min-width: 1280px) {
            //   padding-left: 45px;
            // }

            @media only screen and (max-width: 990px) {
              display: flex;
            }
          }

          .payout {
            color: #ffc01f;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 990px) {
              display: flex;
            }

            .coin {
              display: flex;
              align-items: center;
              justify-content: center;

              // &::before {
              //   content: url("../images/coin-xs.png");
              //   position: relative;
              //   top: 2px;
              //   margin-right: 6px;
              // }
            }
          }
        }

        // .challenge-details {
        //   width: 100%;
        //   max-width: 100%;
        //   display: flex;
        //   justify-content: space-between;
        //   align-items: center;
        //   flex-direction: column;
        //   gap: 25px;

        //   @media only screen and (min-width: 991px) {
        //     max-width: 50%;
        //     gap: 10px;
        //     flex-direction: row;
        //   }

        //   @media only screen and (min-width: 1280px) {
        //     max-width: 40%;
        //   }
        //   @media only screen and(min-width: 1366px) and(max-width: 1919px) {
        //     max-width: 43%;
        //   }
        //   @media only screen and (max-width: 990px){
        //     flex-direction: row;
        //     gap: 10px;
        //     justify-content: flex-start;
        //     align-items: center;
        //     text-align: center;
        //   }
        //   @media only screen and (max-width: 400px){
        //     gap: 5px;
        //   }
        //   /* The container */
        //   .btn-container {
        //     display: block;
        //     position: relative;
        //     padding-left: 25px;
        //     padding-right: 10px;
        //     cursor: pointer;
        //     font-size: 22px;
        //     -webkit-user-select: none;
        //     -moz-user-select: none;
        //     -ms-user-select: none;
        //     user-select: none;

        //     @media only screen and (max-width: 575px) {
        //       padding-left: 10px;
        //     }

        //     input {
        //       position: absolute;
        //       opacity: 0;
        //       cursor: pointer;
        //     }
        //   }

        //   /* Create a custom radio button */
        //   .checkmark {
        //     position: absolute;
        //     top: -15px;
        //     left: 7px;
        //     height: 30px;
        //     width: 30px;
        //     background-color: #000000;
        //     border-radius: 50%;
        //     border: 2px solid #2d2d2d;
        //     @media only screen and (min-width: 1366px) and (max-width: 1919px){
        //     height: 22px;
        //     width: 22px;
        //     top: -10px;
        //     }
        //     @media only screen and (max-width: 990px){
        //       height: 20px;
        //       width: 20px;
        //       top: -9px;
        //     }
        //     @media only screen and (max-width: 575px){
        //      left: 0px;
        //     }
        //     @media only screen and (max-width: 400px){
        //       height: 16px;
        //       width: 16px;
        //     }

        //     &::after {
        //       content: "";
        //       position: absolute;
        //       display: none;
        //     }
        //   }

        //   /* When the radio button is checked, add a blue background */
        //   .btn-container input:checked ~ .checkmark {
        //     background-color: #000000;
        //   }

        //   /* Show the indicator (dot/circle) when checked */
        //   .btn-container input:checked ~ .checkmark:after {
        //     display: block;
        //   }

        //   /* Style the indicator (dot/circle) */
        //   .btn-container .checkmark:after {
        //     top: 7px;
        //     left: 7px;
        //     width: 12px;
        //     height: 12px;
        //     border-radius: 50%;
        //     background: #40ab00;
        //     @media only screen and(min-width: 1366px) and(max-width: 1919px) {
        //       top: 3px;
        //       left: 3px;
        //     }
        //     @media only screen and (max-width: 990px){
        //       top: 3px;
        //       left: 3px;
        //       width: 10px;
        //       height: 10px;
        //     }
        //     @media only screen and (max-width: 400px){
        //       height: 6px;
        //       width: 6px;
        //     }
        //   }

        //   h3 {
        //     font-family: "Gilroy-Bold";
        //     font-size: 16px;
        //     line-height: 100%;
        //     letter-spacing: 0.04em;
        //     margin-bottom: 0px;
        //     color: #52dd21;
        //     flex-direction: column;
        //     gap: 10px;
        //     display: flex;
        //     text-transform: uppercase;
        //     @media only screen and (min-width: 1366px) and (max-width: 1919px){
        //       font-size: 12px;
        //     }
        //     @media only screen and (min-width: 768px) and (max-width: 990px){
        //       font-size: 14px;
        //     }
        //     @media only screen and (min-width: 991px) {
        //       width: 100%;
        //       max-width: 207px;
        //       max-width: 75%;
        //       flex-direction: row;
        //       gap: 15px;
        //     }
        //     @media only screen and (max-width: 767px){
        //       flex-direction: row;
        //       align-items: center;
        //       font-size: 12px;
        //     }
        //   }

        //   span {
        //     font-family: "Gilroy-Bold";
        //     font-size: 16px;
        //     line-height: 100%;
        //     letter-spacing: 0.04em;
        //     color: #ffc01f;
        //     text-transform: capitalize;
        //     @media only screen and (min-width: 1366px) and (max-width: 1919px){
        //       font-size: 12px;
        //     }
        //     @media only screen and (max-width: 767px){
        //       font-size: 12px;
        //     }
        //   }

        //   img{
        //     @media only screen and (max-width: 400px){
        //       width: 22px;
        //       height: 22px;
        //     }
        //   }
        // }
        .body-row-right {
          display: contents;

          @media only screen and (max-width: 767px) {
            max-width: 73%;
            width: 100%;
            margin-right: 0px;
            justify-content: center;
            display: flex;
            flex-direction: column;
          }

          @media only screen and (max-width: 400px) {
            margin-left: 35px;
          }

          @media only screen and (min-width: 400px) and (max-width: 600px) {
            max-width: 65%;
          }
        }

        .challenge-time {
          width: 100%;
          max-width: 100%;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.04em;
          color: #ffffff;
          opacity: 0.88;
          text-align: center;

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            font-size: 12px;
          }

          @media only screen and (max-width: 767px) {
            font-size: 12px;
            text-align: left;
          }

          &.timer-ico {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;

            @media only screen and (max-width: 767px) {
              justify-content: flex-start;
            }

            @media only screen and (min-width: 991px) {
              align-items: center;
            }

            &::before {
              content: url("../images/timer.png");
            }
          }

          @media only screen and (min-width: 991px) {
            max-width: 20%;
          }

          @media only screen and (min-width: 1280px) {
            max-width: 28%;
          }

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            max-width: 32%;
          }
        }

        .reward,
        .payout {
          width: 100%;
          max-width: 109%;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.04em;
          opacity: 0.88;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            font-size: 12px;
            max-width: 11%;
          }

          @media only screen and (min-width: 768px) and (max-width: 990px) {
            max-width: 11%;
          }

          @media only screen and (min-width: 991px) {
            max-width: 12%;
          }

          @media only screen and (max-width: 767px) {
            justify-content: flex-start !important;
            font-size: 14px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        margin-right: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #000000;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #535353;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #e52420;
      }
    }
  }

  .betting-initial {
    // background: #181818;
    // border: 2px solid #454545;
    // border-radius: 14px;
    // padding: 40px 30px;

    @media only screen and (max-width: 767px) {
      text-align: center;
    }

    h3 {
      font-family: "Gilroy-Bold";
      font-size: 30px;
      line-height: 100%;
      color: #ffffff;
      opacity: 0.88;
    }

    p {
      font-family: "Gilroy-Bold";
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.04em;
      color: #ffffff;
      opacity: 0.88;
    }

    .create-challenge-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 20px;
      }

      .bet-amount-block {
        width: 100%;
        max-width: 550px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #181818;
        padding: 3px 4px;
        border-radius: 11px;
        gap: 10px;

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          max-width: 58%;
        }

        @media only screen and (max-width: 1280px) {
          max-width: 60%;
        }

        @media only screen and (max-width: 1200px) {
          max-width: 100%;
        }

        @media only screen and (max-width: 767px) {
          gap: 10px;
          padding: 15px 4px;
        }

        @media only screen and (max-width: 400px) {
          gap: 5px;
        }

        .form-control {
          width: 100%;
          max-width: 181px;
          height: 50px;
          background: #000000;
          border: 2px solid #2d2d2d;
          border-radius: 12px;
          font-family: "Gilroy-Bold";
          font-size: 26px;
          line-height: 100%;
          text-align: center;
          color: #fe5242;

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 20px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1600px) {
            max-width: 240px;
          }

          @media only screen and (max-width: 575px) {
            max-width: 121px;
          }
        }

        .up-down {
          background-image: url("../images/up-down.png");
          height: 20px;
          min-width: 21px;
          cursor: pointer;
          display: inline-block;
          background-size: cover;

          //margin-right: 4px;
          @media only screen and (max-width: 400px) {
            min-width: 10px;
            height: 11px;
          }
        }

        .bet-amount-unit {
          width: 100%;
          max-width: 165px;

          @media only screen and (max-width: 400px) {
            max-width: 98px;
          }

          .dropdown-toggle {
            background: #262627;
            border-radius: 8px;
            width: 100%;
            max-width: 165px;
            height: 50px;
            border: none;
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 16px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and(min-width: 1366px) and(max-width: 1600px) {
              max-width: 125px;
            }

            @media only screen and (max-width: 575px) {
              max-width: 98px;
            }

            &:after {
              content: url("../images/betting/drop-arrow.png");
              width: 12px;
              border: none;
              margin-left: auto;
            }

            &.before-coin {
              display: flex;
              align-items: center;
              gap: 6px;
              justify-content: flex-start;

              &::before {
                content: url("../images/betting/coin-gamebeef.png");
                top: 1px;
                position: relative;
              }
            }
          }

          .dropdown-menu {
            @media only screen and (max-width: 575px) {
              min-width: 98px;
            }

            .dropdown-item {
              padding: 5px 10px;
              display: flex;
              align-items: center;

              @media only screen and (max-width: 575px) {
                gap: 5px;
              }

              .usdt_coin {
                margin-right: 5px;
              }

              .usdc_coin {
                margin-right: 5px;
              }
            }

            .before-coin {
              display: flex;
              align-items: center;
              gap: 6px;

              &::before {
                content: url("../images/betting/coin-gamebeef.png");
                top: 3px;
                position: relative;
              }
            }
          }
        }

        p {
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 0px;

          @media only screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
          }
        }

        .black-box {
          background: #000000;
          border-radius: 8px;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            @media only screen and (max-width: 400px) {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

      .green-btn {
        width: 100%;
        max-width: 253px;
        height: 56px;
        background: #52dd21;
        border-radius: 10px;
        font-family: "Poppins-Bold";
        color: #000;
        font-size: 15px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          max-width: 225px;
        }

        @media only screen and (max-width: 1200px) {
          max-width: 235px;
          padding: 0px 10px;
        }

        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }

        &::after {
          content: url("../images/btn-black-arrow.png");
          margin-left: 10px;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}

.no-data {
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  display: block;
  padding-top: 10px;
}

#payout-popup {
  z-index: 9999;
  border: 1.5px solid #c80000;
  border-radius: 12px;

  .popover-body {
    background: #640000;
    border-radius: 12px;
    color: #ffffff;
    font-family: "Gilroy-Bold";
    font-size: 14px;
    line-height: 100%;
    text-align: center;
  }

  .popover-arrow {
    &::after {
      content: url("../images/tooltip_arrow.png");
      border-top: none;
      top: -5px;
      left: -8px;
    }
  }
}

#sidebar-popup {
  background: #640000;
  height: 38px;
  width: 145px;
  border: 2px solid #c80000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gilroy-Bold";
  z-index: 9999;
  color: #ffffff;
  bottom: -10px !important;

  @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    width: 115px;
    height: 32px;
  }

  .popover-arrow {
    &::after {
      content: url("../images/tooltip_arrow.png");
      border-top: none;
      top: -1px;
      left: -12px;
    }
  }

  .popover-body {
    font-family: "Gilroy-Bold";
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      font-size: 12px;
    }
  }
}

/*****************betting create challenge new page***********/
body {
  background: #000;
}

.betting_top {
  @media only screen and (min-width: 992px){
    margin-top: 55px;
  }
}

.challenge_tops {

  .betting-left-container {
    top: 90px !important;

    @media only screen and (min-width: 1366px) {
      position: absolute;
      top: auto;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      top: 65px !important;
    }

    @media only screen and (min-width: 1500px) and (max-width: 1919px) {
      height: 80vh;
    }
  }

  .GB_createChallenge {
    padding-top: 100px;

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      padding-top: 80px;
    }
  }

  .container {
    @media only screen and (max-width: 991px) {
      max-width: 100% !important;
      padding: 0;
    }

    // @media only screen and (min-width: 1025px) and (max-width: 1365px){
    //   max-width: 70% !important;
    // }
    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      max-width: 70% !important;
    }
  }
}


/****************** mob-leaderboard *******************/
.mob-leaderboard {
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
  }

  .leaderboard-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #212121;
    border-radius: 10px;
    padding: 15px 10px;
    margin-bottom: 10px;

    .left-sec {
      display: flex;
      align-items: center;

      .rank-sec {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &.goldBox {
          background-color: #FDA829;
        }

        &.silverBox {
          background-color: #444444;
        }

        &.bronzeBox {
          background-color: #D99246;
        }

        &.redBox {
          background-color: #e52420;
          color: #fff;
          font-family: "Gilroy-Bold";
        }
      }

      .profile-name {
        font-family: "Gilroy-Bold";
        font-size: 14px;
        line-height: 14px;
        color: #fff;
        margin-left: 10px;
      }

      .profile-img {
        border: 1px solid #9D9DA3;
        border-radius: 50px;
      }
    }

    .right-sec {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }

      .coins-earned {
        font-family: "Gilroy-Bold";
        font-size: 12px;
        line-height: 12px;
        color: #FFC01F;
      }
    }
  }

  .view-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 10px;

    a {
      border: 2px solid #292929;
      border-radius: 10px;
      padding: 12px 40px;
      text-decoration: none;
      color: #fff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      max-width: 160px;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

/****************** mob-leaderboard End *******************/

/****************** Supported-Game-details Start *******************/
._GB_SupportedGameDetails {
  ._GB_Banner_Top {
    ._GB_support_Details_Banner {
        @media only screen and (min-width: 1025px){
          margin-top: 50px;
        }
      .title {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 40px;
        line-height: 46px;
        @media only screen and (max-width: 767px){
          font-size: 30px;
          line-height: 40px;
        }
      }

      .sub-title {
        color: #fff;
        font-family: "Gilroy-Medium";
        font-size: 20px;
        line-height: 29px;
        padding: 15px 0;
        
      }

      .country-alert {
        background-color: #E52420;
        color: #fff;
        border-radius: 10px;
        font-family: "Gilroy-Medium";
        font-size: 16px;
        line-height: 29px;
        max-width: 771px;
        width: 100%;
        margin-bottom: 20px;
      }

      .support_details_sec {
        flex-wrap: wrap;
        margin: 50px 0;
        justify-content: space-between;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          gap: 0px;
          margin: 10px 0;
        }
        @media only screen and (min-width: 1200px){
          gap: 50px;
        }
        .support-details-left{
          max-width: 20%;
          flex: 0 0 20%;
          @media only screen and (max-width: 1200px){
            max-width: 30%;
            flex: 0 0 30%;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            order: 2;
          }
          .support_questions {
            @media only screen and (min-width: 1025px) {
              width: 100%;
            }

            .support_questions_list {
              background-color: #121212;
              border: 2px solid #2D2D2D;
              border-radius: 10px;
              width: 100%;
              @media only screen and (min-width: 1025px) {
                max-width: 339px;
              }
              .list_head{
                display: flex;
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 28px;
                color: #fff;
                padding: 15px ;
      
                border-bottom: 1px solid #2D2D2D;
              }
              .guide-accordion {
                border: none;
                --bs-accordion-bg: #121212;
                --bs-accordion-border-color: #2D2D2D;
                .accordion-item{
                  border: none;
                  .accordion-button{
                    color: #fff;
                    font-family: "Poppins-SemiBold";
                    font-size: 14px;
                    line-height: 18px;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid #2D2D2D;
                   &:focus{
                    border-color: #2D2D2D;
                    box-shadow: none;
                   }
                   &:not(.collapsed){
                    background-color: #121212 ;
                    &::before{
                      background-image: url("../images/dd_down.png");
                      position: relative;
                      top: 5px;
                      margin-right: 5px;
                    }
                   }
                   &::after{
                    display: none;
                   }
                   &::before{
                    content: "";
                    background-image: url("../images/support/list_arrow.png");
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                   }
                  }
                }
                .active {
                  color: #E52420 !important;
                }
                .accordion-body{
                  color: #fff;
                  .guide-accordin-item{
                    cursor: pointer;
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 10px !important;
                    a{
                      font-family: "Gilroy-Medium";
                      font-size: 14px;
                      line-height: 18px;
                      text-decoration: none;
                      color: #fff;
                    }
                    &:hover{
                      opacity: 0.5;
                    }
                  }
                }
                .collapse{
                  border: none;
                }
                .sub-accordion{
                  .accordion-item{
                      border-bottom: none !important;
                  }
                }
              }
              
            }
          }
          .mobile-table-content{
            margin-top: 30px;
            @media only screen and(min-width: 1200px){
              display: none;
            }
          }
        }
        .support-details-middle {
          max-width: 50%;
          flex: 0 0 50%;
          @media only screen and (min-width: 1024px) and (max-width: 1200px) {
            max-width: 65%;
            flex: 0 0 65%;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            order: 1;
          }

          .support_dota2_content {
            color: #fff;
            margin: 20px 0 35px;
            font-family: "Gilroy-Medium";
            font-size: 16px;
            line-height: 27px;

            a {
              color: #E52420;
            }

            .guide_item {
              padding-left: 20px;

              @media only screen and (max-width: 1200px) {
                padding-left: 10px;
              }

              @media only screen and (max-width: 767px) {
                padding-left: 20px;
              }
            }
            .important{
              background-color: #121212;
              padding: 10px;
              margin-top: 10px;
            }
          }

          .support_data {
            color: #fff;
            font-family: "Gilroy-Bold";
            font-size: 26px;
            line-height: 30px;
            border-top: 3px solid #454545;
            padding-top: 35px;

            .support_dota2_content {
              color: #fff;
              margin-bottom: 50px;
              font-family: "Gilroy-Medium";
              font-size: 16px;
              line-height: 27px;

              .sub_item {
                margin: 15px 0 15px 25px;
                @media only screen and (max-width: 767px){
                  margin: 0;
                }
                img {
                  margin-right: 5px;
                }

                ol {
                  @media only screen and (max-width: 767px){
                    padding-left: 0;
                  }
                  li {
                    margin: 15px;
                  }
                }
              }

              #support-table {
                padding-left: 25px;
                max-width: 711px;
                margin: auto;
                @media only screen and (max-width: 767px) {
                  margin: auto auto 20px auto !important;
                }

                thead {
                  background-color: #1E1E1E;
                  color: rgba(235, 235, 245, 0.6);
                  font-family: "Gilroy-Bold";
                  font-size: 12px;
                  line-height: 12px;

                  tr {
                    border-color: #1E1E1E;

                    th {
                      height: 43px;
                      padding: 15px;
                          background-color: #1E1E1E;
                            color: #fff;
                      &:first-child {
                        border-top-left-radius: 10px;
                      }

                      &:last-child {
                        border-top-right-radius: 10px;
                      }
                    }
                  }
                }

                tbody {
                  color: #fff;
                  font-family: "Gilroy-Bold";
                  font-size: 14px;
                  line-height: 14px;

                  tr {
                    background-color: #161616;
                    border-color: #000;
                    height: 43px;

                    td {
                          background-color: #161616;
                            color: #fff;
                      padding: 15px;
                    }

                    &:last-child {
                      td {
                        border-bottom: none;

                        &:first-child {
                          border-bottom-left-radius: 10px;
                        }

                        &:last-child {
                          border-bottom-right-radius: 10px;
                        }
                      }
                    }
                  }
                }
              }

              .reward-boost-sec {
                width: 100%;
                flex-wrap: wrap;
                justify-content: space-between;
                ;

                @media only screen and (max-width: 767px) {
                  flex-direction: column;
                }

                .reward-boost-left {
                  flex: 0 0 50%;
                  max-width: 50%;

                  @media only screen and (min-width: 768px) and (max-width: 1600px) {
                    flex: 0 0 35%;
                    max-width: 35%;
                  }

                  @media only screen and (max-width: 767px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                  }
                }

                .reward-boost-right {
                  flex: 0 0 50%;
                  max-width: 50%;

                  @media only screen and (min-width: 768px) and (max-width: 1600px) {
                    flex: 0 0 60%;
                    max-width: 60%;
                  }

                  @media only screen and (max-width: 767px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    width: 100%;
                  }
                }

                .reward-boost-table {
                  max-width: 283px !important;
                }

                .boost-data {
                  background: #181818;
                  border: 2px solid #2D2D2D;
                  border-radius: 10px;
                  max-width: 398px;
                  height: 285px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  @media only screen and (max-width: 767px) {
                    margin: auto;
                  }

                  ul {
                    padding: 0;
                    margin: 0;

                    li {
                      font-family: "Gilroy-Medium";
                      font-size: 16px;
                      line-height: 34px;

                      @media only screen and (min-width: 1025px) and (max-width: 1200px) {
                        font-size: 15px;
                      }
                    }
                  }
                }
              }

              .additional-reward-sec {
                margin: 25px 0 15px 45px;

                @media only screen and (max-width: 767px) {
                  margin: 10px 0;
                }

                img {
                  margin-right: 5px;
                }

                ul {
                  margin-left: 15px;
                  margin-top: 20px;

                  li {
                    font-family: "Gilroy-Medium";
                    font-size: 16px;
                    line-height: 27px;
                  }
                }
              }

            }
          }

          .betting_dota_support {
            img {
              margin: 20px 0;
            }

            .challenge-repeat-details {
              li {
                margin-bottom: 10px;
                font-family: "Gilroy-Medium";
                font-size: 16px;
                line-height: 27px;
              }
            }
          }
        }

        .support-details-right {
          max-width: 20%;
          flex: 0 0 20%;

          @media only screen and (min-width: 1025px) and (max-width:1200px) {
            max-width: 35%;
            flex: 0 0 35%;
          }

          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 30px;
          }


          .share-on-sec{
            .sharing-list {
              font-family: "Gilroy-SemiBold";
              font-size: 16px;
              line-height: 27px;
              color: #fff;
              margin: 20px 0;
              background-color: #121212;
              border: 2px solid #2D2D2D;
              border-radius: 10px;
              width: 100%;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              // img {
              //   height: 22px;
              //   width: 22px;
              // }
              // .list_item_data {
              //   font-family: "Gilroy-SemiBold";
              //   font-size: 16px;
              //   line-height: 27px;
              //   color: #fff;
              //   margin-bottom: 0px;
              //   cursor: pointer;
              //   @media only screen and (min-width: 1025px) {
              //     max-width: 262px;
              //   }
              // }

              @media only screen and (min-width: 1200px) and (max-width: 1400px){
                flex-direction: column;
              }

              .shared-media {
              background: #000;
                /* padding: 5px; */
                height: 35px;
                width: 35px;
                border: 1px solid #2D2D2D;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  opacity: 0.5;
                }
              }
            }
            
          }
          .desktop-table-content{
            @media only screen and (max-width: 1200px){
              display: none;
            }
          }
          
        }
        .table-guide-content{
          .related-guides {
            border-bottom: 2px solid #2D2D2D;
            width: 100%;

            .list_head{
              display: flex;
              font-family: "Gilroy-Bold";
              font-size: 24px;
              line-height: 28px;
              color: #fff;
            }
            .list_item {
              border-bottom: 1px solid #2D2D2D;
              display: flex;
              font-family: "Gilroy-Bold";
              font-size: 24px;
              line-height: 28px;
              color: #fff;
              cursor: pointer;
              &:last-child {
                border-bottom: none;
              }

              img {
                margin: 5px 10px 0 0;
                height: 100%;
                cursor: pointer;
              }

              .list_item_data {
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 27px;
                color: #fff;
                margin: 10px 0;
                @media only screen and (min-width: 1025px) {
                  max-width: 262px;
                }
              }
              .active {
                color: #E52420 !important;
              }
            }
          }
          .sharing-list {
            font-family: "Gilroy-SemiBold";
            font-size: 16px;
            line-height: 27px;
            color: #fff;
            margin: 20px 0;
            background-color: #121212;
            border: 2px solid #2D2D2D;
            border-radius: 10px;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            // gap: 10px;
            // img {
            //   height: 22px;
            //   width: 22px;
            // }
            @media only screen and (min-width: 1200px) and (max-width: 1400px){
              flex-direction: column;
              gap: 10px;
            }

            .sharing-list-item{
              gap: 10px;
            }

            .shared-media {
              background: #000;
                /* padding: 5px; */
                height: 35px;
                width: 35px;
                border: 1px solid #2D2D2D;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
              &:hover {
                opacity: 0.5;
              }
            }
          }
          
        }
      }
    }
  }
}

#dropdown-help {
  background-color: #000 !important;
  border-color: #000 !important;
  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  line-height: 16px;
  &:focus{
    box-shadow: none;
  }
}

.help-drop-item {
  &:hover {
    color: #E52420 !important;
  }

  font-family: "Gilroy-SemiBold";
  font-size: 16px;
  line-height: 16px;
  color: #fff !important;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 20px !important;

  &:hover {
    color: #E52420 !important;
  }

}

/****************** Supported-Game-details End *******************/

/****************** Guides Start *******************/
._GB_Guides{
  ._GB_Banner_Top{
    ._GB_Guides_Banner{
      @media only screen and (min-width: 1025px){
        margin-top: 50px;
      }
      .title{
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 40px;
        line-height: 46px;
        @media only screen and (max-width: 767px){
          font-size: 30px;
          line-height: 40px;
        }
      }
      .sub-title{
        gap: 30px;
        font-family: "Gilroy-Medium";
        font-size: 26px;
        line-height: 29px;
        color: #fff;
        margin: 15px 0;
        img{
          border: 2px solid #3D3D3D;
          border-radius: 5px;
          padding: 5px;
        }
      }
      .guides_details_sec{
        

        flex-wrap: wrap;
        margin: 50px 0;
        justify-content: space-between;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          gap: 0px;
          margin: 10px 0;
        }
        @media only screen and (min-width: 1200px){
          gap: 50px;
        }
        .guides-details-left{
          max-width: 20%;
          flex: 0 0 20%;
          @media only screen and (max-width: 1200px){
            max-width: 30%;
            flex: 0 0 30%;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            order: 2;
          }
          .support_questions {
            @media only screen and (min-width: 1025px) {
              width: 100%;
            }

            .support_questions_list {
              background-color: #121212;
              border: 2px solid #2D2D2D;
              border-radius: 10px;
              width: 100%;
              @media only screen and (min-width: 1025px) {
                max-width: 339px;
              }
              .list_head{
                display: flex;
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 28px;
                color: #fff;
                padding: 15px ;
      
                border-bottom: 1px solid #2D2D2D;
              }
              .guide-accordion {
                border: none;
                --bs-accordion-bg: #121212;
                --bs-accordion-border-color: #2D2D2D;
                .accordion-item{
                  border: none;
                  .accordion-button{
                    color: #fff;
                    font-family: "Poppins-SemiBold";
                    font-size: 14px;
                    line-height: 18px;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid #2D2D2D;
                   &:focus{
                    border-color: #2D2D2D;
                    box-shadow: none;
                   }
                   &:not(.collapsed){
                    background-color: #121212 ;
                    &::before{
                      background-image: url("../images/dd_down.png");
                      position: relative;
                      top: 5px;
                      margin-right: 5px;
                    }
                   }
                   &::after{
                    display: none;
                   }
                   &::before{
                    content: "";
                    background-image: url("../images/support/list_arrow.png");
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                   }
                  }
                }
                .active {
                  color: #E52420 !important;
                }
                .accordion-body{
                  color: #fff;
                  .guide-accordin-item{
                    cursor: pointer;
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 10px !important;
                    a{
                      font-family: "Gilroy-Medium";
                      font-size: 14px;
                      line-height: 18px;
                      text-decoration: none;
                      color: #fff;
                    }
                    &:hover{
                      opacity: 0.5;
                    }
                  }
                }
                .collapse{
                  border: none;
                }
              }
              
            }
          }
          .mobile-table-content{
            margin-top: 30px;
            @media only screen and(min-width: 1200px){
              display: none;
            }
          }
        }

        .guides-details-middle{
          max-width: 50%;
          flex: 0 0 50%;
          @media only screen and (min-width: 1024px) and (max-width: 1200px) {
            max-width: 65%;
            flex: 0 0 65%;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            order: 1;
          }
          // .guide_questions {
          //   @media only screen and (min-width: 1025px) {
          //     width: 100%;
          //   }

          //   .support_questions_list {
          //     background-color: #121212;
          //     border: 2px solid #2D2D2D;
          //     border-radius: 10px;
          //     width: 100%;

          //     @media only screen and (min-width: 1025px) {
          //       max-width: 339px;
          //       height: 709px;
          //     }

          //     .list_item {
          //       border-bottom: 1px solid #2D2D2D;
          //       padding: 25px;
          //       display: flex;

          //       &:last-child {
          //         border-bottom: none;
          //       }

          //       img {
          //         margin: 5px 10px 0 0;
          //         height: 100%;
          //         cursor: pointer;
          //       }

          //       .list_item_data {
          //         font-family: "Gilroy-SemiBold";
          //         font-size: 16px;
          //         line-height: 27px;
          //         color: #fff;
          //         margin-bottom: 0px;
          //         cursor: pointer;
          //         @media only screen and (min-width: 1025px) {
          //           max-width: 262px;
          //         }
          //       }

          //       .active {
          //         color: #E52420 !important;
          //       }

          //     }
          //   }
          // }

          .guidelines{
            color: #fff;
            font-family: "Gilroy-Medium";
            font-size: 16px;
            line-height: 27px;
            margin: 15px 0;
            max-width: 771px;
            width: 100%;
            a{
              color: #E52420;
            }
          }
          .transfer_swap_sec{
            font-family: "Gilroy-Medium";
            font-size: 16px;
            line-height: 27px;
            color: #fff;
            .binance_sec{
              padding: 15px;
              border: 5px solid #454545;
              margin: 20px 0;
              background: #181818;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              .binance-link{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                color: #919191;
                text-decoration: none;
                img{
                  width: 16px;
                  height: 16px;
                  margin-right: 10px;
                }
              }
              
              .binance_wrap{
                display: flex;
                gap: 10px;
                img{
                  width: 116px;
                  height: 55px;
                }
                .link-title{
                  color: #0f82af;
                  text-decoration: none;
                  font-family: "Gilroy-Bold";
                  font-size: 16px;
                  line-height: 24px;
                  cursor: pointer;
                  margin-bottom: 10px;
                }
                .binance_descr{
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  line-height: 27px;
                  color: #fff;
                }

              }
            }
          }
          .global_wallet_sec{
            font-family: "Gilroy-SemiBold";
            font-size: 16px;
            line-height: 27px;
            color: #fff;
            a{
              color: #0f82af;
              text-decoration: none;
            }
            ol{
              li{
                color: #fff;
                font-family: "Gilroy-Medium";
                font-size: 16px;
                line-height: 27px;
                margin: 10px 0;
              }
            }
            .deposit_alert{
              background: #181818;
              border: 2px solid #2D2D2D;
              border-radius: 20px;
              padding: 15px 30px;
              margin: 30px 0;
              color: #fff;
              font-family: "Gilroy-Medium";
              font-size: 16px;
              line-height: 27px;
            }
          }
          .block-chain-sec{
            .alert-box{
              background: #121212;
              padding: 15px;
              .alert-title{
                font-family: "Gilroy-SemiBold";
                font-size: 18px;
                line-height: 27px;
                color: #fff;
                margin-bottom: 10px;
              }
              ol{
                li{
                  color: #fff;
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  line-height: 27px;
                }
              }
            }
            
          }
          .wallet_install_sec{
            padding-bottom: 45px;
            border-bottom: 3px solid #454545;
            .guide-title{
              font-family: "Gilroy-Bold";
              font-size: 26px;
              line-height: 29px;
              color: #fff;
            }
            .game_logo{
              font-family: "Gilroy-Bold";
              font-size: 20px;
              line-height: 29px;
              color: #fff;
              img{
                margin-right: 15px;
              }
            }
            .warning_box{
              max-width: 771px;
              width: 100%;
              background: #181818;
              border: 2px solid #E52420;
              border-radius: 20px;
              padding: 30px;
              margin-top: 25px;
              .warning_title{
                font-family: "Gilroy-Bold";
                font-size: 34px;
                line-height: 40px;
                color: #E52420;
                margin-bottom: 10px;
              }
              ul{
                li{
                  color: #fff;
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  line-height: 27px;
                }
              }

            }
            .phantom_main{
              
              .wallet_box{
                max-width: 771px;
                width: 100%;
                background: #181818;
                border: 2px solid #2D2D2D;
                border-radius: 20px;
                padding: 30px;
                .wallet_box_head{
                  color: #fff;
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  line-height: 27px;
        
                  img{
                    margin-right: 15px;
                  }
                  .box_link{
                    opacity: 0.5;
                  }
                }
                .wallet_box_content{
                  gap: 30px;
                  margin-top: 20px;
                  @media only screen and (max-width: 490px){
                    align-items: start !important;
                    flex-direction: column;
                  }
                  .download_app{
                    font-family: "Gilroy-Medium";
                    font-size: 16px;
                    line-height: 27px;
                    color: #fff;
                    span{
                      font-family: "Gilroy-SemiBold";
                      font-size: 18px;
                      line-height: 27px;
                      color: #E52420;
                    }
                   
                  }
                }  
              }
              .web_store_sec{
                margin-top: 25px;
              }
              .wallet_create_steps{
                max-width: 771px;
                width: 100%;
                @media only screen and (max-width: 1200px){
                  flex-wrap: wrap;
                  gap: 30px;
                }
                @media only screen and (min-width: 1201px){
                  justify-content: space-between;
                }
              }
              .wallet_check_sec{
                gap: 30px;
                flex-wrap: wrap;
                img{
                  margin: auto;
                }
              }
            }

          }
          .wallet_swap_sec{
            padding-bottom: 45px;
            border-bottom: 3px solid #454545;
            .sub-title{
              font-family: "Gilroy-Bold";
              font-size: 22px;
              line-height: 26px;
              color: #fff;
            }
            .wallet_alert{
              background: #181818;
              border: 2px solid #2D2D2D;
              border-radius: 20px;
              padding: 15px 30px;
              margin: 30px 0;
              color: #fff;
              font-family: "Gilroy-Medium";
              font-size: 16px;
              line-height: 27px;
            }
            ul{
              li{
                font-family: "Gilroy-Medium";
                font-size: 16px;
                line-height: 27px;
                color: #fff;
                margin: 20px 0;
              }
              .withdraw_popups{
                img{
                  margin-bottom: 20px;
                }
              }
            }
            .connect_wallet{
              gap:30px;
              flex-wrap: wrap;
            }
          }
          .wallet_deposit_sec{
            .deposit_alert{
                background: #181818;
                border: 2px solid #2D2D2D;
                border-radius: 20px;
                padding: 15px 30px;
                margin: 30px 0;
                color: #fff;
                font-family: "Gilroy-Medium";
                font-size: 16px;
                line-height: 27px;
            }
            ul{
              li{
                font-family: "Gilroy-Medium";
                font-size: 16px;
                line-height: 27px;
                color: #fff;
                margin: 20px 0;
              }
            }
          }
          .in-game_wallet{
            border-top: 3px solid #454545;
            padding-bottom: 30px;
            li{
              a{
                color: #0f82af;
                text-decoration: none;
              }
            }
          }
          .live_market_swap{
            border-top: 3px solid #454545;
            font-family: "Gilroy-Medium";
            font-size: 16px;
            line-height: 27px;
            color: #fff;
            .sub-title{
              font-family: "Gilroy-Bold";
              font-size: 22px;
              line-height: 26px;
              color: #fff;
            }
            .birdeye_sec{
              padding: 15px;
              border: 5px solid #454545;
              margin-bottom: 20px;
              background: #181818;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              .birdeye-link{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                color: #919191;
                text-decoration: none;
                img{
                  width: 16px;
                  height: 16px;
                  margin-right: 10px;
                }
              }
              .link-title{
                color: #0f82af;
                text-decoration: none;
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
                margin-bottom: 10px;
              }
              .birdeye_token{
                font-family: "Gilroy-Medium";
                font-size: 16px;
                line-height: 27px;
                color: #fff;
                word-break: break-all;
              
              }
              .market_cap_wrap{
                display: flex;
                gap: 10px;
                img{
                  height: 60px;
                  width: 60px;
                }
              }
            }
          }
        }
        .guides-details-right{
          max-width: 20%;
          flex: 0 0 20%;

          @media only screen and (min-width: 1025px) and (max-width:1200px) {
            max-width: 35%;
            flex: 0 0 35%;
          }

          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 30px;
          }


          .share-on-sec{
            .sharing-list {
              font-family: "Gilroy-SemiBold";
              font-size: 16px;
              line-height: 27px;
              color: #fff;
              margin: 20px 0;
              background-color: #121212;
              border: 2px solid #2D2D2D;
              border-radius: 10px;
              width: 100%;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              // img {
              //   height: 22px;
              //   width: 22px;
              // }
              @media only screen and (min-width: 1200px) and (max-width: 1400px){
                flex-direction: column;
              }

              .shared-media {
                background: #000;
                  /* padding: 5px; */
                  height: 35px;
                  width: 35px;
                  border: 1px solid #2D2D2D;
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                &:hover {
                  opacity: 0.5;
                }
              }
            }
            
          }
          .desktop-table-content{
            @media only screen and (max-width: 1200px){
              display: none;
            }
          }
          
        }
        .table-guide-content{
          .related-guides {
            border-bottom: 2px solid #2D2D2D;
            width: 100%;

            .list_head{
              display: flex;
              font-family: "Gilroy-Bold";
              font-size: 24px;
              line-height: 28px;
              color: #fff;
            }
            .list_item {
              border-bottom: 1px solid #2D2D2D;
              display: flex;
              font-family: "Gilroy-Bold";
              font-size: 24px;
              line-height: 28px;
              color: #fff;
              cursor: pointer;
              &:last-child {
                border-bottom: none;
              }

              img {
                margin: 5px 10px 0 0;
                height: 100%;
                cursor: pointer;
              }

              .list_item_data {
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 27px;
                color: #fff;
                margin: 10px 0;
                @media only screen and (min-width: 1025px) {
                  max-width: 262px;
                }
              }
              .active {
                color: #E52420 !important;
              }
            }
          }
          .sharing-list {
            font-family: "Gilroy-SemiBold";
            font-size: 16px;
            line-height: 27px;
            color: #fff;
            margin: 20px 0;
            background-color: #121212;
            border: 2px solid #2D2D2D;
            border-radius: 10px;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            // gap: 10px;
            // img {
            //   height: 22px;
            //   width: 22px;
            // }
            @media only screen and (min-width: 1200px) and (max-width: 1400px){
              flex-direction: column;
              gap: 10px;
            }

            .sharing-list-item{
              gap: 10px;
            }

            .shared-media {
            background: #000;
              /* padding: 5px; */
              height: 35px;
              width: 35px;
              border: 1px solid #2D2D2D;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                opacity: 0.5;
              }
            }
          }
          
        }
      }
    }
  }
}
/****************** Guides End *******************/



/****************** Guides GetStart start *******************/
._GB_guide_getstart{
    ._GB_guide_getstart_inner{
      @media only screen and (min-width: 1025px){
        margin-top: 50px;
      }
      .desktop-table-content{
        @media only screen and (max-width: 1400px){
          display: none;
        }
      }
      .mobile-table-content{
        @media only screen and(min-width: 1400px){
          display: none;
        }
      }
     
      .guide_getstart_sec{
        flex-wrap: wrap;
        margin: 50px 0;
        justify-content: space-between;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
          gap: 0px;
          margin: 10px 0;
        }
        @media only screen and (min-width: 1200px){
          gap: 40px;
        }
        .guide-getstart-left{
          max-width: 20%;
          flex: 0 0 20%;
          // @media only screen and (min-width: 1400px){
          //   position: fixed;
          // }
          @media only screen and (max-width: 1400px){
            max-width: 30%;
            flex: 0 0 30%;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            order: 2;
          }
          .search_title{
            font-family: "Gilroy-Bold";
            font-size: 28px;
            line-height: 32px;
            color: #fff;
            height: 55px;
            display: flex;
            align-items: center;
            margin-bottom: 50px;
            
            @media only screen and (max-width: 1200px){
              font-size: 24px;
              line-height: 30px;
            }
            @media only screen and(max-width: 990px){
              max-width: 100%;
              flex: 0 0 100%;
            }
          }
          .our_guides {
            @media only screen and (min-width: 1025px) {
              width: 100%;
            }

            .our_guides_list {
              background-color: #121212;
              border: 1px solid #2D2D2D;
              border-radius: 10px;
              width: 100%;
              @media only screen and (min-width: 1025px) {
                max-width: 339px;
              }
              .list_head{
                display: flex;
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 28px;
                color: #fff;
                padding: 15px ;
      
                border-bottom: 1px solid #2D2D2D;
              }
              .guide-accordion {
                border: none;
                --bs-accordion-bg: #121212;
                --bs-accordion-border-color: #2D2D2D;
                .accordion-item{
                  border: none;
                  border-bottom: 1px solid #2D2D2D !important;
                  &:last-of-type{
                    border-bottom: 0;
                    border-bottom-left-radius: 10px !important;
                    border-bottom-right-radius: 10px !important;
                  }
                  .accordion-button{
                    color: #fff;
                    font-family: "Poppins-SemiBold";
                    font-size: 14px;
                    line-height: 18px;
                    border-radius: 0;
                    border: none;
                    border-bottom: 1px solid #2D2D2D;
                    padding: 20px 15px;
                   &:focus{
                    border-color: #2D2D2D;
                    box-shadow: none;
                   }
                   &.collapsed{
                    &:last-of-type{
                      border-bottom: 0;
                      border-bottom-left-radius: 10px !important;
                      border-bottom-right-radius: 10px !important;
                    }
                   }
                   &:not(.collapsed){
                    background-color: #121212 ;
                    &::before{
                      background-image: url("../images/dd_down.png");
                      position: relative;
                      top: 5px;
                      padding-right: 5px;
                    }
                   }
                   &::after{
                    display: none;
                   }
                   &::before{
                    content: "";
                    background-image: url("../images/support/list_arrow.png");
                    width: 20px;
                    height: 20px;
                    background-repeat: no-repeat;
                    padding-left: 10px;
                   }
                  }
                }
                .active {
                  color: #E52420 !important;
                }
                .accordion-body{
                  color: #fff;
                  .guide-accordin-item{
                    cursor: pointer;
                    //max-width: 177px;
                    width: 100%;
                    margin-bottom: 10px !important;
                    a{
                      font-family: "Gilroy-Medium";
                      font-size: 14px;
                      line-height: 18px;
                      text-decoration: none;
                      color: #fff;
                    }
                    &:hover{
                      opacity: 0.5;
                    }
                  }
                }
                .collapse{
                  border: none;
                }
              }
              .sub-accordion{
                .accordion-item{
                    border-bottom: none !important;
                    .guide-accordin-item{
                      max-width: 100%;
                    }
                }
              }
              
            }
          }
          .table-guide-content{
            .sharing-list {
              font-family: "Gilroy-SemiBold";
              font-size: 16px;
              line-height: 27px;
              color: #fff;
              margin: 20px 0;
              background-color: #121212;
              border: 2px solid #2D2D2D;
              border-radius: 10px;
              width: 100%;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              // gap: 10px;
              // img {
              //   height: 22px;
              //   width: 22px;
              // }
              @media only screen and (min-width: 1200px) and (max-width: 1400px){
                flex-direction: column;
                gap: 10px;
              }
  
              .sharing-list-item{
                gap: 10px;
              }
  
              .shared-media {
                // background: #000;
                // padding:5px;
                // border: 1px solid #2D2D2D;
                // border-radius: 8px;
                // display: flex;
                // align-items: center;
                &:hover {
                  opacity: 0.5;
                }
              }
            }
            
          }
        }
        .guide-getstart-middle{
          max-width: 50%;
          flex: 0 0 50%;
          // @media only screen and (min-width: 1400px){
          //   margin: auto;
          // }
          @media only screen and (min-width: 1024px) and (max-width: 1400px) {
            max-width: 65%;
            flex: 0 0 65%;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
          }
          .guide_middle_top{
            display: flex;
            justify-content: space-between;
            margin-bottom: 50px;
            @media only screen and (max-width: 767px){
              flex-direction: column;
              gap: 20px;
              margin-bottom: 35px;
            }
            .search_field{
              max-width: 70%;
              flex: 0 0 70%;
              position: relative;
              @media only screen and (max-width: 767px){
                max-width: 100%;
                flex: 0 0 100%;
              }
              .search_data{
                max-width: 457px;
                width: 100%;
                height: 55px;
                border: 2px solid #262627;
                background: #000;
                border-radius: 10px;
                font-family: "Gilroy-Medium";
                font-size: 16px;
                line-height: 16px;
                color: #9C9CA3;
                &:focus{
                  box-shadow: none;
                }
              }
              .search-dropdown{
                border: 2px solid #262627;
                border-radius: 10px;
                background: #121212;
                max-width: 457px;
                width: 100%;
                min-height: 105px;
                position: absolute;
                color: #9C9CA3;
                padding: 20px;
                z-index: 1;
                ul{
                  padding: 0;
                  margin-bottom: 0;
                  li{
                    list-style: none;
                    margin-bottom: 10px;
                    font-family: "Gilroy-Medium";
                    font-size: 16px;
                    line-height: 16px;
                    &:hover{
                      opacity: 0.5;
                    }
                    &.no_result{
                      opacity: 1;
                    }
                  }  
                }
                .search_result{
                  font-size: 16px;
                  line-height: 16px;
                  font-family: "Gilroy-Medium";
                  text-align: center;
                  margin-top: 20px;
                  &:hover{
                    opacity: 1;
                  }
                  span{
                    font-family: "Gilroy-SemiBold";
                  }
                  .result_count{
                    color: #C80000;
                  }
                }
              }
              input[type="search"]::-webkit-search-cancel-button:hover { 
                cursor:pointer; 
              }
            }
            .search_btn{
                max-width: 151px;
                width: 100%;
                color: #fff;
                background-color: #e52420;
                background-image: linear-gradient(90deg,
                      #6d1513,
                      #e52420,
                      #6d1513);
                border-radius: 10px;
                border: 2px solid #ffffff;
                font-family: "Poppins-SemiBold";
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                letter-spacing: 0.04em;
                text-decoration: none;
                padding: 15px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                &::after{
                  content: url("../images/arrow.png");
                  padding-left: 10px;
                }
                &:hover{
                  opacity: 0.5;
                }
            }
          }
          
          .title {
            color: #fff;
            font-family: "Gilroy-Bold";
            font-size: 40px;
            line-height: 46px;
            @media only screen and (max-width: 767px){
              font-size: 30px;
              line-height: 40px;
            }
          }
    
          .sub-title {
            color: #fff;
            font-family: "Gilroy-Medium";
            font-size: 20px;
            line-height: 29px;
            padding: 15px 0;
            
          }
    
          .country-alert {
            background-color: #E52420;
            color: #fff;
            border-radius: 10px;
            font-family: "Gilroy-Medium";
            font-size: 16px;
            line-height: 29px;
            max-width: 771px;
            width: 100%;
            margin-bottom: 20px;
            padding: 5px;
          }
          .get_start_box{
            flex-wrap: wrap;
            gap: 25px;
            @media only screen and (min-width: 1025px) and (max-width: 1400px){
              gap: 22px;
            }
            .box_title{
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 28px;
              line-height: 38px;
              @media only screen and (max-width: 767px){
                font-size: 24px;
                line-height: 30px;
              }
            }
            .box_subtitle{
                font-family: "Gilroy-Medium";
                font-size: 18px;
                line-height: 24px;
                color: #fff;
            }
            .box_item{
              border: 3px solid #2D2D2D;
              border-radius: 10px;
              padding: 25px;
              max-width: 48%;
              flex: 0 0 48%;
              background-size: cover;
              background-repeat: no-repeat;
              @media only screen and (max-width: 767px){
                max-width: 100%;
                flex: 0 0 100%;
              }
              .box_item_head{
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 28px;
                color: #fff;
              }
              .box_item_content{
                font-family: "Gilroy-Medium";
                font-size: 14px;
                line-height: 25px;
                color: #fff;
                margin: 20px 0;
              }
              .box_item_btn{
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 27px;
                color: #E52420;
                cursor: pointer;
                a{
                  color: #E52420;
                  text-decoration: none;
                }
                &:hover{
                  opacity: 0.5;
                }
                img{
                  margin-left: 5px;
                }
              }
            }
          }
          .intro-video-sec{
            margin: 65px 0;
            @media only screen and (max-width: 767px){
              margin: 40px 0 0;
            }
            .intro-title{
              margin-bottom: 20px;
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 28px;
              line-height: 38px;
              @media only screen and (max-width: 767px){
                font-size: 24px;
                line-height: 30px;
              }
            }
          }
          .view-btn{
            margin: 30px 0 50px;
            max-width: 215px;
            width: 100%;
            color: #fff;
            background-color: #e52420;
            background-image: linear-gradient(90deg,
                  #6d1513,
                  #e52420,
                  #6d1513);
            border-radius: 10px;
            border: 2px solid #ffffff;
            font-family: "Poppins-SemiBold";
            font-size: 16px;
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            text-decoration: none;
            padding: 15px;
            cursor: pointer;
            @media only screen and (max-width: 767px){
              margin: 30px 0;
            }
            &::after{
              content: url("../images/arrow.png");
              padding-left: 10px;
            }
            &:hover{
              opacity: 0.5;
            }
          }
          .demo-sec{
            justify-content: space-between;
            width: 100%;
            background-color: #121212;
            border: 1px solid #2D2D2D;
            border-radius: 10px;
            padding: 10px 20px;
            margin-top: 30px;
            margin-bottom: 30px;
            @media only screen and (min-width: 1025px){
              max-width: 690px ;
              padding: 10px 35px;
            }
            .demo_left{
                max-width: 38%;
                flex: 0 0 38%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .demo_right{
              max-width: 60%;
              flex: 0 0 60%;
              .demo_head{
                font-family: "Gilroy-Bold";
                font-size: 24px;
                line-height: 28px;
                color: #fff;
              }
              .demo_content{
                font-family: "Gilroy-Medium";
                font-size: 14px;
                line-height: 25px;
                color: #fff;
              }
              .readmore_btn{
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 27px;
                color: #E52420;
                cursor: pointer;
                &:hover{
                  opacity: 0.5;
                }
                img{
                  margin-left: 5px;
                }
              }

            }
          }
        }

        .guide-getstart-right{
          max-width: 20%;
          flex: 0 0 20%;
          margin-top: 105px;
          // @media only screen and (min-width: 1400px){
          //   position: fixed;
          // }
          @media only screen and (min-width: 1025px) and (max-width:1200px) {
            max-width: 35%;
            flex: 0 0 35%;
          }

          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 30px;
          }

          @media only screen and (max-width: 767px) {
            margin-bottom: 0px;
          }

          .share-on-sec {
            .sharing-list {
              font-family: "Gilroy-SemiBold";
              font-size: 16px;
              line-height: 27px;
              color: #fff;
              background-color: #121212;
              border: 2px solid #2D2D2D;
              border-radius: 10px;
              width: 100%;
              height: 100px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              // img {
              //   height: 22px;
              //   width: 22px;
              // }
              .shared-media {
                    background: #000;
                      /* padding: 5px; */
                      height: 35px;
                      width: 35px;
                      border: 1px solid #2D2D2D;
                      border-radius: 8px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                &:hover {
                  opacity: 0.5;
                }
              }
              @media only screen and (min-width: 1200px) and (max-width: 1400px){
                flex-direction: column;
              }
              .sharing-list-item{
                gap: 10px;
                margin-top: 10px;
              }
            }
            
          }
          .mobile-table-content{
            margin-top: 30px;
            @media only screen and(min-width: 1200px){
              display: none;
            }
          }
        }
      }
    }
}
/****************** Guides  GetStart End *******************/


/****************** Help Faq Start *******************/
._GB_helpFaq{
    ._GB_helpFaq_inner{
      @media only screen and (min-width: 1025px){
        margin: 50px 0 100px;
      }
      .helpFaq_sec{
        gap: 50px;
        flex-wrap: wrap;
        
        @media only screen and (min-width: 1600px){
          justify-content: space-between;
        }
        @media only screen and (max-width: 1400px){
          justify-content: space-between;
        }
        @media only screen and (max-width: 1024px){
          flex-direction: column;
        }
        
        .helpFaq_sec_left{
          max-width: 20%;
          flex: 0 0 20%;
          @media only screen and (max-width: 1400px) {
            max-width: 25%;
            flex: 0 0 25%;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            margin-bottom: 50px;
            order: 2;
          }
          .our_faq_list {
            background-color: #121212;
            border: 2px solid #2D2D2D;
            border-radius: 10px;
            width: 100%;
            @media only screen and (min-width: 1025px) {
              max-width: 339px;
            }
            .list_head{
              display: flex;
              font-family: "Gilroy-Bold";
              font-size: 24px;
              line-height: 28px;
              color: #fff;
              padding: 15px ;
    
              border-bottom: 1px solid #2D2D2D;
            }
            .guide-accordion {
              border: none;
              --bs-accordion-bg: #121212;
              --bs-accordion-border-color: #2D2D2D;
              .accordion-item{
                border-bottom: 1px solid #2D2D2D;
                &:last-of-type{
                  border-bottom: 0;
                  border-bottom-left-radius: 10px !important;
                  border-bottom-right-radius: 10px !important;
                }
                .accordion-button{
                  color: #fff;
                  font-family: "Poppins-SemiBold";
                  font-size: 14px;
                  line-height: 18px;
                  border-radius: 0;
                  border: none;
                  border-bottom: 1px solid #2D2D2D;
                  padding: 20px 15px;
                 &:focus{
                  border-color: #2D2D2D;
                  box-shadow: none;
                 }
                 &.collapsed{
                  &:last-of-type{
                    border-bottom: 0;
                    border-bottom-left-radius: 10px !important;
                    border-bottom-right-radius: 10px !important;
                  }
                 }
                 
                 &:not(.collapsed){
                  background-color: #121212 ;
                  &::before{
                    background-image: url("../images/dd_down.png");
                    position: relative;
                    top: 5px;
                    padding-right: 5px;
                  }
                 }
                 &::after{
                  display: none;
                 }
                 &::before{
                  content: "";
                  background-image: url("../images/support/list_arrow.png");
                  width: 20px;
                  height: 20px;
                  background-repeat: no-repeat;
                  padding-left: 10px;
                 }
                }
              }
              .active {
                color: #E52420 !important;
              }
              .accordion-body{
                color: #fff;
                border-bottom: 1px solid #2D2D2D;
                .guide-accordin-item{
                  cursor: pointer;
                  width: 100%;
                  font-family: "Gilroy-Medium";
                  font-size: 14px;
                  line-height: 18px;
                  margin-bottom: 10px !important;
                  &:hover{
                    opacity: 0.5;
                  }
                }
              }
              .collapse{
                border: none;
              }
              .sub-accordion{
                .accordion-item{
                    border-bottom: none !important;
                }
              }
            }
            
          }
        }
        .helpFaq_sec_middle{
          max-width: 775px;
          width: 100%;
          @media only screen and (max-width: 1400px) {
            max-width: 68%;
            flex: 0 0 68%;
          }
          @media only screen and (max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
            order: 1;
          }
          .helpFaq_search{
            gap: 20px;
            @media only screen and (max-width: 767px){
              flex-direction: column;
            }
            .search_field{
                width: 100%;
                position: relative;
              .search_data{
                  max-width: 600px;
                  width: 100%;
                  height: 55px;
                  border: 2px solid #262627;
                  background: #000;
                  border-radius: 10px;
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  line-height: 16px;
                  color: #9C9CA3;
                  &:focus{
                    box-shadow: none;
                  }
                }
                .search-dropdown{
                  border: 2px solid #262627;
                  border-radius: 10px;
                  background: #121212;
                  max-width: 600px;
                  width: 100%;
                  min-height: 105px;
                  position: absolute;
                  color: #9C9CA3;
                  padding: 20px;
                  z-index: 1;
                 
                  ul{
                    padding: 0;
                    margin-bottom: 0;
                    li{
                      list-style: none;
                      margin-bottom: 10px;
                      font-family: "Gilroy-Medium";
                      font-size: 16px;
                      line-height: 16px;
                      &:hover{
                        opacity: 0.5;
                      }
                      &.no_result{
                        opacity: 1;
                      }
                    }  
                  }
                  .search_result{
                    font-size: 16px;
                    line-height: 16px;
                    font-family: "Gilroy-Medium";
                    text-align: center;
                    margin-top: 20px;
                    &:hover{
                      opacity: 1;
                    }
                    span{
                      font-family: "Gilroy-SemiBold";
                    }
                    .result_count{
                      color: #C80000;
                    }
                  }
                }
                input[type="search"]::-webkit-search-cancel-button:hover { 
                  cursor:pointer; 
                }
            }
            .search_btn{
              max-width: 151px;
              height: 55px;
              width: 100%;
              color: #fff;
              background-color: #e52420;
              background-image: linear-gradient(90deg,
                    #6d1513,
                    #e52420,
                    #6d1513);
              border-radius: 10px;
              border: 2px solid #ffffff;
              font-family: "Poppins-SemiBold";
              font-size: 16px;
              line-height: 16px;
              text-transform: uppercase;
              letter-spacing: 0.04em;
              text-decoration: none;
              padding: 15px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              &::after{
                content: url("../images/arrow.png");
                padding-left: 10px;
              }
              &:hover{
                opacity: 0.5;
              }
            }
          }
          .helpFaq_title_sec{
            margin: 35px 0; 
            @media only screen and (max-width: 767px){
              margin: 25px 0 5px 0;
            }
            .title {
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 40px;
              line-height: 46px;
              @media only screen and (max-width: 767px){
                font-size: 28px;
                line-height: 40px;
              }
            }
      
            .sub-title {
              color: #fff;
              font-family: "Gilroy-Medium";
              font-size: 20px;
              line-height: 29px;
              padding: 15px 0;
              
            }
          }
          .helpFaq_box{
            flex-wrap: wrap;
            gap: 25px;
            @media only screen and (min-width: 1025px) and (max-width: 1400px){
              gap: 22px;
            }
            .box_title{
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 28px;
              line-height: 38px;
              @media only screen and (max-width: 767px){
                font-size: 24px;
                line-height: 30px;
              }
            }
            .box_subtitle{
                font-family: "Gilroy-Medium";
                font-size: 18px;
                line-height: 24px;
                color: #fff;
            }
            .box_item{
              border: 3px solid #2D2D2D;
              border-radius: 20px;
              padding: 25px;
              max-width: 48%;
              flex: 0 0 48%;
              height: 250px;
              display: flex;
              flex-direction: column;
              background-size: cover;
              justify-content: center;
              background-repeat: no-repeat;
              @media only screen and (max-width: 767px){
                max-width: 100%;
                flex: 0 0 100%;
              }
              .box_item_head{
                font-family: "Gilroy-Bold";
                font-size: 22px;
                line-height: 25px;
                color: #fff;
              }
              .box_item_content{
                font-family: "Gilroy-Medium";
                font-size: 14px;
                line-height: 25px;
                color: #fff;
                margin: 20px 0;
                @media only screen and (max-width: 990px){
                  margin: 10px 0;
                }
              }
              .box_item_btn{
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 27px;
                color: #E52420;
                cursor: pointer;
                &:hover{
                  opacity: 0.5;
                }
                a{
                  color: #E52420;
                  text-decoration: none;
                }
                img{
                  margin-left: 5px;
                }
              }
              
            }
          }
          
        }
        .helpFaq_sec_right{
          max-width: 15%;
          flex: 0 0 15%;

          @media only screen and (max-width: 1200px) {
            max-width: 100%;
            flex: 0 0 100%;
          }
                    .share-on-sec {
                      .sharing-list {
                        font-family: "Gilroy-SemiBold";
                        font-size: 16px;
                        line-height: 27px;
                        color: #fff;
                        background-color: #121212;
                        border: 2px solid #2D2D2D;
                        border-radius: 10px;
                        width: 100%;
                        height: 100px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
          
                        // img {
                        //   height: 22px;
                        //   width: 22px;
                        // }
          
                        .shared-media {
                          background: #000;
                            /* padding: 5px; */
                            height: 35px;
                            width: 35px;
                            border: 1px solid #2D2D2D;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
          
                          &:hover {
                            opacity: 0.5;
                          }
                        }
          
                        @media only screen and (min-width: 1200px) and (max-width: 1400px) {
                          flex-direction: column;
                        }
          
                        .sharing-list-item {
                          gap: 10px;
                          margin-top: 10px;
                        }
                      }
          
                    }
        }
      }
      
    }
}
/****************** Help Faq End *******************/


/****************** Manage Faq End *******************/

._GB_guidesWithdraw{
  .support_data{
    border-top: none !important;
    border-bottom: 3px solid #454545;
  }
}
/****************** Manage Faq End *******************/


/****************** Faq Deails Start *******************/
._GB_faq_details{
  .faq_data{
      font-family: "Gilroy-Medium";
      font-size: 16px;
      line-height: 27px;
      color: #ffffff;
      margin: 20px 0;
  }
  .faq-list{
    .list-head{
      font-family: "Gilroy-Bold";
      font-size: 28px;
      line-height: 32px;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .faq-list-item{
      font-family: "Gilroy-Medium";
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img{
        margin-right: 10px;
      }
    }
  }
}

/****************** Faq Deails End *******************/

.guide-landing-toc{
  .table-guide-content {
      .related-guides {
        border-bottom: 2px solid #2D2D2D;
        width: 100%;
  
        .list_head {
          display: flex;
          font-family: "Gilroy-Bold";
          font-size: 24px;
          line-height: 28px;
          color: #fff;
        }
  
        .list_item {
          border-bottom: 1px solid #2D2D2D;
          display: flex;
          font-family: "Gilroy-Bold";
          font-size: 24px;
          line-height: 28px;
          color: #fff;
          cursor: pointer;
  
          &:last-child {
            border-bottom: none;
          }
  
          img {
            margin: 5px 10px 0 0;
            height: 100%;
            cursor: pointer;
          }
  
          .list_item_data {
            font-family: "Gilroy-SemiBold";
            font-size: 16px;
            line-height: 27px;
            color: #fff;
            margin: 10px 0;
  a{
    color: #fff !important;
    text-decoration: none;
    display: block;
    &:hover{
      color: #E52420!important;
    }
  }
            @media only screen and (min-width: 1025px) {
              max-width: 262px;
            }
          }
  
          .active {
            color: #E52420 !important;
          }
        }
      }
  
      .sharing-list {
        font-family: "Gilroy-SemiBold";
        font-size: 16px;
        line-height: 27px;
        color: #fff;
        margin: 20px 0;
        background-color: #121212;
        border: 2px solid #2D2D2D;
        border-radius: 10px;
        width: 100%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        // gap: 10px;
  
        // img {
        //   height: 22px;
        //   width: 22px;
        // }
  
        @media only screen and (min-width: 1200px) and (max-width: 1400px) {
          flex-direction: column;
          gap: 10px;
        }
  
        .sharing-list-item {
          gap: 10px;
        }
  
        .shared-media {
          background: #000;
            /* padding: 5px; */
            height: 35px;
            width: 35px;
            border: 1px solid #2D2D2D;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
  
          &:hover {
            opacity: 0.5;
          }
        }
      }
  
    }
}
.serachresultmiddle {
  max-width: 100% !important;
  @media only screen and (min-width: 1200px) {
    max-width: 1024px !important;
  }
}

.no-result-search {
  color: #fff;
  font-family: "Gilroy-Medium";
  font-size: 18px;
}
.guide-accordion{
  --bs-accordion-transition: none !important;
}