.tournamentSec {
  .tournament_container {
    max-width: 1193px;
        padding: 0 20px;
    margin: auto;
    @media only screen and (max-width: 1365px) {
      margin: auto 5%;
    }
    @media only screen and (min-width: 1366px) and (max-width: 1500px) {
      margin: auto 15%;
    }
  }
  .tournamentSec_Inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #3f3f3f;
    padding-bottom: 10px;
    @media only screen and (max-width: 767px){
      flex-direction: column;
    }
    .title {
      font-family: "Gilroy-Bold";
      font-size: 30px;
      line-height: 35px;
      color: #ffffff;
      padding-bottom: 10px;
      margin-top: 30px;
    }
  
   .tournament_games{
    display: flex;
    align-items: center;
    gap: 5px;
    .selected_game{
      padding: 5px;
      border: 2px solid #484848;
      border-radius: 8px;
      cursor: pointer;
      &:hover,&.active{
        border: 2px solid #C80000;
        background-color: #640000;
        img{
          background-color: #640000;
        }
      }
      img{
        width: 30px;
        height: 30px;
      }
    }
   }
   
  }
  .filter-Sec{
    padding: 20px 0;
    border-bottom: 1px solid #3f3f3f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 1024px){
      flex-direction: column;
      gap: 10px;
    }
    .filter-inner{
      display: flex;
      align-items: center;
      gap: 10px;
      max-width: 60%;
      flex: 0 0 60%;
      @media only screen and (max-width: 1024px){
        max-width: 100%;
        flex: 0 0 100%;
        flex-wrap: wrap;
      }
      .commonTFilter{
        select{
          max-width: 176px; 
          width: 100%;
          border: 2px solid #262627;
          border-radius: 10px;
          background-color: #000;
          color: #fff;
          font-size: 14px;
          line-height: 14px;
          padding: 5px 10px;
          font-family: "Gilroy-SemiBold";
          cursor: pointer;
          option{
            font-size: 14px;
            line-height: 14px;
            font-family: "Gilroy-SemiBold";
          }
        }
      }
    }
   
   }
   .clearFilter_Btn{
    border: 2px solid #535353;
    background-color: #1D1D1D;
    border-radius: 10px;
    max-width: 127px;
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Gilroy-Bold";
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    &:hover{
      opacity: 0.5;
    }
   }
   .sort-by{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 14px;
    max-width: 35%;
    flex: 0 0 35%;
    font-family: "Gilroy-Bold";
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    @media only screen and (max-width: 1024px){
      max-width: 100%;
      flex: 0 0 100%;
      width: 100%;
      justify-content: center;
      gap: 10px;
    }
    .sort-options{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #1D1D1D;
      border-radius: 10px;
      max-width: 337px;
      width: 100%;
      height: 38px;
      padding: 5px;
      .sort-item{
        max-width: 101px;
        width: 100%;
        height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-transform: uppercase;
        &:hover,&.active{
          border: 1px solid #C80000;
          background-color: #640000;
          border-radius: 8px;
        }
      }
    }
   }
  .change_game_sec {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    .selGametxt {
      font-size: 16px;
      line-height: 19px;
      color: #fff;
      display: flex;
      align-items: center;
      font-family: "Gilroy-Bold";
      margin-right: 15px;
    }
    .titles_games {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
      .all-btn {
        width: 80px;
        height: 50px;
        border: 2px solid #484848;
        border-radius: 8px;
        cursor: pointer;
        padding: 4px;
        margin-right: 10px;
        font-family: "Gilroy-Bold";

        &:hover {
          background: rgba(200, 0, 0, 0.5) !important;
          border-color: #c80000;
        }

        &.active {
          background: rgba(200, 0, 0, 0.5) !important;
          border-color: #c80000;
        }
      }

      .summary_gameleft {
        display: flex;
      }

      .summary_logo {
        display: flex;
      }

      .btn.show {
        background: transparent;
        border: none;
      }

      img {
        width: 44px;
        height: 44px;
        border: 2px solid #484848;
        border-radius: 8px;
        cursor: pointer;
        padding: 4px;
        margin: 2px;

        &:hover {
          background: rgba(200, 0, 0, 0.5);
          border-color: #c80000;
        }

        &.active {
          background: rgba(200, 0, 0, 0.5);
          border-color: #c80000;
        }

        &.summary_filter {
          margin-left: 15px;
          padding: 12px;
        }

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          width: 50px;
          height: 50px;
        }
      }

      .qrcode {
        width: auto;
        height: auto;
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }

      .qrcode-item {
        p {
          margin-bottom: 0;
        }
      }

      .dropdown-menu {
        transform: translate(-93px, 68px) !important;

        @media only screen and(max-width: 991px) {
          transform: translate(0px, 64px) !important;
        }
      }

      .dropdown-item {
        padding: 8px 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.active {
          background: #c80000;
          border-radius: 14px;
        }
      }

      .btn-primary {
        background: transparent !important;
        padding: 0;
        box-shadow: none;
      }

      .summary_logofilter {
        .dropdown {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  .tournament_form {
    border: 2px solid #2d2d2d;
    background-color: #121212;
    border-radius: 10px;
    margin: 20px 0 50px;
    .tournament_rules {
      padding: 20px;
      .rules_inner {
        background-color: #202020;
        border-radius: 10px;
        padding: 15px;
        .rules_top {
          display: flex;
          align-items: center;
          .rules_head {
            font-family: "Gilroy-Bold";
            font-size: 24px;
            line-height: 28px;
            color: #ffffff;
            padding: 0 10px;
          }
          .rules_down {
            cursor: pointer;
          }
          .rules_up {
            cursor: pointer;
            transform: rotate(180deg);
          }
        }
        .rules_bottom {
          .rules-details {
            display: flex;
            margin-top: 20px;

            @media only screen and (max-width: 1200px) {
              flex-direction: column;
            }

            .rule-box {
              flex: 0 0 40%;
              max-width: 40%;

              @media only screen and (max-width: 1200px) {
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
              }

              ul {
                margin-bottom: 0;

                li {
                  font-family: "Gilroy-Bold";
                  color: #ffffff;
                  font-size: 17px;
                  line-height: 26px;
                  margin-bottom: 5px;

                  @media only screen and (max-width: 767px) {
                    font-size: 16px;
                  }
                }
              }
            }

           
          }
        }
      }
    }
  }
    
  ._Dashboard_Game_Stats {
    ._Game_Stats_inner {
      ._Game_Stats_top {
        .change_game {
          .games_list img {
            width: 44px;
            height: 44px;
          }
        }
      }
    }
  }
  ._addicon {
    width: 44px !important;
    height: 44px !important;
  }
.tournament_details{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px 0;
  gap: 42px;
  @media only screen and (max-width: 1200px){
    gap: 21px;
  }
  .tournament_details_box{
    flex: 0 0 30%;
    max-width: 30%;
    @media only screen and (max-width: 1200px){
      flex: 0 0 46%;
      max-width: 46%;
    }
    @media only screen and (max-width: 1200px){
      flex: 0 0 48%;
      max-width: 48%;
    }
    @media only screen and (max-width: 575px){
      flex: 0 0 100%;
      max-width: 100%;
    }
    .tournamentBox{
      background-image: url("../images/tournament/tournamnetbox_bg.png");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.2);
      background-size: cover;
      background-repeat: no-repeat;
      height: 229px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      &.dota2 {
          background-image: url("../images/prev-dota2-bg.jpg");
          //box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
        }
      
        &.aoe2 {
          background-image: url("../images/aoe2_wall.jpg");
          //box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
        }
      
        &.aoe4 {
          background-image: url("../images/aoe4_leaderboardbg.jpg");
         // box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
        }
      
        &.starcraft2 {
          background-image: url("../images/starcraft2_lb.png");
         // box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
        }
      
        &.tf2 {
          background-image: url("../images/tf2-big.jpg");
         // box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
        }

        &.pubg_normal_squad_fpp {
          background-image: url("../images/tournament/pubg_normal_squad_fpp.jpg");
        }

        &.pubg_normal_squad_tpp {
          background-image: url("../images/tournament/pubg_normal_squad_tpp.jpg");
        }

        &.pubg_ranked_squad {
          background-image: url("../images/tournament/pubg_ranked_squad.jpg");
              background-position: right;
        }

        &.dota2_captain {
          background-image: url("../images/tournament/dota2_captain.jpg");
        }

        
      .tornamentBox_top{
        display: flex;
        justify-content: space-between;
        .game_logo{
          padding-top: 23px;
          padding-left: 23px;
           img {
              width: 60px;
            }
        }
        .earned_amount{
          margin-top: 15px;
          margin-right: 12px;
          background-color: #000000;
          opacity: 0.9;
          max-width: 180px;
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          border-radius: 12px;
          font-size: 14px;
          color: #FFC01F;
          font-family: "Gilroy-Bold";

        }
      }
      
    }
        .tournamentBox_bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          //padding: 0px 22px 15px;
        padding: 10px 15px;
          background: #0F0F0F;
          .tournamentBox_left {
            display: flex;
            align-items: center;
            gap: 10px;
    
            .tournament_fee {
              display: flex;
              align-items: center;
              gap: 5px;
              font-size: 15px;
              //line-height: 14px;
              color: #FFC01F;
              font-family: "Gilroy-Bold";
            }
    
            .players_count {
              display: flex;
              align-items: center;
              gap: 5px;
              font-size: 15px;
              //line-height: 14px;
              color: #FFC01F;
              font-family: "Gilroy-Bold";
            }
    
            #player_popover {
              background: #181818 !important;
              height: 48px;
              width: 152px;
              border: 2px solid #484848;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 12px;
              font-size: 14px;
              line-height: 14px;
              font-family: "Gilroy-Medium";
              cursor: pointer;
    
              .popover-arrow {
                &::after {
                  content: url("../images/arrow-black.png");
                  border-top: none;
                  top: -1px;
                  left: -8px;
                }
              }
    
              .popover-body {
                color: rgba(235, 235, 245, 0.6);
              }
    
            }
          }
    
          .tournamentBox_right {
            display: flex;
            align-items: center;
            gap: 5px;
            color: #fff;
            font-family: "Poppins-SemiBold";
            font-size: 15px;
           // line-height: 12px;
          }
        }
    .tournamentBox_content{
      background-color: #181818;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      .content_top{
        display: flex;
        flex-direction: column;
        color: #fff;
        padding: 14px 12px;
        .player_name{
          font-size: 22px;
          line-height: 27px;
          font-family: "Gilroy-Bold";
          margin-bottom: 14px;
        }
        .tournament_earning{
          .progress{
              background-color: #000000;
              height: 7px;
              .progress-bar{
                background-color: #40AB00;
                border-radius: 5px;
              }
          }
                                        .time_left {
                                          font-family: "Poppins-SemiBold";
                                          font-size: 15px;
                                          line-height: 25px;
                                          color: #fff;
                                          margin-top: 15px;
                                          display: flex;
                                          justify-content: space-between;
                                        }
                    
                                        .tour_end_display {
                                          font-family: "Poppins-SemiBold";
                                          font-size: 15px;
                                          line-height: 25px;
                                          color: #fff;
                                          margin-top: 10px;
                                        }
                    
                                        .time_duration {
                                          font-family: "Poppins-SemiBold";
                                          font-size: 16px;
                                          line-height: 25px;
                                          margin-top: 10px;
                                          opacity: 0.6;
                                        }
          
        }
      }
      .content_bottom{
        background-color: #0F0F0F;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 12px;
        .user_List{
          display: flex;
          align-items: center;
          img{
            margin-left: -10px;
            &:first-child{
              margin-left: 0;
            }
                                width: 35px;
                                height: 35px;
                                border-radius: 25px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                object-fit: cover;
          }
          .more{
            height: 26px;
            background-color: #e52420;
            color: #fff;
            width: 35px;
            height: 35px;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: -10px;
            font-family: "Poppins-SemiBold";
            font-size: 13px;
            line-height: 13px;
          }
        }
        .game_mode{
          color: #FFFFFF;
          opacity: 0.5;
          font-family: "Gilroy-Bold";
          font-size: 14px;
          line-height: 14px;
          margin-bottom: 0;
        }
      }
      .content_Btn{
        display: flex;
        justify-content: space-between;
        padding: 12px 18px 14px;
        @media only screen and (max-width: 767px){
          flex-direction: column;
          gap: 10px;
        }
        .seeBtn{
          border: 2px solid #535353;
          max-width: 150px;
          width: 100%;
          height: 43px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          font-family: "Poppins-SemiBold";
          font-size: 13px;
          line-height: 13px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          text-decoration: none;
          color: #fff;
          cursor: pointer;
          &:hover{
            opacity: 0.5;
          }
          @media only screen and (max-width: 767px){
            width: 100%;
            max-width: 100%;
          }          
        }
        .joinBtn{
          border: 2px solid #FFFFFF;
          max-width: 150px;
          width: 100%;
          height: 43px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px; 
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
          font-family: "Poppins-SemiBold";
          font-size: 13px;
          line-height: 13px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          text-decoration: none;
          cursor: pointer;
          &:hover{
            opacity: 0.5;
          }
          @media only screen and (max-width: 767px){
            width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
}
.tournament_Head {
  padding: 30px 0;
  border-bottom: 2px solid #343434;
  .head_Inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .step_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 0 0 15%;
      max-width: 15%;
      @media only screen and (max-width: 1365px) {
        flex: 0 0 9%;
        max-width: 9%;
      }

      &:last-child {
        @media only screen and (max-width: 575px) {
          flex: 0 0 10%;
          max-width: 10%;
        }
        .steps_icon {
          &::after {
            border: none;
          }
        }
      }
      .steps_icon {
        position: relative;
        img {
          cursor: pointer;
        }
        &::after {
          content: "";
          position: absolute !important;
          border-top: 2px solid #343434;
          width: 140px;
          left: 30px;
          top: 8px;
          @media only screen and (max-width: 1365px) {
            width: 70px;
            left: 25px;
          }
          @media only screen and (max-width: 1200px) {
            width: 60px;
            left: 28px;
          }
          @media only screen and (min-width: 991px) and (max-width: 1024px) {
            width: 64px;
          }
          @media only screen and (max-width: 990px) {
            width: 48px;
            left: 28px;
          }
          @media only screen and (max-width: 767px) {
            width: 63px;
            left: 18px;
          }
          @media only screen and (max-width: 575px) {
            border-top: none;
          }
        }
        .active_step {
          filter: drop-shadow(0px 2px 5px#52DD21);
        }
      }
      img {
        margin-bottom: 10px;
      }
      .step_name {
        color: #ffffff;
        font-size: 15px;
        line-height: 17px;
        font-family: "Gilroy-SemiBold";
        @media only screen and (max-width: 1365px) {
          text-align: center;
        }
        @media only screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 15px;
        }
        @media only screen and (max-width: 575px) {
          font-size: 10px;
          line-height: 13px;
        }
      }
    }
  }
}

.tournament_steps {
  padding: 20px;
  .step-title {
    color: #fff;
    font-size: 26px;
    line-height: 30px;
    font-family: "Gilroy-Bold";
    margin-bottom: 30px;
    @media only screen and (max-width: 767px) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .tournament_field {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    @media only screen and (max-width: 575px) {
      flex-direction: column;
      margin-bottom: 20px;
    }
    input {
      background-color: #000000;
      border: 2px solid #262627;
      height: 51px;
      border-radius: 10px;
      color: #fff !important;
      font-size: 16px;
      line-height: 18px;
      font-family: "Gilroy-SemiBold";
      option {
        color: #fff !important;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .tornament-select {
      background-image: url("../images/tournament/dd_down.png") !important;
      background-size: 12px 8px;
    }
    select {
      background-color: #000000;
      border: 2px solid #262627;
      border-radius: 10px;
      width: 100%;
      height: 51px;
      color: #fff;
      //padding: 5px 5px 5px 10px;
      font-size: 16px;
      line-height: 18px;
      font-family: "Gilroy-SemiBold";
      &:focus {
        box-shadow: none;
      }
    }
    label {
      color: #ffffff;
      font-size: 16px;
      line-height: 17px;
      font-family: "Gilroy-SemiBold";
      margin-bottom: 10px;
    }
    .gb-ct-date {
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
        cursor: pointer;
      }
    }
    .form_field_left {
      flex: 0 0 48%;
      max-width: 48%;
      @media only screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      
      &.form_field_left_not_allowed {
        pointer-events: none;
        opacity: 0.4;
      }
    }
    .form_field_full{
      width: 100%;
      .logo_upload {
          //cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 10px;
          height: 51px;
          padding: 15px;
      
          .logo_name {
            color: #fff;
            opacity: 0.6;
            font-size: 20px;
            line-height: 20px;
            font-family: "Gilroy-SemiBold";
            margin: 0 10px;
          }
      
          .logo_size {
            color: #ffffff;
            font-size: 11px;
            line-height: 12px;
            opacity: 0.6;
            font-family: "Gilroy-SemiBold";
          }
        }
    }
    .form_field_right {
      flex: 0 0 48%;
      max-width: 48%;
      @media only screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .minFee {
        color: #ffffff;
        font-size: 15px;
        line-height: 12px;
        font-family: "Gilroy-SemiBold";
        opacity: 0.5;
        margin-top: 5px;
      }
      &.form_field_right_not_allowed {
        pointer-events: none;
        opacity: 0.4;
      }
    }
    .orSec {
      color: #fff;
      font-size: 18px;
      line-height: 18px;
      font-family: "Gilroy-SemiBold";
      display: flex;
      align-items: center;
      @media only screen and (max-width: 575px) {
        justify-content: center;
        margin: 20px 0;
      }
    }
    .count_field {
      flex: 0 0 47%;
      max-width: 47%;
      display: flex;
      align-items: center;
      gap: 30px;
      background-color: #121212;
      border: 2px solid #262627;
      border-radius: 10px;
      width: 100%;
      height: 51px;
      color: #fff;
      padding: 10px;
      cursor: pointer;
      @media only screen and (max-width: 767px) {
        flex: 0 0 46%;
        max-width: 46%;
      }
      @media only screen and (max-width: 575px) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 15px 10px;
      }
      label {
        margin-bottom: 0px;
        cursor: pointer;
      }
      /* The container */
      .btn-container {
        display: block;
        position: relative;
        padding-left: 20px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @media only screen and (max-width: 767px) {
          padding-left: 0px;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
        }
      }

      /* Create a custom radio button */
      .checkmark {
        position: absolute;
        top: -11px;
        left: 3px;
        height: 22px;
        width: 22px;
        background-color: #000000;
        border-radius: 50%;
        border: 2px solid #2d2d2d;

        &::after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      /* When the radio button is checked, add a blue background */
      .btn-container input:checked ~ .checkmark {
        background-color: #000000;
      }

      /* Show the indicator (dot/circle) when checked */
      .btn-container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the indicator (dot/circle) */
      .btn-container .checkmark:after {
        top: 5px;
        left: 4px;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #40ab00;
      }
      &.sector_field {
        flex: 0 0 49% !important;
        max-width: 49% !important;
      }
    }
    .score_field {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #000;
      border: 2px solid #262627;
      border-radius: 10px;
      .field_title {
        max-width: 30%;
        flex: 0 0 30%;
        color: #fff;
        padding-left: 20px;
        font-size: 18px;
        line-height: 18px;
        font-family: "Gilroy-SemiBold";
        border-right: 2px solid #262627;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
      }
      .tournament_conditions {
        max-width: 70%;
        flex: 0 0 70%;
        padding-left: 10px;
        .score-type-select {
          border: none;
          box-shadow: none;
          width: 100%;
          background-image: url("../images/tournament/dd_down.png") !important;
          background-size: 12px 8px;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
}
.next-step{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 50px auto;
    @media only screen and (max-width: 575px) {
      gap: 10px;
    }
    .bottomBtn {
      max-width: 124px;
      width: 100%;
      height: 49px;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 9px;
      font-family: "Gilroy-Bold";
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 18px 25px;
      @media only screen and (max-width: 400px) {
        max-width: 115px;
      }
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
      &.nextBtnLoader {
        max-width: 260px;
      }
    }
    .backBtn {
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      &::before {
        content: url("../images/tournament/back_arrow.png");
        padding-right: 10px;
      }
    }
    .nextBtn {
      background-image: linear-gradient(to right, #175900, #52dd21, #175900);
      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }
    }
    .pay_and_confirmBtn {
      max-width: 226px !important;
      width: 100%;
      height: 49px;
      background-color: #e52420;
      margin: 0;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      &::after {
        content: url("../images/arrow.png");
        padding-left: 5px;
      }
    }
  }
  .pot_field {
    .pot_head {
      color: #fff;
      width: 100%;
      border-bottom: 2px solid #343434;
      padding-bottom: 20px;
      font-size: 20px;
      line-height: 23px;
      font-family: "Gilroy-Bold";
    }
    .potSec {
      width: 100%;
      .pot_title {
        width: 100%;
        color: #ffffff;
        font-size: 18px;
        line-height: 18px;
        font-family: "Gilroy-SemiBold";
        margin-top: 40px;
        margin-bottom: 14px;
      }
      .potBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        //justify-content: space-between;
        gap: 15px;
          justify-content: flex-start;
        // @media only screen and (max-width: 1400px) {
        //   gap: 20px;
        //   justify-content: flex-start;
        // }

        .boxItem {
          max-width: 241px;
          width: 100%;
          height: 51px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #1d1d1d;
          border: 2px solid #262627;
          border-radius: 10px;
          color: #ffffff;
          font-size: 18px;
          line-height: 18px;
          font-family: "Gilroy-SemiBold";
          cursor: pointer;
          @media only screen and (max-width: 767px) {
            max-width: 100%;
          }
          &:hover,
          &.active {
            border: 2px solid #52dd21;
            background-color: rgba(82, 221, 33, 0.05);
          }
        }
      }
    }
  }
  .prize_percentage {
    display: flex;
    align-items: center;
    margin-top: 30px;
    @media only screen and (max-width: 575px) {
      flex-direction: column;
    }
    .prize_left {
      max-width: 60%;
      flex: 0 0 60%;
      @media only screen and (max-width: 575px) {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
      }
      .prizeBox {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        @media only screen and (max-width: 767px) {
          justify-content: flex-start;
        }
        @media only screen and (max-width: 575px) {
          justify-content: center;
        }
        .prize_value_per {
          max-width: 368px;
          width: 100%;
          height: 51px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 20px;
          line-height: 20px;
          border: 2px solid #262627;
          border-radius: 10px;
          background-color: #000;
          @media only screen and (max-width: 990px) {
            max-width: 50%;
          }
          span {
            color: #ffc52e;
          }
        }
        .prize_position {
          color: #fff;
          margin-left: 20px;
          font-size: 18px;
          line-height: 18px;
          font-family: "Gilroy-SemiBold";
          @media only screen and (max-width: 575px) {
            width: 90px;
          }
        }
      }
    }
    .prize_right {
      max-width: 40%;
      flex: 0 0 40%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media only screen and (max-width: 575px) {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
        justify-content: center;
        margin-top: 10px;
      }
      .rewards_based_per {
        max-width: 242px;
        width: 100%;
        height: 104px;
        color: #ffc52e;
        font-size: 26px;
        line-height: 30px;
        font-family: "Gilroy-Bold";
        background-color: #1d1d1d;
        border-radius: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
.game_mode {
  margin-bottom: 30px;
  &.gb_ch_err {
      .gameModeBox {
        border: 2px solid #e52420 !important;
      }
    }
  label {
    color: #ffffff;
    font-size: 16px;
    line-height: 17px;
    font-family: "Gilroy-SemiBold";
    margin-bottom: 10px;
  }
  .game_mode_inner {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    &.game_mode_inner_start {
      justify-content: flex-start;
    }
    @media only screen and (max-width: 1200px) {
      justify-content: flex-start !important;
    }
    @media only screen and (max-width: 575px) {
      flex-direction: column;
      gap: 15px;
    }
    .gameModeBox {
      max-width: 32%;
      flex: 0 0 32%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid #262627;
      background-color: #1d1d1d;
      border-radius: 10px;
      height: 51px;
      padding: 15px;
      cursor: pointer;
      @media only screen and (max-width: 575px) {
        padding: 10px;
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
      &:hover,
      &.active {
        border: 2px solid #52dd21;
        background-color: rgba(82, 221, 33, 0.05);
      }
      .mode_name {
        color: #ffffff;
        font-size: 15px;
        line-height: 12px;
        font-family: "Gilroy-SemiBold";
        margin-left: 10px;
      }
    }
  }
}
.next-step {
  display: flex;
  justify-content: center;
  align-items: center;
  .nextBtn {
    cursor: pointer;
    max-width: 124px;
    width: 100%;
    height: 49px;
    color: #fff;
    background-color: #e52420;
    background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
    border-radius: 10px;
    border: 2px solid #ffffff;
    font-family: "Gilroy-SemiBold";
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: url("../images/arrow.png");
      padding-left: 10px;
    }

    &:hover {
      opacity: 0.5;
    }
    &.nextBtnLoader {
      max-width: 260px;
    }
  }
}
.payment_field {
  .entryFee_sec {
    display: flex;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    .entryFee_left {
      max-width: 70%;
      flex: 0 0 70%;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
        flex: 0 0 100%;
      }
      .entryFee_notify {
        color: #fff;
        max-width: 456px;
        width: 100%;
        font-size: 18px;
        line-height: 20px;
        font-family: "Gilroy-SemiBold";
        @media only screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
    .entryFee_right {
      max-width: 30%;
      flex: 0 0 30%;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 10px;
      }
      .entryFee_box {
        max-width: 267px;
        width: 100%;
        height: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: #1d1d1d;
        border-radius: 18px;
        font-size: 26px;
        line-height: 30px;
        font-family: "Gilroy-Bold";
        @media only screen and (max-width: 990px) {
          font-size: 22px;
          line-height: 26px;
        }
        @media only screen and (max-width: 990px) {
          max-width: 100%;
        }
        .amount {
          color: #ffc52e;
          margin-left: 15px;
        }
      }
    }
  }
  .next_Btn {
    max-width: 124px;
    margin: 50px auto 40px auto;
    width: 100%;
    height: 49px;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 9px;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 25px;
    cursor: pointer;
    background-image: linear-gradient(to right, #175900, #52dd21, #175900);
    &::after {
      content: url("../images/arrow.png");
      padding-left: 10px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
  .percentage_sec {
    display: flex;
    align-items: center;
    gap: 10px;
    .percentage_Box {
      width: 47px;
      height: 31px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      line-height: 19px;
      font-family: "Gilroy-Bold";
      border: 2px solid #2d2d2d;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        border: 2px solid #ffc01f;
        background-color: rgba(255, 192, 31, 0.4);
      }
      &.active_item {
        border: 2px solid #ffc01f;
        background-color: rgba(255, 192, 31, 0.4);
      }
    }
  }
  .slot_sec {
    border-top: 2px solid #343434;
    padding-top: 30px;
    display: flex;
    gap: 30px;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
    .slot_left {
      max-width: 341px;
      width: 100%;
      font-size: 20px;
      line-height: 26px;
      color: #fff;
      font-family: "Gilroy-SemiBold";
      @media only screen and (max-width: 767px) {
        max-width: 100%;
      }
    }
    .slot_right {
      background-color: #1d1d1d;
      max-width: 557px;
      border-radius: 18px;
      width: 100%;
      padding: 15px 35px;
      @media only screen and (max-width: 767px) {
        max-width: 100%;
      }
      .slot_box {
        display: flex;
        align-items: center;
        .slot_boxleft {
          max-width: 50%;
          flex: 0 0 50%;
          @media only screen and (max-width: 767px) {
            max-width: 30%;
          }
          .member_name {
            color: #fff;
            font-size: 16px;
            line-height: 19px;
            font-family: "Gilroy-Bold";
          }
        }
        .slot_boxRight {
          display: flex;
          align-items: center;
          gap: 10px;
          max-width: 50%;
          flex: 0 0 50%;
          @media only screen and (max-width: 767px) {
            max-width: 70%;
          }
          .paidBtn {
            border: 2px solid #52dd21;
            text-transform: uppercase;
          }
          .payBtn {
            text-transform: uppercase;
            background-color: #e52420;
            border: 2px solid #fff;
            background-image: linear-gradient(
              to right,
              #6d1513,
              #e52420,
              #6d1513
            );
            &:hover {
              opacity: 0.5;
            }
          }
          .slotBtn {
            max-width: 128px;
            width: 100%;
            height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 14px;
            color: #fff;
            font-family: "Gilroy-Bold";
            border-radius: 10px;
            margin-bottom: 10px;
            cursor: pointer;
          }
          .delete_Btn {
            background-color: #2d2d2d;
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}
.prizePot_Modal {
  max-width: 528px;
  .modal-body {
    .prizePot_wrapper {
      max-width: 366px;
      margin: auto;
      .prizePot_inner {
        color: #fff;
        font-size: 19px;
        line-height: 25px;
        font-family: "Gilroy-Bold";
        .prizePot_content {
          margin: 10px 0;
        }
        .selected_tournament {
          background-color: #1d1d1d;
          color: #ffc52e;
          padding: 5px;
          border-radius: 10px;
        }
        .entry_fee {
          background-color: #1d1d1d;
          border-radius: 10px;
          color: #52dd21;
          padding: 5px;
        }
        .pot_type {
          background-color: #1d1d1d;
          border-radius: 10px;
          color: #e52420;
          padding: 5px;
        }
        .proceed_sec {
          font-size: 15px;
          line-height: 17px;
          font-family: "Gilroy-SemiBold";
          margin: 20px 0;
          @media only screen and (max-width: 400px) {
            margin: 15px 0;
          }
        }
        .nextBtn {
          max-width: 124px;
          margin: 20px auto 50px auto;
          width: 100%;
          height: 49px;
          color: #fff;
          border: 2px solid #fff;
          border-radius: 9px;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 16px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 18px 25px;
          cursor: pointer;
          background-image: linear-gradient(
            to right,
            #175900,
            #52dd21,
            #175900
          );
          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.bet-amount-block {
  width: 100%;
  max-width: 550px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #181818;
  padding: 3px 4px;
  border-radius: 11px;
  gap: 10px;

  @media only screen and(min-width: 1366px) and(max-width: 1919px) {
    max-width: 58%;
  }

  @media only screen and (max-width: 1280px) {
    max-width: 60%;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
  }

  @media only screen and (max-width: 767px) {
    gap: 10px;
    padding: 15px 4px;
  }

  @media only screen and (max-width: 400px) {
    gap: 5px;
  }

  .form-control {
    width: 100%;
    max-width: 181px;
    height: 50px;
    background: #000000;
    border: 2px solid #2d2d2d;
    border-radius: 12px;
    font-family: "Gilroy-Bold";
    font-size: 26px;
    line-height: 100%;
    text-align: center;
    color: #fe5242;

    @media only screen and(min-width: 1366px) and(max-width: 1919px) {
      font-size: 20px;
    }

    @media only screen and(min-width: 1366px) and(max-width: 1600px) {
      max-width: 240px;
    }

    @media only screen and (max-width: 575px) {
      max-width: 375px;
      font-size: 18px;
    }
  }

  .up-down {
    background-image: url("../images/up-down.png");
    height: 20px;
    min-width: 21px;
    cursor: pointer;
    display: inline-block;
    background-size: cover;

    //margin-right: 4px;
    @media only screen and (max-width: 400px) {
      min-width: 10px;
      height: 11px;
    }
  }

  .bet-amount-unit {
    width: 100%;
    max-width: 135px;

    @media only screen and (max-width: 400px) {
      max-width: 95px;
    }

    .dropdown-toggle {
      background: #262627;
      border-radius: 8px;
      width: 100%;
      max-width: 165px;
      height: 50px;
      border: none;
      font-family: "Gilroy-Bold";
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and(min-width: 1366px) and(max-width: 1600px) {
        max-width: 125px;
      }

      @media only screen and (max-width: 575px) {
        max-width: 98px;
        font-size: 12px;
        line-height: 12px;
      }

      &:after {
        content: url("../images/betting/drop-arrow.png");
        width: 12px;
        border: none;
        margin-left: auto;
      }

      &.before-coin {
        display: flex;
        align-items: center;
        gap: 6px;
        justify-content: flex-start;

        &::before {
          content: url("../images/betting/coin-gamebeef.png");
          top: 1px;
          position: relative;
        }
      }

      &.sol-coin {
        display: flex;
        align-items: center;
        gap: 6px;

        &::before {
          content: url("../images/betting/solana_coin.png");
          top: 1px;
          position: relative;
        }
      }
    }

    .dropdown-menu {
      @media only screen and (max-width: 575px) {
        min-width: 98px;
      }

      .dropdown-item {
        padding: 5px 10px;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 575px) {
          gap: 5px;
          font-size: 12px;
          line-height: 16px;
        }

        .usdt_coin,
        .usdc_coin,
        .sol-coin {
          margin-right: 5px;
        }
      }

      .before-coin {
        display: flex;
        align-items: center;
        gap: 6px;

        &::before {
          content: url("../images/betting/coin-gamebeef.png");
          top: 3px;
          position: relative;
        }
      }
    }
  }

  p {
    font-family: "Gilroy-SemiBold";
    font-size: 16px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 0px;

    @media only screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .black-box {
    background: #000000;
    border-radius: 8px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      @media only screen and (max-width: 400px) {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.fee_block {
  margin-top: 20px;
}

.tournament-rules {
  .modal-body {
    padding: 0 !important;
    .heading-bar {
      width: 100% !important;
      max-width: 100% !important;
      border-bottom: 2px solid #2d2d2d;
    }
    .personal-form {
      padding: 20px 30px !important;
      @media only screen and (max-width: 767px) {
        padding: 20px !important;
      }
      .betting-rules {
        .rule {
          //height: 248px !important;
          li {
            font-size: 14px !important;
            line-height: 20px !important;
          }
        }
      }
    }
  }
}
.thankyou-popup {
  max-width: 488px;
  margin: auto;
  @media only screen and (max-width: 575px) {
    padding: 20px;
  }
  .modal-header {
    padding-bottom: 20px;
  }
  .thankyou_sec {
    .thankyou_inner {
      padding: 0 30px 30px;
      @media only screen and (max-width: 767px) {
        padding: 0 0px 30px;
      }
      @media only screen and (max-width: 400px) {
        padding: 0px;
      }
      img {
        width: 82px;
        height: 82px;
        @media only screen and (max-width: 767px) {
          width: 60px;
          height: 60px;
        }
      }
      .thankyou_content {
        color: #fff;
        font-size: 19px;
        line-height: 25px;
        font-family: "Gilroy-Bold";
        margin-top: 30px;
        @media only screen and (max-width: 767px) {
          font-size: 17px;
          line-height: 23px;
        }
        @media only screen and (max-width: 400px) {
          margin-top: 10px;
        }
      }
    }
  }
}
.no-tour-game {
  font-family: "Gilroy-Bold";
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #842029;
  padding: 20px;
  max-width: 80%;
  margin: 20px auto;
  border: solid 1px red;
  border-radius: 10px;
}

.tf-req {
  color: #c80000;
  font-size: 18px;
  padding-left: 3px;
}

.changeImgBtn {
  background: #52dd21;
  padding: 2px 10px;
  border-radius: 7px;
  font-family: "Gilroy-Bold";
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}
.changeImgBtnRed{
background: red;
}

.tdz_Modal {
  .modal-content {
    background: #181818;
    border: 2px solid #2d2d2d;
    .modal-header {
      border-bottom: none !important;
      .btn-close {
        background: url("../images/close_button.png") no-repeat !important;
      }
      h5 {
        color: #fff;
        font-size: 26px;
        line-height: 30px;
        font-family: "Gilroy-Bold";
        padding: 15px 15px 0;
      }
    }
    .modal-body {
      .tdz_wrapper {
        .dzu-dropzone {
          overflow: auto !important;
          background-color: #262627;
          border: 2px solid #000;
          .dzu-inputLabel {
            color: #ffc52e;
            font-family: "Gilroy-Bold";
          }
          .dzu-previewButton {
            background-image: url("../images/close_button.png") !important;
          }
          .dzu-previewContainer {
            border: none !important;
            padding: 5px 15px !important;
            justify-content: space-evenly !important;
          }
          .dzu-submitButton {
            background-color: #e52420;
            background-image: linear-gradient(
              to right,
              #6d1513,
              #e52420,
              #6d1513
            );
            font-family: "Gilroy-Bold";
            text-transform: uppercase;
          }
        }
      }
    }
    .modal-footer {
      border-top: none !important;
    }
  }
}
.gbt-err {
  border: 2px solid #e52420 !important;
}
.gbt-err-text {
  color: #e52420;
  font-family: "Gilroy-SemiBold";
  padding: 8px 5px 0;
}
.gbt_agree_label {
  font-family: "Gilroy-SemiBold";
  font-size: 15px;
  color: #9c9ca3;
}
.gbt-agree-warning {
  color: #e52420;
  font-family: "Gilroy-SemiBold";
  text-align: center;
  margin: 15px 0 0;
}
._gbt_C{
.active-tour-game {
  background: #c80000;
  margin: 30px 0 50px;
  padding: 10px;

  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .active-tour-game-left {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #000;
    .active-tour-head {
      font-family: "Gilroy-Bold";
      font-size: 22px;
    }
    .active-tour-title {
      font-family: "Gilroy-Bold";
      font-size: 16px;
    }
  }
  .active-tour-game-right {
    button {
      background-color: #000000;
      color: #fff;
      border: none;
      padding: 15px 30px;
      border-radius: 8px;
      font-family: "Gilroy-Bold";
      font-size: 18px;
      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }
    }
  }
}
}
.gbt_form_field_date {
  position: relative;
      flex: 0 0 100%;
        max-width: 100%;
            margin-top: 15px;
  .range-calander {
    &.range-calander-gbt-time{
      right: 0 !important;
      top: 40px !important;
    }
    .rdrDateRangePickerWrapper {
      display: block !important;
    }
    .rdrDefinedRangesWrapper {
      display: none !important;
    }
    .rdrDateDisplay {
      display: none !important;
    }
    .rdrCalendarWrapper {
      padding: 25px 6px !important;
    }
    .rdrDayDisabled {
      background-color: rgba(0, 0, 0, 0.1) !important;
      cursor: default !important;
    }
  }
  .start_date_field {
    background-color: #000000;
    border: 2px solid #262627;
    height: 51px;
    border-radius: 10px;
    color: #fff !important;
    font-size: 16px;
    line-height: 18px;
    font-family: "Gilroy-SemiBold";
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    .start_date_field_display {
      flex: 0 0 90%;
      max-width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      ._gbt_start_end{
            display: flex;
              align-items: center;
              select{
                cursor: pointer;
                border: none !important;
                height: auto !important;
              }
      }
    }
    .start_date_field_cal {
      img {
        cursor: pointer;
      }
    }
  }
  &.admin_gbt_form_field_date{
    .start_date_field{
      height: auto;
      padding: 10px 15px;
    }
    ._gbt_start_end {
      gap: 15px;
    }
    .start_date_field_display {
      gap: 10px;
    }
        select.search-admin-form-control {
          padding: 7px;
        }
  }
}
.ad_tour_btn {
  cursor: pointer;
  color: #fff;
  background-color: #e52420;
  background-image: linear-gradient(90deg, #6d1513, #e52420, #6d1513);
  border-radius: 10px;
  border: 2px solid #fff;
  font-family: "Poppins-SemiBold";
  text-transform: uppercase;
  letter-spacing: 0.04em;
  padding: 10px 20px;
  text-decoration: none;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  margin-bottom: 10px;
}
.ad_tour_game_td {
  display: flex;
  gap: 10px;
  align-items: center;
}
.gtbt_fund_warning {
  font-family: "Gilroy-Bold";
  font-size: 20px;
  color: #fe5242;
  padding: 10px 0;
}
//tournament landing
.tournament_landing_top {
  .tournament-banner.tour_game_banner {
    background-image: url("../images/blastbanner.png");
    
    &.dota2 {
        background-image: url("../images/dota2_background.png");
    }
  
    &.pubg {
      background-image: url("../images/blastbanner.png");
    }

    &.pubg_normal_squad_fpp {
      background-image: url("../images/tournament/pubg_normal_squad_fpp.jpg");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
    }

    &.pubg_normal_squad_tpp {
      background-image: url("../images/tournament/pubg_normal_squad_tpp.jpg");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
    }

    &.pubg_ranked_squad {
      background-image: url("../images/tournament/pubg_ranked_squad.jpg");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
      background-position: bottom right;
      background-size: auto;
    }

    &.dota2_captain {
      background-image: url("../images/tournament/dota2_captain.jpg");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
    }
  
    &.warzone {
      background-image: url("../images/warzone_background.png");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
    }
  
    &.aoe2 {
      background-image: url("../images/aoe2_wall.jpg");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    }

    &.aoe4 {
      background-image: url("../images/aoe4_leaderboardbg.jpg");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    }

    &.starcraft2 {
      background-image: url("../images/starcraft2_lb.png");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
    }

    &.tf2 {
      background-image: url("../images/tf2-big.jpg");
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.3);
    }



    
    background-size: cover;
    background-position: top center;

    .banner_head {
      font-size: 50px;
      line-height: 60px;
    }

    .tournament-Subtitle.putdown {
      margin-top: 25px;
    }

    .banner_subhead {
      font-size: 22px;
      line-height: 32px;
    }

    .banner_subhead_col {
      font-size: 22px;
      line-height: 32px;
      color: #ffc01f;
      margin-top: 20px;
    }

    .cont_sec {
      margin: 20px 0;
    }

   

    .countdown-digit {
      margin-top: 0px;
      margin-bottom: 0 !important;
    }
  }

  .prize-section {
    padding-top: 0px;
    margin-top: 70px;
  }

  .seasonResult_game {
    justify-content: space-between;

    .season_title {
      display: flex;
      align-items: center;
    }

    .playerratio {
      font-family: "Gilroy-Bold";
      font-size: 26px;
      line-height: 30.47px;
      letter-spacing: 0.04em;
      text-align: right;
      color: #fff;

      @media only screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 20px;
      }

      img {
        margin-right: 10px;
      }
    }
  }

  .season_Scoreboard {
    margin-top: 30px;

    .tot-prizes {
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 23px;
      color: #fff;
    }

    .seasonResult_table {
      #seasonResult_Table {
        thead {
          tr {
            th:nth-child(6) {
              text-align: center;
            }
          }

        }

        tbody {
          tr {
            td:nth-child(6) {
              text-align: center;
            }
          }

        }

      }

    }
  }

  .tournament_rules_top {
    margin-top: 70px;

    .tournament_rules {
      padding: 0px;

      .rules_inner {
        background-color: #202020;
        border-radius: 10px;
        padding: 15px 50px 15px 35px;

        .rules_top {
          display: flex;
          align-items: center;

          .rules_head {
            font-family: "Gilroy-Bold";
            font-size: 24px;
            line-height: 28px;
            color: #ffffff;
            padding: 0 10px;
          }

          .rules_down {
            cursor: pointer;
          }

          .rules_up {
            cursor: pointer;
            transform: rotate(180deg);
          }
        }

        .rules_bottom {
          .rules-details {
            display: flex;
            margin-top: 20px;

            @media only screen and (max-width: 1200px) {
              flex-direction: column;
            }

            .rule-box {
              flex: 0 0 40%;
              max-width: 40%;

              @media only screen and (max-width: 1200px) {
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
              }

              ul {
                margin-bottom: 0;

                li {
                  font-family: "Gilroy-Bold";
                  color: #ffffff;
                  font-size: 17px;
                  line-height: 26px;
                  margin-bottom: 5px;

                  @media only screen and (max-width: 767px) {
                    font-size: 16px;
                  }
                }
              }
            }

            .rules-btn {
              flex: 0 0 20%;
              max-width: 20%;
              display: flex;
              justify-content: flex-end;

              @media only screen and (max-width: 1200px) {
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
              }

              @media only screen and (max-width: 767px) {
                justify-content: center;
              }

              .readBtn {
                cursor: pointer;
                max-width: 253px;
                width: 100%;
                height: 56px;
                color: #fff;
                background-color: #e52420;
                background-image: linear-gradient(to right,
                    #6d1513,
                    #e52420,
                    #6d1513);
                border-radius: 10px;
                border: 2px solid #ffffff;
                font-family: "Poppins-SemiBold";
                font-size: 16px;
                line-height: 16px;
                text-transform: uppercase;
                letter-spacing: 0.04em;
                text-decoration: none;
                display: flex;
                align-items: center;
                justify-content: center;

                &::after {
                  content: url("../images/arrow.png");
                  padding-left: 10px;
                }

                &:hover {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }
  }

  .search_sec {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .upload-sec {
      max-width: 100%;
      width: 100%;
      height: 46px;
      color: #fff;
      background-color: #1d1d1d;
      border-radius: 10px;
      border: 2px solid #262627;
      font-family: "Gilroy-SemiBold";
      font-size: 17px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      gap: 10px;

      &.up {
        &::before {
          content: url("../images/upload_icon.png");
          padding-left: 10px;
        }
      }

      &.mesg {
        &::before {
          content: url("../images/mesg_icon.png");
          padding-left: 10px;
        }
      }

      input {
        background: none;
        border: 0;
        color: #fff;
        font-family: "Gilroy-SemiBold";
        font-size: 17px;
        line-height: 17px;
      }
    }

    .aff_top_inner {
      background: #181818;
      border: 2px solid #2d2d2d;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 30px;
      margin-top: 70px;
      display: flex;
      flex-direction: column;

      .aff_copy_save {
        display: block;

        @media only screen and (min-width: 768px) {
          display: flex;
          flex-wrap: wrap;
        }

        .aff_clip_wrap {
          flex: 0 0 80%;
          max-width: 80%;
          background: #000 !important;
          border: 2px solid #2d2d2d;
          border-radius: 15px;
          height: 56px;
          width: 100%;
          display: flex;
          align-items: center;

          @media only screen and (max-width: 767px) {
            max-width: 100%;
          }

          input {
            background: none;
            border: none;
            box-shadow: none;
            color: #fff !important;
            font-family: "Gilroy-Bold";
            font-size: 17px;
            line-height: 19px;
          }

          .cpy-img {
            padding: 0 20px;

            img {
              cursor: pointer;
            }
          }
        }

        .aff_copysave {
          flex: 0 0 18%;
          max-width: 18%;
          margin-left: 2%;
          height: 56px;
          padding: 0px;

          &::after {
            display: none;
          }

          @media only screen and (max-width: 767px) {
            max-width: 100%;
            padding: 10px 20px;
            margin-top: 10px;
            margin-left: 0;
          }
        }
      }

      label {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 20px;
        line-height: 32px;
      }

      .aff_bold {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 50px;
        line-height: 55px;
      }

      p {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 16px;
        line-height: 28px;
        opacity: 0.5;
      }

      select {
        background-color: #050505;
        border-radius: 10px;
        border-color: #050505;
        color: #fff;
        min-width: 150px;
        padding: 10px 15px;
        width: 100%;
        cursor: pointer;
      }

      .successMsg {
        color: #40ab00;
        font-family: "Gilroy-Bold";
        font-size: 17px;
        line-height: 19px;
        padding: 10px 0;
        text-align: left;
      }

      .errorMsg {
        color: red;
        font-family: "Gilroy-Bold";
        font-size: 17px;
        line-height: 19px;
        padding: 10px 0;
        text-align: left;
      }
    }

    .mf-head-text {
      color: #fff;
      font-family: "Gilroy-Bold";
      font-size: 26px;
      line-height: 30.47px;

      &.mf-head-text-invites {
        font-size: 34px;
        line-height: 40px;
      }
    }

    .mf-tag {
      border: 2px solid #343434;
      margin-top: 20px;
    }

    .mf-searchbar {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 10px;

      .mf-searchbar-form-wrap {
        flex: 0 0 75%;
        max-width: 75%;
        margin-top: 10px;

        @media only screen and (max-width: 991px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .mf-searchbar-form-inner {
          background: #000 !important;
          border: 2px solid #2d2d2d;
          height: 56px;
          border-radius: 10px !important;
          display: flex;
          padding: 10px;
          position: relative;

          .mf-searchbar-img {
            padding: 5px;
          }

          .mf-searchbar-form {
            background: #000 !important;
            border: none;
            box-shadow: none;
            outline: none;
            border-radius: 10px !important;
            height: 100%;
            //color: rgba(235, 235, 245, 0.6);
            color: #fff;
            font-family: "Gilroy-SemiBold";

            &::-webkit-input-placeholder {
              color: #fff;
            }
          }

          .mf-close-img {
            padding: 8px;
            cursor: pointer;
          }

          .my-search-list {
            padding: 10px;
            position: absolute;
            top: 56px;
            left: 0px;
            background: #000;
            width: 100%;
            border: solid 1px;
            border-radius: 10px !important;
            z-index: 99999;

            .my-search-list-items-wrap {
              height: 300px;
              overflow-y: scroll;

              &::-webkit-scrollbar {
                width: 3px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #181818;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #e52420;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #e52420;
              }

              .my-search-list-items {
                display: flex;
                align-items: center;
                gap: 15px;
                padding: 10px;
                cursor: pointer;

                .my-search-list-img {
                  img {
                    border-radius: 25px;
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                  }
                }

                .my-search-list-name {
                  color: #fff;
                  font-family: "Gilroy-Bold";
                  font-size: 16px;
                }
              }

              .nousermessage {
                color: #fff;
                /* text-align: center; */
                padding: 10px;
              }
            }
          }
        }

        .note-text {
          font-family: "Gilroy-SemiBold";
          font-size: 14px;
          line-height: 16px;
          color: #ebebf599;
        }
      }

      .mf-searchbar-btn {
        flex: 0 0 20%;
        max-width: 20%;

        &::after {
          display: none;
        }
      }
    }

    .left_search {
      width: 68%;

      @media only screen and (max-width: 991px) {
        width: 60%;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .right_search {
      width: 30%;

      @media only screen and (max-width: 991px) {
        width: 39%;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .profile-info {
      color: #ffffff;
      font-family: "Gilroy-SemiBold";
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      margin-top: 20px;

      img {
        height: 50px;
        object-fit: cover;
        width: 50px;

        &.prof-circle {
          margin-right: 15px;
          border: 2px solid #fff;
          border-radius: 100%;
          box-sizing: border-box;
        }

        &.prof-flag {
          margin-left: 30px;
        }
      }

      .playerInfo-inner {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.balance-tour-box {
  flex-direction: column;
  justify-content: space-around;
  padding: 20px !important;
  .balance-box_item{
    border: solid 1px;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    &:hover,&.active {
        border: 2px solid #52dd21; 
        background-color: rgba(82, 221, 33, 0.05);
    }
    img {
        width: 35px !important;
    }
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
  }
}


.condition-btn{
  display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 50px auto;
  width: 100%;
    @media only screen and (max-width: 575px) {
      gap: 10px;
    }
  
    .bottomBtn {
      max-width: 124px;
      width: 100%;
      height: 49px;
      color: #fff;
      border: 2px solid #fff;
      border-radius: 9px;
      font-family: "Gilroy-Bold";
      font-size: 16px;
      line-height: 16px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 18px 25px;
  
      @media only screen and (max-width: 400px) {
        max-width: 115px;
      }
  
      cursor: pointer;
  
      &:hover {
        opacity: 0.5;
      }
  
      &.nextBtnLoader {
        max-width: 260px;
      }
    }
.backBtn {
    background-color: #e52420;
    background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);

    &::before {
      content: url("../images/tournament/back_arrow.png");
      padding-right: 10px;
    }
  }

  .nextBtn {
    background-image: linear-gradient(to right, #175900, #52dd21, #175900);

    &::after {
      content: url("../images/arrow.png");
      padding-left: 10px;
    }
  }
}
 
.gbt-list-wrap{
    // display: flex;
    //   gap: 20px;
    //   flex-wrap: wrap;
      margin: 20px 0;
  .gbt-list-block{
    // flex: 0 0 48%;
    // max-width: 48%;
    // @media only screen and (max-width: 767px) {
    //     flex: 0 0 100%;
    //       max-width: 100%;
    //   }
    padding: 20px;
    .active-tour-game {
        background-color: #161515;
        border: 2px solid #5b5b5b;
          margin: 0;
        padding: 20px;
    
        border-radius: 8px;
        display: flex;
            align-items: center;
              gap: 15px;
        .active-tour-image {
            flex: 0 0 22%;
            max-width: 22%;
                display: flex;
                  align-items: center;
                  justify-content: center;
          }
        .active-tour-content {
          color: #fff;
          padding: 20px 0;
          flex: 0 0 78%;
          max-width: 78%;
          .active-tour-title {
            font-family: "Gilroy-Bold";
            font-size: 26px;
            padding-bottom: 30px;
                color: #52dd21;
          }
          .active-tour-details{
            padding-bottom: 30px;
            display: flex;
            gap: 10px;
            .active-tour-details-inner {
                font-family: "Gilroy-Bold";
                font-size: 18px;
                line-height: 36px;
                display: flex;
                  gap: 10px;
                      margin-bottom: 10px;
                      .gbt_cal_icon {
                          display: block;
                          padding: 0 10px;
                          svg{
                            fill: #fff;
                              font-size: 28px;
                          }
                        }
              }
            .active-tour-details-left {
                flex: 0 0 48%;
                max-width: 48%;
              }
                            .active-tour-details-right {
                              flex: 0 0 48%;
                              max-width: 48%;
                            }
          }
          .active-tour-button{
              button {
                  background-color: #e52420;
                    background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
                      border: 2px solid #fff !important;
                  color: #fff;
                  border: none;
                  padding: 15px 30px;
                  border-radius: 8px;
                  font-family: "Gilroy-Bold";
                  font-size: 18px;
                  text-transform: uppercase;
              &:hover {
                  background-image: linear-gradient(to right, #175900, #52dd21, #175900);
                }
                  &::after {
                    content: url("../images/arrow.png");
                    padding-left: 10px;
                  }
                }
          }

                   
        }
    
      }
  }
}

.gbt-prize-details{
  justify-content: start!important;
  gap: 30px !important;
}

.playerInfoScroll{
  height: 300px;
    overflow-y: scroll;
  
    &::-webkit-scrollbar {
      width: 3px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #181818;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e52420;
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #e52420;
    }
}
.gbt_filter {
      display: flex;
        gap: 20px;
        margin: 20px 0 0;
        padding: 10px 20px 0;
  .gbt_filter_select {
      cursor: pointer;
      flex: 0 0 30%;
        max-width: 30%;
        select{
              padding: 10px;
        }
    }
  .gbt_filter_search {
      width: 100%;
    }
}
.tournament_landing_top {
  padding-bottom: 20px;
}

 .saveTMBtn {
   cursor: pointer;
   max-width: 253px;
   width: 100%;
   height: 56px;
   color: #fff;
   background-color: #e52420;
   background-image: linear-gradient(to right,
       #6d1513,
       #e52420,
       #6d1513);
   border-radius: 10px;
   border: 2px solid #ffffff;
   font-family: "Poppins-SemiBold";
   font-size: 16px;
   line-height: 16px;
   text-transform: uppercase;
   letter-spacing: 0.04em;
   text-decoration: none;
   display: flex;
   align-items: center;
   justify-content: center;

   &::after {
     content: url("../images/arrow.png");
     padding-left: 10px;
   }

   &:hover {
     opacity: 0.5;
   }
 }
 .gbt_seltab{
      width: 100%;
        justify-content: space-between;
        padding: 15px 0;
 }
 .gbt_ms_wrap {
   margin-top: 60px;
  .season_Scoreboard{
    padding: 0 !important;
  }
 }
.tournament-banner-gbt{
  flex-direction: column;
      position: relative;
      .tournament-banner-floating-top{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 10px 20px;
        @media only screen and (max-width: 767px) {
          justify-content: center;
          flex-direction: column;
        }
        @media only screen and (min-width: 768px) {
          position: absolute;
          top: 0;
        }
      }
    .green_join {
        font-family: "Gilroy-Bold";
        font-size: 17px;
        line-height: 17px;
        letter-spacing: 0.04em;
        text-align: center;
        background-color: #52dd21;
        padding: 10px 15px;
        // max-width: 248px;
        border-radius: 10px;
        @media only screen and (max-width: 991px) {
          font-size: 14px;
          padding: 6px;
        }
      }
            .gbt-not-approved-text {
              font-family: "Gilroy-Bold";
                padding: 20px 5px;
                font-size: 22px;
                line-height: 32px;
                color: #ffc01f;
            }
}

.tour-main-logo{
  img{
    width: 100px;
    height: 100px;
    background-color: #2d2d2d;
    border: 5px solid #fff;
    border-radius: 100%;
    object-fit: contain;
    object-position: center;
    margin-bottom: 25px;
  }
}
._gbt_time_end{
      border: solid 1px #c80000;
        padding: 6px 20px;
        ._gbt_date{
          color: #ffc52e;
        }
}
.GB_ref_gbt{
  img{
    cursor: pointer;
  }
}
.tourfinished-text {
  font-family: "Gilroy-Bold";
  color: #ffffff;
  font-size: 30px;
  line-height: 52px;
  margin: 30px 0;
}
.no-tour-list {
  font-family: "Gilroy-Bold";
  font-size: 18px;
  line-height: 36px;
  color: #fff;
  text-align: center;
  a{
    color: #C80000;
      text-decoration: none;
  }
}
.text-center-adtd{
  text-align: center !important;
}
span.createorName {
  span {
      color: gold;
      font-family: "Gilroy-Bold";
      font-size: 18px;
      line-height: 25px;
    }
}
._gbt_start_time{
  position: relative;
}

.creator_name {
  flex: 0 0 100%;
  max-width: 100%;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-family: "Gilroy-Bold";
  padding: 10px;
  span{
    color: #ffc01f;
  }
}
.noSpotBtn{
  max-width: 225px !important;
}
.tourRank{
  .rankBox{
    align-items: center;
      border-radius: 10px;
      display: flex;
      font-family: Gilroy-Bold;
      font-size: 18px;
      height: 32px;
      justify-content: center;
      line-height: 100%;
      text-align: center;
      width: 32px;
    img{
          object-fit: none;
    }
    &.goldBox {
      background: #fda829;
    }
    &.silverBox {
      background: #444;
    }
    &.bronzeBox {
      background: #d99246;
    }
  }
}

.adminTourFieldWrap{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .adminTourField {
    flex: 0 0 48%;
    max-width: 48%;
    .search-admin-form-control{
      width: 100% !important;
        padding: 10px 20px;
    }
    // &.adminTourFieldFull {
    //   flex: 0 0 100% !important;
    //   max-width: 100% !important;
    // }
     .gbt_form_field_date {
      margin-top: 0 !important;
    }
    .start_date_field{
      height: 56px !important;
    }
  }
}
 .admin_change_game_sec {
   width: 100%;
   justify-content: flex-start !important;
 }

 .tour-live-sec {
      background: #181818;
        border: 1px solid #52dd21;
        border-radius: 7px;
        color: #52dd21;
        width: 85px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
  img {
      margin-right: 5px;
      animation: blink 2s linear infinite;
    }
 }

 .adt-matches-list {
    padding: 10px;
    .adt-matches-items {
      margin-bottom: 2px;
      background: #000;
      padding: 6px 15px;
    
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      justify-content: space-between;
      .adt-matches-items-th {
        color: #fdbf1f;
        font-family: "Poppins-SemiBold";
        text-align: left;
      }
      .adt-matches-items-td {
        color: #fff;
            font-family: "Gilroy-Medium";
            text-align: left;
      }
      .adt-matches-items-1 {
        flex: 0 0 5%;
        max-width: 5%;
      }
      .adt-matches-items-2 {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .adt-matches-items-5 {
        flex: 0 0 15%;
        max-width: 15%;
      }
      .adt-matches-items-3 {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .adt-matches-items-4 {
        flex: 0 0 15%;
        max-width: 15%;
      }
      &.counted_match {
        background: #212529;
      }
    }
    .adt-matches-no-items {
      color: #fff;
      font-size: 18px;
      font-family: "Poppins-SemiBold";
    }
 }

 .rules-btn {
    display: flex;
      justify-content: center;
    //margin-top: 50px;
   @media only screen and (max-width: 1200px) {
     flex: 0 0 100%;
     max-width: 100%;
     width: 100%;
   }

   @media only screen and (max-width: 767px) {
     justify-content: center;
        margin-top: 20px;
   }

   .readBtn {
     cursor: pointer;
     max-width: 253px;
     width: 100%;
     height: 56px;
     color: #fff;
     background-color: #e52420;
     background-image: linear-gradient(to right,
         #6d1513,
         #e52420,
         #6d1513);
     border-radius: 10px;
     border: 2px solid #ffffff;
     font-family: "Poppins-SemiBold";
     font-size: 16px;
     line-height: 16px;
     text-transform: uppercase;
     letter-spacing: 0.04em;
     text-decoration: none;
     display: flex;
     align-items: center;
     justify-content: center;

     &::after {
       content: url("../images/arrow.png");
       padding-left: 10px;
     }

     &:hover {
       opacity: 0.5;
     }
   }
 }

.tournament_creator{
  .title {
      font-family: "Gilroy-Bold";
      font-size: 30px;
      line-height: 35px;
      color: #ffffff;
      padding-bottom: 10px;
      margin-top: 30px;
    }
}
    .blockTour {
      background: #e52420;
      padding: 3px 15px;
      border-radius: 10px;
      margin-left: 8px;
    }

    ._each_lb_left_tour{
    flex: 0 0 80% !important;
      max-width: 80% !important;
    }
    ._each_lb_right_tour {
    flex: 0 0 20%!important;
      max-width: 20%!important;
    }

    .finTname{
          display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;
            .blockTour {
                margin-left: 0;
              }
    }
        .admt-err {
          border: 2px solid red !important;
        }

      .adminTourField {
        .logo_upload {
            background: #000 !important;
            border: 2px solid #2d2d2d;
            border-radius: 15px;
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 20px 15px;
            //height: 56px;
            .has_img_tl{
                  width: 100px;
                    height: 100px;
                    border-radius: 100%;
            }
          }
      }


  .countdown-Box-Tour{
    @media only screen and (max-width: 1200px) {
      height: 150px !important;
    }
    .countdown-digit{
      @media only screen and (max-width: 1366px) {
        font-size: 55px !important;
        line-height: 60px !important;
      }
    }
  }
  .tournament-banner-gbt{
    height: 900px;
  }

    .tz-ttxt {
      font-family: "Gilroy-Bold";
      padding: 8px;
      display: block;
      .gold-ttxt {
          color: #ffc52e;
        }
    }

    .nofolloowers{
          color: #ffffff;
            font-family: "Gilroy-Bold";
            font-size: 18px;
            padding: 20px 5px;
            display: block;
            cursor: pointer;
    }


        .ht-flex {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .ht-head {
            color: #fff;
              font-size: 22px;
              font-family: "Gilroy-Bold";
            }
                        .ht-count {
                          font-family: "Gilroy-Bold";
                          color: #ffc52e;
                          font-size: 20px;
                        }
        }


                ._gbt_diff_time {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start !important;
                  padding: 10px 0;
                  gap: 15px;
                      font-family: "Gilroy-Bold";
                        color: #fff;
                }
        
                ._gbt_diff_time_inner .inner-span {
                  color: #ffc52e;
                }
        
                ._GB_Tournaments .tournament-banner .countdown-sec .countdown-Box {
        
                  @media only screen and (min-width: 1200px) {
                    height: 150px !important;
                  }
                }

.adm-fixed-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;

  .adm-fixed-loop {
    flex: 0 0 30%;
    max-width: 30%;

    label {
      margin-bottom: 10px;
    }
  }
}
.adm-fixed-wrap-column {
  flex-direction: column;
  align-items: flex-start !important;
}
.tourInfo {
  svg {
    font-size: 27px !important;
    cursor: pointer;

    path {
      fill: #ffc01f !important;
    }
  }
}

.tourInfo {
  position: relative;
  top: 10px;

  @media only screen and (min-width: 1366px) {
    position: absolute;
    top: 25px;
    right: 25px;
  }
  &.pull-down{
    @media only screen and (min-width: 1366px) {
      top: 55px;
    }
  }
}

.heading-bar {
  svg {
    font-size: 30px !important;
    cursor: pointer;

    path {
      fill: #ffc01f !important;
    }
  }
}

.info-popu-txt {
  font-family: "Gilroy-Bold";
  color: #ffffff;
  font-size: 23px;
  text-align: center;
  padding: 30px 50px 50px;
}


@media only screen and (min-width: 1366px) {
  .parti-match-modal {
    max-width: 50% !important;
  }
}

// Claims- reward css
.claim_rewards{
.claims_btn {
    // max-width: 232px;
    color: #fff;
    background-color: #e52420;
    background-image: linear-gradient(to right,
        #6d1513,
        #e52420,
        #6d1513);
    border-radius: 10px;
    border: 2px solid #ffffff;
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    line-height: 17px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding: 10px 15px;
    text-decoration: none;
    margin-left: 15px;

    @media only screen and (max-width: 991px) {
      font-size: 10px;
      padding: 5px 6px;
      margin-left: 0px;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      font-size: 11px;
      padding: 10px 12px;
    }

    &::after {
      content: url("../images/arrow.png");
      padding-left: 10px;

      @media only screen and (max-width: 991px) {
        position: relative;
        top: 2px;
      }
    }

    &:hover {
      opacity: 0.6;
    }

    &.four_success {
      background-color: #000000;
      background-image: none;
      border: none;

      &:hover {
        opacity: 0.6;
      }

      @media only screen and (min-width: 992px) and (max-width: 1919px) {
        font-size: 11px !important;
        padding: 6px 9px !important;
      }
    }

    @media only screen and (min-width: 992px) and (max-width: 1260px) {
      font-size: 11px;
      padding: 11px 8px;
    }

    @media only screen and (min-width: 1301px) and (max-width: 1400px) {
      font-size: 11px;
      padding: 9px 12px;
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.claims_remaining_time {
  font-family: "Poppins-SemiBold";
}
.transaction_claim_timer{
  .claims_remaining_time {
    background: #640000;
    border: 2px solid #c80000;
    border-radius: 10px;
    margin: 20px 0;
    padding: 10px 20px;
    display: inline-block;
    color: #fff;
  }
}


.trheadFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.showtourmatchcount {
  font-family: "Gilroy-Bold";
  font-size: 16px;
  line-height: 19px;
  color: rgba(235, 235, 245, 0.6);
}
.heroImageName{
  cursor: pointer;
      text-transform: capitalize;
}
.heroImageDota {
  &.heroImage{
    width: 150px;
    // height: 50px;
    // border-radius: 100%;
    // border: 2px solid #2d2d2d;
    object-fit: cover;
  }
  &.defaultImage{
    width: 35px;
    height: 35px;
  }
}
.GB_device_hero {
  text-align: left !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.match-gamertag {
  color: #fff;
  font-family: "Poppins-SemiBold";
  font-size: 24px;
  margin-bottom: 10px;
}

.tour_land_end_date {
  color: #fff;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.claim_rewards {
  display: flex;
  align-items: center;
  gap: 10px;
  .claimInfosvg {
    cursor: pointer;
      svg {
        font-size: 24px;
        path {
          fill: #ffc01f !important;
        }
      }
    }
}
