/********************** Explore Start ************************/
.GB_explore_wrap {
  background-color: #000000;

  .GB_explore {
    background-color: #000000;

    .GB_explore_inner {
      padding-right: 0;
      display: flex;
      padding-top: 34px;

      @media only screen and (min-width: 1366px) and (max-width: 1919px) {
        padding-top: 9px;
      }
    }

    .column-right {
      @media only screen and (max-width: 575px) {
        width: 100%;
        margin: auto;
      }

      .bet-topSec {
        padding: 25px;

        ._title {
          font-family: "Gilroy-Bold";
          font-size: 45px;
          line-height: 52px;
          color: #fff;
          margin-bottom: 10px;

          @media only screen and (max-width: 1024px) {
            font-size: 38px;
            line-height: 45px;
          }

          @media only screen and (max-width: 767px) {
            font-size: 30px;
            line-height: 35px;
          }

          @media only screen and(max-width: 1200px) {
            text-align: center;
          }
        }

        .sub_title {
          font-family: "Gilroy-Bold";
          font-size: 18px;
          line-height: 18px;
          color: #fff;
          margin-bottom: 25px;

          @media only screen and(max-width: 1200px) {
            text-align: center;
          }
        }

        .gamelist_sec {
          display: flex;
          align-items: center;
          gap: 25px;
          // justify-content: space-evenly;
          flex-wrap: wrap;
          border-bottom: 1px solid #2d2d2d;
          padding-bottom: 30px;

          @media only screen and (max-width: 767px) {
            padding-bottom: 20px;
          }

          @media only screen and (max-width: 1920px) {
            justify-content: space-evenly;
          }

          .game_list {
            max-width: 316px;
            width: 100%;
            height: 80px;
            margin-bottom: 25px;

            @media only screen and (min-width: 1920px) {
              max-width: 19%;
            }

            .livelist_box {
              display: flex;
              align-items: center;
              background-color: #181818;
              border: 2px solid #2d2d2d;
              border-radius: 20px;
              padding: 10px;

              @media only screen and (min-width: 1920px) {
                justify-content: space-between;
              }

              .game_image {
                margin-right: 10px;
              }

              .game_name {
                font-family: "Gilroy-Bold";
                font-size: 15px;
                line-height: 15px;
                color: #fff;
                width: 180px;
                cursor: pointer;

                .viewers {
                  font-family: "Gilroy-Medium";
                  font-size: 15px;
                  line-height: 15px;
                  color: rgba(235, 235, 245, 0.6);
                  margin-top: 5px;
                }
              }

              .live_menu {
                text-align: right;

                .game_menu {
                  .dropdown-menu {
                    transform: translate(-120px, 44px) !important;

                    .dropdown-item {
                      padding-left: 8px;
                    }
                  }

                  .dropdown-toggle {
                    background-color: #181818;
                    border-color: #181818;
                    padding-right: 0px;

                    &::after {
                      content: url("../images/betting/menu_icon.png") !important;
                      background-color: #181818 !important;
                      border-top: none !important;
                    }

                    &:focus {
                      box-shadow: none !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/********************** Explore End ************************/

/********************** PopularLiveMatches Start ************************/
.GB_PopularLiveMatches {
  padding: 0 25px;

  @media only screen and (max-width: 575px) {
    padding: 15px 25px;
  }

  .title_Sec {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      text-align: center;
    }

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: 20px;
    }

    ._title {
      font-size: 40px;
      line-height: 46px;
      font-family: "Gilroy-Bold";
      color: #fff;
      margin-bottom: 5px;

      @media only screen and (max-width: 767px) {
        font-size: 25px;
        line-height: 30px;
      }
    }

    .sub_title {
      font-size: 18px;
      line-height: 18px;
      color: rgba(235, 235, 245, 0.6);
      font-family: "Gilroy-Medium";
      margin-bottom: 10px;

      @media only screen and (max-width: 767px) {
        font-size: 16px;
        line-height: 18px;
      }
    }

    .title-right {
      .sortby_btn {
        .dropdown-toggle {
          background: #181818;
          border: 2px solid #2d2d2d;
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 16px;
          width: 160px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .dropdown-menu {
          .dropdown-item {
            padding-left: 15px;
          }
        }
      }
    }
  }

  .liveStream_Sec {
    // display: flex;
    // justify-content: space-between;
    //border-bottom: 1px solid #2d2d2d;
    padding-bottom: 20px;

    // @media only screen and (max-width: 1200px) {
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    // }

    .liveStream-left {
      // flex: 0 0 34%;
      // max-width: 34%;

      // @media only screen and (max-width: 1200px) {
      //   flex: 0 0 100%;
      //   max-width: 100%;
      //   margin-bottom: 15px;
      //   width: 100%;
      // }

      a {
        text-decoration: none;
      }

      .liveStream-game {
        position: relative;

        .stream-mainlmg {
          width: 100%;
        }

        .game-details {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #181818;
          border-radius: 0px 0px 5px 5px;
          padding: 15px 20px;

          .gameDetails_left {
            a {
              text-decoration: none;
            }

            .streamer-name {
              font-size: 20px;
              line-height: 20px;
              font-family: "Gilroy-Bold";
              color: #fff;
              margin-bottom: 5px;
              text-decoration: none;

              @media only screen and (max-width: 767px) {
                font-size: 18px;
                line-height: 20px;
              }

              @media only screen and (max-width: 575px) {
                font-size: 15px;
                line-height: 16px;
              }
            }

            .game-name {
              font-size: 15px;
              line-height: 15px;
              font-family: "Gilroy-Medium";
              color: rgba(235, 235, 245, 0.6);
              display: flex;
              align-items: center;
              margin-bottom: 5px;
            }

            .live_viewlist {
              display: flex;
              align-items: center;

              .country-flag {
                display: flex;
                align-items: center;

                img {
                  margin-right: 10px;
                  width: 32px;
                  height: 32px;
                }
              }

              .viewlist-details {
                font-size: 14px;
                line-height: 14px;
                font-family: "Gilroy-Medium";
                color: rgba(235, 235, 245, 0.6);
                display: flex;
                align-items: center;
                cursor: pointer;

                img {
                  margin-right: 10px;
                }
              }
            }
          }

          .gameDetails_middle {
            .join_btn {
              background-color: red;
              border: 1px solid red;
            }
          }

          .gameDetails_right {
            text-align: right;

            img {
              margin-right: 5px;
              width: 18px;
              height: auto;
              object-fit: contain;
            }

            .game_menu {
              margin-bottom: 5px;

              .dropdown-menu {
                transform: translate(-110px, 35px) !important;

                .dropdown-item {
                  padding-left: 8px;
                }
              }

              .dropdown-toggle {
                background-color: #181818;
                border-color: #181818;
                padding: 0;

                &::after {
                  content: url("../images/betting/menu_icon.png") !important;
                  background-color: #181818 !important;
                  border-top: none !important;
                }

                &:focus {
                  box-shadow: none !important;
                }
              }
            }

            color: #ffc01f;
            font-size: 14px;
            line-height: 21px;
            font-family: "Poppins-SemiBold";
          }
        }
      }

      .gamelogo {
        position: absolute;
        top: 0px;
        right: 0px;
      }

      .people_watching {
        position: absolute;
        bottom: 116px;
        left: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        font-family: "Gilroy-Bold";
        cursor: pointer;

        // @media only screen and (min-width: 1200px) and (max-width: 1700px){
        //   bottom: 120px;
        // }
        // @media only screen and (min-width: 343px) and ( max-width: 575px){
        //   bottom: 120px;
        // }
        @media only screen and (min-width: 320px) and (max-width: 551px) {
          bottom: 119px;
        }
      }
    }

    .liveStream-right {
      // flex: 0 0 100%;
      // max-width: 100%;

      // @media only screen and (min-width: 1200px) {
      //   flex: 0 0 65%;
      //   max-width: 65%;
      // }

      .carousel-inner {
        padding: 0 55px !important;
      }

      .carousel-control-prev,
      .carousel-control-next {
        width: 2% !important;
      }

      .carousel-indicators {
        margin-bottom: -28px !important;
      }

      .liveStream_Box {
        // display: flex;
        // flex-wrap: wrap;
        // gap: 18px;
        // width: 100%;
        // justify-content: flex-start;

        a {
          text-decoration: none;
        }

        @media only screen and (max-width: 1600px) {
          gap: 14px;
        }

        @media only screen and (max-width: 1600px) {
          gap: 10px;
        }

        @media only screen and (max-width: 1200px) {
          gap: 15px;
        }

        @media only screen and (max-width: 374px) {
          gap: 10px;
        }

        .liveStream-game {
          flex: 0 0 32%;
          // max-width: 243px;
          position: relative;

          .stream-subimg {
            width: 100%;
          }

          @media only screen and (max-width: 767px) {
            flex: 0 0 48%;
          }

          @media only screen and (max-width: 575px) {
            flex: 0 0 100%;
          }

          @media only screen and (min-width: 1600px) {
            flex: 0 0 23%;
          }

          .game-details {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            background-color: #181818;
            width: 100%;
            border-radius: 0px 0px 5px 5px;
            padding: 15px 10px;

            .gameDetails_left {
              flex: 0 0 75%;
              max-width: 75%;

              a {
                text-decoration: none;
              }

              .streamer-name {
                font-size: 15px;
                line-height: 16px;
                font-family: "Gilroy-Bold";
                color: #fff;
                margin-bottom: 5px;
                text-decoration: none;
                width: 167px;
                height: 32px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .live_viewlist {
                display: flex;
                align-items: center;

                .country-flag {
                  display: flex;
                  align-items: center;

                  img {
                    margin-right: 10px;
                  }
                }

                .viewlist-details {
                  font-size: 14px;
                  line-height: 14px;
                  font-family: "Gilroy-Medium";
                  color: rgba(235, 235, 245, 0.6);
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  img {
                    margin-right: 10px;
                  }
                }

                .join_bet {
                  .join_btn {
                    background-color: red;
                    border: 1px solid red;
                    font-size: 12px;
                    width: 70px;
                    margin-left: 10px;
                  }
                }
              }
            }

            .gameDetails_right {
              text-align: right;
              flex: 0 0 25%;
              max-width: 25%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              .game_menu {
                margin-bottom: 5px;

                .dropdown-menu {
                  transform: translate(-105px, 44px) !important;

                  .dropdown-item {
                    padding-left: 8px;
                  }
                }

                .dropdown-toggle {
                  background-color: #181818;
                  border-color: #181818;
                  padding: 0;

                  &::after {
                    content: url("../images/betting/menu_icon.png") !important;
                    background-color: #181818 !important;
                    border-top: none !important;
                  }

                  &:focus {
                    box-shadow: none !important;
                  }
                }
              }

              .earned_coin {
                color: #ffc01f;
                font-size: 14px;
                line-height: 21px;
                font-family: "Poppins-SemiBold";
                display: flex;
                justify-content: center;

                img {
                  margin-right: 5px;
                }
              }
            }
          }

          .game-details-new {
            background-color: #181818;
            width: 100%;
            border-radius: 0px 0px 5px 5px;
            padding: 15px 10px;
            min-height: 100px;

            .gameDetails_first {
              .streamer-name {
                font-size: 15px;
                line-height: 16px;
                font-family: "Gilroy-Bold";
                color: #fff;
                margin-bottom: 5px;
                text-decoration: none;
                width: 167px;
                height: 32px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .game-name {
                font-size: 15px;
                line-height: 15px;
                font-family: "Gilroy-Medium";
                color: rgba(235, 235, 245, 0.6);
                display: flex;
                align-items: center;
                margin-top: 3px;
              }
            }

            .gameDetails_second {
              text-align: right;
              display: flex;
              color: #ffc01f;
              font-size: 14px;
              line-height: 21px;
              font-family: "Poppins-SemiBold";

              .live_viewlist {
                display: flex;
                align-items: center;
                flex: 0 0 50%;
                max-width: 50%;

                .country-flag {
                  display: flex;
                  align-items: center;

                  img {
                    margin-right: 10px;
                  }
                }

                .viewlist-details {
                  font-size: 14px;
                  line-height: 14px;
                  font-family: "Gilroy-Medium";
                  color: rgba(235, 235, 245, 0.6);
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  img {
                    margin-right: 10px;
                  }
                }

                .join_bet {
                  .join_btn {
                    background-color: red;
                    border: 1px solid red;
                    font-size: 12px;
                    width: 70px;
                    margin-left: 10px;
                  }
                }
              }

              .earned_coin {
                flex: 0 0 50%;
                max-width: 505%;
                color: #ffc01f;
                font-size: 14px;
                line-height: 21px;
                font-family: "Poppins-SemiBold";
                display: flex;
                justify-content: end;
                align-items: center;

                img {
                  margin-right: 5px;
                  width: 20px;
                  height: 20px;
                }
              }

              // img {
              //   margin-right: 5px;
              // }

              // .game_menu {
              //   margin-bottom: 5px;

              //   .dropdown-menu {
              //     transform: translate(-110px, 35px) !important;

              //     .dropdown-item {
              //       padding-left: 8px;
              //     }
              //   }

              //   .dropdown-toggle {
              //     background-color: #181818;
              //     border-color: #181818;
              //     padding: 0;

              //     &::after {
              //       content: url("../images/betting/menu_icon.png") !important;
              //       background-color: #181818 !important;
              //       border-top: none !important;
              //     }

              //     &:focus {
              //       box-shadow: none !important;
              //     }
              //   }
              // }
            }
          }
        }
      }

      .gamelogo {
        position: absolute;
        top: 0px;
        right: 0px;
      }

      .people_watching {
        position: absolute;
        top: 105px;
        left: 10px;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 16px;
        font-family: "Gilroy-Bold";
        cursor: pointer;
        // @media only screen and (min-width: 1200px) and (max-width: 1700px){
        //   bottom: 105px;
        // }
        // @media only screen and (min-width: 768px) and (max-width: 798px){
        //   bottom: 105px;
        // }
      }

      .live-stream {
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }
  }

  .no_data {
    h1 {
      color: rgba(235, 235, 245, 0.6);
      font-family: "Gilroy-Bold";
      font-size: 35px;
      text-align: center;
    }
  }
}

#viewlist-popup {
  background: #181818 !important;
  height: 48px;
  width: 152px;
  border: 2px solid #484848;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gilroy-Medium";

  .popover-arrow {
    &::after {
      content: url("../images/arrow-black.png");
      border-top: none;
      top: -1px;
      left: -8px;
    }
  }

  .popover-body {
    color: rgba(235, 235, 245, 0.6);
  }
}

#totalview-popup {
  background: #181818 !important;
  height: 40px;
  width: 112px;
  border: 2px solid #484848;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gilroy-Medium";

  .popover-arrow {
    &::after {
      content: url("../images/arrow-black.png");
      border-top: none;
      top: -1px;
      left: -12px;
    }
  }

  .popover-body {
    color: rgba(235, 235, 245, 0.6);
  }
}

/********************** PopularLiveMatches End ************************/

/********************** BettingScoredboard start ************************/
.GB_BettingScoreboard {
  padding: 25px;
  background-color: #000000;

  .GB_BettingScoreboardtable {
    border-bottom: 1px solid #2d2d2d;
    padding-bottom: 35px;

    ._title {
      display: flex;
      justify-content: space-between;
      padding: 20px 0px;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
        padding: 0px 0px 20px 0px;
      }

      h2 {
        font-size: 40px;
        line-height: 46px;
        font-family: "Gilroy-Bold";
        color: #fff;

        @media only screen and (max-width: 767px) {
          font-size: 28px;
          line-height: 26px;
        }
      }

      .title-left {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
        }
      }

      .title-right {
        .gamechange {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 16px;

          .games-box {
            height: 40px;
            width: 40px;
            border: 2px solid #2d2d2d;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.gamesbox_active {
              border-color: #e52420;
              background: rgba(200, 0, 0, 0.5);
            }

            &:hover {
              border-color: #e52420;
              background: rgba(200, 0, 0, 0.5);
            }
          }

          .all-btn {
            width: 80px;
            height: 50px;
            border: 2px solid #484848;
            border-radius: 8px;
            cursor: pointer;
            padding: 4px;
            margin-right: 10px;
            font-family: "Gilroy-Bold";
            background: transparent !important;
            padding: 0;
            box-shadow: none;

            &:hover {
              background: rgba(200, 0, 0, 0.5) !important;
              border-color: #c80000;
            }

            &.active {
              background: rgba(200, 0, 0, 0.5) !important;
              border-color: #c80000;
            }
          }
        }
      }
    }

    .seasonResult_table {
      background: #121212;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      padding: 20px;

      @media only screen and (max-width: 374px) {
        padding: 15px;
      }
    }

    .GB_device_th {
      font-size: 16px;
      line-height: 16px;
      font-family: "Gilroy-Bold";
      color: rgba(235, 235, 245, 0.6);

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding-left: 25px;

        @media only screen and (min-width: 768px) {
          border-top-right-radius: 0px;
        }
      }

      &:last-child {
        padding-right: 10px;

        @media only screen and (min-width: 768px) {
          border-top-right-radius: 10px;
        }
      }
    }

    .GB_device_td {
      text-align: left;
      font-family: "Gilroy-Bold";
      font-size: 18px;
      line-height: 18px;
      vertical-align: middle !important;

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }

      &::before {
        @media only screen and (max-width: 479px) {
          position: relative !important;
        }
      }

      &:first-child {
        padding-left: 25px;
      }

      &:nth-child(2) {
        @media only screen and (min-width: 768px) {
          width: 20%;
        }

        .profile-info {
          @media only screen and (min-width: 768px) {
            max-width: 250px;
          }
        }
      }

      .green-text {
        color: #31ff1f;
      }

      .red-text {
        color: #c80000;
      }
    }

    .playerDetails {
      .playerInfo {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: 374px) {
          flex-direction: column;
          text-align: right;
        }

        .profile-info {
          img {
            margin-right: 10px;
            width: 30px;
            height: 30px;
            border-radius: 20px;
          }
        }

        .country-flag {
          img {
            margin-left: 10px;
          }
        }
      }
    }

    .pointDetails {
      color: #ffc01f !important;

      .playerInfo {
        @media only screen and (max-width: 767px) {
          display: block;
        }

        .profile-info {
          img {
            margin-right: 10px;
          }
        }
      }
    }

    .kills {
      .playerInfo {

        // display: flex;
        // justify-content: space-between;
        // align-items: center;
        // @media only screen and (max-width: 374px){
        //   flex-direction: column;
        //   align-items: flex-end;
        // }
        .profile-info {
          display: flex;
          align-items: center;

          img {
            border: 2px solid #fff;
            border-radius: 100%;
            box-sizing: border-box;
            // height: 50px;
            margin-right: 20px;
            object-fit: cover;
            // width: 50px;
          }

          .playerInfo-inner {
            display: flex;
            flex-direction: column;

            span {
              color: gold !important;
              font-size: 14px;
              padding-top: 5px;
            }
          }
        }

        .bet-in-progress {
          color: #ffffff;
          opacity: 0.6;
        }

        .bet-failed {
          color: #c80000;
        }

        .streamer-sec {
          img {
            @media only screen and (max-width: 767px) {
              margin-left: 5px;
            }
          }
        }
      }

      // color: #31ff1f !important;

      // .playerInfo {
      //   @media only screen and (max-width: 767px) {
      //     display: block;
      //   }

      //   img {
      //     margin-right: 10px;
      //   }
      // }
    }
  }
}

/********************** BettingScoredboard End ************************/

/********************** StreamProfile start ************************/
.GB_StreamerProfile {
  background: #181818;
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  margin-bottom: 25px;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 767px) {
    position: absolute;
    background: #181818;
    opacity: 0.88;
    margin-bottom: 0px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .streamerProfile-left {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 15px;
    }

    @media only screen and (max-width: 490px) {
      flex-direction: column;
    }

    .profile-details {
      display: flex;
      align-items: center;
      margin-right: 30px;

      @media only screen and (max-width: 575px) {
        margin-right: 0px;
      }

      @media only screen and (max-width: 490px) {
        margin-bottom: 10px;
      }

      .profile-image {
        img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }

      .profile-right {
        .profile-name {
          display: flex;
          align-items: center;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 18px;
          color: #fff;
          margin-bottom: 5px;

          .country-flag {
            margin-left: 5px;
          }
        }

        .stream-link {
          display: flex;
          align-items: center;
          font-size: 12px;
          line-height: 12px;
          color: #fff;
          font-family: "Poppins-SemiBold";

          img {
            margin-right: 5px;
          }

          .stream-status {
            img {
              margin-left: 5px;
            }
          }
        }
      }
    }

    .bet-history {
      .won-details {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;

        img {
          margin-right: 5px;
        }
      }

      .total-bets {
        display: flex;
        align-items: center;
        color: rgba(235, 235, 245, 0.6);
        font-family: "Gilroy-Bold";
        font-size: 12px;
        line-height: 14px;

        .coins-earned {
          color: #ffc01f;
          font-family: "Gilroy-Bold";
          font-size: 12px;
          line-height: 14px;
          margin-left: 5px;

          img {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .streamerProfile-right {
    display: flex;
    align-items: center;
    gap: 20px;

    .view-details {
      color: rgba(235, 235, 245, 0.6);
      font-family: "Gilroy-Bold";
      font-size: 10px;
      line-height: 11px;

      img {
        margin-right: 5px;
      }
    }

    .follow-sec {
      background: #222222;
      border-radius: 6px;
      padding: 12px 8px;
      color: #fff;
      font-family: "Gilroy-Bold";
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 94px;
      height: 30px;
      cursor: pointer;

      &:hover {
        .hover-follow {
          display: block;
          margin-right: 5px;
        }

        .follow-btn {
          display: none;
        }
      }

      .hover-follow {
        display: none;
      }

      .follow-btn {
        display: block;
        margin-right: 5px;
      }
    }

    .profile-share {
      display: flex;
      align-items: center;
      gap: 5px;

      .share-box {
        border: 1px solid #727070;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }

      .share-sec {}
    }
  }
}

#share-popup {
  background: #181818 !important;
  height: 80px;
  width: 316px;
  border: 2px solid #484848;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gilroy-Medium";
  z-index: 9999;

  .popover-arrow {
    &::after {
      content: url("../images/arrow-black.png");
      border-top: none;
      top: -2px;
      left: -12px;
    }
  }

  .popover-body {
    padding: 0px;

    .share-box {
      color: rgba(235, 235, 245, 0.6);

      .share-icons {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 5px;
      }
    }
  }
}

#follow-popup {
  background: #181818 !important;
  height: 46px;
  width: 236px;
  border: 2px solid #484848;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gilroy-Medium";
  z-index: 9999;

  .popover-arrow {
    &::after {
      content: url("../images/arrow-black.png");
      border-top: none;
      top: -2px;
      left: -12px;
    }
  }

  .popover-body {
    padding: 0px;

    .follow-box {
      color: rgba(235, 235, 245, 0.6);

      .user-profile {
        color: #fff;
      }
    }
  }
}

/********************** StreamProfile End ************************/

/********************** streamChat start ************************/
.stream-live {
  position: relative;
}

.GB_streamChat {
  background: rgba(0, 0, 0, 0.9);
  border: 2px solid rgba(69, 69, 69, 0.6);
  border-radius: 10px;
  max-width: 384px;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 3px;
  padding-bottom: 10px;

  @media only screen and (min-width: 768px) {
    top: 85px;
    right: 25px;
    height: 275px;
  }

  .chat_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0px 10px;
    border-bottom: 2px solid #242424;

    .chat-logo {
      img {
        margin-left: 10px;
        border-right: 2px solid #242424;
        padding-right: 5px;
      }
    }

    .dataUsage {
      display: flex;
      align-items: center;
      font-family: "Gilroy-SemiBold";
      font-size: 14px;
      line-height: 16px;
      color: #40ab00;

      img {
        margin-right: 5px;
      }

      .online-status {
        color: rgba(235, 235, 245, 0.6);
        display: flex;
        align-items: center;
        margin-left: 10px;

        img {
          margin-right: 5px;
        }
      }
    }

    .chat-close {
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      img {
        margin-right: 10px;
      }
    }
  }

  .chat-data {
    font-family: "Gilroy-SemiBold";
    font-size: 14px;
    line-height: 16px;
    padding: 15px 25px;

    .messager-name {
      color: #40ab00;
      margin-bottom: 10px;

      .message {
        color: rgba(235, 235, 245, 0.6);
        margin-left: 5px;
      }
    }
  }

  .chat-bottom {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0px 25px;

    .chat-field {
      position: relative !important;
      width: 100%;

      input {
        background-color: #000;
        border: 2px solid #545454;
        border-radius: 7px;
        max-width: 300px;
        width: 100%;
        height: 41px;
        font-family: "Gilroy-SemiBold";
        font-size: 14px;
        line-height: 16px;
        padding: 11px;
        color: rgba(235, 235, 245, 0.6);
      }
    }

    .smiley-sec {
      top: 5px;
      right: 8px;
      cursor: pointer;
      position: absolute !important;
    }

    .send-btn {
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

/********************** streamChat End ************************/

._GB_bettingExpand {
  padding: 25px !important;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    padding: 15px 0;
  }

  .container {
    @media only screen and (min-width: 768px) and (max-width: 1200px) {
      max-width: 720px !important;
    }

    @media only screen and (min-width: 1201px) and (max-width: 1365px) {
      max-width: 910px !important;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      max-width: 100% !important;
    }

    @media only screen and (min-width: 1600px) {
      max-width: 100% !important;
    }

    .subcribe_section_inner {
      .left {
        .subscribe_form {
          @media only screen and (min-width: 992px) and (max-width: 1600px) {
            flex-direction: column;
          }

          input {
            @media only screen and (min-width: 992px) and (max-width: 1600px) {
              margin-bottom: 10px;
            }
          }

          .subscribe_btn {
            @media only screen and (min-width: 992px) and (max-width: 1600px) {
              margin-left: 0;
            }
          }
        }
      }

      .right {
        .discord_cls {
          img {
            @media only screen and (min-width: 992px) and (max-width: 1200px) {
              margin-bottom: 10px;
            }
          }
        }

        .twitter_cls {
          img {
            @media only screen and (min-width: 992px) and (max-width: 1200px) {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

/***************** challengeNotication Start ****************/
.GB_challengeNotication {
  background: #181818;
  border: 2px solid #2d2d2d;
  box-shadow: 0px -10px 4px rgba(0, 0, 0, 0.27);
  border-radius: 20px 20px 0px 0px;

  &.show {
    display: block;
    transition: top 2s ease 0s;
    -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

    @-webkit-keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  &.hide {
    display: none;
    transition: top 2s ease 0s;
  }

  .notification-close {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    cursor: pointer;
  }

  .challengeNotication-inner {
    padding: 0px 25px;

    .notify-message {
      color: #fff;
      font-family: "Gilroy-Bold";
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 20px;
    }

    .notify-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .yes-btn {
        background: transparent;
        border: 2px solid #ffffff;
        border-radius: 10px;
        color: #fff;
        max-width: 111px;
        height: 47px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Gilroy-Bold";
        font-size: 17px;
        line-height: 100%;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background: linear-gradient(90deg,
              #1f7700 1.07%,
              #52dd21 49.73%,
              #1f7700 100.09%);
          border: 2px solid #ffffff;
        }

        &.active {
          background: linear-gradient(90deg,
              #1f7700 1.07%,
              #52dd21 49.73%,
              #1f7700 100.09%);
          border: 2px solid #ffffff;
        }
      }

      .no-btn {
        background: transparent;
        border: 2px solid #ffffff;
        color: #fff;
        border-radius: 10px;
        max-width: 111px;
        height: 47px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Gilroy-Bold";
        font-size: 17px;
        line-height: 100%;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background: linear-gradient(90deg,
              #701513 1.07%,
              #e52420 49.73%,
              #6d1513 100.09%);
          border: 2px solid #ffffff;
        }

        &.active {
          background: linear-gradient(90deg,
              #701513 1.07%,
              #e52420 49.73%,
              #6d1513 100.09%);
          border: 2px solid #ffffff;
        }
      }
    }

    .challenge-amount {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-top: 20px;
      gap: 15px;

      .challenge-amount-left {
        display: flex;
        align-items: center;
        justify-content: center;

        input {
          background-color: #000000;
          border: 2px solid #2d2d2d;
          border-radius: 12px;
          max-width: 123px;
          height: 56px;
          font-family: "Gilroy-Bold";
          font-size: 18px;
          line-height: 100%;
          color: #fe5242;
          box-shadow: none;
          outline: none;
          text-align: center;
        }
      }
    }

    .challenge-amount-right {
      .converted-amout {
        font-family: "Gilroy-SemiBold";
        color: rgba(255, 255, 255, 0.5);
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 5px;

        img {
          margin-right: 5px;
          width: 26px;
        }
      }

      .challenge-amount-select {
        #challenge-dropdown {
          background: #262627;
          border-radius: 8px;
          outline: none;
          box-shadow: none;
          border: 1px solid #262627;
          display: flex;
          align-items: center;
          max-width: 115px;
          height: 37px;
          width: 100%;

          &::after {
            border-top: 0;
          }

          .item-token {
            display: flex !important;
            align-items: center !important;
            margin-right: 5px;

            .coin-img {
              display: flex;
              align-items: center;
              margin-right: 2px;
            }

            .coin-name {
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 18px;
            }
          }
        }

        .dropdown-menu {
          min-width: 96px;

          .dropdown-item {
            justify-content: center;

            .item-token-list {
              justify-content: center;

              .coin-img {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .challenge-send {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      margin: 15px 0px;

      .send-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .coin-box {
          width: 35px;
          height: 30px;
          background-color: #000000;
          border: 1.5px solid #484848;
          border-radius: 6px;
          font-size: 12px;
          line-height: 100%;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Poppins-SemiBold";
          font-size: 12px;
          line-height: 12px;
          cursor: pointer;

          &.active-coinbox {
            background: rgba(255, 192, 31, 0.4);
            border: 1.5px solid #ffc01f;
          }
        }
      }

      .send-right {
        button {
          display: block !important;
          width: 113px;
          height: 36px;
          margin: 10px auto 15px;
          font-family: "Poppins-SemiBold";
          text-transform: uppercase;
          text-decoration: none;
          color: #ffffff;
          border-radius: 10px;
          padding: 13px 10px 15px 25px;
          background: url(../images/white_arrow.png) no-repeat 19% center;
          cursor: pointer;
          background-color: #141414;
          border: 2px solid #292929;
          border-radius: 10px;
          color: #fff;
          font-size: 12px;
          line-height: 12px;

          &:hover {
            opacity: 0.5;
          }

          @media only screen and (max-width: 767px) {
            padding: 10px 0 10px 30px;
            font-size: 14px;
            line-height: 14px;
            width: 100px;
          }
        }
      }
    }

    .minimize-notofication {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 10px;
    }
  }
}

/***************** challengeNotication End ****************/
.Toastify {
  z-index: 9999999999 !important;
  position: absolute;
}

.betting-top-container {
  background-color: #000;
  height: auto;
  width: 100%;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #454545;

  // @media only screen and (min-width:1025px) {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  // }
  @media only screen and (max-width: 991px) {
    border-bottom: 0px solid #454545;
  }

  @media only screen and (min-width: 992px) {
    position: fixed;
  }

  .logo-section {
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    border-bottom: 2px solid #45454569;

    @media only screen and (min-width: 992px) {
      max-width: 220px;
    }

    @media only screen and (min-width: 992px) {
      padding-left: 30px;
    }

    @media only screen and (min-width: 1600px) {
      max-width: 240px;
    }

    @media only screen and (min-width: 1700px) {
      max-width: 265px;
    }

    &.expanded-bg {
      background-color: #0c0c0c;
    }

    &.collapsed-bg {
      background-color: #000;
    }
  }

  .header-menu {
    border-bottom: 2px solid #45454569;
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 30px;

    .header-nav {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 30px;
      margin-bottom: 0px;

      li {
        font-family: "Gilroy-Bold";
        color: #ffffff;
        font-size: 18px;
        cursor: pointer;

        img {
          margin-right: 15px;
        }

        .dropdown-menu {
          .dropdown-item {
            font-size: 14px;
            padding-left: 10px;
          }
        }

        .dropdown-toggle {
          background: #000;
          border: none;

          .profile {
            border: 3px solid #e52420;
            border-radius: 25px;
          }
        }

        .dropdown-app,
        #dropdown-language {
          background-color: #000;
          border: 2px solid #2d2d2d;
          box-shadow: none;
        }

        .dropdown-app {
          &:after {
            display: none;
          }

          &:hover {
            border: 2px solid #e52420;
          }
        }

        .dropdown-item {
          .appicon {
            width: 23px;
            height: 23px;
            object-fit: contain;
          }
        }

        .qrcode-item {
          &:hover {
            background: none;
          }

          p {
            font-family: "Gilroy-Bold";
            color: #a9a9a9;
            font-size: 13px;
            text-align: center;
            padding-top: 6px;
          }
        }
      }
    }
  }
}

.modal-open {
  .betting-top-container {
    z-index: 0;
  }
}

.main-menu {
  width: 100%;
  display: none;
  position: relative;

  @media only screen and (min-width: 1025px) {
    display: flex;
  }
}

.golive_sec {
  position: fixed;
  right: 0;
  top: 37%;
  width: 70px;
  z-index: 99;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.challenge_sec {
  position: fixed;
  right: 0;
  top: 45%;
  width: 70px;
  z-index: 99;
  cursor: pointer;

  @media only screen and (min-width: 1920px) {
    top: 44%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.followers-section {
  h3 {
    font-family: "Gilroy-Bold";
    color: #ffffff;
    font-size: 18px;
    padding: 20px 20px;
  }

  .followers-list {
    list-style: none;
    padding-left: 0;
    padding: 0px 10px;

    li {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      background-color: #000;
      border-radius: 10px;
      padding: 6px;
      margin-bottom: 10px;

      p {
        font-family: "Gilroy-Bold";
        font-size: 14px;
        color: #ffffff;
        margin-bottom: 0px;
      }

      .image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;

        img {
          border-radius: 25px;
        }
      }

      .col2 span {
        font-family: "Gilroy-Medium";
        color: #ebebf5;
        font-size: 12px;
        opacity: 0.6;
      }

      .col3 span {
        font-family: "Gilroy-Medium";
        color: #ebebf5;
        font-size: 14px;

        &.red-tick {
          &::before {
            content: url("../images/sidebar/redRound.png");
            width: 9px;
            height: 9px;
          }
        }

        &.green-tick {
          &::before {
            content: url("../images/sidebar/greenRound.png");
            width: 9px;
            height: 9px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .manage-followers {
    font-family: "Gilroy-Bold";
    color: #a9a9a9;
    font-size: 13px;
    padding: 0px 20px;
    cursor: pointer;

    &::before {
      content: url("../images/sidebar/settings.png");
      margin-right: 5px;
    }
  }
}

.betting-left-container {
  // background-color: #181818;
  background-color: #161515;
  height: 100vh;
  padding-right: 0px;
  display: none;
  width: 100%;
  max-width: 240px;
  position: sticky;
  top: 6%;
  left: 0;
  z-index: 999;
  height: 100vh;

  @media only screen and (min-width: 1025px) and (max-width: 1365px) {
    max-width: 200px;
  }

  @media only screen and (min-width: 1025px) {
    display: block;
  }

  .collapse-btn {
    position: absolute;
    top: 12px;
    right: -10px;
    cursor: pointer;
  }

  .menu-section {
    border-bottom: 2px solid #45454569;

    .menu-nav {
      list-style: none;
      padding-left: 0px;
      padding: 0px 0px;

      li {
        position: relative;

        // @media only screen and (min-width: 1025px) and (max-width: 1919px) {
        //   margin-bottom: 10px;
        // }

        a {
          font-family: "Gilroy-Bold";
          color: #ffffff;
          font-size: 15px;
          padding: 20px 20px;
          height: 60px;
          display: flex;
          align-items: center;
          gap: 20px;
          cursor: pointer;
          text-decoration: none;

          @media only screen and (min-width: 1025px) and (max-width: 1919px) {
            gap: 10px;
            padding: 9px 13px;
            font-size: 14px;
          }

          .mega-menu {
            position: absolute;
            right: 12px;
          }

          .sidebar-icon {
            @media only screen and (min-width: 1366px) and (max-width: 1919px) {
              // width: 21px;
            }
          }

          .dashbaord {
            width: 45px;
            height: 45px;
            margin-left: 5px;
            object-fit: contain;
          }

          &:hover {
            background-color: #2d2d2d;
          }

          &.menuactive {
            background-color: #2d2d2d !important;
          }

          &.deactive {
            background-color: #161515;
            opacity: 0.5;

            img {
              filter: grayscale(100%);
            }
          }

          .mega-menu {
            img {
              margin-left: 30px;

              @media only screen and (min-width: 1300px) and (max-width: 1365px) {
                margin-left: 6px;
              }
            }
          }
        }

        .competition-menu {
          background: #121212 !important;
          border: 1px solid #2d2d2d !important;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
          border-radius: 8px !important;
          padding: 10px !important;
          position: absolute;
          top: 0;
          left: 100%;
          display: none;
          cursor: pointer;

          @media only screen and (min-width: 1360px) and (max-width: 1919px) {
            // right: -200px;
            left: 100%;
            width: 300px;
          }

          a {
            color: #fff;

            &:hover {
              color: #e52420 !important;
              background: #000000;
              border-radius: 8px;
            }
          }

          .menu-item {
            color: #fff;
            padding: 8px !important;
            font-family: "Gilroy-Bold";
            font-size: 14px;

            img {
              margin-right: 10px;
              width: 32px;

              @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                width: 21px;
              }
            }
          }
        }

        &:hover {
          .competition-menu {
            display: block;
          }
        }
      }
    }
  }

  &.expanded {
    transition: all 0.4s ease-out;
    // max-width: 240px;
    // @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    //   max-width: 200px;
    // }

    .slow {
      -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    @-webkit-keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .big-sidebar {
      width: 240px;

      @media only screen and (min-width: 1025px) and (max-width: 1919px) {
        width: 200px !important;
      }
    }
  }

  &.collapsed {
    transition: all 0.5s ease-out;
    max-width: 4%;
    background-color: #212121;

    @media only screen and (max-width: 1500px) {
      max-width: 6%;
    }

    .menu-section {
      border-bottom: none;
      margin-bottom: 50px;

      .menu-nav {
        padding: 8px 0px;

        li {
          margin-bottom: 10px;
          cursor: pointer;

          &:hover {
            width: 200px;
            height: auto;
            background-color: #181818;
            border-radius: 10px;
            margin-left: 20px;
            z-index: 99;

            img {
              margin: 0px 0px 0px 10px;
            }

            span {
              display: block;
              font-family: "Gilroy-Bold";
              color: #ffffff;
              font-size: 18px;
              line-height: 18px;
            }

            a {
              margin-left: 0px;
            }
          }

          a {
            width: 50px;
            height: 50px;
            padding: 0px;
            background-color: #181818;
            margin: 0 auto;
            border-radius: 8px;

            span {
              display: none;
            }

            img {
              margin: 0 auto;

              &.dashbaord {
                width: 40px;
                height: 40px;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }

    .followers-section {
      .follow-heart {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        background-color: #181818;
        border-radius: 8px;
        margin: 0 auto 10px;
      }

      .followers-list {
        li {
          width: 36px;
          height: 36px;
          margin: 0 auto 10px;
          padding: 0px;
          background-color: #212121;
          position: relative;
          z-index: 9999;
          min-height: 52px;

          img {
            border-radius: 0;
          }

          .col2,
          .col3 {
            display: none;
          }

          &:hover {
            width: 200px;
            height: auto;
            background-color: #181818;
            padding: 2px;
            margin-bottom: 10px;
            margin-left: 10px;

            @media only screen and (max-width: 1700px) {
              margin-left: 0px;
            }

            img {
              border-radius: 25px;
            }

            .col2,
            .col3 {
              display: block;
            }
          }
        }
      }
    }

    .competition {
      width: auto !important;

      .competition-menu {
        right: -215px !important;

        a {
          width: auto !important;
          height: auto !important;
          border-radius: 0 !important;
          background-color: #121212 !important;
        }

        .menu-item {
          &:hover {
            color: #e52420 !important;
            background: #000000;
            border-radius: 8px;
          }

          img {
            margin-left: 0 !important;
            margin-right: 10px !important;
          }
        }
      }
    }
  }
}

.column-right {
  position: relative;
}

.bet_exp_right {
  width: 80%;
  margin: auto;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.video-straming-inner {
  background-color: #000;
  display: flex;
  height: auto;

  .betting-right-container {
    display: flex;
    padding: 30px 50px;

    .straming-left {
      width: 100%;
      max-width: 70%;

      .top-bar {
        width: 100%;
        height: 50px;
        background: #181818;
        border-radius: 10px;
      }
    }

    .straming-right {
      width: 100%;
      max-width: 30%;
    }
  }
}

.betting-sidebar-mobile {
  @media only screen and (min-width: 1025px) {
    display: none;
  }

  .nav-link {
    font-family: "Gilroy-Bold";
    color: #ffffff;
    font-size: 14px;
    padding: 10px 10px;
    cursor: pointer;

    img {
      margin-right: 15px;
    }

    .dropdown-menu {
      .dropdown-item {
        font-size: 12px;
        padding-left: 10px;
      }
    }

    .dropdown-toggle {
      background: transparent;
      border: none;
      font-size: 14px;
      padding: 0px;

      .profile {
        border: 3px solid #e52420;
        border-radius: 25px;
      }
    }

    .dropdown-app,
    #dropdown-language {
      background-color: transparent;
      box-shadow: none;
    }

    .dropdown-app {
      &:after {
        display: none;
      }

      &:hover {
        border: 2px solid #e52420;
      }
    }
  }

  .followers-section {
    .followers-list {
      li {
        max-width: 300px;
      }
    }

    padding-bottom: 30px;
  }
}

/*********personal deatils modal***********/
.modal-personal-details {
  padding: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e52420;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e52420;
  }

  .betting-top-container {
    z-index: 0;
  }

  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 20px;
    //margin: 8% auto !important;

    @media only screen and (min-width: 768px) {
      padding: 0px !important;
      max-width: 528px;
    }

    .modal-content {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      margin-bottom: 20%;

      .modal-header {
        border: none;

        .btn-close {
          background: url("../images/close_button.png") no-repeat;
          position: relative;
          top: 10px;
        }
      }

      .modal-body {
        padding: 0px 30px 30px;

        // @media only screen and (min-width:991px) {
        //     padding: 0px 79px 40px
        // }

        @media only screen and (min-width: 991px) {
          padding: 0px 40px 10px;
        }

        .heading-bar {
          width: 100%;
          margin: 0 auto;

          @media only screen and (min-width: 991px) {
            max-width: 395px;
          }

          h2 {
            font-family: "Gilroy-Bold";
            font-size: 25px;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            color: #fff;
            margin-bottom: 12px;

            @media only screen and (min-width: 991px) {
              font-size: 30px;
              line-height: 30px;
            }
          }

          p {
            font-family: "Gilroy-Bold";
            max-width: 314px;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0em;
            text-align: center;
            color: #9c9ca3;
            margin: auto auto 20px auto;
          }
        }

        .personal-form {
          border-top: 2px solid #2d2d2d;
          padding: 20px 0px 0px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          .form-group {
            width: 100%;
            position: relative;

            label {
              font-family: "Gilroy-Medium";
              font-size: 14px;
              line-height: 14px;
              letter-spacing: 0em;
              text-align: left;
              color: #9c9ca3;
              margin-bottom: 5px;

              &.hide {
                visibility: hidden;

                @media only screen and (max-width: 400px) {
                  display: none;
                }
              }

              span {
                color: #e52420;
              }
            }

            .form-field-error {
              position: absolute;
              color: #e52420;
              bottom: -4px;
              font-size: 14px;
            }

            .form-control {
              width: 100%;
              height: 44px;
              margin-bottom: 10px;
              background-color: #000000 !important;
              border: 2px solid #262627;
              border-radius: 12px;
              font-family: "Gilroy-Medium";
              font-size: 14px;
              line-height: 14px;
              color: #fff;

              &::placeholder {
                color: #9c9ca3;
              }

              &:focus {
                border: 2px solid #e52420;
                box-shadow: none;
              }

              &:disabled {
                background-color: #1b1b1b !important;
                border: 2px solid #262627;
                color: #9c9ca3;
              }
            }

            select {
              background: url("../images/sidebar/down.png") no-repeat right center;
              appearance: none;
              -moz-appearance: none;
              -webkit-appearance: none;
              width: 90px;
              text-indent: 0.01px;
              text-overflow: "";
              background-position: 92%;
            }

            // @media only screen and (min-width:768px) {
            &.md-3 {
              // max-width: 145px;
              flex: 0 0 32%;
              max-width: 32%;

              @media only screen and (max-width: 400px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 0px !important;
              }
            }

            &.md-2 {
              // max-width: 220px;
              flex: 0 0 48%;
              max-width: 48%;

              @media only screen and (max-width: 400px) {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }

            // }

            .btn-danger {
              background-color: #e52420;
              background-image: linear-gradient(90deg,
                  #6d1513,
                  #e52420,
                  #6d1513);
              border: 2px solid #fff;
              border-radius: 10px;
              color: #fff;
              cursor: pointer;
              font-family: "Poppins-Bold";
              font-size: 15px;
              letter-spacing: 0.04em;
              line-height: 15px;
              padding: 17px 10px;
              text-align: center;
              text-decoration: none;
              text-transform: uppercase;
              width: 100%;
              max-width: 178px;
              display: block;
              margin: 0px auto;

              &:after {
                content: url("../images/arrow.png");
                padding-left: 10px;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }

          .betting-rules {
            background: #000000;
            border: 2px solid #262627;
            border-radius: 12px;
            padding: 5px 15px 20px 15px;

            // @media only screen and (min-width:991px) {
            //     padding: 5px 40px 20px 40px;
            // }

            ul {
              height: 260px;
              overflow: auto;
              padding-right: 1em;

              &::-webkit-scrollbar {
                width: 8px;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: #181818;
                border-radius: 8px;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #535353;
                border-radius: 8px;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb:hover {
                background: #e52420;
              }

              li {
                font-family: "Gilroy-SemiBold";
                font-size: 12px;
                line-height: 15px;
                list-style: number;
                color: #9c9ca3;
                border-bottom: 2px solid #2d2d2d;
                padding: 15px 0px;
              }
            }

            .agreeToUserAgreements {
              position: relative;
              margin-bottom: 15px;

              ._GB_st_enable {
                font-family: "Gilroy-SemiBold";
                font-size: 15px;
                color: #9c9ca3;
                margin-left: 33px;
              }

              input {
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
              }

              .checkmark {
                position: absolute;
                top: 2px;
                left: 0;
                height: 21px;
                width: 21px;
                background-color: #262627;
                border: 2px solid #5c5c5c;
                border-radius: 3px;
                cursor: pointer;
              }

              /* On mouse-over, add a grey background color */
              .container:hover input~.checkmark {
                background-color: #ccc;
              }

              /* When the checkbox is checked, add a blue background */
              input:checked~.checkmark {
                background-color: #c80000;
              }

              /* Create the checkmark/indicator (hidden when not checked) */
              .checkmark:after {
                content: "";
                position: absolute;
                display: none;
              }

              input:checked~.checkmark:after {
                display: block;
              }

              /* Style the checkmark/indicator */
              .checkmark:after {
                left: 6px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
}

.stepperWrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  max-width: 200px;
  margin: 0 auto 10px;

  &.challenge-page {
    justify-content: flex-end !important;
    margin: 0 0px;

    .stepperItem {
      @media only screen and (min-width: 768px) {
        align-items: flex-end;

        &::before,
        &::after {
          left: 99%;
        }
      }
    }
  }
}

.stepperItem {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  &::before {
    position: absolute;
    content: "";
    border-bottom: 1px dashed #d9d9d9;
    width: 74%;
    top: 11px;
    left: 63%;
    z-index: 2;
    opacity: 0.5;
  }

  &::after {
    position: absolute;
    content: "";
    border-bottom: 1px dashed #d9d9d9;
    width: 74%;
    top: 11px;
    left: 64%;
    z-index: 2;
    opacity: 0.5;
  }

  .stepCounter {
    cursor: pointer;
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #181818;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    color: #ffffff;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    margin-bottom: 6px;

    &.one {
      border: 2px solid #52dd21;
    }

    &.active {
      background-color: #52dd21;
      border: 2px solid #52dd21;
      color: #000000;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepperItem:last-child::before {
  content: none;
}

.stepperItem:last-child::after {
  content: none;
}

.product-list-container {
  display: grid;
  grid-template-rows: 55px calc(100vh - 55px);
  grid-template-columns: 220px auto;
  grid-template-areas:
    "filter header"
    "filter products";
}

/* styling for just the main list of products displayed on screen */
.product-list-header {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: header;
}

.products-container {
  grid-area: products;
  overflow: auto;
}

.product-list-product-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

/* styling for the filters */
.filter-wrapper {
  padding: 10px;
  background-color: #ffcfae;
  grid-area: filter;
  height: 100vh;
}

.filter-title {
  font-weight: 600;
}

.filter-data {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  border-top: 2px solid #012025;
}

.filter-item {
  margin: 8px 16px;
}

.filter-checkbox {
  margin-left: 10px;
}

/****************create challenge*************/
.modal-challenges {
  padding: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e52420;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e52420;
  }

  .betting-top-container {
    z-index: 0;
  }

  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 20px;

    @media only screen and (min-width: 768px) {
      padding: 0px !important;
      max-width: 540px;
    }

    .modal-content {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      margin-bottom: 20%;

      .modal-header {
        border: none;
        padding: 10px;

        .btn-close {
          background: url("../images/close_button.png") no-repeat;
          position: relative;
          top: 10px;
          box-shadow: none;
        }
      }

      .modal-body {
        padding: 0px 30px 10px;

        @media only screen and (min-width: 1700px) {
          padding: 0px 25px 10px;
        }

        @media only screen and (max-width: 374px) {
          padding: 0px 20px 10px;
        }

        .GB_challenges {
          h2 {
            font-family: "Gilroy-Bold";
            font-size: 30px;
            line-height: 30px;
            text-align: center;
            color: #ffffff;
          }

          .games {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            @media only screen and (max-width: 991px) {
              flex-direction: column;
              margin-top: 30px;
            }

            .left-section {
              width: 100%;
              max-width: 50%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              @media only screen and (max-width: 990px) {
                max-width: 100%;
                justify-content: center;
              }

              .game-type {
                width: 100%;
                max-width: 100px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #181818;
                border-radius: 8px;
                border: 2px solid #2d2d2d;
                font-family: "Gilroy-SemiBold";
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                color: #ffffff;
                text-decoration: none;
                height: 36px;
                cursor: pointer;

                &.active {
                  border: 2px solid #ffc01f;
                }

                &.private {
                  color: rgba(255, 255, 255, 0.3);
                }

                &.public {
                  &:hover {
                    border: 2px solid #ffc01f;
                  }
                }
              }
            }

            .right-section {
              display: flex;
              align-items: center;
              width: 100%;
              max-width: 40%;
              justify-content: space-between;

              @media only screen and (max-width: 990px) {
                flex-wrap: wrap;
                max-width: 100%;
                justify-content: center;
                gap: 10px;
              }

              p {
                font-family: "Gilroy-SemiBold";
                font-size: 14px;
                line-height: 16px;
                text-align: right;
                text-transform: capitalize;
                color: #ffffff;
                margin-bottom: 0px;
                margin-right: 10px;

                @media only screen and (max-width: 990px) {
                  text-align: center;
                  margin: 20px 0px;
                }

                @media only screen and (max-width: 375px) {
                  width: 100%;
                  margin: 20px 0px 10px;
                }
              }

              .game-list {
                width: 40px;
                height: 40px;
                background: #000000;
                border: 1.5px solid #2d2d2d;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover,
                &.active {
                  background: #640000;
                  border: 1.5px solid #c80000;
                }

                img {
                  width: 100%;
                  max-width: 30px;
                  height: 30px;
                }
              }
            }
          }

          .challenge-types {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0px;

            @media only screen and (max-width: 767px) {
              flex-wrap: wrap;
              justify-content: center;
              gap: 10px;
            }

            .before-coin {
              width: 159px;
              height: 42px;
              background: #000000;
              border: 2px solid #2d2d2d;
              border-radius: 12px;
              font-family: "Gilroy-SemiBold";
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              color: #ffffff;
              text-decoration: none;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              cursor: pointer;

              &:hover,
              &.active {
                border: 2px solid #e52420;
                box-shadow: 0px 0px 9px rgba(229, 36, 32, 0.25);
              }

              &::before {
                content: url("../images/sidebar/coin.png");
                margin-top: 8px;
              }

              &.disabled {
                cursor: not-allowed;
              }
            }
          }

          .challnges-list-container {
            background: #262627;
            padding: 15px 15px;
            border-radius: 8px;
            max-height: 249px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 767px) {
              padding: 15px 15px 15px 0px !important;
            }
          }

          .bet-amount-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 50px;

            @media only screen and (max-width: 767px) {
              flex-direction: column;
              gap: 15px;
              padding: 12px 30px;
            }

            .initial-bet {
              h3 {
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 100%;
                letter-spacing: 0.04em;
                color: #ffffff;
                opacity: 0.88;
              }

              p {
                font-family: "Gilroy-Bold";
                font-size: 10px;
                line-height: 100%;
                letter-spacing: 0.04em;
                color: #ffffff;
                opacity: 0.88;
                margin-bottom: 0px;
              }

              @media only screen and (max-width: 767px) {
                text-align: center;
              }
            }

            .bet-amount {
              .form-control {
                background: #000000;
                border: 2px solid #2d2d2d;
                border-radius: 12px;
                width: 123px;
                height: 50px;
                font-family: "Gilroy-Bold";
                font-size: 26px;
                line-height: 100%;
                text-align: center;
                color: #fe5242;

                @media only screen and(min-width: 1366px) and(max-width: 1919px) {
                  font-size: 20px;
                }

                &:focus {
                  box-shadow: none;
                }
              }
            }

            .bet-amount-unit {
              p {
                font-family: "Gilroy-SemiBold";
                font-size: 13px;
                line-height: 15px;
                color: rgba(255, 255, 255, 0.5);
                margin-bottom: 4px;

                &::before {
                  content: url("../images/betting/up-down-arrow.png");
                  transform: rotate(90deg);
                  display: inline-block;
                  margin-right: 10px;
                  margin-left: 5px;
                }
              }

              .dropdown-toggle {
                background: #262627;
                border-radius: 8px;
                width: fit-content;
                height: 32px;
                border: none;
                font-family: "Gilroy-Bold";
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;

                &:after {
                  content: url("../images/betting/drop-arrow.png");
                  width: 12px;
                  border: none;
                }

                &.before-coin {
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  &::before {
                    content: url("../images/betting/coin-gamebeef.png");
                    top: 1px;
                    position: relative;
                  }
                }

                &.sol-coin {
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  &::before {
                    content: url("../images/betting/solana_coin.png");
                    top: 1px;
                    position: relative;
                  }
                }
              }

              .dropdown-menu {
                .dropdown-item {
                  padding: 5px 10px;

                  @media only screen and (max-width: 575px) {
                    min-width: 98px;
                  }
                }

                .before-coin {
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  &::before {
                    content: url("../images/betting/coin-gamebeef.png");
                    top: 3px;
                    position: relative;
                  }
                }
              }
            }
          }

          .selected-game-challegs {
            width: 100%;
            max-height: 225px;
            overflow: auto;
            padding: 0px 15px 0px 0px;

            @media only screen and (max-width: 620px) {
              padding: 0px 10px;
            }

            @media only screen and (max-width: 374px) {
              padding: 0px;
            }

            &::-webkit-scrollbar {
              width: 8px;
              margin-right: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #000000;
              border-radius: 8px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #535353;
              border-radius: 8px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #e52420;
            }

            .item {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;

              .box-description {
                background: #000000;
                border: 2px solid #2d2d2d;
                border-radius: 20px;
                width: 100%;
                max-width: 535px;
                padding: 11px 20px;
                margin-bottom: 5px;
                display: flex;
                justify-content: space-between;

                @media only screen and (max-width: 374px) {
                  padding: 11px 15px;
                }

                .box-left {
                  width: 100%;
                  max-width: 70%;

                  @media only screen and (max-width: 620px) {
                    flex-direction: column;
                  }

                  @media only screen and (max-width: 420px) {
                    max-width: 100%;
                  }

                  .top {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    @media only screen and (max-width: 620px) {
                      flex-direction: column;
                      align-items: flex-start;
                    }

                    h3 {
                      font-family: "Gilroy-Bold";
                      font-size: 14px;
                      line-height: 16px;
                      letter-spacing: 0.04em;
                      color: #52dd21;
                      margin-bottom: 0px;
                    }

                    span {
                      font-family: "Gilroy-Bold";
                      font-size: 14px;
                      line-height: 100%;
                      letter-spacing: 0.04em;
                      color: #ffc01f;
                    }
                  }

                  p {
                    font-family: "Gilroy-Bold";
                    font-size: 14px;
                    line-height: 16px;
                    letter-spacing: 0.04em;
                    color: #ffffff;
                    margin-bottom: 0px;
                    margin-top: 5px;
                  }
                }

                @media only screen and (max-width: 420px) {
                  flex-direction: column-reverse;
                }

                img {
                  object-fit: contain;

                  @media only screen and (max-width: 420px) {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 10px;
                  }
                }
              }

              /* The container */
              .btn-container {
                display: block;
                position: relative;
                padding-left: 25px;
                cursor: pointer;
                font-size: 22px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;

                @media only screen and (max-width: 767px) {
                  padding-left: 0px;
                }

                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                }
              }

              /* Create a custom radio button */
              .checkmark {
                position: absolute;
                top: -15px;
                left: 7px;
                height: 22px;
                width: 22px;
                background-color: #000000;
                border-radius: 50%;
                border: 2px solid #2d2d2d;

                &::after {
                  content: "";
                  position: absolute;
                  display: none;
                }
              }

              /* When the radio button is checked, add a blue background */
              .btn-container input:checked~.checkmark {
                background-color: #000000;
              }

              /* Show the indicator (dot/circle) when checked */
              .btn-container input:checked~.checkmark:after {
                display: block;
              }

              /* Style the indicator (dot/circle) */
              .btn-container .checkmark:after {
                top: 5px;
                left: 4px;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: #40ab00;
              }
            }

            .spinner-grow {
              display: block;
              margin: 0 auto;
            }
          }

          .btn-danger {
            background-color: #e52420;
            background-image: linear-gradient(90deg, #6d1513, #e52420, #6d1513);
            border: 2px solid #fff;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            font-family: "Poppins-Bold";
            font-size: 14px;
            letter-spacing: 0.04em;
            line-height: 15px;
            margin: auto;
            padding: 17px 20px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            width: 100%;
            max-width: fit-content;
            display: block;
            margin: 30px auto !important;

            &:after {
              content: url("../images/arrow.png");
              padding-left: 10px;
            }

            &:hover {
              opacity: 0.5;
            }

            &.waiting {
              display: flex;
              align-items: center;

              .spinner-border {
                margin-right: 8px;
              }

              &::after {
                display: none;
              }
            }
          }
        }

        .challenges-step-two {
          padding: 30px 15px;
        }
      }
    }
  }
}

.gif-rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**********join competition popup **********/
.modal-join-competition {
  padding: 0;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e52420;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e52420;
  }

  .betting-top-container {
    z-index: 0;
  }

  .modal-dialog {
    width: 100%;
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;

    @media only screen and (min-width: 768px) {
      padding: 0px !important;
    }

    .modal-content {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      margin-bottom: 20%;

      .modal-header {
        border: none;
        padding: 10px;

        .btn-close {
          background: url("../images/close_button.png") no-repeat;
          position: relative;
          top: 10px;
          box-shadow: none;
        }
      }

      .modal-body {
        padding: 0px 30px 10px;

        .competition-body {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          gap: 20px;

          h2 {
            font-family: "Gilroy-Bold";
            font-size: 22px;
            line-height: 100%;
            color: #ffffff;

            @media only screen and (min-width: 991px) {
              font-size: 30px;
            }
          }

          p {
            font-family: "Gilroy-Bold";
            font-size: 14px;
            line-height: 25px;
            text-align: center;
            color: #ffffff;

            @media only screen and (min-width: 991px) {
              font-size: 16px;
            }
          }

          h3 {
            font-family: "Gilroy-Bold";
            font-size: 18px;
            line-height: 32px;
            text-align: center;
            color: #fa3d3f;

            @media only screen and (min-width: 991px) {
              font-size: 29px;
            }
          }

          .games {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .game-list {
              width: 54px;
              height: 54px;
              background: #000000;
              border: 1.5px solid #2d2d2d;
              border-radius: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &:hover,
              &.active {
                background: #640000;
                border: 1.5px solid #c80000;
              }

              img {
                width: 100%;
                max-width: 37px;
                height: 37px;
                object-fit: contain;
              }
            }
          }

          .green-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;
            max-width: 239px;
            height: 55px;
            background: #52dd21;
            border-radius: 10px;
            font-family: "Poppins-Bold";
            color: #000;
            font-size: 15px;
            letter-spacing: 0.04em;
            text-transform: uppercase;

            &:hover {
              opacity: 0.5;
            }

            &::after {
              content: url("../images/btn-black-arrow.png");
            }

            @media only screen and (max-width: 768px) {
              font-size: 14px;
              height: 38px;
            }
          }
        }
      }
    }
  }
}

/****************** Challenge Sidebar Start *************/
.challenge-megamenu {
  position: relative;

  @media only screen and (max-width: 1024px) {
    display: none;
  }

  .close-btn {
    position: absolute;
    top: 18px;
    right: 12px;
    cursor: pointer;
  }

  .challenge-right {
    background-color: #161515;
    display: flex;
    gap: 50px;
    align-items: center;
    height: 314px;
    padding: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .challenge-yourself {
      background-image: url("../images/betting/betagainstyourself.png");
      background-repeat: no-repeat;

      &:hover {
        background-image: url("../images/betting/betagainstyourself-green.png");
      }
    }

    .challenge-another {
      background-image: url("../images/betting/challengeanotherplayer.png");
      background-repeat: no-repeat;

      &:hover {
        background-image: url("../images/betting/challengeanotherplayer-green.png");
      }

      span {
        max-width: 247px;
      }
    }

    a {
      max-width: 494px;
      height: 214px;
      width: 100%;
      border-radius: 10px;
      cursor: pointer;
      position: relative;
      text-decoration: none;

      .challenge-box {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 494px;
        height: 100%;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #707070;

        span {
          font-family: "Gilroy-Bold";
          font-size: 30px;
          line-height: 35px;
          color: #fff;
          text-align: center;
        }

        &:hover {
          background-color: rgba(49, 255, 31, 0.5);
          border: 1px solid rgba(49, 255, 31, 0.5);
        }

        .challenge-alert {
          position: absolute;
          top: 7px;
          right: 7px;
        }

        &.deactive {
          background-color: rgba(0, 0, 0, 0.9);

          img {
            filter: grayscale(100%);
          }

          &:hover {
            border: 1px solid #707070;
          }

          .coming_soon {
            display: none;
          }

          &:hover {
            span {
              opacity: 0.4;
            }

            .coming_soon {
              display: block;
              font-family: "Gilroy-Bold";
              font-size: 30px;
              line-height: 35px;
              color: #fff;
              position: absolute;
              top: 85px;
              left: 150px;
            }
          }
        }

        .beta-btn {
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 19px;
          color: #000;
          background: #ffc01f;
          position: absolute;
          top: 11px;
          left: 7px;
          width: 100%;
          max-width: 52px;
          height: 19px;
          text-align: center;
          background: #ffc01f;
          border-radius: 3px;
          z-index: 99999;
        }
      }
    }
  }
}

.cymmexpanded {
  .challenge-right {
    @media only screen and (min-width: 1920px) {
      padding-left: 280px;
    }
  }
}

.cymmcollapsed {
  .challenge-right {
    @media only screen and (min-width: 1920px) {
      padding-left: 145px;
    }
  }
}

#alert-popup {
  z-index: 9999;
  border: 1.5px solid #c80000;
  border-radius: 12px;

  &.beta-alert {
    width: 100%;
    max-width: 395px;

    .popover-body {
      max-width: 100%;
    }
  }

  .popover-body {
    max-width: 232px;
    background: #640000;
    border-radius: 12px;
    color: #ffffff;
    font-family: "Gilroy-Bold";
    font-size: 14px;
    line-height: 14px;
    text-align: center;
  }

  .popover-arrow {
    &::after {
      content: url("../images/tooltip_arrow.png");
      border-top: none;
      top: -5px;
      left: -8px;
    }
  }
}

/****************** Challenge Sidebar End *************/

/***************** PlayToEarn Dashboard Start ****************/
.GB_playToEarn {
  background-color: #000000;

  .playToEarn_inner {
    padding-top: 84px !important;

    @media only screen and (min-width: 1024px) {
      display: flex;
      padding-top: 78px;
    }

    @media only screen and (max-width: 767px) {
      padding-top: 0px !important;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      padding-top: 54px !important;
    }

    .playToEarn_right {
      background-color: #000000;

      // @media only screen and (min-width: 1025px) and (max-width: 1414px) {
      //   max-width: 83%;
      // }

      // @media only screen and (min-width: 1415px) and (max-width: 1470px) {
      //   max-width: 84%;
      // }
      // @media only screen and (min-width: 1025px) and (max-width: 1919px) {
      //   max-width: 100%;
      //   margin: auto;
      // }
      // @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      //   max-width: 70%;
      //   margin: auto;
      // }

      @media only screen and (max-width: 767px) {
        padding: 0px 0px 25px;
      }

      .container {

        // max-width: 1255px;
        // width: 100%;
        @media only screen and (max-width: 1024px) {
          max-width: 100%;
          padding: 0;
        }

        // &:first-child{
        //   @media only screen and (min-width: 768px) and (max-width: 991px) {
        //     padding: 0;
        //   }
        // }

        @media only screen and (min-width: 1366px) and (max-width: 1599px) {
          // max-width: 1380px;
          width: 100%;
          max-width: 70% !important;
          margin: auto;
        }

        @media only screen and (min-width: 1600px) and (max-width: 1919px) {
          // max-width: 1380px;
          width: 100%;
          max-width: 70% !important;
          margin: auto;
        }

        @media only screen and (min-width: 1920px) {
          max-width: 1430px;
        }

        &.top-container {
          padding: 0;
        }
      }

      ._GB_Dashboard_Banner {

        // @media only screen and (min-width: 1600px){
        //     max-width: 1320px;
        // }
        ._GB_Dashboard_Inner {
          .profile {
            @media only screen and (max-width: 1400px) {
              margin-bottom: 0;
            }
          }

          ._beta_banner_top {
            @media only screen and (max-width: 1600px) {
              padding: 0 15px;
            }

            @media only screen and (min-width: 992px) and (max-width: 1100px) {
              flex-direction: column;
            }

            @media only screen and (max-width: 991px) {
              justify-content: flex-end;
            }
          }

          .profile_details {
            @media only screen and (max-width: 1200px) {
              flex-direction: column;
            }

            .left_section {
              @media only screen and (max-width: 1200px) {
                width: 100%;
                margin: auto;
              }

              @media only screen and (max-width: 767px) {
                order: 2;
                width: 98%;
              }

              .game_details {
                padding-top: 10px;

                @media only screen and (max-width: 1600px) {
                  padding-top: 0px;
                }

                .total {
                  @media only screen and (min-width: 768px) and (max-width: 1600px) {
                    flex: 0 0 31%;
                    max-width: 31%;
                    margin: 1% !important;
                  }

                  .items_inner {
                    @media only screen and (min-width: 768px) and (max-width: 1365px) {
                      min-height: 270px;
                    }

                    // @media only screen and (min-width: 1441px) and (max-width: 1600px) {
                    //   width: 200px;
                    // }

                    .desc-sect {
                      img {
                        @media only screen and (max-width: 1600px) {
                          height: 40px;
                        }

                        @media only screen and (max-width: 767px) {
                          height: 38px;
                        }
                      }
                    }
                  }
                }
              }

              ._Dashboard_Fortnite_Stats {
                padding: 50px 0px 0px;

                @media only screen and (max-width: 767px) {
                  padding: 0px !important;
                }

                @media only screen and (min-width: 1300px) and (max-width: 1600px) {
                  padding: 20px 0px 0px !important;
                }

                ._Fortnite_Stats_inner {
                  ._Fortnite_Stats_content {
                    .reward_session {
                      .second-section {
                        // @media only screen and (min-width: 1301px) and (max-width: 1600px) {
                        //   flex-direction: column;
                        // }
                      }
                    }
                  }
                }
              }
            }

            .right_section {
              @media only screen and (max-width: 1200px) {
                width: 85%;
                margin: auto;
              }

              @media only screen and (max-width: 1024px) {
                width: 97%;
                margin: auto;
              }

              @media only screen and (max-width: 767px) {
                width: 95%;
              }

              @media only screen and (max-width: 490px) {
                width: 100%;
                margin: auto;
              }

              .gameModeWrap {
                .most_played {
                  img {
                    height: auto;

                    @media only screen and (min-width: 1601px) {
                      height: 400px !important;
                    }
                  }
                }
              }
            }
          }

          .title-top {
            font-family: "Gilroy-Bold";
            color: #fff;
            font-size: 25px;
            line-height: 40px;

            @media only screen and (max-width: 1024px) {
              display: none;
            }

            // @media only screen and (min-width: 991px) {
            //   font-size: 30px;
            //   line-height: 35px;
            // }

            // @media only screen and (min-width: 1366px) {
            //   font-size: 25px;
            //   line-height: 35px;
            // }

            @media only screen and (min-width: 1920px) {
              font-size: 30px;
              line-height: 40px;
            }

            // @media only screen and (min-width: 1366px) and (max-width: 1600px) {
            //   font-size: 30px;
            //   line-height: 35px;
            // }


          }

          ._Fortnite_Stats_inner {
            ._Fortnite_Stats_content {
              .left_section {
                display: flex;

                @media only screen and (max-width: 390px) {
                  flex-direction: column;
                }

                .today_session {
                  flex: 0 0 46%;
                  margin-right: 2%;
                  max-width: 46%;

                  @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    margin: auto;
                    flex: 0 0 45%;
                    max-width: 45%;
                  }

                  @media only screen and (max-width: 767px) {
                    flex: 0 0 48%;
                    max-width: 48%;
                    margin: auto;
                    min-height: 140px;
                  }

                  @media only screen and (max-width: 490px) {
                    margin-left: 0;
                  }

                  @media only screen and (max-width: 390px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin: auto auto 15px;
                  }

                  .title {
                    .gameTitle {
                      font-size: 16px;

                      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                        font-size: 14px;
                        line-height: 16px;
                      }

                      @media only screen and (max-width: 767px) {
                        font-size: 12px;
                        line-height: 14px;
                      }
                    }
                  }
                }

                .reward_session {
                  flex: 0 0 52%;
                  max-width: 52%;
                  margin-bottom: 20px;

                  @media only screen and (min-width: 768px) and (max-width: 1024px) {
                    margin: auto;
                    flex: 0 0 50%;
                    max-width: 50%;
                  }

                  @media only screen and (max-width: 767px) {
                    flex: 0 0 48%;
                    max-width: 48%;
                    margin: auto;
                    min-height: 140px;
                  }

                  @media only screen and (max-width: 490px) {
                    margin-right: 0;
                  }

                  @media only screen and (max-width: 390px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                  }

                  .title-section {
                    ._title {
                      font-size: 16px;

                      @media only screen and (max-width: 767px) {
                        font-size: 12px;
                      }
                    }
                  }

                  .second-section {
                    @media only screen and (min-width: 391px) and (max-width: 767px) {
                      flex-direction: column;
                    }

                    @media only screen and (min-width: 1300px) and (max-width: 1365px) {
                      flex-direction: column !important;
                    }

                    ._Timer_left {
                      p {
                        font-size: 16px;

                        @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                          font-size: 12px;
                          line-height: 14px;
                          margin-left: 0;
                        }

                        @media only screen and (max-width: 767px) {
                          font-size: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ._beta_banner_top {
            justify-content: space-between;
          }

          ._Game_Stats_inner {
            .gameTitle {
              display: none;
            }

            ._Game_Stats_top {
              .change_game {
                .games_list {
                  img {
                    @media only screen and (max-width: 1365px) {
                      height: 50px;
                      width: 50px;
                    }

                    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                      height: 40px;
                      width: 40px;
                    }
                  }
                }

                .reward_popup {
                  .add_games_list {
                    img {
                      @media only screen and (max-width: 1365px) {
                        height: 50px !important;
                        width: 50px !important;
                      }

                      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                        height: 40px !important;
                        width: 40px !important;
                      }
                    }
                  }
                }
              }
            }
          }

          ._beta_changegame {
            ._Dashboard_Game_Stats {
              .changeGame_container {
                @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                  max-width: 100% !important;
                }
              }
            }
          }
        }
      }

      ._GameStatsBeta {

        // padding: 0 30px 50px 30px;
        @media only screen and (min-width: 768px) {
          padding: 0 0 30px;
        }

        .pteec {

          // max-width: 1200px;
          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            max-width: 70% !important;
            width: 100%;
            margin: auto;
          }

          // @media only screen and (min-width: 1600px) {
          //   max-width: 1320px;
          // }
        }
      }

      &.cymmexpanded {
        @media only screen and (min-width: 991px) and (max-width: 1919px) {
          max-width: 100%;
          width: 100%;
          margin: auto;
        }
      }
    }

    .betting-left-container {
      @media only screen and (min-width: 1366px) {
        position: fixed;
        top: auto;
      }

      .menu-section {
        .menu-nav {
          li {
            a {
              .slow {
                width: 95px;

                &.dashbaord {
                  margin-left: 1px;
                }
              }
            }
          }
        }
      }
    }

    .cymmexpanded {
      .challenge-megamenu {
        .challenge-right {
          @media only screen and (min-width: 1360px) and (max-width: 1919px) {
            padding-left: 280px;
          }

          @media only screen and (min-width: 1024px) and (max-width: 1359px) {
            padding-left: 250px;
            margin-top: 10%;
          }

          @media only screen and (min-width: 1024px) and (max-width: 1280px) {
            height: 225px;

            a {
              height: 160px;
            }
          }
        }
      }
    }
  }
}

.betting-left-container {
  .menu-section {
    .menu-nav {
      li {
        a {
          .slow {
            width: 95px;
          }
        }
      }
    }
  }
}

/***************** PlayToEarn Dashboard End ****************/

/*****************betting create challenge new page***********/
.GB_createChallenge {
  padding-top: 30px;
  padding-bottom: 100px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    padding-bottom: 50px;
  }

  // @media only screen and (min-width: 1360px) {
  //   max-width: 1010px;
  // }

  // @media only screen and (min-width: 1550px) {
  //   max-width: 1153px;
  // }

  @media only screen and (min-width: 1360px) {
    max-width: 100%;
  }

  .GB_headingBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #2d2d2d;
    padding-bottom: 25px;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
      border-bottom: none;
      padding-bottom: 0px;
    }

    .titles {
      width: 100%;

      @media only screen and (min-width: 768px) {
        max-width: 50%;
      }

      h2 {
        font-family: "Gilroy-Bold";
        font-size: 40px;
        line-height: 47px;
        text-transform: capitalize;
        color: #ffffff;

        @media only screen and (max-width: 767px) {
          font-size: 25px;
          line-height: 25px;
          text-align: center;
        }

        @media only screen and(min-width: 1366px) and(max-width: 1600px) {
          font-size: 30px;
        }
      }

      h1 {
        font-family: "Gilroy-Bold";
        font-size: 40px;
        line-height: 47px;
        text-transform: capitalize;
        color: #ffffff;

        @media only screen and (max-width: 767px) {
          font-size: 25px;
          line-height: 25px;
          text-align: center;
        }

        @media only screen and(min-width: 1366px) and(max-width: 1600px) {
          font-size: 30px;
        }
      }
    }

    .btns {
      width: 100%;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 10px;

      @media only screen and (max-width: 767px) {
        max-width: 100%;
        justify-content: center;
      }

      @media only screen and(min-width: 768px) and (max-width: 1024px) {
        margin-top: 30px;
      }

      .game-type {
        width: 100%;
        max-width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #181818;
        border-radius: 8px;
        border: 2px solid #2d2d2d;
        font-family: "Gilroy-SemiBold";
        font-size: 16px;
        line-height: 24px;
        text-align: right;
        color: #ffffff;
        text-decoration: none;
        height: 36px;
        cursor: pointer;

        &.active {
          border: 2px solid #ffc01f;
        }

        &.private {
          color: rgba(255, 255, 255, 0.3);
        }

        &.public {
          &:hover {
            border: 2px solid #ffc01f;
          }
        }
      }
    }
  }

  .challenge-tab-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0px 0px;
    border-bottom: 2px solid #2d2d2d;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      grid-area: 10px;
      border-bottom: none;
    }

    .tabs-left {
      width: 100%;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
      }

      @media only screen and (min-width: 768px) {
        max-width: 70%;
      }

      .tab-challenge {
        width: 177px;
        height: 49px;
        background: #000000;
        border-radius: 8px 8px 0px 0px;
        font-family: "Poppins-SemiBold";
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        position: relative;
        top: 4px;
        border-bottom: 2px solid #2d2d2d;
        cursor: pointer;
        margin-right: 4px;

        @media only screen and(min-width: 1366px) and(max-width: 1600px) {
          font-size: 14px;
        }

        @media only screen and (max-width: 990px) {
          width: 50%;
        }

        @media only screen and (max-width: 767px) {
          font-size: 12px;
          line-height: 16px;
        }

        &.active,
        &:hover {
          background: #e5242082;
          border-bottom: 2px solid #e52420;
        }

        // &:nth-child(1) {
        //   margin-right: 4px;
        // }
      }
    }

    .btns-right {
      min-height: 54px;
      width: 100%;
      max-width: 30%;

      @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: flex-start;
      }

      @media only screen and (min-width: 768px) {
        .titles_games {
          justify-content: flex-end;
        }
      }
    }

    .titles_games {
      display: flex;
      align-items: center;
      position: relative;
      bottom: 6px;
      width: 100%;

      @media only screen and (max-width: 767px) {
        justify-content: center;
      }

      .all-btn {
        width: 50px;
        height: 50px;
        border: 2px solid #484848;
        border-radius: 8px;
        cursor: pointer;
        padding: 4px;
        margin-right: 10px;
      }

      .summary_logo {
        display: flex;
      }

      .btn.show {
        background: transparent;
        border: none;
      }

      img {
        width: 50px;
        height: 50px;
        border: 2px solid #484848;
        border-radius: 8px;
        cursor: pointer;
        padding: 4px;
        margin: 2px;

        &:hover,
        &.active {
          background: rgba(200, 0, 0, 0.5);
          border-color: #c80000;
        }

        &.summary_filter {
          margin-left: 15px;
          padding: 12px;
        }
      }

      .live-green-btn {
        width: 100%;
        max-width: 86px;
        border: 1px solid #52dd21;
        border-radius: 8px;
        background-color: #181818;
        color: #52dd21;
        height: 40px;
        margin-left: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        span {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #52dd21;
          animation: blink 2s linear infinite;
        }

        @keyframes blink {
          0% {
            opacity: 0;
          }

          50% {
            opacity: 0.75;
          }

          100% {
            opacity: 1;
          }
        }
      }

      .btn-primary {
        background: transparent !important;
        padding: 0;
      }
    }
  }

  .challenge-listing-container {
    margin: 40px 0px 24px 0px;
    background: #181818;
    border: 2px solid #454545;
    border-radius: 14px;
    padding: 15px;

    @media only screen and(min-width: 1366px) and(max-width: 1919px) {
      margin: 13px 0px;
    }

    @media only screen and (max-width: 767px) {
      margin: 15px 0px;
    }

    .challenge-table-outer {
      height: 370px;
      overflow: auto;
      padding-right: 15px;

      @media only screen and (max-width: 767px) {
        padding-right: 10px;
      }

      .challenge-table {
        .spinner-grow {
          display: block;
          margin: 20px auto;
        }

        .head-row {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 15px;
          background: #1e1e1e;
          border-radius: 10px 10px 0px 0px;
          height: 35px;
          padding: 10px;

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            height: 25px;
          }

          @media only screen and (max-width: 767px) {
            display: none;
          }

          .reward {
            color: rgba(235, 235, 245, 0.6);
          }

          .payout {
            color: rgba(235, 235, 245, 0.6);

            .payout-popover {
              width: 15px;
              height: 15px;
              border: 1px solid #ffc01f;
              border-radius: 25px;
              font-family: "Gilroy-Bold";
              font-size: 11px;
              line-height: 100%;
              text-align: center;
              color: #ffc01f;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              margin-left: 3px;
            }
          }
        }

        .body-row {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;
          background: #121212;
          border-radius: 8px;
          padding: 10px;
          margin-bottom: 10px;

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            padding: 11px 10px;
            margin: 0;
            margin-bottom: 10px;
            border-bottom: 1px solid #000000;
          }

          .mobile-head-text {
            display: none;
          }

          @media only screen and (max-width: 990px) {
            gap: 15px;
            padding: 15px 10px;
          }

          @media only screen and (max-width: 767px) {
            flex-direction: column;
            gap: 5px;

            .mobile-head-text {
              display: block;
              color: rgba(235, 235, 245, 0.6);
              margin-right: 10px;
            }
          }

          .reward {
            color: #52dd21;
            margin-left: 8px;

            // @media only screen and (min-width: 1280px) {
            //   padding-left: 45px;
            // }

            @media only screen and (max-width: 990px) {
              display: flex;
            }
          }

          .payout {
            color: #ffc01f;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 990px) {
              display: flex;
            }

            @media only screen and (max-width: 767px) {
              padding-left: 0px;
            }

            .coin {
              display: flex;
              align-items: center;
              justify-content: center;

              // &::before {
              //   content: url("../images/coin-xs.png");
              //   position: relative;
              //   top: 2px;
              //   margin-right: 6px;
              // }
            }
          }
        }

        .challenge-details {
          width: 100%;
          max-width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          gap: 25px;

          @media only screen and (min-width: 991px) {
            max-width: 73%;
            gap: 10px;
            flex-direction: row;
          }

          // @media only screen and (min-width: 1280px) {
          //   max-width: 40%;
          // }
          // @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          //   max-width: 43%;
          // }
          @media only screen and (max-width: 990px) {
            flex-direction: row;
            gap: 10px;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
          }

          @media only screen and (max-width: 400px) {
            gap: 5px;
          }

          /* The container */
          .btn-container {
            display: block;
            position: relative;
            padding-left: 25px;
            padding-right: 10px;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            @media only screen and (max-width: 575px) {
              padding-left: 10px;
            }

            input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
            }
          }

          /* Create a custom radio button */
          .checkmark {
            position: absolute;
            top: -15px;
            left: 0px;
            height: 30px;
            width: 30px;
            background-color: #000000;
            border-radius: 50%;
            border: 2px solid #2d2d2d;

            @media only screen and (max-width: 1919px) {
              height: 22px;
              width: 22px;
              top: -10px;
            }

            // @media only screen and (max-width: 990px){
            //   height: 20px;
            //   width: 20px;
            //   top: -9px;
            //   left: 0px;
            // }
            @media only screen and (max-width: 400px) {
              height: 16px;
              width: 16px;
            }

            &::after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          /* When the radio button is checked, add a blue background */
          .btn-container input:checked~.checkmark {
            background-color: #000000;
          }

          /* Show the indicator (dot/circle) when checked */
          .btn-container input:checked~.checkmark:after {
            display: block;
          }

          /* Style the indicator (dot/circle) */
          .btn-container .checkmark:after {
            top: 7px;
            left: 7px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #40ab00;

            @media only screen and(min-width: 1366px) and(max-width: 1919px) {
              top: 3px;
              left: 3px;
            }

            @media only screen and (max-width: 1365px) {
              top: 4px;
              left: 4px;
              width: 10px;
              height: 10px;
            }

            @media only screen and (max-width: 400px) {
              height: 6px;
              width: 6px;
              top: 3px;
              left: 3px;
            }
          }

          h3 {
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.04em;
            margin-bottom: 0px;
            color: #52dd21;
            gap: 10px;
            display: flex;
            text-transform: uppercase;

            @media only screen and (min-width: 1366px) and (max-width: 1919px) {
              font-size: 12px;
            }

            @media only screen and (min-width: 768px) and (max-width: 990px) {
              font-size: 14px;
            }

            @media only screen and (min-width: 991px) {
              width: 100%;
              max-width: 85%;
              flex-direction: row;
              gap: 15px;
            }

            @media only screen and (max-width: 767px) {
              flex-direction: row;
              align-items: center;
              font-size: 12px;
            }
          }

          span {
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 100%;
            letter-spacing: 0.04em;
            color: #ffc01f;
            text-transform: capitalize;

            @media only screen and (min-width: 1366px) and (max-width: 1919px) {
              font-size: 12px;
            }

            @media only screen and (max-width: 767px) {
              font-size: 12px;
            }
          }

          // img {
          //   @media only screen and (max-width: 400px) {
          //     width: 22px;
          //     height: 22px;
          //   }
          // }
        }

        .body-row-right {
          display: contents;

          @media only screen and (max-width: 767px) {
            max-width: 73%;
            width: 100%;
            margin-right: 0px;
            justify-content: center;
            display: flex;
            flex-direction: column;
          }

          @media only screen and (max-width: 400px) {
            margin-left: 35px;
          }

          @media only screen and (min-width: 400px) and (max-width: 600px) {
            max-width: 65%;
          }
        }

        .challenge-time {
          width: 100%;
          max-width: 100%;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.04em;
          color: #ffffff;
          opacity: 0.88;
          text-align: center;

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            font-size: 12px;
          }

          @media only screen and (max-width: 767px) {
            font-size: 12px;
            text-align: left;
          }

          &.timer-ico {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;

            @media only screen and (max-width: 767px) {
              justify-content: flex-start;
            }

            @media only screen and (min-width: 991px) {
              align-items: center;
            }

            &::before {
              content: url("../images/timer.png");
            }
          }

          @media only screen and (min-width: 991px) {
            max-width: 20%;
          }

          @media only screen and (min-width: 1280px) {
            max-width: 28%;
          }

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            max-width: 32%;
          }
        }

        .reward,
        .payout {
          width: 100%;
          max-width: 109%;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 100%;
          letter-spacing: 0.04em;
          opacity: 0.88;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            font-size: 12px;
            max-width: 11%;
          }

          @media only screen and (min-width: 768px) and (max-width: 990px) {
            max-width: 11%;
          }

          @media only screen and (min-width: 991px) {
            max-width: 10%;
          }

          @media only screen and (max-width: 767px) {
            justify-content: flex-start !important;
            font-size: 14px;
            margin-bottom: 5px;
          }
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
        margin-right: 10px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #000000;
        border-radius: 8px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #535353;
        border-radius: 8px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #e52420;
      }
    }
  }

  .betting-initial {
    // background: #181818;
    // border: 2px solid #454545;
    // border-radius: 14px;
    // padding: 40px 30px;

    @media only screen and (max-width: 767px) {
      text-align: center;
    }

    h3 {
      font-family: "Gilroy-Bold";
      font-size: 30px;
      line-height: 100%;
      color: #ffffff;
      opacity: 0.88;
    }

    p {
      font-family: "Gilroy-Bold";
      font-size: 16px;
      line-height: 100%;
      letter-spacing: 0.04em;
      color: #ffffff;
      opacity: 0.88;
    }

    .create-challenge-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 20px;
      }

      .bet-amount-block {
        width: 100%;
        max-width: 550px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #181818;
        padding: 3px 4px;
        border-radius: 11px;
        gap: 10px;

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          max-width: 58%;
        }

        @media only screen and (max-width: 1280px) {
          max-width: 60%;
        }

        @media only screen and (max-width: 1200px) {
          max-width: 100%;
        }

        @media only screen and (max-width: 767px) {
          gap: 10px;
          padding: 15px 4px;
        }

        @media only screen and (max-width: 400px) {
          gap: 5px;
        }

        .form-control {
          width: 100%;
          max-width: 181px;
          height: 50px;
          background: #000000;
          border: 2px solid #2d2d2d;
          border-radius: 12px;
          font-family: "Gilroy-Bold";
          font-size: 26px;
          line-height: 100%;
          text-align: center;
          color: #fe5242;

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 20px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1600px) {
            max-width: 240px;
          }

          @media only screen and (max-width: 575px) {
            max-width: 375px;
            font-size: 18px;
          }
        }

        .up-down {
          background-image: url("../images/up-down.png");
          height: 20px;
          min-width: 21px;
          cursor: pointer;
          display: inline-block;
          background-size: cover;

          //margin-right: 4px;
          @media only screen and (max-width: 400px) {
            min-width: 10px;
            height: 11px;
          }
        }

        .bet-amount-unit {
          width: 100%;
          max-width: 135px;

          @media only screen and (max-width: 400px) {
            max-width: 98px;
          }

          .dropdown-toggle {
            background: #262627;
            border-radius: 8px;
            width: 100%;
            max-width: 165px;
            height: 50px;
            border: none;
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 16px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and(min-width: 1366px) and(max-width: 1600px) {
              max-width: 125px;
            }

            @media only screen and (max-width: 575px) {
              max-width: 98px;
            }

            &:after {
              content: url("../images/betting/drop-arrow.png");
              width: 12px;
              border: none;
              margin-left: auto;
            }

            &.before-coin {
              display: flex;
              align-items: center;
              gap: 6px;
              justify-content: flex-start;

              &::before {
                content: url("../images/betting/coin-gamebeef.png");
                top: 1px;
                position: relative;
              }
            }

            &.sol-coin {
              display: flex;
              align-items: center;
              gap: 6px;

              &::before {
                content: url("../images/betting/solana_coin.png");
                top: 1px;
                position: relative;
              }
            }
          }

          .dropdown-menu {
            @media only screen and (max-width: 575px) {
              min-width: 98px;
            }

            .dropdown-item {
              padding: 5px 10px;
              display: flex;
              align-items: center;

              @media only screen and (max-width: 575px) {
                gap: 5px;
              }

              .usdt_coin,
              .usdc_coin,
              .sol-coin {
                margin-right: 5px;
              }
            }

            .before-coin {
              display: flex;
              align-items: center;
              gap: 6px;

              &::before {
                content: url("../images/betting/coin-gamebeef.png");
                top: 3px;
                position: relative;
              }
            }
          }
        }

        p {
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 21px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 0px;

          @media only screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 14px;
          }
        }

        .black-box {
          background: #000000;
          border-radius: 8px;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            @media only screen and (max-width: 400px) {
              width: 22px;
              height: 22px;
            }
          }
        }
      }

      .green-btn {
        width: 100%;
        max-width: 253px;
        height: 56px;
        background: #52dd21;
        border-radius: 10px;
        font-family: "Poppins-Bold";
        color: #000;
        font-size: 15px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        .spinner-border {
          margin-right: 5px;
        }

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          max-width: 225px;
        }

        @media only screen and (max-width: 1200px) {
          max-width: 235px;
          padding: 0px 10px;
        }

        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }

        &::after {
          content: url("../images/btn-black-arrow.png");
          margin-left: 10px;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}

.no-data {
font-family: "Gilroy-Bold";
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  display: block;
  /* padding-top: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #842029;
  padding: 20px;
  max-width: 80%;
  margin: auto;
  border: solid 1px red;
  border-radius: 10px;
  margin-top: 20px;
}

#payout-popup {
  z-index: 9999;
  border: 1.5px solid #c80000;
  border-radius: 12px;

  .popover-body {
    background: #640000;
    border-radius: 12px;
    color: #ffffff;
    font-family: "Gilroy-Bold";
    font-size: 14px;
    line-height: 100%;
    text-align: center;
  }

  .popover-arrow {
    &::after {
      content: url("../images/tooltip_arrow.png");
      border-top: none;
      top: -5px;
      left: -8px;
    }
  }
}

#sidebar-popup {
  background: #640000;
  height: 38px;
  width: 145px;
  border: 2px solid #c80000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gilroy-Bold";
  z-index: 9999;
  color: #ffffff;
  bottom: -10px !important;

  @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    width: 115px;
    height: 32px;
  }

  .popover-arrow {
    &::after {
      content: url("../images/tooltip_arrow.png");
      border-top: none;
      top: -1px;
      left: -12px;
    }
  }

  .popover-body {
    font-family: "Gilroy-Bold";
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    color: #ffffff;

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      font-size: 12px;
    }
  }
}

/*****************betting create challenge new page***********/
body {
  background: #000;
}

.betting_top {
  @media only screen and (min-width: 992px) {
    margin-top: 55px;
  }
}

.challenge_tops {
  .betting-left-container {
    top: 140px !important;

    @media only screen and (min-width: 1366px) {
      position: fixed;
      top: auto;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      top: 110px !important;
    }

    @media only screen and (min-width: 1500px) and (max-width: 1919px) {
      height: 100vh;
    }

    &.expanded {
      .big-sidebar {
        width: 240px;

        @media only screen and (min-width: 1360px) and (max-width: 1919px) {
          width: 200px;
        }

        .menu-section {
          .menu-nav {
            li {
              .menuactive {
                background-color: #2d2d2d !important;
              }
            }
          }
        }
      }
    }
  }

  .GB_createChallenge {
    padding-top: 100px;

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      padding-top: 80px;
    }
  }

  .container {
    @media only screen and (max-width: 1024px) {
      max-width: 100% !important;
      padding: 0;
    }

    // @media only screen and (min-width: 1025px) and (max-width: 1365px){
    //   max-width: 70% !important;
    // }
    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      max-width: 70% !important;
    }
  }
}

.mob_sidebar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #181818;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    display: none !important;
  }

  a {
    width: 100%;
    text-decoration: none;
  }

  .mob_sidebardash {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    width: 100%;
    padding: 9px 0px;

    &.active {
      background: #2d2d2d;
    }

    &.inactive {
      opacity: 0.5;
    }

    p {
      margin: 0;
      font-family: "Gilroy-Bold";
      font-size: 10px;
      line-height: 10px;
    }

    img {
      margin-bottom: 8px;
      width: 40px;
      height: 40px;
    }
  }
}

/****************** mob-leaderboard *******************/
.mob-leaderboard {
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
  }

  .leaderboard-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #212121;
    border-radius: 10px;
    padding: 15px 10px;
    margin-bottom: 10px;

    .left-sec {
      display: flex;
      align-items: center;

      .rank-sec {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &.goldBox {
          background-color: #fda829;
        }

        &.silverBox {
          background-color: #444444;
        }

        &.bronzeBox {
          background-color: #d99246;
        }

        &.redBox {
          background-color: #e52420;
          color: #fff;
          font-family: "Gilroy-Bold";
        }
      }

      .profile-name {
        font-family: "Gilroy-Bold";
        font-size: 14px;
        line-height: 14px;
        color: #fff;
        margin-left: 10px;
      }

      .profile-img {
        img {
          height: 40px;
          width: 40px;
          border: 1px solid #9d9da3;
          border-radius: 50px;
        }
      }
    }

    .right-sec {
      display: flex;
      align-items: center;

      img {
        margin-right: 5px;
      }

      .coins-earned {
        font-family: "Gilroy-Bold";
        font-size: 12px;
        line-height: 12px;
        color: #ffc01f;
      }
    }
  }

  .view-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 10px;

    a {
      border: 2px solid #292929;
      border-radius: 10px;
      padding: 12px 40px;
      text-decoration: none;
      color: #fff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      max-width: 160px;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.webdashleaderboard {
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

/****************** mob-leaderboard End *******************/

.GB_BettingHistory {

  // @media only screen and (min-width: 1920px){
  //     max-width: 1920px;
  //     margin: auto;
  // }
  .bettingHistory_container {
    width: 100%;

    @media only screen and (min-width: 1025px) and (max-width: 1919px) {
      max-width: 70% !important;
      width: 100%;
      margin: auto;
    }
  }

  .GB_BettingScoreboardinner {
    // display: flex;

    //  @media only screen and (min-width: 1920px) {
    //   display: block;
    // }
    @media only screen and (min-width: 1025px) {
      display: flex;
    }

    @media only screen and (max-width: 767px) {
      display: block;
      padding-top: 20px;
    }

    padding-top: 85px;

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      padding-top: 36px;
    }

    &.GB_BettingHistoryInner {
      @media only screen and (max-width: 991px) {
        padding-top: 20px !important;
      }

      @media only screen and (min-width: 1366px) and (max-width: 1919px) {
        padding-top: 10px !important;
      }

      padding-top: 36px !important;
    }

    .column-right {
      .GB_BettingScoreboardtable {
        padding: 5px 25px;

        @media only screen and (max-width: 767px) {
          padding: 0px 25px;
        }

        @media only screen and (min-width: 1366px) and (max-width: 1919px) {
          padding: 5px 10px;
        }

        td {
          @media only screen and (max-width: 480px) {
            font-size: 11px !important;
          }
        }

        .title {
          display: flex;
          gap: 10px;
          padding: 20px 0px;
          align-items: center;

          @media only screen and (max-width: 767px) {
            justify-content: center;
            padding: 0px 0px 20px 0px;
          }

          h2 {
            font-size: 40px;
            line-height: 46px;
            font-family: "Gilroy-Bold";
            color: #fff;

            @media only screen and (max-width: 767px) {
              font-size: 28px;
              line-height: 46px;
            }
          }
        }

        .GB_device_th {
          font-size: 16px;
          line-height: 16px;
          font-family: "Gilroy-Bold";
          color: rgba(235, 235, 245, 0.6);
        }

        .playerDetails {
          .playerInfo {
            display: flex;
            align-items: center;
          }
        }

        .pointDetails {
          color: #ffc01f !important;

          .playerInfo {
            @media only screen and (max-width: 767px) {
              display: block;
            }

            img {
              margin-right: 10px;
            }
          }
        }

        .kills {
          color: #31ff1f !important;

          .playerInfo {
            @media only screen and (max-width: 767px) {
              display: block;
            }

            img {
              margin-right: 10px;

              &.bh_ticketimg {
                margin-right: 0px !important;
              }
            }
          }
        }
      }

      .GB_Chat {
        padding: 25px;

        .bets_first {
          .stream-live {
            .chat_image {
              width: 100%;

              @media only screen and(min-width: 1300px) {
                height: 828px;
                object-fit: cover;
                border: 2px solid #2d2d2d;
                border-radius: 20px;
              }
            }
          }

          @media only screen and(max-width: 1299px) {
            width: 100%;
          }
        }

        .bets_sec {
          border: 2px solid #2d2d2d;
          border-radius: 20px;
          background: #181818;
          padding: 0;

          @media only screen and(max-width: 1299px) {
            width: 100%;
            max-width: 395px;
            margin: 30px auto;
          }

          .no_data {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Gilroy-Bold";
            color: #ffffff;
            opacity: 0.5;
          }

          .place_Bets {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 13px 20px 9px 20px;

            @media only screen and(max-width: 374px) {
              padding: 13px 10px 9px 10;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 13px 10px 9px 10;
            }

            h3 {
              font-size: 18px;
              line-height: 22px;
              font-family: "Gilroy-Bold";
              color: #fff;
              margin: 0;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }

            .place_Betsright {
              background: #0c0c0c;
              padding: 3px 13px;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              border-color: #000000;

              img {
                margin-right: 10px;
              }

              span {
                font-size: 16px;
                line-height: 18px;
                font-family: "Gilroy-Bold";
                color: #ffffff;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }
              }
            }

            .dropdown-menu {
              min-width: 280px !important;
              transform: translate(0px, 34.5556px) !important;
              width: 100%;

              .dropdown-item {
                margin-right: 0px;

                img {
                  margin: 0px 5px 0px 8px;
                }
              }
            }
          }

          .bets_profile {
            display: flex;
            justify-content: space-between;
            padding: 8.5px 30px;
            border-top: 1px solid #2d2d2d;
            align-items: center;

            @media only screen and(max-width: 374px) {
              padding: 8.5px 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 8.5px 10px;
            }

            .bets_profileleft {
              display: flex;
              align-items: center;
              gap: 10px;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                gap: 5px;
              }

              img {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                object-fit: cover;
              }

              p {
                font-size: 14px;
                line-height: 16px;
                font-family: "Gilroy-Bold";
                color: rgba(235, 235, 245, 0.6);
                margin: 0;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }

                .no {
                  color: #e52420;
                  text-transform: uppercase;
                }

                .yes {
                  color: #52dd21;
                  text-transform: uppercase;
                }
              }
            }

            .bets_profileright {
              img {
                margin-right: 10px;
                width: 23px;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  margin-right: 5px;
                }
              }

              span {
                font-size: 14px;
                line-height: 16px;
                font-family: "Gilroy-Bold";
                color: #ffc01f;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }
              }
            }
          }

          .bets_win {
            padding: 12px 30px;
            border-top: 1px solid #2d2d2d;

            @media only screen and(max-width: 374px) {
              padding: 12px 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 12px 10px;
            }

            .win_left {
              font-size: 14px;
              line-height: 16px;
              font-family: "Gilroy-Bold";
              color: #ffc01f;
              display: flex;
              gap: 5px;
              align-items: center;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              &.three_success {
                background: rgba(0, 0, 0, 0.6);
              }

              @media only screen and (min-width: 991px) and (max-width: 1260px) {
                font-size: 10px;
              }

              @media only screen and (min-width: 1301px) and (max-width: 1400px) {
                font-size: 12px;
              }

              span {
                color: #52dd21;
                cursor: pointer;
              }

              img {
                width: 20px;
              }
            }

            p {
              font-size: 14px;
              line-height: 16px;
              font-family: "Gilroy-Bold";
              color: rgba(235, 235, 245, 0.6);
              margin-top: 3px;
              margin-bottom: 0px;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }
          }

          .bets_alert {
            padding: 11px 30px;
            border-top: 1px solid #2d2d2d;

            @media only screen and(max-width: 374px) {
              padding: 11px 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 11px 10px;
            }

            p {
              font-size: 14px;
              line-height: 16px;
              font-family: "Gilroy-Bold";
              color: #e52420;
              margin: 0;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }
          }

          .bets_Timer {
            background-color: #52dd21;
            padding: 7px 20px;
            margin-bottom: 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media only screen and(max-width: 374px) {
              padding: 7px 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 7px 10px;
            }

            .bets_count {
              ul {
                // text-align: center;
                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                font-size: 29px;
                line-height: 33px;
                font-family: "Gilroy-Bold";

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 15px;
                }

                li {
                  display: inline-block;
                  list-style-type: none;
                  font-size: 10px;
                  line-height: 11px;
                  font-family: "Gilroy-Bold";
                  color: #000000;
                  padding: 0px 3px;

                  @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                    font-size: 8px;
                  }

                  span {
                    display: block;
                    font-size: 29px;
                    line-height: 33px;
                    font-family: "Gilroy-Bold";
                    color: #000000;

                    @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                      font-size: 14px;
                    }

                    @media only screen and (min-width: 1360px) and (max-width: 1650px) {
                      font-size: 21px;
                    }

                    @media only screen and(max-width: 375px) {
                      font-size: 25px;
                    }
                  }
                }
              }
            }

            .bets_Timeright {
              .bets_Timersub {
                font-size: 13px;
                line-height: 20px;
                font-family: "Poppins-SemiBold";
                color: #000000;
                margin: 0;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 8px;
                }

                @media only screen and(max-width: 375px) {
                  font-size: 10px;
                }
              }

              .bets_Timersupersub {
                font-size: 10px;
                line-height: 10px;
                font-family: "Poppins-SemiBold";
                color: #000000;
                margin: 0;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 8px;
                }

                @media only screen and(max-width: 375px) {
                  font-size: 8px;
                }
              }
            }
          }

          .bets_placingbtn {
            text-align: center;
            border-top: 1px solid #2d2d2d;

            a {
              background-color: #e52420;
              background-image: linear-gradient(90deg,
                  #6d1513,
                  #e52420,
                  #6d1513);
              border: 2px solid #fff;
              border-radius: 10px;
              color: #fff;
              cursor: pointer;
              font-family: "Poppins-Bold";
              font-size: 17px;
              letter-spacing: 0.04em;
              line-height: 17px;
              margin: auto;
              padding: 17px 10px;
              text-align: center;
              text-decoration: none;
              text-transform: uppercase;
              width: 100%;
              max-width: 178px;
              margin: auto;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }

              &:after {
                content: url("../images/arrow.png");
                padding-left: 10px;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }

          .bets_progressbar {
            background-color: #000000;
            padding: 17px 20px;
            margin: 0 20px 14px;
            border-radius: 10px;

            @media only screen and(max-width: 374px) {
              padding: 17px 10px;
              margin: 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 17px 10px;
              margin: 10px;
            }

            h4 {
              font-size: 16px;
              line-height: 18.75px;
              font-family: "Gilroy-Bold";
              color: #ffffff;
              text-align: center;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }

            .progress {
              background-color: #e52420;
              height: 5px;

              .progress-bar {
                background-color: #52dd21;
              }
            }

            .progressbar_Top {
              font-size: 13px;
              line-height: 13px;
              font-family: "Poppins-SemiBold";
              color: #52dd21;
              display: flex;
              justify-content: space-between;
              padding-bottom: 5px;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }

              span {
                color: #e52420;
              }
            }

            .progressbar_bottom {
              display: flex;
              justify-content: space-between;
              padding-top: 5px;

              .progressbar_bottomleft {
                font-size: 13px;
                line-height: 13px;
                font-family: "Poppins-SemiBold";
                color: #97979d;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }

                span {
                  color: #52dd21;
                }
              }

              .progressbar_bottomright {
                font-size: 13px;
                line-height: 13px;
                font-family: "Poppins-SemiBold";
                color: #97979d;
                    text-align: right;
                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }

                span {
                  color: #e52420;
                }
              }
            }
          }

          .bets_feed {
            display: flex;
            justify-content: space-between;
            padding: 0px 20px 12px 20px;
            align-items: center;

            @media only screen and(max-width: 374px) {
              padding: 10px;
            }

            @media only screen and (min-width: 1300px) and (max-width: 1650px) {
              padding: 10px;
            }

            .bets_feedleft {
              font-size: 20px;
              line-height: 23.44px;
              font-family: "Gilroy-Bold";
              color: #ffffff;

              @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                font-size: 12px;
              }

              @media only screen and(max-width: 374px) {
                font-size: 12px;
              }
            }

            .bets_feedright {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              align-items: center;

              .bets_feedrightnumber {
                font-size: 14px;
                line-height: 14px;
                font-family: "Gilroy-Bold";
                color: #ffffff;
                background-color: #000000;
                padding: 7px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                gap: 7px;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }

                img {
                  cursor: pointer;
                }

                span {
                  cursor: pointer;
                }
              }

              .bets_feedrightpeople {
                font-size: 14px;
                line-height: 13px;
                font-family: "Gilroy-Bold";
                color: #ffffff;
                background-color: #000000;
                padding: 7px;
                border-radius: 6px;
                display: flex;
                align-items: center;
                gap: 7px;

                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 12px;
                }

                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }
              }
            }
          }

          .needScroll {
            height: 350px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 10px;

              @media only screen and (max-width: 767px) {
                width: 5px;
              }
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #313131;
              border-radius: 6px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #535353;
              border-radius: 6px;
              max-height: 131px !important;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #535353;
            }
          }
        }
      }
    }

    &.GB_BettingScoreboardinnersummary {
      .betting-left-container {
        @media only screen and (min-width: 1366px) {
          position: fixed;
          top: auto;
        }

        &.expanded {
          .big-sidebar {
            width: 240px;

            @media only screen and (min-width: 1360px) and (max-width: 1919px) {
              width: 200px;
            }
          }
        }
      }

      @media only screen and (min-width: 1366px) {
        display: block !important;
      }

      @media only screen and(min-width: 1366px) and(max-width: 1600px) {
        padding-top: 54px;
      }
    }

    //betting summary remove next day
    &.cymmexpanded {
      .challenge-megamenu {
        @media only screen and (min-width: 991px) and (max-width: 1919px) {
          position: absolute;
          width: 100%;
        }

        .challenge-right {
          @media only screen and (min-width: 991px) and (max-width: 1919px) {
            padding-left: 280px;
          }
        }
      }
    }

    &.cymmcollapsed {
      .challenge-megamenu {
        @media only screen and (min-width: 1360px) and (max-width: 1919px) {
          position: absolute;
          width: 100%;
        }

        .challenge-right {
          @media only screen and (min-width: 1360px) and (max-width: 1919px) {
            padding-left: 140px;
          }
        }
      }
    }

    .create_challenge_inner {
      max-width: 100%;

      @media only screen and (min-width: 991px) and (max-width: 1365px) {
        width: 100%;
        max-width: 100%;
      }

      // @media only screen and (min-width: 1025px) and (max-width: 1365px){
      //   max-width: 70%;
      // }
    }
  }
}

.GB_Bettingtitles {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    width: 100%;
    max-width: 768px;
    margin: auto;
  }

  // @media only screen and (min-width: 768px) and (max-width: 991px) {
  //   justify-content: space-around;
  // }
  .title {
    padding: 0 !important;
  }

  .titles {
    h2 {
      font-size: 40px;
      line-height: 46px;
      font-family: "Gilroy-Bold";
      color: #fff;

      @media only screen and (max-width: 991px) {
        font-size: 20px;
      }

      @media only screen and(min-width: 1366px) and(max-width: 1919px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }

  .titles_games {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 991px) {
      width: 100%;
    }

    // @media only screen and (max-width: 991px) {
    //   // justify-content: space-between;
    //   width: 100%;
    // }

    .all-btn {
      width: 80px;
      height: 50px;
      border: 2px solid #484848;
      border-radius: 8px;
      cursor: pointer;
      padding: 4px;
      margin-right: 10px;
      font-family: "Gilroy-Bold";

      &:hover {
        background: rgba(200, 0, 0, 0.5) !important;
        border-color: #c80000;
      }

      &.active {
        background: rgba(200, 0, 0, 0.5) !important;
        border-color: #c80000;
      }
    }

    .summary_gameleft {
      display: flex;
    }

    .summary_logo {
      display: flex;
    }

    .btn.show {
      background: transparent;
      border: none;
    }

    img {
      width: 50px;
      height: 50px;
      border: 2px solid #484848;
      border-radius: 8px;
      cursor: pointer;
      padding: 4px;
      margin: 2px;

      &:hover {
        background: rgba(200, 0, 0, 0.5);
        border-color: #c80000;
      }

      &.active {
        background: rgba(200, 0, 0, 0.5);
        border-color: #c80000;
      }

      &.summary_filter {
        margin-left: 15px;
        padding: 12px;
      }

      @media only screen and(min-width: 1366px) and(max-width: 1919px) {
        width: 50px;
        height: 50px;
      }
    }

    .qrcode {
      width: auto;
      height: auto;
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 0;
    }

    .qrcode-item {
      p {
        margin-bottom: 0;
      }
    }

    .dropdown-menu {
      transform: translate(-93px, 68px) !important;

      @media only screen and(max-width: 991px) {
        transform: translate(0px, 64px) !important;
      }
    }

    .dropdown-item {
      padding: 8px 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.active {
        background: #c80000;
        border-radius: 14px;
      }
    }

    .btn-primary {
      background: transparent !important;
      padding: 0;
      box-shadow: none;
    }

    .summary_logofilter {
      .dropdown {
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.GB_BettingSummary {
  padding: 5px 25px;

  @media only screen and (min-width: 1920px) {
    padding: 16px 0;
  }

  @media only screen and (max-width: 991px) {
    padding: 0px 20px 14px 20px;
    margin-bottom: 0px;
  }

  @media only screen and(min-width: 1366px) and(max-width: 1919px) {
    margin-bottom: 10px;
    padding: 5px 10px;
  }

  margin-bottom: 30px;

  .box_wh {
    border-bottom: 1px solid #2d2d2d;
    padding-bottom: 30px;

    @media only screen and(min-width: 1366px) and(max-width: 1919px) {
      padding-bottom: 15px;
    }

    @media only screen and (max-width: 767px) {
      display: block;
    }

    @media only screen and (max-width: 991px) {
      border-bottom: 0px solid #2d2d2d;
      padding-bottom: 0px;
    }

    .sum_mob {
      @media only screen and (max-width: 991px) {
        width: 100%;
        max-width: 100%;
        margin: 10px auto;
      }

      .items_inner {
        background: #181818;
        border: 2px solid #2d2d2d;
        border-radius: 20px;
        width: 100%;
        padding: 25px;
        min-height: 322px;
        justify-content: center;

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          min-height: 230px;
          padding: 15px 20px;
          justify-content: space-between;
        }

        @media only screen and (max-width: 991px) {
          min-height: 84px;
        }

        @media only screen and (min-width: 992px) and (max-width: 1316px) {
          min-height: 340px;
        }

        @media only screen and (max-width: 991px) {
          flex-direction: row !important;
          padding: 5px;
          max-width: 768px;
          margin: auto;
          justify-content: space-between;
          padding: 5px 20px;
        }
      }

      .desc_sec {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        min-height: 200px;
        justify-content: space-between;

        &.fetching-spinner {
          justify-content: center;
        }

        @media only screen and (max-width: 991px) {
          flex-direction: row !important;
          min-height: auto;
          margin: 0;
          text-align: left;
        }

        @media only screen and(min-width: 1366px) and(max-width: 1919px) {
          min-height: 100px;
          justify-content: center;
          margin-bottom: 12px;
        }

        img {
          height: 100px;
          margin-bottom: 20px;
          width: 100px;
          object-fit: contain;

          @media only screen and (max-width: 991px) {
            height: 50px;
            width: 50px;
            margin: 0;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            height: 46px;
            width: 46px;
            margin-bottom: 14px;
          }
        }

        .title {
          font-family: "Gilroy-SemiBold";
          font-size: 20px;
          line-height: 24px;
          color: #fff;

          @media only screen and (min-width: 1025px) and (max-width: 1050px) {
            font-size: 16px;
          }

          @media only screen and (max-width: 991px) {
            font-size: 12px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 14px;
            margin-bottom: 7px;
          }
        }

        .tokens {
          font-family: "Gilroy-SemiBold";
          font-size: 30px;
          line-height: 36px;
          color: #fff;
          margin: 5px;

          @media only screen and (max-width: 991px) {
            font-size: 16px;
            line-height: 10px;
            margin: 0px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 24px;
            line-height: 13px;
          }
        }

        .token_dollar {
          font-family: "Gilroy-SemiBold";
          font-size: 30px;
          line-height: 36px;
          color: #eca418;

          @media only screen and (max-width: 991px) {
            font-size: 12px;
            line-height: 24px;
          }

          @media only screen and(min-width: 1366px) and(max-width: 1919px) {
            font-size: 14px;
          }
        }
      }

      .game_drop {
        background-color: #050505;
        border-radius: 16px;
        border-color: #050505;
        color: #fff;
        padding: 10px 15px;
        font-family: "Gilroy-SemiBold";
        font-size: 20px;
        line-height: 24px;
        width: 90%;

        @media only screen and (min-width: 1025px) and (max-width: 1050px) {
          width: 100%;
        }

        @media only screen and (max-width: 991px) {
          padding: 2px 9px;
          font-size: 12px;
          width: 100%;
          max-width: 110px;
        }

        @media only screen and (min-width: 1366px) and (max-width: 1600px) {
          padding: 10px 10px;
          font-size: 12px;
        }
      }
    }
  }
}

.mob_tokens {
  @media only screen and (max-width: 991px) {
    margin-left: 20px;
  }
}

._GB_bettingsummarytop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 25px;

  @media only screen and (max-width: 1024px) {
    padding: 10px 0px 0px;
    align-items: unset;
  }

  @media only screen and (min-width: 1920px) {
    padding: 16px 0;
  }

  @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    padding: 16px 10px 0px;
  }

  gap: 20px;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
  }

  .bettingsummary_topleft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background: #121212;
    border: 2px solid #2d2d2d;
    border-radius: 10px;
    width: 100%;
    padding: 3px 6px;
    margin-bottom: 10px;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }

    @media only screen and (max-width: 1024px) {
      // display: block;
      text-align: left;
      // max-width: 768px;
      padding: 6px;
      width: 100% !important;
      max-width: 90%;
      margin: auto;
    }

    @media only screen and (max-width: 768px) {
      display: block;
    }

    @media only screen and (max-width: 475px) {
      padding: 4px;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      padding: 5px 6px;
      // margin-bottom: 0px;
    }

    &.topleft_success {
      background: #52dd21;
    }

    .bettingsummary_topleftonemain {
      .bettingsummary_topleftone {
        font-family: "Gilroy-Bold";
        font-size: 18px;
        line-height: 18px;

        @media only screen and (min-width: 992px) and (max-width: 1260px) {
          font-size: 14px;
        }

        @media only screen and (min-width: 1366px) and (max-width: 1600px) {
          font-size: 14px;
          line-height: 15px;
        }

        @media only screen and (max-width: 991px) {
          font-size: 12px;
        }

        &.one_success {
          color: #000000;
        }

        .bettingsummary_topleftonesub {
          display: flex;
          gap: 10px;
          align-items: center;

          @media only screen and (max-width: 991px) {
            justify-content: left;
            padding: 0px;
            max-width: 100%;
            margin: auto;
          }

          img {
            @media only screen and (min-width: 1366px) and (max-width: 1919px) {
              height: 26px;
            }
          }

          p {
            margin: 0;

            @media only screen and (max-width: 991px) {
              line-height: 12px;
              font-size: 12px;
            }

            span {
              font-family: "Gilroy-SemiBold";
              font-size: 14px;
              line-height: 14px;

              @media only screen and (max-width: 991px) {
                justify-content: left;
                padding: 0px;
                font-size: 10px;
                //max-width: 350px;
                //margin: auto;
              }

              @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                font-size: 10px;
                line-height: 12px;
              }
            }
          }
        }
      }
    }

    .bettingsummary_toplefttwomain {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      gap: 5px;
      justify-content: flex-end;
      width: 42%;

      @media only screen and (min-width: 1550px) {
        width: 50%;
      }

      @media only screen and (min-width: 1366px) and (max-width: 1549px) {
        width: 57%;
      }

      @media only screen and (min-width: 1024px) and (max-width: 1365px) {
        width: 75%;
      }

      @media only screen and (min-width: 991px) and (max-width: 1024px) {
        width: 100%;
      }

      @media only screen and (max-width: 1024px) {
        // display: block;
        width: 100%;
        max-width: 100%;
        text-align: center;
      }

      @media only screen and (max-width: 1024px) {
        margin-top: 5px;
      }

      .bettingsummary_toplefttwo {
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 15px;

        @media only screen and (max-width: 991px) {
          font-size: 10px;
          line-height: 10px;
          font-family: "Gilroy-Bold";
        }

        &.two_success {
          color: #000000;
        }

        @media only screen and (min-width: 992px) and (max-width: 1260px) {
          font-size: 12px;
        }

        @media only screen and (min-width: 1301px) and (max-width: 1600px) {
          font-size: 12px;
        }
      }

      .bettingsummary_topleftthree {
        // font-family: "Gilroy-Bold";
        // font-size: 16px;
        // line-height: 17px;
        // background-color: #000000;
        // padding: 5px 10px 5px 7px;
        // border-radius: 15px;
        font-family: "Gilroy-semibold";
        font-size: 14px;
        line-height: 16px;
        background-color: #000000;
        padding: 5px 15px 5px 15px;
        border-radius: 9px;
        min-height: 43px;

        @media only screen and (max-width: 1024px) {
          max-width: 23%;
          flex: 0 0 23%;
          margin: 10px auto;
        }

        @media only screen and (max-width: 990px) {
          font-size: 12px;
        }

        @media only screen and (max-width: 475px) {
          font-size: 10px;
          max-width: 22%;
          flex: 0 0 22%;
        }

        &.three_success {
          background: rgba(0, 0, 0, 0.6);
        }

        @media only screen and (min-width: 991px) and (max-width: 1260px) {
          font-size: 10px;
        }

        @media only screen and (min-width: 1301px) and (max-width: 1400px) {
          font-size: 12px;
        }

        img {
          margin-right: 6px;
          width: 16px;
        }

        @media only screen and (max-width: 991px) {
          margin: 0px auto;
        }

        @media only screen and (max-width: 767px) {
          padding: 5px;
        }
      }

      .bettingsummary_topleftfour {
        .result_btn {
          max-width: 232px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 10px;
          text-decoration: none;
          margin-left: 15px;

          @media only screen and (max-width: 991px) {
            font-size: 10px;
            padding: 11px 6px;
            margin-left: 0px;
          }

          @media only screen and (max-width: 475px) {
            font-size: 9px;
            padding: 11px 4px;
          }

          @media only screen and (min-width: 1366px) and (max-width: 1600px) {
            font-size: 11px;
            padding: 10px 12px;
          }

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;

            @media only screen and (max-width: 991px) {
              position: relative;
              top: 2px;
            }
          }

          &:hover {
            opacity: 0.6;
          }

          &.four_success {
            background-color: #000000;
            background-image: none;
            border: none;

            &:hover {
              opacity: 0.6;
            }

            @media only screen and (min-width: 992px) and (max-width: 1919px) {
              font-size: 11px !important;
              padding: 11px 9px !important;
            }
          }

          @media only screen and (min-width: 992px) and (max-width: 1260px) {
            font-size: 11px;
            padding: 11px 8px;
          }

          @media only screen and (min-width: 1301px) and (max-width: 1400px) {
            font-size: 11px;
            padding: 11px 12px;
          }
        }
      }

      &.right_zero {
        justify-content: end;
      }
    }
  }

  &.sp-summarymob {
    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }

  &.summarytop_desk {
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  &.fetching-challenge-data {
    border-radius: 8px;
    margin-top: 20px;
    border: 2px solid #2d2d2d;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.betting_right_inner {
  // @media only screen and (min-width: 1500px) {
  //   max-width: 1430px;
  // }

  @media only screen and (min-width: 1921px) {
    // margin: auto;
  }

  //  &.collapsed{
  //   margin: auto;
  //  }
}

.summary_drop {
  font-size: 0;
  background: transparent;
  border: none;

  &:hover {
    background: transparent;
    border: none;
  }
}

.summary_Extent {
  margin-bottom: 90px;

  .seasonResult_table {
    padding: 40px 33px 40px;

    @media only screen and (max-width: 991px) {
      padding: 20px;
      width: 100%;
      max-width: 768px;
      margin: auto;
    }

    @media only screen and(min-width: 1366px) and(max-width: 1919px) {
      padding: 20px 33px 20px;
    }

    .table-dark {
      background: #121212;
      --bs-table-bg: #1e1e1e;
      --bs-table-striped-bg: #161616;
      --bs-table-border-color: #000000;
      margin: 0;

      thead {
        border-style: hidden;
      }

      .disputeButton {
        font-size: 12px;
        line-height: 12px;
        padding: 3px 7px !important;
        font-family: "Gilroy-Bold";
        border: 1px solid #31ff1f;
        border-radius: 7px;
        color: #b6b6b6;
      }
    }
  }

  @media only screen and (min-width: 1920px) {
    padding: 0px 0px !important;
  }

  .title {
    h2 {
      @media only screen and (max-width: 991px) {
        font-size: 20px !important;
      }

      img {
        @media only screen and (max-width: 991px) {
          width: 13%;
        }
      }

      @media only screen and(min-width: 1366px) and(max-width: 1919px) {
        font-size: 30px !important;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 0px 20px !important;
  }
}

.desc_four {
  .items_inner {
    justify-content: space-between !important;

    @media only screen and (max-width: 991px) {
      justify-content: left !important;
    }
  }
}

.challenge_tops {
  .mob_sidebar {
    margin-top: 56px !important;

    @media only screen and(min-width: 768px) and(max-width: 1024px) {
      margin-top: 80px !important;
    }
  }

  .GB_createChallenge {
    @media only screen and (max-width: 1024px) {
      padding: 30px 20px !important;
    }
  }

  .challenge-megamenu {
    margin-top: 57px;
    z-index: 99;
  }
}

.GB_BettingScoreboardinnersummary {
  .container {
    @media only screen and(min-width: 1025px) and (max-width: 1919px) {
      max-width: 70% !important;
      margin: auto;
    }

    @media only screen and(min-width: 1024px) and (max-width: 1365px) {
      max-width: 79% !important;
    }
  }
}

.cymmexpanded {
  .challenge-megamenu {
    .challenge-right {
      width: 100%;
      position: fixed;
      margin-top: 110px;

      @media only screen and (min-width: 1024px) and (max-width: 1365px) {
        padding-left: 280px;
        margin-top: 6%;
      }

      @media only screen and (min-width: 1366px) and (max-width: 1400px) {
        padding-left: 280px;
        margin-top: 8%;
      }

      @media only screen and (min-width: 1701px) and (max-width: 1919px) {
        padding-left: 280px;
        margin-top: 6%;
      }

      @media only screen and (min-width: 1920px) {
        margin-top: 138px;
      }
    }
  }

  .big-sidebar {
    width: 240px;
  }
}

/*************** Dashboard Switcher Start ***********************/
._GB_Dashboard_switcher {
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: right;
  padding-bottom: 10px;

  @media only screen and (max-width: 1024px) {
    justify-content: space-between;
  }

  @media only screen and (max-width: 991px) {
    padding-bottom: 0px;
  }

  .switcher_left {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1.5px solid #2d2d2d;
    cursor: pointer;
    //padding: 10.3px 15.5px;
    padding: 5px 10px;

    &:hover {
      background: rgba(52, 255, 34, 0.3);
      border: 1.5px solid #31ff1f;
    }

    &.switch_active {
      background: rgba(52, 255, 34, 0.3);
      border: 1.5px solid #31ff1f;
    }

    &.switcher_leftmob {
      color: #fff;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      padding: 10.3px 2.5px;
      font-family: "Gilroy-Bold";
      max-width: 48%;
      flex: 0 0 48%;
      border-radius: 10px;
      justify-content: center;
      text-transform: uppercase;

      @media only screen and (max-width: 575px) {
        gap: 1px;
        max-width: 49%;
        flex: 0 0 49%;
        font-size: 9px;
      }

      @media only screen and (max-width: 991px) {
        padding: 6.3px 2.5px;
      }
    }

    img {
      width: 40px;
      height: 40px;

      @media only screen and (max-width: 575px) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .switcher_right {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1.5px solid #2d2d2d;
    cursor: pointer;
    //padding: 10.3px 15.5px;
    padding: 5px 10px;

    &:hover {
      background: rgba(52, 255, 34, 0.3);
      border: 1.5px solid #31ff1f;
    }

    &.switch_active {
      background: rgba(52, 255, 34, 0.3);
      border: 1.5px solid #31ff1f;
    }

    &.switcher_rightmob {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 14px;
      line-height: 14px;
      width: 100%;
      padding: 10.3px 2.5px;
      font-family: "Gilroy-Bold";
      max-width: 48%;
      flex: 0 0 48%;
      border-radius: 10px;
      text-transform: uppercase;

      @media only screen and (max-width: 575px) {
        font-size: 9px;
      }

      @media only screen and (max-width: 991px) {
        padding: 6.3px 2.5px;
      }
    }

    img {
      width: 40px;
      height: 40px;

      @media only screen and (max-width: 575px) {
        width: 30px;
        height: 30px;
      }
    }
  }

  img {
    //width: 30px;
    // height: 30px;

    // border: 1.5px solid #2d2d2d;
    // padding: 15px;
    // cursor: pointer;
    // &:hover{
    //   background: rgba(52, 255, 34, 0.3);
    //   border: 1.5px solid #31FF1F;
    // }
    // &.switch_active{
    //   background: rgba(52, 255, 34, 0.3);
    //   border: 1.5px solid #31FF1F;
    // }
    // @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    //   width: 22px;
    //   height: 22px;
    // }
  }

  &.dashboard_switchermob {
    width: 100%;
    max-width: 768px;

    @media only screen and (max-width: 1024px) {
      width: 100% !important;
      max-width: 90% !important;
      margin: auto;
    }
  }
}

/*************** Dashboard Switcher  End ***********************/
// .challenge_fullpage {
//   #subscribe {
//     .container {
//       @media only screen and (min-width: 1920px) {
//         max-width: 1175px !important;
//       }
//     }
//   }

//   .footer {
//     .container {
//       @media only screen and (min-width: 1920px) {
//         max-width: 1175px !important;
//       }
//     }
//   }

//   .rights_reserved {
//     .container {
//       @media only screen and (min-width: 1920px) {
//         max-width: 1175px !important;
//       }
//     }
//   }
// }

#subscribe {
  .container {
    @media only screen and (min-width: 1366px) {
      max-width: 70% !important;
    }

    @media only screen and (min-width: 1920px) {
      max-width: 1400px !important;
    }
  }
}

.footer {
  .container {
    @media only screen and (min-width: 1366px) {
      max-width: 70% !important;
    }

    @media only screen and (min-width: 1920px) {
      max-width: 1400px !important;
    }
  }
}

.rights_reserved {
  .container {
    @media only screen and (min-width: 1366px) {
      max-width: 70% !important;
    }

    @media only screen and (min-width: 1920px) {
      max-width: 1400px !important;
    }
  }
}

.betting_seconds {
  background: #181818;
  border: 1px solid #454545;
  border-radius: 12px;
  width: 100%;
  max-width: 114px;
  margin: 25px auto;
  padding: 17px 15px;

  .betting_number {
    font-family: "Gilroy-Bold";
    font-size: 68px;
    line-height: 68px;
    text-align: center;
    text-transform: capitalize;
    color: #ffffff;
  }

  .betting_secondstext {
    font-family: "Gilroy-Bold";
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
  }
}

.view_ticket {
  content: url("../images/ticket.png");
  left: 15px;
  top: 21px;
  position: absolute;
  cursor: pointer;
}

.mob_sidebar {
  display: none !important;
  // @media only screen and (min-width: 1025px){
  //   display: none !important;
  // }
}

.mobile-bottom-nav {
  background: #000;

  @media only screen and (min-width: 992px) {
    display: none;
  }
}

.challenge_fullpage {
  ._GB_Dashboard_switcher {
    display: none;
  }

  .titles {
    @media only screen and (max-width: 1024px) {
      margin-top: 30px;
    }
  }
}

.dash_titlemob {
  @media only screen and (max-width: 1024px) {
    padding: 0px 5%;
  }
}

/************ _GB_Tournaments Start**************/
._GB_Tournaments {
  .playToEarn_inner {
    .container {
      padding-top: 20px;

      @media only screen and (max-width: 1024px) {
        padding: 15px !important;
      }
    }
  }
}

.GB_leaderboadall {
  .playToEarn_inner {
    @media only screen and (max-width: 1024px) {
      padding: 0px;
    }

    ._GB_Leaderboard_Banner {
      padding-top: 0;

      @media only screen and (max-width: 1024px) {
        padding: 15px !important;
      }
    }
  }
}

#_GB_LogoSection {
  img {
    @media only screen and (max-width: 767px) {
      max-width: 165px;
    }
  }
}

.user_device_menu {
  .mobile-main {
    @media only screen and (max-width: 767px) {
      height: 83px;
    }

    .tooth-device-menu {
      .item-burger {
        .menu-burger {
          width: 30px;
        }
      }
    }
  }
}

.footer {
  .footer_row {
    .col_2 {
      .footer_menu {
        ._gb_desc {
          img {
            @media only screen and (max-width: 767px) {
              max-width: 165px;
            }
          }
        }
      }
    }
  }
}

._GB_Transactions {
  ._GB_Banner_Top {
    ._GB_Transactions_Banner {
      padding: 0 !important;

      ._GB_Transactions_Inner {
        .Transactions_Bg {
          .top-section {
            .img-section {
              @media only screen and (min-width: 576px) and (max-width: 605px) {
                width: 100%;
              }
            }
          }

          .SelectBoxGrp {
            gap: 10px;

            .Transactions_drop {
              @media only screen and (max-width: 767px) {
                margin-bottom: 0px !important;
              }
            }

            .sortType {
              .sort_drop {
                @media only screen and (max-width: 767px) {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
      }
    }
  }

  .playToEarn_inner {
    .container {
      padding-top: 20px;

      @media only screen and (max-width: 1024px) {
        padding: 15px !important;
      }
    }

    ._lb_mob_table {
      ._each_lb {
        ._each_lb_head {
          .table-cont {
            .GbplayerCol {
              .challenge_failed {
                width: 24px;
              }
            }
          }
        }
      }

      ._each_lb_score {
        ._each_lb_scoreline {
          font-size: 14px;

          ._each_score_right {
            .table-cont {
              .new_balance {
                img {
                  margin-right: 5px;
                }
              }
            }

            .view_ticket-img {
              .bh_ticketimg {
                @media only screen and (max-width: 767px) {
                  margin-right: 0px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

/************ _GB_Tournaments End**************/
.mobile-bottom-nav__item--active {
  background: #2d2d2d !important;
}

.user_device_menu {
  .mobile-main {
    .sidebar {
      .nav-link {
        .sp-nav-logo {
          img {
            @media only screen and (max-width: 767px) {
              max-width: 165px !important;
            }
          }
        }
      }
    }

    #mobileSidebar {
      .nav-link {
        .nav-gbtokens {
          @media only screen and (max-width: 767px) {
            left: -21px;
          }
        }

        a {
          .transaction_image {
            max-width: 100% !important;
            margin-right: 0 !important;
          }
        }
      }
    }
  }
}

.mobile-tournament-acc-item {
  .mobile-tournament {
    .accordion-header {
      .accordion-button {
        padding: 8px 12px;
      }
    }
  }
}

#mobileSidebar {
  .mobile-tournament {
    img {
      &.competition-img {
        max-width: 100% !important;
        width: 40px !important;
      }
    }
  }
}

.up-down.selswap {
  border: 1px solid #e52420;
  filter: drop-shadow(0px 0px 4px #e52420);
  background-size: contain !important;
}

.forCurrwap {
  display: flex;
  align-items: center;

  .swapDolarInp {
    font-family: "Gilroy-Bold";
    font-size: 26px;
    text-align: center;
    color: #fe5242;
    padding: 0 10px;
  }
}

.betlistaction {
  display: flex;
  align-items: center;
}

.bet-show-result-btn {
  background: #31ff1f;
  border-radius: 7px;
  text-decoration: none;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000 !important;
  font-family: "Gilroy-Bold";
  font-size: 11px;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 10px;

  &:hover {
    color: #000000 !important;
  }
}

.createbethead {
  display: flex;
  align-items: center;

  .dota2rankicon {
    margin-left: 10px;
    display: flex;
    align-items: center;
  }
}

.pubg-tab-challenge {
  width: 100% !important;
}

.trx_date {
  @media only screen and (min-width: 1920px) {
    width: 20%;
  }
}

.trx_desc {
  width: 45%;
}

.trx_token {
  @media only screen and (min-width: 1920px) {
    width: 20%;
  }
}

.trx_balance {
  @media only screen and (min-width: 1920px) {
    width: 15%;
  }
}

.newtext_ch {
  color: #e52420 !important;
  padding-left: 15px;
  padding-right: 15px;
}

.affpaid {
  cursor: pointer;
}

.spinner-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-full-section {
  margin: 40px 0px;

  @media only screen and (max-width: 767px) {
    margin: 20px 0px;
  }

  .paginate {
    margin: 0px 13px;
    background: rgba(0, 0, 0, 0.5);
    border: 1px solid #4f4f4f;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 4px 4px;
    justify-content: center;
    align-items: center;

    ul {
      margin-bottom: 0px;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;

      li {
        list-style-type: none;
        margin-right: 10px;

        a {
          font-family: "Gilroy-Bold";
          font-size: 18px;
          line-height: 100%;
          text-align: center;
          color: #ffffff;
          background-color: transparent;
          border: none;

          &:focus {
            box-shadow: none;
          }
        }

        &.previous {
          position: absolute;
          left: -61px;

          a {
            background: url(../images/rightarrow.png) 0px 0px no-repeat;
            height: 50px;
            width: 50px;
          }
        }

        &.next {
          position: absolute;
          right: -61px;

          a {
            background: url(../images/leftarrow.png) 0px 0px no-repeat;
            height: 50px;
            width: 50px;
          }
        }

        &.active {
          background: #e52420;
          border-radius: 8px;
          height: 42px;
          width: 42px;
          vertical-align: middle;
          align-items: center;
          justify-content: center;
          display: flex;
        }

        &:last-child {
          margin-right: 0px;
        }

        &:first-child {
          margin-right: 0px;
        }

        &:nth-last-child(2) {
          margin-right: 0px;
        }
      }
    }
  }
}

.cc-head-row {
  background: #1e1e1e !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;

  .payout-popover {
    align-items: center;
    border: 1px solid #ffc01f;
    border-radius: 25px;
    color: #ffc01f;
    cursor: pointer;
    display: inline-flex;
    font-family: Gilroy-Bold;
    font-size: 11px;
    height: 15px;
    justify-content: center;
    line-height: 100%;
    margin-left: 3px;
    text-align: center;
    width: 15px;
  }

  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

.cc-head-row-th {
  color: rgba(235, 235, 245, 0.6) !important;
}

.GB_explore .common_change_game,
.GB_BettingHistory .common_change_game {
  display: none !important;
}

.hide-reward-payout {
  display: none !important;
}

.bettingsummary_topleft.topleft_success.danger_bet {
  background: red;
}

.switcher_center {
  border: 1.5px solid #2d2d2d;
  cursor: pointer;
  padding: 5px 10px;
  border-left: none;
  border-right: none;
}

.switcher_center.switch_active {
  background: rgba(52, 255, 34, 0.3);
  border: 1.5px solid #31ff1f;
}

.switcher_center img {
  width: 40px;
  height: 40px;
}

.dashboard_switchermob {
  align-items: center;
  flex-wrap: wrap;
  justify-content: center !important;
  gap: 10px;
}

.betTimerImg {
  width: 35px;
  cursor: pointer;
}

.greentext {
  color: #31ff1f;
  padding-left: 5px;
}


.create-streamer-bet-page .body-row.cc-head-row {
  display: none !important;
}

.create-streamer-bet-page .body-row-right {
  display: none !important;
}

// --------------- new edits from 15 sept 2023

.hide-body-row  {
  .body-row-right, .reward, .payout {
      display: none !important;
    }
}
.hide-cc-head-row{
  display: none !important;
}
.won-details.sb_init_counter {
  display: flex;
  gap: 20px;
  color: #fff;
      margin-top: 8px;
}
.usdYN{
  font-size: 16px;
  margin-top: 8px;
  &.usdY{
    color: #52dd21;
  }
  &.usdN {
    color: #e52420;
  }
}

.forceLeft {
  text-align: left !important;
}

.ollste-strbet-btn {
  display: flex;
  justify-content: end;
  padding: 10px;
  .dropdown-item{
    padding: 5px 10px;
  }
}
.ollste-strbet {
  background: #181818 !important;
  border: 2px solid #2d2d2d !important;
  color: #fff;
  font-family: "Gilroy-Bold";
  font-size: 16px;
  line-height: 16px;
  width: 160px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.betStreamerName{
  text-decoration: none;
  color: #fff !important;
}

.privOrPub{
  cursor: pointer;
  display: flex;
    gap: 10px;
  .challenge-radio-privacy{
font-family: "Gilroy-Bold";
  font-size: 18px;
  margin-left: 10px;
  color: #fff;
  cursor: pointer;
  } 
}

.betExplorePrivacy {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-bottom: 10px;
  &.betExplorePrivacySmall{
    margin-bottom: 0;
    margin-right:10px;
  }
  span{
    font-family: "Gilroy-Bold";
    font-size: 16px;
    color: #fff;
  }
  svg {
    fill: gold;
    font-size: 20px;
  }
}
.notifnLink {
  color: #fff;
  text-decoration: none;
  &:hover{
    color: #e52420 !important;
  }
}
.GB_PopularLiveMatches .no_data {
    background: #121212;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    padding: 20px;
    margin: 15px 0;
  }

  .streamer-result-opt-head {
    text-align: center;
    color: #fff;
    font-family: "Poppins-SemiBold";
    margin-bottom: 30px;
    font-size: 20px;
  }
    .streamer-result-opt-wrap {
      display: flex;
      justify-content: center;
      gap: 15px;
    }
  .streamer-result-opt-btn {
    margin: 15px 0;
  }

  .for-private-bet-overlay{
    position: relative;
    .private-bet-overlay {
      position: absolute;
          width: 100%;
      height: 100%;
      z-index: 99;
      background: rgba(17, 16, 16, 0.94);
          border-radius: 20px;
      .pbo-inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        z-index: 99;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 60%;
        margin: auto;
        .pbo-title {
        font-size: 30px;
        line-height: 36px;
        font-family: "Gilroy-Bold";
        color: #fff;
        }
        .pbo-details {
        font-family: "Gilroy-Medium";
        font-size: 18px;
        color: #fff;
        }
        .pbo-quote {
          font-family: "Gilroy-Medium";
          font-size: 18px;
          color: #FFC01F;
        }
        .explore-private{
          &::after{
            content: none;
          }
          &.explore-email-verified{
            display: flex;
            align-items: center;
            gap: 10px
          }
        }
      }
    }
  }
  
    .shared-pots-wrap {
      display: flex;
      align-items: center;
      gap: 10px;
          font-size: 18px;
    }

        .shared-pot-versus {
          display: flex;
          align-items: end;
          font-family: "Gilroy-Bold";
          color: #ffffff;
          font-size: 18px;
        }
                             .shared-pot-title-text {
                               font-size: 18px;
                               font-family: "Gilroy-Bold";
                               color: #ffc01f;
                               padding: 0 20px;
                               text-align: center;
                             }

.betname-title{
  font-size: 18px !important;
    font-family: "Gilroy-Bold"!important;
    color: #ffc01f!important;
    padding: 0!important;
}
                                                               
.place_Bets_v2 {
    display: flex;
    justify-content: space-evenly;
      gap: 22px;
      padding: 14px 20px;
      .pv2_img {
          padding: 10px 0;
        }
  .pv2_pot{
          background-color: #000000;
            padding: 10px 15px;
            border-radius: 10px;
            flex: 0 0 50%;
            text-align: center;
    .pv2_pot_head {
        font-size: 14px;
        @media only screen and (min-width: 1920px) {
            font-size: 18px;
          }
        line-height: 22px;
        font-family: "Gilroy-Bold";
        color: #fff;
            // margin-bottom: 10px;
      }
            .place_Betsright {
              background-color: #000000;
              padding: 3px 13px;
              border-radius: 6px;
              outline: none;
              box-shadow: none;
              border-color: #000000;
      
              img {
                margin-right: 10px;
              }
      
              span {
                  font-size: 22px;
                    line-height: 25px;
                      font-family: "Gilroy-Bold";
                      color: #ffc01f;
      
                @media only screen and (min-width: 1300px) and (max-width: 1650px) {
                  font-size: 16px;
                }
      
                @media only screen and(max-width: 374px) {
                  font-size: 12px;
                }
              }
            }
      
            .dropdown-menu {
              min-width: 280px !important;
              transform: translate(0px, 34.5556px) !important;
              width: 100%;
      
              .dropdown-item {
                margin-right: 0px;
                white-space: normal !important;
                font-size: 13px!important;
                img {
                  margin: 0px 5px 0px 8px;
                }
              }
            }
  }
  .pv2_rules{
.pv2_rules_btn {
          background: #2D2D2D;
            color: #fff;
            border: solid 2px #727070;
            padding: 6px 15px;
            border-radius: 8px;
            margin: 10px 0;
            font-family: "Gilroy-Medium";
            font-size: 15px;
  }
  }
    
}

.result_dispute_btn {
  color: #fff;
  background-color: #e52420;
  background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
  border-radius: 10px;
  border: 2px solid #ffffff !important;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  padding: 10px 20px;
  text-decoration: none;
  &:hover{
    opacity: 0.6;
  }
  &.result_confirm_btn{
    background-image: linear-gradient(to right,
        #175900,
        #52DD21,
        #175900);

    &::after {
      content: url("../images/arrow.png");
      padding-left: 10px;
    }
  }
}
.outcome_green_text{
  color: #31ff1f;
}
.outcome_red_text {
  color: #c80000;
}

.personal-form {
  .rule{
    li{
      @media only screen and(min-width: 1360px) {
        font-size: 14px !important;
        line-height: 20px !important;
      }
      &:last-child{
        border: none !important;
      }
    }
  }
  &.no-input-personal-form {
    border: none !important;
    padding-bottom: 20px !important;
    .rule {
      height: 350px !important;
    }
  }
}
.list_pool_items {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .pool_item{
    flex: 0 0 45%;
    max-width: 45%;
    ._GB_Admin_CB_Name{
      color: #fdbf1f;
      font-family: "Gilroy-Bold";
      font-size: 16px;
      position: relative;
      top: 4px !important;
    }
  }
}

.confisRedMsg {
  padding: 10px;
  display: block;
  text-align: center;
  color: #c80000;
  font-family: "Gilroy-Bold";
  font-size: 18px;
}

.no-game-challenge-table{
      margin: 10% 0;
}

.liveStream_Sec_new{
  display: flex;
  gap: 20px;
      align-items: end;
  .liveStream-left-new{
    flex: 0 0 47%;
    max-width: 47%;
    .liveStream-link-left {
      cursor: pointer;
      padding-top: 20px;
      .liveStream-game{
        .stream-mainlmg {
            height: 475px;
            object-fit: cover;
              border-top-left-radius: 5px;
                border-top-right-radius: 5px;
          }
      }
      
    }
  }
  .liveStream-right-new{
    flex: 0 0 48%;
    max-width: 48%;
    .people_watching {
        position: absolute;
        top: 150px !important;
    }
    .liveStream_Box_new{
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .liveStream-link-right {
        cursor: pointer;
        flex: 0 0 48%;
        max-width: 48%;
        .stream-subimg {
            height: 180px;
            object-fit: cover;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
      }
    }
  }
     .liveStream-full {
          width: 100%;
       .liveStream_Box_new {
         display: flex;
         flex-wrap: wrap;
         gap: 20px;
  
         .liveStream-link-right {
           cursor: pointer;
           flex: 0 0 23%;
           max-width: 23%;
  
           .stream-subimg {
             height: 180px;
             object-fit: cover;
             border-top-left-radius: 5px;
             border-top-right-radius: 5px;
           }
         }
       }
     }
}
.GB_PopularLiveMatches .title-right {
  margin-right: 50px;
}

// .grid-container {
//   display: grid;
//   grid-template-columns: auto auto auto auto;
//   grid-gap: 10px;
//   background-color: #2196F3;
//   padding: 10px;
// }

// .grid-container>div {
//   background-color: rgba(255, 255, 255, 0.8);
//   text-align: center;
//   padding: 20px 0;
//   font-size: 30px;
// }

// .item1 {
//   grid-column: 1 / 3;
// }

.liveStream_dd {
  text-align: center;
  svg {
    fill: #fff;
    font-size: 30px;
    position: relative;
    left: -55px;
    cursor: pointer;
  }
}

.bet_pool_items {
  display: flex;
  width: 100%;
  flex-direction: column;
  .bet_pool_item {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
    .bet_pool_item_block {
      flex: 0 0 22%;
      max-width: 15%;
      text-align: left;
      padding: 15px;
      border-color: #000;
      background: #161616;
      &.bet_pool_item_block_head{
        background: #292929;
        color: rgba(235, 235, 245, 0.6);
      }
    }
  }
}

.search-sb {
  max-width: 20% !important;
}
.adminsb-link {
  cursor: pointer;
  display: block;
  color: #c80000 !important;
}

._logredtext span {
  color: #c80000 !important;
}

.trx_green_desc {
  color: #52dd21 !important;
}

.trx_red_desc {
  color: #c80000 !important;
}

.betting_Result_SB .betting_Resultmain .betting_Resultlist p {
  font-size: 18px  !important;
  line-height: 24px !important;
  padding: 10px;
}