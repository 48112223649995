@import "../fonts/stylesheet.css";

.container {
  @media only screen and (min-width: 1600px) {
    max-width: 1400px !important;
  }

  &.adminlistcontainer {
    @media only screen and (min-width: 1024px) {
      max-width: 90% !important;
    }
  }
}

._GB_Privacy,
._GB_Leaderboard {
  // overflow: hidden;

  .subcribe_section {
    @media only screen and (max-width: 767px) {
      .row {
        .left,
        .right {
          width: 100%;
        }
      }
    }
  }

  ._GB_Banner_Top {
    .container {
      max-width: 1164px !important;

      @media only screen and (min-width: 991px) {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
      }

      ._GB_Left_Section {
        .nav {
          .nav-item {
            margin-bottom: 20px;

            @media only screen and (min-width: 991px) {
              max-width: 305px;
            }

            .nav-link {
              font-family: "Poppins-SemiBold";
              font-size: 17px;
              color: #ffffff;
              text-transform: uppercase;
              border: 1px solid #292929;
              border-radius: 10px;
              background: transparent;
              padding: 17px;
              cursor: pointer;
              background: transparent url(../images/privacy_arrow.png) no-repeat
                94% 50%;

              &.active,
              &:hover {
                border-color: #e52420 !important;
              }
            }
          }
        }
      }

      ._GB_Right_Section {
        .tab-content {
          .tab-pane {
            h2 {
              font-size: 45px;
              color: #ffffff;
              font-family: "Poppins-Bold";
              line-height: 67.5px;
              border-bottom: 1px solid #292929;
              padding-bottom: 20px;
              margin-bottom: 30px;
            }

            h3 {
              font-family: "Gilroy-Bold";
              font-size: 25px;
              line-height: 25px;
              color: #ffffff;

              &.large-margin {
                margin-top: 60px;
                margin-bottom: 20px;
              }
            }

            h4 {
              font-family: "Gilroy-Bold";
              font-size: 23px;
              line-height: 23px;
              color: #ffffff;
            }

            h5 {
              font-family: "Gilroy-Bold";
              font-size: 20px;
              line-height: 20px;
              color: #ffffff;
            }

            p {
              font-family: "Gilroy-SemiBold";
              font-size: 16px;
              line-height: 29px;
              color: #ffffff;
            }

            ul {
              li {
                font-family: "Gilroy-SemiBold";
                color: #ffffff;
                line-height: 29px;
              }
            }
          }
        }
      }
    }

    // background: linear-gradient(
    //   231.85deg,
    //   #c80000 -37.37%,
    //   rgba(200, 0, 0, 0) 23.62%
    // );
    background-color: #000;

    ._GB_Home_Banner {
      height: 700px;
      color: #fff;

      ._Title {
        font-family: "Gilroy-Bold";
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;

        @media only screen and (min-width: 991px) {
          font-size: 40px;
          line-height: 50px;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 65px;
          line-height: 65px;
        }
      }

      ._SubTitle {
        font-family: "Gilroy-Medium";
        font-size: 19px;
        line-height: 27px;

        @media only screen and (min-width: 1366px) {
          margin-bottom: 20px;
        }
      }

      ._SearchArea {
        max-width: 800px;
        background: #262627;
        padding: 8px 10px;
        border-radius: 15px;

        ._GB_Enter_Player {
          background: none;
          border: none;
          font-family: "Gilroy-Medium";
          color: #ffffff;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .game_dropdown {
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;

          .game_selected_dd {
            width: 275px;
            font-family: "Gilroy-Medium";
            padding: 0 15px;

            .game_choosed {
              padding-right: 35px;
            }
          }

          .game_dropdown_list {
            background: #f9f9f9;
            border-radius: 14px;
            padding: 8px 12px;
            width: 275px;
            color: #0d0f15;
            position: absolute;
            top: 40px;
            z-index: 1;

            .game_dropdown_each {
              cursor: pointer;
              margin: 4px 0;
              padding: 8px;

              &:hover {
                background: #e6e6e6;
                border-radius: 8px;
              }

              .game_dd_image {
                width: 40px;
                text-align: center;

                img {
                  width: 40px;
                  height: 40px;
                }

                &.all_game_img {
                  img {
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              .game_dd_name {
                padding-left: 15px;
                font-family: "Gilroy-Medium";
              }
            }
          }
        }

        ._GB_Select_Game {
          border: none;
          outline: none;
          font-family: "Gilroy-Medium";
          color: #fff !important;
          background: #262627;
          border: none;
        }

        ._GB_Search_Player {
          border: solid 2px #fff;
          background-color: #e52420;
          background-image: linear-gradient(
            to right,
            #6d1513,
            #e52420,
            #6d1513
          );
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 2px 2px 2px 10px;
          border-radius: 10px;
        }

        ._GB_Search_List {
          background: #262627;
          border-radius: 15px;
          margin-top: 20px;
          padding: 15px 10px;
          position: absolute;
          width: 780px;

          ._GB_Game_Item {
            color: #fff;
            padding: 8px 15px;
            margin-bottom: 10px;

            &:hover {
              background: #3f3f3f;
              border-radius: 10px;
              padding: 8px 15px;
            }

            ._GB_Game_Item_Name {
              .gameImage {
                display: none;

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .gameName {
                color: #ffffff;
                opacity: 0.5;
                font-family: "Gilroy-Regular";
                font-size: 13px;
                // padding-left: 15px;
              }
            }

            ._GB_CB_PlayerName {
              font-family: "Gilroy-Medium";
            }
          }

          hr {
            color: #fff;
          }

          ._GB_Mining_Seleted {
            color: #ffffff;
            opacity: 0.5;
            font-family: "Gilroy-Medium";
            font-size: 14px;
          }

          ._GB_mining_Btn {
            height: 56px;
            color: #fff;
            background-color: #e52420;
            background-image: linear-gradient(
              to right,
              #6d1513,
              #e52420,
              #6d1513
            );
            border-radius: 10px;
            border: 2px solid #ffffff;
            font-family: "Poppins-SemiBold";
            font-size: 15px;
            line-height: 17px;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            padding: 18px 50px;
            text-decoration: none;
            margin-left: 15px;

            &::after {
              content: url("../images/arrow.png");
              padding-left: 10px;
            }

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }

      ._Banner_Bottom {
        color: #fff;
        font-family: "Gilroy-SemiBold";

        ._Banner_Bottom_Inner {
          max-width: 400px;
          margin: 50px auto 20px auto;

          ._Icon_Each {
            cursor: pointer;
          }

          ._Icon_Text {
            font-family: "Gilroy-Bold";
            font-size: 19px;
          }

          ._Icon_Btn {
            cursor: pointer;

            .whitepaperbtn {
              background: #000000;
              border: 1.5px solid #e52420;
              box-sizing: border-box;
              box-shadow: 0px 0px 4px #e52420;
              border-radius: 7px;
              padding: 5px 10px 6px;
            }
          }

          ._Icon_Text_Bold {
            font-family: "Gilroy-SemiBold";
            font-size: 22px;

            &.Green {
              color: #40ab00;
            }
          }
        }
      }
    }
  }
}

.withdral-popup-small {
  .modal-dialog {
    max-width: 610px !important;
  }

  ._Icon_coin {
    color: #ffffff;
    margin-top: 30px;
    font-family: "Gilroy-Bold";
    font-size: 15px;

    img {
      margin-right: 10px;
    }
  }

  ._GB_withdrawal {
    max-width: 232px;
    height: 56px;
    color: #fff;
    background-color: #e52420;
    background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
    border-radius: 10px;
    border: 2px solid #ffffff;
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    line-height: 17px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding: 18px 50px;
    text-decoration: none;

    @media only screen and (max-width: 374px) {
      padding: 18px 30px;
    }

    &::after {
      content: url("../images/arrow.png");
      padding-left: 10px;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .withdrawal-wrap {
    max-width: 800px;
    background: #262627;
    padding: 8px 10px;
    border-radius: 15px;
    margin-top: 20px;
    margin-bottom: 30px;

    @media only screen and (max-width: 574px) {
      flex-direction: column;
      justify-content: center;
    }

    ._GB_Enter_Player {
      background: none;
      border: none;
      font-family: "Gilroy-Medium";
      color: #ffffff;

      @media only screen and (max-width: 574px) {
        text-align: center;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .game_dropdown {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      .game_selected_dd {
        width: 275px;
        font-family: "Gilroy-Medium";
        padding: 0 15px;

        @media only screen and (max-width: 574px) {
          justify-content: center !important;
          margin: auto;
        }

        .game_choosed {
          padding-right: 35px;
          color: #ffffff;
          display: flex;
          align-items: center;

          img {
            margin-right: 5px;
            width: 33px;
            height: 33px;
          }
        }
      }

      .game_dropdown_list {
        background: #181818;
        border: 1px solid #2d2d2d;
        border-radius: 14px;
        padding: 8px 12px;
        width: 275px;
        color: #0d0f15;
        position: absolute;
        top: 40px;
        z-index: 1;

        .game_dropdown_each {
          cursor: pointer;
          margin: 4px 0;
          padding: 8px;

          &:hover,
          &.active {
            background: #000000;
            border-radius: 8px;

            .game_dd_name {
              color: #fff;
            }

            .btn-success {
              background: transparent;
              border: none;

              &:focus {
                box-shadow: none !important;
              }
            }
          }

          &.disable {
            opacity: 0.5;
            pointer-events: none;

            .btn-success {
              background: transparent;
              border: none;

              &:focus {
                box-shadow: none !important;
              }
            }
          }

          &.disable {
            opacity: 0.5;
            pointer-events: none;
          }

          .game_dd_image {
            width: 40px;
            text-align: center;

            img {
              width: 33px;
              height: 33px;
            }

            &.all_game_img {
              img {
                width: 25px;
                height: 25px;
              }
            }
          }

          .game_dd_name {
            padding-left: 15px;
            font-family: "Gilroy-Bold";
            color: #979797;
            font-size: 16px;
            text-align: left;
            min-width: 140px;

            &:hover {
              color: #ffffff;
            }
          }
        }
      }
    }

    ._GB_Select_Game {
      border: none;
      outline: none;
      font-family: "Gilroy-Medium";
      color: #fff !important;
      background: #262627;
      border: none;
    }

    ._GB_Search_Player {
      border: solid 2px #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px 2px 2px 10px;
      border-radius: 10px;
    }

    ._GB_Search_List {
      background: #262627;
      border-radius: 15px;
      margin-top: 20px;
      padding: 15px 10px;
      position: absolute;
      width: 780px;

      ._GB_Game_Item {
        color: #fff;
        padding: 8px 15px;
        margin-bottom: 10px;

        &:hover {
          background: #3f3f3f;
          border-radius: 10px;
          padding: 8px 15px;
        }

        ._GB_Game_Item_Name {
          .gameImage {
            display: none;

            img {
              width: 35px;
              height: 35px;
            }
          }

          .gameName {
            color: #ffffff;
            opacity: 0.5;
            font-family: "Gilroy-Regular";
            font-size: 13px;
            // padding-left: 15px;
          }
        }

        ._GB_CB_PlayerName {
          font-family: "Gilroy-Medium";
        }
      }

      hr {
        color: #fff;
      }

      ._GB_Mining_Seleted {
        color: #ffffff;
        opacity: 0.5;
        font-family: "Gilroy-Medium";
        font-size: 14px;
      }

      ._GB_mining_Btn {
        height: 56px;
        color: #fff;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        padding: 18px 50px;
        text-decoration: none;
        margin-left: 15px;

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.withdral-popup {
  @media only screen and (max-width: 767px) {
    padding: 0px !important;
  }

  .modal-dialog {
    .modal-content {
      border: 2px solid #454545 !important;
      border-radius: 5px;
      background: #181818;
      padding: 50px 40px;
      min-height: 220px;

      @media only screen and (max-width: 767px) {
        padding: 25px;
      }

      .modal-header {
        padding: 0;
        border: none;

        .btn-close {
          color: #727070;
          outline: none;
          background: url(../images/popupclose.png) no-repeat center center;
        }
      }

      .votp .data {
        color: #fff;
        font-family: Gilroy-Bold;
        font-size: 17px;
        line-height: 17px;
        padding-bottom: 5px;
      }

      .resend-otp button {
        background-color: transparent;
        border: none;
        color: #e52420;
      }

      .otp-enter {
        margin: 10px 0;
      }

      .middle_section .security_update {
        color: rgba(235, 235, 245, 0.6);
        font-family: Gilroy-SemiBold;
        font-size: 15px;
        line-height: 19px;
        margin: 15px auto;
        padding-bottom: 5px;
        max-width: 293px;
      }

      .modal-footer {
        justify-content: center;
        border: none;
        padding: 0;

        button {
          border: 1px solid #292929 !important;
          color: #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          line-height: 17px;
          text-transform: uppercase;
          padding: 22px 35px 22px 65px;
          min-width: 141px;
          box-shadow: none !important;

          &:first-child {
            margin-right: 15px;
            background: #141414 url(../images/tick_green.png) no-repeat 20px
              center;
          }

          &:last-child {
            background: #141414 url(../images/close_red.png) no-repeat 20px
              center;
          }

          &:hover {
            opacity: 0.5;
          }

          @media only screen and (max-width: 767px) {
            margin: 15px 5px !important;
          }
        }
      }

      .container {
        .row {
          h3 {
            text-align: center;
            color: #fff;
            font-family: "Poppins-SemiBold";
            margin-bottom: 15px;

            @media only screen and (max-width: 767px) {
              font-size: 22px;
            }
          }

          p {
            max-width: 400px;
            margin: auto;
            font-family: "Poppins-Medium";
            font-size: 13px;
            line-height: 20px;

            @media only screen and (min-width: 1920px) {
              font-size: 16px;
              line-height: 23px;
            }

            text-align: center;
            color: #ffffff;
          }
        }
      }

      .withdraw-class {
        .total-ammount {
          color: #fe5242;
          font-family: "Poppins-SemiBold";
          padding: 15px;
          font-size: 35px;
          line-height: 45px;

          @media only screen and (min-width: 768px) {
            font-size: 50px;
            line-height: 50px;
          }

          @media only screen and (min-width: 1360px) {
            font-size: 60px;
            line-height: 60px;
          }

          @media only screen and (min-width: 1920px) {
            font-size: 75px;
            line-height: 75px;
          }
        }

        .token-name {
          color: #ffffff;
          font-family: "Poppins-Medium";
          font-size: 16px;
          line-height: 23px;
        }

        .sendall {
          display: block !important;
          max-width: 176px;
          margin: 15px auto 0;
          font-family: "Poppins-SemiBold";
          text-transform: uppercase;
          text-decoration: none;
          color: #ffffff;
          border-radius: 10px;
          padding: 16px 0px 16px 40px;
          background: url(../images/white_arrow.png) no-repeat 19% center;
          cursor: pointer;
          background-color: #141414;
          border: 2px solid #292929;
          border-radius: 10px;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

// ------------- Claim Rewards Popup Start ---------------//
.claim_reward_popup_modal {
  max-width: 610px !important;

  .modal-content {
    border: 2px solid #454545 !important;
    border-radius: 25px;
    background: #181818;
    padding: 50px 40px;
    border-bottom: none;

    @media only screen and (max-width: 374px) {
      padding: 40px 30px;
    }

    .modal-header {
      border-bottom: none;

      .btn-close {
        color: #727070;
        outline: none;
        background: url(../images/popupclose.png) no-repeat center center;
      }
    }

    .modal-body {
      h3 {
        text-align: center;
        color: #fff;
        font-family: "Poppins-SemiBold";
        font-size: 41px;
        margin-bottom: 25px;

        @media only screen and(max-width: 374px) {
          font-size: 30px;
        }
      }

      img {
        @media only screen and(max-width: 574px) {
          width: 100%;
        }
      }

      .reward-points {
        align-items: baseline;
        margin-top: 30px;

        h3 {
          margin-bottom: 0px !important;
          font-family: "Gilroy-Bold";
          font-size: 63px;
          line-height: 73px;
        }

        img {
          width: 49px;
          height: 49px;
          margin-left: 12px;
        }
      }

      .content {
        font-family: "Gilroy-Bold";
        font-size: 22px;
        line-height: 25px;
        text-align: center;
        color: #ffffff;
        margin-top: 13px;
      }

      .btn-claim-now {
        text-align: center;
        margin-top: 35px;

        ._GB_claim_rewars {
          max-width: 232px;
          height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(
            to right,
            #6d1513,
            #e52420,
            #6d1513
          );
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 50px;
          text-decoration: none;

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

// ------------- Claim Rewards Popup End ---------------//

// ------------- Conatct Us Start ---------------//
._GB_conatct_Banner {
  height: 650px;
  color: #fff;

  @media only screen and (max-width: 574px) {
    height: auto;
    padding-bottom: 45px;
  }

  ._Banner_content {
    ._Title {
      font-family: "Poppins-Bold";
      margin-bottom: 20px;
      font-size: 45px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;

      @media only screen and (min-width: 991px) {
        font-size: 40px;
        line-height: 50px;
      }

      @media only screen and (min-width: 1366px) {
        font-size: 45px;
        line-height: 67px;
      }

      @media only screen and (max-width: 574px) {
        font-size: 30px;
      }
    }

    ._SubTitle {
      font-family: "Poppins";
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      line-height: 34px;
      max-width: 750px;
      margin: auto;
    }
  }

  ._Banner_dropdown-button {
    .game_drop {
      width: 740px;
      text-align: left;
      background: #262627;
      border-radius: 15px;
      height: 65px;
      border-color: #262627;
      margin-bottom: 40px;
      color: #fff;
      padding: 0 20px 0 20px;
      font-family: "Gilroy-Medium";
      font-size: 20px;
      line-height: 23px;

      @media only screen and (max-width: 989px) {
        width: 100%;
      }
    }
  }

  .btn-next {
    text-align: center;

    ._GB_next {
      max-width: 232px;
      height: 56px;
      color: #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border-radius: 10px;
      border: 2px solid #ffffff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding: 18px 50px;
      text-decoration: none;

      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

._GB_Contact_Form {
  height: 980px;
  color: #fff;

  @media only screen and (max-width: 574px) {
    height: auto;
    padding-bottom: 45px;
  }

  ._Banner_content {
    ._Title {
      font-family: "Poppins-Bold";
      margin-bottom: 20px;
      font-size: 45px;
      letter-spacing: 0em;
      text-align: center;
      color: #ffffff;

      @media only screen and (min-width: 991px) {
        font-size: 40px;
        line-height: 50px;
      }

      @media only screen and (min-width: 1366px) {
        font-size: 45px;
        line-height: 67px;
      }

      @media only screen and(max-width: 574px) {
        font-size: 30px;
      }
    }

    ._SubTitle {
      font-family: "Poppins";
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      line-height: 34px;
      max-width: 693px;
      margin: auto;
    }
  }

  ._contact_form_section {
    .contact-form {
      max-width: 552px;
      margin: 0 auto;

      .form-group {
        margin-bottom: 40px !important;

        .form-control {
          padding: 15px 10px;
          border-radius: 20px;
          background: #000000;
          border: 2px solid #262627;
          color: #9c9ca3;
        }

        textarea {
          min-height: 133px;
        }

        .form-field-error {
          color: red;
          font-size: 12px;
          padding: 10px 0px;
        }
      }
    }

    .btn-submit {
      @media only screen and (min-width: 1024px) {
        margin-bottom: 100px;
      }

      .send-btn {
        max-width: 232px;
        height: 56px;
        color: #fff;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        padding: 18px 50px;
        text-decoration: none;

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

// ------------- Conatct Us End ---------------//
// ------------- Membership Start ---------------//
._GB_membership {
  // background: linear-gradient(32.85deg, #5200FF -37.37%, rgba(82, 0, 255, 0) 33.62%);
  ._Title {
    font-family: "Gilroy-Bold";
    font-size: 36px;
    line-height: 48px;
    color: #ffffff;
    padding-top: 40px;
    padding-bottom: 40px;

    @media only screen and (min-width: 768px) {
      font-size: 48px;
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  .box_row {
    max-width: 990px;
    margin: 0 auto;
    margin-bottom: 70px;

    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }

    .first-clm {
      width: 100%;
      background: #181818;
      border: 2px solid #2d2d2d;
      box-sizing: border-box;
      border-radius: 20px;
      margin: 0 auto;
      padding: 30px;
      color: #ffffff;
      margin-bottom: 25px;

      @media only screen and (min-width: 992px) {
        width: 424px;
      }

      @media only screen and (max-width: 767px) {
        padding: 20px;
      }

      h3 {
        font-family: "Gilroy-Bold";
        font-size: 30px;
        line-height: 35px;
        text-align: center;
        padding-top: 25px;
        padding-bottom: 15px;
      }

      p {
        font-family: "Gilroy-Medium";
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        padding: 30px 20px;

        &.premium {
          @media only screen and (min-width: 992px) {
            height: 130px;
          }
        }
      }

      ul {
        li {
          list-style: none;
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 28px;
          padding: 20px 0px;
          border-bottom: 1px solid #2c2c2c;

          @media only screen and (min-width: 768px) {
            font-size: 22px;
          }

          &:nth-child(1) {
            border-top: 1px solid #2c2c2c;
          }

          &::before {
            content: url("../images/tic-icon.png");
            padding-right: 25px;
            top: 7px;
            position: relative;

            @media only screen and (max-width: 767px) {
              padding-right: 14px;
            }
          }
        }

        li.cross-section {
          &::before {
            content: url("../images/cross-icon.png");
          }
        }
      }

      .game-btn {
        margin-top: 60px;
        margin-bottom: 20px;

        .d_grade {
          max-width: 232px;
          height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(
            to right,
            #6d1513,
            #e52420,
            #6d1513
          );
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 40px;
          text-decoration: none;

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .competitive-game {
      h3 {
        color: #ffc01f !important;
      }
    }
  }
}

// ------------- Membership End ---------------//

.ad-modal {
  position: fixed;
  inset: 0;
  /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;

  .ad-modal-content {
    background-color: #282c34;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    position: relative;

    #ad-container {
      position: relative;
      width: 780px;
      height: 440px;
    }

    .adTimr {
      display: inline-flex;
      font-size: 14px;
      position: absolute;
      right: 20px;
      bottom: 0px;
    }

    .btn-close {
      position: absolute;
      color: #727070;
      outline: none;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADHSURBVHgBtZRRDoMgDIY7eyn3xpHMLjC4wOKRtqd5q40aIEgK/Jj4Jw2xtJ8tUomu0mNZnmI0qDKPo9Mv1pu5zzN9t+2Dwsq8KezdsjiLVJrBKGfsFXry279BHCZsNitVYO61rjYBR6At2AGIQHuw1LfSkgTl52iLVYVVgRUo9WAiriRo7Xdhoona+oG+pGqFygeIal4pBmHOmwBMD8oITM4MvaeMwOIDAmUUhkJ5BIZAtb9NExYVYlzmOg6JTEaYjiGdzYP1B85YokALXne1AAAAAElFTkSuQmCC)
        no-repeat center center;
      top: 0px;
      right: 0px;
      z-index: 99999;
    }
  }
}

._BoostTimer {
  color: #fff;

  // display: flex;
  // margin-left: auto;
  // justify-content: center;
  // text-align: center;
  @media only screen and(max-width: 1200px) {
    margin-left: 0;
    flex-direction: column;
  }

  // @media only screen and(min-width: 992px) and (max-width: 1200px) {
  //   margin-left: 50px;
  // }

  .left_sec {
    display: flex;

    @media only screen and(max-width: 992px) {
      margin-right: 0;
      justify-content: center;
    }

    .title {
      margin-left: 10px;
      font-family: "Gilroy-SemiBold";
      font-size: 22px;
      line-height: 26px;
    }

    img {
      height: 30px;
      width: 30px;
    }
  }

  div {
    margin-right: 10px;
    margin-left: 5px;

    @media only screen and (max-width: 767px) {
      margin-left: 0px;
    }
  }

  p {
    margin-bottom: 0px;
    color: #ffc01f;
    font-family: "Gilroy-Medium";
    font-size: 18px;
    line-height: 26px;
  }

  .right_sec {
    display: flex;

    @media only screen and(max-width: 992px) {
      margin-right: 0;
      justify-content: center;
    }

    ._LeftText {
      font-size: 15px;
      line-height: 15px;
      opacity: 0.5;
    }

    .go_btn {
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border: 2px solid #fff;
      border-radius: 10px;
      padding: 12px 20px;
      text-decoration: none;
      color: #fff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      min-width: 90px;
    }
  }
}

.profile_hc_dd .mode_switch .hardcore_cb .form-check-input {
  cursor: pointer !important;
}

.act_hc {
  cursor: pointer !important;

  &:hover {
    .mode_type {
      color: #fff !important;
    }
  }
}

._GB_User_Header .profile .profile_info .profile_list_dd .mode_alert {
  cursor: default !important;
}

.news_slider_wrap {
  .slider_top {
    margin-bottom: 30px;
  }
}

.home_news_com_slide {
  .slick-prev {
    top: -40px;
    left: 92%;

    @media only screen and(max-width: 374px) {
      left: 80%;
    }

    @media only screen and(min-width: 375px) and(max-width: 574px) {
      left: 85%;
    }

    @media only screen and(min-width: 575px) and (max-width: 1200px) {
      left: 90%;
    }
  }

  .slick-next {
    top: -40px;
    right: 10px;
  }

  &.guide_slide {
    .latest_guide {
      width: 97% !important;

      @media only screen and(max-width: 480px) {
        width: 100% !important;
      }

      @media only screen and(max-width: 767px) {
        padding: 10px;
      }

      .item_inner {
        background: #151515;
        border-radius: 20px;
        padding: 10px 20px;
        height: 175px;

        .new_slider_details {
          margin-left: 10px;

          .slider_news_title {
            a {
              font-family: "Poppins-SemiBold";

              font-size: 16px;
              @media only screen and(max-width: 767px) {
                font-size: 14px;
              }
              color: #fff;
              text-decoration: none;

              &:hover {
                opacity: 0.5;
              }
            }
          }

          .slider_news_date {
            font-family: "Gilroy-Medium";
            font-size: 15px;
            line-height: 100%;
            color: #ffffff;
            opacity: 0.5;
            padding-top: 10px;
          }
        }

        .new_slider_image {
          img {
            border-radius: 12px;
          }

          .wallet_guide {
            width: 132px;

            @media only screen and (min-width: 768px) and (max-width: 1023px) {
              width: 132px;
            }

            @media only screen and (min-width: 1200px) and (max-width: 1424px) {
              width: 70%;
            }
          }
        }
      }
    }
  }

  &.twitter_slide {
    .slick-list {
      margin: 0 -5px;

      .slick-track {
        .slick-slide {
          & > div {
            padding: 0 5px;
          }
        }
      }
    }
  }
}

._GB_News ._GB_News_Inner .news_right {
  padding-top: 87px;

  @media only screen and (max-width: 767px) {
    padding-top: 50px;
  }
}

.gameModeWrap {
  .most_played {
    .game_mode_dd {
      img {
        max-height: 652px;
      }
    }
  }
}

/****************************NoGame Start ************************/
._GB_noGame {
  background-color: #000;

  .noGame_inner {
    padding: 50px 15px;
    background: #181818;
    border: 2px solid #5b5b5b;
    border-radius: 20px;
    margin: auto;

    ._Dashboard_Game_Stats {
      .container {
        background-color: #181818;

        ._Game_Stats_top {
          .change_game {
            margin: auto;
          }
        }
      }
    }

    .noGame_title {
      font-family: "Gilroy-Bold";
      font-size: 35px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      padding-bottom: 20px;

      @media only screen and (min-width: 768px) {
        font-size: 50px;
        line-height: 55px;
      }
    }

    .noGame_subtitle {
      font-family: "Poppins";
      max-width: 645px;
      font-size: 18px;
      line-height: 25px;
      color: #fff;
      margin: auto;
      text-align: center;

      @media only screen and (min-width: 768px) {
        font-size: 26px;
        line-height: 39px;
      }

      p {
        margin: 0;
      }

      h2 {
        margin: 0;
      }
    }

    .add_game {
      padding-top: 20px;
    }

    .add_new {
      margin-top: 20px;

      .addnew_btn {
        height: 56px;
        max-width: 456px;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border: 2px solid #fff;
        border-radius: 10px;
        padding: 17px 50px;
        text-decoration: none;
        color: #fff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        cursor: pointer;

        // @media only screen and (max-width: 1024px) {
        //   padding: 12px 9px;
        // }
        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

/****************************NoGame End ************************/

/****************************faq  Start************************/
._GB_Faq {
  background-color: #000;

  ._GB_Faq_top {
    ._title {
      padding: 50px 0 20px 0;
      font-family: "Poppins-Bold";
      color: #fff;
      font-size: 45px;
      line-height: 67px;
      text-align: center;

      @media only screen and(max-width: 767px) {
        font-size: 35px;
        line-height: 47px;
      }
    }

    ._sub_title {
      font-family: "Poppins";
      font-size: 18px;
      line-height: 37px;
      color: #fff;
      max-width: 650px;
      text-align: center;
      margin: auto;

      a {
        font-family: "Poppins-Bold";
        color: #c80000;
        text-decoration: none;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .questions {
    margin-top: 70px;

    @media only screen and (max-width: 990px) {
      flex-direction: column;
      margin-top: 30px;
    }

    .faq_btn {
      height: 56px;
      max-width: 550px;
      border: 2px solid #484848;
      border-radius: 10px;
      padding: 17px 70px;
      text-decoration: none;
      color: #fff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      @media only screen and(max-width: 767px) {
        width: 70%;
        text-align: center;
        margin-bottom: 10px;
        margin-right: 0px;
        padding: 17px 50px;
      }

      @media only screen and(min-width: 768px) and (max-width: 990px) {
        width: 55%;
        text-align: center;
        margin-bottom: 10px;
      }

      &.activeFaq {
        background: rgba(200, 0, 0, 0.5);
        border-color: #c80000;
      }
    }
  }
}

.faq_acc_wrap {
  padding: 30px 0;

  .acc_tab {
    @media only screen and (min-width: 1366px) {
      max-width: 70%;
      margin: auto;
    }

    .acc_item {
      background-color: #000 !important;
      margin-bottom: 10px;

      .acc_header {
        .accordion-button {
          background-color: #181818 !important;
          color: #fff;
          border-radius: 12px;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          padding: 1rem 2.25rem;

          @media only screen and (min-width: 768px) {
            padding: 1rem 4.25rem;
          }

          &:focus {
            border-color: #181818;
            box-shadow: none;
          }

          padding-top: 0;
          padding-bottom: 0;

          button {
            background: url("../images/acc_mini.png");
            background-repeat: no-repeat;
            background-position: right;
            padding-top: 15px;
            padding-bottom: 15px;

            @media only screen and (max-width: 767px) {
              padding-right: 25px;
            }
          }

          &::after {
            display: none;
          }

          &.collapsed {
            button {
              background: url("../images/acc_abbr.png");
              background-repeat: no-repeat;
              background-position: right;
            }

            &::after {
              display: none;
            }
          }
        }
      }

      .acc_body {
        margin-top: 15px;
        color: #fff;
        font-family: "Gilroy-SemiBold";
        font-size: 15px;
        border-radius: 12px;
        border: 1px solid #181818;
      }
    }
  }
}

/****************************faq  End************************/

.featured_slider_game_mode {
  padding: 25px 0;

  #dropdown-gmode-button {
    background: #181818;
    border: 2px solid #2d2d2d;
    border-radius: 15px;
    color: #fff;
    font-family: "Gilroy-Bold";
    outline: none;
  }

  .dropdown-item {
    padding: 10px 15px;
  }
}

/****************************faq  End************************/

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  //give nav it's own compsite layer
  will-change: transform;
  transform: translateZ(0);

  display: flex;

  height: 70px;

  box-shadow: 0 -2px 5px -2px #333;
  background-color: #000;
  z-index: 999;

  // @media only screen and (min-width: 992px) {
  //   display: none;
  // }

  &__item {
    flex-grow: 1;
    text-align: center;
    font-size: 12px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item--active {
    //dev
    color: red;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 45px;
  }

  .user_profile {
    img {
      border: 3px solid #c80000;
      border-radius: 100%;
    }
  }

  .GB_mobileapp_item {
    text-decoration: none;

    .bottom_item {
      color: #fff;
      font-family: "Gilroy-Bold";
      text-decoration: none;
    }
  }
}
