._GB_restorePwd{
    .restorePwd_outerWrap{
        background-image: url("../../images/redo/forgot_bg.png");
        background-position: bottom left;
        background-size: cover;
        background-repeat: no-repeat;
        .restorePwd_innerWrap{
            background-image: url("../../images/redo/dashboard_wave.png");
            background-size: cover;
            background-repeat: no-repeat;
            padding: 40px 0;
            @media only screen and (max-width: 767px){
                padding: 40px 15px;
            }
            .restorePwd_pattern{
                position: absolute;
                transform-origin: center center;
                animation: rotate 60s linear infinite;
                object-fit: cover;
                left: 23%;
                top: 0%;
                z-index: 0; 
                @media only screen and (max-width: 1024px){
                    display: none;
                }
            }
            .restorePwd_inner{
                padding: 48px;
                max-width: 612px;
                height: 592px;
                width: 100%;
                margin: auto;
                border-radius: 24px;
                background: rgba(4, 5, 6, 0.40);
                backdrop-filter: blur(24px);
                border: 2px solid #2a5e142e;
                border-bottom-color: #2a5e142e;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                @media only screen and (max-width: 767px){
                    height: 450px; 
                    padding: 30px;
                }
                .back-arrow{
                    position: absolute;
                    top: 24px;
                    left: 24px;
                    cursor: pointer;
                    @media only screen and (max-width: 400px){
                        left: 20px;
                    }
                }
                .restorePwd_top{
                    text-align: center;
                    .secTitle{
                        font-size: 32px;
                        line-height: 32px;
                        color: #fff;
                        font-family: "Gilroy-Medium";
                        @media only screen and (max-width: 767px){
                            font-size: 25px;
                            line-height: 25px;
                        }
                    }
                    .subTitle{
                        font-size: 18px;
                        line-height: 24px;
                        color: #92A6BA;
                        margin-top: 8px;
                        font-family: "Gilroy-Regular";
                        @media only screen and (max-width: 767px){
                            font-size: 16px;
                        }
                        span{
                            color: #51E20E;
                            font-family: "Gilroy-Bold";
                            margin-left: 5px;
                        }
                    }
                }
                .restorePwd_middle{
                    max-width: 500px;
                    width: 100%;
                    .form-item{
                        background-color: #181f26;
                        max-width: 500px;
                        width: 100%;
                        height: 64px;
                        border-radius: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 19px 20px; 
                        &.password-field{
                            input{
                                max-width: 485px;
                                width: 100%;
                            }
                            .password_eye{
                                cursor: pointer;
                            }

                        }
                        &.confirm-field{
                            margin-top: 24px;
                        }
                    }
                    input{
                        background-color: #181f26;
                        color: #767A87;
                        max-width: 500px;
                        width: 100%;
                        height: 64px;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: none;
                        border-radius: 24px;
                        &:focus{
                            box-shadow: none;
                        }
                        &.password-field{
                            position: relative;
                        }
                        &:-webkit-autofill{
                            background-color: #181f26 !important;
                            color: #767A87 !important;
                        }
                    }
                    input[data-autocompleted] {
                        background-color: transparent !important;
                    }
                    .otp-enter{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        @media only screen and (max-width: 767px){
                            justify-content: center;
                            gap:10px;
                        }
                        input{
                            max-width: 72px !important;
                            width: 100% !important;
                            height: 72px !important;
                            color: #fff !important;
                            font-size: 24px !important;
                            line-height: 24px !important;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-right: 0 !important;
                            @media only screen and (max-width: 767px){
                                max-width: 48px !important;
                                height: 48px !important;
                                font-size: 18px !important;
                                line-height: 18px !important;
                                border-radius: 16px;
                            }
                            @media only screen and (max-width: 359px){
                                max-width: 35px !important;
                                height: 35px !important;
                                border-radius: 10px;
                            }
                        }
                    }
                   
                //  .password_requirement{
                //     max-width: 500px;
                //     width: 100%;
                //     margin: auto;
                //     display: flex;
                //     align-items: center;
                //     flex-wrap: wrap;
                //     margin-top: 10px;
                //     @media only screen and (max-width: 767px){
                //         display: none;
                //     }
                //     ul{
                //         margin-bottom: 0;
                //         li{
                //             color: #fff;
                //             font-size: 14px;
                //             font-family: "Gilroy-Medium";
                //             &::marker{
                //                 color: #51E20E !important;
                //             }
                //         } 
                //     }
                //  }
                .password_requirement{
                    margin-bottom: 35px;
                    max-width: 402px;
                    width: 100%;
                    margin-top: 24px;
                    .pwd-req-list{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        .pwd-item{
                            flex: 0 0 48%;
                            max-width: 48%;
                            font-family: "Gilroy-Medium";
                            font-size: 14px;
                            line-height: 24px;
                            color: #fff;
                            &.pass_tick{
                                &::marker{
                                    color: #51E20E !important;
                                }

                            }
                            &.pass_untick{
                                &::marker{
                                    color: #feda31 !important;
                                }

                            }
                            @media only screen and(min-width: 768px) and (max-width: 991px){
                                flex: 0 0 100%;
                                max-width: 100%;
                            } 
                            @media only screen and (max-width: 490px){
                                flex: 0 0 100%;
                                max-width: 100%;
                            } 
                        }
                    }
                }

                }
                .restorePwd_bottom{
                        max-width: 500px;
                        width: 100%;
                    .submitBtn{
                        color: #0E1216;
                        font-size: 14px;
                        line-height: 24px;
                        border-radius: 24px;
                        height: 64px;
                        max-width: 500px;
                        width: 100%;
                        background-color: #51E20E;
                        // border: 1px solid #fff;
                        font-family: 'Inter-Medium';
                        cursor: pointer;
                        &:hover{
                            opacity: 0.5;
                        }
                    }
                }
            }
            
            .login_copyright{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding: 60px 0 30px;
                font-size: 14px;
                line-height: 16px;
                font-family: 'Inter-Regular';
                color: #fff;
                img{
                    margin-bottom: 35px;
                }
                @media only screen and (min-width: 1921px){
                    padding: 30px 0 180px;
                }
            }
        }
    }
}

.successModal{
    .modal-dialog {
          max-width: 470px;
      }
    .modal-content{
        padding-top: 10px;
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.50);
        opacity: var(--Corner-radius-xs, 1);
        background: var(--Background---grey-Grey---600---GB, #141A1F);
        .modal-header {
            border: none !important;
            padding: 15px 15px 0 15px;
            .btn-close {
              box-shadow: none;
              outline: none;
              background: url("../../images/redo/bet-close-circle.png") no-repeat;
              opacity: 1;
              &:hover{
                opacity: 0.5;
              }
            }
          }
        .modal-body{
            padding: 0 15px 15px;
            .successful_sec{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .img-animi-sec{
                    background-image: url("../../images/redo/tour_thanks_icon-svg.svg") !important;
                    background-position: 51% 43% !important;
                    background-repeat: no-repeat;
                    margin-bottom: 24px;
                    img{
                      animation: rotateRight 90s linear infinite;
                    }
                }
                .success_title{
                    color: #fff;
                    font-size: 24px;
                    line-height: 24px;
                    font-family: "Gilroy-Medium";
                    margin-bottom: 16px;
                    @media only screen and (max-width: 767px){
                        text-align: center;
                    }
                }
                .success_subtitle{
                    color: #92A6BA;
                    font-size: 18px;
                    line-height: 24px;
                    font-family: "Gilroy-Regular";
                    margin-bottom: 32px;
                    text-align: center;
                }
            }
            .done_btn{
                background-color: #F1FEEC;
                color: #0E1216;
                font-size: 14px;
                line-height: 24px;
                font-family: 'Inter-Medium';
                display: flex;
                align-items: center;
                justify-content: center;
                height: 56px;
                max-width: 422px;
                width: 100%;
                margin: auto;
                border-radius: 24px;
                cursor: pointer;
                &:hover{
                    opacity: 0.5;
                }
            }
        }
    }
}