/****************** Home **********************/
#dropdown-language {
  background: none;
  border-color: transparent;
  font-size: 14px;
  @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    font-size: 12px;
  }
}

._GB_Home {
  ._GB_Banner_Top {
    ._GB_Home_Banner {
      // background-image: url("../images/gb_banner.png");
      margin-top: -90px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      padding-top: 50px;

      @media only screen and (min-width: 1366px) {
        height: 990px;
      }

      .banner_BtnSec {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        @media only screen and (max-width: 375px) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .playvideoBtn {
          background: #000000;
          border: 1.5px solid #e52420;
          box-sizing: border-box;
          box-shadow: 0px 0px 4px #e52420;
          border-radius: 7px;
          padding: 5px 10px 5px;
          color: #fff;
          width: 100%;
          max-width: 203px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 15px;
          text-transform: uppercase;
          text-decoration: none;
          cursor: pointer;
          @media only screen and (max-width: 374px) {
            font-size: 14px;
          }
          &:hover {
            background: #e52420;
            border: 1.5px solid #000000;
            box-shadow: 0px 0px 4px #000000;
          }

          &::before {
            content: url("../images/videoBtn.png");
            padding-left: 10px;
            margin-right: 10px;
            @media only screen and (max-width: 374px) {
              padding-left: 0px;
            }
          }
        }

        .playnowBtn {
          background: #000000;
          border: 1.5px solid #ffc01f;
          box-sizing: border-box;
          box-shadow: 0px 0px 4px #ffc01f;
          border-radius: 7px;
          padding: 5px 10px 5px;
          color: #ffc01f;
          width: 100%;
          max-width: 203px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 15px;
          text-transform: uppercase;
          cursor: pointer;
          text-decoration: none;
          @media only screen and (max-width: 374px) {
            font-size: 14px;
          }
          &:hover {
            background: #ffc01f;
            border: 1.5px solid #000000;
            box-shadow: 0px 0px 4px #000000;
            color: #fff;
          }
        }
      }

      .bannerSec {
        margin-top: 90px;

        @media only screen and (min-width: 991px) {
          margin-top: 97px;
        }
        @media only screen and (max-width: 767px) {
          margin-top: 60px;
        }
      }

      // .banner-layout{
      //   align-items: center;
      //   background-color: #000;
      //   border-radius: 14px;
      //   max-width: 744px;
      //   opacity: .95;
      //   padding: 35px 25px;
      //   width: 100%;
      //   margin: auto;
      // }

      // .bannerSec{
      //     align-items: flex-end;
      //     @media only screen and (min-width: 991px) and (max-width: 1400px){
      //         gap: 20px;
      //     }
      //     @media only screen and (max-width: 990px){
      //         flex-direction: column;
      //     }
      //     @media only screen and (min-width: 991px){
      //         margin-top: 185px;
      //     }
      //     .banner_leftSec{

      //         @media only screen and (min-width: 1366px){
      //             flex: 0 0 60%;
      //             max-width: 60%;
      //         }
      //         @media only screen and (max-width: 990px){
      //             flex: 0 0 100%;
      //             max-width: 100%;
      //             margin: 10px auto;
      //         }
      //         .searchBox{
      //             width: 100%;
      //             max-width: 744px;
      //             background-color: #000;
      //             opacity: 0.95;
      //             border-radius: 14px;
      //             display: flex;
      //             flex-direction: column;
      //             align-items: center;
      //             padding: 35px 25px;
      //             @media only screen and (min-width: 375px){
      //               height: 196px;
      //             }
      //             ._SearchArea{
      //                 margin-top: 0px !important;
      //                 max-width: 688px;
      //                 width: 100%;
      //             }
      //             ._Banner_Bottom{
      //                 margin-top: 0px !important;
      //                 ._Banner_Bottom_Inner{
      //                     margin: 0px;
      //                 }
      //             }

      //         }
      //     }
      //     .banner_rightSec{
      //         @media only screen and (min-width: 1366px){
      //             flex: 0 0 40%;
      //             max-width: 40%;
      //         }
      //         @media only screen and (max-width: 990px){
      //             flex: 0 0 100%;
      //             max-width: 100%;
      //             margin: auto;
      //         }
      //         ._Title{
      //             width: 100%;
      //             font-size: 30px;
      //             line-height: 40px;
      //             max-width: 744px;
      //             @media only screen and (max-width: 990px){
      //                 margin: auto;
      //             }
      //             @media only screen and (min-width: 768px) and (max-width: 990px){
      //                 font-size: 38px;
      //                 line-height: 60px;
      //             }
      //             @media only screen and (min-width: 991px){
      //                 max-width: 371px;
      //                 font-size: 75px;
      //                 line-height: 100px;
      //             }
      //         }
      //         ._SubTitle{
      //             width: 100%;
      //             @media only screen and (max-width: 990px){
      //                 max-width: 744px;
      //                 margin: auto auto 10px auto;
      //             }
      //             @media only screen and (min-width: 991px){
      //                 max-width: 289px;
      //             }
      //         }
      //         .banner_BtnSec{
      //             display: flex;
      //             gap: 10px;
      //             @media only screen and (max-width: 375px){
      //                 flex-direction: column;
      //                 align-items: center;
      //                 justify-content: center;
      //             }
      //             .playvideoBtn{
      //                 background: #000000;
      //                 border: 1.5px solid #e52420;
      //                 box-sizing: border-box;
      //                 box-shadow: 0px 0px 4px #e52420;
      //                 border-radius: 7px;
      //                 padding: 5px 10px 5px;
      //                 color: #fff;
      //                 width: 100%;
      //                 max-width: 203px;
      //                 height: 56px;
      //                 display: flex;
      //                 align-items: center;
      //                 justify-content: center;
      //                 font-family: "Poppins-SemiBold";
      //                 font-size: 15px;
      //                 line-height: 15px;
      //                 text-transform: uppercase;
      //                 cursor: pointer;
      //                 &:hover{
      //                     background: #e52420;
      //                     border: 1.5px solid #000000;
      //                     box-shadow: 0px 0px 4px #000000;
      //                 }
      //                 &::before{
      //                     content: url("../images/videoBtn.png");
      //                     padding-left: 10px;
      //                     margin-right: 10px;
      //                 }

      //             }
      //             .playnowBtn{
      //                 background: #000000;
      //                 border: 1.5px solid #FFC01F;
      //                 box-sizing: border-box;
      //                 box-shadow: 0px 0px 4px #FFC01F;
      //                 border-radius: 7px;
      //                 padding: 5px 10px 5px;
      //                 color: #FFC01F;
      //                 width: 100%;
      //                 max-width: 169px;
      //                 height: 56px;
      //                 display: flex;
      //                 align-items: center;
      //                 justify-content: center;
      //                 font-family: "Poppins-SemiBold";
      //                 font-size: 15px;
      //                 line-height: 15px;
      //                 text-transform: uppercase;
      //                 cursor: pointer;
      //                 &:hover{
      //                     background: #FFC01F;
      //                     border: 1.5px solid #000000;
      //                     box-shadow: 0px 0px 4px #000000;
      //                     color: #fff;
      //                 }
      //             }
      //         }
      //     }
      // }
    }
  }
}

.banner_game_details {
  padding: 50px 0px;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (min-width: 768px) {
    padding: 35px 0;
    justify-content: space-between;
    max-width: 650px;
    margin: auto;
  }

  @media only screen and (min-width: 1024px) {
    justify-content: center;
  }

  @media only screen and (min-width: 1366px) {
    justify-content: space-between;
    padding: 100px 0px 50px 0;
    max-width: 1300px;
  }

  .detail_box {
    width: 300px;
    margin-bottom: 15px;
    position: relative;

    @media only screen and (min-width: 1024px) {
      width: 260px;
      padding: 0px 10px;
      margin-bottom: 20px;
    }

    @media only screen and (min-width: 1366px) {
      margin-bottom: 15px;
      margin-bottom: 0px;
      padding: 0 0;
    }

    @media only screen and (min-width: 1400px) {
      width: 300px;
    }

    .spinner-border {
      position: absolute;
      right: 18px;
      top: 30px;
    }

    .box-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #fff;
      height: 116px;
      width: 95%;
      margin: auto;
      padding: 15px 25px;
      background: rgba(21, 21, 21, 1);
      border-radius: 20px;
      cursor: pointer;

      img {
        width: 60px;

        @media only screen and (max-width: 1365px) {
          margin-right: 15px;
        }
      }

      .box_right {
        padding-left: 10px;

        .title {
          font-family: "Gilroy-Bold";
          font-size: 30px;
          line-height: 30px;
          padding-bottom: 10px;
        }

        .sub_title {
          font-family: "Gilroy-Bold";
          font-size: 15px;
          line-height: 15px;
          opacity: 0.5;
          width: 110px;
        }
      }

      &:hover {
        background: rgba(21, 21, 21, 0.8);
      }
    }
  }
}

/****************** Home  end **********************/

/****************** Home  about Sec**********************/
.about_Sec {
  ._GB_About_row {
    @media only screen and (min-width: 1366px) {
      justify-content: space-between;
    }

    .left_section {
      max-width: 569px;

      // width: 100%;
      @media only screen and (min-width: 768px) and (max-width: 1366px) {
        padding: 0px 15px;
      }

      ._GB_About_Title {
        padding-right: 0px !important;

        @media only screen and (min-width: 1366px) {
          line-height: 60px !important;
          font-size: 50px !important;
        }

        .right_section {
          max-width: 713px;

          @media only screen and (min-width: 768px) and (max-width: 1366px) {
            padding: 0px 15px;
          }

          ._GB_About_SubTitle {
            font-size: 23px !important;

            @media only screen and (max-width: 767px) {
              font-size: 15px !important;
            }
          }
        }
      }
    }
  }
}

/****************** Home about sec end**********************/

/****************** Tornament Watchlist**********************/
._GB_Tournament_Watchlist {
  background-color: #000;
  padding: 50px 0;

  @media only screen and (max-width: 767px) {
    padding: 0px 15px;
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    padding: 0 0 50px;
  }

  .watchlist_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;

    @media only screen and (max-width: 991px) {
      display: block;
      text-align: center;
    }

    @media only screen and (max-width: 767px) {
      margin-bottom: 40px;
    }

    .top-left {
      ._title {
        font-family: "Gilroy-Bold";
        font-size: 48px;
        line-height: 90px;
        color: #ffffff;

        @media only screen and (max-width: 767px) {
          font-size: 25px;
          line-height: 39px;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }

    .top-right {
      .game-list {
        display: flex;
        gap: 5px;

        @media only screen and (max-width: 991px) {
          justify-content: center;
        }

        @media only screen and (max-width: 767px) {
          display: none;
        }

        .list-item {
          height: 65px;
          width: 65px;
          border: 2px solid #484848;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          img {
            max-width: 83%;
          }

          &:hover {
            background: rgba(200, 0, 0, 0.5);
            border-color: #c80000;
          }
        }
      }
    }
  }

  .top-right {
    .game-list {
      display: flex;
      gap: 20px;

      @media only screen and(max-width:991px) {
        display: block;
      }

      .watchlist-box {
        background: #181818;
        border: 2px solid #2d2d2d;
        border-radius: 20px;
        max-width: 445px;
        width: 100%;
        height: 520px;

        @media only screen and(max-width:991px) {
          width: 100%;
          margin: 20px auto;
        }

        .watchlist-topimage {
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          // height: 217px;
          width: 100%;
          object-fit: cover;
          object-position: top;
              height: 275px;
        }

        .watchlist_boxitem {
          padding: 15px 30px;

          .watchlist_title {
            font-family: "Gilroy-Bold";
            color: #fff;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 20px;

            @media only screen and(max-width:767px) {
              font-size: 18px;
              line-height: 22px;
            }
            .seasonnumber {
              color: #979797;
              font-size: 18px;
            }
          }

          .joined-player {
            color: #fff;
            display: flex;
            align-items: center;
            font-family: "Poppins-SemiBold";
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 10px;

            .joinBox {
              align-items: center;
              border-radius: 10px;
              display: flex;
              font-family: "Gilroy-Bold";
              font-size: 18px;
              height: 44px;
              justify-content: center;
              line-height: 100%;
              text-align: center;
              width: 44px;
              background-color: #c80000;
              color: #fff;
              margin-right: 10px;
            }
          }

          .prize_achieved {
            color: #fff;
            display: flex;
            align-items: center;
            font-family: "Poppins-SemiBold";
            font-size: 16px;
            line-height: 16px;
            padding-bottom: 20px;
            border-bottom: 1px solid #404040;

            img {
              margin-right: 10px;
            }
          }

          .time_sec {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;

            @media only screen and(max-width:1300px) {
              display: block;
            }

            .time_left {
              flex: 0 0 50%;
              max-width: 50%;
              font-family: "Poppins-SemiBold";
              font-size: 16px;
              line-height: 16px;
              color: #fff;
              padding-right: 10px;

              @media only screen and(max-width:1300px) {
                flex: 0 0 100%;
                max-width: 100%;
              }

              .progress {
                height: 5px;
                background-color: #000;
                margin-bottom: 10px;

                .bg-success {
                  background-color: #40ab00 !important;
                }
              }
            }

            .time_right {
              flex: 0 0 50%;
              max-width: 50%;

              @media only screen and(max-width:1300px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin: 20px;
              }

              .joinBtn {
                // max-width: 162px;
                text-align: center;
                width: 100%;
                margin: auto;
                height: 43px;
                color: #fff;
                background-color: #e52420;
                background-image: linear-gradient(
                  to right,
                  #6d1513,
                  #e52420,
                  #6d1513
                );
                border-radius: 10px;
                border: 2px solid #ffffff;
                font-family: "Poppins-Bold";
                font-size: 17px;
                line-height: 17px;
                text-transform: uppercase;
                letter-spacing: 0.04em;
                padding: 11px 10px;
                text-decoration: none;
                cursor: pointer;

                &::after {
                  content: url("../images/arrow.png");
                  padding-left: 10px;
                }

                &:hover {
                  opacity: 0.5;
                }

                @media only screen and(min-width:1301px) and(max-width:1500px) {
                  font-size: 13px;
                }
              }

              @media only screen and(max-width:991px) {
                margin: auto;
                margin-top: 20px;
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.watchlist_bottom {
  display: flex;
  gap: 30px;

  @media only screen and (max-width: 991px) {
    display: block;
  }

  .watchlist-box {
    background: #181818;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    max-width: 445px;
    width: 100%;

    @media only screen and (max-width: 991px) {
      width: 100%;
      margin: 30px auto;
    }

    // @media only screen and (min-width: 768px){
    //   height: 520px;
    // }
    .watchlist-topimage {
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      // height: 217px;
      width: 100%;
      object-fit: cover;
      object-position: top;
          height: 275px;
    }

    .watchlist_boxitem {
      padding: 25px 25px 36px 25px;

      @media only screen and (max-width: 767px) {
        padding: 15px 20px;
      }

      .watchlist_title {
        font-family: "Gilroy-Bold";
        color: #fff;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 20px;

        @media only screen and(max-width:767px) {
          font-size: 18px;
          line-height: 22px;
        }
        .seasonnumber {
          color: #979797;
          font-size: 18px;
        }
      }

      .joined-player {
        color: #fff;
        display: flex;
        align-items: center;
        font-family: "Poppins-SemiBold";
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 14px;

        .joinBox {
          align-items: center;
          border-radius: 10px;
          display: flex;
          font-family: Gilroy-Bold;
          font-size: 18px;
          height: 44px;
          justify-content: center;
          line-height: 100%;
          text-align: center;
          width: 44px;
          background-color: #c80000;
          color: #fff;
          margin-right: 10px;
        }
      }

      .prize_achieved {
        color: #fff;
        display: flex;
        align-items: center;
        font-family: "Poppins-SemiBold";
        font-size: 16px;
        line-height: 16px;
        padding-bottom: 23px;
        border-bottom: 1px solid #404040;

        img {
          margin-right: 10px;
        }
      }

      .time_sec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 26px;

        @media only screen and (max-width: 1400px) {
          display: block;
          flex-direction: column;
        }

        .time_left {
          flex: 0 0 50%;
          max-width: 50%;

          @media only screen and (max-width: 1400px) {
            flex: 0 0 100%;
            max-width: 100%;
          }

          font-family: "Poppins-SemiBold";
          font-size: 16px;
          line-height: 16px;
          color: #fff;
          padding-right: 30px;

          .progress {
            height: 5px;
            background-color: #000;
            margin-bottom: 10px;

            .bg-success {
              background-color: #40ab00 !important;
            }
          }
        }

        .time_right {
          flex: 0 0 50%;
          max-width: 50%;

          @media only screen and (max-width: 1400px) {
            flex: 0 0 85%;
            max-width: 85%;
            margin: auto;
          }

          .joinBtn {
            // max-width: 162px;
            text-align: center;
            width: 100%;
            margin: auto;
            height: 43px;
            color: #fff;
            background-color: #e52420;
            background-image: linear-gradient(
              to right,
              #6d1513,
              #e52420,
              #6d1513
            );
            border-radius: 10px;
            border: 2px solid #ffffff;
            font-family: "Poppins-SemiBold";
            font-size: 15px;
            line-height: 17px;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            padding: 11px 12px;
            text-decoration: none;
            cursor: pointer;

            &::after {
              content: url("../images/arrow.png");
              padding-left: 10px;
            }

            &:hover {
              opacity: 0.5;
            }
          }

          @media only screen and(max-width: 1400px) {
            margin: auto;
            margin-top: 20px;
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.bottom-btnSec {
  margin-top: 25px;

  .seeMoreBtn {
    max-width: 223px;
    width: 100%;
    margin: auto;
    height: 56px;
    color: #fff;
    background-color: #000;
    border-radius: 10px;
    border: 2px solid #292929;
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    line-height: 17px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    padding: 11px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}

/****************** Tornament Watchlist end**********************/

/********************** Explore Start ************************/
// .GB_explore_wrap {
//   background-color: #000000;

//   .GB_explore {
//     background-color: #000000;

//     .GB_explore_inner {
//       padding-right: 0;
//       display: flex;
//       padding-top: 34px;
//       @media only screen and (min-width: 1366px) and (max-width: 1919px){
//         padding-top: 9px;
//       }
//     }

//     .column-right {
//       @media only screen and (max-width: 575px) {
//         width: 100%;
//         margin: auto;
//       }

//       .bet-topSec {
//         padding: 25px;

//         ._title {
//           font-family: "Gilroy-Bold";
//           font-size: 45px;
//           line-height: 52px;
//           color: #fff;
//           margin-bottom: 10px;

//           @media only screen and (max-width: 1024px) {
//             font-size: 38px;
//             line-height: 45px;
//           }

//           @media only screen and (max-width: 767px) {
//             font-size: 30px;
//             line-height: 35px;
//           }

//           @media only screen and(max-width: 1200px) {
//             text-align: center;
//           }
//         }

//         .sub_title {
//           font-family: "Gilroy-Bold";
//           font-size: 18px;
//           line-height: 18px;
//           color: #fff;
//           margin-bottom: 25px;

//           @media only screen and(max-width: 1200px) {
//             text-align: center;
//           }
//         }

//         .gamelist_sec {
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           flex-wrap: wrap;
//           border-bottom: 1px solid #2d2d2d;
//           padding-bottom: 30px;

//           @media only screen and (max-width: 767px) {
//             padding-bottom: 20px;
//           }

//           @media only screen and (max-width: 1920px) {
//             justify-content: space-evenly;
//           }

//           .game_list {
//             max-width: 316px;
//             width: 100%;
//             height: 80px;
//             margin-bottom: 25px;

//             @media only screen and (min-width: 1920px) {
//               max-width: 19%;
//             }

//             .livelist_box {
//               display: flex;
//               align-items: center;
//               background-color: #181818;
//               border: 2px solid #2d2d2d;
//               border-radius: 20px;
//               padding: 10px;

//               @media only screen and (min-width: 1920px) {
//                 justify-content: space-between;
//               }

//               .game_image {
//                 margin-right: 10px;
//               }

//               .game_name {
//                 font-family: "Gilroy-Bold";
//                 font-size: 15px;
//                 line-height: 15px;
//                 color: #fff;
//                 width: 180px;

//                 .viewers {
//                   font-family: "Gilroy-Medium";
//                   font-size: 15px;
//                   line-height: 15px;
//                   color: rgba(235, 235, 245, 0.6);
//                   margin-top: 5px;
//                 }
//               }

//               .live_menu {
//                 text-align: right;

//                 .game_menu {
//                   .dropdown-menu {
//                     transform: translate(-120px, 44px) !important;

//                     .dropdown-item {
//                       padding-left: 8px;
//                     }
//                   }

//                   .dropdown-toggle {
//                     background-color: #181818;
//                     border-color: #181818;
//                     padding-right: 0px;

//                     &::after {
//                       content: url("../images/betting/menu_icon.png") !important;
//                       background-color: #181818 !important;
//                       border-top: none !important;
//                     }

//                     &:focus {
//                       box-shadow: none !important;
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

/********************** Explore End ************************/

/********************** PopularLiveMatches Start ************************/
// .GB_PopularLiveMatches {
//   padding: 25px;

//   @media only screen and (max-width: 575px) {
//     padding: 15px 25px;
//   }

//   .title_Sec {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;

//     @media only screen and (max-width: 1200px) {
//       text-align: center;
//     }

//     @media only screen and (max-width: 767px) {
//       flex-direction: column;
//       margin-bottom: 20px;
//     }

//     ._title {
//       font-size: 40px;
//       line-height: 46px;
//       font-family: "Gilroy-Bold";
//       color: #fff;
//       margin-bottom: 5px;

//       @media only screen and (max-width: 767px) {
//         font-size: 25px;
//         line-height: 30px;
//       }
//     }

//     .sub_title {
//       font-size: 18px;
//       line-height: 18px;
//       color: rgba(235, 235, 245, 0.6);
//       font-family: "Gilroy-Medium";
//       margin-bottom: 10px;

//       @media only screen and (max-width: 767px) {
//         font-size: 16px;
//         line-height: 18px;
//       }
//     }

//     .title-right {
//       .sortby_btn {
//         .dropdown-toggle {
//           background: #181818;
//           border: 2px solid #2d2d2d;
//           color: #fff;
//           font-family: "Gilroy-Bold";
//           font-size: 16px;
//           line-height: 16px;
//           width: 160px;
//           height: 40px;
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//         }

//         .dropdown-menu {
//           .dropdown-item {
//             padding-left: 15px;
//           }
//         }
//       }
//     }
//   }

//   .liveStream_Sec {
//     display: flex;
//     justify-content: space-between;
//     border-bottom: 1px solid #2d2d2d;
//     padding-bottom: 30px;

//     @media only screen and (max-width: 1200px) {
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//     }

//     .liveStream-left {
//       flex: 0 0 34%;
//       max-width: 34%;

//       @media only screen and (max-width: 1200px) {
//         flex: 0 0 100%;
//         max-width: 100%;
//         margin-bottom: 15px;
//         width: 100%;
//       }

//       .liveStream-game {
//         position: relative;

//         .stream-mainlmg {
//           width: 100%;
//         }

//         .game-details {
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           background-color: #181818;
//           border-radius: 0px 0px 5px 5px;
//           padding: 15px 20px;

//           .gameDetails_left {
//             a {
//               text-decoration: none;
//             }

//             .streamer-name {
//               font-size: 20px;
//               line-height: 20px;
//               font-family: "Gilroy-Bold";
//               color: #fff;
//               margin-bottom: 5px;
//               text-decoration: none;

//               @media only screen and (max-width: 767px) {
//                 font-size: 18px;
//                 line-height: 20px;
//               }

//               @media only screen and (max-width: 575px) {
//                 font-size: 15px;
//                 line-height: 16px;
//               }
//             }

//             .game-name {
//               font-size: 15px;
//               line-height: 15px;
//               font-family: "Gilroy-Medium";
//               color: rgba(235, 235, 245, 0.6);
//               display: flex;
//               align-items: center;
//               margin-bottom: 5px;
//             }

//             .live_viewlist {
//               display: flex;
//               align-items: center;

//               .country-flag {
//                 display: flex;
//                 align-items: center;

//                 img {
//                   margin-right: 10px;
//                 }
//               }

//               .viewlist-details {
//                 font-size: 14px;
//                 line-height: 14px;
//                 font-family: "Gilroy-Medium";
//                 color: rgba(235, 235, 245, 0.6);
//                 display: flex;
//                 align-items: center;
//                 cursor: pointer;

//                 img {
//                   margin-right: 10px;
//                 }
//               }
//             }
//           }

//           .gameDetails_right {
//             text-align: right;

//             img {
//               margin-right: 5px;
//             }

//             .game_menu {
//               margin-bottom: 5px;

//               .dropdown-menu {
//                 transform: translate(-110px, 35px) !important;

//                 .dropdown-item {
//                   padding-left: 8px;
//                 }
//               }

//               .dropdown-toggle {
//                 background-color: #181818;
//                 border-color: #181818;
//                 padding: 0;

//                 &::after {
//                   content: url("../images/betting/menu_icon.png") !important;
//                   background-color: #181818 !important;
//                   border-top: none !important;
//                 }

//                 &:focus {
//                   box-shadow: none !important;
//                 }
//               }
//             }

//             color: #ffc01f;
//             font-size: 14px;
//             line-height: 21px;
//             font-family: "Poppins-SemiBold";
//           }
//         }
//       }

//       .gamelogo {
//         position: absolute;
//         top: 0px;
//         right: 0px;
//       }

//       .people_watching {
//         position: absolute;
//         bottom: 105px;
//         left: 10px;
//         color: #fff;
//         display: flex;
//         align-items: center;
//         font-size: 16px;
//         line-height: 16px;
//         font-family: "Gilroy-Bold";
//         cursor: pointer;

// @media only screen and (min-width: 1200px) and (max-width: 1700px){
//   bottom: 120px;
// }
// @media only screen and (min-width: 343px) and ( max-width: 575px){
//   bottom: 120px;
// }
//     @media only screen and (min-width: 320px) and (max-width: 551px) {
//       bottom: 119px;
//     }
//   }
// }

// .liveStream-right {
//   flex: 0 0 100%;
//   max-width: 100%;

//   @media only screen and (min-width: 1200px) {
//     flex: 0 0 65%;
//     max-width: 65%;
//   }

//   .liveStream_Box {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 18px;
//     width: 100%;
//     justify-content: center;

//     @media only screen and (max-width: 1600px) {
//       gap: 14px;
//     }

//     @media only screen and (max-width: 1600px) {
//       gap: 10px;
//     }

//     @media only screen and (max-width: 1200px) {
//       gap: 15px;
//     }

//     @media only screen and (max-width: 374px) {
//       gap: 10px;
//     }

//     .liveStream-game {
//       flex: 0 0 32%;
//       // max-width: 243px;
//       position: relative;

//       .stream-subimg {
//         width: 100%;
//       }

//       @media only screen and (max-width: 767px) {
//         flex: 0 0 48%;
//       }

//       @media only screen and (max-width: 575px) {
//         flex: 0 0 100%;
//       }

//       @media only screen and (min-width: 1600px) {
//         flex: 0 0 23%;
//       }

//       .game-details {
//         display: flex;
//         align-items: center;
// justify-content: space-between;
//       background-color: #181818;
//       width: 100%;
//       border-radius: 0px 0px 5px 5px;
//       padding: 15px 10px;

//       .gameDetails_left {
//         flex: 0 0 75%;
//         max-width: 75%;

//         .streamer-name {
//           font-size: 15px;
//           line-height: 16px;
//           font-family: "Gilroy-Bold";
//           color: #fff;
//           margin-bottom: 5px;
//           text-decoration: none;
//         }

//         .live_viewlist {
//           display: flex;
//           align-items: center;

//           .country-flag {
//             display: flex;
//             align-items: center;

//             img {
//               margin-right: 10px;
//             }
//           }

//           .viewlist-details {
//             font-size: 14px;
//             line-height: 14px;
//             font-family: "Gilroy-Medium";
//             color: rgba(235, 235, 245, 0.6);
//             display: flex;
//             align-items: center;
//             cursor: pointer;

//             img {
//               margin-right: 10px;
//             }
//           }
//         }
//       }

//       .gameDetails_right {
//         text-align: right;
//         flex: 0 0 25%;
//         max-width: 25%;
//         display: flex;
//         flex-direction: column;
//         gap: 10px;

//         .game_menu {
//           margin-bottom: 5px;

//           .dropdown-menu {
//             transform: translate(-105px, 44px) !important;

//             .dropdown-item {
//               padding-left: 8px;
//             }
//           }

//           .dropdown-toggle {
//             background-color: #181818;
//             border-color: #181818;
//             padding: 0;

//             &::after {
//               content: url("../images/betting/menu_icon.png") !important;
//               background-color: #181818 !important;
//               border-top: none !important;
//             }

//             &:focus {
//               box-shadow: none !important;
//             }
//           }
//         }

//         .earned_coin {
//           color: #ffc01f;
//           font-size: 14px;
//           line-height: 21px;
//           font-family: "Poppins-SemiBold";
//           display: flex;
//           justify-content: center;

//           img {
//             margin-right: 5px;
//           }
//         }
//       }
//     }
//   }
// }

// .gamelogo {
//   position: absolute;
//   top: 0px;
//   right: 0px;
// }

// .people_watching {
//   position: absolute;
//   bottom: 105px;
//   left: 10px;
//   color: #fff;
//   display: flex;
//   align-items: center;
//   font-size: 16px;
//   line-height: 16px;
//   font-family: "Gilroy-Bold";
//   cursor: pointer;
// @media only screen and (min-width: 1200px) and (max-width: 1700px){
//   bottom: 105px;
// }
// @media only screen and (min-width: 768px) and (max-width: 798px){
//   bottom: 105px;
// }
//       }

//       .live-stream {
//         position: absolute;
//         top: 5px;
//         left: 5px;
//       }
//     }
//   }
// }

// #viewlist-popup {
//   background: #181818 !important;
//   height: 48px;
//   width: 152px;
//   border: 2px solid #484848;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 12px;
//   font-size: 14px;
//   line-height: 14px;
//   font-family: "Gilroy-Medium";

//   .popover-arrow {
//     &::after {
//       content: url("../images/arrow-black.png");
//       border-top: none;
//       top: -1px;
//       left: -8px;
//     }
//   }

//   .popover-body {
//     color: rgba(235, 235, 245, 0.6);
//   }
// }

// #totalview-popup {
//   background: #181818 !important;
//   height: 40px;
//   width: 112px;
//   border: 2px solid #484848;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 12px;
//   font-size: 14px;
//   line-height: 14px;
//   font-family: "Gilroy-Medium";

//   .popover-arrow {
//     &::after {
//       content: url("../images/arrow-black.png");
//       border-top: none;
//       top: -1px;
//       left: -12px;
//     }
//   }

//   .popover-body {
//     color: rgba(235, 235, 245, 0.6);
//   }
// }

/********************** PopularLiveMatches End ************************/

/********************** BettingScoredboard start ************************/
// .GB_BettingScoreboard {
//   padding: 25px;
//   background-color: #000000;

//   .GB_BettingScoreboardtable {
//     border-bottom: 1px solid #2d2d2d;
//     padding-bottom: 35px;

//     ._title {
//       display: flex;
//       justify-content: space-between;
//       padding: 20px 0px;

//       @media only screen and (max-width: 767px) {
//         flex-direction: column;
//         align-items: center;
//         padding: 0px 0px 20px 0px;
//       }

//       h2 {
//         font-size: 40px;
//         line-height: 46px;
//         font-family: "Gilroy-Bold";
//         color: #fff;

//         @media only screen and (max-width: 767px) {
//           font-size: 28px;
//           line-height: 26px;
//         }
//       }

//       .title-left {
//         display: flex;
//         align-items: center;

//         img {
//           margin-right: 10px;
//         }
//       }

//       .title-right {
//         .gamechange {
//           display: flex;
//           align-items: center;
//           gap: 5px;
//           color: #fff;
//           font-family: "Gilroy-Bold";
//           font-size: 16px;
//           line-height: 16px;

//           .games-box {
//             height: 40px;
//             width: 40px;
//             border: 2px solid #2d2d2d;
//             border-radius: 8px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             cursor: pointer;
//             &.gamesbox_active {
//               border-color: #e52420;
//               background: rgba(200, 0, 0, 0.5);
//             }
//             &:hover {
//               border-color: #e52420;
//               background: rgba(200, 0, 0, 0.5);
//             }
//           }
//         }
//       }
//     }

//     .seasonResult_table {
//       background: #121212;
//       border: 2px solid #2d2d2d;
//       border-radius: 20px;
//       padding: 20px;

//       @media only screen and (max-width: 374px) {
//         padding: 15px;
//       }
//     }

//     .GB_device_th {
//       font-size: 16px;
//       line-height: 16px;
//       font-family: "Gilroy-Bold";
//       color: rgba(235, 235, 245, 0.6);

//       &:first-child {
//         border-top-left-radius: 10px;
//         border-top-right-radius: 10px;
//         padding-left: 25px;

//         @media only screen and (min-width: 768px) {
//           border-top-right-radius: 0px;
//         }
//       }

//       &:last-child {
//         padding-right: 10px;

//         @media only screen and (min-width: 768px) {
//           border-top-right-radius: 10px;
//         }
//       }
//     }

//     .GB_device_td {
//       text-align: left;
//       font-family: "Gilroy-Bold";
//       font-size: 18px;
//       line-height: 18px;
//       vertical-align: middle !important;

//       @media only screen and (max-width: 767px) {
//         font-size: 16px;
//       }

//       &::before {
//         @media only screen and (max-width: 479px) {
//           position: relative !important;
//         }
//       }

//       &:first-child {
//         padding-left: 25px;
//       }

//       &:nth-child(2) {
//         @media only screen and (min-width: 768px) {
//           width: 20%;
//         }

//         .profile-info {
//           @media only screen and (min-width: 768px) {
//             max-width: 250px;
//           }
//         }
//       }
//     }

//     .playerDetails {
//       .playerInfo {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;

//         @media only screen and (max-width: 374px) {
//           flex-direction: column;
//           text-align: right;
//         }

//         .profile-info {
//           img {
//             margin-right: 10px;
//           }
//         }

//         .country-flag {
//           img {
//             margin-left: 10px;
//           }
//         }
//       }
//     }

//     .pointDetails {
//       color: #ffc01f !important;

//       .playerInfo {
//         @media only screen and (max-width: 767px) {
//           display: block;
//         }

//         .profile-info {
//           img {
//             margin-right: 10px;
//           }
//         }
//       }
//     }

//     .kills {
//       color: #31ff1f !important;

//       .playerInfo {
//         @media only screen and (max-width: 767px) {
//           display: block;
//         }

//         img {
//           margin-right: 10px;
//         }
//       }
//     }
//   }
// }

/********************** BettingScoredboard End ************************/

/********************** StreamProfile start ************************/
// .GB_StreamerProfile {
//   background: #181818;
//   border-radius: 10px;
//   padding: 10px 15px;
//   display: flex;
//   margin-bottom: 25px;
//   align-items: center;
//   justify-content: space-between;
//   max-width: 100%;
//   width: 100%;
//   @media only screen and (max-width: 767px) {
//     flex-direction: column;
//   }
//   @media only screen and (min-width: 767px){
//     position: absolute;
//     background: #181818;
//     opacity: 0.88;
//     margin-bottom: 0px;
//     border-top-left-radius: 16px;
//     border-top-right-radius: 16px;
//     border-bottom-left-radius: 0;
//     border-bottom-right-radius: 0;
//   }

//   .streamerProfile-left {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     @media only screen and (max-width: 767px){
//       width: 100%;
//       margin-bottom: 15px;
//     }
//     @media only screen and (max-width: 490px) {
//       flex-direction: column;
//     }
//     .profile-details {
//       display: flex;
//       align-items: center;
//       margin-right: 30px;

//       @media only screen and (max-width: 575px) {
//         margin-right: 0px;
//       }
//       @media only screen and (max-width: 490px) {
//         margin-bottom: 10px;
//       }

//       .profile-image {
//         img {
//           height: 32px;
//           width: 32px;
//           border-radius: 50%;
//           margin-right: 5px;
//         }
//       }

//       .profile-right {
//         .profile-name {
//           display: flex;
//           align-items: center;
//           font-family: "Gilroy-Bold";
//           font-size: 16px;
//           line-height: 18px;
//           color: #fff;
//           margin-bottom: 5px;

//           .country-flag {
//             margin-left: 5px;
//           }
//         }

//         .stream-link {
//           display: flex;
//           align-items: center;
//           font-size: 12px;
//           line-height: 12px;
//           color: #fff;
//           font-family: "Poppins-SemiBold";

//           img {
//             margin-right: 5px;
//           }

//           .stream-status {
//             img {
//               margin-left: 5px;
//             }
//           }
//         }
//       }
//     }

//     .bet-history {
//       .won-details {
//         color: #fff;
//         font-family: "Gilroy-Bold";
//         font-size: 14px;
//         line-height: 16px;
//         margin-bottom: 5px;

//         img {
//           margin-right: 5px;
//         }
//       }

//       .total-bets {
//         display: flex;
//         align-items: center;
//         color: rgba(235, 235, 245, 0.6);
//         font-family: "Gilroy-Bold";
//         font-size: 12px;
//         line-height: 14px;

//         .coins-earned {
//           color: #ffc01f;
//           font-family: "Gilroy-Bold";
//           font-size: 12px;
//           line-height: 14px;
//           margin-left: 5px;

//           img {
//             margin-right: 5px;
//           }
//         }
//       }
//     }
//   }

//   .streamerProfile-right {
//     display: flex;
//     align-items: center;
//     gap: 20px;

//     .view-details {
//       color: rgba(235, 235, 245, 0.6);
//       font-family: "Gilroy-Bold";
//       font-size: 10px;
//       line-height: 11px;

//       img {
//         margin-right: 5px;
//       }
//     }

//     .follow-sec {
//       background: #222222;
//       border-radius: 6px;
//       padding: 12px 8px;
//       color: #fff;
//       font-family: "Gilroy-Bold";
//       font-size: 14px;
//       line-height: 16px;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       max-width: 94px;
//       height: 30px;
//       cursor: pointer;

//       &:hover {
//         .hover-follow {
//           display: block;
//           margin-right: 5px;
//         }

//         .follow-btn {
//           display: none;
//         }
//       }

//       .hover-follow {
//         display: none;
//       }

//       .follow-btn {
//         display: block;
//         margin-right: 5px;
//       }
//     }

//     .profile-share {
//       display: flex;
//       align-items: center;
//       gap: 5px;

//       .share-box {
//         border: 1px solid #727070;
//         width: 30px;
//         height: 30px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         border-radius: 6px;
//         cursor: pointer;

//         &:hover {
//           opacity: 0.5;
//         }
//       }

//       .share-sec {
//       }
//     }
//   }
// }

// #share-popup {
//   background: #181818 !important;
//   height: 80px;
//   width: 316px;
//   border: 2px solid #484848;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 12px;
//   font-size: 14px;
//   line-height: 14px;
//   font-family: "Gilroy-Medium";
//   z-index: 9999;

//   .popover-arrow {
//     &::after {
//       content: url("../images/arrow-black.png");
//       border-top: none;
//       top: -2px;
//       left: -12px;
//     }
//   }

//   .popover-body {
//     padding: 0px;

//     .share-box {
//       color: rgba(235, 235, 245, 0.6);

//       .share-icons {
//         display: flex;
//         align-items: center;
//         gap: 10px;
//         margin-top: 5px;
//       }
//     }
//   }
// }

#follow-popup {
  background: #181818 !important;
  height: 46px;
  width: 236px;
  border: 2px solid #484848;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gilroy-Medium";
  z-index: 9999;

  .popover-arrow {
    &::after {
      content: url("../images/arrow-black.png");
      border-top: none;
      top: -2px;
      left: -12px;
    }
  }

  .popover-body {
    padding: 0px;

    .follow-box {
      color: rgba(235, 235, 245, 0.6);

      .user-profile {
        color: #fff;
      }
    }
  }
}

/********************** StreamProfile End ************************/

/********************** streamChat start ************************/
// .stream-live {
//   position: relative;
// }

// .GB_streamChat {
//   background: rgba(0, 0, 0, 0.9);
//   border: 2px solid rgba(69, 69, 69, 0.6);
//   border-radius: 10px;
//   max-width: 384px;
//   width: 100%;
//   position: absolute;
//   top: 0px;
//   right: 3px;
//   padding-bottom: 10px;
//   @media only screen and (min-width: 768px) {
//     top: 85px;
//     right: 25px;
//     height: 275px;
//   }

//   .chat_top {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 5px 0px 10px;
//     border-bottom: 2px solid #242424;

//     .chat-logo {
//       img {
//         margin-left: 10px;
//         border-right: 2px solid #242424;
//         padding-right: 5px;
//       }
//     }

//     .dataUsage {
//       display: flex;
//       align-items: center;
//       font-family: "Gilroy-SemiBold";
//       font-size: 14px;
//       line-height: 16px;
//       color: #40ab00;

//       img {
//         margin-right: 5px;
//       }

//       .online-status {
//         color: rgba(235, 235, 245, 0.6);
//         display: flex;
//         align-items: center;
//         margin-left: 10px;

//         img {
//           margin-right: 5px;
//         }
//       }
//     }

//     .chat-close {
//       cursor: pointer;

//       &:hover {
//         opacity: 0.5;
//       }

//       img {
//         margin-right: 10px;
//       }
//     }
//   }

//   .chat-data {
//     font-family: "Gilroy-SemiBold";
//     font-size: 14px;
//     line-height: 16px;
//     padding: 15px 25px;

//     .messager-name {
//       color: #40ab00;
//       margin-bottom: 10px;

//       .message {
//         color: rgba(235, 235, 245, 0.6);
//         margin-left: 5px;
//       }
//     }
//   }

//   .chat-bottom {
//     display: flex;
//     align-items: center;
//     gap: 5px;
//     padding: 0px 25px;

//     .chat-field {
//       position: relative !important;
//       width: 100%;

//       input {
//         background-color: #000;
//         border: 2px solid #545454;
//         border-radius: 7px;
//         max-width: 300px;
//         width: 100%;
//         height: 41px;
//         font-family: "Gilroy-SemiBold";
//         font-size: 14px;
//         line-height: 16px;
//         padding: 11px;
//         color: rgba(235, 235, 245, 0.6);
//       }
//     }

//     .smiley-sec {
//       top: 5px;
//       right: 8px;
//       cursor: pointer;
//       position: absolute !important;
//     }

//     .send-btn {
//       cursor: pointer;

//       &:hover {
//         opacity: 0.5;
//       }
//     }
//   }
// }

/********************** streamChat End ************************/

// ._GB_bettingExpand {
//   padding: 25px !important;
//   justify-content: space-between;
//   @media only screen and (max-width: 767px) {
//     padding: 15px 0;
//   }

//   .container {
//     @media only screen and (min-width: 768px)  and (max-width: 1200px){
//       max-width: 720px !important;
//     }

//     @media only screen and (min-width: 1201px)  and (max-width: 1365px){
//       max-width: 910px !important;
//     }
//     @media only screen and (min-width: 1366px)  and (max-width: 1600px){
//       max-width: 100% !important;
//     }
//     @media only screen and (min-width: 1600px) {
//       max-width: 100% !important;
//     }
//     .subcribe_section_inner {
//       .left{
//         .subscribe_form {
//           @media only screen and (min-width: 992px) and (max-width: 1600px){
//             flex-direction: column;
//           }
//           input{
//             @media only screen and (min-width: 992px) and (max-width: 1600px){
//               margin-bottom: 10px;
//             }
//           }
//           .subscribe_btn{
//             @media only screen and (min-width: 992px) and (max-width: 1600px){
//               margin-left: 0;
//             }
//           }
//         }
//       }
//       .right{
//         .discord_cls{
//           img{
//             @media only screen and (min-width: 992px) and (max-width: 1200px){
//               margin-bottom: 10px;
//             }
//           }
//         }
//         .twitter_cls{
//           img{
//             @media only screen and (min-width: 992px) and (max-width: 1200px){
//               margin-bottom: 10px;
//             }
//           }
//         }
//       }

//     }
//   }
// }

/***************** challengeNotication Start ****************/
// .GB_challengeNotication {
//   background: #181818;
//   border: 2px solid #2d2d2d;
//   box-shadow: 0px -10px 4px rgba(0, 0, 0, 0.27);
//   border-radius: 20px 20px 0px 0px;

//   &.show {
//     display: block;
//     transition: top 2s ease 0s;
//     -webkit-animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
//     animation: fade-in 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

//     @-webkit-keyframes fade-in {
//       0% {
//         opacity: 0;
//       }

//       100% {
//         opacity: 1;
//       }
//     }

//     @keyframes fade-in {
//       0% {
//         opacity: 0;
//       }

//       100% {
//         opacity: 1;
//       }
//     }
//   }

//   &.hide {
//     display: none;
//     transition: top 2s ease 0s;
//   }

//   .notification-close {
//     display: flex;
//     justify-content: flex-end;
//     padding: 15px;
//     cursor: pointer;
//   }

//   .challengeNotication-inner {
//     padding: 0px 25px;

//     .notify-message {
//       color: #fff;
//       font-family: "Gilroy-Bold";
//       font-size: 16px;
//       line-height: 19px;
//       text-align: center;
//       margin-bottom: 20px;
//     }

//     .notify-btn {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       gap: 15px;

//       .yes-btn {
//         background: linear-gradient(
//           90deg,
//           #1f7700 1.07%,
//           #52dd21 49.73%,
//           #1f7700 100.09%
//         );
//         border: 2px solid #ffffff;
//         border-radius: 10px;
//         color: #fff;
//         max-width: 111px;
//         height: 47px;
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-family: "Gilroy-Bold";
//         font-size: 17px;
//         line-height: 100%;
//         text-transform: uppercase;
//         cursor: pointer;

//         &:hover {
//           opacity: 0.5;
//         }
//       }

//       .no-btn {
//         background: linear-gradient(
//           90deg,
//           #701513 1.07%,
//           #e52420 49.73%,
//           #6d1513 100.09%
//         );
//         border: 2px solid #ffffff;
//         color: #fff;
//         border-radius: 10px;
//         max-width: 111px;
//         height: 47px;
//         width: 100%;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         font-family: "Gilroy-Bold";
//         font-size: 17px;
//         line-height: 100%;
//         text-transform: uppercase;
//         cursor: pointer;

//         &:hover {
//           opacity: 0.5;
//         }
//       }
//     }

//     .challenge-amount {
//       display: flex;
//       align-items: flex-end;
//       justify-content: center;
//       margin-top: 20px;
//       gap: 15px;

//       .challenge-amount-left {
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         input {
//           background-color: #000000;
//           border: 2px solid #2d2d2d;
//           border-radius: 12px;
//           max-width: 123px;
//           height: 56px;
//           font-family: "Gilroy-Bold";
//           font-size: 26px;
//           line-height: 100%;
//           color: #fe5242;
//           box-shadow: none;
//           outline: none;
//           text-align: center;
//         }
//       }
//     }

//     .challenge-amount-right {
//       .converted-amout {
//         font-family: "Gilroy-SemiBold";
//         color: rgba(255, 255, 255, 0.5);
//         font-size: 13px;
//         line-height: 16px;
//         margin-bottom: 5px;

//         img {
//           margin-right: 5px;
//           width: 26px;
//         }
//       }

//       .challenge-amount-select {
//         #challenge-dropdown {
//           background: #262627;
//           border-radius: 8px;
//           outline: none;
//           box-shadow: none;
//           border: 1px solid #262627;
//           display: flex;
//           align-items: center;
//           max-width: 96px;
//           height: 37px;
//           width: 100%;

//           &::after {
//             border-top: 0;
//           }
//           .item-token {
//             display: flex !important;
//             align-items: center !important;
//             margin-right: 5px;
//             .coin-img{
//               display: flex;
//               align-items: center;
//               margin-right: 2px;
//             }
//             .coin-name {
//               font-family: "Gilroy-Bold";
//               font-size: 16px;
//               line-height: 18px;
//             }
//           }
//         }
//         .dropdown-menu{
//           min-width: 96px;
//           .dropdown-item{
//             justify-content: center;
//             .item-token-list{
//               justify-content: center;
//               .coin-img{
//                 margin-right: 5px;
//               }
//             }
//           }
//         }
//       }
//     }

//     .challenge-send {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       gap: 15px;

//       .send-left {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         gap: 10px;

//         .coin-box {
//           width: 26px;
//           height: 24px;
//           background-color: #000000;
//           border: 1.5px solid #484848;
//           border-radius: 6px;
//           font-size: 12px;
//           line-height: 100%;
//           color: #ffffff;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           font-family: "Poppins-SemiBold";
//           font-size: 12px;
//           line-height: 12px;

//           &.active-coinbox {
//             background: rgba(255, 192, 31, 0.4);
//             border: 1.5px solid #ffc01f;
//           }
//         }
//       }

//       .send-right {
//         button {
//           display: block !important;
//           width: 113px;
//           height: 36px;
//           margin: 10px auto 15px;
//           font-family: "Poppins-SemiBold";
//           text-transform: uppercase;
//           text-decoration: none;
//           color: #ffffff;
//           border-radius: 10px;
//           padding: 13px 10px 15px 25px;
//           background: url(../images/white_arrow.png) no-repeat 19% center;
//           cursor: pointer;
//           background-color: #141414;
//           border: 2px solid #292929;
//           border-radius: 10px;
//           color: #fff;
//           font-size: 12px;
//           line-height: 12px;

//           &:hover {
//             opacity: 0.5;
//           }

//           @media only screen and (max-width: 767px) {
//             padding: 10px 0 10px 30px;
//             font-size: 14px;
//             line-height: 14px;
//             width: 100px;
//           }
//         }
//       }
//     }

//     .minimize-notofication {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       cursor: pointer;
//       margin-bottom: 10px;
//     }
//   }
// }

/***************** challengeNotication End ****************/
