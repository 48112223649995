._GB_redoSettings{
  position: relative;
  top: 35px;
  @media only screen and (max-width: 1024px){
    top: 0px;
}
}
.rsettings_wrap {
    background-image: url("../../images/redo/settings_bg.png");
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: cover;
    border: 1px solid rgba(59, 77, 94, 0.5);
    border-top-left-radius: 18px;
    border-bottom: none;
    opacity: var(--Corner-radius-xs, 1);
    background-color: var(--Background---grey-Grey---700---GB, #0E1216);
    @media only screen and (max-width: 767px) {
      background-image: url("../../images/redo/settings_bg_mobile.png");
      background-position: 100% 85%;
    }
    @media only screen and (max-width: 1024px) {
      border: none;
    }
    
  .rsettings_banner {
    background-image: url("../../images/redo/rsettings-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    border-radius: var(--Spacing-4xl, 40px);
    border: 1px solid rgba(59, 77, 94, 0.5);
    opacity: var(--Corner-radius-xs, 1);
    //margin: 30px;
    margin-top: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      background-image: url("../../images/redo/rsettings_bg_mobile.png");
      margin: 0px;
      border-radius: 0;
    }
    @media only screen and (max-width: 480px) {
      height: 128px;
    }
    // width:100%;

    .rsettings_profileImg {
      position: relative;
      //height: 154px;
      //width: 154px;
      max-height: 150px;
      max-width: 150px;
      border-radius: 56px;
      // background: #000;
      display: flex;
      //align-items: center;
      align-items: flex-end;
      justify-content: center;
      top: 73px;
      min-width: 40px;
      min-height: 40px;
      @media only screen and (max-width: 767px) {
        max-height: 80px;
        max-width: 80px;
        top: 152px;
        border-radius: 32px;
      }
      @media only screen and (max-width: 480px) {
        top: 90px;
      }
      
      .profileImg {
        // height: 154px;
        // width: 154px;
        width: 100%;
        border-radius: 56px;
        object-fit: cover;
        @media only screen and (max-width: 767px) {
          border-radius: 32px;
        }
      }
      .uploadImg {
        position: absolute;
        bottom: 0px;
        right: 0px;
        cursor: pointer;
        @media only screen and (max-width: 767px) {
          bottom: -4px;
          right: -12px;
        }
      }
      label{
        img{
          width:40px;
          height:40px;
          @media only screen and (max-width: 767px) {
            width:32px;
            height:32px;
          }
        }
      }
    }
    

  }

  .rsettings_userInfoSec {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    //margin-top: -152px;
    background-image: url("../../images/redo/profile_bg.png");
    background-repeat: no-repeat;
    background-position: 50% 100%;
    @media only screen and (max-width: 767px) {
      //margin-top: -38px;
      background-image: url("../../images/redo/profile_bg_mobile.png");
    }
    


    .rsettings_userName {
      //margin-top: 16px;
      margin-top: 46px;
      margin-bottom: 12px;
      h1 {
        color: var(--Color-Neutral-100, #f2f4f7);
        text-align: center;
        font-family: "Gilroy-Medium";
        // font-size: 24px;
        font-size: 34px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        @media only screen and (max-width: 1365px){
          font-size: 24px;
        }
        @media only screen and (max-width: 1200px){
          font-size: 20px;
        }
        @media only screen and (max-width: 767px) {
          font-size: 18px;
        }
      }
    }
  }

  .rsettings_contentSec {
    .rsettings_container {
      // max-width: 725px;
      max-width: 673px;
      width: 100%;
      margin: 0 auto;
      padding: 40px 15px 15px;
      @media only screen and (max-width: 767px) {
        padding: 10px 16px 15px;
      }
      .rsettings_connectSec {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 25px;
        @media only screen and (max-width: 600px) {
          flex-direction: column;
        }
        .rsettings_connect {
          border-radius: var(--Spacing-xl, 20px);
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---600---GB, #141a1f);
          padding: 20px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 50px;
          // height: 72px;
          height: 62px;
          max-width: 301px;
          width: 100%;
          @media only screen and (max-width: 767px) {
            gap: 20px;
            max-width: 100%;
          }
          .rsettings_connectImg {
            img {
              height: 16px;
            }
          }
          .rsettings_connectAdd {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              color: var(--Background---grey-Grey---200---GB, #92a6ba);
              text-align: right;
              font-family: "Gilroy-Medium";
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              margin-bottom: 0;
              @media only screen and (max-width: 767px) {
                font-size: 12px;
              }
            }
            span {
              padding-left: 16px;
            }
          }
        }
      }
      .rsettings_tabSec {
        border-radius: var(--Spacing-xl, 20px);
        // border: 1.5px solid var(--Background---grey-Grey---50---GB, #E3E8ED);
        border: 1px solid rgba(59, 77, 94, 0.5);
        opacity: var(--Corner-radius-xs, 1);
        background: rgba(14, 18, 22, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 32px;
        @media only screen and (max-width: 767px) {
          padding: 8px;
        }
        @media only screen and (max-width: 400px){
          display: none;
        }
        .rsettings_tab {
          color: var(--Background---grey-Grey---300---GB, #5d7893);
          text-align: center;
          font-family: "Gilroy-Medium";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 0px;
          background: none;
          border: none;
          cursor: pointer;
          position: relative;
          @media only screen and (max-width: 767px){
            font-size: 14px;
          }
          &:hover {
            color: #fff;
          }
          &.active {
            color: #fff;
            text-shadow: 0 0 8px hsla(0,0%,100%,.4);
            font-family: "Gilroy-Bold";
            &::before {
              content: url("../../images/redo/tab_sel-icon.png");
              bottom: -38px;
              left: 27%;
              position: absolute;
              animation: pulseAnimation 2s infinite;
              border-radius: 50%;
              height:32px;
              @media only screen and (max-width: 767px) {
                bottom: -29px;
              }
            }
          }
          @media only screen and (max-width: 767px) {
            padding:8px;
           
          }
          @media only screen and (max-width: 400px) {
            padding: 4px;
          }
          @media only screen and (max-width: 340px) {
            font-size: 12px;
          }
        }
      }
      .rsettings_tabSec_mobile{
        @media only screen and (min-width: 401px) {
          display: none;
        }
        
        .settings_tab_dropdown{
          .dropdown-menu.show {
            margin-top: 0px;
            width: 100%;
            .dropdown-item
            {
              &.active{
                background: transparent !important;
                color: #51e20e !important;
              }
              &:hover{
                background-color: transparent !important;
                color: #51e20e !important;
              }
            }
            
        }
        button{
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent !important;
          border: 1px solid rgba(59, 77, 94, .5);
          border-radius: 20px;
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          padding: 10px 20px;
          text-transform: capitalize;
          &::after{
            position: absolute;
            right: 20px;
              border-top: none !important;
              border-right: none !important;
              content: "";
              background-image: url("../../images/redo/whitearrow-down.png");
              background-repeat: no-repeat;
              width: 24px;
              height: 24px;
          

          }
        }
        }
      }
    }
  }

  .tabDetailsSec {
    padding: 30px 20px 60px;
    // myprofile Section start...
    @media only screen and (max-width: 767px) {
      padding: 17px 16px 40px;
    }
    @media only screen and (max-width: 360px) {
      padding-bottom: 26px;
    }
    .myprofileSec {
      padding: 15px 0px;
      @media only screen and (max-width: 767px) {
        padding:0px;
      }
      .myprofileContainer {
        // max-width: 946px;
        max-width: 964px;
        width: 100%;
        margin: 0 auto;
        padding: 15px 0px;
        display: flex;
        // align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
          padding:0px;
          gap: 40px;
        }
        @media only screen and (max-width: 990px) {
          flex-direction: column;
          gap: 50px;
          align-items: center;
        }
        .myprofileBox {
          max-width: 402px;
          width: 100%;
          .myprofileHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            margin-bottom: 30px;
            @media only screen and (max-width: 767px) {
              margin-bottom: 8px;
            }
            .title {
              h3 {
                color: var(--Background---grey-Grey---50---GB, #e3e8ed);
                font-family: "Gilroy-Medium";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0px;
                @media only screen and (max-width: 767px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
            .saveSec {
              p {
                color: var(--Background---grey-Grey---300---GB, #5d7893);
                text-align: right;
                font-family: "Gilroy-Medium";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin-bottom: 0px;
                @media only screen and (max-width: 767px) {
                  font-size: 12px;
                }
              }
            }
          }
          .profileDetails {
            @media only screen and (min-width: 991px) {
              min-height: 340px;
            }
            .inputRow {
              // position: relative;
              margin-top: 16px;
              display: flex;
              justify-content: space-between;
              gap: 10px;
              // width:100%;
              .inputCol {
                align-items: center;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                // input{
                //     border: 1px solid rgba(59,77,94,0.5);
                // }
                .myprofileDropdown {
                  position: relative;
                  width: 100%;
                  button {
                    border-radius: var(--Spacing-2xl, 24px);
                    opacity: var(--Corner-radius-xs, 1);
                    background: var(
                      --Background---grey-Grey---500---GB,
                      #181f26
                    );
                    color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    padding: 12px;
                    font-size: 16px;
                    font-family: "Inter-Medium";
                    font-weight: 400;
                    line-height: 18px;
                    // width: 196px;
                    width: 100%;
                    height: 64px;
                    text-align: left;
                    @media only screen and (max-width: 767px) {
                      height: 48px;
                    }
                    &::after {
                      content: url("../../images/redo/drop-arrow-down.png");
                      border: none;
                      position: absolute;
                      right: 10px;
                    }
                  }
                  .dropdown-menu.show {
                    background: var(
                      --Background---grey-Grey---500---GB,
                      #181f26
                    );
                    width: 100%;
                    a {
                      color: var(
                        --Colors-Grayscale-Text-icon-Subtitle,
                        #767a87
                      );
                      font-family: "Gilroy-Medium";
                      background: var(
                        --Background---grey-Grey---500---GB,
                        #181f26
                      );
                      &:hover {
                        color: #fff !important;
                      }
                    }
                  }
                }
              }
              input {
                border-radius: var(--Spacing-2xl, 24px);
                opacity: var(--Corner-radius-xs, 1);
                background: var(--Background---grey-Grey---500---GB, #181f26);
                // color: var(--Colors-Grayscale-Text-icon-Subtitle, #767A87);
                color: #fff;
                border: 1px solid rgba(59, 77, 94, 0.5);
                padding: 23px 20px 12px;
                // font-size: 16px;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                // font-family: "Gilroy-Medium";
                font-family: "Inter-Regular";
                // width: 402px;
                width: 100%;
                height: 64px;
                &:focus {
                  outline: none;
                }
                @media only screen and (max-width: 767px) {
                  height: 48px;
                  border: 1px solid transparent;
                }
                @media only screen and (max-width: 480px) {
                  width: 100%;
                }
              }

              input[type="date"]:required:invalid::-webkit-datetime-edit {
                color: transparent;
              }
              input[type="date"]:focus::-webkit-datetime-edit {
                color: #fff !important;
              }

              // .inputDob{
              //     cursor: pointer;
              // }
              select {
                border-radius: var(--Spacing-2xl, 24px);
                opacity: var(--Corner-radius-xs, 1);
                background: var(--Background---grey-Grey---500---GB, #181f26);
                // color: var(--Colors-Grayscale-Text-icon-Subtitle, #767A87);
                color: #fff;
                border: 1px solid rgba(59, 77, 94, 0.5);
                padding: 23px 21px 12px;
                // font-size: 16px;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                // font-family: "Gilroy-Medium";
                font-family: "Inter-Regular";
                width: 100%;
                height: 64px;
                -webkit-appearance: initial;
                cursor: pointer;
                &:focus {
                  outline: none;
                }
                @media only screen and (max-width: 767px) {
                  height: 48px;
                  padding: 17px 21px 7px;
                }
              }
              .dobLabel{
                font-size: 16px;
                font-weight: 400;
                line-height: 18px;
                font-family: "Inter-Regular";
                color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
                @media only screen and (max-width: 767px) {
                  font-size: 14px;
                }
              }
              .react-datepicker-wrapper {
                width: 100%;
              }
            }

            .errorinputRow {
              display: flex;
              gap: 20px;
              .inputCol {
                display: flex;
              }
            }
          }
          .buttonSec {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            @media only screen and (max-width: 990px) {
              padding: 20px 0px;
            }
            @media only screen and (max-width: 767px) {
              padding: 0px;
            }
            button {
              border-radius: var(--Spacing-2xl, 24px);
              // border: 1.5px solid #FFF;
              border: 1.5px solid #fff;
              background: var(--Primary-Green---50---GB, #f1feec);
              box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.02),
                0px 16px 56px -4px rgba(241, 254, 236, 0.24);
              color: var(--Background---grey-Grey---700---GB, #0e1216);
              font-family: "Gilroy-Medium";
              // font-size: 16px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              width: 100%;
              // height: 56px;
              height: 48px;
              @media only screen and (max-width: 767px) {
                font-size: 16px;
              }
              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
        .rightBorder {
          height: 446px;
          border: 1px solid #181f26;
          margin: 0px 10px;
          @media only screen and (max-width: 990px) {
            display: none;
          }
        }
      }
    }
    // myprofile Section End...

    // Security Section Start...
    .securitySec {
      .securityContainer {
        max-width: 724px;
        width: 100%;
        margin: 0 auto;
        .passwordSection {
          .passwd-addSec {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin: 40px 0px;
            @media only screen and (max-width: 767px) {
              flex-direction: column;
              margin:0px;
            }
            .titleSec {
              h3 {
                color: #fff;
                font-family: "Gilroy-Medium";
                // font-size: 18px;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                @media only screen and (max-width: 767px) {
                  margin-bottom: 0px;
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              .desc {
                color: var(--Background---grey-Grey---300---GB, #5d7893);
                font-family: "Gilroy-Medium";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0px;
                @media only screen and (max-width: 767px){
                  font-size: 14px;
                  line-height: 24px;
                }

              }
            }
            .buttonSec {
              @media only screen and (max-width: 767px) {
                display: flex;
                margin-bottom: 32px;
              }
              button {
                border-radius: var(--Spacing-lg, 16px);
                border: 1px solid rgba(78, 255, 97, 0.4);
                background: var(--Primary-Green---50---GB, #f1feec);
                backdrop-filter: blur(32px);
                color: var(--Background---grey-Grey---950---GB, #020303);
                font-family: "Inter-Medium";
                // font-size: 14px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                // padding: 12px 18px;
                padding: 12px 7px;
                width: 140px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  opacity: 0.6;
                }
                @media only screen and (max-width: 767px) {
                  height: 44px;
                }
              }
              .changePasswdBtn{
                // width: 156px;
              }
            }
          }
          .passwdSec {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            .titleSec {
              h3 {
                color: #fff;
                font-family: "Gilroy-Medium";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                @media only screen and (max-width: 767px){
                  font-size: 16px;
                  line-height: 24px;
                }
              }
              .number {
                color: #fff;
                font-family: "Gilroy-Medium";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
              }
              .desc {
                color: var(--Background---grey-Grey---300---GB, #5d7893);
                font-family: "Gilroy-Medium";
                // font-size: 16px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0px;
              }
            }
            .secInputRow {
              display: flex;
              justify-content: space-between;
              gap: 16px;
              @media only screen and (max-width: 767px) {
                flex-wrap: wrap;
              }
              @media only screen and (max-width: 480px) {
                flex-direction: column;
              }
              .secInputCol {
                width: 100%;
                input {
                  border-radius: var(--Spacing-2xl, 24px);
                  opacity: var(--Corner-radius-xs, 1);
                  background: var(--Background---grey-Grey---500---GB, #181f26);
                  color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
                  // color: #FFF;
                  border: 1px solid rgba(59, 77, 94, 0.5);
                  // font-size: 16px;
                  font-size: 14px;
                  font-family: "Inter-Medium";
                  font-style: normal;
                  font-weight: 500;
                  line-height: 26px;
                  padding: 15px;
                  width: 100%;
                  @media only screen and (max-width: 767px) {
                    padding: 10px;
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
            .passwordValidations {
              display: flex;
              flex-wrap: wrap;
              // justify-content: space-between;
              // width: 80%;

              .col-md-6 {
                @media only screen and (max-width: 480px) {
                  flex: 0 0 50%;
                 }
                 @media only screen and (max-width: 360px) {
                  flex: 0 0 100%;
                 }
                .pass_ver_name {
                  color: #fff;
                  font-family: "Gilroy-Medium";
                  // font-size: 14px;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 12px;
                }
                .pass_tick {
                  background: url("../../images/redo/pass_tick.png");
                  background-repeat: no-repeat;
                  padding-left: 20px;
                }
                .pass_untick {
                  background: url("../../images/redo/pass_untick.png");
                  background-repeat: no-repeat;
                  padding-left: 20px;
                }
              }
            }
            .passwdButtonSec {
              display: flex;
              gap: 10px;
              @media only screen and (max-width: 767px) {
                justify-content: center;
              }
              button {
                font-family: "Gilroy-Medium";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                width: 156px;
                height: 48px;
                padding: 16px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media only screen and (max-width: 767px) {
                  font-size: 14px;
                }
                &.saveBtn {
                  border-radius: var(--Spacing-lg, 24px);
                  border: 1px solid rgba(78, 255, 97, 0.4);
                  background: var(--Primary-Green---50---GB, #f1feec);
                  -webkit-backdrop-filter: blur(32px);
                  backdrop-filter: blur(32px);
                  color: var(--Background---grey-Grey---950---GB, #020303);
                  &:hover {
                    opacity: 0.7;
                  }
                }
                &.cancelBtn {
                  border: 1px solid rgba(59, 77, 94, 0.5);
                  border-radius: var(--Spacing-2xl, 24px);
                  background: var(--Background---grey-Grey---400---GB, #3b4d5e);
                  color: #fff;
                  &:hover {
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }
        .phone-numberSection{
          .addSec {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin: 40px 0px;
            @media only screen and (max-width: 767px) {
              flex-direction: column;
              margin: 32px 0px;
            }
            .titleSec {
              h3 {
                color: #fff;
                font-family: "Gilroy-Medium";
                // font-size: 18px;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                @media only screen and (max-width: 767px){
                  font-size: 16px;
                  line-height: 24px;
                  margin-bottom: 0px;
                }
              }
              .number {
                color: #fff;
                font-family: "Gilroy-Medium";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                @media only screen and (max-width: 767px) {
                  margin-bottom: 8px;
                  font-size: 14px;
                  line-height: 24px;
                }
              }
              .desc {
                color: var(--Background---grey-Grey---300---GB, #5d7893);
                font-family: "Gilroy-Medium";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 0px;
                @media only screen and (max-width: 767px) {
                  font-size: 14px;
                  line-height: 24px;
                }

              }
            }
            .buttonSec {
              @media only screen and (max-width: 767px) {
                display: flex;
              }
              button {
                border-radius: var(--Spacing-lg, 16px);
                border: 1px solid rgba(78, 255, 97, 0.4);
                background: var(--Primary-Green---50---GB, #f1feec);
                backdrop-filter: blur(32px);
                color: var(--Background---grey-Grey---950---GB, #020303);
                font-family: "Inter-Medium";
                // font-size: 14px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                padding: 12px 7px;
                width: 140px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                  opacity: 0.6;
                }
                @media only screen and (max-width: 767px) {
                  height: 44px;
                }
              }
            }
          }
          .phoneUpdate_section{
            margin:40px 0px;
            .phone_update_form{
              .titleSec {
                h3 {
                  color: #fff;
                  font-family: "Gilroy-Medium";
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 26px;
                  @media only screen and (max-width: 767px){
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }
              // .phone_form_section{
              //   // label{
              //   //   color: #fff;
              //   //   font-family: "Gilroy-Medium";
              //   //   font-size: 16px;
              //   //   font-style: normal;
              //   //   font-weight: 400;
              //   //   line-height: 32px;
              //   // }
              //   .special-label{
              //     // color: #fff;
              //     color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
              //     font-family: "Gilroy-Medium";
              //     font-size: 16px;
              //     font-style: normal;
              //     font-weight: 400;
              //     line-height: 32px;
              //   }
              //   input{
                  // border-radius: var(--Spacing-2xl, 24px);
                  // opacity: var(--Corner-radius-xs, 1);
                  // background: var(--Background---grey-Grey---500---GB, #181f26);
                  // color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
                  // // color: #FFF;
                  // border: 1px solid rgba(59, 77, 94, 0.5);
                  // font-size: 14px;
                  // font-family: "Inter-Medium";
                  // font-style: normal;
                  // font-weight: 500;
                  // line-height: 26px;
                  // padding: 15px 20px;
                  // width: 100%;
              //   }
              // }
              .phone_form_section{
                width: 100%;
                max-width: 80%;
                position: relative;
                @media only screen and (max-width: 767px) {
                  max-width: 100%;
                }
                label{
                  color: #767a87;
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 32px;
                }
                .react-tel-input {
                  .form-control {
                    border-radius: var(--Spacing-2xl, 24px);
                    opacity: var(--Corner-radius-xs, 1);
                    background: var(--Background---grey-Grey---500---GB, #181f26);
                    color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
                    // color: #FFF;
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    font-size: 14px;
                    font-family: "Inter-Medium";
                    font-style: normal;
                    font-weight: 500;
                    line-height: 26px;
                    padding: 15px 50px;
                    width: 100%;
                    height: 64px;
                    @media only screen and (max-width: 767px) {
                      height: 48px;
                    }
                    &:focus{
                      box-shadow: none;
                    }
                  }
                  .flag-dropdown {
                    border: 2px solid #2d2d2d;
                    background-color: #181f26;
                    border-radius: 20px 0 0 20px;
        
                    &.open {
                      .selected-flag {
                        background: #000;
                        border-radius: 20px 0 0 20px;
                        background: var(--Background---grey-Grey---500---GB, #181f26);
                      }
                    }
                    .selected-flag {
                      &:hover {
                        background-color: #181f26;
                        border-radius: 20px 0 0 20px;
                      }
        
                      &:focus {
                        background-color: #181f26;
                      }
                    }
                  }
        
                  .country-list {
                    background-color: #000;
                    width: 310px;
        
                    .search {
                      background-color: #000;
                    }
        
                    .search-box {
                      background: #000;
                      border: 2px solid #2d2d2d;
                      color: #ccc;
                    }
        
                    .country {
                      color: #fff !important;
                      font-family: "Gilroy-Medium";
        
                      &.highlight {
                        color: #fff !important;
                        background-color: #181818 !important;
                      }
        
                      // &.active {
                      //   color: #000 !important;
                      // }
                      &:hover {
                        color: #fff !important;
                        background-color: #181818 !important;
                      }
                    }
                  }
                }
              }

            }
            .phone_buttonSec {
              display: flex;
              gap: 10px;
              margin-top: 20px;

              button {
                font-family: "Gilroy-Medium";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                width: 156px;
                height: 48px;
                padding: 16px 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                @media only screen and (max-width: 767px) {
                  font-size: 14px;
                }
                &.save_btn {
                  border-radius: var(--Spacing-lg, 24px);
                  border: 1px solid rgba(78, 255, 97, 0.4);
                  background: var(--Primary-Green---50---GB, #f1feec);
                  -webkit-backdrop-filter: blur(32px);
                  backdrop-filter: blur(32px);
                  color: var(--Background---grey-Grey---950---GB, #020303);
                  &:hover {
                    opacity: 0.7;
                  }
                }
                &.cancel_btn {
                  border: 1px solid rgba(59, 77, 94, 0.5);
                  border-radius: var(--Spacing-2xl, 24px);
                  background: var(--Background---grey-Grey---400---GB, #3b4d5e);
                  color: #fff;
                  &:hover {
                    opacity: 0.7;
                  }
                }
              }
            }
          }
          .required {
            font-family: "Inter-Medium";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 26px;
            color: var(--Primary-Green---400---GB, #74f33b);
            @media only screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
        }

        .two_step_verification {
          padding: 30px 0px 20px;
          @media only screen and (max-width: 767px) {
            padding:0px
          }
          .title_section{
            h3 {
              color: #fff;
              font-family: "Gilroy-Medium";
              // font-size: 18px;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              @media only screen and (max-width: 767px){
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
          .sub_title {
            color: #fff;
            font-family: "Gilroy-Medium";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            @media only screen and (max-width: 767px){
              font-size: 16px;
              line-height: 24px;
            }
          }
          .current_section{
            .current_box{
              border-radius: var(--Spacing-2xl, 24px);
              opacity: var(--Corner-radius-xs, 1);
              background: var(--Background---grey-Grey---500---GB, #181f26);
              padding:20px;
              display: flex;
              justify-content: space-around;
              align-items: center;
              gap: 10px;
              @media only screen and (max-width: 480px) {
                flex-direction: column;
              }
              .sec_manage_btn{
                border-radius: var(--Spacing-lg, 16px);
                border: 1px solid rgba(78, 255, 97, 0.40);
                background: var(--Primary-Green---50---GB, #F1FEEC);
                backdrop-filter: blur(32px);
                height: 48px;
                width: 140px;
                padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--Spacing-sm, 8px);
                color: var(--Background---grey-Grey---950---GB, #020303);
                font-family: "Inter-Medium";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                // padding: 12px;
                margin-top: 20px;
                cursor: pointer;
                &:hover{
                  opacity: 0.7;
                }
                @media only screen and (max-width: 767px) {
                  font-size: 12px;
                  height: 44px;
                }
              }
              // .left_section{
              // }
              .middle_section{
                flex:0 0 60%;
                @media only screen and (max-width: 767px) {
                  flex: unset;
                }
                .message, .moderately_req{
                  color: #fff;
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 32px;
                  @media only screen and (max-width: 767px) {
                    font-size: 14px;
                  }
                }
                .security_update{
                  color: #5d7893;
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 32px;
                  @media only screen and (max-width: 767px) {
                    font-size: 14px;
                  }
                  .resend_otp{
                    button{
                      background: none;
                      border: none;
                      color: #74f33b;
                    }
                  }
                  .otp-validation{
                      color: #dd1717;
                      font-family: "Inter-Regular";
                      font-size: 13px;
                  }
                }
                // .moderately_req{
                // }
              }
            }
            .tip{
              color: #5d7893;
              font-family: "Gilroy-Medium";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              margin-top: 10px;
              @media only screen and (max-width: 767px) {
                font-size: 14px;
              }
            }
           
          }
  
        }
        .devider {
          max-width: 724px;
          width: 100%;
          border: 2px solid #181f26;
        }
      }
    }
    // Security Section End...

    // Notification Section Start..
    .notificationSec {
      .notificationContainer {
        max-width: 724px;
        width: 100%;
        margin: 0 auto;
        h3 {
          color: #fff;
          font-family: "Gilroy-Medium";
          // font-size: 18px;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          padding-bottom: 20px;
          @media only screen and (max-width: 767px) {
            padding-bottom: 0px;
            margin-bottom: 16px;
            font-size: 16px;
            line-height: 24px;
          }
        }
        .notificationBox {
          border-radius: var(--Spacing-2xl, 24px);
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---600---GB, #141a1f);
          padding: 18px;
          margin: 16px 0px;
          display: flex;
          align-items: center;;
          @media only screen and (max-width: 767px) {
            padding: 16px;
            margin: 8px 0px;
          }
          @media only screen and (min-width: 768px) {
            min-height: 60px;
          }
          .checkboxContWrap {
            display: flex;
            .checkboxContainer {
              height: 30px;
              width: 30px;
              margin: inherit;
              position: relative;
              padding-left: 21px;
              cursor: pointer;
              font-size: 20px;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
              .checkmark{
                top: 1px;
              }
            }
            /* Hide the browser's default checkbox */
            .checkboxContainer input {
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            /* Create a custom checkbox */
            .checkmark {
              content: "";
                position: absolute;
                left: 0;
                width: 24px;
                height: 24px;
                border: none;
                border-radius: var(--Spacing-sm, 8px);
                background: var(--Background---grey-Grey---300---GB, #5d7893);
            }

            /* When the checkbox is checked, add a blue background */
            .checkboxContainer input:checked ~ .checkmark {
              // background-color: #262627;
              // border: 2px solid #5c5c5c;
            //   content: url("../../images/redo/notif_cb.png");
              position: absolute;
              top: 1px;
            // left: 1px;
              border-radius: var(--Spacing-sm, 8px);
              background: var(--Primary-Green---500---GB, #74f33b);
              width: 24px;
              height: 24px;
              border: none;
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            .checkboxContainer:after {
              content: "";
              position: absolute;
              display: none;
            }

            /* Style the checkmark/indicator */
            .checkboxContainer .checkmark:after {
              content: "";
              top: 1px;
              left: 6px;
              width: 6px;
              height: 12px;
              border: solid #5d7893;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              position: absolute;
            }

            /* Show the checkmark when checked */
            .checkboxContainer input:checked ~ .checkmark:after {
              top: 5px;
              left: 10px;
              width: 6px;
              height: 12px;
              border: solid #FFF;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              position: absolute;
            }
            .notificData {
              color: var(--Background---grey-Grey---50---GB, #e3e8ed);
              font-family: "Gilroy-Medium";
              // font-size: 16px;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              padding-left: 15px;
              @media only screen and (max-width: 767px) {
                font-size: 12px;
                padding-left: 7px;
              }
            }
          }
        }

        .notifButtonSec {
          margin-top: 30px;
          @media only screen and (max-width: 767px) {
            justify-content: center;
            display: flex;
            margin-top:16px
          }
          button {
            border-radius: var(--Spacing-2xl, 24px);
            // border: 1.5px solid #FFF;
            border: 1.5px solid #fff;
            background: var(--Primary-Green---50---GB, #f1feec);
            box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.02),
              0px 16px 56px -4px rgba(241, 254, 236, 0.24);
            width: 176px;
            color: var(--Background---grey-Grey---700---GB, #0e1216);
            font-family: "Gilroy-Medium";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding: 12px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media only screen and (max-width: 480px) {
              width:100%
            }
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
    // Notification Section End..

        // Gamertag Section Start..
        .gamertagSec {
          .gamertagContainer {
            // max-width: 724px;
            width: 100%;
            margin: 0 auto;
    
            h2 {
              color: #fff;
              font-family: "Gilroy-Medium";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 32px;
              text-align: center;
            }
    
            h3 {
              color: var(--Background---grey-Grey---300---GB, #5d7893);
              font-family: "Gilroy-Medium";
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 26px;
              @media only screen and (max-width: 767px){
                font-size: 18px;
                line-height: 26px;
              }
            }
    
            .gameGridSec {
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              gap: 15px;
              margin: 16px 0px;
    
              @media only screen and (max-width: 667px) {
                flex-direction: column;
              }
    
              .gameBoxWrap {
                border-radius: var(--Spacing-lg, 16px);
                opacity: var(--Corner-radius-xs, 1);
                background: var(--Background---grey-Grey---600---GB, #141a1f);
                padding: 6px 10px;
                /* background: red; */
                flex: 0 0 20%;
                max-width: 20%;
    
                @media only screen and (max-width: 1200px) {
                  flex: 0 0 45%;
                  max-width: 45%;
                }
    
                @media only screen and (max-width: 667px) {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
    
                .gameBoxTagName {
                  text-align: center;
                  padding: 3px 10px;
                  color: #c9fab2;
                  font-size: 16px;
                  line-height: 24px;
                  font-family: "Gilroy-SemiBold";
    
                  .gtn.acc {
                    font-size: 15px;
                    color: #92a6ba;
                    font-family: "Gilroy-SemiBold";
                    word-wrap: break-word;
                  }
                }
    
                .gameBox {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  // border-radius: var(--Spacing-lg, 16px);
                  // opacity: var(--Corner-radius-xs, 1);
                  // background: var(--Background---grey-Grey---600---GB, #141a1f);
                  padding: 8px;
                  height: 165px;
                  // width: 175px;
    
                  @media only screen and (max-width: 400px) {
                    flex: 0 0 48%;
                  }
    
                  .gameImage {
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    border-radius: var(--Spacing-md, 12px);
                    opacity: var(--Corner-radius-xs, 1);
                    background: var(--Background---grey-Grey---500---GB, #181f26);
                    padding: 12px;
                    min-height: 120px;
    
                    img {
                      // height: 50px;
                      padding: 20px;
                    }
                  }
    
                  .bottomSec {
                    display: flex;
                    justify-content: space-between;
                    padding: 6px;
    
                    .verified {
                      .verify_popup {
                        .verify-message {
                          font-family: "Inter-Regular";
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: 24px;
    
                          &.verified {
                            color: #27ff18;
                          }
    
                          &.requested {
                            color: #ffc01f;
                          }
                        }
    
                        .verify-link {
                          display: flex;
                          cursor: pointer;
    
                          .verify-message {
                            color: #767a87;
                          }
                        }
                      }
                    }
    
                    .trash {
                      img {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
    
              .noGamerTagsSection {
                h3 {
                  color: var(--Background---grey-Grey---300---GB, #5D7893);
                  font-family: "Gilroy-Medium";
                  font-size: 24px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 26px;
                  padding-bottom: 20px;
                  @media only screen and (max-width: 767px){
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }
            }
    
            .ruleSection {
              margin: 20px 0px;
    
              @media only screen and (max-width: 767px) {
                margin: 16px 0px 0px;
              }
    
              .ruleBox {
                border-radius: var(--Spacing-2xl, 24px);
                opacity: var(--Corner-radius-xs, 1);
                background: var(--Background---grey-Grey---600---GB, #141a1f);
                padding: 18px 32px;
                margin-bottom: 16px;
                display: flex;
                align-items: center;
    
                @media only screen and (max-width: 767px) {
                  padding: 16px;
                  margin-bottom: 8px;
                }
    
                @media only screen and (min-width: 1025px) {
                  min-height: 60px;
                }
    
                p {
                  color: var(--Background---grey-Grey---50---GB, #e3e8ed);
                  font-family: "Gilroy-Medium";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                  margin-bottom: 0px;
    
                  @media only screen and (max-width: 767px) {
                    font-size: 12px;
                    line-height: 14px;
                  }
                }
              }
            }
          }
        }
    
        // Gamertag Section End..
  }
}

.form-field-error {
  color: #dd1717;
  font-family: "Inter-Regular";
  font-size: 13px;
  margin-bottom: 10px;
}
.set_spinner {
  .text-light {
    color: #181f26 !important;
    margin-right: 5px !important;
  }
}

.floating-label-group {
  position: relative;
  .floating-label ,.floating-disabled-label {
    position: absolute;
    pointer-events: none;
    top: 21px;
    left: 20px;
    transition: all 0.1s ease;
    color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
    // padding: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    font-family: "Inter-Regular";
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      top: 15px;
    }
  }
  .floating-disabled-label {
    font-size: 12px!important;
    top: 10px!important;
    left: 21px!important;
    @media only screen and (max-width: 767px) {
      font-size: 10px!important;
      top: 5px !important;
    }
  }
  input:focus ~ .floating-label,
  select:focus ~ .floating-label,
  input:not(:focus):valid ~ .floating-label,
  select:not(:focus):valid ~ .floating-label {
    top: 10px;
    bottom: 0px;
    left: 21px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    font-family: "Inter-Regular";
    color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
    @media only screen and (max-width: 767px) {
      left: 21px;
      font-size: 10px;
      top: 5px !important;
    }
  }
}

.deleteGame_Modal{
  .modal-content{
    max-width: 470px;
    width: 100%;
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.50);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---500---GB, #181F26);
    padding: 10px;
    .modal-header{
      border-bottom: none;
      // .btn-close{
      //   border:2px solid #FFF;
      //   border-radius: 20px;
      // }
      .btn-close{
        // background-image: url("../../images/redo/popup-close-circle.png");
        background: url("../../images/redo/bet-close-circle.png") no-repeat;
        opacity: unset !important;
      }
    }
    .modal-body{
      .deleteGame_HeadImg{
        text-align: center;
      }
      .deleteGame_title{
        color: #FFF !important;
        text-align: center;
        font-family: "Gilroy-Medium";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .deleteGame_subTitle{
        h3{
          color: var(--Background---grey-Grey---200---GB, #92A6BA) !important;
          text-align: center;
          font-family: "Gilroy-Regular" !important;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          @media only screen and (max-width: 767px){
            font-size: 16px;
            line-height: 24px;
          }
        }
        h4{
          color: var(--Background---grey-Grey---200---GB, #92A6BA) !important;
          text-align: center;
          font-family: "Gilroy-Regular" !important;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          @media only screen and (max-width: 767px){
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
    .modal-footer{
      border-top:none;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      button{
        // max-width: 190px;
        // width: 100%;
        min-width: 146px;
        height: 50px;
        font-family: "Inter-Medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; 
        text-transform: none;
        padding: 10px 20px;
        // width: 100% !important;
        //   max-width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
          opacity: 0.7;
        }
      }
      .cancal_btn{
        border-radius: var(--Spacing-2xl, 24px);
        border: 1.5px solid #FFF;
        background: var(--Primary-Green---50---GB, #F1FEEC);
        box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.02), 0px 16px 56px -4px rgba(241, 254, 236, 0.08);
        color: var(--Background---grey-Grey---700---GB, #0E1216);
       
      }
      .delete_btn{
        border-radius: var(--Spacing-2xl, 24px);
        background: var(--Background---grey-Grey---400---GB, #3B4D5E);
        border: none;
        color: #FFF;
        &.del_spinner{
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
        }
      }
    }
  }
}

.verifyGame_Modal{
  .modal-content{
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.50);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---500---GB, #181F26);
    padding: 10px;
    .modal-header{
      border-bottom: none;
      button{
        // background-image: url("../../images/redo/popup-close-circle.png");
        background: url("../../images/redo/bet-close-circle.png") no-repeat;
        opacity: unset !important;
      }
    }
    .modal-body{
      p{
        color: var(--Background---grey-Grey---200---GB, #92A6BA) !important;
        text-align: center;
        font-family: "Gilroy-Regular" !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .verify_title{
        color: #FFF !important;
        text-align: center;
        font-family: "Gilroy-Medium";
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .verify_subTitle{
        margin-bottom: 20px;
        h4{
          color: var(--Background---grey-Grey---200---GB, #92A6BA) !important;
          text-align: center;
          font-family: "Gilroy-Regular" !important;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          @media only screen and (max-width: 767px){
            font-size: 16px;
            line-height: 24px;
          }
        }
        a{
          color: #74f33b;
        }
      }
      // .verify_content-sect{
      // }
      // .verify_content-sect-bottom{
      // }
    }
    .modal-footer{
      border-top: none;
    }
  }
}
@keyframes pulseAnimation {
  0% {
    box-shadow: 0 0 0 0px rgba(116,243,59, 0.4);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(116,249,59,0);
  }
}
