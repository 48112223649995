._GB_redoSteamUserEmail{
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: 1025px){
        position: relative;
        top: 35px;
    }
    .redoSteamUserEmail_wrap{
        height: 600px;
        @media only screen and (max-width: 767px){
            height: 400px;
        }
        .redoSteamUserEmail_inner{
            .verifyEmailOTPTitle{
                font-family: "Gilroy-Medium";
                font-size: 28px;
                line-height: 34px;
                color: #fff;
                margin-bottom: 10px;
                @media only screen and (max-width: 767px){
                    font-size: 20px;
                    line-height: 28px;
                }
            }
            .GB_twofactor_authentication{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                padding: 15px 0px 30px;
                .form-item{
                    max-width: 425px;
                    width: 100%;
                    .form-control{
                        align-items: center;
                        background-color: #181f26;
                        border-radius: 24px;
                        display: flex;
                        height: 56px;
                        justify-content: space-between;
                        margin-bottom: 20px;
                        max-width: 425px;
                        padding: 20px;
                        width: 100%;
                    }
                   &.form-item-btn{
                        max-width: 175px;
                        width: 100%;
                        button{
                            border-radius: var(--Spacing-2xl, 24px);
                            // border: 1.5px solid #FFF;
                            border: 1.5px solid #fff;
                            background: var(--Primary-Green---50---GB, #f1feec);
                            box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.02),
                                0px 16px 56px -4px rgba(241, 254, 236, 0.24);
                            color: var(--Background---grey-Grey---700---GB, #0e1216);
                            font-family: "Gilroy-Medium";
                            // font-size: 16px;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            width: 100%;
                            // height: 56px;
                            height: 48px;
                            @media only screen and (max-width: 767px) {
                                font-size: 16px;
                            }
                            &:hover {
                                opacity: 0.5;
                            }
                        }
                   } 
                }
                .otp-validation{
                    font-family: "Gilroy-Medium";
                    font-size: 13px;
                    line-height: 19px;
                    color: #e52420;
                    padding-bottom: 10px;
                }
            }
        }
    }
}