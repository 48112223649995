body {
    background-color: var(--Background---grey-Grey---700---GB, #0E1216);

    ._GB_raffiliates {
        display: flex;
        position: relative;
        top: 35px;

        @media only screen and (max-width: 1024px) {
            top: 0px;
        }

        .common_wrapper {
            width: 100%;
            max-width: 100%;

            .affliates_wrap {
                border: 1px solid rgba(59, 77, 94, 0.5);
                border-top-left-radius: 18px;
                background-repeat: no-repeat;
                background-position: top right;
                border-bottom: none;
                background-color: var(--Background---grey-Grey---700---GB, #0E1216);

                @media only screen and (max-width: 1024px) {
                    padding: 10px 15px;
                    border: none;
                }

                .common_Inner {
                    h2 {
                        color: #FFF;
                        font-family: "Gilroy-Medium";
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 32px;
                        /* 100% */
                        margin-top: 30px;
                        margin-bottom: 24px;

                        @media only screen and (max-width: 767px) {
                            margin-bottom: 16px;
                            font-size: 20px;
                        }

                    }

                    .affiliates_first_wrap {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        gap: 10px;

                        @media only screen and (max-width: 1024px) {
                            display: block;
                        }

                        .affiliates_first_wrap_left {
                            display: inline-flex;
                            flex-direction: column;
                            align-items: flex-start;
                            gap: var(--Spacing-lg, 13px);
                            width: 100%;
                            max-width: 648px;

                            .left_head {
                                color: #FFF;
                                font-family: 'Inter-Medium';
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 30px;
                                /* 166.667% */

                                @media only screen and (max-width: 767px) {
                                    line-height: 10px;
                                }

                            }

                            p {
                                color: var(--Background---grey-Grey---300---GB, #5D7893);
                                font-family: "Gilroy-Medium";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 26px;
                                /* 162.5% */

                                @media only screen and (max-width: 767px) {
                                    margin-bottom: 2px;
                                }


                            }

                            .affliates_textbox {
                                // display: flex;
                                width: 100%;

                                .affiliates_form {
                                    // display: flex;
                                    position: relative;

                                    input {
                                        // display: flex;
                                        padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
                                        align-items: center;
                                        gap: var(--Spacing-sm, 8px);
                                        flex: 1 0 0;
                                        align-self: stretch;
                                        border-radius: var(--Spacing-2xl, 24px);
                                        opacity: var(--Corner-radius-xs, 1);
                                        background: var(--Background---grey-Grey---500---GB, #181F26);
                                        // display: flex;
                                        // width: 647px;
                                        width: 100%;
                                        max-width: 648px;
                                        height: 76px;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: flex-start;
                                        gap: var(--Spacing-sm, 8px);
                                        color: #FFF;
                                        font-family: "Gilroy-Medium";
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 26px;
                                        /* 162.5% */
                                        position: relative;
                                        border: 0 !important;
                                        outline: none;
                                        padding-right: 247px;

                                        &::placeholder {
                                            color: var(--Background---grey-Grey---300---GB, #5D7893);
                                            font-family: "Gilroy-Medium";
                                            font-size: 16px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: 26px;
                                        }

                                        @media only screen and (max-width: 576px) {
                                            height: 57px;
                                            padding-right: 70px;
                                            font-size: 12px;
                                        }
                                    }

                                    img {
                                        position: absolute;
                                        right: 200px;
                                        top: 29px;
                                        cursor: pointer;

                                        @media only screen and (max-width: 576px) {
                                            right: 20px;
                                            top: 19px;
                                        }
                                    }

                                    button {
                                        display: flex;
                                        width: 123px;
                                        padding: var(--Spacing-xl, 13px) var(--Spacing-3xl, 32px);
                                        justify-content: center;
                                        align-items: center;
                                        gap: var(--Spacing-sm, 8px);
                                        position: absolute;
                                        right: 8px;
                                        bottom: 8px;
                                        border-radius: var(--Spacing-2xl, 24px);
                                        border: 1px solid rgba(78, 255, 97, 0.40);
                                        background: var(--Primary-Green---50---GB, #F1FEEC);
                                        backdrop-filter: blur(32px);
                                        color: var(--Background---grey-Grey---950---GB, #020303);
                                        font-family: 'Inter-Medium';
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 32px;
                                        /* 177.778% */
                                        opacity: var(--Corner-radius-xs, 1);
                                        position: absolute;

                                        &:hover {
                                            opacity: 0.8;
                                        }

                                        @media only screen and (max-width: 767px) {
                                            width: 100%;
                                            right: 0px;
                                            bottom: -50px;
                                            border-radius: var(--Spacing-2xl, 15px);
                                            font-size: 12px;
                                            padding: var(--Spacing-xl, 3px) var(--Spacing-3xl, 32px);
                                        }

                                    }
                                }

                            }

                            .affiliate_messages {
                                min-height: 50px;

                                .successMsg {
                                    font-size: 14px;
                                    line-height: 24px;
                                    font-family: 'Inter-Medium';
                                    color: #51E20E;
                                    font-weight: 500;
                                }

                                .errorMsg {
                                    font-size: 14px;
                                    line-height: 24px;
                                    font-family: 'Inter-Medium';
                                    color: #e20e0e;
                                    border: none !important;
                                    font-weight: 500;
                                    margin: 0;
                                }
                            }
                        }

                        .affiliates_first_wrap_right {
                            display: flex;
                            gap: 16px;

                            @media only screen and (max-width: 1024px) {
                                margin-top: 65px;
                            }

                            @media only screen and (max-width: 767px) {
                                justify-content: flex-start;
                                gap: 8px;
                            }

                            .invites_affiliates {
                                display: flex;
                                padding: var(--Spacing-2xl, 24px) 40px;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-end;
                                gap: var(--Spacing-sm, 8px);
                                border-radius: var(--Spacing-2xl, 24px);
                                border: 1px solid rgba(241, 254, 236, 0.30);
                                opacity: var(--Corner-radius-xs, 1);
                                background: var(--Background---grey-Grey---500---GB, #181F26);

                                @media only screen and (max-width: 1420px) and (min-width: 1325px) {
                                    padding: var(--Spacing-2xl, 24px) 20px;
                                }

                                @media only screen and (max-width:767px) {


                                    padding: 16px;
                                    width: 100%;
                                    max-width: 300px;
                                    gap: 0px;

                                }

                                p {
                                    color: var(--Background---grey-Grey---300---GB, #5D7893);
                                    font-family: "Gilroy-Regular";
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px;
                                    @media only screen and (max-width: 1024px) {
                                        font-size: 14px;
                                        line-height: 20px;
                                    }
                                    @media only screen and (max-width:767px) {
                                        margin-bottom: 0px;
                                        font-size: 12px;
                                    }
                                }

                                .affiliates_count {
                                    color: #FFF;
                                    font-family: "Gilroy-Regular";
                                    font-size: 26px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 40px;

                                    @media only screen and (max-width:767px) {

                                        font-size: 18px;
                                    }
                                }

                            }

                            .earned_affiliates {
                                display: flex;
                                padding: var(--Spacing-2xl, 24px) 40px;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-end;
                                gap: var(--Spacing-sm, 8px);
                                border-radius: var(--Spacing-2xl, 24px);
                                border: 1px solid rgba(241, 254, 236, 0.30);
                                opacity: var(--Corner-radius-xs, 1);
                                background: var(--Background---grey-Grey---500---GB, #181F26);

                                @media only screen and (max-width: 1420px) and (min-width: 1325px) {
                                    padding: var(--Spacing-2xl, 24px) 20px;
                                }

                                @media only screen and (max-width:767px) {

                                    padding: 16px 9px;
                                    gap: 0px;
                                    width: 100%;
                                    max-width: 300px;


                                }

                                // @media only screen and (max-width: 420px)
                                // {
                                //     padding: var(--Spacing-2xl, 24px) 9px;
                                // }
                                p {
                                    color: var(--Background---grey-Grey---300---GB, #5D7893);
                                    font-family: "Gilroy-Regular";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 20px;

                                    @media only screen and (max-width:767px) {
                                        font-size: 12px;
                                        margin-bottom: 0px;
                                    }
                                }

                                .affiliates_count {
                                    color: #FFF;
                                    font-family: "Gilroy-Regular";
                                    font-size: 26px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 40px;

                                    @media only screen and (max-width:767px) {
                                        font-size: 20px;

                                    }
                                }

                            }
                        }
                    }




                }

                ._affiliate_table {


                    margin-top: 15px;
                    padding-bottom: 100px;

                    @media only screen and (max-width: 1024px) {
                        margin-top: 40px;
                    }

                    @media only screen and (max-width: 767px) {
                        margin-top: 32px;
                        padding-bottom: 20px;
                    }

                    .table_head {

                        color: var(--Background---grey-Grey---300---GB, #5D7893);
                        /* Subtitle/Subtitle 30/$Subtitle 30-medium */
                        font-family: "Gilroy-Medium";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 26px;
                        /* 162.5% */
                        margin-bottom: 16px;

                        @media only screen and (max-width: 767px) {
                            font-size: 14px;
                        }
                    }

                    .table-container {
                        overflow-x: auto;
                        max-width: 100%;


                        #seasonResult_Table {
                            border-bottom: none !important;

                            thead {
                                @media only screen and (max-width: 1024px) {
                                    //   display: none;  
                                }

                                tr {
                                    th {
                                        background-color: #141A1F;
                                        font-size: 14px;
                                        line-height: 24px;
                                        font-family: 'Inter-Medium';
                                        color: #51E20E;
                                        border: none !important;
                                        font-weight: 500;
                                        text-transform: capitalize;

                                        @media only screen and (max-width: 1024px) {
                                            width: 25%
                                        }

                                        &:first-child {
                                            border-top-left-radius: 10px;
                                            // text-align: center;
                                        }

                                        &:last-child {
                                            border-top-right-radius: 10px;
                                        }

                                        img {
                                            margin-left: 10px;
                                        }

                                        @media only screen and (max-width: 767px) {
                                            font-size: 14px;
                                        }

                                        &.gb-first-col {
                                            @media only screen and (max-width: 1024px) {
                                                min-width: 250px;
                                            }
                                        }

                                        &.gb-sec-col {
                                            @media only screen and (max-width: 1024px) {
                                                min-width: 350px;
                                            }
                                        }

                                        &.gb-third-col {
                                            @media only screen and (max-width: 1024px) {
                                                min-width: 350px;
                                            }
                                        }

                                        @media only screen and (max-width: 1024px) {
                                            display: table-cell;
                                            text-align: -webkit-match-parent;
                                            min-width: 150px;
                                        }
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);

                                    @media only screen and (max-width: 1024px) {
                                        //border-bottom: 3px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
                                    }

                                    td {
                                        color: #DBFFDF;
                                        font-family: 'Inter-Regular';
                                        font-size: 14px;
                                        line-height: 24px;
                                        text-align: left;
                                        height: 45px;
                                        vertical-align: middle;

                                        @media only screen and (max-width: 767px) {
                                            font-size: 12px;
                                        }
                                        &.no-affilliates{
                                            text-align: center;
                                          }

                                        .profile-info {
                                            display: flex;
                                            align-items: center;

                                            img {
                                                border: 2px solid #fff;
                                                border-radius: 100%;
                                                box-sizing: border-box;
                                                height: 40px;
                                                margin-right: 20px;
                                                object-fit: cover;
                                                width: 40px;
                                            }
                                        }

                                        &.red_unfollow {
                                            .affliatestable-cont {
                                                color: var(--Color-Error-500, #FF3C35);
                                                font-family: "Gilroy-Medium";
                                                font-size: 16px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 24px;

                                                @media only screen and (max-width: 767px) {
                                                    font-size: 12px;
                                                }

                                            }
                                        }

                                        .affliatestable-cont {
                                            display: flex;
                                            gap: 10px;

                                            .affliates_games {
                                                display: flex;
                                                gap: 10px;
                                                align-items: center;
                                                flex-wrap: wrap;

                                                img {
                                                    // width:35px;

                                                    padding-right: 10px;
                                                }
                                            }

                                            .affiliatespaidnote {
                                                &.not-paid {
                                                    color: #FF3C35;
                                                }

                                                &.paid {
                                                    color: #51E20E;
                                                }
                                            }
                                        }

                                        &.GB_device_td {
                                            @media only screen and (max-width: 1024px) {
                                                // align-items: center;
                                                display: table-cell;
                                                // justify-content: space-between;
                                                // padding-left: 10px !important;
                                                // padding-right: 10px !important;
                                                // position: relative;
                                            }

                                            &.GB_device_td:before {
                                                // @media only screen and (max-width: 1024px)
                                                //     {
                                                //         content: attr(data-label);
                                                //         font-weight: 500;
                                                //         text-transform: capitalize;
                                                //         top: 0px;
                                                //         color: #51E20E;
                                                //     }
                                                // @media only screen and (max-width: 479px)
                                                // {
                                                //     position: relative !important;
                                                // }
                                            }
                                        }

                                        // @media only screen and (max-width: 1024px)
                                        // {
                                        //     border-bottom-width: 0px !important;
                                        //     padding: 2rem 0.5rem !important;
                                        // }
                                    }
                                }
                            }
                        }

                    }
                }

            }

        }
    }
}