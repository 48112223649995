@import "../fonts/stylesheet.css";

.container {
  @media only screen and (min-width: 1600px) {
    max-width: 1400px !important;
  }
}

._GB_Home {

  // overflow: hidden;
  ._GB_Banner_Top {
    // background: linear-gradient(
    //   231.85deg,
    //   #c80000 -37.37%,
    //   rgba(200, 0, 0, 0) 23.62%
    // );
    background-color: #000;

    ._GB_Home_Banner {
      color: #fff;
      min-height: 825px;

      @media only screen and (min-width: 1024px) {
        margin-top: 50px;
      }

      @media only screen and(max-width: 767px) {
        flex-direction: column;
        margin-top: 25px;
      }

      ._Title {
        font-family: "Gilroy-Bold";
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;

        @media only screen and (min-width: 991px) {
          font-size: 38px;
          line-height: 50px;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 65px;
          line-height: 65px;
        }
      }

      ._SubTitle {
        font-family: "Gilroy-Medium";
        font-size: 19px;
        line-height: 27px;

        @media only screen and (min-width: 1366px) {
          margin-bottom: 20px;
        }

        @media only screen and (max-width: 767px) {
          font-size: 17px;
          line-height: 22px;
        }
      }

      ._SearchArea {
        max-width: 800px;
        background: #262627;
        padding: 8px 10px;
        border-radius: 15px;

        ._SearchArea_wrap {

          // @media only screen and(max-width: 767px) {
          //   flex-wrap: wrap;
          // }
          ._GB_Enter_Player {
            background: none;
            border: none;
            font-family: "Gilroy-Medium";
            color: #ffffff;
            padding: 10px 5px;

            @media only screen and(max-width: 767px) {
              text-align: center;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }

            &.hide-onweb {
              @media only screen and(min-width: 768px) {
                display: none;
              }
            }

            &.hide-onmobile {
              @media only screen and(max-width: 767px) {
                display: none;
              }
            }
          }

          .game_dropdown {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            @media only screen and(max-width: 767px) {
              width: 25%;
            }

            .game_selected_dd {
              width: 275px;
              font-family: "Gilroy-Medium";
              padding-right: 10px;

              .game_choosed {
                padding-right: 0px;

                @media only screen and (min-width: 767px) {
                  padding-right: 35px;
                }
              }

              .web-gameselected {
                @media only screen and(max-width: 767px) {
                  display: none;
                }
              }

              .mobile-gameselected {
                @media only screen and(min-width: 768px) {
                  display: none;
                }

                img {
                  max-width: 35px;
                }
              }

              .dd_down_up {
                padding-left: 10px;
              }
            }

            .game_dropdown_list {
              background: #f9f9f9;
              border-radius: 14px;
              padding: 8px 12px;
              width: 275px;
              color: #0d0f15;
              position: absolute;
              top: 40px;
              z-index: 2;

              @media only screen and (max-width: 767px) {
                right: -50px;
                width: 220px;
              }

              .game_dropdown_each {
                cursor: pointer;
                margin: 4px 0;
                padding: 8px;

                &:hover {
                  background: #e6e6e6;
                  border-radius: 8px;
                }

                .game_dd_image {
                  width: 40px;
                  text-align: center;

                  img {
                    width: 40px;
                    //height: 40px;
                  }

                  &.all_game_img {
                    img {
                      width: 40px;
                      //height: 40px;
                    }
                  }
                }

                .game_dd_name {
                  padding-left: 15px;
                  font-family: "Gilroy-Medium";
                }
              }
            }
          }

          ._GB_Select_Game {
            border: none;
            outline: none;
            font-family: "Gilroy-Medium";
            color: #fff !important;
            background: #262627;
            border: none;
          }

          ._GB_Search_Player {
            border: solid 2px #fff;
            background-color: #e52420;
            background-image: linear-gradient(to right,
                #6d1513,
                #e52420,
                #6d1513);
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 2px 2px 2px 10px;
            border-radius: 10px;

            @media only screen and (max-width: 767px) {
              margin: 2px 2px;
            }
          }
        }

        ._GB_Search_List {
          background: #262627;
          border-radius: 15px;
          margin-top: 20px;
          padding: 15px 10px;
          position: absolute;
          max-width: 320px;
          z-index: 1;

          @media only screen and (min-width: 1024px) {
            min-width: 780px;
          }

          ._GB_Game_Item {
            color: #fff;
            padding: 8px 15px;
            margin-bottom: 10px;

            &:hover {
              background: #3f3f3f;
              border-radius: 10px;
              padding: 8px 15px;
            }

            ._GB_Game_Item_Name {
              .gameImage {
                display: none;

                img {
                  width: 35px;
                  height: 35px;
                }
              }

              .gameName {
                color: #ffffff;
                opacity: 0.5;
                font-family: "Gilroy-Regular";
                font-size: 13px;
                // padding-left: 15px;
              }
            }

            ._GB_CB_PlayerName {
              font-family: "Gilroy-Medium";
            }
          }

          hr {
            color: #fff;
          }

          ._GB_Mining_wrap {
            @media only screen and(max-width: 1023px) {
              flex-direction: column;
            }

            ._GB_Mining_Seleted {
              color: #ffffff;
              opacity: 0.5;
              font-family: "Gilroy-Medium";
              font-size: 14px;

              @media only screen and(max-width: 1023px) {
                margin-bottom: 10px;
              }
            }

            ._GB_mining_Btn {
              height: 56px;
              color: #fff;
              background-color: #e52420;
              background-image: linear-gradient(to right,
                  #6d1513,
                  #e52420,
                  #6d1513);
              border-radius: 10px;
              border: 2px solid #ffffff;
              font-family: "Poppins-SemiBold";
              font-size: 15px;
              line-height: 17px;
              text-transform: uppercase;
              letter-spacing: 0.04em;
              padding: 18px 50px;
              text-decoration: none;
              margin-left: 15px;

              &::after {
                content: url("../images/arrow.png");
                padding-left: 10px;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }

      ._Banner_Bottom {
        color: #fff;
        font-family: "Gilroy-SemiBold";

        ._Banner_Bottom_Inner {
          // max-width: 400px;
          max-width: 350px;
          margin: 50px auto 20px auto;

          @media only screen and (max-width: 400px) {
            margin: 20px auto 30px auto;
            justify-content: center !important;
            gap: 10px;
          }

          .social_icon {

            // @media only screen and (max-width: 767px) {
            //   margin: 0px 0px 15px 0px;
            // }
            ._Icon_Each {
              cursor: pointer;
              margin: 4px;
            }
          }

          .live_rate {
            margin-right: 10px;
          }

          ._Icon_Each {
            display: flex;
            align-items: center;
            cursor: pointer;
          }

          ._Icon_Text {
            font-family: "Gilroy-Bold";
            font-size: 19px;

            @media only screen and (max-width: 374px) {
              font-size: 15px;
            }
          }

          .whitepaper-link {
            text-decoration: none;

            &:hover {
              opacity: 0.5;
            }
          }

          ._Icon_Btn {
            cursor: pointer;

            .whitepaperbtn {
              background: #000000;
              border: 1.5px solid #e52420;
              box-sizing: border-box;
              box-shadow: 0px 0px 4px #e52420;
              border-radius: 7px;
              padding: 5px 10px 5px;
              color: #fff;

              @media only screen and (max-width: 374px) {
                font-size: 12px;
              }
            }
          }

          ._Icon_Text_Bold {
            font-family: "Gilroy-medium";
            font-size: 22px;
            margin: 5px;

            &.Green {
              color: #40ab00;
            }
          }

          &._Banner_Coin {
            @media only screen and (max-width: 767px) {
              display: flex;
            }
          }

          &.banner_live_rate {
            @media only screen and (max-width: 767px) {
              flex-direction: row;
            }
          }
        }
      }

      .banner_game_details {
        padding: 50px 0px;
        flex-wrap: wrap;
        gap: 15px;

        @media only screen and (min-width: 768px) {
          padding: 25px 0;
          // justify-content: space-evenly;
          // max-width: 650px;
          margin: auto;
          justify-content: space-evenly;
        }

        @media only screen and (min-width: 1024px) {
          justify-content: center;
          padding: 50px 0px;
        }

        @media only screen and (min-width: 1366px) {
          justify-content: space-between;
          padding: 50px 0;
          max-width: 1300px;
        }

        .detail_box {
          width: 300px;
          margin-bottom: 15px;
          position: relative;

          @media only screen and(max-width: 990px) {
            max-width: 46%;
            flex: 0 0 46%;
          }

          @media only screen and (max-width: 767px) {
            margin-bottom: 0px;
          }

          @media only screen and (min-width: 991px) and (max-width: 1024px) {
            padding: 0px 10px;
            margin-bottom: 20px;
          }

          @media only screen and (min-width: 1024px) {
            width: 260px;
            padding: 0px 10px;
            margin-bottom: 20px;
          }

          @media only screen and (min-width: 1366px) {
            margin-bottom: 15px;
            margin-bottom: 0px;
            padding: 0 0;
          }

          @media only screen and (min-width: 1400px) {
            width: 300px;
          }

          .spinner-border {
            position: absolute;
            right: 18px;
            top: 30px;
          }

          .box-item {
            display: flex;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: #fff;
            height: 116px;
            width: 100%;
            padding: 15px 25px;
            background: rgba(21, 21, 21, 1);
            border-radius: 20px;
            cursor: pointer;

            @media only screen and (max-width: 490px) {
              flex-direction: column;
              align-items: center;
              justify-content: center;
              height: 150px;
            }

            img {
              width: 60px;

              @media only screen and (max-width: 1365px) {
                margin-right: 15px;
              }

              @media only screen and (max-width: 767px) {
                width: 40px;
              }

              @media only screen and (min-width: 768px) and (max-width: 990px) {
                width: 50px;
              }

              @media only screen and (max-width: 767px) {
                margin-right: 0px;
              }
            }

            .box_right {
              padding-left: 10px;

              @media only screen and (max-width: 490px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding-left: 0px;
              }

              .title {
                font-family: "Gilroy-Bold";
                font-size: 30px;
                line-height: 30px;
                padding-bottom: 10px;

                @media only screen and (max-width: 1024px) {
                  font-size: 25px;
                  line-height: 25px;
                }

                @media only screen and (max-width: 767px) {
                  font-size: 20px;
                  line-height: 25px;
                }
              }

              .sub_title {
                font-family: "Gilroy-Bold";
                font-size: 15px;
                line-height: 15px;
                opacity: 0.5;
                width: 110px;

                @media only screen and (max-width: 767px) {
                  font-size: 13px;
                  line-height: 15px;
                }

                @media only screen and (max-width: 490px) {
                  text-align: center;
                }
              }
            }

            &:hover {
              background: rgba(21, 21, 21, 0.8);
            }
          }
        }
      }
    }
  }

  ._GB_About {
    background: #000;
    // height: 250px;
    padding: 100px 0px;

    @media only screen and (max-width: 1360px) {
      height: auto;
    }

    @media only screen and (max-width: 767px) {
      padding: 30px 0px;
    }

    ._GB_About_row {
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }

      .left_section {
        @media only screen and (max-width: 767px) {
          width: 100%;
        }

        ._GB_About_Title {
          font-family: "Poppins-Bold";
          color: #fff;
          font-size: 25px;
          line-height: 35px;

          @media only screen and (min-width: 768px) {
            font-size: 38px;
            line-height: 52px;
            padding-right: 20%;
          }

          @media only screen and (min-width: 1366px) {
            font-size: 50px;
            line-height: 60px;
          }

          @media only screen and (max-width: 767px) {
            text-align: center;
          }
        }
      }

      .right_section {
        @media only screen and (max-width: 767px) {
          width: 100%;
          text-align: center;
        }

        ._GB_About_SubTitle {
          color: #fff;
          font-family: "Gilroy-Medium";
          font-size: 18px;
          line-height: 28px;

          @media only screen and(max-width: 767px) {
            font-size: 15px;
            text-align: center;
          }
        }

        ._GB_About_Btn {
          max-width: 232px;
          height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-semiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 45px;
          text-decoration: none;

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

._GB_CB_Hide {
  display: none;
}

._GB_CB_Name {
  padding-left: 15px;
}

._GB_CB_Container {
  height: 21px;
  width: 21px;
  margin: auto;
  position: relative;
  padding-left: 21px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
._GB_CB_Container input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 7px;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #262627;
  border: 2px solid #5c5c5c;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
._GB_CB_Container:hover input~.checkmark {
  background-color: #262627;
}

/* When the checkbox is checked, add a blue background */
._GB_CB_Container input:checked~.checkmark {
  background-color: #c80000;
  border: 2px solid #c80000;
}

/* Create the checkmark/indicator (hidden when not checked) */
._GB_CB_Container:after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
._GB_CB_Container .checkmark:after {
  content: "";
  top: 1px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #262627;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
}

/* Show the checkmark when checked */
._GB_CB_Container input:checked~.checkmark:after {
  top: 1px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
}

._GB_Register {
  .user_device_menu {
    position: relative;

    .mobile-main {
      background-color: transparent;
    }
  }

  .reg-login-header {
    padding-left: var(--bs-gutter-x, 0.75rem);
    padding-right: var(--bs-gutter-x, 0.75rem);
    position: absolute;
    top: 0;
    width: 100%;

    .register_gb_logo {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 21px;

      @media only screen and(min-width: 480px) {
        padding-left: 30px;
        padding-right: 30px;
      }

      img {
        &.desktop {
          @media only screen and(max-width: 1365px) {
            display: none;
          }
        }

        &.mobile {
          max-width: 40px;
          border: 4.5px solid #c80000;
          border-radius: 100%;

          @media only screen and (min-width: 375px) {
            max-width: 50px;
          }

          @media only screen and (min-width: 480px) {
            max-width: 60px;
          }

          @media only screen and(min-width: 1366px) {
            display: none;
          }
        }
      }
    }
  }

  .register_wrap {
    margin-top: -90px;

    @media only screen and (min-width: 320px) {
      min-height: 100vh;
    }

    @media only screen and (max-width: 1365px) {
      flex-direction: column;
    }

    .register_left {
      background: linear-gradient(228.87deg,
          #131313 7.3%,
          rgba(19, 19, 19, 0) 67.05%);
      background-color: #000;

      @media only screen and (min-width: 320px) {
        min-height: 100vh;
      }

      @media only screen and (min-width: 1366px) {
        padding-top: 120px;
      }

      @media only screen and (max-width: 1365px) {
        width: 100%;
        padding-top: 75px;
      }

      .register_left_inner {
        @media only screen and (min-width: 1360px) {
          width: 100%;
          max-width: 450px;
          margin: 0 auto;
        }

        @media only screen and (max-width: 574px) {
          width: 90%;
          margin: auto;
        }

        @media only screen and(min-width: 575px) and (max-width: 1423px) {
          width: 60%;
          margin: auto;
        }

        text-align: center;

        .register_title {
          font-family: "Poppins-Bold";
          font-size: 33px;
          color: #fff;

          @media only screen and (max-width: 574px) {
            font-size: 30px;
          }
        }

        .forgot_password {
          a {
            color: #fff;
            font-family: "Gilroy-Regular";

            &:hover {
              color: #e52420;
            }
          }
        }

        .register_p {
          font-family: "Gilroy-Regular";
          font-size: 15px;
          color: #fff;
          margin: 20px 0;

          @media only screen and (min-width: 1920px) {
            margin: 15px 0 30px;
          }

          @media only screen and (max-width: 767px) {
            flex-direction: column;
            display: flex;
          }

          a {
            color: #fff;
            font-family: "Gilroy-Bold";
            text-decoration: none;
          }
        }

        .register_connect_wrap {
          .connect_each {
            font-family: "Poppins-SemiBold";
            color: #fff;
            font-size: 15px;
            background: rgba(24, 24, 24, 0.5);
            border-radius: 20px;
            margin: 10px 0;
            padding: 10px 20px;
            text-align: left;

            &.connect_google {
              padding: 5px 20px 6px;
            }

            &.connect_google {
              .connect_each_inner {
                button {
                  background: none !important;
                  box-shadow: none !important;

                  div {
                    background: none !important;
                  }

                  span {
                    background: none !important;
                    font-family: "Poppins-SemiBold";
                    color: #fff;
                    font-size: 15px;
                  }
                }
              }
            }

            &.connect_facebook {
              .gamebeef_fb {
                background: none !important;
                border: none;
                color: #fff;
                padding-left: 15px;
              }
            }

            .connect_type {
              padding-left: 15px;
              cursor: pointer;
            }
          }
        }

        .register_OR {
          position: relative;

          .or_text {
            color: #fff;
            font-family: "Poppins-SemiBold";
            margin-bottom: 0;

            &::before {
              content: "";
              position: absolute;
              border-top: 2px solid #262627;
              width: 38%;
              left: 15px;
              top: 11px;
            }

            &::after {
              content: "";
              position: absolute;
              border-top: 2px solid #262627;
              width: 38%;
              right: 15px;
              top: 11px;
            }

            @media only screen and (max-width: 575px) {
              &::before {
                content: "";
                width: 35%;
                left: 15px;
                top: 11px;
              }

              &::after {
                width: 35%;
                right: 15px;
                top: 11px;
              }
            }
          }

          @media only screen and (min-width: 1920px) {
            margin: 30px 0;
          }
        }

        .register_link {
          margin-top: 15px;
          font-family: "Gilroy-Regular";
          font-size: 15px;
          color: #fff;

          a {
            color: #fff;
            font-family: "Gilroy-Medium";
          }
        }

        .form-item-field {
          color: #000;
          margin: 10px 0;
          border: 2px solid #262627;
          border-radius: 20px;
          padding: 5px;
          width: 100%;

          .form-field {
            font-family: "Gilroy-Regular";
            background: #000000 !important;
            border: none;
            outline: none !important;
            color: #ffffff;
            opacity: 0.5;
            border-radius: 15px;

            &:active {
              outline: none;
              box-shadow: none;
            }

            &:focus {
              outline: none;
              box-shadow: none;
            }
          }
        }

        .form-item-cbox {
          margin: 15px 0;
        }

        .form-item-btn {
          margin: 20px 0;

          @media only screen and (min-width: 1920px) {
            margin: 30px 0;
          }
        }

        .form-field-error {
          color: red;
          font-size: 12px;
          text-align: left;
          padding: 0 15px;
        }

        .password_eye {
          color: #fff;
          font-size: 10px;
          display: flex;
          align-items: center;
          padding: 10px;
          opacity: 0.5;

          img {
            width: 22px;
            height: 17px;
          }
        }

        .password_requirement {
          color: #fff;
          text-align: left;

          .pass_ver_name {
            font-size: 13px;
            font-family: "Gilroy-Regular";
            opacity: 0.5;
          }

          &.pass {
            color: #0bff1a;
          }

          .pass_tick {
            background: url("../images/pass_tick.png");
            background-repeat: no-repeat;
            background-position: 0px 8px;
            padding-left: 20px;
          }

          .pass_untick {
            background: url("../images/pass_untick.png");
            background-repeat: no-repeat;
            background-position: 0px 8px;
            padding-left: 20px;
          }
        }

        .register_check_updates {
          color: #fff;
          font-size: 13px;
          font-family: "Gilroy-Regular";
          opacity: 0.5;
        }

        ._GB_RegBtn {
          max-width: 232px;
          height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 45px;
          text-decoration: none;
          margin-left: 15px;

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }

      .register_copyright {
        text-align: center;
        color: #fff;
        opacity: 0.5;
        font-size: 13px;
        font-family: "Gilroy-Medium";
        padding-top: 15px;

        @media only screen and (max-width: 1365px) {
          padding-bottom: 15px;
        }

        @media only screen and (min-width: 768px) and (max-width: 1365px) {
          display: none;
        }
      }
    }

    .register_right {
      // background: linear-gradient(
      //   231.85deg,
      //   #c80000 -37.37%,
      //   rgba(200, 0, 0, 0) 23.62%
      // );
      background-color: #000;

      @media only screen and (min-width: 320px) {
        min-height: 100vh;
      }

      @media only screen and (min-width: 1366px) {
        padding-top: 90px;
      }

      @media only screen and (max-width: 1365px) {
        width: 100%;
      }

      @media only screen and (max-width: 767px) {
        display: none !important;
      }

      .register_right_inner {
        padding: 30px 15px;
        text-align: center;

        .register_image {
          img {
            @media only screen and(max-width: 1423px) {
              margin: auto;
            }
          }
        }

        .register_image_title {
          font-family: "Poppins-Bold";
          font-size: 30px;
          line-height: 38px;
          text-align: center;
          color: #fff;
          margin: 10px auto;

          @media only screen and (min-width: 1360px) {
            width: 100%;
            max-width: 580px;
          }
        }

        .register_image_p {
          font-family: "Gilroy-Regular";
          font-size: 15px;
          line-height: 25px;
          text-align: center;
          color: #fff;
          opacity: 0.5;

          @media only screen and (min-width: 1360px) {
            width: 100%;
            max-width: 480px;
            margin: 0 auto;
          }
        }
      }

      .register_copyright {
        text-align: center;
        color: #fff;
        opacity: 0.5;
        font-size: 13px;
        font-family: "Gilroy-Medium";
        padding-top: 15px;
        padding-bottom: 25px;

        @media only screen and (min-width: 1366px) {
          display: none;
        }
      }
    }
  }

  &.login-page {
    .register_wrap {
      .register_right {
        .register_right_inner {
          .register_image {
            img {
              height: 560px;
            }
          }
        }
      }
    }
  }

  &.register-page {
    .register_wrap {
      .register_left {
        .register_left_inner {
          .register_p {
            @media only screen and (max-width: 767px) {
              display: block;
              max-width: 288px;
              margin-left: auto;
              margin-right: auto;
            }

            @media only screen and (max-width: 575px) {
              max-width: 390px;
            }

            @media only screen and (max-width: 479px) {
              max-width: 288px;
            }
          }
        }
      }

      .register_right {
        .register_right_inner {
          ._GB_Featured {
            padding-bottom: 0;
            padding-top: 0;

            .featured_title {
              display: none;
            }

            .featured_slider {
              .carousel-item {
                .featured_slider_rewards {
                  .reward_items {
                    @media only screen and (max-width: 1699px) {
                      padding: 15px 15px;
                    }

                    @media only screen and (max-width: 479px) {
                      width: 90%;
                    }

                    .rewards_data {
                      @media only screen and (max-width: 991px) {
                        padding-right: 0;
                      }

                      .reward_head {
                        @media only screen and (max-width: 1699px) {
                          font-size: 18px;
                        }
                      }

                      .reward_score {
                        @media only screen and (max-width: 1699px) {
                          font-size: 15px;
                          line-height: 18px;
                          margin-top: 6px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &._initPage {
    .register_wrap {
      @media only screen and (min-width: 1366px) {
        height: 100vh;
        margin-top: 0;
      }
    }
  }
}

.Toastify__toast-body {
  font-family: "Gilroy-Regular";
}

._GB_Sync {
  ._GB_Sync_Wrap {
    // background: linear-gradient(231.85deg,
    //     #c80000 -37.37%,
    //     rgba(200, 0, 0, 0) 23.62%);
    // background-color: #000;

    .sync_section {
      // padding: 50px;

      @media only screen and(max-width: 767px) {
        padding: 25px;
      }

      .sync_section_inner {
        width: 100%;
        // max-width: 675px;
        margin: auto;
        padding: 50px 70px;
        // background: rgba(24, 24, 24, 0.5);
        border: 2px solid #454545;
        box-sizing: border-box;
        border-radius: 25px;

        @media only screen and(max-width:767px) {
          padding: 25px;
        }

        .sync_title {
          font-family: "Poppins-Bold";
          color: #fff;
          font-size: 40px;
          text-align: center;

          @media only screen and(max-width: 767px) {
            font-size: 28px;
          }
        }

        .sync_p {
          font-family: "Poppins-Medium";
          font-size: 16px;
          color: #fff;
          text-align: center;
        }

        .sync_process {
          padding: 20px 0;

          .sync_item {
            background: #000000;
            border: 1px solid #414141;
            box-sizing: border-box;
            border-radius: 13px;
            padding: 22px 30px;
            margin: 15px 0;

            @media only screen and(max-width: 575px) {
              flex-direction: column;
              padding: 15px 15px;
            }

            .sync_game_image {
              img {
                width: 40px;
                height: 40px;
              }
            }

            .sync_game_details {
              @media only screen and(max-width: 574px) {
                padding: 10px 0px;
              }

              .sync_game_name {
                font-family: "Poppins-SemiBold";
                color: #fff;
                font-size: 20px;
                text-transform: uppercase;

                @media only screen and(max-width: 574px) {
                  padding-right: 12px;
                }

                a {
                  color: #fff;
                  text-decoration: none;
                }
              }

              .sync_game_user {
                font-family: "Gilroy-SemiBold";
                color: #fff;
                opacity: 0.5;
                font-size: 20px;
              }

              .error_sync {
                @media only screen and (min-width: 768px) {
                  padding-right: 15px;
                }

                .errornamewrap {
                  background: #e52420;
                  border-radius: 7px;
                  margin: 20px 0px;
                  padding: 5px 10px;

                  .errornamewrapright {
                    padding-left: 10px;

                    .error_sync_user {
                      color: #fff;
                      font-size: 18px;
                      line-height: 18px;
                      font-family: "Gilroy-Medium";
                    }

                    .errorMsg {
                      color: #fff;
                      font-size: 14px;
                      line-height: 14px;
                      font-family: "Gilroy-Medium";
                      margin-bottom: 0;
                    }
                  }
                }

                .findSearch {
                  color: #fff;
                  font-size: 13px;
                  font-family: "Gilroy-Regular";
                  opacity: 0.7;
                  margin-bottom: 8px;
                }

                .searchAgain {
                  background: #000000;
                  border: 2px solid #262627;
                  box-sizing: border-box;
                  border-radius: 10px;
                  align-items: center;
                  width: 220px;

                  @media only screen and (max-width: 479px) {
                    max-width: 100%;
                  }

                  input {
                    background: #000000;
                    border: none;
                    font-family: "Gilroy-Medium";
                    font-size: 13px;
                    color: #fff;
                    opacity: 0.7;

                    &:focus {
                      outline: none;
                      box-shadow: none;
                    }
                  }

                  img {
                    padding: 10px;
                  }
                }
              }
            }
          }
        }

        .sync_gotodash {
          text-align: right;

          @media only screen and(max-width: 767px) {
            text-align: center;
          }

          .sync_gotodash_link {
            .godashbtn {
              color: #fff;
              background-color: #e52420;
              background-image: linear-gradient(to right,
                  #6d1513,
                  #e52420,
                  #6d1513);
              border-radius: 10px;
              border: 2px solid #ffffff;
              font-family: "Poppins-SemiBold";
              font-size: 15px;
              line-height: 17px;
              text-transform: uppercase;
              letter-spacing: 0.04em;
              padding: 18px 50px;
              text-decoration: none;

              @media only screen and(max-width: 767px) {
                padding: 18px 15px;
              }

              &::after {
                content: url("../images/arrow.png");
                padding-left: 10px;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}

._GB_Header {
  @media only screen and(min-width: 992px) {
    padding: 0 30px;
  }

  ._GB_Header_Nav {
    height: 90px;

    @media only screen and(max-width: 1024px) {
      display: none !important;
    }

    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
      height: 64px !important;
    }

    #_GB_MainMenu {
      .nav-item {
        .nav-link {
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 16px;
          color: #fff;
          cursor: pointer;
          padding: 0 20px;

          @media only screen and (min-width: 1366px) and (max-width: 1919px) {
            font-size: 15px;
          }

          &.menu-points {
            color: #40ab00;
          }

          .profile_image {
            img {
              width: 42px;
              height: 42px;
              object-fit: cover;

              @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                width: 30px;
                height: 30px;
              }
            }
          }
        }

        .t-title {
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 16px;
          color: #fff;
          cursor: pointer;
          text-decoration: none;
        }

        .header_lang {
          border: 2px solid #727070;
          box-sizing: border-box;
          border-radius: 7px;
          position: relative;
          padding: 3px 10px 5px;
          cursor: pointer;

          .lang_selected {
            .lang {
              color: #fff;
              font-family: "Gilroy-SemiBold";
              font-size: 15px;
              line-height: 17px;
              padding-right: 10px;
            }

            .lang_dd {
              padding-left: 5px;
            }
          }

          .language_dd {
            position: absolute;
            background: #181818;
            border: 2px solid #2d2d2d;
            box-sizing: border-box;
            border-radius: 14px;
            width: 180px;
            right: 0;
            top: 35px;
            padding: 4px 10px;

            .lang_dd_each {
              margin: 4px 0;
              font-family: "Gilroy-Bold";
              color: #fff;
              font-size: 14px;
              padding: 8px 10px;
              cursor: pointer;

              &:hover {
                background: #000000;
                border-radius: 14px;
                color: #e52420;
              }
            }
          }
        }

        &.logout {
          padding: 0px 15px;
          cursor: pointer;
        }
      }
    }

    #_GB_LogoSection {
      .logo {
        @media only screen and (min-width: 992px) {
          width: 265px;
        }
      }
    }
  }

  &._GB_User_Header {
    @media only screen and (min-width: 992px) {
      padding: 0 30px;
    }

    ._GB_Header_Nav {
      height: 90px;

      @media only screen and (max-width: 1024px) {
        display: none !important;
      }

      #_GB_MainMenu {
        .nav-item {
          .nav-link {
            font-family: "Gilroy-Bold";
            color: #fff;
            cursor: pointer;
            padding: 0 20px;
            position: relative;

            @media only screen and (max-width: 1366px) {
              padding: 0 12px;
            }

            &.menu-points {
              color: #40ab00;
            }

            &.warning {
              @media only screen and (min-width: 320px) {
                &:hover {
                  .warning_msg {
                    display: block;
                    position: absolute;
                    bottom: -44px;
                    left: -65px;
                    width: 200px;
                  }
                }
              }

              .warning_msg {
                @media only screen and (min-width: 320px) {
                  display: none;
                }
              }
            }

            .dashboard {
              color: #fff;
              text-decoration: none;
              font-family: "Gilroy-Bold";

              img {
                padding-right: 10px;
                width: 50px;

                @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                  width: 40px;
                }
              }
            }

            .com-link {
              color: #fff;
              text-decoration: none;
              font-family: "Gilroy-Bold";

              img {
                padding-right: 10px;
                max-width: 50px;
              }
            }

            p {
              font-size: 14px;
              color: #fff;
              margin-bottom: 0px;

              @media only screen and (max-width: 1366px) {
                font-size: 12px;
                padding-left: 5px;
              }
            }

            .premium_btn {
              border: 2px solid #727070;
              border-radius: 7px;
              text-decoration: none;
              font-size: 16px;
              font-family: "Gilroy-Bold";
              color: #ffc01f;
              padding: 9px 15px;
              width: 164px;

              img {
                height: 24px;
                margin-right: 8px;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }

          .header_lang {
            border: 2px solid #727070;
            box-sizing: border-box;
            border-radius: 7px;
            position: relative;
            padding: 4px 10px 5px;
            cursor: pointer;

            .lang_selected {
              .lang {
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 14px;
                padding-right: 10px;
              }

              .lang_dd {
                padding-left: 5px;
              }
            }

            .language_dd {
              position: absolute;
              background: #181818;
              border: 2px solid #2d2d2d;
              box-sizing: border-box;
              border-radius: 14px;
              width: 180px;
              right: 0;
              top: 35px;
              padding: 4px 10px;

              .lang_dd_each {
                margin: 4px 0;
                font-family: "Gilroy-Bold";
                color: #fff;
                font-size: 14px;
                padding: 8px 10px;
                cursor: pointer;

                &:hover {
                  background: #000000;
                  border-radius: 14px;
                  color: #e52420;
                }
              }
            }
          }
        }
      }
    }
  }
}

._GB_Featured {
  background-color: #000;
  padding: 100px 0;

  @media only screen and(max-width: 1023px) {
    padding: 50px 0;
  }

  .featured_title {
    font-family: "Poppins-Bold";
    color: #fff;
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 35px;

    @media only screen and (min-width: 768px) {
      font-size: 38px;
      line-height: 50px;
      margin-bottom: 70px;
    }

    @media only screen and (min-width: 1366px) {
      font-size: 58px;
      line-height: 70px;
    }
  }

  .featured_slider {
    .indicatorFeatured {
      bottom: -70px !important;
    }

    .featured_slider_inner {
      .featured_slider_image {
        margin-bottom: 40px;

        img {
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }

      .featured_slider_rewards {
        @media only screen and(max-width: 767px) {
          align-items: center;
          flex-direction: column;
        }

        .reward_items {
          background: rgba(0, 0, 0, 0.3);
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 17px 20px;
          margin: 0 15px;
          align-items: center;

          @media only screen and(max-width: 767px) {
            width: 70%;
            margin: 10px 7px;
            justify-content: center;
            padding: 15px 10px;
          }

          .rewards_image {
            @media only screen and(max-width: 767px) {
              margin-bottom: 10px;
            }

            img {
              width: 60px;

              @media only screen and(max-width: 767px) {
                max-height: 60px;
              }
            }
          }

          .rewards_data {
            padding: 0 10px;

            @media only screen and(min-width: 768px) {
              padding: 0 15px;
            }

            .reward_head {
              font-family: "Gilroy-Medium";
              font-size: 18px;
              color: #fff;

              @media only screen and(min-width: 768px) {
                font-size: 24px;
              }
            }

            .reward_score {
              font-family: "Gilroy-Medium";
              font-size: 14px;
              color: #fff;
              opacity: 0.5;

              @media only screen and(min-width: 768px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      @media only screen and(max-width: 767px) {
        top: 63%;
        bottom: unset;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        @media only screen and(max-width: 767px) {
          position: absolute;
          top: -85px;
        }
      }
    }
  }

  .view_all_games {
    padding: 70px;

    @media only screen and(max-width: 575px) {
      padding: 40px 10px;
    }

    .view_all_games_btn {
      height: 56px;
      color: #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border-radius: 10px;
      border: 2px solid #ffffff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding: 18px 50px;
      text-decoration: none;
      margin-left: 15px;

      @media only screen and(max-width: 574px) {
        padding: 18px 25px;
        margin-left: 0;
      }

      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

._GB_News {
  background-color: #000;
  padding: 50px 0;

  ._GB_News_Inner {
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .news_left {
      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      .newstitle {
        font-family: "Poppins-Bold";
        color: #fff;
        font-size: 28px;
        line-height: 30px;
        margin-bottom: 30px;

        @media only screen and (min-width: 768px) {
          font-size: 37px;
          line-height: 50px;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 50px;
          line-height: 60px;
        }

        @media only screen and (max-width: 767px) {
          text-align: center;
        }
      }

      .news_btns_wrap {
        .news_btn {
          background: rgba(0, 0, 0, 0.3);
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          margin-bottom: 20px;
          font-family: "Poppins-Bold";
          text-transform: uppercase;
          color: #fff;
          padding: 38px 30px;
          text-align: left;

          @media only screen and(max-width: 767px) {
            padding: 30px 25px;
          }

          &:hover {
            border: 2px solid #c80000;
          }

          &.latest {
            &::after {
              content: url("../images/forward.png");
              padding-left: 10px;
              float: right;
            }
          }

          &.twitter {
            &::after {
              content: url("../images/twitter.png");
              padding-left: 10px;
              float: right;
            }
          }
        }
      }
    }

    .news_right {
      @media only screen and(max-width: 767px) {
        width: 100%;
      }

      .slider_arrows {
        padding: 0 40px;

        @media only screen and(max-width: 767px) {
          justify-content: center !important;
        }

        .slider_arrow {
          padding-left: 10px;
          cursor: pointer;

          img {
            @media only screen and (max-width: 767px) {
              width: 40px;
            }
          }
        }
      }
    }
  }
}

.loader_wrap {
  color: #fff;
  background-color: #e52420;
  background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
  border-radius: 10px;
  border: 2px solid #ffffff;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
  padding: 10px 20px;
  width: 220px;
  margin: auto;
}

.gb_loader {
  &.dash_loader {
    background-color: #000;
    padding: 60px 0px;
  }

  &.withdrawal {
    width: 100%;
    position: absolute;
    background: #181818;
    z-index: 9999;
    padding:70px 0px;

    &.pad-20 {
      padding: 20px 0px;
    }
  }
     &.withdrawal-admin {
       width: 100%;
       position: absolute;
       background: #181818;
       z-index: 9999;
       padding: 45px 0px;
  
       &.pad-20 {
         padding: 20px 0px;
       }
     }
    

  .gb_loader_text {
    padding-left: 15px;
    font-family: "Gilroy-Bold";
    font-size: 15px;
    color: #ffffff;
  }
}

.rank_section .avatar-chess {
  width: 120px;
  height: 120px;

  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
    width: 90px;
    height: 90px;
  }

  border-radius: 15px;
  // object-fit: scale-down;
  // background: #000;
  object-fit: cover;
  object-position: center;
  background: #272727;
}

.warzone-image-section {
  flex-wrap: wrap;

  .warusername {
    color: #fff;
    padding: 10px;
    margin: 5px;
    border-radius: 15px;
    border: 2px solid #2d2d2d;
    text-transform: uppercase;
    font-family: "Gilroy-Medium";
    background: #050505;
    word-break: break-all;
    font-size: 14px;
  }
}

.errorMsg {
  color: #c80000;
  font-size: 14px;
  font-family: "Gilroy-Regular";
  margin-bottom: 10px;
  margin-top: 5px;
}

/*Steam account verifying*/
.GB_Steam_verify {
  p {
    font-family: "Poppins-Bold";
    color: #fff;
    font-size: 25px;
    letter-spacing: 0em;
  }
}

.gameTitle {
  text-transform: capitalize;
}

.disableCommon {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

._GB_Dashboard .common_change_game {
  display: none !important;
}

._GB_Cron {
  background: #000;

  .crontestbanner {
    height: 400px;

    h1 {
      font-family: "Poppins-Bold";
      color: #fff;
      font-size: 25px;
    }

    .cron_btn {
      height: 56px;
      color: #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border-radius: 10px;
      border: 2px solid #ffffff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding: 18px 50px;
      text-decoration: none;
      margin-left: 15px;

      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }

      &:hover {
        opacity: 0.5;
      }

      @media only screen and (max-width: 767px) {
        margin-top: 15px;
      }
    }
  }
}

.tparty-wrap {
  .tparty-connect {
    text-align: right;
  }
}

.walltet_icon_head {
  width: 41px;
}

.addons-earnings {
  color: gold;
  cursor: pointer;
}

#popover-adddon {
  background: #181818;
  border: 2px solid #2d2d2d;
  color: #fff;
  width: 250px;

  .popover-arrow {
    &::after {
      border-top-color: #2d2d2d !important;
    }
  }

  .popover-header {
    background: #181818;
    border-bottom: 2px solid #2d2d2d;
    color: #fff;
    font-family: "Poppins-Bold";
    text-align: center;

    &.deleted {
      font-size: 12px;
      opacity: 0.4;
    }
  }

  .popover-body {
    color: rgba(235, 235, 245, 0.6);
    font-family: "Poppins-Medium";
    text-align: center;

    .tokenvalue {
      font-family: "Poppins-Bold";
    }

    .ticket_img {
      width: 26px;
    }

    .balancematchid {
      text-align: center;
      margin-bottom: 15px;
    }
  }
}

.show-grid.wallet-section.modal-body {
  padding: 0;

  .selconimg {
    padding-right: 10px;
  }

  ._conwallet_refresh {
    padding-left: 10px;
  }
}

.token_greenred_arrow {
  margin-right: 15px;
}

.connect_popup {
  z-index: 9999;
}

/****************email verify*************/
.email_verify {
  background: linear-gradient(228.87deg,
      #131313 7.3%,
      rgba(19, 19, 19, 0) 67.05%);
  background-color: #000;

  .register_gb_logo {
    padding: 20px;
  }

  .register_wrap {
    margin-top: -88px;
    background-color: transparent;
    background: transparent;

    .register_left {
      background-color: transparent;

      .register_left_inner {
        width: 100%;
        max-width: 65%;
        background-color: #000;
        box-shadow: 1px -1px 10px #2a2a2a;
        border-radius: 18px;
        padding: 50px 0px;

        p {
          margin-top: 30px;
        }

        a {
          color: #c80000;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}

.response_contaniner {
  font-family: "Gilroy-Medium";
  font-size: 14px;
  line-height: 25px;
  color: #e10c0c;

  @media only screen and (min-width: 991px) {
    font-size: 16px;
  }

  .verify_link {
    cursor: pointer;
    color: #ffc01f;
    text-decoration: none;
  }

  &.success {
    color: #0bff1a;
  }
}

/*************************************/

/*-----About-Page-Styles-Starts-----*/
._GB_AboutUs {
  background-color: #000;

  .about-banner {
    background-image: url("../images/about-banner.png");
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 50px;
    padding-bottom: 50px;

    @media only screen and (min-width: 1366px) {
      padding-top: 175px;
      padding-bottom: 175px;
    }

    .text-block {
      max-width: 740px;
      margin: auto;
      text-align: center;

      h3 {
        font-size: 28px;
        color: #fff;

        @media only screen and (min-width: 768px) {
          font-size: 45px;
        }
      }
    }

    .games-list {
      margin-top: 30px;
      flex-wrap: wrap;
      padding-left: 0;
      justify-content: center;

      li {
        // margin-bottom: 25px;
        list-style: none;
        margin: 10px;

        @media only screen and (min-width: 768px) {
          flex: 0 0 48%;
          max-width: 48%;
        }

        @media only screen and (min-width: 1024px) {
          flex: 0 0 33.3333%;
          max-width: 33.3333%;
        }

        @media only screen and (min-width: 1366px) {
          flex: 0 0 19%;
          max-width: 19%;
          margin-bottom: 0;
        }

        a {
          display: inline-block;
          padding: 0 15px;
          background-color: rgba(217, 217, 217, 0.1);
          border-radius: 5px;
          height: 96px;
          width: 222px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
        }
      }
    }
  }

  .who-we-are {
    background: linear-gradient(223.85deg,
        #290000 -2.37%,
        rgba(200, 0, 0, 0) 47.62%);

    @media only screen and(min-width: 768px) and (max-width: 991px) {
      padding: 25px 0;
    }
  }

  .grow-section {
    padding-top: 50px;
    padding-bottom: 50px;

    @media only screen and (min-width: 1366px) {
      padding-top: 175px;
      padding-bottom: 175px;
    }

    .caption-block {
      h3 {
        color: #fff;
        font-family: "Poppins-SemiBold";
        font-size: 23px;
        margin-bottom: 25px;

        @media only screen and (min-width: 768px) {
          font-size: 34px;
          line-height: 47px;
        }

        @media only screen and (max-width: 991px) {
          text-align: center;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 0;

      // max-width: 688px;
      li {
        list-style: none;
        flex: 0 0 50%;
        padding: 0 13px;
        margin-bottom: 26px;

        @media only screen and(max-width: 767px) {
          flex: 0 0 100%;
        }

        div {
          display: flex;
          flex-direction: column;
          padding: 35px;
          border-radius: 20px;
          background: rgba(255, 255, 255, 0.1);

          @media only screen and (max-width: 767px) {
            padding: 30px;
            text-align: center;
          }

          span {
            font-family: "Gilroy-SemiBold";
            color: #fff;
            font-size: 15px;
            line-height: 18px;

            &:first-child {
              font-family: "Gilroy-Bold";
              font-size: 28px;
              line-height: 30px;
              color: #e52420;
              display: inline-block;
              margin-bottom: 15px;

              @media only screen and (min-width: 768px) {
                font-size: 35px;
                line-height: 35px;
              }
            }
          }
        }
      }
    }
  }

  .about-sect-three {
    background-image: url("../images/about-sect-three-back.png");
    background-repeat: no-repeat;
    background-position: left top;
    padding-top: 50px;
    padding-bottom: 50px;

    @media only screen and (min-width: 1366px) {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    .image-section {
      @media only screen and (max-width: 767px) {
        padding-top: 40px;
      }
    }
  }

  .about-bottom-sect {
    padding-top: 50px;
    padding-bottom: 50px;

    @media only screen and (min-width: 1366px) {
      padding-top: 140px;
      padding-bottom: 140px;
    }

    .top-section {
      max-width: 886px;
      margin: auto;
      margin-bottom: 50px;

      h3 {
        color: #fff;
        font-family: "Poppins-SemiBold";
        font-size: 26px;
        text-align: center;

        @media only screen and (min-width: 768px) {
          font-size: 36px;
          line-height: 54px;
        }
      }
    }

    .image-wrapper {
      max-width: 1180px;
      margin: auto;

      .image-sect {
        display: flex;
        align-items: center;

        img {
          @media only screen and (max-width: 767px) {
            margin-bottom: 30px;
            display: none;
          }
        }

        &.sect-2 {
          flex-direction: column;

          img {
            &:not(:last-child) {
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    .bottom-text {
      max-width: 766px;
      margin: auto;
      text-align: center;
      margin-top: 35px;

      p {
        font-family: "Gilroy-Medium";
        font-size: 18px;
        line-height: 32px;
        color: #fff;
      }
    }
  }
}

/*-----About-Page-Styles-End-----*/

/*-----Verify-Game-Popup-Styles-Start-----*/
.verify-popup-modal {
  max-width: 608px !important;
  max-height: none !important;

  .modal-content {
    .modal-body {
      padding: 0 15px 75px 15px !important;

      @media only screen and (min-width: 576px) {
        padding: 0 25px 75px 25px !important;
      }

      @media only screen and (min-width: 768px) {
        padding: 0 40px 170px 40px !important;
      }

      .sub-title {
        h4 {
          font-size: 16px;
          color: #fff;
          font-family: "Poppins-Medium";
          line-height: 23px;

          a {
            color: #e52420;
            font-family: "Poppins-Medium";
            font-weight: 600;
          }
        }
      }

      .content-sect {
        margin-top: 35px;

        @media only screen and (max-width: 767px) {
          text-align: center;
        }

        p {
          text-align: left;
          font-size: 15px;
          // color: #97979a;
          color: #fff;
          font-family: "Poppins-Medium";

          @media only screen and (max-width: 767px) {
            text-align: center;
          }

          a {
            color: #e52420;
            font-family: "Poppins-Medium";
            font-weight: 600;
          }
        }
      }

      .dropzone-box {
        margin-top: 30px;

        p {
          font-size: 12px;
          line-height: 20px;
          // color: #97979a;
          color: #fff;
          font-family: "Poppins-Medium";
          text-align: left;

          @media only screen and (max-width: 767px) {
            text-align: center;
          }
        }

        .dzu-dropzone {
          overflow: auto;
          border: none;
          background: #0e0e0e;
          border: 1px dashed #3e3e3e;
          border-radius: 10px;
          background-image: url("../images/upload-icn.png");
          background-repeat: no-repeat;
          background-position: center 25px;
          position: static;

          //padding: 0 15px;
          .dzu-previewContainer {
            padding: 15px 15px;
            border: none;

            // @media only screen and (max-width: 767px) {
            //   flex-direction: column;
            // }
            &:nth-child(1) {
              margin-top: 60px;
            }

            .dzu-previewStatusContainer {
              progress {
                &::-webkit-progress-bar {
                  background-color: yellow;
                  border-radius: 7px;
                }

                &::-webkit-progress-bar {
                  background-color: red;
                }
              }
            }
          }

          .dzu-inputLabelWithFiles {
            color: #0e0e0e;
            font-size: 13px;
            font-family: "Poppins-Medium";
            margin: 17px auto;

            .dzu-input {
              display: block;
              //margin-top: 15px;
              width: 195px;
              opacity: 0;
              height: 0;
              width: 0;
            }
          }

          .dzu-submitButtonContainer {
            position: absolute;
            bottom: 11px;

            @media only screen and (min-width: 768px) {
              bottom: 71px;
            }

            .dzu-submitButton {
              max-width: 456px;
              background-color: #e52420;
              background-image: linear-gradient(to right,
                  #6d1513,
                  #e52420,
                  #6d1513);
              border: 2px solid #fff;
              border-radius: 10px;
              padding: 17px 50px;
              text-decoration: none;
              color: #fff;
              font-family: "Poppins-SemiBold";
              font-size: 15px;
              line-height: 17px;
              text-transform: uppercase;
              letter-spacing: 0.04em;
              display: inline-block;

              @media only screen and (max-width: 1024px) {
                padding: 12px 25px;
              }

              &::after {
                content: url("../images/arrow.png");
                padding-left: 10px;
              }

              &:hover {
                opacity: 0.5;
                color: #fff;
              }
            }
          }

          .dzu-inputLabel {
            font-size: 13px;
            color: #535353;
            font-family: "Poppins-Medium";
            flex-direction: column;
            padding-top: 65px;
            justify-content: baseline;
            position: static;
            padding-bottom: 25px;

            .dzu-input {
              display: block;
              //margin-top: 15px;
              width: 195px;
              opacity: 0;
              height: 0;
            }
          }
        }
      }

      .content-sect-bottom {
        margin-top: 15px;

        p {
          font-size: 15px;
          color: #535353;
          font-family: "Poppins-Medium";
          text-align: left;
        }

        .btn-section {
          span {
            font-family: "Poppins-Medium";
            font-size: 15px;

            &.success {
              color: #00a900;
            }

            &.error {
              color: #ff0000;
            }
          }
        }
      }
    }

    .modal-footer {
      display: none;
    }
  }
}

/*-----Verify-Game-Popup-Styles-End-----*/

/*-----Admin-Styles-Start-----*/

/*-----Admin-Home-Styles-End-----*/
.admin-home-wrapper {
  background-color: #000;

  section {
    padding: 50px 0;

    @media only screen and (min-width: 768px) {
      padding: 75px 0;
    }

    @media only screen and (min-width: 1366px) {
      padding: 100px 0;
    }
  }
}

.inner-sect {
  .admin-items {
    display: flex;
    justify-content: center;

    li {
      list-style: none;
      margin: 10px 15px;
      // &:not(:last-child) {
      //   margin-right: 15px;
      // }
    }
  }

  hr {
    color: #fff;
  }
}

/*-----Admin-Home-Styles-End-----*/

/*-----Admin-Verify-Styles-Start-----*/
.verify-gamertag-section {
  table {
    td {
      .img-box {
        img {
          max-width: 50px;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }

      &.action {
        text-align: center;

        a {
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

/*-----Admin-Verify-Styles-End-----*/

/*-----Admin-Players-List-Start-----*/
.players-list-section {
  .showing {
    font-family: "Gilroy-Bold";
    font-size: 15px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 9px;
  }

  table {
    .user-details {
      display: flex;
      flex-direction: column;

      .email {
        color: #555555;
        font-size: 14px;
      }

      .sub {
        color: #555555;
        font-size: 14px;
        font-family: "Gilroy-Regular";

        &.red {
          color: #e52420;
        }

        &.yellow {
          color: #ffc01f;
        }

        &.last-login {
          font-size: 12px;
        }
      }
    }

    .games-list {
      .game-name {
        display: block;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        .text {
          min-width: 90px;
          display: inline-block;
          cursor: pointer;
        }

        button {
          margin-left: 10px;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .action-block {
      button {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  .check-boxes {
    .check-container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: -7px;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
        border: 2px solid #ffffff;
      }

      /* On mouse-over, add a grey background color */
      .container:hover input~.checkmark {
        background-color: #ccc;
      }

      /* When the checkbox is checked, add a blue background */
      input:checked~.checkmark {
        background-color: #c80000;
        top: 0;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      input:checked~.checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}

.channeLink a {
  color: gold !important;
  font-size: 14px;
  text-decoration: none;
  width: 100%;
}

._ManageSettings {
  .card {
    background: none;
    color: #fff;

    .setingFormField {
      margin: 20px 0;

      label {
        margin-bottom: 5px;
      }

      textarea {
        resize: none;
      }

      select {
        width: 150px;
        padding: 10px 20px;
        cursor: pointer;
      }
    }

    .loader_wrap {
      margin: 0;
    }
  }
}

.modal-backdrop.show {
  opacity: 0.8 !important;
  z-index: 0;
}

.gMTagTT {
  opacity: 1 !important;
  min-width: 300px !important;

  .tooltip-inner {
    padding: 5px !important;
    background: #000 !important;
    min-width: 300px !important;

    .gt_copy_save {
      input {
        background: none !important;
        border: none;
        color: #fff;
        text-align: center;
        box-shadow: none;
      }
    }
  }
}

.games-list {
  .game-name {
    .gtclick {
      img {
        cursor: pointer;
      }
    }
  }
}

.discord2x {
  img {
    width: 40px;
    cursor: pointer;
  }
}

.admin2X {
  .modal-header {
    width: 100%;

    .modal-title {
      color: #fff;
      font-family: "Gilroy-Bold";
      font-size: 26px;
      width: 100%;
      text-align: center;
    }
  }

  .r2xStatus {
    color: #fff;
    font-family: "Gilroy-Bold";
    font-size: 18px;

    span {
      color: gold;
    }
  }

  hr {
    color: #fff;
  }

  .r2xEnDb {
    label {
      color: #fff;
      opacity: 0.5;
      font-family: "Gilroy-Bold";
    }
  }
}

.adminmdl {
  max-width: 80% !important;

  .modal-content {
    color: #fff;
    font-family: "Gilroy-Bold";

    .form-item {
      label {
        color: gold;
      }
    }
  }
}

.emailAdminList {
  margin: 5px 0;
  font-size: 16px;
  width: 350px;

  input {
    background: #000 !important;
    border: 2px solid #2d2d2d;
    box-shadow: none;
    outline: none;
    color: #fff !important;
  }

  button {
    background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
    border: 1px solid #fff !important;
    box-shadow: none;
    outline: none;
  }

  .editEmailAdmin {
    color: #fff;
    margin-left: 10px;
    cursor: pointer;
  }
}

/*-----Admin-Players-List-End-----*/

/*-----Admin-Styles-End-----*/

/*-----Common-Styles-Starts-----*/
.gb-red-btn {
  max-width: 456px;
  background-color: #e52420;
  background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
  border: 2px solid #fff !important;
  border-radius: 10px;
  padding: 17px 8px;
  text-decoration: none;
  color: #fff;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  display: inline-block;
  margin-right: 10px;

  @media only screen and (max-width: 1024px) {
    padding: 12px 15px;
  }

  &::after {
    content: url("../images/arrow.png");
    padding-left: 10px;
  }

  &:hover {
    opacity: 0.5;
    color: #fff;
  }

  &.access-btn {
    padding: 10px 20px;
  }
}

.com-content-image-wrap {
  .content-section {
    @media only screen and (max-width: 767px) {
      text-align: center;
    }

    h2 {
      font-family: "Poppins-Bold";
      font-size: 35px;
      line-height: 40px;
      color: #fff;
      margin-bottom: 20px;

      @media only screen and (min-width: 1366px) {
        font-size: 48px;
        line-height: 48px;
      }
    }

    p {
      font-family: "Gilroy-Medium";
      font-size: 18px;
      line-height: 32px;
      color: #fff;
      max-width: 588px;

      @media only screen and(max-width: 574px) {
        max-width: 350px;
        margin: auto;
      }
    }

    .list-sect {
      ul {
        padding-left: 0;

        li {
          list-style: none;
          position: relative;
          padding-left: 35px;
          font-family: "Gilroy-Medium";
          font-size: 18px;
          line-height: 32px;
          color: #fff;
          display: flex;
          align-items: center;

          @media only screen and(max-width: 574px) {
            text-align: left;
          }

          &::before {
            position: absolute;
            content: "";
            background-image: url("../images/icn-green-tick-2.png");
            height: 16px;
            width: 22px;
            left: 0;
            top: 10px;
          }
        }
      }
    }
  }
}

.common-spinner-back {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}

.image-fluid {
  max-width: 100%;
}

.plane-btn {
  border: none;
  padding: 0;
  background-color: transparent;

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    padding: 0;
    box-shadow: none !important;
    border: none;
  }
}

.com-popup-modal {
  &.common-confirm {
    max-width: 475px;

    .modal-content {
      @media only screen and (max-width: 767px) {
        padding: 15px;
      }

      .modal-header {
        padding-bottom: 1rem;
      }

      .modal-body {
        text-align: left;

        @media only screen and (min-width: 768px) {
          padding: 0 25px;
        }

        .content-sect {
          p {
            color: #fff;
            margin-bottom: 0;
          }
        }
      }

      .modal-footer {
        padding: 0;

        @media only screen and (min-width: 768px) {
          padding: 20px 25px 20px;
        }

        button {
          &:after {
            display: none;
          }

          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  }

  &.delete-game {
    .modal-content {
      .modal-body {
        .title {
          text-align: center;
          color: #c80000;
        }

        .sub-title {
          text-align: center;

          h3 {
            color: rgba(235, 235, 245, 0.6);
            font-family: "Gilroy-Medium";
            font-size: 18px;
            line-height: 28px;
          }

          h4 {
            color: rgba(235, 235, 245, 0.6);
            font-family: "Gilroy-Medium";
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .modal-footer {
        justify-content: center;

        button {
          max-width: none;
        }
      }
    }
  }
}

/*-----Common-Styles-End-----*/

.verifyB4withrawWarning {
  color: #fff;
  width: 100%;
  // text-align: center;
  display: flex;
  align-items: center;
  // justify-content: center;
  font-family: "Gilroy-Bold";

  .red-text {
    color: #e52420;
    margin-right: 5px;
    margin-left: 5px;
  }

  .white-text {
    margin-right: 5px;
  }

  .verifysp-btn {
    background: none;
    color: #fff;
    border: solid 2px #40ab00;
    padding: 6px 10px;
    border-radius: 6px;

    &:hover {
      background: #40ab00;
    }
  }

  .green-text {
    color: #40ab00;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }

  @media only screen and (min-width: 1365px) and (max-width: 1599px) {
    font-size: 12px !important;
  }
}

.web-nav-item {
  a {
    @media only screen and (min-width: 1365px) and (max-width: 1599px) {
      font-size: 12px !important;
    }

    img {
      @media only screen and (min-width: 1366px) and (max-width: 1919px) {
        width: 30px;
      }
    }
  }

  .com-link {
    @media only screen and (min-width: 1365px) and (max-width: 1599px) {
      font-size: 12px !important;
    }
  }
}

#dropdown-profile {
  @media only screen and (min-width: 1365px) and (max-width: 1599px) {
    font-size: 12px !important;
  }
}

#_GB_LogoSection {
  img {
    @media only screen and (min-width: 1024px) and (max-width: 1599px) {
      max-width: 160px !important;
    }
  }
}

.user_device_menu {
  @media only screen and (min-width: 1365px) {
    display: none !important;
  }
}

.wallet-adapter-button-trigger {
  // background: url("../images/wallet.png");

  color: #fff !important;
  text-decoration: none !important;
  font-family: "Gilroy-Bold" !important;
  font-size: 12px !important;
  background-repeat: no-repeat;
  background-size: 35px;
  background-color: #000 !important;
  // padding-left: 60px !important;
  background-position: 10px;
}

.wallet-adapter-button .item-img {
  width: 31px;
  height: 31px;
  align-items: revert;
  display: flex;
}

.wallet-adapter-button .item-img img {
  margin: 0px;
  padding: 0px;
  vertical-align: top;
}

.wallet-adapter-dropdown {
  width: 100%;
  background: #000000;
  margin-bottom: 6px;
  padding: 4px 0px;
  border-radius: 10px;
}

.withdraw-wrap.loading {
  opacity: 10%;
}

button.wallet-adapter-button.wallet-adapter-button-trigger {
  width: 100%;
  padding: 0;
  padding: 12px 20px;
  height: 55px;
  border-radius: 10px;
  margin-bottom: 6px;
}

button.wallet-adapter-button.wallet-adapter-button-trigger span.item-text {
  flex: 0 0 90%;
  text-align: left;
  font-size: 1rem;
}

.wallet-adapter-dropdown button.wallet-adapter-button.wallet-adapter-button-trigger span.item-text {
  flex: 0 0 80%;
  text-align: left;
  font-size: 1rem;
}

.mode-popup .wallet-adapter-modal-container {
  display: block;
  margin: 0px;
  min-height: auto;
  align-items: center;
  justify-content: center;
}

.wallet-adapter-modal .wallet_list_dd,
.wallet-adapter-modal .wallet-main-menu,
.wallet-adapter-modal .button.wallet-adapter-modal-button-close {
  z-index: 9;
  position: relative;
}

.GB_BettingScoreboardinnersummary {
  .container {
    // @media only screen and(min-width: 1025px) and (max-width: 1919px) {
    //   max-width: 70% !important;
    //   margin: auto;
    // }
  }
}

.range-calander {
  position: absolute;
  right: 5px;
}

.rdrCalendarWrapper {
  position: absolute;
  right: 0%;
  background: #181818 !important;
  border: 2px solid #2d2d2d;
  border-radius: 5px;
  padding: 20px 0px;
  z-index: 999;

  .rdrDateDisplayWrapper {
    background-color: #181818;
  }

  .rdrDateInput {
    background-color: #000000;

    input {
      font-family: "Gilroy-Bold";
      color: #fff;
    }
  }

  .rdrDayNumber {
    span {
      color: #fff;
      font-family: "Gilroy-Medium";
    }
  }

  .rdrDayPassive {
    .rdrDayNumber {
      span {
        color: #5c5d5e;
        font-family: "Gilroy-Medium";
      }
    }
  }

  .rdrMonthAndYearPickers {
    select {
      color: #fdfeff;
      font-family: "Gilroy-Bold";
    }
  }
}

.calander-close {
  background-image: url("../images/betting/close_icon.png");
  display: block;
  position: absolute;
  right: 8%;
  top: 15%;
  z-index: 9999;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  margin-top: 8px;
  cursor: pointer;
}

.remove-challenge {
  background-image: url("../images/betting/betting-close.png");
  display: block;
  width: 26px;
  height: 26px;
  margin-left: 15px;
  background-repeat: no-repeat;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.challenge_fee {
  display: block;
  margin: 10px;
  color: gold;
  font-size: 17px;
}

.ticket-popover {
  width: 100% !important;
  max-width: 350px;

  .ticketmatchid {
    flex-direction: column;
    text-align: center;
    margin-bottom: 15px;
  }

  .tokenvalue {
    font-size: 13px;
    color: gold;
  }
}

.challenge_failed {
  width: 55px;
}

.bh_ticket_th {
  text-align: left !important;
}

.bh_ticketimg {
  margin-right: 30px !important;
  cursor: pointer;
}

.tn_copy_save {
  input {
    background: none !important;
    color: #fff;
    text-align: right;
    border: none;
    max-width: 200px;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
  }
}

.usernamedit {
  font-size: 14px;
  margin-left: 10px;
  cursor: pointer;
}

img.usdt_coin {
  width: 21px;
}

img.usdc_coin {
  width: 21px;
}

img.selBetCoin {
  width: 24px;
  margin-right: 6px;
}

.trx_coin_img {
  margin-right: 10px;
}

.bet-reward-percentage {
  display: flex;
  margin-top: 15px;

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }

  .each_percent {
    padding: 10px;
    margin: 0 10px;
    cursor: pointer;
    background: #181818;
    border: 3px solid #2d2d2d;
    border-radius: 7px;
    color: #fff;
    font-family: "Gilroy-Bold";
    font-size: 16px;

    &:hover {
      background: rgba(255, 192, 31, 0.4);
      border: 3px solid #ffc01f;
    }

    &.active {
      background: rgba(255, 192, 31, 0.4);
      border: 3px solid #ffc01f;
    }
  }
}

.homebetcoin {
  margin-right: 5px;
}

.homehistgameimg {
  height: 50px;
  width: 60px;
  font-family: "Gilroy-Bold";
  background: #000 !important;
  border-color: #2d2d2d !important;
  object-fit: scale-down;

  &.active {
    background: rgba(200, 0, 0, 0.5) !important;
    border-color: #c80000 !important;
  }
}

.homebethistoryloader {
  .gb-loader-text {
    font-family: "Gilroy-Bold";
    color: #fff;
    padding-left: 10px;
    font-size: 18px;
  }
}

.winhomebet {
  color: #52dd21;
}

.losthomebet {
  color: #c80000;
}

.on-progress {
  opacity: 0.5;
}


.homemob_bethistory_head {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #1e1e1e;
  padding: 5px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .homemob_head {
    flex: 0 0 33%;
    color: #fff;
    font-family: Gilroy-Bold;
    font-size: 12px;

    &.player {
      flex: 0 0 50%;
    }

    &.bet {
      flex: 0 0 25%;
      padding: 0 5px;
    }
  }
}

.homemob_bethistory_body {
  display: flex;
  padding: 5px;
  align-items: center;

  .homemob_body {
    flex: 0 0 33%;
    padding: 5px;
    color: #fff;
    font-family: Gilroy-Bold;
    font-size: 11px;

    .challengerplayerhome {
      display: flex;
      align-items: center;

      img {
        border: 2px solid #484848;
        border-radius: 100%;
        box-sizing: border-box;
        margin-right: 5px;
        width: 30px;
        height: 30px;
        object-fit: scale-down;
      }
    }

    &.player {
      flex: 0 0 50%;
    }

    &.bet {
      flex: 0 0 25%;

      ._each_score_right {
        display: flex;
        align-items: center;
      }
    }
  }
}

.homemob_bethistory_body:nth-child(odd) {
  background-color: #212121;
}

.Transactions_Bg {
  margin-bottom: 30px;
}

.ticket_coin_img {
  margin-right: 3px;
}

.tokenvalue.red {
  color: red !important;
}

.tokenvalue.green {
  color: #31FF1F !important;
}

.challenge-radioclick-wrap {
  justify-content: flex-start !important;

  .checkmark {
    top: 5px !important;

    @media only screen and (max-width: 767px) {
      top: 12px !important;
    }
  }

  .challenge-radio-name {
    margin-left: 20px;
    color: #52dd21 !important;
    text-transform: uppercase !important;
  }
}

.challenge-table {
  .reward {
    @media only screen and (max-width: 767px) {
      margin-left: 0px !important;
    }
  }
}

._each_score_right {
  .green {
    color: #52dd21;
  }

  .red {
    color: red;
  }
}

.GB_BettingHistory {
  .user_device_menu {
    padding: 0 10px;

    .item-token {
      font-size: 12px !important;
    }
  }
}

.taMatchdata {
  padding-top: 10px
}

.tokAll_popover {
  z-index: 99999;
  width: 100% !important;
    max-width: 300px !important;
}

.challenge-comment-mark {
  margin-left: 5px;
}

.wd_popup {
  z-index: 9999999999 !important;
}

.withdraw_btn_wrap {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.withdraw_timer_msg {
  font-size: 16px;
  line-height: 21px;
  display: block;
  margin-bottom: 10px;
}

.withdraw_btn_origin {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
  padding: 10px 20px;
}

.cu_items {
  display: block;
  padding: 5px 0;

  img {
    margin-right: 6px;
  }
}

.denyPopup {
  p {
    font-family: "Gilroy-Bold";
    font-size: 18px;
    padding-bottom: 10px;
  }

  .form-control {
    background: #181818;
    border: 2px solid #454545;
    border-radius: 13px;
    color: #ffffff;
    font-family: "Gilroy-Medium";
  }
}
.gmr-2{
margin-right: 10px !important;
}
       