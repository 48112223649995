._GB_RLogin{
    &.login-page{
        .login_inner{
            border-left-color: #6cce3b17 !important;
            @media only screen and (min-width: 768px){
            .login_middle{
                img{
                    height: 160px;
                }
            }
            .login_right_inner{
                min-height: 390px !important;
                .form-item.loginBtn {
                    margin-top: 120px !important;
                }
            }
        }
            

        }
        .login_copyright{
            @media only screen and (min-width: 768px){
            padding: 57px !important;
            }
        }
        .login_wrap{
            @media only screen and (min-width: 1025px){
            height: calc(100vh - 150px);
            }
        }

    }
    &.register-page{
        .login_inner{
            border-right-color: #6cce3b17 !important;
    }
    .login_wrap{
        @media only screen and (min-width: 1025px){
        height: calc(100vh - 60px);
        }
    }
       
    }
    .login_wrap{
        background: linear-gradient(355.71deg, #0E1216 3.49%, rgba(14, 18, 22, 0.015625) 71.81%, rgba(14, 18, 22, 0) 71.81%);
        background-repeat: no-repeat;
        background-size: 100% 105%;
        
       // height: 800px;
        @media only screen and (max-width: 1365px){
            padding: 0 30px;
        }
        @media only screen and (max-width: 1024px){
            height: auto;
        }

            // background-image: url("../../images/redo/register_bg.png"); 
           
            background-image: url("../../images/redo/register_bg.png"); 
            @media only screen and (min-width: 768px) {
                    background-image: url("../../images/redo/login_bg.png");
                    background-size: 105% 100%;
                    animation: wave 10s linear infinite alternate;
                }
            //height: 810px;
            @media only screen and (min-width: 768px) and (max-width: 1024px){
                height: 100vh;
            }

    
        
       
        .login_pattern{
            position: absolute;
            transform-origin: center center;
            animation: rotate 60s linear infinite;
            object-fit: cover;
            left: 35%;
            top: 0%; 
            z-index: 0 !important;
            @media only screen and (max-width: 1365px){
                left: 0%;
                top: 0%; 
            }
            @media only screen and (max-width: 1200px){
                left: 0%;
                top: 25%; 
            }
            @media only screen and (max-width: 1024px){
                display: none;
            }
            img{
                max-width: 90%; 
                @media only screen and (max-width: 1200px){
                    max-width: 70%;
                }
            }
        }
        .first-sec{
            max-width: 612px;
            width: 100%;
            margin: 40px auto 16px;
            text-align: center;
            z-index: 1 !important;
            position: relative;
            .login_title{
                font-family: "Gilroy-Medium";
                font-size: 30px;
                line-height: 32px;
                color: #fff;
                margin-bottom: 20px;
                @media only screen and (max-width: 767px){
                    font-size: 28px;
                    line-height: 30px;
                }
            }
            .subTitle{
                color: #92A6BA;
                font-size: 16px;
                line-height: 24px;
                font-family: "Gilroy-Regular";
                a{
                    color: #92A6BA;
                    text-decoration: none;
                    font-family: "Gilroy-SemiBold";
                    display: inline-block;
                    &:hover{
                    opacity: 0.5;
                    }
                }
            }
        }
        .login_container{
            max-width: 1240px;
            width: 100%;
            margin: auto;
            .login_inner{
                border-radius: 24px;
                background: rgba(4, 5, 6, 0.40);
                backdrop-filter: blur(24px);
                border: 2px solid #2a5e142e;
                border-bottom-color: #2a5e142e;
                @media only screen and (max-width: 767px){
                    flex-direction: column;
                }
                .login_left{
                    padding: 60px 0 60px;
                    justify-content: space-between;
                    @media only screen and (max-width: 767px){
                        max-width: 100%;
                        width: 100%;
                        padding: 30px 20px 20px;
                        align-items: center;
                    }
                    .login_left_inner{
                        @media only screen and (max-width:767px){
                            margin: auto;
                        }
                        .login_connect_wrap{
                            .connect_each {
                                height: 56px;
                                max-width: 402px;
                                width: 100%;
                                background-color: #181F26;
                                margin-bottom: 15px;
                                border-radius: 24px;
                                padding: 20px;
                                cursor: pointer;
                                &:last-child{
                                    margin-bottom: 0px;
                                }
                                .connect_each_inner{
                                    max-width: 402px;
                                    width: 100%;
                                    button{
                                        background-color: #181F26 !important;
                                        box-shadow: none !important;
                                        height: 56px;
                                        max-width: 402px;
                                        width: 100%;
                                        div{
                                            background-color: transparent !important;
                                            padding: 0px!important;
                                            margin-right: 0px!important;
                                            svg{
                                                background-image: url("../../images/redo/Google.png");
                                                width: 24px;
                                                height: 24px;
                                                g {
                                                    display: none;
                                                }
                                            }
                                        }
    
                                        span{
                                            color: #fff;
                                            font-size: 14px;
                                            line-height: 24px;
                                            font-family: 'Inter-Regular';
                                            max-width: 402px;
                                            width: 100%;
                                            text-align: start;
                                            margin-left: 12px;
                                        }
                                    }
                                }
                                .connect_image{
                                    // padding: 10px;
                                    width: 24px;
                                }
                                .connect_type{
                                    color: #fff;
                                    font-size: 14px;
                                    line-height: 24px;
                                    font-family: 'Inter-Regular';
                                    margin-left: 16px;
                                    max-width: 402px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .login_left_bottom{
                        // margin-top: 68px;
                        @media only screen and (max-width: 767px){
                            // margin-top: 30px;
                            text-align: center;
                        }
                        .bottom_title{
                            font-size: 28px;
                            line-height: 34px;
                            font-family: "Gilroy-Medium";
                            color: #fff;
                            margin-bottom: 12px;
                            max-width: 402px;
                            @media only screen and (max-width: 767px){
                                font-size: 22px;
                                line-height: 28px;
                                margin-top: 30px;
                            }
                        }
                        .bottom_details{
                            font-family: "Gilroy-Regular";
                            font-size: 16px;
                            line-height: 24px;
                            color: #E3E8ED;
                            max-width: 402px;
                        }
    
                    }
                }
                .login_middle{
                    padding: 50px 0 68px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    @media only screen and(min-width: 768px) and (max-width: 1024px){
                        padding: 60px 30px;
                    }
                    @media only screen and (max-width: 767px){
                        padding: 20px;
                        justify-content: center;
                        flex-direction: row;
                        gap:25px
                    }
                    .first_sec{
                       @media only screen and (max-width: 767px){
                        display: none;
                       }
                    }
                    .first_mobile_sec{
                        @media only screen and (min-width: 768px){
                         display: none;
                        }
                     }
                    .second_sec{
                        font-family: "Gilroy-Regular";
                        font-size: 16px;
                        line-height: 24px;
                        color: #fff;
                        padding: 20px 0px;
                    }
                    .third_sec{
                        @media only screen and (max-width: 767px){
                            display: none;
                        }
                    }
                    .third_mobile_sec{
                        @media only screen and (min-width: 768px){
                            display: none;
                        }
                    }
                }
                .login_right {
                    padding: 60px 0 60px;
                    @media only screen and (max-width: 767px){
                        max-width: 100%;
                        width: 100%;
                        padding: 20px 20px 30px;
                        align-items: center;
                    }
                    .login_right_inner{
                        min-height: 450px;
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        @media only screen and (max-width:767px){
                            margin: auto;
                            min-height: auto;
                        }
                        .login_form{
                            .form-item{
                                height: 56px;
                                max-width: 402px;
                                width: 100%;
                                background-color: #181F26;
                                margin-bottom: 10px;
                                border-radius: 24px;
                                padding: 20px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .pwdSec{
                                    width: 100%;
                                    .password_eye{
                                        cursor: pointer;
                                    }
                                }
                                input{
                                    background: #181F26 !important;
                                    border: none;
                                    max-width: 372px;
                                    font-size: 14px;
                                    width: 100%;
                                    box-shadow: none;
                                    color: #767A87;
                                    border-radius: 0px !important;
                                    &:focus-visible{
                                        outline: none;
                                    }
                                }
                                input:-webkit-autofill,
                                input:-webkit-autofill:hover, 
                                input:-webkit-autofill:focus, 
                                input:-webkit-autofill:active{
                                    -webkit-box-shadow: 0 0 0 30px #181F26 inset !important;
                                    border: none;
                                    -webkit-text-fill-color: #767A87 !important;
                                }
                                &.loginBtn{
                                    margin-top: 175px;
                                    margin-bottom: 24px;
                                    padding: 0;
                                    @media only screen and (max-width: 767px){
                                        margin-top: 30px;
                                    }
                                    button{
                                        max-width: 402px;
                                        width: 100%;
                                        height: 56px;
                                        border-radius: 24px;
                                        background-color: #51E20E;
                                        box-shadow: 0px 2px 4px 0px #4EFF6105;
                                        box-shadow: 0px 16px 56px -4px #4EFF613D;
                                        font-size: 14px;
                                        line-height: 24px;
                                        &:hover{
                                            opacity:0.5
                                        }
                                        img{
                                            margin-left: 5px;
                                        }
                                        span{
                                            font-family: 'Inter-Medium';
                                            color: #0E1216;
                                        }
                                    }
                                }
                                &.registerBtn{
                                    padding: 0;
                                    justify-content: center !important;
                                    @media only screen and (max-width: 767px){
                                        margin-top: 30px;
                                    }
                                    button{
                                        max-width: 402px;
                                        width: 100%;
                                        height: 56px;
                                        border-radius: 24px;
                                        background-color: #51E20E;
                                        box-shadow: 0px 2px 4px 0px #4EFF6105;
                                        box-shadow: 0px 16px 56px -4px #4EFF613D;
                                        font-size: 14px;
                                        line-height: 24px;
                                        &:hover{
                                            opacity:0.5
                                        }
                                        img{
                                            margin-left: 5px;
                                        }
                                        span{
                                            font-family: 'Inter-Medium';
                                            color: #0E1216;
                                        }
                                    }
                                }
                                &.calendar-field{
                                    
                                    
                                    input{
                                        background:  url("../../images/redo/calendar.png") 100% 50% no-repeat !important;
                                      }
                                      .rdrCalendarWrapper{
                                        background-color: #181F26 !important;
                                        right: 5%;
                                        bottom: 20%;
                                        .rdrMonthAndYearPickers{
                                            .rdrMonthPicker{
                                                select{
                                                    option{
                                                        background-color: #181F26;
                                                    }
                                                }
                                            }
                                            .rdrYearPicker{
                                                select{
                                                    option{
                                                        background-color: #181F26;
                                                    }
                                                }
                                            }
                                            
                                            
                                        }
                                        .rdrDayStartPreview  {    
                                            color: #51E20E !important; 
                                            
                                            
                                          }
                                      }
                                  }
                            }
                            .password_requirement{
                                // margin-bottom: 35px;
                                max-width: 402px;
                                width: 100%;
                                margin-top: 10px;
                                .pwd-req-list{
                                    display: flex;
                                    flex-wrap: wrap;
                                    justify-content: space-between;
                                    .pwd-item{
                                        flex: 0 0 48%;
                                        max-width: 48%;
                                        font-family: "Gilroy-Medium";
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: #fff;
                                        &.pass_tick{
                                            &::marker{
                                                color: #51E20E !important;
                                            }

                                        }
                                        &.pass_untick{
                                            &::marker{
                                                color: #feda31 !important;
                                            }

                                        }
                                        @media only screen and(min-width: 768px) and (max-width: 991px){
                                            flex: 0 0 100%;
                                            max-width: 100%;
                                        } 
                                        @media only screen and (max-width: 490px){
                                            flex: 0 0 100%;
                                            max-width: 100%;
                                        } 
                                    }
                                }
                            }
                            .forgot_password{
                                text-align: center;
                                span{
                                    font-family: "Gilroy-Medium";
                                    color: #fff;
                                    font-size: 16px;
                                    line-height: 24px;
                                }
                                a{
                                    color: #51E20E;
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-family: "Gilroy-Bold";
                                    margin-left: 5px;
                                    &:hover{
                                        opacity: 0.5;
                                        }
                                }
                            }
                        }
                        .login_p{
                            font-family: "Gilroy-Medium";
                            color: #fff;
                            font-size: 16px;
                            line-height: 16px;
                            text-align: center;
                            a{
                                color: #51E20E;
                                font-size: 16px;
                                line-height: 12px;
                                font-family: "Gilroy-Bold";
                                margin-left: 5px;
                                display: inline-block;
                                &:hover{
                                    opacity: 0.5;
                                    }
                            }
                        }
                    }
                }
            }
            &.redo_reg_container{
                position: relative;
                z-index: 999;
            }
        }
        
        .login_copyright{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            padding: 30px 0;
            font-size: 14px;
            line-height: 16px;
            font-family: 'Inter-Regular';
            color: #E3E8ED;
            img{
                margin-bottom: 35px;
            }
            @media only screen and (min-width: 1921px){
                padding: 30px 0 180px;
            }
            @media only screen and (max-width: 767px){
                color: #040506;
            }
        }
    }



}
.login_loader {
    color: #212529;
    background-color: #51E20E;
    border-radius: 10px;
    border: 2px solid #51E20E;
    font-family: 'Inter-Medium';
    font-size: 15px;
    padding: 10px 20px;
    height: 64px;
    max-width: 402px;
    width: 100%;
    margin: auto;
    border-radius: 24px;
    gap: 10px;
  }
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes wave {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 100% 0;
    }
}
.verify_pages{
   .register_wrap {
        margin-top: auto;
    }
    .GB_Steam_verify{
        p{
            font-family: "Gilroy-Medium" !important;
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0;
        }
  }
    .register_left_inner {
        max-width: 100% !important;
    
        .verification-logo {
          img {
            width: 300px;
          }
        }
    
        a {
          color: #51E20E;
          
          &:hover{
            opacity: 0.5;  
          }
        }
        .steam_verify{
            font-family: "Gilroy-Medium";
            color: #fff;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0;

        }

      }
}
.login_verify_msg {
    padding: 10px 20px;
    text-align: center;
    max-width: 385px;
}
.login_response_contaniner {
    font-family: "Gilroy-Medium";
    font-size: 14px;
    line-height: 25px;
    color: #e10c0c;
  
    @media only screen and (min-width: 991px) {
      font-size: 16px;
    }
  
    .verify_link {
      cursor: pointer;
      color: #51E20E;
      text-decoration: none;
    }
  
    &.success {
      color: #51E20E;
    }
  }
  .form-item-cbox{
    display: flex;
  }
  ._GB_CB_Container_Wrap{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    max-width: 390px;
    ._GB_CB_Container {
        height: 21px;
        width: 21px;
        position: relative;
        padding-left: 21px;
        margin-right: 10px;
        margin-top: 2px;
        cursor: pointer;
        font-size: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .checkmark{
        top: 0 !important;
        margin-right: 10px;
    }
    .register_check_updates{
        font-family: "Gilroy-Regular";
        font-size: 14px;
        line-height: 24px;
        color: #fff;
        a{
            cursor: pointer;
            color: #51E20E;
            text-decoration: none;
            &:hover{
                opacity: 0.5;
            }
        }
    }
    
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1); /* Invert the color of the default icon */
    cursor: pointer;
  }
  .reg-dob-wrap{
    width: 100%;
    input[type="text"]{
        padding: 0.375rem 0.75rem;
        cursor: pointer !important;
    }
    .react-datepicker__day--disabled{
        opacity: 0.2;
    }
    .react-datepicker {
        font-family: "Gilroy-Medium" !important;  
    }
        select.react-datepicker__month-select,select.react-datepicker__year-select {
            background: #181F26 !important;
            color: #fff;
            outline: none;
            box-shadow: none;
            padding: 5px 10px;
            border-color: #767A87;
        }
                .react-datepicker__current-month{
                    font-size: 16px !important;
                    margin-bottom: 15px;
                }
  }
 
.onchangeerror {
    padding: 10px 15px;
}
.login_form{
    .form-field-error{
        margin-bottom: 10px;
        padding: 5px 15px;
            max-width: 402px;
    }
}
.reg-nomb{
    margin-bottom: 15px;
   
}
.reg-nomb-err {
    margin-bottom: 0;
}