.redoExplore{
    display: flex;  
    .rexplore_wrap{
        width: 100%;
        max-width: 100%;
        overflow-x: hidden;
        .rexplore_inner{
            border: 1px solid rgba(59, 77, 94, 0.5);
            border-top-left-radius: 18px;
            border-bottom: none;
            @media only screen and (max-width: 767px){
                border-top-left-radius: 0px;
            }
            .explore_Banner{
                background-image: url("../../images/redo/explore_banner.png");
                background-repeat: no-repeat;
                background-position: 100%;
                @media only screen and (min-width: 1366px){
                    background-size: cover;
                    height: 395px;
                }
                @media only screen and (max-width: 575px){
                    background-image: url("../../images/redo/mob_eplore_bg.png");
                    height: 669px;
                    background-size: cover;
                    background-position: center;
                }
                .banner_Inner{
                    max-width: 1576px;
                    width: 100%;
                    margin: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 75px 0;
                    @media only screen and (max-width: 767px){
                        flex-direction: column;
                    }
                    .banner_left{
                        max-width: 48%;
                        flex: 0 0 48%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        @media only screen and (max-width: 767px){
                            max-width: 100%;
                            flex: 0 0 100%;
                            width: 100%;
                            padding: 0 15px;
                        }
                        .banner_Tilte{
                            font-size: 66px;
                            line-height: 65px;
                            color: #fff;
                            font-family: "Gilroy-Bold";
                            margin-bottom: 15px;
                            @media only screen and (max-width: 767px){
                                font-size: 32px;
                                line-height: 28px;
                            }
                            span{
                                color: #51E20E;
                            }
                        }
                        .subTitle{
                            font-size: 26px;
                            color: #fff;
                            font-family: "Gilroy-Medium";
                            @media only screen and (max-width: 767px){
                                font-size: 18px;
                                line-height: 21px;
                            }
                        }
                        .bannerDetails{
                            font-size: 16px;
                            line-height: 24px;
                            color: #92A6BA;
                            font-family: "Gilroy-Regular";
                            max-width: 514px;

                        }
                        .actionBtn{
                            background: var(--Primary-Green---50---GB, #f1feec);
                            box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04);
                            max-width: 220px;
                            height: 50px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 20px;
                            cursor: pointer;
                            margin-top: 30px;
                            font-size: 14px;
                            line-height: 24px;
                            color: #020303;
                            font-family: 'Inter-Medium';
                            &:hover{
                                opacity: 0.5;
                            }
                        }
                    }
                    .banner_right{
                        max-width: 48%;
                        flex: 0 0 48%;
                        @media only screen and (max-width: 767px){
                            max-width: 100%;
                            flex: 0 0 100%;
                        }
                    }
                }
               
            }
            .reco_games{
                max-width: 1576px;
                width: 100%;
                margin: 60px auto;
                @media only screen and (max-width: 767px){
                    margin: 32px auto;
                }
                .games_inner{
                    .games_top{
                        margin-bottom: 20px;
                        @media only screen and (max-width: 1920px){
                            padding: 0 15px;
                        }
                       
                        .secTitle{
                            font-size: 18px;
                            font-family: "Gilroy-Medium";
                            color: #DBFFDF;
                            
                            .sub_title{
                                color: #92A6BA;
                                font-size: 14px;
                                line-height: 24px;
                                font-family: "Gilroy-Medium";
                            }
                            
                        }
                        .view_all{
                            font-size: 14px;
                            font-family: "Gilroy-Medium";
                            color: #fff;
                            cursor: pointer;
                            img{
                                margin-left: 8px;
                            }
                            &:hover{
                                opacity: 0.5;
                            }
                        }
                        &.fire_games_top{
                            @media only screen and (max-width: 767px){
                                display: none !important;
                              }
                        }
                    }
                    .game_mob_top{
                        display: block;
                        @media only screen and (min-width: 768px){
                            display: none;
                        }
                        margin-bottom: 20px;
                        @media only screen and (max-width: 1920px){
                            padding: 0 15px;
                        }
                       
                        .secTitle{
                            font-size: 16px;
                            font-family: "Gilroy-Medium";
                            color: #DBFFDF;
                        }
                        .sub_title{
                            color: #92A6BA;
                            font-size: 14px;
                            line-height: 24px;
                            font-family: "Gilroy-Medium";
                            margin-top: 8px;
                        }
                        .view_all{
                            font-size: 14px;
                            font-family: "Gilroy-Medium";
                            color: #fff;
                            cursor: pointer;
                            img{
                                margin-left: 8px;
                            }
                            &:hover{
                                opacity: 0.5;
                            }
                        }
                    }
                    .games_mob_list{
                        gap: 10px;
                        @media only screen and (min-width: 768px){
                            display: none;
                        }
                        .games_listItem{
                            cursor: pointer;
                            max-width: 90%;
                            &:hover{
                                opacity: 0.5;
                            }
                            img{
                                max-width: 302px;
                                width: 100%;
                            }
                        }
                        .gameSlider {
                            overflow: hidden;
                            .slick-prev{
                                &::before{
                                    display: none;
                                }
                            }
                            .slick-next{
                                &::before{
                                    display: none;
                                }

                            }
                            .slick-dots{
                                display: none !important;
                            }
                            .slick-list {
                                margin-left: 0px;
                                margin-right: 0px;
                            }
                           
                        }
                    }
                    .games_list{
                        @media only screen and (max-width: 1920px){
                            padding: 0 15px;
                        }
                        @media only screen and (max-width: 767px){
                            display: none !important;
                        }
                        .games_listItem{
                            @media only screen and (min-width: 768px) and (max-width: 1600px){
                                max-width: 18%;
                                flex: 0 0 18%;
                            }
                            @media only screen and (min-width: 1601px){
                                max-width: 20%;
                                flex: 0 0 20%;
                            }
                            cursor: pointer;
                            &:hover{
                                opacity: 0.5;
                            }
                            img{
                                max-width: 302px;
                                width: 100%;
                            }
                        }
                        
                    }
                    .firelives_mob_sec{
                        @media only screen and (min-width: 768px){
                            display: none;
                        }
                        .firelives_box{
                            .firelive_items{
                                max-width: 80%;
                                .firelive_top{
                                    padding: 8px;
                                    border: 1px solid #3B4D5E;
                                    background: #040506;
                                    border-radius: 24px;
                                    position: relative;
                                   
                                    .live_sec{
                                        position: absolute;
                                        top: 10px;
                                        left: 15px;
                                        max-width: 80px;
                                        height: 40px;
                                        width: 100%;
                                        padding: 6px 16px;
                                        color: #51E20E;
                                        border-radius: 12px;
                                        border: 1px solid rgba(59, 77, 94, 0.5);
                                        backdrop-filter: blur(32px);
                                        font-size: 12px;
                                        line-height: 12px;
                                        font-family: "Gilroy-SemiBold";
                                        background-color: rgba(20, 26, 31, 0.6);
                                        img{
                                            width: 18px;
                                            height: 18px;
                                        }
                                    }
                                    .no_ofparticipient{
                                        position: absolute;
                                        top: 10px;
                                        right: 15px;
                                        padding: 6px 16px;
                                        color: #fff;
                                        max-width: 80px;
                                        height: 40px;
                                        width: 100%;
                                        border-radius: 12px;
                                        font-size: 12px;
                                        line-height: 22px;
                                        border: 1px solid rgba(59, 77, 94, 0.5);
                                        background-color: rgba(20, 26, 31, 0.8);
                                        font-family: "Gilroy-SemiBold";
                                        img{
                                            width: 18px;
                                            height: 18px;
                                        }
                                    }
                                }
                                .firelive_bottom{
                                    padding: 0 8px;
                                    .tournament_name{
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: #fff;
                                        font-family: "Gilroy-Medium";
                                        padding: 8px 0;
                                    }
                                    .player_name{
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: #92A6BA;;
                                        font-family: "Gilroy-Medium";
                                    }
                                    // .game_name{
                                    //     color: #92A6BA;
                                    //     font-size: 12px;
                                    //     line-height: 24px;
                                    //     font-family: "Gilroy-Medium";
                                    //     padding: 6px 0;
                                    // }
                                    .total_earning{
                                        .game_logo{
    
                                        }
                                        .earning_sec{
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-end;
                                            span{
                                                color: #DBFFDF;
                                                font-size: 12px;
                                                line-height: 24px;
                                                font-family: "Gilroy-Medium";
                                            }
                                            .coins_earned{
                                                color: #fff;
                                                font-size: 18px;
                                                font-family: "Gilroy-Medium";
                                                display: flex;
                                                align-items: center;
                                                img{
                                                    margin-right: 8px;
                                                    height: 24px;
                                                    width: 24px;
                                                }
                                            }
                                        }
                                    }
                                }
                               
                            }
                            .liveSlider{
                                .slick-prev{
                                    &::before{
                                        display: none;
                                    }
                                }
                                .slick-next{
                                    &::before{
                                        display: none;
                                    }
    
                                }
                                .slick-dots{
                                    display: none !important;
                                }
                                .slick-list {
                                    margin-left: 0px;
                                    margin-right: 0px;
                                  }
                            }
                        }
                    }
                    .firelives_sec{
                        @media only screen and (max-width: 767px){
                            display: none;
                        }
                        .firelives_box{
                            gap: 20px;
                            @media only screen and (min-width: 768px){
                                flex-wrap: wrap;
                            }
                            @media only screen and (max-width: 1920px){
                                padding: 0 15px;
                            }
                            // img{
                            //     width: 100%;
                            // }
                            .firelive_items{
                                max-width: 23.5%;
                                flex: 0 0 23.5%;
                                @media only screen and (max-width: 1400px){
                                    max-width: 48%;
                                    flex: 0 0 48%;
                                }
                                .firelive_top{
                                    padding: 8px;
                                    border: 1px solid #3B4D5E;
                                    background: #040506;
                                    border-radius: 24px;
                                    position: relative;
                                    .sub_img{
                                        width: 100%;
                                    }

                                    .live_sec{
                                        position: absolute;
                                        top: 10px;
                                        left: 15px;
                                        max-width: 97px;
                                        height: 40px;
                                        width: 100%;
                                        padding: 6px 16px;
                                        color: #51E20E;
                                        border-radius: 12px;
                                        border: 1px solid rgba(59, 77, 94, 0.5);
                                        backdrop-filter: blur(32px);
                                        font-size: 14px;
                                        line-height: 24px;
                                        font-family: "Gilroy-SemiBold";
                                        background-color: rgba(20, 26, 31, 0.6);
    
                                    }
                                    .no_ofparticipient{
                                        position: absolute;
                                        top: 10px;
                                        right: 15px;
                                        padding: 6px 16px;
                                        color: #fff;
                                        max-width: 97px;
                                        height: 40px;
                                        width: 100%;
                                        border-radius: 12px;
                                        font-size: 14px;
                                        line-height: 24px;
                                        border: 1px solid rgba(59, 77, 94, 0.5);
                                        background-color: rgba(20, 26, 31, 0.8);
                                        font-family: "Gilroy-SemiBold";
                                    }
                                }
                                .firelive_bottom{
                                    padding: 0 8px;
                                    .tournament_name{
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: #fff;
                                        font-family: "Gilroy-Medium";
                                        padding: 8px 0;
                                    }
                                    .player_name{
                                        font-size: 14px;
                                        line-height: 24px;
                                        color: #92A6BA;;
                                        font-family: "Gilroy-Medium";
                                    }
                                    // .game_name{
                                    //     color: #92A6BA;
                                    //     font-size: 12px;
                                    //     line-height: 24px;
                                    //     font-family: "Gilroy-Medium";
                                    //     padding: 6px 0;
                                    // }
                                    .total_earning{
                                        .game_logo{
    
                                        }
                                        .earning_sec{
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-end;
                                            span{
                                                color: #DBFFDF;
                                                font-size: 12px;
                                                line-height: 24px;
                                                font-family: "Gilroy-Medium";
                                            }
                                            .coins_earned{
                                                color: #fff;
                                                font-size: 18px;
                                                font-family: "Gilroy-Medium";
                                                display: flex;
                                                align-items: center;
                                                img{
                                                    margin-right: 8px;
                                                    height: 24px;
                                                    width: 24px;
                                                }
                                            }
                                        }
                                    }
                                }
                               
                            }
                        }
                        
                    }
                }
            }
            .betTable{              
                padding-bottom: 50px;
                @media only screen and (max-width: 767px){
                    padding-bottom: 24px;
                }
                @media only screen and (min-width: 768px){
                    background-image: url("../../images/redo/dashboardDetails_bg.png");
                    background-repeat: no-repeat;
                    background-size: 100%;
                }
                .betTableInner{
                    max-width: 1576px;
                    width: 100%;
                    margin: auto;
                    @media only screen and (max-width: 1600px){
                        padding: 0 15px;
                    }
                    .top_sec{
                        .secTilte{
                            color: #DBFFDF;
                            font-size: 18px;
                            line-height: auto;
                            font-family: "Gilroy-Medium";
                        }
                        #gamedrop{
                            max-width: 120px;
                            width: 100%;
                            height: 40px;
                            border: 1px solid rgba(59, 77, 94, 0.5);
                            border-radius: 20px;
                            backdrop-filter: blur(32px);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            padding: 15px;
                            color: var(--Background---grey-Grey---50---GB, #E3E8ED);
                            font-family: "Gilroy-Medium";
                            font-size: 12px;
                            line-height: 24px;
                            background: transparent !important;
                            &:hover {
                                border-color: #74F33B;
                                background: rgba(116, 243, 59, 0.20) !important;
                                &::after {
                                    border-top: none !important;
                                    border-right: none !important;
                                    content: "";
                                    background-image: url("../../images/redo/arrow-down-green.png");
                                    background-repeat: no-repeat;
                                    width: 24px;
                                    height: 24px;
                                }
                            }
    
                            &::after {
                                border-top: none !important;
                                border-right: none !important;
                                content: "";
                                background-image: url("../../images/redo/whitearrow-down.png");
                                background-repeat: no-repeat;
                                width: 24px;
                                height: 24px;
                            }
                            &:active{
                                background: rgba(116, 243, 59, 0.20) !important;
                            }
    
                        }
                        .dropdown-menu{
                            border: 1px solid #1c2e2b;
                            border-radius: 10px;
                            width: 120px;
                            background-color: #0E1216;
                            .dropdown-item{
                                color: #ffff;
                                &:hover{
                                    color: #52dd21;
                                    font-size: 14px;
                                }
                          
                            }
                            .dropdown-item:hover{
                              background: none;
                              opacity:0.7;
                            }
                          }
                    }
                    #score_Table {
                        margin: 25px 0 ;
                        thead {
                            background: #141A1F;
                            th {
                                color: var(--Primary-Green---500---GB, #74F33B);
                                font-family: 'Inter-Medium';
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 24px;
                                height: 48px;
                                padding: 16px 8px;
                                border-bottom: none;
                                text-align: center;
        
                                &:first-child {
                                    border-top-left-radius: 20px !important;
                                  
                                }
        
                                &:last-child {
                                    border-top-right-radius: 20px !important;
                                }
        
                                &:nth-child(2) {
                                    text-align: left;
                                }
                                &:nth-child(3) {
                                    text-align: left;
                                    padding: 12px;
                                }
                                &.bet-second-col{
                                    @media only screen and (max-width: 1024px){
                                        min-width: 175px;
                                    }
                                }
                                &.bet-third-col{
                                    @media only screen and (max-width: 1024px){
                                        min-width: 250px;
                                    }
                                }
                                &.bet-fourth-col{
                                    text-align: left;
                                    @media only screen and (max-width: 1024px){
                                        min-width: 120px;
                                    }
                                }
                                &.bet-fifth-col{
                                    text-align: left;
                                    @media only screen and (max-width: 1024px){
                                        min-width: 120px;
                                    }
                                }
                            }
                        }
        
                        tbody {
                            tr{
                                border: none !important;
                            }
        
                            td {
                                color: #DBFFDF !important;
                                font-family: 'Inter-Regular';
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                height: 80px;
                                vertical-align: middle;
                                padding: 12px 6px;
                                backdrop-filter: blur(32px);
                                background: rgba(14,18,22,0.4);
                               border: none;
                                &:nth-child(2) {
                                    text-align: left;
                                    img{
                                        margin-right: 10px;
                                        border-radius: 24px;
                                    }
                                }
                                &:nth-child(3) {
                                    text-align: left;
                                    padding: 12px;
                                }
                                .betStreamerName{
                                    color: #DBFFDF !important;
                                }
        
        
                            }
        
                            img.img-fluid.dota2 {
                                max-width: 35px;
                            }
        
                            img.img-fluid.pubg {
                                max-width: 47px;
                            }
        
                            img.img-fluid.avataritem {
                                max-width: 32px;
                            }
        
                            .profilesection {
                                display: flex;
                                align-items: center;
                                gap: 6px;
        
                                p {
                                    color: var(--Neutral-Gray-400, #9296A1);
                                    font-family: 'Inter-Regular';
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 24px;
                                    margin-bottom: 0px;
                                }
        
                            }
        
        
        
        
                        }
        
                        .form-check-input {
                            background-color: #3B4D5E;
                            height: 16px;
                            width: 16px;
        
                            &:checked {
                                background-color: #74F33B;
                                border-color: #74F33B;
                            }
        
                            &:focus {
                                outline: none;
                                border: none;
                                box-shadow: none;
                            }
                        }
        
                        .form-check-input:checked[type=checkbox] {
                            background-image: url("../../images/redo/tick-black.png");
                            background-size: auto;
                        }
        
                    }
                }
                

                
            }
        }
    }
    
}
.sort_sec{
    #sort_drop {
        width: 200px;
        height: 40px;
        border: 1px solid rgba(59, 77, 94, 0.5);
        border-radius: 20px;
        backdrop-filter: blur(32px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 15px;
        color: var(--Background---grey-Grey---50---GB, #E3E8ED);
        font-family: "Gilroy-Medium";
        font-size: 12px;
        line-height: 24px;
        background: transparent !important;
        @media only screen and (max-width: 767px){
            width: 150px;
        }
        &:hover {
            border-color: #74F33B;
            background: rgba(116, 243, 59, 0.20) !important;
            color: #74F33B;
            &::after {
                border-top: none !important;
                border-right: none !important;
                content: "";
                background-image: url("../../images/redo/arrow-down-green.png");
                background-repeat: no-repeat;
                width: 24px;
                height: 24px;
            }
        }
    }
    .dropdown-menu{
        border: 1px solid #1c2e2b;
        border-radius: 10px;
        width: 200px;
        background-color: #0E1216;
        @media only screen and (max-width: 767px){
            width: 150px;
        }
        .dropdown-item{
            color: #ffff;
            &:hover{
                color: #52dd21;
                font-size: 14px;
            }
      
        }
        .dropdown-item:hover{
          background: none;
          opacity:0.7;
        }
      }
}
