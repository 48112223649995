/*************** aminlistplayer start *****************/
.GB_AdminCommon {
  display: flex;

  .adminCommon_left {
    background-color: #161515;

    @media only screen and (min-width: 1920px) {
      padding-top: 133px !important;
    }

    @media only screen and (min-width: 1366px) {
      position: auto;
      margin: 0;
      padding-top: 107px;
    }

    .betting-left-container {
      .big-sidebar {
        width: 240px;

        @media only screen and (min-width: 1025px) and (max-width: 1919px) {
          width: 200px !important;
        }
      }

      .small-sidebar {
        width: 60px;

        .menu-section {
          .menu-nav {
            li {
              &:hover {
                margin-left: 10px;
                width: 200px;
              }

              &.admin-list {
                &:hover {
                  width: 50px;
                }
              }

              .competition-menu {
                width: 200px;
                right: -200px !important;

                a {
                  width: 175px;
                }
              }
            }
          }
        }
      }

      &.collapsed {
        transition: all 0.5s ease-out;
        max-width: 60px;
        background-color: #212121;

        @media only screen and (max-width: 1025px) {
          max-width: 6%;
        }

        .menu-section {
          .menu-nav {
            li {
              a {
                .slow {
                  font-size: 14px;
                  width: 200px;
                }
              }
            }
          }
        }
      }

      .competition-menu {
        left: 100%;
        // right: -173px !important;

        // @media only screen and (min-width: 1025px) and (max-width: 1919px) {
        //   right: -158px !important;
        // }
      }

      .transaction-menu {
        left: 100%;
        // right: -163px !important;

        // @media only screen and (min-width: 1025px) and (max-width: 1919px) {
        //   right: -150px !important;
        // }
      }

      .betting-menu {
        left: 100%;
        // right: -190px !important;

        // @media only screen and (min-width: 1025px) and (max-width: 1919px) {
        //   right: -178px !important;
        // }
      }

      .admin-sidedrop {
        justify-content: space-between;

        .slow {
          width: 125px !important;
        }
      }
    }
  }

  .adminCommon_right {
    width: 100%;
    max-width: auto;

    // padding: 0px 30px;
    .plane-btn {
      margin-left: 15px;

      @media only screen and (min-width: 768px) and (max-width: 1600px) {
        margin-left: 20px;
      }
    }

    .gb-red-btn {
      @media only screen and (max-width: 1500px) {
        padding: 10px 6px !important;
      }

      @media only screen and (min-width: 768px) and (max-width: 1250px) {
        &:after {
          display: none;
        }
      }

      //max-width: 136px;
      // padding: 10px 12px;
    }

    #subscribe {
      .container {
        padding: 15px 30px;

        @media only screen and (min-width: 1366px) {
          max-width: 100% !important;
        }

        @media only screen and (min-width: 1921px) {
          max-width: 1600px !important;
        }
      }
    }

    .footer {
      .container {
        padding: 0 30px;

        @media only screen and (min-width: 1366px) {
          max-width: 100% !important;
        }

        @media only screen and (min-width: 1921px) {
          max-width: 1600px !important;
        }
      }
    }

    ._GB_Leaderboard_Banner {
      padding: 0px 0px 20px;

      .adminlistcontainer {
        //max-width: auto !important;
        max-width: 100% !important;
        width: 100%;
        padding: 0px 30px;

        // @media only screen and (min-width: 1366px) {
        //   max-width: 100% !important;
        // }

        // @media only screen and (min-width: 1921px) {
        //   max-width: 1600px !important;
        // }

        @media only screen and (max-width: 767px) {
          padding: 0px 15px;
        }

        // ._GB_Leaderboard_Inner {
        //     h2{
        //         line-height: 60px !important;
        //     }
        // }
      }
    }

    &.adminbeta_right {
      max-width: 100%;
      width: 100%;

      @media only screen and (min-width: 1366px) {
        padding-top: 135px;
      }
    }

    .betYourself_inner {
      padding: 0px 0px 20px;

      .adminlistcontainer {
       // max-width: auto;
        max-width: 100% !important;
        width: 100%;
        padding: 0px 30px;

        // @media only screen and (min-width: 1366px) {
        //   max-width: 100% !important;
        // }

        // @media only screen and (min-width: 1921px) {
        //   max-width: 1600px !important;
        // }

        @media only screen and (max-width: 767px) {
          padding: 0px 15px;
        }
      }
    }

    #score_Table {
      thead {
        background: #1e1e1e;

        tr {
          td {
            background-color: #1e1e1e;
          }
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            td {
              border-color: #000;
              background: #212121;
            }
          }

          &:nth-child(odd) {
            td {
              border-color: #000;
              background: #161616;
            }
          }

          .action_td {
            display: flex;
            justify-content: flex-end;
            gap: 10px;
          }

          td {
            &.dispute {
              text-align: left !important;

              .dispute-open {
                color: #6c757d;
                border: 2px solid #2d9923;
                border-radius: 10px;
                padding: 2px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: fit-content;
              }

              .dispute-closed {
                background-color: #fdbf1f;
                color: #000;
                border: 2px solid #fdbf1f;
                border-radius: 10px;
                padding: 2px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                max-width: fit-content;
              }
            }

            .action {
              p {
                margin-top: 6px;
                color: rgba(235, 235, 245, 0.6);
              }

              .deny {
                color: #c80000;
              }

              .approve {
                color: #52dd21;
              }
            }

            .rotate-arrow {
              transform: rotate(180deg);
              top: -2px;
              position: relative;
            }

            .bet-in-progress {
              color: #ffffff;
              opacity: 0.6;
            }

            .bet-failed {
              color: #c80000;
            }

            .bet-passed {
              color: #52dd21;

              img {
                margin-right: 2px;
              }
            }
          }
        }

        .details-row {
          transition: opacity 0.5s linear, transform 0.5s linear,
            max-height 0.5s linear, padding 0.5s linear;

          &.hide {
            display: none;
            transform: scaleY(0);
          }
        }
      }
    }

    .Leaderboard_Bg {
      @media only screen and (max-width: 575px) {
        padding: 15px !important;
      }

      .full_section {
        .Leaderboard_details {
          .d-flex {
            align-items: center;
            justify-content: center;
          }

          .leaderboard_showing {
            @media only screen and (max-width: 767px) {
              flex-direction: column;
            }

            .showing {
              .showing_inner {
                @media only screen and (max-width: 767px) {
                  justify-content: center;
                }
              }

              .lbtokend {
                @media only screen and (max-width: 767px) {
                  text-align: center;
                }
              }
            }
          }

          .no_of_players {
            @media only screen and (max-width: 767px) {
              flex-direction: column;
            }
          }

          .showing {
            @media only screen and (max-width: 767px) {
              width: 100% !important;
              margin-bottom: 0;
              font-size: 15px;
              text-align: center;
            }
          }

          table {
            thead {
              background: #1e1e1e;

              tr {
                background: #1e1e1e;

                th {
                  @media only screen and (min-width: 768px) and (max-width: 1250px) {
                    font-size: 10px !important;
                  }

                  @media only screen and (min-width: 1251px) and (max-width: 1600px) {
                    font-size: 12px !important;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  font-family: "Gilroy-Bold";
                  font-size: 16px !important;
                  line-height: 16px !important;
                  color: #ffffff;

                  @media only screen and (max-width: 767px) {
                    font-size: 10px !important;

                    &::before {
                      top: 0px !important;
                    }
                  }

                  .emailAdmin {
                    font-size: 12px;
                    line-height: 12px;
                  }

                  .user-details {
                    justify-content: center;
                  }

                  .audit-btn {
                    padding: 10px;
                  }

                  .viewMatch {
                    display: flex;
                    gap: 5px;
                    justify-content: center;
                    align-items: center;
                  }

                  &:nth-child(even) {
                    td {
                      border-color: #000;
                      background: #212121;
                    }
                  }

                  &:nth-child(odd) {
                    td {
                      border-color: #000;
                      background: #161616;
                    }
                  }

                  &.GB_device_td {
                    &::before {
                      position: relative;
                    }
                  }

                  @media only screen and (min-width: 768px) and (max-width: 1250px) {
                    font-size: 10px !important;
                  }

                  @media only screen and (min-width: 1251px) and (max-width: 1600px) {
                    font-size: 11px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.adminnew_header {
  @media only screen and (min-width: 1366px) {
    position: fixed;
    width: 100%;
    z-index: 999;
    border-bottom: 1px solid #454545;
  }
}

// ._GB_LeaderBoard ._GB_Banner_Top ._GB_Leaderboard_Banner {
//     background-color: #000;
//     /* padding: 50px 0px; */
// }
.adminfull_top {
  @media only screen and (min-width: 1366px) {
    ._GB_HeaderNewsFeed {
      position: fixed;
      z-index: 999999;
      width: 100%;
    }
  }

  ._GB_Header {
    @media only screen and (min-width: 1366px) {
      position: fixed;
      top: 42px;
    }

    width: 100%;
    border-bottom: 1px solid #454545;
    z-index: 9999;
    background-color: #000;
  }

  ._GB_Leaderboard_Banner {
    padding: 0px 0px 50px 0px !important;
  }

  ._GB_Leaderboard_Inner {
    h2 {
      @media only screen and (max-width: 1899px) {
        font-size: 26px !important;
        line-height: 52px !important;
      }
    }
  }
}

/*************** aminlistplayer start *****************/

/*************** leaderboard-history start *****************/
.leaderboard-history {
  .leaderboard-history-top {
    display: flex;
    align-items: center;
    gap: 20px;

    @media only screen and (max-width: 990px) {
      flex-direction: column;
    }

    .prev-title {
      font-size: 18px;
      line-height: 18px;
      font-family: "Gilroy-Medium";
      color: #fff;

      @media only screen and (max-width: 767px) {
        text-align: left;
      }
    }

    .leaderboard-history-box {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px;
      max-width: 500px;
      width: 100%;

      .history-box {
        max-width: 200px;
        width: 100%;

        @media only screen and (min-width: 768px) {
          max-width: 235px;
        }
      }
    }

    .search-season-form-control {
      background: #181818 !important;
      border: 2px solid #2d2d2d;
      border-radius: 15px;
      height: 48px;
      width: 100%;
      max-width: 235px;
      font-family: "Gilroy-Bold";
      font-size: 18px;
      line-height: 19px;
      color: #fff !important;
    }
  }

  .result-msg {
    font-size: 18px;
    line-height: 18px;
    font-family: "Gilroy-Medium";
    color: #fff;
    padding: 25px 0px;
  }
}

/*************** leaderboard-history End *****************/

/*************** userStatic_formdata Start *****************/
.userStatic_formdata {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 52px 30px 33px;
  background: #121212;
  border: 2px solid #2d2d2d;
  border-radius: 20px;
  margin-top: 30px;

  @media only screen and (max-width: 1433px) {
    flex-direction: column;
  }

  &:nth-child(3) {
    @media only screen and (max-width: 1635px) {
      flex-direction: column;
    }
  }

  .formdata_left {
    display: flex;
    align-items: center;
    gap: 20px;

    @media only screen and (max-width: 1100px) {
      flex-direction: column;
    }

    .formfield_box {
      display: flex;
      flex-direction: column;
      // max-width: 310px;
      width: 230px;

      @media only screen and (max-width: 1365px) {
        max-width: 165px;
      }

      @media only screen and (max-width: 1100px) {
        flex-direction: row;
        justify-content: space-between;
        max-width: 332px;
        width: 338px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        max-width: 230px;

        @media only screen and (max-width: 1365px) {
          max-width: 165px;
        }

        @media only screen and (max-width: 1100px) {
          max-width: 332px;
          width: 338px;
        }
      }

      .select-box {
        @media only screen and (max-width: 1100px) {
          width: 60% !important;
        }
      }

      label {
        font-size: 18px;
        line-height: 18px;
        font-family: "Gilroy-Medium";
        color: #fff;
        margin-bottom: 10px;

        @media only screen and (max-width: 448px) {
          font-size: 12px;
        }
      }

      .formfield_details {
        background: #000;
        border: 2px solid #2d2d2d;
        border-radius: 16px;
        height: 49px;
        color: #ffff;
        padding-left: 10px;
      }
    }
  }

  .formdata_right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    padding: 20px;

    p {
      color: rgba(235, 235, 245, 0.6);
      font-family: "Gilroy-Bold";
      font-size: 16px;
      margin-top: 25px;
      margin-bottom: 0px !important;
    }

    .formDate_box {
      display: flex;
      flex-direction: column;
      // max-width: 310px;
      width: 171px;

      label {
        font-size: 18px;
        line-height: 18px;
        font-family: "Gilroy-Medium";
        color: #fff;
        margin-bottom: 10px;

        @media only screen and (max-width: 448px) {
          font-size: 12px;
        }
      }

      .formfield_details {
        background: #000;
        border: 2px solid #2d2d2d;
        border-radius: 16px;
        height: 49px;
        color: #ffff;
        padding-left: 10px;
      }

      .date_title {
        font-size: 18px;
        line-height: 18px;
        font-family: "Gilroy-Medium";
        color: #fff;
      }

      img {
        float: right;
        margin-right: 15px;
        margin-top: -32px;
        cursor: pointer;
      }

      .calendar-drop {
        position: relative;

        .rdrCalendarWrapper {
          @media only screen and (max-width: 768px) {
            right: -153px;
          }
        }

        .calander-close {
          @media only screen and (max-width: 768px) {
            right: -140px;
          }
        }
      }
    }

    .search_btn {
      max-width: 232px;
      color: #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border-radius: 10px;
      border: 2px solid #ffffff;
      font-family: "Poppins-SemiBold";
      font-size: 13px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding: 10px 22px;
      text-decoration: none;
      margin-left: 15px;
      margin-top: 24px;
      cursor: pointer;

      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }
    }
  }

  .search-admin-form-control {
    max-width: 100%;
    background: #000 !important;
  }
}

// .userStatic_gamedata {
//   display: flex;
//   align-items: center;
//   gap: 20px;
//   padding: 52px 30px 33px;
//   background: #121212;
//   border: 2px solid #2d2d2d;
//   border-radius: 20px;
//   margin-top: 30px;
//   .search-admin-form-control {
//     width: 230px;
//     background: #181818 !important;
//   }

//   .formfield_box {
//     display: flex;
//     flex-direction: column;

//     &:nth-child(2),
//     &:nth-child(3),
//     &:nth-child(4) {
//       max-width: 210px;
//     }

//     &:nth-child(5) {
//       width: 171px;
//     }

//     &.boxOne {
//       width: 230px;
//     }

//     label {
//       font-size: 18px;
//       line-height: 18px;
//       font-family: "Gilroy-Medium";
//       color: #fff;
//       margin-bottom: 10px;
//     }

//     .formfield_details {
//       background: #181818;
//       border: 2px solid #2d2d2d;
//       border-radius: 16px;
//       height: 49px;
//       color: #ffff;
//       img {
//         float: right;
//         margin-right: 15px;
//         margin-top: 15px;
//       }
//     }
//     .calendar-drop {
//       position: relative;
//     }
//   }
//   .formDate_box {
//     display: flex;
//     flex-direction: column;
//     // max-width: 310px;
//     width: 171px;

//     label {
//       font-size: 18px;
//       line-height: 18px;
//       font-family: "Gilroy-Medium";
//       color: #fff;
//       margin-bottom: 10px;
//     }

//     .formfield_details {
//       background: #181818;
//       border: 2px solid #2d2d2d;
//       border-radius: 16px;
//       height: 49px;
//       color: #ffff;
//     }
//   }
//   .search_btn {
//     max-width: 232px;
//     color: #fff;
//     background-color: #e52420;
//     background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
//     border-radius: 10px;
//     border: 2px solid #ffffff;
//     font-family: "Poppins-SemiBold";
//     font-size: 13px;
//     line-height: 17px;
//     text-transform: uppercase;
//     letter-spacing: 0.04em;
//     padding: 10px 22px;
//     text-decoration: none;
//     margin-left: 15px;
//     margin-top: 24px;
//     &::after {
//       content: url("../images/arrow.png");
//       padding-left: 10px;
//     }
//   }
// }

// .new_userdata {
//   display: flex;
//   flex-direction: column;
//   gap: 20px;

//   .new_userbox {
//     label {
//       font-size: 18px;
//       line-height: 18px;
//       font-family: "Gilroy-Medium";
//       color: #fff;
//       margin-right: 10px;
//     }

//     .formfield_details {
//       background: #181818;
//       border: 2px solid #2d2d2d;
//       border-radius: 16px;
//       height: 49px;
//       color: #ffff;
//     }
//   }
// }

.userStatic_graphs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  .graphs_left {
    max-width: 48%;
    flex: 0 0 48% !important;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    background: #121212;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      flex: 0 0 100% !important;
      margin-bottom: 10px;
    }
  }

  .graphs_right {
    max-width: 48%;
    flex: 0 0 48% !important;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    background: #121212;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      flex: 0 0 100% !important;
    }
  }
}

// .gamer_graphs {
//   width: 100%;
//   margin: auto;

//   .gamerGraph-firstrow {
//     max-width: 48%;
//     width: 100%;
//     margin: auto;
//   }

//   .gamerGraph-row {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;

//     .graphs_left {
//       max-width: 48%;
//       flex: 0 0 48%;
//     }

//     .graphs_right {
//       max-width: 48%;
//       flex: 0 0 48%;
//     }
//   }
// }

// .cumilative_graphs {
//   .graph_title {
//     font-size: 25px;
//     line-height: 25px;
//     font-family: "Gilroy-Medium";
//     color: #fff;

//     font-size: 16px;
//     line-height: 26px;
//     font-family: "Gilroy-Bold";
//     color: #fff;
//     padding-left: 12px;
//   }
// }

// .cumilativeGraphs_inner {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;

//   .graphs_left {
//     max-width: 48%;
//     flex: 0 0 48%;
//   }

//   .graphs_right {
//     max-width: 48%;
//     flex: 0 0 48%;
//   }
// }
// }

/*************** userStatic_formdata End *****************/

/*************** Registration Graph start *****************/
.GB_registrationGraph {
  padding: 15px 0;

  .graph_title {
    font-size: 25px;
    line-height: 25px;
    font-family: "Gilroy-Medium";
    color: #fff;
    text-align: center;
  }

  .registration_graph {
    .apexcharts-canvas {
      z-index: -10 !important;

      .apexcharts-svg {
        background-color: #ffff !important;
      }
    }

    .apexcharts-toolbar {
      display: none;
    }
  }
}

/*************** Registration Graph End *****************/

/*************** GB_tournaments Start *****************/
.GB_tournaments {
  .tournament_inner {
    .title-sec {
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 25px 0 0 0;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        gap: 10px;
      }

      .title-box {
        display: flex;
        gap: 30px;
        margin: 10px;

        @media only screen and (max-width: 767px) {
          gap: 10px;
        }
      }

      h2 {
        font-family: "Gilroy-Bold";
        font-size: 40px;
        line-height: 90px;
        color: #ffffff;

        @media only screen and(max-width: 1600px) {
          font-size: 28px;
          line-height: 35px;
        }

        @media only screen and(max-width: 990px) {
          font-size: 25px;
          line-height: 35px;
        }
      }

      .prize_pot {
        color: #b3b3b3;
      }

      .game_filter {
        select {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }

      .participate_filter {
        width: 100%;
        max-width: 299px;

        .search-admin-form-control {
          background: #181818 !important;
          border: 2px solid #2d2d2d;
          border-radius: 15px;
          height: 42px;
          width: 100%;
          max-width: 299px;
          font-family: "Gilroy-Medium";
          font-size: 17px;
          line-height: 19px;
          color: #fff !important;
        }

        input {
          background: #181818 !important;
          border: 2px solid #2d2d2d;
          border-radius: 15px;
          height: 42px;
          width: 100%;
          max-width: 299px;
          font-family: "Gilroy-Medium";
          font-size: 17px;
          line-height: 19px;
          color: #fff !important;
          padding: 0 10px;
        }
      }
    }

    .tournament-table {
      // background: #181818;
      // border: 2px solid #2d2d2d;
      // box-sizing: border-box;
      // border-radius: 20px;
      // padding: 20px 33px 10px 33px;
      .Leaderboard_Bg {
        background: #181818;
        border: 2px solid #2d2d2d;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 20px 20px 10px 20px;

        .top-section {
          justify-content: space-between;

          @media only screen and(max-width: 767px) {
            flex-direction: column;
          }
        }

        .img-section {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media only screen and(max-width: 767px) {
            flex-direction: column;
          }

          .GB_countdown {
            padding-left: 30px;
          }

          .title {
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 19px;
            color: rgba(235, 235, 245, 0.6);

            @media only screen and (max-width: 767px) {
              padding-bottom: 10px;
            }

            .reward_dollar {
              font-family: "Gilroy-Bold";
              color: #fff;
              margin-bottom: 0px;
              height: 32px;
              width: 32px;
              background: #db9a01;
              border-radius: 10px;
              margin: 0 10px;
              line-height: 100%;
              text-align: center;
              align-items: center;
              justify-content: center;
              display: flex;
            }

            .equals {
              margin-bottom: 0;
              padding-right: 5px;
            }
          }
        }

        .SelectBoxGrp {
          justify-content: flex-end;

          @media only screen and(max-width: 767px) {
            justify-content: center;
          }

          .fortnite {
            margin-right: 15px;

            @media only screen and (max-width: 767px) {
              margin-right: 0px;
            }
          }

          .Leaderboard_drop {
            background-color: #050505;
            border-radius: 10px;
            border-color: #050505;
            color: #fff;
            width: 189px;
            padding: 5px 15px;
            margin-bottom: 25px;
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;

            option {
              background-color: #050505;
              border-radius: 10px;
              border-color: #050505;
              color: #fff;
              width: 189px;
              padding: 5px 15px;
              margin-bottom: 15px;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
              color: #ffffff;
            }
          }

          .sortType {
            .sort_drop {
              background-color: #050505;
              border-radius: 10px;
              border-color: #050505;
              color: #fff;
              width: 189px;
              padding: 5px 15px;
              margin-bottom: 25px;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
              color: #ffffff;

              option {
                background-color: #050505;
                border-radius: 10px;
                border-color: #050505;
                color: #fff;
                width: 189px;
                padding: 5px 15px;
                margin-bottom: 15px;
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
              }
            }
          }
        }

        .full_section {
          .Leaderboard_details {
            // background: #1f1f1f;
            // border-radius: 15px;
            // padding: 18px 18px;
            width: 100%;

            .showing {
              font-family: "Gilroy-Bold";
              font-size: 18px;
              line-height: 100%;
              color: #ffffff;
              margin-bottom: 20px;
            }

            // margin-bottom: 7px;
            .table > :not(:first-child) {
              border-top: 0px solid currentColor;
            }

            table {
              // @media only screen and (max-width: 991px){
              //   display: flex;
              // }
              thead {
                background: #161616;

                @media only screen and (max-width: 767px) {
                  display: none;
                }

                tr {
                  background: #161616;
                  border-radius: 10px 10px 0px 0px;

                  // @media only screen and (max-width: 991px){
                  //   display: grid;
                  // }
                  th {
                    font-family: "Gilroy-Bold";
                    font-size: 16px;
                    line-height: 100%;
                    color: rgba(235, 235, 245, 0.6);
                    padding: 10px 10px 10px 3px;

                    @media only screen and (max-width: 1450px) {
                      font-size: 12px;
                    }

                    &:first-child {
                      padding: 10px 10px 10px 20px;
                      border-radius: 10px 0px 0px 0px;
                    }

                    &:last-child {
                      padding: 10px 10px 10px 20px;
                      border-top-right-radius: 10px;
                    }

                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                      text-align: center;
                    }

                    &.chess_board_th {
                      text-align: center;
                    }
                  }
                }
              }

              tbody {
                tr {
                  // @media only screen and (max-width: 991px){
                  //   display: grid;
                  // }
                  &:nth-child(even) {
                    td {
                      border-color: #000;
                      background: #121212;
                    }
                  }

                  &:nth-child(odd) {
                    td {
                      border-color: #000;
                      background: #161616;
                    }
                  }

                  td {
                    font-family: "Gilroy-Bold";
                    font-size: 16px;
                    line-height: 100%;
                    color: #ffffff;
                    vertical-align: middle;
                    padding: 10px 2px;
                    box-shadow: none;
                    text-align: left;

                    @media only screen and (max-width: 991px) {
                      font-size: 14px;
                    }

                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6) {
                      text-align: center;
                    }

                    &:nth-child(2) {
                      @media only screen and (min-width: 768px) {
                        width: 13%;
                      }
                    }

                    .redBox {
                      height: 32px;
                      width: 32px;
                      background: #e52420;
                      border-radius: 10px;
                      font-family: "Gilroy-Bold";
                      font-size: 18px;
                      line-height: 100%;
                      text-align: center;
                      color: #ffffff;
                      align-items: center;
                      justify-content: center;
                      display: flex;
                    }

                    .GbplayerCol {
                      flex-direction: row;
                      display: flex;
                      align-items: center !important;

                      @media only screen and(max-width: 767px) {
                        display: flex !important;
                      }

                      @media only screen and (min-width: 768px) and (max-width: 991px) {
                        flex-direction: column;
                        display: flex;
                        line-height: 21px;
                      }

                      img {
                        border: 2px solid #ffffff;
                        box-sizing: border-box;
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        border-radius: 100%;
                        margin-right: 20px;
                      }
                    }

                    &.match_history {
                      text-align: center;
                    }

                    &.rank_image {
                      padding-left: 15px;
                    }

                    &.player_data {
                      @media only screen and (min-width: 768px) {
                        width: 23%;
                      }
                    }

                    &.streamer_data {
                      // display: flex;
                      // flex-direction: column;
                      // align-items: center;
                      // justify-content: center;
                      text-align: center;

                      @media only screen and(max-width: 767px) {
                        // text-align: left;
                        // display: block;
                      }
                    }

                    &.action-block {
                      vertical-align: middle;

                      .plane-btn {
                        &:first-child {
                          margin-right: 5px;
                        }
                      }
                    }

                    .watch_btn {
                      max-width: 130px;
                      color: #fff;
                      background-color: #e52420;
                      background-image: linear-gradient(
                        to right,
                        #6d1513,
                        #e52420,
                        #6d1513
                      );
                      border-radius: 10px;
                      border: 2px solid #ffffff;
                      font-family: "Poppins-SemiBold";
                      font-size: 13px;
                      line-height: 17px;
                      text-transform: uppercase;
                      letter-spacing: 0.04em;
                      padding: 10px;
                      text-decoration: none;
                      cursor: pointer;

                      &::after {
                        content: url("../images/arrow.png");
                        padding-left: 10px;
                      }

                      &:hover {
                        opacity: 0.5;
                      }
                    }

                    &.GB_device_td {
                      .emailAdmin {
                        font-size: 16px;
                        line-height: 16px;
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .pagination-full-section {
          margin: 40px 0px;

          .paginate {
            margin: 0px 13px;
            background: rgba(0, 0, 0, 0.5);
            border: 1px solid #4f4f4f;
            box-sizing: border-box;
            border-radius: 10px;
            padding: 4px 4px;
            justify-content: center;
            align-items: center;

            ul {
              margin-bottom: 0px;
              align-items: center;
              justify-content: center;
              display: flex;
              position: relative;

              li {
                list-style-type: none;
                margin-right: 10px;

                a {
                  font-family: "Gilroy-Bold";
                  font-size: 18px;
                  line-height: 100%;
                  text-align: center;
                  color: #ffffff;
                  background-color: transparent;
                  border: none;

                  &:focus {
                    box-shadow: none;
                  }
                }

                &.previous {
                  position: absolute;
                  left: -61px;

                  a {
                    background: url(../images/rightarrow.png) 0px 0px no-repeat;
                    height: 50px;
                    width: 50px;
                  }
                }

                &.next {
                  position: absolute;
                  right: -61px;

                  a {
                    background: url(../images/leftarrow.png) 0px 0px no-repeat;
                    height: 50px;
                    width: 50px;
                  }
                }

                &.active {
                  background: #e52420;
                  border-radius: 8px;
                  height: 42px;
                  width: 42px;
                  vertical-align: middle;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                }

                &:last-child {
                  margin-right: 0px;
                }

                &:first-child {
                  margin-right: 0px;
                }

                &:nth-last-child(2) {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .program_table {
      .Leaderboard_Bg {
        background: #181818;
        border: 2px solid #2d2d2d;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 20px 33px 10px 33px;

        .Leaderboard_details {
          width: 100%;

          thead {
            background-color: #161616 !important;

            th {
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 100%;
              color: rgba(235, 235, 245, 0.6);
              padding: 10px 10px 10px 3px;
              background-color: #292929 !important;

              &:first-child {
                padding: 10px 10px 10px 20px;
                border-radius: 10px 0px 0px 0px;
              }

              &:last-child {
                padding: 10px 10px 10px 20px;
                border-top-right-radius: 10px;
              }
            }
          }

          tr {
            vertical-align: middle;

            .GB_device_td {
              text-align: left;
              font-family: "Gilroy-Bold";
              font-size: 18px;
              line-height: 100%;
              color: #ffffff;
            }
          }

          tbody {
            tr {
              background-color: #161616;

              td {
                background-color: #161616;
              }
            }
          }

          .edit_btn {
            max-width: 86px;
            color: #fff;
            background-color: #e52420;
            background-image: linear-gradient(
              to right,
              #6d1513,
              #e52420,
              #6d1513
            );
            border-radius: 10px;
            border: 2px solid #ffffff;
            font-family: "Poppins-SemiBold";
            font-size: 13px;
            line-height: 17px;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            padding: 10px;
            text-decoration: none;
            display: flex;
            align-items: center;
            margin: auto;
            cursor: pointer;

            &::after {
              content: url("../images/arrow.png");
              padding-left: 10px;
            }

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }

    .GB_createTournament {
      display: flex;
      // align-items: center;
      flex-wrap: wrap;
      gap: 50px;

      @media only screen and (max-width: 990px) {
        flex-wrap: wrap;
        gap: 30px;
      }

      .createBox {
        max-width: 30%;
        flex: 0 0 30%;

        @media only screen and (max-width: 990px) {
          max-width: 50%;
          flex: 0 0 50%;
        }

        @media only screen and (max-width: 767px) {
          max-width: 100%;
          flex: 0 0 100%;
        }

        .tournament_btn {
          max-width: 340px;
          height: 140px;
          background: #e52420;
          border: 2px solid #ffffff;
          border-radius: 10px;
          background-image: linear-gradient(
            to right,
            #6d1513,
            #e52420,
            #6d1513
          );
          border-radius: 10px;
          padding: 25px;
          cursor: pointer;
          display: flex;
          align-items: center;
          font-family: "Gilroy-Bold";
          font-size: 25px;
          line-height: 35px;
          text-align: center;
          color: #ffffff;
          box-shadow: 1px 4px 6px #e52420;

          &::after {
            content: url("../images/admin/tournament_arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }

          @media only screen and (max-width: 1200px) {
            padding: 10px;
            font-size: 20px;
          }

          @media only screen and (max-width: 990px) {
            height: 100px;
            margin: auto;
          }
        }

        .tournament-fields {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-bottom: 10px;
              justify-content: space-between;
          label {
            font-family: "Gilroy-Medium";
            font-size: 17px;
            line-height: 19px;
            color: #fff;
            width: 173px;
          }

          .search-admin-form-control {
            background: #181818 !important;
            border: 2px solid #2d2d2d;
            border-radius: 15px;
            height: 42px;
            width: 100%;
            max-width: 273px;
            font-family: "Gilroy-Medium";
            font-size: 17px;
            line-height: 19px;
            color: #fff !important;
          }

          input {
            background: #181818 !important;
            border: 2px solid #2d2d2d;
            border-radius: 15px;
            height: 39px;
            width: 100%;
            max-width: 273px;
            font-family: "Gilroy-Medium";
            font-size: 17px;
            line-height: 19px;
            color: #fff !important;
            padding: 0 10px;
          }
        }

        .date-field {
          label {
            width: 200px;
          }

          .calendar-drop {
            position: relative;

            .rdrCalendarWrapper {
              .rdrDateInput {
                background-color: transparent;
              }
            }
          }
        }

        &:first-child {
          max-width: 20%;
          flex: 0 0 20%;

          @media only screen and (max-width: 990px) {
            max-width: 45%;
            flex: 0 0 45%;
          }

          @media only screen and (max-width: 767px) {
            max-width: 100%;
            flex: 0 0 100%;
          }
        }
      }
    }
  }
}

/*************** GB_tournaments End *****************/

/*************** Admin Bet History Start ****************/
._GB_Bethistory_Inner {
  margin-bottom: 60px;
  margin-top: 30px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 0px;
  }

  .main-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @media only screen and (max-width: 1280px) {
      flex-wrap: wrap;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    h2 {
      font-family: "Gilroy-Bold";
      font-size: 40px;
      line-height: 47px;
      color: #ffffff;

      @media only screen and (max-width: 1360px) {
        font-size: 25px;
      }

      @media only screen and (max-width: 1280px) {
        width: 100%;
      }

      @media only screen and (max-width: 1024px) {
        font-size: 35px;
      }

      @media only screen and (max-width: 767px) {
        text-align: center;
      }

      @media only screen and (max-width: 420px) {
        font-size: 20px;
      }
    }

    .filter-drop {
      background-color: black;
      color: white;
      border: 2px solid #2d2d2d;
      border-radius: 10px;
      max-width: 200px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      line-height: 20px;
      font-family: "Gilroy-Bold";
      padding-left: 20px;
      margin-left: 10px;

      @media only screen and (max-width: 990px) {
        margin-bottom: 10px;
      }
    }

    .clear-filter {
      .gb-red-btn {
        padding: 17px 10px !important;
      }
    }
  }

  .date_filter {
    display: flex;
    justify-content: flex-end;
    .formdata_right {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      padding: 20px;
      margin-bottom: 32px;
      p {
        color: rgba(235, 235, 245, 0.6);
        font-family: "Gilroy-Bold";
        font-size: 16px;
        margin-top: 25px;
        margin-bottom: 0px !important;
      }
      .formDate_box {
        display: flex;
        flex-direction: column;
        // max-width: 310px;
        width: 171px;

        label {
          font-size: 18px;
          line-height: 18px;
          font-family: "Gilroy-Medium";
          color: #fff;
          margin-bottom: 10px;

          @media only screen and (max-width: 448px) {
            font-size: 12px;
          }
        }

        .formfield_details {
          background: #000;
          border: 2px solid #2d2d2d;
          border-radius: 16px;
          height: 49px;
          color: #ffff;
          padding-left: 10px;
        }

        .date_title {
          font-size: 18px;
          line-height: 18px;
          font-family: "Gilroy-Medium";
          color: #fff;
        }

        img {
          float: right;
          margin-right: 15px;
          margin-top: -27px;
          cursor: pointer;
        }

        .calendar-drop {
          position: relative;

          .rdrCalendarWrapper {
            @media only screen and (max-width: 768px) {
              right: -153px;
            }
          }

          .calander-close {
            @media only screen and (max-width: 768px) {
              right: -140px;
            }
          }
        }
      }
      .search_btn {
        max-width: 232px;
        color: #fff;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 13px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        padding: 10px 22px;
        text-decoration: none;
        margin-left: 15px;
        // margin-top: 24px;
        cursor: pointer;

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }
      }
    }
  }

  .row {
    margin-bottom: 20px;

    .first-Bg {
      padding: 10px 0px;
      height: 137px;

      @media only screen and (max-width: 1024px) {
        margin-bottom: 30px;
      }

      #dropdown {
        font-family: "Gilroy-Bold";
        font-size: 20px;
        text-align: center;
        background-color: transparent;
        background: none;
        border: none;

        &::after {
          content: url("../images/dd_down.png");
          border-top: none;
        }
      }

      .card-text {
        font-family: "Gilroy-Bold";
        font-size: 18px;
        color: yellow;
        text-align: center;
      }
    }

    .Bethistory_Bg {
      background: #181818;
      border: 2px solid #2d2d2d;
      box-sizing: border-box;
      border-radius: 10px;
      height: 137px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 1024px) {
        margin-bottom: 30px;
      }

      @media only screen and (min-width: 1367px) {
        padding: 20px 23px 10px 23px;
      }

      @media only screen and (min-width: 769px) and (max-width: 1023px) {
        height: 170px;
      }

      .bethistory-card {
        border: none;

        .challenge_opt {
          text-align: center;
        }

        .challenge-drop {
          background-color: black;
          color: white;
          border: 2px solid #2d2d2d;
          border-radius: 10px;
          max-width: 230px;
          width: 100%;
          height: 49px;
          font-size: 16px;
          line-height: 20px;
          font-family: "Gilroy-Bold";
          padding-left: 20px;
        }

        .card-title {
          font-family: "Gilroy-SemiBold";
          text-align: center;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
        }

        .card-text {
          font-family: "Gilroy-SemiBold";
          color: #ffc01f;
          text-align: center;
          font-weight: 600;
          font-size: 30px;
          line-height: 36px;

          &.green-text {
            color: #52dd21;
          }

          &.red-text {
            color: #c80000;
          }
        }
      }
    }

    .overall-row {
      border-radius: 20px;

      .overall-profit-1 {
        border: none;
        box-sizing: border-box;
        text-align: center;

        .profit-card {
          border: none;
          color: #000;
          padding-top: 10px;

          .profit-card-title {
            font-family: "Gilroy-SemiBold";
            font-size: 24px;
            line-height: 29px;
          }
        }
      }

      .overall-profit-2 {
        background: #101010;
        border: 2px solid #52dd21;
        border-radius: 20px;
        text-align: center;

        .profit-card-2 {
          border: none;

          .profit-card-title {
            font-family: "Gilroy-SemiBold";
            font-size: 37px;
            line-height: 44px;
          }
        }
      }

      &.red-bg {
        background-color: #c80000;
        border: 2px solid #c80000;

        .overall-profit-2 {
          border: 2px solid #c80000;
        }
      }

      &.green-bg {
        background-color: #52dd21;
        border: 2px solid #52dd21;
      }
    }

    .numbersOfWin_Bg {
      border: 2px solid #2d2d2d;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 20px 23px 10px 23px;
      height: 150px;
      background: linear-gradient(
        90deg,
        rgba(86, 109, 19, 0.5) 0%,
        rgba(117, 168, 10, 0.09) 48.67%,
        rgba(86, 109, 19, 0.5) 100.07%
      );

      @media only screen and (max-width: 1024px) {
        margin-bottom: 30px;
      }

      .bethistory-card {
        border: none;

        .card-title {
          font-family: "Gilroy-Bold";
          font-size: 20px;
          text-align: center;
        }

        .card-text {
          font-family: "Gilroy-Bold";
          font-size: 20px;
          color: yellow;
          text-align: center;
        }
      }
    }

    .bet-col {
      @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 95%;
        margin: 0 auto;

        h5 {
          font-size: 24px;
        }
      }
    }
  }
}

._GB_Betonyourself_Inner {
  .title {
    font-family: "Gilroy-Bold";
    font-size: 40px !important;
    line-height: 47px !important;
    color: #ffffff;
    margin-bottom: 32px;

    @media only screen and (max-width: 1024px) {
      font-size: 25px;
    }
  }

  .adminSearchType {
    display: flex;
    gap: 10px;
  }

  .sort-asc {
    max-width: 230px;
    width: 100%;
    height: 49px;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  .search-name {
    max-width: 353px;
    width: 100%;
    height: 49px;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }
  }

  .search-btn {
    max-width: 143px;
    width: 100%;
    height: 49px;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      margin-top: 10px;
    }
  }

  .complete-btn {
    max-width: 117px;
    width: 100%;
    height: 49px;

    @media only screen and (max-width: 767px) {
      max-width: 50%;
    }
  }

  .gb-red-btn-style {
    max-width: 456px;
    background-color: #e52420;
    background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
    border: 2px solid #fff !important;
    border-radius: 10px;
    padding: 17px 8px;
    text-decoration: none;
    color: #fff;
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    line-height: 17px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    display: inline-block;

    @media only screen and (max-width: 1024px) {
      padding: 12px 15px;
    }

    &::after {
      content: url("../images/arrow.png");
      padding-left: 10px;
    }

    &:hover {
      opacity: 0.5;
      color: #fff;
    }

    &.access-btn {
      padding: 10px 20px;
    }
  }

  .btn-style {
    max-width: 456px;
    // background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
    border-radius: 10px;
    padding: 17px 8px;
    text-decoration: none;
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    line-height: 17px;
    // text-transform: uppercase;
    letter-spacing: 0.04em;
    display: inline-block;

    @media only screen and (max-width: 1024px) {
      padding: 12px 15px;
    }

    &:hover {
      opacity: 0.5;
      color: #fff;
    }

    &.access-btn {
      padding: 10px 20px;
    }
  }

  .bg-btn1 {
    background-color: #640000;
    border: 2px solid #c80000 !important;
    color: #fff;
    font-size: 16px;
    line-height: 100%;
  }

  .bg-btn2 {
    background-color: #050505;
    border: 2px solid #2d2d2d !important;
    color: rgba(235, 235, 245, 0.6);
    font-size: 16px;
    line-height: 100%;
  }
}

.bethistory-graph1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;

  .graph-left {
    max-width: 48%;
    flex: 0 0 48% !important;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    background: #121212;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      flex: 0 0 100% !important;
      margin-bottom: 10px;
    }
  }

  .graph-right {
    max-width: 48%;
    flex: 0 0 48% !important;
    border: 2px solid #2d2d2d;
    border-radius: 20px;
    background: #121212;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      flex: 0 0 100% !important;
    }
  }
}

/*************** Admin Bet History End *****************/

/***************Admin Bet History Graph Start ****************/

.GB_playerwinGraph {
  padding: 15px 0;

  .graph_title {
    font-size: 30px;
    line-height: 35px;
    font-family: "Gilroy-Bold";
    color: #fff;
    padding-left: 36px;

    @media only screen and(max-width: 767px) {
      font-size: 16px;
      line-height: 26px;
      font-family: "Gilroy-Bold";
      color: #fff;
      padding-left: 12px;
    }
  }

  .noGraph {
    font-family: "Gilroy-Medium";
    color: #fff;
    padding-left: 25px;
    font-size: 20px;
  }

  .playerwin_graph {
    .apexcharts-toolbar {
      display: none;
    }
  }

  .apexcharts-tooltip {
    background: #282828;
    color: #ffffff;

    .apexcharts-tooltip-text {
      .apexcharts-tooltip-y-group {
        display: flex;
        flex-direction: column;

        .apexcharts-tooltip-text-y-label {
          color: #99999f;
        }

        .apexcharts-tooltip-text-y-value {
          font-size: 34px;
        }
      }
    }
  }
}

/***************Admin Bet History Graph End ****************/

/*************** Betting logs starts *****************/
.admin_logs {
  @media only screen and (max-width: 1899px) {
    padding: 20px 5px 10px 5px !important;
  }

  @media only screen and (max-width: 1250px) {
    text-align: center;
  }

  table {
    thead {
      tr {
        td {
          @media only screen and (max-width: 1899px) {
            font-size: 10px !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          @media only screen and (max-width: 1899px) {
            font-size: 10px !important;
          }

          text-align: center !important;

          &:nth-child(2) {
            width: auto !important;
          }

          .gb-red-btn {
            @media only screen and (min-width: 768px) and (max-width: 1600px) {
              font-size: 10px !important;
            }
          }
        }
      }
    }
  }

  .bet_details {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: "Gilroy-Bold";
    background-color: #1e1e1e;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .detail_box {
      padding: 5px;
      color: rgba(235, 235, 245, 0.6);
      max-width: 11%;
      flex: 0 0 11%;

      &:first-child,
      &:nth-child(5) {
        max-width: 5%;
        flex: 0 0 5%;
      }

      &:nth-child(3),
      &:nth-child(4) {
        max-width: 9%;
        flex: 0 0 9%;
      }

      &:nth-child(6) {
        max-width: 7%;
        flex: 0 0 7%;
      }

      &:nth-child(7) {
        max-width: 10%;
        flex: 0 0 10%;
      }

      &:nth-child(9) {
        max-width: 14%;
        flex: 0 0 14%;
      }
    }
  }

  .bet-accordion {
    // --bs-accordion-bg: #161616;
    border: none;
    box-shadow: none;
    outline: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    .bet_details_row {
      &:nth-child(even) {
        .accordion-button,
        .accordion-body {
          background-color: #212121;
        }
      }

      &:nth-child(odd) {
        .accordion-button,
        .accordion-body {
          background-color: #161616;
        }
      }
    }

    .accordion-item {
      // background-color: #161616;
      border-radius: 0;
      border: none;

      .accordion-header {
        line-height: 0px !important;
        border: none;

        .bet-row-data {
          font-size: 15px;
          line-height: 15px;
          max-width: 11%;
          flex: 0 0 11%;

          &:first-child {
            max-width: 5%;
            flex: 0 0 5%;

            img {
              margin-left: 10px;
            }
          }

          &:nth-child(5) {
            max-width: 5%;
            flex: 0 0 5%;
          }

          &:nth-child(3),
          &:nth-child(4) {
            max-width: 9%;
            flex: 0 0 9%;
          }

          &:nth-child(6) {
            max-width: 7%;
            flex: 0 0 7%;
          }

          &:nth-child(7) {
            max-width: 10%;
            flex: 0 0 10%;
          }

          &:nth-child(8),
          &:nth-child(9) {
            text-align: center;
          }
        }

        .edit_btn {
          max-width: 86px;
          width: 100%;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(
            to right,
            #6d1513,
            #e52420,
            #6d1513
          );
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 13px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 10px;
          text-decoration: none;
          margin-left: 30px;

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }

        .delete_btn {
          button {
            &:hover {
              background-color: #161616;
            }
          }
        }

        .accordion-button {
          // background-color: #161616;
          padding: 5px;
          color: #fff;
          gap: 14px;
          box-shadow: none;
          outline: none;

          &.focus {
            border: none;
            box-shadow: none;
            outline: none;
          }

          &::after {
            background-image: url("../images/admin/accordin_drop.png");
            position: absolute;
            left: 55%;
          }
        }
      }
    }

    .accordion-item:last-of-type .accordion-button.collapsed {
      border-radius: 0 !important;
    }

    .accordion-item:first-of-type .accordion-button.collapsed {
      border-radius: 0 !important;
    }

    .accordion-item:last-of-type .accordion-button {
      border-radius: 0 !important;
    }

    .accordion-item:first-of-type .accordion-button {
      border-radius: 0 !important;
    }
  }

  .betting_adminlogs {
    padding: 20px 20px 0px;

    .title {
      font-family: "Gilroy-Bold";
      font-size: 18px !important;
      line-height: 18px !important;
      color: #fff;
      margin-bottom: 30px;
      text-align: left;
    }

    .betting_personaldetails {
      margin: 15px 0;
      display: flex;
      gap: 25px;
      flex-wrap: wrap;

      @media only screen and (max-width: 720px) {
        gap: 0px;
      }

      .logdetails_block {
        // @media only screen and (max-width: 767px) {
        //   flex: 0 0 48%;
        //   max-width: 48%;
        // }

        .betlog_data {
          display: flex;
          gap: 20px;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 16px;

          @media only screen and (max-width: 720px) {
            font-size: 14px;
          }

          p {
            width: 185px;
            color: rgba(235, 235, 245, 0.6);
            text-align: left;

            @media only screen and (max-width: 720px) {
              width: 120px;
            }
          }

          span {
            color: #fff;
          }

          .red-text {
            color: #c80000;
          }

          .green-text {
            color: #52dd21;
          }
        }
      }
    }
  }
}

// }
.modal-betting-logs {
  .modal-dialog {
    max-width: 75%;

    @media only screen and (min-width: 1300px) {
      max-width: 1100px;
    }

    @media only screen and (min-width: 1900px) {
      max-width: 1400px;
    }

    .modal-content {
      background: #181818;

      .btn-close {
        background-image: url("../images/close_button.png");
      }
    }
  }
}

.betting_adminpersonaldetails {
  display: flex;
  gap: 100px;
  margin: auto;
  border-bottom: 2px solid #6a6868;
  padding: 25px 10px;

  @media only screen and (min-width: 1300px) {
    padding: 5px 10px;
  }

  .logdetails_block {
    p {
      font-family: "Gilroy-medium";
      font-size: 16px;
      line-height: 100%;
      color: #ffffff;

      @media only screen and (max-width: 1899px) {
        font-size: 12px;
      }

      span {
        font-family: "Gilroy-semibold";
        color: gold;
      }
    }
  }

  &:last-child {
    border-bottom: 0px solid #6a6868;
  }
}

.adminSearchType {
  align-items: center;
  gap: 10px;

  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  .adminSearchfield {
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .admin_searchbtn {
    @media only screen and (max-width: 767px) {
      width: 48%;
    }
  }

  @media only screen and (max-width: 1250px) {
    flex-direction: column;
    gap: 10px;
  }

  .col-2 {
    @media only screen and (max-width: 1250px) {
      width: 100%;
    }
  }

  .col-3 {
    @media only screen and (max-width: 1250px) {
      width: 100%;
    }
  }

  .sort-order {
    @media only screen and (min-width: 1280px) {
      width: 100%;
      max-width: 15%;
    }

    @media only screen and (min-width: 1360px) {
      width: 100%;
      max-width: 10%;
    }
  }

  .sort-dispute {
    @media only screen and (min-width: 1024px) {
      width: 100%;
      max-width: 20%;
    }

    @media only screen and (min-width: 1360px) {
      width: 100%;
      max-width: 12%;
    }
  }

  .sort-game {
    @media only screen and (min-width: 1280px) {
      width: 100%;
      max-width: 18%;
    }

    @media only screen and (min-width: 1360px) {
      width: 100%;
      max-width: 13%;
    }
  }

  .gb-red-btn {
    @media only screen and (min-width: 1024px) {
      width: 100%;
      max-width: 182px;
    }
  }

  .search {
    @media only screen and (min-width: 1080px) {
      width: 100%;
      max-width: 16%;
    }
  }
}

.right_but {
  display: flex;
  gap: 10px;
  justify-content: center;

  @media only screen and (min-width: 991px) {
    justify-content: flex-end;
  }

  @media only screen and (min-width: 1360px) {
    justify-content: right;
  }

  @media only screen and (max-width: 990px) {
    margin-bottom: 10px;
    width: 100%;
  }

  @media only screen and (max-width: 420px) {
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .gb-simplered-btn {
    max-width: 130px;
    background-color: #050505;
    border: 2px solid #2d2d2d;
    border-radius: 10px;
    padding: 17px 8px;
    text-decoration: none;
    color: #fff;
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    line-height: 17px;
    // text-transform: uppercase;
    letter-spacing: 0.04em;
    display: inline-block;
    height: 56px;

    &:hover {
      background-color: #640000;
      border: 2px solid #c80000;
    }

    &.active {
      background-color: #640000;
      border: 2px solid #c80000;
    }

    @media only screen and (max-width: 1600px) {
      font-size: 13px;
    }

    @media only screen and (max-width: 1280px) and(min-width:990px) {
      max-width: 245px;
    }
  }
}

.clear-filter {
  margin: 0px 10px;
}

.adminfull_top .change_game {
  display: none !important;
}

/*************** Betting logs End *****************/

/*************** Fee list End *****************/
.adminfull_top {
  .fee_list {
    td {
      text-align: left !important;
    }

    .gb-fee-btn {
      background-color: #050505;
      border: 2px solid #2d2d2d;
      border-radius: 10px;
      padding: 17px 41px;
      text-decoration: none;
      color: #ffc01f;
      font-family: "Poppins-SemiBold";
      font-size: 18px;
      line-height: 7px;
      letter-spacing: 0.04em;
      display: inline-block;
      height: 44px;

      @media only screen and (max-width: 991px) {
        width: 100%;
        padding: 0;
      }
    }

    h4 {
      font-family: "Gilroy-Bold";
      font-size: 30px;
      line-height: 36px;
      color: #ffffff;
    }

    .Leaderboard_Bg {
      margin-bottom: 30px;
    }

    .date_ranges_fee {
      display: flex;
      align-items: center;
      color: #fff;

      .fee_date {
        // max-width: 150px;
        &::after {
          content: url("../images/betting/date_range.png");
          padding-left: 10px;
        }
      }

      img {
        margin-left: 10px;
      }

      span {
        padding: 10px;
      }
    }

    .fee_btn {
      max-width: 200px;
    }

    .fee_left {
      padding-left: 20px !important;
    }

    .payment_top {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      display: flex;
      align-items: center;
      max-width: 928px;
      padding: 28px 15px;
      margin-bottom: 20px;
      margin-left: 0;
      margin-right: 0;

      h3 {
        font-family: "Gilroy-Bold";
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
      }

      @media only screen and (max-width: 991px) {
        gap: 10px;
        justify-content: center;
        text-align: center;
        flex-direction: column;
      }

      .col-5 {
        @media only screen and (max-width: 991px) {
          width: 100%;
        }
      }

      .col-3 {
        @media only screen and (max-width: 991px) {
          width: 70%;
        }
      }
    }

    .GB_device_td {
      justify-content: left !important;
    }
  }
}

/*************** BetYourself start ****************/
.GB_betYourself_details {
  .title {
    font-family: "Gilroy-Bold";
    font-size: 40px;
    line-height: 90px;
    color: #ffffff;

    @media only screen and (max-width: 1024px) {
      font-size: 25px;
      line-height: 40px;
    }
  }

  .betYourself_inner {
    .betYourself_data {
      background: #121212;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      padding: 20px 30px;

      @media only screen and (max-width: 767px) {
        padding: 20px;
      }

      .bet_details_row {
        .title {
          font-family: "Gilroy-Bold";
          font-size: 18px;
          line-height: 18px;
          color: #ffffff;
        }

        .betting_personaldetails {
          display: flex;
          gap: 50px;
          margin: 30px 0 0;

          @media only screen and (max-width: 1365px) {
            gap: 20px;
          }

          @media only screen and (max-width: 991px) {
            flex-direction: column;
            grid-area: 0px;
          }

          .betlog_data {
            display: flex;
            gap: 20px;

            p {
              width: 185px;
              color: rgba(235, 235, 245, 0.6);
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 16px;

              @media only screen and (max-width: 1300px) {
                font-size: 13px;
                line-height: 15px;
                max-width: 130px;
                width: 100%;
              }
            }

            span {
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 16px;

              @media only screen and (max-width: 1300px) {
                font-size: 13px;
                line-height: 15px;
              }
            }

            .red-text {
              color: #c80000;
            }

            .green-text {
              color: #52dd21;
            }
          }
        }
      }

      #betdetails_table {
        thead {
          @media only screen and (max-width: 767px) {
            display: none;
          }

          tr {
            .GB_device_th {
              background: #1e1e1e;

              &:first-child {
                border-top-left-radius: 10px;
              }

              &:last-child {
                border-top-right-radius: 10px;
              }
            }
          }
        }

        tbody {
          tr {
            vertical-align: middle;

            &:nth-child(even) {
              border-color: #000;
              background-color: #212121 !important;
            }

            &:nth-child(odd) {
              border-color: #000;
              background-color: #161616 !important;
            }

            .GB_device_td {
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 16px;
              color: #fff;
              text-align: left;

              .edit_btn {
                max-width: 86px;
                width: 100%;
                color: #fff;
                background-color: #e52420;
                background-image: linear-gradient(
                  to right,
                  #6d1513,
                  #e52420,
                  #6d1513
                );
                border-radius: 10px;
                border: 2px solid #ffffff;
                font-family: "Poppins-SemiBold";
                font-size: 13px;
                line-height: 17px;
                text-transform: uppercase;
                letter-spacing: 0.04em;
                padding: 10px;
                text-decoration: none;
                margin-left: 30px;

                &::after {
                  content: url("../images/arrow.png");
                  padding-left: 10px;
                }

                &:hover {
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }
    }

    .dispute_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0px;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        padding: 25px 0px 0px;
      }

      .dispute-col {
        display: flex;
        align-items: center;
        gap: 20px;

        @media only screen and (max-width: 767px) {
          width: 100%;
          justify-content: center;
          margin-bottom: 10px;
          gap: 10px;
        }

        &:nth-child(2) {
          flex: 0 0 60%;
          max-width: 60%;
          justify-content: flex-end;

          @media only screen and (max-width: 767px) {
            max-width: 100%;
            justify-content: center;
          }
        }

        .message-text {
          max-width: 701px;
          width: 100%;
          height: 50px;
          padding: 0px 20px;
          background: #050505;
          border: 2px solid #2d2d2d;
          border-radius: 10px;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          color: #fff;

          @media only screen and (max-width: 767px) {
            max-width: 65%;
          }

          &::placeholder {
            color: rgba(235, 235, 245, 0.6);
          }
        }

        a {
          padding: 17px 20px;
        }
      }
    }

    .mark-resolve_btn {
      padding: 25px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        padding: 17px 20px;
      }
    }
  }
}

/*************** BetYourself End *****************/
/*************** Resolve Popup Start ***************/
.modal-resolve-logs {
  .modal-dialog {
    max-width: 35%;

    @media only screen and (max-width: 768px) {
      max-width: 77%;
      margin: auto;
    }

    @media only screen and (min-width: 767px) and (max-width: 991px) {
      max-width: 50%;
    }

    // @media only screen and (min-width: 1300px) {
    //   max-width: 1100px;
    // }
    // @media only screen and (min-width: 1900px) {
    //   max-width: 1400px;
    // }
    .modal-content {
      background: #181818;
      border: 2px solid #454545;
      border-radius: 25px;

      .modal-header {
        border-bottom: none !important;

        .btn-close {
          background-image: url("../images/close_button.png");
        }
      }
    }
  }
}

.resolve_popup {
  .resolve_popup_block {
    h3 {
      font-size: 25px;
      color: #fff;
      text-align: center;
      font-family: "Gilroy-Bold";
    }

    .resolve-btn-block {
      display: flex;
      gap: 20px;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .resolve-btn {
        border: 2px solid #292929;
        border-radius: 10px;
        background-color: #000;
        font-family: "Poppins-SemiBold";
        font-size: 17px;
        line-height: 17px;
        text-transform: uppercase;
        min-width: 141px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 55px;
      }

      .resolve-yes-btn {
        &::before {
          content: url("../images/tick_green.png");
          padding-right: 15px;
        }
      }

      .resolve-no-btn {
        &::before {
          content: url("../images/close_red.png");
          padding-right: 15px;
        }
      }
    }
  }
}

/*************** Resolve Popup End ***************/

/*************** players-list-section Start ***************/
.players-list-section,
.GB_tournaments {
  ._lb_web_table {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  ._lb_mob_table {
    @media only screen and (max-width: 767px) {
      display: block;
    }

    ._each_lb_head {
      align-items: start;
      flex-direction: column;

      ._each_lb_left {
        max-width: 100%;
        flex: 0 0 100%;
      }

      ._each_lb_right {
        max-width: 100%;
        flex: 0 0 100%;

        ._each_lb_email {
          text-transform: none;
        }
      }

      .emailAdmin {
        font-size: 11px;
      }
    }

    .last-login {
      color: #ffc01f;
      font-size: 12px;
      margin-bottom: 5px;
    }

    .head_sub {
      display: flex;
      flex-direction: column;
    }

    .referralURL {
      font-size: 12px !important;
      padding-top: 0;
    }
  }

  .tournament-table {
    .watch_btn {
      max-width: 130px;
      color: #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border-radius: 10px;
      border: 2px solid #ffffff;
      font-family: "Poppins-SemiBold";
      font-size: 13px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding: 10px;
      text-decoration: none;
      cursor: pointer;

      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.betting-logs-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .gb-red-btn {
    padding: 17px 15px;
  }
}

/*************** players-list-section End ***************/

/*************** Admin-model Start ***************/
.modal {
  z-index: 9999999 !important;
  top: 15px;
}

.adminmdl {
  width: 100%;
  margin: auto;

  .modal-body {
    .content-sect {
      .form-item {
        @media only screen and (max-width: 767px) {
          flex-direction: column;
          align-items: center !important;
        }

        .form-item-box {
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .form-item-btn {
          @media only screen and (max-width: 767px) {
            width: 85%;
            margin: auto;
          }

          .custprice-btn {
            margin-top: 10px;

            @media only screen and (max-width: 767px) {
              margin-left: 0;
            }
          }
        }
      }
    }

    ._GB_Transactions {
      ._GB_Transactions_Banner {
        @media only screen and (max-width: 767px) {
          padding: 25px 0;
        }
      }

      .Transactions_details {
        .GB_device_td {
          @media only screen and (max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            vertical-align: middle;
          }

          &::before {
            @media only screen and (max-width: 767px) {
              position: relative;
              top: 0;
            }
          }

          .table-cont {
            @media only screen and (max-width: 767px) {
              padding-top: 0;
            }

            ._managePaid {
              ._GB_CB_Name {
                @media only screen and (max-width: 767px) {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

/*************** Admin-model End ***************/

.apexcharts-text tspan {
  @media only screen and (max-width: 767px) {
    font-size: 10px;
  }
}

.lf_errordesc {
  max-width: 500px;
  word-wrap: break-word;
}

._logyellowtext span {
  color: #fdbf1f !important;
}

._loggreentext span {
  color: #52dd21 !important;
}
.editChallengesList{
  .ec-items {
      display: flex;
          align-items: center;
      gap: 10px;
      padding: 10px;
      font-family: "Poppins-SemiBold";
      color: #fff;
      border-bottom: 1px solid #454545;
        margin-bottom: 10px;
      &.ec-items-head{
          color: gold;
      }
      .ec-number{
flex: 0 0 5%;
  max-width: 5%;
      }
      .ec-image {
        flex: 0 0 5%;
        max-width: 5%;
      }
      .ec-name {
        flex: 0 0 25%;
        width: 25%;
      }
      .ec-cond {
        flex: 0 0 15%;
        max-width: 15%;
      }
      .ec-mult {
        flex: 0 0 15%;
        max-width: 15%;
            display: flex;
              justify-content: center;
      }
      .ec-mult-double{
        flex: 0 0 7.5%;
        max-width: 7.5%;
            display: flex;
              justify-content: center;
      }
      .ec-account{
        flex: 0 0 10%;
        max-width: 10%;
      }
      .ec-auth {
        flex: 0 0 15%;
        max-width: 15%;
      }
      .ec-hideshow {
        flex: 0 0 10%;
        max-width: 10%;
      }
      .ec-input-text {
        width: 100px;
        background-color: #161515;
        color: #fff;
        border: none;
        padding: 10px 5px;
        text-align: center;
        font-size: 16px;
      }
      .ec-mult{
        .ec-input-text {
            width: 85px;
          }
      }
            select.ec-input-text {
              cursor: pointer;
                  text-align: left;
                    padding: 10px;
                    font-size: 16px;
            }
      .ec-cond-wrap {
        display: flex;
        margin-bottom: 10px;
        justify-content: flex-start;
        gap: 13px;
        align-items: center;
        label {
          text-transform: capitalize;
        }
      }
    }
}

.gl-bet {
display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 30px;
  .sel-save-wrap {
      display: flex;
      align-items: center;
      gap: 10px;
    .gl-choose {
        color: #fff;
        font-family: "Poppins-SemiBold";
      }
    
      .gl-name {
        cursor: pointer;
    
        &.selectedGame {
          background: rgba(200, 0, 0, 0.5);
          border-color: #c80000;
          padding: 5px;
        }
    
        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  
}
.editChalPage{
  .nav-link{
    color: #fff;
    font-family: "Poppins-SemiBold";
    &.active{
          background: #e5242082;
            border-bottom: 2px solid #e52420;
            color: #fff;
    }
  }
}
.savechallengesBtn {
  cursor: pointer;
}
.editedBy {
  color: #52dd21;
  font-family: "Poppins-SemiBold";
}
.ad_withd{
  .editedBy {
      color: #fff;
    }
}

.edit2faMsg .alert-danger {
  background-color: #161515;
  color: #fff;
  border: none;
  text-align: center;
  font-family: "Poppins-SemiBold";
      padding: 30px;
}

.editchallenges2faBtn {
  background: #52dd21;
  color: #000;
  font-family: "Poppins-Bold";
  font-size: 16px;
  line-height: 20px;
  padding: 15px 30px;
  border-radius: 10px;
  cursor: pointer;
  text-transform: uppercase;
}

.updateonBy {
  display: block;
  color: gold;
  font-size: 13px;
}
.ad_withd {
  .updateonBy {
      margin-top: 10px;
    }
}
.votp {
  text-align: center;
}
.acc-data-wrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.acc-remark.succ{
 color: #52dd21
}
.acc-remark.err {
  color: #e52420
}
.matchdetails-popup{
 
  .modal-content{
    background: #212121;
    .modal-header {
          font-family: "Gilroy-Bold";
            font-size: 25px;
            color: #ffffff;
            .btn-close {
                background: url(../images/popupclose.png) no-repeat center center;
                &:focus {
                  box-shadow: none !important;
                }
              }
    }
    .modalmatchwrap {
      .logdetails_block {
        margin-bottom: 20px;
        .title {
          font-family: "Gilroy-Bold";
            font-size: 20px;
            color: #52dd21;
        }
        .betlog_data {
          color: #fff;
          // display: flex;
          //   justify-content: space-between;
            font-family: "Gilroy-Bold";
            margin-bottom: 5px;
        }
      }
    }
  }
}
.admin_formDate_box{
  label{
    font-size: 16px !important;
      line-height: 16px !important;
  }
}
.admin_summary_logofilter{
.dropdown-menu .dropdown-item {
    padding: 8px 10px !important;
  }
.dropdown-item.active {
      background: #c80000 !important;
      border-radius: 14px !important;
    }
    .admin-daterangepick{
      padding-left: 10px;
    }
}

._GB_manageFaq {
  ._GB_manageFaq_Inner {
    .title {
      font-family: "Gilroy-Bold";
      font-size: 40px;
      line-height: 90px;
      color: #ffffff;

      @media only screen and (max-width: 990px) {
        font-size: 30px;
      }

      @media only screen and (max-width: 480px) {
        font-size: 25px;
      }
    }

    .sub-title {
      font-family: "Gilroy-SemiBold";
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;

    }

    label {
      font-family: "Gilroy-Medium";
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 10px;
    }

    .manageFaq_wrap {
      margin-bottom: 50px;

      @media only screen and (min-width: 768px) {
        justify-content: space-between;
      }

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
      }

      .manageFaq_left {
        max-width: 40%;
        flex: 0 0 40%;

        @media only screen and (max-width: 1024px) {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 30px;
        }

        .manageFaq_Bg {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px 33px;

          .faqCategory_sec {
            .categoryForm {
              margin: 20px 0;
              gap: 20px;
              max-width: 100%;
              flex: 0 0 100%;

              .category_formleft {
                max-width: 48%;
                flex: 0 0 48%;
              }

              .category_formright {
                .form-leftSec {
                  margin-bottom: 10px;
                }
              }
            }

            .category-list {
              margin: 20px 0;
              max-width: 48%;
              flex: 0 0 48%;
            }
          }

        }
      }

      .manageFaq_right {
        max-width: 59%;
        flex: 0 0 59%;

        @media only screen and (max-width: 1024px) {
          max-width: 100%;
          flex: 0 0 100%;
        }

        .manageFaq_Bg {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px 33px;

          .category-list {
            margin: 20px 0;
          }
        }
      }
    }

    .manageAddFaq_wrap {
      .manageFaq_Bg {
        background: #181818;
        border: 2px solid #2d2d2d;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 20px 33px;

        @media only screen and (max-width: 767px) {
          padding: 20px 0px;
        }
      }
    }


  }

  .card {
    .setingFormField {

      .sun-editor {
        border-radius: 10px;
        background: #000 !important;
        border: 2px solid #2d2d2d;

        .se-toolbar {
          background: #000 !important;
          border-bottom: 2px solid #2d2d2d;
          outline: none !important;
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 0px !important;
          border-bottom-right-radius: 0px !important;

          .se-btn-module {
            border: 1px solid #2d2d2d;

            .se-menu-list {
              .se-tooltip {
                color: #fff;

                &:hover {
                  background-color: #2d2d2d;
                }
              }
            }
          }
        }

        .se-list-format {
          border: 2px solid #2d2d2d;
        }

        .sun-editor-editable {
          background: #000 !important;
          border-top: none;
          height: 250px;
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 19px;
          color: #fff;
          table{
            th{
              background-color: #000 !important;
            }
          }
        }

        .sun-editor-common {
          //background: #000 !important;
          border-top: none;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

.se-resizing-bar .se-navigation {
  background: #000 !important;
}
.se-resizing-bar.sun-editor-common.se-resizing-none {
  background: #000;
}

      }

      .ck-balloon-panel {
        display: none !important;
      }

      .ck-powered-by {
        display: none !important;
      }
    }
  }

    .faq-add-title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .faqupt-btn {
      gap: 15px px;
    }
  
    .faq-table-tr {
      font-family: "Gilroy-Medium";
      font-size: 18px;
    }
  
    .faqcat-tag {
      font-family: "Gilroy-Bold";
      font-size: 16px;
      color: #52dd21;
    }
.faq-count {
  font-family: "Gilroy-Medium";
  font-size: 16px;
  margin-bottom: 10px;
}
        .faq-nodata {
          font-family: "Gilroy-Medium";
          font-size: 15px;
          margin-bottom: 10px;
          text-align: center;
        }

        .acc_tab_admin{
          .acc_item_admin{
                border: none;
                  outline: none !important;
                  box-shadow: none !important;
                  .acc_header_admin{
                    outline: none !important;
                      box-shadow: none !important;
                      .accordion-button{
                            border-color: #2c3034;
                              background-color: #2c3034 !important;
                              
                                color: #52dd21;
                              background: url("../images/acc_mini.png");
                              background-repeat: no-repeat;
                                    background-position: 98% 50%;
                                        outline: none;
                                          box-shadow: none;
                              &::after {
                                  display: none;
                                }
                                                                &.collapsed {
                                                                  
                                                                    background: url("../images/acc_abbr.png");
                                                                    background-repeat: no-repeat;
                                                                      background-position: 98% 50%;
                                                                  
                                
                                                                  &::after {
                                                                    display: none;
                                                                  }
                                                                }
                      }
                      
                  }
                                    .accordion-body {
                                      border-color: #2c3034;
                                      background: #2c3034;
                                      color: #fff;
                                      td{
                                            border:none !important;
                                      }
                                                                            .ac-head-sub-faq {
                                                                              display: flex;
                                                                              justify-content: space-between;
                                                                              align-items: center;
                                                                              padding-bottom: 15px;
                                                                            }
                                    }
          }
        }

}

.guide-accordin-item {
  font-family: "Gilroy-Medium";
      font-size: 14px !important;
  &.selected {
      color: #c80000;
      font-family: "Gilroy-Bold";
      opacity: 1 !important;
    }
}
._GB_faq_details{
  .faq_data {
      ul{
        padding-left: 0;
        li{
          list-style: none;
          background: url("../images/support/listitem_arrow.png");
              background-repeat: no-repeat;
                background-position: 0px 6px;
                padding-left: 25px;
          &::marker{
            display: none;
          }
        }
      }
  }
}
._GB_Leaderboard_Inner.editChalPage {
  padding: 0 50px;
}
.faq_data{
  img {
    width: 100%;
  }
  figure {
    padding-bottom: 10px !important;
    width: 100% !important;
  }
  iframe{
    width: 100% !important;
    // height: 340px !important;
  }
  a {
    color: #c80000 !important;
    text-decoration: none;
  }
  table{
    width: 100%;
    tr{
      border: solid 1px #000;
      th{
        padding: 10px;
        background-color: #1E1E1E;
        color: #fff;
      }
      td{
        background-color: #161616;
        color: #fff;
        padding: 10px;
      }
    }
  }
  pre{
    background-color: #E52420;
      color: #fff;
      border-radius: 10px;
      font-family: "Gilroy-Medium";
      font-size: 16px;
      line-height: 29px;
      max-width: 771px;
      width: 100%;
      margin-bottom: 20px;
      padding: 5px;
          text-align: center;
  }
  .se-video-container{
    figure {
      height: 400px !important;
    }
  }
}

.common-faq-guide-exrept{
  a {
      color: #c80000 !important;
      text-decoration: none;
    }
}
.cat-faq-guide-select {
  margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
  select{
    flex: 0 0 50%;
      max-width: 50%;
    background: #000 !important;
    border: 2px solid #2d2d2d;
    border-radius: 15px;
    height: 56px;
    width: 100%;
    font-family: "Gilroy-Bold";
    font-size: 17px;
    line-height: 19px;
    color: #fff !important;
  }
    .manage-guide-order {
      span {
        font-family: "Gilroy-Bold";
          font-size: 17px;
          margin-left: 10px;
        }
    }
}
.guide-number-input{
  background: #000 !important;
  border: 2px solid #2d2d2d;
  border-radius: 15px;
  height: 56px;
  width: 80px;
  padding: 10px;
  font-family: "Gilroy-Bold";
  font-size: 17px;
  line-height: 19px;
  color: #fff !important;
  text-align: center;
}
.setingFormField{
  .sun-editor{
    .se-wrapper{
height: 500px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e52420;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #e52420;
  }
    }
      
  }
}

.faq-guide-tab{
  .nav-link {
    color: #fff !important;
    font-family: "Gilroy-Bold";
    font-size: 17px;
    &.active {
      background: #e5242082;
      border-bottom: 2px solid #e52420;
    }
  }
}

.common-faq-guide-exrept pre {
  overflow: hidden;
  font-family: "Gilroy-Medium";
}

.repeater_action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .setingFormField {
    flex: 0 0 90%;
    max-width: 90%;
  }
}
.repeater_item_wrap {
  padding: 20px;
  border: solid 1px;
  background: #2c3034;
  border-color: #000;
}

/****************** Hardcore Mode **********************/
.hcModeSettings{
  .hctab{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #fff;
    margin-top: 30px;
    .hctabitem{
      color: #fff;
      min-width: 75px;
      padding: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      font-family: "Gilroy-Bold";
      cursor: pointer;
      &:hover{
        background: #e52420;
        border: 1px solid #fff;
        border-bottom: none !important;
      }
      &.active{
        background: #e52420;
        border: 1px solid #fff;
        border-bottom: none !important;
      }
    }
  }
  .hc_top_sec{
    display: flex;
    align-items: center;
    margin-top: 20px;
    .hc_top_item{
      max-width: 25%;
      flex: 0 0 25%;
      .Title{
        color: #fff;
        font-size: 18px;
        font-family: "Gilroy-Medium";
      }
      .sort-game{
        padding: 10px 0;
      }
      .total_earnings{
        color: gold;
        font-family: "Gilroy-Bold";
        height: 35px;
      }
    }
  }
  .hc_second_sec{
    .title{
      color: #fff;
      font-size: 18px;
      font-family: "Gilroy-Medium";
      margin-top: 20px;
    }
    .hc_second_item{
      border: 2px solid #2d2d2d;
      border-radius: 12px;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      img{
        width: 110px;
        height: 110px;
      }
      .reward_item{
        color: #fff;
        font-family: "Gilroy-Medium";
        font-size: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
          color: gold;
        }
      }
    }
  }
}

/****************** Hardcore Mode **********************/
.p2ewrapadmin {
  display: flex;
  gap: 20px;
  flex-direction: column;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  color: #fff;
  padding: 30px;
  .p2ecryp {
      color: #fff;
      display: flex;
      align-items: center;
      padding: 15px;
      gap: 5px;
      font-family: "Gilroy-Bold";
      font-size: 18px;
    }
    .handleCriteria{
      margin-bottom: 10px;
    }
}

.hc_second_sec {
  .title {
    color: #fff;
    font-size: 18px;
    font-family: "Gilroy-Medium";
    margin-top: 20px;
  }

  .hc_second_item {
    border: 2px solid #2d2d2d;
    border-radius: 12px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
       margin-top: 20px;
         margin-bottom: 20px;

    img {
     //  width: 110px;
     //  height: 110px;
    }

    .reward_item {
      color: #fff;
      font-family: "Gilroy-Medium";
      font-size: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        color: gold;
      }
    }
  }
}
.admbtnwrapnew {
  display: flex;
  gap: 10px;
  .gb-red-btn{
    margin-right: 0 !important;
  }
}