.footerSection {
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---700---GB, #0E1216);

    .footerTopSection {
        display: flex;
        background: url("../../images/redo/footer-bg.png");
        background-size: 105% 120%;
        background-repeat: no-repeat;
        position: relative;
        animation: wave 10s linear infinite alternate;
        background-position: bottom;

        @media screen and (max-width: 767px) {
            flex-direction: column;
            background: url("../../images/redo/new-footer-bg.png");
            background-size: 110% 69%;
            background-repeat: no-repeat;
            position: relative;
            animation: wave 10s linear infinite alternate;
            background-position: bottom;
        }

        .leftSection {
            max-width: 250px;
            width: 100%;
            background-color: #0e1216;
            z-index: 0;
            @media screen and (max-width: 1024px) {
                max-width: 0px;
            }
        }

        .rightSection {
            width: 100%;

            @media only screen and (min-width: 1921px) {
                max-width: 1276px;
                margin: auto;
            }

            @media only screen and (max-width: 767px) {
                flex-direction: column;
            }

            .rightSection_left {
                @media only screen and (min-width: 1200px) and (max-width: 1800px){
                    margin: auto 125px auto 25px;
                }
                margin: auto 125px auto auto;
                @media only screen and (min-width: 1921px) {
                    width: 100%;
                   
                }

                @media screen and (max-width: 767px) {
                    margin: auto;
                }

                .leftContainer {
                    max-width: 968px;
                    width: 100%;

                    .footerContents {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        padding: 100px 0px;

                        @media screen and (max-width: 1260px) {
                            padding: 100px 15px;
                           
                        }

                        @media screen and (max-width: 767px) {
                            flex-direction: row;
                            justify-content: flex-start;
                            // column-gap: 70px;
                            padding: 50px 15px 20px 15px;
                        }

                        ul {
                            li {
                                list-style-type: none;
                                color: #92A6BA;
                                font-family: "Gilroy-Bold";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 22px;
                                padding-bottom: 15px;
                                cursor: pointer;
                                @media only screen and (max-width: 767px){
                                   font-size: 14px;
                                }
                                &:hover{
                                    opacity: 0.5;
                                }

                                &:first-child {
                                    color: #F0FFF1;
                                    &:hover{
                                        opacity: 1;
                                    }
                                }

                                a {
                                    color: #92A6BA;
                                    text-decoration: none;
                                    font-family: "Gilroy-Regular";
                                    font-weight: 400;
                                }
                            }
                        }

                        .col {
                            @media screen and (max-width: 1200px) {
                                width: 35%;
                            }

                            @media screen and (max-width: 767px) {
                                width: 50%;
                            }
                        }

                        .siteDesc {

                            @media screen and (max-width: 1024px) {
                                margin-bottom: 34px;
                            }

                            @media screen and (min-width: 1201px) {
                                flex: 0 0 30%;
                            }

                            p {
                                color: #C7D1DB; 
                                font-family: "Gilroy-Medium";
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 24px;
                                margin-top: 24px;
                                margin-bottom: 22px;
                                @media only screen and (max-width: 767px){
                                    font-size: 14px;
                                }
                                span{
                                    font-family: "Gilroy-Bold";
                                }
                            }
                        }

                        .companySec {
                            @media screen and (max-width: 767px) {
                                flex: 0 1 50%;
                            }
                            

                            ul {
                                @media screen and (max-width: 767px) {
                                    padding-left: 10px;
                                }
                            }

                        }

                        .socialSec {
                            @media screen and (max-width: 767px) {
                                flex: 0 1 50%;
                            }
                        }

                        .supportedSec {
                            ul {
                                @media screen and (max-width: 767px) {
                                    padding-left: 10px;
                                }
                            }

                        }

                    }
                }
            }

            .rightSection_right {
                background: url("../../images/redo/footer-desk-bg.png");
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
                animation: wave 10s linear infinite alternate;
                // margin-left: auto;
                max-width: 414px;
                width: 100%;
                display: flex;
                justify-content: center;


                    @media only screen and (max-width: 767px) {
                        background: url("../../images/redo/new-footer-greenbg.png");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        position: relative;
                        animation: wave 10s linear infinite alternate;
                        background-position: bottom!important;
                         padding-bottom: 231px;

                        
                    }


                @media screen and (max-width: 1024px) {
                    width: 100%;
                    max-width: unset;
                    background-position: center;
                }

                @media screen and (min-width: 1601px) {
                    max-width: 483px;
                }

                .backImageSec1 {
                    position: absolute;
                    z-index: 0;
                    transform-origin: center center;
                    // animation: rotate 60s linear infinite;
                    object-fit: cover;
                    margin-top: 0px;

                    @media only screen and (max-width: 767px) {
                       //  display: none;
                    }
                }

                .mobileblackImage
                {
                    display: none;
                }

                .backImageSec2{
                    position: absolute;
                    // z-index: -1;
                    transform-origin: center center;
                    animation: rotate 90s linear infinite;
                    object-fit: cover;
                    // transform: rotateX('90deg');
                    top: 50px;
                    left: 133px;
                    @media only screen and (max-width: 767px){
                        display: none;
                    }
                }

                .mobileblackImage
                {
                    position: absolute;
                    transform-origin: center center;
                    object-fit: cover;
                    
                    // transform: rotateX('90deg');
                    @media only screen and (max-width: 767px) {
                        display: block;
                        z-index: 0;
                        animation: rotate 30s linear infinite;
                    }
                }

                .rightInner {
                    // padding: 96px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 64px;

                    @media screen and (min-width: 768px) and (max-width: 1024px) {
                        padding: 40px 0px;
                        gap: 100px;
                    }

                    @media screen and (min-width: 1025px) {
                        padding: 40px 0px;
                        gap: 100px;
                    }

                    @media only screen and (max-width: 767px) {
                        // padding: 0px 0px 96px 0;
                        gap: 50px;

                    }

                    .comingSoon {
                        display: flex;
                        justify-content: center;

                        @media only screen and (max-width: 767px) {
                            margin-top: 41px;
                        }

                        p {
                            color: var(--Base-White, #FFF);
                            font-family: "Gilroy-SemiBold";
                            font-size: 16px;
                            font-weight: 400;
                            line-height: normal;

                            @media only screen and (max-width: 767px) {
                                font-size: 14px;
                            }
                        }
                    }

                    .innerBox {
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        // @media only screen and (min-width: 491px) and (max-width: 767px){
                        //     flex-direction: row;
                        // }
                        .box {
                            width: 193px;
                            height: 80px;
                            padding: 24px;
                            border-radius: 24px;

                            img {
                                height: 33px;
                                width: 130px;
                            }
                        }

                        .appStore {
                            background: #51E20E;
                            transform: rotate(-14deg);

                            img  {
                                height: 86px;
                                width: 140px;
                                margin-top: -26px;
                            }
                        }

                        .googlePlay {
                            border: 1.5px solid rgba(59, 77, 94, 0.5);
                            background: rgba(10, 13, 16, 0.40);
                            -webkit-backdrop-filter: blur(24px);
                            backdrop-filter: blur(24px);
                            transform: translateY(-6px) translatex(31px) rotate(16deg);

                        }
                    }



                }

            }
        }

    }


    .footerBottomSection {
        background: rgba(10, 13, 16, 0.40);
        border-radius: 24px 24px 0px 0px;
        backdrop-filter: blur(32px);
        margin-top: -40px;
        display: flex;
        align-items: center;

        @media screen and (max-width: 1024px) {
            display: block;
        }

        .footerBottomLeft {
            max-width: 250px;
            width: 100%;
            display: none;
        }

        .footerBottomRight {
            // max-width: 1500px;
            width: 100%;
            margin: 0 auto;
            padding: 20px 0px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            @media screen and (max-width: 1300px) {
                padding: 20px 15px;
            }

            @media screen and (max-width: 1024px) {
                flex-direction: column;
                text-align: center;
                gap: 8px;
                padding: 20px 0px;
            }

            @media only screen and (min-width: 1921px) {
                max-width: 1276px;
            }

            .footerCopyrightSection {

                @media only screen and (max-width: 1920px) {
                    max-width: 968px;
                    width: 100%;
                    margin: auto;
                    flex: 0 0 65%;
                }
                @media only screen and (min-width: 1200px) and (max-width: 1800px){
                    margin-left: 40px;
                }

                @media screen and (max-width: 767px) {
                    padding: 0px 5px;
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                p {
                    color: #74F33B;
                    font-family: "Gilroy-Regular";
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 16px;
                    margin-bottom: 0;

                    @media screen and (max-width: 767px) {
                        font-size: 14px;
                    }
                }
            }

            .footerTermsSection {
                display: flex;
                justify-content: center;
                @media only screen and (max-width: 1920px) {
                    max-width: 30%;
                    flex: 0 0 30%;
                }

                @media only screen and (max-width: 1024px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                p {
                    margin-bottom: 0;

                    a,
                    span {
                        color: #FFF;
                        font-family: "Gilroy-Regular";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        text-transform: capitalize;
                        text-decoration: none;
                        cursor: pointer;

                        @media screen and (max-width: 767px) {
                            font-size: 12px;
                        }
                    }
                    a{
                        &:hover{
                            opacity: 0.5;
                        }
                    }
                    span{
                        padding: 0px 14px;
                        
                    }
                }
            }

        }
    }
}

@keyframes wave {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 0;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
@keyframes rotateAndMove {
    0% {
        transform: rotate(0deg) translate(0, 0);
    }
    25% {
        transform: rotate(90deg) translate(70px, 0);
    }
    50% {
        transform: rotate(180deg) translate(140px, 70px);
    }
    75% {
        transform: rotate(270deg) translate(70px, 140px);
    }
    100% {
        transform: rotate(360deg) translate(0, 70px);
    }
}
