._GB_RHeader {
  background-color: rgba(4, 5, 6, 0.4);
  position: relative;
  z-index: 1 !important;

  ._GB_RHeader_Nav {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    list-style: none !important;
    padding: 5px 0px;
    max-width: 1240px;
    width: 100%;
    margin: auto;

    ._GB_RHeader_left {
      display: flex;
      align-items: center;
    }

    #dropdown-language {
      width: 124px;
      height: 50px;
      margin: 20px 0;
      // border: 1px solid rgba(10, 13, 16, 0.4) !important;
      border: none !important;
      // font-family: "Gilroy-Medium";
      border-radius: 16px;
      background: rgba(10, 13, 16, 0.4);
      padding: 12px 15px;
      color: #fff;
      font-family: "Gilroy-Regular";
    }

    .dropdown-menu {
      .dropdown-item {
        width: 124px !important;

        &:hover {
          color: #51e20e !important;
          background: none !important;
        }

        .lang_dd_each {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          &:hover {
            color: #51e20e !important;
            background: none !important;
          }

          img {
            margin-right: 5px;
          }
        }
      }
    }

    .dropdown-menu {
      width: 124px !important;
      // background: transparent !important;
      // border: 1px solid rgba(10, 13, 16, 0.4) !important;
      width: 124px !important;
      backdrop-filter: blur(32px);
      border: 1px solid rgba(10, 13, 16, 0.4) !important;
      -webkit-appearance: none;
      border-radius: var(--Spacing-lg, 16px);
      -webkit-backdrop-filter: blur(32px);
    }

    .navbar-nav {
      display: flex !important;
      align-items: center !important;
      list-style: none !important;

      .nav-item {
        a {
          color: #fff;
          font-family: "Gilroy-Regular";
        }

        &.nav-reg {
          margin: 0 15px;

          a {
            color: #51e20e;
            font-size: 14px;
            line-height: 24px;
            font-family: "Inter-Medium";
            &:hover{
              opacity:0.5;
            }
          }
        }

        .loginBtn {
          background-color: #51e20e;
          color: #0e1216;
          height: 48px;
          max-width: 100px;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 24px;
          font-family: "Inter-Medium";
          padding: 12px 29px;
          border-radius: 18px;
          &:hover{
            opacity:0.5;
          }
        }

        #dropdown-help {
          background-color: transparent !important;
          border: none !important;

          .menu_help {
            span {
              img {
                margin-right: 5px;
              }
            }
          }
        }

      }
    }
  }
}

.GB_newsFeed {
  background-image: url("../../images/redo/newsFeed.png");
  background-size: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  position: relative;
  z-index: 1 !important;

  @media only screen and (max-width: 1024px) {
    display: none;
  }

  &.GB_loggedNewsFeed {
    position: fixed !important;
    width: 100%;

    @media only screen and (max-width: 1024px) {
      display: none !important;
    }
  }

  .newsFeedInner {
    .contentSec {
      cursor: pointer;
      font-size: 14px;
      line-height: 18px;
      font-family: "Gilroy-SemiBold";
      color: #040506;
      text-align: center;
      z-index: 1;

      @media only screen and (max-width: 1024px) {
        font-size: 15px;
      }

      @media only screen and (max-width: 575px) {
        font-size: 11px;
      }

      a {
        color: #040506;
        text-decoration: none;
      }
    }
  }
}

.r_device_menu {
  padding: 0 30px;

  @media only screen and (max-width: 1024px) {
    padding: 0px;
  }

  .mobile-main {
    display: block;
    background-color: #0E1216;
    height: 90px;

    @media only screen and (min-width: 1101px) {
      display: none;
    }

    transition: margin-left 0.5s;
    /* If you want a transition effect */
    padding: 20px 0px;
    box-shadow: 10px 2px 14px 10px rgba(17, 15, 15, 0.06);

    .tooth-device-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .menu-left-sec{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        .item-logo {
          padding: 0;
          a{
            padding: 0px;
          }
        }
  
        .item-burger {
          .menu-burger {
            width: 24px;
            cursor: pointer;
  
            span {
              display: block;
              background: #fff;
              height: 2px;
              border-radius: 10px;
  
              &.middle {
                margin: 5px 0;
              }
            }
          }
        }

      }
      .menu-right-sec{
        ul{
          display: flex;
          gap: 28px;
          margin-bottom: 0;
          padding-left: 0;
          justify-content: center;
          align-items: center;
          li{
            list-style: none;
            &.register-link{
              a{
                color: #51e20e;
                font-family: Inter-Medium;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-decoration: none;
              }
            }
            &.login-link{
              a{
                border-radius: 16px;
                background: #51e20e;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                height: 44px;
                font-family: Inter-Medium;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                color: #0e1216;
                cursor: pointer;
                border: none !important;
                text-decoration: none;
              }
            }
          }
        }
      }

 

      #_GB_LogoSection {
        img {
          max-width: 87px;
          // image-rendering: -webkit-optimize-contrast;
          // border: 4.5px solid #c80000;
          // border-radius: 100%;
          // @media only screen and (min-width: 375px) {
          //   max-width: 50px;
          // }
          // @media only screen and (min-width: 480px) {
          //   max-width: 60px;
          // }
          // @media only screen and (min-width: 1366px) {
          //   max-width: 265px;
          // }
        }
      }
    }

    /* The sidebar menu */
    #mobileSidebar {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      z-index: 9999;
      padding-top: 40px;

      &.sidebar {
        height: 100%;
        /* 100% Full-height */
        width: 0;
        /* 0 width - change this with JavaScript */
        position: fixed;
        /* Stay in place */
        z-index: 1;
        /* Stay on top */
        top: 0;
        left: 0;
        overflow-x: hidden;
        /* Disable horizontal scroll */
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidebar */
        opacity: var(--Corner-radius-xs, 1);
        background: var(--Background---grey-Grey---700---GB, #0E1216);
        z-index: 1000;

        .closebtn {
          position: absolute;
          top: 0;
          right: 25px;
          font-size: 36px;
          margin-left: 50px;
          color: #979797 !important;
          text-decoration: none;
        }

        a.nav-link {
          padding: 12px;
          text-decoration: none;
          font-size: 16px;
          color: #979797 !important;
          display: block;
          transition: 0.3s;
          font-family: "Gilroy-SemiBold";
          width: 100%;

          &:hover {
            color: #f1f1f1;
          }
        }

        .nav-link {
          padding: 12px;
          color: #fff;
          border-bottom: 1px solid rgba(59, 77, 94, 0.5);
          background: #0E1216;

          &:first-child {
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
          }

          .dropdown {
            #dropdown-language {
              background-color: #0E1216;
              border: 3px solid rgba(59, 77, 94, 0.5) !important;
              min-width: 110px;
            }
          }

          a {
            // padding: 12px;
            text-decoration: none;
            font-size: 16px;
            color: #979797 !important;
            display: block;
            transition: 0.3s;
            font-family: "Gilroy-SemiBold";
            width: 100%;

            &:hover {
              color: #f1f1f1;
            }

            img {
              max-width: 40px;
              image-rendering: -webkit-optimize-contrast;

              @media only screen and (min-width: 768px) {
                max-width: 265px;
              }
            }

            .text {
              padding-left: 24px;
            }

            &.sp-nav-logo {
              display: flex;

              img {
                padding-right: 30px;
                max-width: 180px;
                object-fit: contain;
              }
            }

            &.mode {
              .mode_type {
                color: #979797 !important;
                text-decoration: none;
              }
            }
          }

          &.profile {
            position: relative;

            .profile_image {
              img {
                width: 45px;
                height: 45px;
                max-width: 100%;
              }
            }

            .profile_info {
              position: static;

              .dropdown {
                position: static;

                #dropdown-profile {
                  background: #181818;
                  border: none;
                  font-size: 16px;
                  color: #979797 !important;
                  font-family: "Gilroy-SemiBold";
                }

                .profile_list_dd {
                  z-index: 1;
                  border-radius: none;
                  border: none;

                  // padding-left: 30px;
                  @media only screen and (max-width: 992px) {
                    transform: none !important;
                    left: 0 !important;
                    // top: 75px !important;
                    right: 0 !important;
                  }

                  hr {
                    display: none;
                  }

                  a {
                    .profile_dd_each {
                      a {
                        @media only screen and (max-width: 992px) {
                          // padding: 12px 0px;
                        }
                      }

                      img {
                        width: 45px;
                      }
                    }

                    .mode_alert {
                      white-space: break-spaces;
                    }
                  }
                }
              }
            }
          }

          .dashboard {
            img {
              width: 50px;
            }
          }

          &.wallet-section {
            a {
              div {
                color: #979797 !important;
              }
            }
          }
        }

        .warning {
          padding: 8px 50px;

          .warning_msg {
            font-size: 10px;
            margin: auto;
          }
        }

        .wallet_list_dd {
          z-index: 1;

          @media only screen and (max-width: 992px) {
            right: -69px;
          }

          .title_sec {
            img {
              @media only screen and(max-width: 574px) {
                width: 25px;
              }
            }
          }

          .active {
            a {
              @media only screen and(max-width: 992px) {
                padding: 0px 12px;
              }
            }
          }
        }

        .wallet_dropdown {
          .title_section {
            text-align: center;
          }
        }
      }

      // &.active {
      //   padding-left: 30px;
      //   padding-right: 30px;
      // }
    }

    .dropdown-menu {
      background-color: #181818;

      .lang_items {
        padding: 15px;
      }

      .dropdown-item {
        &:not(:last-child) {
          border-bottom: 1px solid #2d2d2d;
        }
      }
    }
  }

  @media screen and (min-width: 1101px) {
    display: none !important;
  }

  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {
      padding-top: 15px;
    }

    .sidebar a {
      font-size: 18px;
    }
  }
}

.flg-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

._GB_Redo_Main {
  background: linear-gradient(355.71deg,
      #0e1216 3.49%,
      rgba(14, 18, 22, 0.015625) 71.81%,
      rgba(14, 18, 22, 0) 71.81%);
  background-image: url("../../images/redo/2fa-bg.png");
  background-repeat: no-repeat;
  background-size: 100% 120%;
  display: flex;

  .common_wrapper {
    width: 100%;
    max-width: 100%;
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---700---GB, #0E1216);
  }
}

._GB_RUserheader {
  //margin-left: 248px;
  background-color: var(--Background---grey-Grey---700---GB, #0E1216);

  @media only screen and (max-width: 1100px) {
    display: none;
  }

  .header {
    display: flex;
    //justify-content: space-between;
    padding: 15px;
    align-items: center;
    border-right: 3px solid rgba(59, 77, 94, 0.5);

    .headLeft {
      display: flex;
      gap: 20px;
      align-items: center;

      img {
        &.arow {
          border-radius: var(--Spacing-md, 12px);
          border: 1.5px solid var(--Colors-Grayscale-Border-Disable, #dcdee8);
          backdrop-filter: blur(28px);
          width: 24px;
          height: 24px;
        }
      }
    }

    .headRight {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .left {
        margin-left: 50px;
        max-width: 382px;
        width: 100%;

        // @media only screen and (max-width: 1850px) {
        //   max-width: 260px;
        //   margin-left: 30px;
        // }

        @media only screen and (max-width: 1440px) {
          max-width: 200px;
        }

        @media only screen and (max-width: 1200px) {
          max-width: 150px;
          margin-left: 10px;
        }

        .search {
          max-width: 382px;
          width: 100%;

          // @media only screen and (max-width: 1850px) {
          //   max-width: 260px;
          // }

          @media only screen and (max-width: 1440px) {
            max-width: 200px;
          }

          @media only screen and (max-width: 1200px) {
            max-width: 150px;
          }

          .searchInner {
            padding: 0 20px;
            display: flex;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 20px;
            background: #181f26;
            width: 100%;
            @media only screen and (min-width: 1025px) and (max-width: 1200px){
              padding: 0 10px;
            }
            input {
              font-family: "Inter-Medium";
              font-size: 13px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              background: #181f26;
              border: 0 !important;
              outline: none;
              box-shadow: none;
              color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
              height: 44px;
              width: 100%;

              &:-webkit-input-placeholder {
                font-family: "Inter-Medium";
                color: #767a87;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }
            }
            .search-icon{
              @media only screen and (min-width: 1101px) and (max-width: 1365px){
                display: none;
              }
            }
            .dropdown {
              display: flex;
              align-items: center;
              justify-content: center;

              #dropdown-search-help {
                background: no-repeat;
                border: none;
                padding: 0;
                display: flex;
                align-items: center;
                box-shadow: none;
                &::after {
                  display: none;
                }
              }
            }
          }

        }

        .searchResult {
          background-color: #181F26;
          max-width: 385px;
          width: 100%;
          border-radius: 20px;
          min-height: 105px;
          padding: 15px;
          position: absolute;
          top: 65px;
          z-index: 999;

          ul {
            padding-left: 0px !important;

            li {
              list-style: none;
              color: #c7d1db;
              font-family: "Gilroy-Regular";
              font-size: 14px;
              line-height: 24px;
              &:hover{
                opacity: 0.5;
              }
            }
          }

          .searchAjaxLoader {
            font-size: 16px;
            line-height: 24px;
            color: #5D7893;
            font-family: "Gilroy-SemiBold";
            width: 100%;
            display: flex;
            gap: 10px;
            height: 100%;
            justify-content: center;
            align-items: center;
            // border-radius: var(--Spacing-xl, 20px);
            // border: 1.5px solid rgba(255, 255, 255, 0.10);
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---500---GB, #181F26);
            height: 200px;
          }


          .scrollIt {
            height: 310px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 3px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #181F26;
              border-radius: 9px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #74F33B;
              border-radius: 9px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #74F33B;
            }
          }
        }
      }

      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        @media only screen and (min-width: 1366px) {
          gap: 16px;
        }
        .show{
          .wallet{
            border-color: #74F33B;
          }
        }

        .addBtn {
          border-radius: var(--Spacing-lg, 16px);
          background: var(--Primary-Green---500---GB, #74f33b);
          box-shadow: 0px 2px 4px 0px rgba(116, 243, 59, 0.04),
            0px 24px 48px -8px rgba(116, 243, 59, 0.12);
          max-width: 149px;
          height: 43px;
          padding: 16px 20px;
          cursor: pointer;
          font-family: 'Inter-Medium';
          font-size: 12px;
          line-height: 24px;

          &:hover {
            opacity: 0.5;
          }

          @media only screen and (max-width: 1080px) {
            padding: 10px 9px;
            height: 40px;
          }
        }

        .wallet {
          display: flex;
          height: 45px;
          padding: 12px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: var(--Spacing-xl, 18px);
          border: 1.5px solid var(--Background---grey-Grey---400---GB, #3b4d5e);
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          cursor: pointer;
          line-height: 24px;
        }

        .notification {
          display: flex;
          width: 44px;
          height: 44px;
          padding: var(--Spacing-xl, 18px);
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: var(--Spacing-xl, 18px);
          border: 1.5px solid var(--Background---grey-Grey---400---GB, #3b4d5e);
          position: relative;
          cursor: pointer;
          &.show{
            border-color: #74F33B;
          }

          .dot {
            position: absolute;
            top: 32px;
            left: 17px;
          }
        }

        .username {
          display: flex;
          align-items: center;
          gap: var(--Spacing-lg, 9px);

          img {
            &.profimage {
              width: 44px;
              height: 44px;
              border-radius: var(--Spacing-xl, 18px);
              border: 1.5px solid rgba(116, 243, 59, 0.5);
              // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
              padding: 5px;
              object-fit: contain;
            }

            &.arrow_img {
              cursor: pointer;
            }
          }

          .namesec {
            color: #fff;
            font-family: "Gilroy-SemiBold";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            cursor: pointer;

            span {
              color: var(--Background---grey-Grey---100---GB, #c7d1db);
              font-family: "Gilroy-SemiBold";
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }

        .redo-head-adm {
          color: #92a6ba;
          font-family: "Gilroy-Bold";
          display: flex;
          align-items: center;
          gap: 5px;
          margin: 0 10px;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }

    &.logoHeader {
      border-bottom: 1.5px solid rgba(59, 77, 94, .5);
    }
  }

  .rnotification {
    position: absolute;
    width: 100%;
    max-width: 511px;
    border-left: 2px solid #181f26;
    right: 0;
    // height: 100vh;
    z-index: 9;
    background-color: #141a1f;

    .rnotification_section {
      background: linear-gradient(90deg,
          rgba(24, 31, 38, 0.5) 0%,
          rgba(24, 31, 38, 0) 100%),
        linear-gradient(0deg, #181f26, #181f26);
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .rnotification_title_sec {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        .rnotification_number {
          border-radius: var(--Spacing-md, 12px);
          border: var(--Corner-radius-xs, 1px) solid rgba(255, 255, 255, 0.5);
          background: var(--Color-Error-500, #ff3c35);
          padding: 6px 7px 6px 7px;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: #fff;
            font-family: "Gilroy-Bold";
            font-size: 18px;
            line-height: 18px;
            /* 100% */
          }
        }

        .rnotification_title {
          font-family: "Gilroy-Medium";
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          color: #fff;
        }
      }

      .rnotification_close {
        max-width: 32px;
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(59, 77, 94, 0.5);
        border-radius: var(--Spacing-lg, 10px);
        background: var(--Background---grey-Grey---500---GB, #181f26);
        cursor: pointer;
      }
    }

    .rnotification_list_sec {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 515px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 8px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #181f26;
        border-radius: 9px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #74f33b;
        border-radius: 9px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #74f33b;
      }

      .rnotification_single {
        border-radius: var(--Spacing-2xl, 24px);
        display: flex;
        padding: 14px 12px;
        gap: 15px;
        align-self: stretch;

        .img_section {
          display: flex;
          max-width: 56px;
          width: 100%;
          height: 56px;
          justify-content: center;
          align-items: center;
          border-radius: var(--Spacing-xl, 20px);
        }

        .rwarning_img {
          border: 1.5px solid rgba(255, 191, 0, 0.6);
          background: linear-gradient(180deg,
              rgba(255, 191, 0, 0) 0%,
              rgba(255, 191, 0, 0.12) 100%);
        }

        .close_img {
          border: 2px solid rgba(255, 60, 53, 0.6);
          background: linear-gradient(180deg,
              rgba(255, 60, 53, 0) 0%,
              rgba(255, 60, 53, 0.12) 100%);
        }

        .coin_img {
          border: 2px solid rgba(45, 198, 86, 0.6);
          background: linear-gradient(180deg,
              rgba(45, 198, 86, 0) 0%,
              rgba(45, 198, 86, 0.12) 100%);
        }
      }

      .warning {
        border: 1.5px solid rgba(255, 191, 0, 0.6);
        background: linear-gradient(90deg,
            rgba(255, 191, 0, 0.1) 0.1%,
            rgba(255, 191, 0, 0) 99.91%);
        backdrop-filter: blur(32px);
        // -webkit-backdrop-filter: blur(32px);
      }

      .failed {
        border: 1.5px solid rgba(255, 60, 53, 0.6);
        background: linear-gradient(90deg,
            rgba(255, 60, 53, 0.1) 0.1%,
            rgba(255, 60, 53, 0) 99.91%);
      }

      .success {
        border: 1.5px solid rgba(45, 198, 86, 0.6);
        background: linear-gradient(90deg,
            rgba(45, 198, 86, 0.1) 0.1%,
            rgba(45, 198, 86, 0) 99.91%);
      }

      .description_sec {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .rnotification_desc {
          overflow: hidden;
          color: #fff !important;
          text-overflow: ellipsis;
          font-family: "Gilroy-Medium";
          font-size: 14px;
          line-height: 16px;
          cursor: pointer;
          a {
            text-decoration: none;
            color: #fff !important;
          }

          /* 112.5% */
        }

        .rnotification_days {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .not_days {
            color: var(--Background---grey-Grey---200---GB, #92a6ba);
            font-family: "Gilroy-Medium";
            font-size: 10px;
            line-height: 18px;
            /* 128.571% */
          }
        }
      }

      .notifListMessage {
        display: flex;
        justify-content: center;
        align-items: center;

        .nomessagetxt {
          color: #fff;
          font-family: "Gilroy-Bold";
        }
      }
    }

    .rnotification_btn_section {
      display: flex;
      gap: 16px;
      padding: 14px 0 14px 16px;
      opacity: var(--Corner-radius-xs, 1);
      background: linear-gradient(180deg,
          rgba(20, 26, 31, 0) 0%,
          #141a1f 52.06%);

      .rnot_button {
        height: 48px;
        border-radius: var(--Spacing-2xl, 24px);
        background: var(--Background---grey-Grey---400---GB, #3b4d5e);
        border: none;
        width: 100%;
        max-width: 249px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        /* Button/$button-medium */
        font-family: "Inter-Medium";
        font-size: 14px;
        line-height: 24px;
        /* 171.429% */
      }
    }
  }

  .rusername {
    position: absolute;
    width: 100%;
    max-width: 380px;
    border: 2px solid #181f26;
    right: 15px;
    // height: 408px;
    z-index: 9;
    background-color: #141a1f;
    border-radius: 24px;
    padding: 16px;

    .ruser_info_sec {
      width: 100%;
      max-width: 390px;
      // height: 85px;
      padding: 12px;
      border-radius: var(--Spacing-2xl, 24px);
      border: var(--Corner-radius-xs, 1px) solid rgba(241, 254, 236, 0.1);
      opacity: var(--Corner-radius-xs, 1);
      background: linear-gradient(90deg,
          rgba(116, 243, 59, 0.1) 0.06%,
          rgba(116, 243, 59, 0) 99.94%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      // gap: 8px;

      .beef_img {
        // width: 100%;
        // max-width: 80px;
        height: 80px;
        flex: 0 0 20%;
        max-width: 20%;
        // position: relative;
        background-image: url("../../images/redo/hardcore_head.svg");
        background-repeat: no-repeat;
        background-position: center;
      }

      .hardCore_desc {
        display: flex;
        flex-direction: column;
        gap: 6px;
        max-width: 60%;
        flex: 0 0 60%;

        .title {
          color: #fff;
          font-family: "Gilroy-Medium";
          font-size: 16px;
          line-height: 16px;
          /* 80% */
        }

        .description {
          color: var(--Background---grey-Grey---200---GB, #92a6ba);
          font-family: "Gilroy-Medium";
          font-size: 12px;
          line-height: 16px;
          /* 114.286% */
        }
      }

      .switchSec {
        flex: 0 0 17%;
        max-width: 17%;

        .form-switch {
          .form-check-input {
            width: 50px;
            height: 22px;
            cursor: pointer;
            // background-image: url("../../images/redo/white_switch_round.png") !important;
            // background-size: auto !important;
            // background-position: 2px;
            // background: var(--Primary-Green---500---GB, #74f33b) !important;

            &:checked {
              cursor: pointer;
              background-color: #74f33b !important;
              border: #00a881 !important;
            }

            &:focus {
              box-shadow: none;
              border: none;
            }
          }
        }
        &.switchSecYellow{
          .form-check-input {
            &:checked {
              background-color: gold !important;
              border: gold !important;
            }
          }
        }
      }
    }

    .list_rprofile_items {
      margin-top: 16px;

      a {
        text-decoration: none;
      }

      .refer_friend {
        cursor: pointer;
        height: 58px;
        width: 100%;
        max-width: 390px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        // margin-top: 16px;
        &:hover {
          border-radius: var(--Spacing-lg, 16px);
          opacity: var(--Corner-radius-xs, 1);
          background: var(--Background---grey-Grey---500---GB, #181f26);
          // gap: 18px;
        }

        .rprofile_refer {
          color: #fff;
          font-family: "Gilroy-Medium";
          font-size: 16px;
          line-height: 16px;
          /* 100% */
        }
      }
    }
  }

  .language_dropdown {
    #dropdown-language {
      border: 1.5px solid var(--Background---grey-Grey---400---GB, #3b4d5e) !important;
      border-radius: 18px;
      background-color: #0E1216 !important;
      font-family: "Gilroy-SemiBold";
      height: 44px;

      &::after {
        display: none !important;
      }

      .menu_lang {
        .menu_lang_dd {
          width: 15px;
          height: 10px;
          margin-left: 10px;
        }
      }

    }
  }
}

.dropdown-menu {
  background-color: #141a1f !important;
  border: 2px solid #181f26 !important;
  margin-top: 17px;

  .dropdown-item {
    &:hover {
      background-color: transparent !important;
      color: #51E20E !important;
    }

    .lang_dd_each {
      img {
        margin-right: 5px;
        font-family: "Gilroy-Bold";
      }
    }
  }
}

._GB_RSidebar {
  width: 100%;
  max-width: 250px;
  opacity: var(--Corner-radius-xs, 1);
  background: var(--Background---grey-Grey---700---GB, #0E1216);

  @media only screen and (max-width: 1100px) {
    display: none;
  }

  .Sidebar_wrap {
    width: 100%;
    max-width: 250px;
    padding-top: 15px;
    padding: 0px 13px 20px;
    border-bottom: 1.5px solid rgba(59, 77, 94, 0.5);
    position: fixed;
    z-index: 999;

    .sidebar-border {
      margin-top: 20px;
      margin-bottom: 16px;
      border: 1.5px solid #3b4d5e;
      max-width: 220px;

      &.bottom {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      &.top {
        margin-top: 0px;
        margin-bottom: 15px;
      }
    }

    .headLeft {
      display: flex;
      gap: 30px;
      align-items: center;
      padding: 26px 0;

      img {
        &.arow {
          // border-radius: var(--Spacing-md, 12px);
          // border: 1.5px solid var(--Colors-Grayscale-Border-Disable, #DCDEE8);
          // backdrop-filter: blur(28px);
          // width: 24px;
          // height: 24px;
          cursor: pointer;
        }
      }
    }

    .sidebar_listItems {
      padding: 0;

      .sidebar_listItem {
        display: flex;
        align-items: center;
        padding: 0px 11px;
        cursor: pointer;

        &:hover {
          background-image: url("../../images/redo/sidebarhover.png");
          background-repeat: no-repeat;
          background-position: 100%;
          a{
            color: #fbfcfd;
          }
        }

        &.active {
          background-image: url("../../images/redo/sidebarhover.png");
          background-repeat: no-repeat;
          background-position: 100%;

          a {
            color: #fbfcfd;
          }

          img:first-child {
            animation: pulseAnimation 2s infinite;
            border-radius: 50%;
          }
        }

        &.disabled_item {
          opacity: 0.4 !important;

          &:hover {
            opacity: 0.4;
          }
        }

        a {
          color: #92a6ba;
          font-family: "Gilroy-Medium";
          font-family: 'Inter-Medium';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-decoration: none;
          padding: 10px 15px 10px 25px;
          max-width: 165px;
          width: 100%;
          @media only screen and (max-width: 767px){
            font-size: 14px;
            line-height: 22px;
          }
          &:hover {
            color: #fbfcfd;
          }
        }
      }

      .sub_menu {
        list-style: none;
        display: flex;
        flex-direction: column;

        .sub_menuItem {
          color: #92a6ba;
          font-family: "Gilroy-Medium";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          text-decoration: none;
          padding: 10px 10px 10px 20px;
          cursor: pointer;
          @media only screen and (max-width: 767px){
            font-size: 14px;
          }
          &:hover {
            color: #fbfcfd;
            border-radius: 16px;
            border: 1.5px solid rgba(201, 250, 178, 0.21);
            opacity: var(--Corner-radius-xs, 1);
            background: linear-gradient(270deg,
                rgba(116, 243, 59, 0.32) -50.59%,
                rgba(116, 243, 59, 0) 89.11%);
            border-bottom: none;
            border-top: none;
            border-left: none;
          }
          &.active{
            color: #fbfcfd;
            border-radius: 16px;
            border: 1.5px solid rgba(201, 250, 178, 0.21);
            opacity: var(--Corner-radius-xs, 1);
            background: linear-gradient(270deg,
                rgba(116, 243, 59, 0.32) -50.59%,
                rgba(116, 243, 59, 0) 89.11%);
            border-bottom: none;
            border-top: none;
            border-left: none;

          }

          &.diabled_item {
            opacity: 0.5;

            &:hover {
              opacity: 0.5;
            }
          }

        }

      }
      .tfa_slider{
        margin: 20px 0;
        .tfa_sliderItem {
          display: flex;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: var(--Spacing-xl, 20px);
          border: 1px solid rgba(59, 77, 94, 0.5);
          background: var(--Background---grey-Grey---500---GB, #181f26);
          min-height: 250px;
          .img_sec {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.twofaSlider{
              background-image: url("../../images/redo/2fa_slider_icon.png") !important;
              background-repeat: no-repeat;
              background-position: 50% 47%;
              background-size: auto;
            }
            &.profileSlider{
              background-image: url("../../images/redo/profile_slider_icon.png") !important;
              background-repeat: no-repeat;
              background-position: 50% 47%;
              background-size: auto;
            }
            &.gameSlider{
              background-image: url("../../images/redo/game_slider_cion.png") !important;
              background-repeat: no-repeat;
              background-position: 50% 47%;
              background-size: auto;
            }
            img {
              // height: 80px;
              width: 60px;
            }
  
          }
          .content_sec{
            color: #fff;
            font-size: 16px;
            line-height: 22px;
            font-family: "Gilroy-Bold";
            max-width: 200px;
            width: 100%;
            margin: 10px auto;
            text-align: center;
          }
          // img {
          //   height: 64px;
          //   width: 64px;
          // }
  
          .turn-on {
            color: #fff;
            text-align: center;
            font-family: "Gilroy-Bold";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            margin-bottom: 15px;
          }
  
          a {
            color: var(--Background---grey-Grey---950---GB, #020303);
            font-family: "Inter-Medium";
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            display: flex;
            padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
            justify-content: center;
            margin: 0px 12px 12px;
            align-items: center;
            gap: var(--Spacing-sm, 8px);
            align-self: stretch;
            border-radius: var(--Spacing-xl, 20px);
            background: var(--Primary-Green---50---GB, #f1feec);
            box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04),
              0px 24px 48px -8px rgba(0, 210, 160, 0.12);
            text-decoration: none;
  
            &:hover {
              opacity: 0.6;
            }
          }
        }
          .slick-slider{
            .slick-prev{
              opacity: 1;
              z-index: 99999;
              left: 15px !important;
              top: 85px !important;
              
            }
            
            .slick-next{
              right: 15px !important;
              top: 85px !important;
              &.slick-disabled{
                &::before{
                  opacity: 1;
                }
              }
            }
            .slick-dots{
              bottom : 10px;
              li{
                button{
                  &::before{
                    color: #fff;
                  }
                }
              }
            }
          }
      }
      .tfa-authenticator {
        display: flex;
        max-width: 220px;
        width: 100%;
        padding: var(--Spacing-md, 12px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.5);
        background: var(--Background---grey-Grey---500---GB, #181f26);
        background-image: url("../../images/redo/2fa-icon-svg.svg") !important;
        background-repeat: no-repeat;
        background-position: 54% -7%;
        background-size: 90px;
       
        img {
          // height: 80px;
          width: 64px;
          animation: rotateRight 90s linear infinite;
        }

        .turn-on {
          color: #fff;
          text-align: center;
          font-family: "Gilroy-Bold";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          margin-bottom: 12px;
        }

        a {
          color: var(--Background---grey-Grey---950---GB, #020303);
          font-family: 'Inter-Medium';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
          justify-content: center;
          align-items: center;
          gap: var(--Spacing-sm, 8px);
          align-self: stretch;
          border-radius: var(--Spacing-xl, 20px);
          background: var(--Primary-Green---50---GB, #f1feec);
          box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04),
            0px 24px 48px -8px rgba(0, 210, 160, 0.12);
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }
        }
      }
      .verify-sec {
        display: flex;
        max-width: 220px;
        width: 100%;
        padding: var(--Spacing-md, 12px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.5);
        background: var(--Background---grey-Grey---500---GB, #181f26);
        background-repeat: no-repeat;
        background-position: 50% -25%;

        img {
          width: 44px;
        }

        .turn-on {
          color: #fff;
          text-align: center;
          font-family: "Gilroy-Bold";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          margin-bottom: 12px;
          span{
            color: #FF3C35;
          }
        }

        a {
          color: var(--Background---grey-Grey---950---GB, #020303);
          font-family: 'Inter-Medium';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
          justify-content: center;
          align-items: center;
          gap: var(--Spacing-sm, 8px);
          align-self: stretch;
          border-radius: var(--Spacing-xl, 20px);
          background: var(--Primary-Green---50---GB, #f1feec);
          box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04),
            0px 24px 48px -8px rgba(0, 210, 160, 0.12);
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

._GB_RMobSidebar {
  max-width: 110px;
  width: 100%;
  opacity: var(--Corner-radius-xs, 1);
  background: var(--Background---grey-Grey---700---GB, #0E1216);

  // @media only screen and (max-width: 1100px) {
  //   display: none;
  // }

  .sidebar-border {
    margin-top: 20px;
    margin-bottom: 16px;
    border: 1.5px solid #3b4d5e;
    width: 100%;
    max-width: 220px;

    &.bottom {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    &.top {
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }

  .Sidebar_wrap {
    padding: 0px 15px;
    position: fixed;
    z-index: 999;

    .headLeft {
      display: flex;
      align-items: center;
      padding: 15px 0px;
      position: relative;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      a{
          max-width: 50px;
      }

      .arow {
        cursor: pointer;
        // position: absolute;
        // right: -31px;
      }
    }

    .sidebarmob_listItems {
      display: flex;
      flex-direction: column;
      align-items: center;

      .sidebar_listItem {
        display: flex;
        align-items: center;
        padding: 0px 15px;
        margin-bottom: 5px;
        cursor: pointer;

        &:hover {
          background-image: url("../../images/redo/mob_activebg.png");
          background-repeat: no-repeat;
          background-position: 35% 60%;
          color: #fbfcfd !important;
        
        }

        &.active {
          background-image: url("../../images/redo/mob_activebg.png");
          background-repeat: no-repeat;
          background-position: 35% 60%;

          a {
            color: #fbfcfd;

            img {
              animation: pulseAnimation 2s infinite;
              border-radius: 50%;

            }

          }
        }
        &.disabled_item {
          a{
            opacity: 0.5 !important;
            &:hover {
              opacity: 0.5 !important;
            }
          } 
        }
        

        a {
          color: #92a6ba;
          font-family: "Gilroy-Medium";
          font-family: 'Inter-Medium';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-decoration: none;
          padding: 12px 3px 12px 0px;

          &:hover {
            color: #fbfcfd;
          }
        }
      
      }

      .sub_menu {
        list-style: none;

        .sub_menuItem {
          color: #92a6ba;
          font-family: "Gilroy-Medium";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          cursor: pointer;
          padding: 15px 15px 15px 25px;

          &:hover {
            color: #fbfcfd;
            border-radius: 28px;
            border: 1.5px solid rgba(201, 250, 178, 0.21);
            opacity: var(--Corner-radius-xs, 1);
            background: linear-gradient(270deg,
                rgba(116, 243, 59, 0.32) -50.59%,
                rgba(116, 243, 59, 0) 89.11%);
          }
        }
      }

      .tfa-authenticator {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.5);
        background: var(--Background---grey-Grey---500---GB, #181f26);

        .img_sec {
          background-image: url("../../images/redo/2fa-mobile-svg.svg") !important;
          background-repeat: no-repeat;
          background-position: 37% 29%;
          background-size: 90px;
          padding: 20px;

          img {
            // height: 80px;
            width: 40px;
            animation: rotateRight 90s linear infinite;
          }

        }

        // img {
        //   height: 64px;
        //   width: 64px;
        // }

        .turn-on {
          color: #fff;
          text-align: center;
          font-family: "Gilroy-Bold";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-bottom: 15px;
        }

        a {
          color: var(--Background---grey-Grey---950---GB, #020303);
          font-family: "Inter-Medium";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
          justify-content: center;
          margin: 0px 12px 12px;
          align-items: center;
          gap: var(--Spacing-sm, 8px);
          align-self: stretch;
          border-radius: var(--Spacing-xl, 20px);
          background: var(--Primary-Green---50---GB, #f1feec);
          box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04),
            0px 24px 48px -8px rgba(0, 210, 160, 0.12);
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }
        }
      }
      .verify-sec {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.5);
        background: var(--Background---grey-Grey---500---GB, #181f26);


        .img_sec {
          padding: 10px;
          img {
            // height: 80px;
            width: 40px;
            
          }

        }

        // img {
        //   height: 64px;
        //   width: 64px;
        // }

        .turn-on {
          color: #fff;
          text-align: center;
          font-family: "Gilroy-Bold";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin-bottom: 15px;
        }

        a {
          color: var(--Background---grey-Grey---950---GB, #020303);
          font-family: "Inter-Medium";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
          justify-content: center;
          margin: 0px 12px 12px;
          align-items: center;
          gap: var(--Spacing-sm, 8px);
          align-self: stretch;
          border-radius: var(--Spacing-xl, 20px);
          background: var(--Primary-Green---50---GB, #f1feec);
          box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04),
            0px 24px 48px -8px rgba(0, 210, 160, 0.12);
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
  .tfa_slider{
    margin: 20px 0;
    max-width: 90%;
    width: 100%;
    .tfa_sliderItem {
      height: 135px;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: var(--Spacing-xl, 20px);
      border: 1px solid rgba(59, 77, 94, 0.5);
      background: var(--Background---grey-Grey---500---GB, #181f26);
      padding: 10px;
      .img_sec {
        // padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.twofaSlider{
          background-image: url("../../images/redo/2fa_slider_icon.png") !important;
          background-repeat: no-repeat;
          background-position: 50% 47%;
          background-size: auto;
        }
        &.profileSlider{
          background-image: url("../../images/redo/profile_slider_icon.png") !important;
          background-repeat: no-repeat;
          background-position: 50% 47%;
          background-size: auto;
        }
        &.gameSlider{
          background-image: url("../../images/redo/game_slider_cion.png") !important;
          background-repeat: no-repeat;
          background-position: 50% 47%;
          background-size: auto;
        }
        img {
          // height: 80px;
          width: 60px;
        }

      }
      .content_sec{
        color: #fff;
        font-size: 16px;
        line-height: 22px;
        font-family: "Gilroy-Bold";
        max-width: 200px;
        width: 100%;
        margin: 10px auto;
        text-align: center; 
        
      }
      // img {
      //   height: 64px;
      //   width: 64px;
      // }

      .turn-on {
        color: #fff;
        text-align: center;
        font-family: "Gilroy-Bold";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 15px;
      }

      a {
        color: var(--Background---grey-Grey---950---GB, #020303);
        font-family: "Inter-Medium";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
        justify-content: center;
        margin: 10px 0 0;
        align-items: center;
        gap: var(--Spacing-sm, 8px);
        align-self: stretch;
        border-radius: var(--Spacing-xl, 20px);
        background: var(--Primary-Green---50---GB, #f1feec);
        box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04),
          0px 24px 48px -8px rgba(0, 210, 160, 0.12);
        text-decoration: none;

        &:hover {
          opacity: 0.6;
        }
      }
    }
      .slick-slider{
        max-width: 75px;
        margin: auto;
        .slick-prev{
          &::before{
            display: none;
          }
          
        }
        
        .slick-next{
         display: none;
          &::before{
            display: none;
          }
      
        }
        .slick-dots{
          bottom: 0;
          margin: auto;
          left: -4px;
          li{
            width: 5px;
            button{
              &::before{
                color: #fff;
              }
            }
          }
        }
      }
  }
}

._GB_RHeader {
  background-color: rgba(4, 5, 6, 0.4);

  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.common_Inner {
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    width: 100%;
  }

  @media only screen and (min-width: 1025px) {
    max-width: 90%;
    width: 100%;
    margin: auto;
  }

  @media only screen and (min-width: 1600px) {
    max-width: 1276px;
    width: 100%;
    margin: auto;
  }

  &.common_inner_mobile {
    @media only screen and (max-width: 490px) {
      // max-width: 330px;
      margin: auto;
    }
  }
}

#redoPopup {
  .tooltip-inner {
    color: #fff;
    font-size: 12px;
    font-family: "Gilroy-Regular";
    //background: rgba(116, 243, 59, 0.2);
    background: rgba(90, 150, 80, 1);
    backdrop-filter: blur(32px);
  }

  .tooltip-arrow {
    &::before {
      border-top-color: rgba(116, 243, 59, 0.2) !important;
      top: 0 !important;
    }
  }
}



.addGameModal {
  padding-left: 0 !important;
  backdrop-filter: brightness(40%);

  .modal-dialog {
    @media only screen and (min-width: 1366px) {
      max-width: 691px;
    }

  }

  .modal-content {
    // border-radius: var(--Spacing-xl, 20px);
    // border: 1px solid rgba(59, 77, 94, 0.50);
    // opacity: var(--Corner-radius-xs, 1);
    // background: var(--Background---grey-Grey---500---GB, #181F26);
    padding-top: 10px;
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.50);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---600---GB, #141A1F);

    .modal-header {
      border: none !important;

      .btn-close {
        box-shadow: none;
        outline: none;
        background: url("../../images/redo/bet-close-circle.png") no-repeat;
        opacity: 1;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .modal-body {
      padding-top: 0;
    }

    .betPopHeadImg {
      display: flex;
      justify-content: center;
      padding: 20px;
    }

    .betPopHeadTitle {
      color: #FFF;
      text-align: center;
      font-family: "Gilroy-Medium";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .betPopHeadTag {
      color: var(--Background---grey-Grey---200---GB, #92A6BA);
      text-align: center;
      font-family: "Gilroy-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding: 15px;
    }

    .betPopContent {
      // border-radius: var(--Spacing-lg, 16px);
      // opacity: var(--Corner-radius-xs, 1);
      // background: var(--Background---grey-Grey---700---GB, #0E1216);
      padding: 15px;
      // margin: 20px 10px;

      @media only screen and (max-width: 767px) {
        padding: 15px 0px;
      }


      .betPopContentInner {
        .betPopContentItem {
          padding: 10px 10px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .betPopContentTag {
            color: var(--Background---grey-Grey---200---GB, #92A6BA);
            font-family: "Gilroy-Medium";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }

          .betPopContentTagValue {
            color: var(--Background---grey-Grey---50---GB, #E3E8ED);
            font-family: "Gilroy-SemiBold";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }

        .betPopList {
          li {
            color: var(--Background---grey-Grey---100---GB, #C7D1DB);
            font-family: "Gilroy-Medium";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            padding: 10px;
            border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
          }
        }
      }

      .gameSearch {
        input {
          width: 100%;
          box-shadow: none;
          border: none;
          outline: none;
          border-radius: var(--Spacing-2xl, 24px);
          background: var(--Background---grey-Grey---500---GB, #181F26);
          color: #fff;
          font-family: Gilroy-Medium;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          padding: var(--Spacing-lg, 16px);

          &::placeholder {
            color: var(--Background---grey-Grey---300---GB, #5D7893);
          }
        }
      }

      .addByCWS {
        margin-top: 10px;
        text-align: center;
      }

      .tpnlist {
        padding: 15px 20px;
        width: 100%;
        box-shadow: none;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: var(--Spacing-2xl, 24px);
        background: var(--Background---grey-Grey---500---GB, #181F26);
        color: var(--Background---grey-Grey---300---GB, #5D7893);
        font-family: "Gilroy-Medium";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }

        option {
          margin: 10px 0;

          &:hover {
            color: #51E20E !important;
          }
        }
      }

      .addByCWS .connect_steam {
        max-width: 280px;
        margin: auto;
        margin-top: 10px;
        font-family: "Poppins-Medium";
        font-size: 16px;
        cursor: pointer;
        border-radius: var(--Spacing-2xl, 24px);
        background: var(--Background---grey-Grey---500---GB, #181F26);
        color: var(--Background---grey-Grey---300---GB, #5D7893);
        padding: 15px 20px;

        @media only screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      .rdBetgames {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
        align-items: center;
        margin-bottom: 15px;

        // @media only screen and (max-width: 767px) {
        //   justify-content: normal;
        // }

        .rdBetgame {
          // flex: 0 0 20%;
          // max-width: 20%;
          text-align: center;
          padding: 8px;
          cursor: pointer;

          .whiteImg {
            display: block;
          }

          .blackImg {
            display: none;
          }

          &:hover,
          &.active {
            //background: rgba(116, 243, 59, 0.5);
            border-radius: var(--Spacing-lg, 8px);
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Primary-Green---600---GB, #51E20E);
            box-shadow: 0px 2px 4px 0px rgba(116, 243, 59, 0.04), 0px 24px 48px -8px rgba(116, 243, 59, 0.12);

            .whiteImg {
              display: none;
            }

            .blackImg {
              display: block;
            }


          }

        }
      }


    }

    .gamechoosetxt {
      padding: 0px 24px 24px;
      padding-left: 5px;
      font-family: "Gilroy-Medium";
      color: var(--Background---grey-Grey---300---GB, #5D7893);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
      .guide-tab{
        cursor: pointer;
        &:hover{
          opacity: 0.5;
        }
      }
    }

    .gameAddResponse {
      font-family: "Gilroy-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 16px;
      margin-bottom: 8px;
      padding: 0 5px;
      text-align: center;
      &.failed {
        color: var(--Color-Error-500, #FF3C35);
        margin-bottom: 24px;

        a {
          font-family: "Gilroy-Bold";
          color: var(--Color-Error-500, #FF3C35);
        }
      }

      &.success {
        margin-bottom: 24px;
        color: var(--Color-Success-500, #2DC656);
      }
    }

    .betPopDisclaimer {
      color: var(--Background---grey-Grey---200---GB, #92A6BA);
      text-align: center;
      font-family: Gilroy-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      padding: 16px 30px 10px;
    }

    .betPopFooter {
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 15px;

      @media only screen and (max-width: 767px) {
        flex-direction: row;
        padding: 10px 0px;
        gap: 8px;
      }

      .betPopNextBtn {
        padding: var(--Spacing-lg, 11px) var(--Spacing-2xl, 24px);
        // border-radius: var(--Spacing-xl, 20px);
        // background: var(--Primary-Green---50---GB, #F1FEEC);
        // box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.04), 0px 24px 48px -8px rgba(241, 254, 236, 0.12);

        border-radius: var(--Spacing-2xl, 24px);
        border: 1.5px solid #FFF;
        background: var(--Primary-Green---50---GB, #F1FEEC);
        box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.02), 0px 16px 56px -4px rgba(241, 254, 236, 0.08);

        width: 100%;
        color: var(--Background---grey-Grey---950---GB, #020303);
        font-family: "Gilroy-SemiBold";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;

        //border: none;
        &.disableIt {
          opacity: 0.5;
          cursor: default;
        }

        &:hover {
          opacity: 0.5;
        }

        &.btnAjaxLoading {
          .text-light {
            color: #181f26 !important;
            margin-right: 5px !important;

            @media only screen and (max-width: 767px) {
              // width: 15px;
            }

          }
        }

        @media only screen and (max-width: 767px) {
          padding: 12px 20px;
          border-radius: 16px;

        }
      }

      .betPopCloseBtn {
        padding: 11px 24px;
        //border-radius: var(--Spacing-2xl, 20px);
        opacity: var(--Corner-radius-xs, 1);
        //background: var(--Background---grey-Grey---400---GB, #3B4D5E);

        border-radius: var(--Spacing-2xl, 24px);
        background: var(--Background---grey-Grey---400---GB, #3B4D5E);

        width: 100%;
        color: #FFF;
        font-family: Gilroy-Medium;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        border: none;

        &.disableIt {
          opacity: 0.5;
          cursor: default;
        }

        &:hover {
          opacity: 0.5;
        }

        @media only screen and (max-width: 767px) {
          padding: 12px 20px;
          border-radius: 16px;
        }

      }

      &.addGameFooterFlex {

        // margin-top: 25px;
        @media only screen and (min-width: 768px) {
          flex-direction: row !important;
        }
      }
    }
  }
}

.user_rdevice_menu {
  height: 83px;
  display: flex;
  align-items: center;
  padding: 0 15px;

  @media only screen and (min-width: 1101px) {
    display: none;
  }

  .mobile-main {
    width: 100%;

    .tooth-device-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menu-left {
        gap: 10px;
        .search-menu{
          
          .dropdown{
            padding:2px;
            &.show{
         
              border:1px solid #74f33b;
              border-radius: 10px;
             
            button{
              &:focus-visible{
                outline:none;
                box-shadow: none;
                border:none !important;
              }
            }
          }

          }
          
        }
        #dropdown-search-help {
          background: no-repeat;
          border: none;
          padding: 0;
          display: flex;
          align-items: center;
          box-shadow: none !important;
          &:focus{
            outline:none;
            box-shadow: none;
            border:none !important;

          }
          &:focus-visible{
            outline:none;
            box-shadow: none;
            border:none !important;
          }

          &:after {
            display: none;
          }
        }
      }

      .menu-middle {
        max-width: 57%;
        flex: 0 0 57%;
        img{
          @media only screen and (max-width: 1024px) {
          max-width: 155px !important;
          }
        }
      }

      .menu-right {
        gap: 10px;

        .notification_menu {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          position: relative;
          cursor: pointer;
          &.active{
            border:1px solid #74f33b;
            border-radius: 10px;
          }

          .dot {
            position: absolute;
            top: 32px;
            left: 31px;

            @media only screen and (max-width: 1024px) {
              top: 25px;
              left: 15px;
            }
          }
        }

        .rnotification_single {
          position: absolute;
        }

        .wallet_menu {
          &.active{
            border:1px solid #74f33b;
            border-radius: 10px;
          }
          .wallet{
            img{
              width: 29px;
            }
          }

          .dropdown-toggle::after {
            display: none;
          }

          #dropdown-redo-curr-wallet {
            background: none;
            padding: 0;
            border: none;
            box-shadow: none;
          }

          .redo-curr-wallet-dropdown {
            width: 300px;
            border: 1px solid rgba(59, 77, 94, 0.5);
            background: var(--Background---grey-Grey---500---GB, #101418);

            .redo-curr-wallet-wrap {

              .redo-curr-wallet-balance {
                // background-image: url("../../images/redo/graph_img.png");
                // background-repeat: no-repeat;
                // background-position: center;
                // background-size: 100% 100%;
                padding: 15px;
                margin: 15px 0;

                .balanceSec {
                  color: #5D7893;
                  font-size: 13px;
                  line-height: 18px;
                  font-family: "Gilroy-Medium";
                  margin-bottom: 10px;

                }

                .balanceAmount {
                  color: #FFF;
                  font-family: "Gilroy-SemiBold";
                  font-size: 24px;
                  font-style: normal;
                  line-height: 29px;
                  cursor: pointer;
                }

              }


              .redo-curr-wallet-coins {
                .redo-curr-wallet-coins-inner {
                  .redo-curr-wallet-coins-items {
                    background: #181F26;
                    padding: 12px 20px;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: var(--Spacing-xl, 11px);
                    border: 1.5px solid #3b4d5e;
                    opacity: var(--Corner-radius-xs, 1);
                    background: var(--Background---grey-Grey---500---GB, #181F26);

                    .item-token-type {
                      display: flex;
                      align-items: center;
                      gap: 10px;

                      .coin-img {
                        width: 24px;
                        display: flex;
                        align-items: center;
                      }

                      .coin-name {
                        color: #fff;
                        font-size: 14px;
                        line-height: 24px;
                        font-family: "Gilroy-Medium";
                        display: flex;
                        align-items: center;

                      }
                    }

                    .item-token-amount {
                      display: flex;
                      align-items: center;
                      gap: 10px;

                      .coin-amount {
                        color: #fff;
                        font-size: 14px;
                        line-height: 24px;
                        font-family: "Gilroy-Medium";
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                      }

                      .coin-sel {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }

              .redo-mobile-wallet-msg {
                p {

                  color: #fff;
                  font-size: 14px;
                  line-height: 24px;
                  font-family: "Gilroy-Medium";
                  display: flex;
                  align-items: center;
                  margin-top: 20px;
                  padding: 7px 12px;
                  background: #000;
                  border-radius: 12px;

                }
              }
            }

          }



        }
      }

      .item-logo {
        padding: 0;

        #_GB_LogoSectionB {
          img {
            max-width: 114px !important;
          }
        }
      }

      .item-burger {
        .menu-burger {
          width: 24px;
          cursor: pointer;

          span {
            display: block;
            background: #fff;
            height: 3px;
            border-radius: 10px;

            &.middle {
              margin: 5px 0;
            }
          }
        }
      }
    }
  }

  .search {
    max-width: 300px;
    width: 100%;
    padding: 16px;

    .searchInner {
      padding: 0 20px;
      display: flex;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      border-radius: 20px;
      background: #181f26;
      width: 100%;

      input {
        font-family: "Inter-Medium";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        background: #181f26;
        border: 0 !important;
        outline: none;
        box-shadow: none;
        color: var(--Colors-Grayscale-Text-icon-Subtitle, #767a87);
        height: 44px;
        width: 100%;

        &:-webkit-input-placeholder {
          font-family: "Inter-Medium";
          color: #767a87;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .dropdown {
        display: flex;
        align-items: center;
        justify-content: center;

        #dropdown-search-help {
          background: no-repeat;
          border: none;
          padding: 0;
          display: flex;
          align-items: center;

          &:after {
            display: none;
          }
        }
      }
    }

  }

  .searchResult {
    background-color: #181F26;
    max-width: 265px;
    width: 100%;
    border-radius: 20px;
    min-height: 105px;
    padding: 15px;
    position: absolute;
    top: 140px;
    z-index: 999;

    ul {
      padding-left: 0px !important;

      li {
        list-style: none;
        color: #c7d1db;
        font-family: "Gilroy-Medium";
        font-size: 12px;
        line-height: 18px;
      }
    }

    .searchAjaxLoader {
      font-size: 16px;
      line-height: 24px;
      color: #5D7893;
      font-family: "Gilroy-SemiBold";
      width: 100%;
      display: flex;
      gap: 10px;
      height: 100%;
      justify-content: center;
      align-items: center;
      // border-radius: var(--Spacing-xl, 20px);
      // border: 1.5px solid rgba(255, 255, 255, 0.10);
      opacity: var(--Corner-radius-xs, 1);
      background: var(--Background---grey-Grey---500---GB, #181F26);
      height: 200px;
    }


    .scrollIt {
      height: 310px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #181F26;
        border-radius: 9px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #74F33B;
        border-radius: 9px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #74F33B;
      }
    }
  }

}

#redoMobileMenu {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  padding-top: 40px;

  background-image: url("../../images/redo/dashboard_bg.png");
  background-repeat: no-repeat;
  // background-position: 100% 98%;
  background-size: cover;

  &.sidebar {
    height: 100%;
    /* 100% Full-height */
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 0;
    left: 0;
    max-width: 300px;
    overflow-x: hidden;
    /* Disable horizontal scroll */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidebar */
    // background: #181818;
    // background: #00C714;
    z-index: 1000;
    // background: #0E1216;
    background: var(--Background---grey-Grey---700---GB, #0E1216);
    // background: #181818;

    .closebtn {
      position: absolute;
      top: 25px;
      right: 15px;
      font-size: 35px;
      margin-left: 50px;
      color: #979797 !important;
      text-decoration: none;

      @media only screen and (max-width: 1100px) {
        top: -6px;
      }
    }

    // .menu-logo {
    //   padding: 0 15px;
    // }
    .redoMobileMenuTop {
      padding: 0px 16px;

      #dropdown-language {
        width: 70px;
        height: 38px;
        padding: 5px;
        border: 1.5px solid #3b4d5e !important;
      }
    }

    .mobile-menu-list {
      .addBtn {
        padding: 10px 16px;
        margin: 0px 20px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 265px;
        width: 100%;
        border-radius: 20px;
        background-color: #51E20E;
        border: 1px solid #51E20E;

        &:hover {
          opacity: 0.5;
        }
      }

      .menu-item {
        padding: 0px 16px 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.username {
          margin: 16px 0px;

          .userprofile {
            display: flex;
            align-items: center;
            gap: 8px;

          }

          a {
            padding: 0px;
          }

          img {
            &.profimage {
              width: 40px;
              height: 40px;
              border-radius: var(--Spacing-xl, 18px);
              border: 1.5px solid rgba(116, 243, 59, 0.5);
              // background: url(<path-to-image>), lightgray 50% / cover no-repeat;
              padding: 5px;
              object-fit: contain;
              margin: 0px;
            }

            &.arrow_img {
              cursor: pointer;
            }
          }

          .namesec {
            color: #fff;
            font-family: "Gilroy-SemiBold";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            span {
              color: var(--Background---grey-Grey---100---GB, #c7d1db);
              font-family: "Gilroy-SemiBold";
              font-size: 11px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
            }
          }
        }

        &.hardcore-mode {
          max-width: 280px;
          background: linear-gradient(90deg, rgba(116, 243, 59, .1) .06%, rgba(116, 243, 59, 0) 99.94%);
          border: 1px solid rgba(241, 254, 236, .1);
          border: var(--Corner-radius-xs, 1px) solid rgba(241, 254, 236, .1);
          border-radius: 24px;
          margin: 0px 16px;
          justify-content: center;
          padding: 12px;
          gap: 6px;

          .mode-sec {
            flex-direction: column;
            padding: 0px
          }

          .mode_type {
            font-size: 16px;
            line-height: 16px;
            text-decoration: none;
            font-family: "Gilroy-Medium";
            //text-align: center;
          }

          .mode_alert {
            font-size: 12px;
            line-height: 16px;
            text-decoration: none;
            font-family: "Gilroy-Medium";
            padding: 0px;
            //text-align: center;

          }

          .form-switch .form-check-input {
            width: 40px;
            height: 20px;
            margin-left: 0px;

            &:checked {
              cursor: pointer;
              background-color: #74f33b !important;
              border: #00a881 !important;
            }

            &:focus {
              box-shadow: none;
              border: none;
            }
          }

          .switchSecYellow{
            .form-switch .form-check-input {
  
                &:checked {
                  background-color: gold !important;
                    border: gold !important;
                }

              }
          }

          .form-switch {
            padding-left: 0px !important;
          }

          img {
            height: 48px;
            width: 48px;
          }

        }


        a {
          // font-size: 16px;
          // line-height: 24px;
          // text-decoration: none;
          // font-family: 'Inter-SemiBold';
          color: #92a6ba;
          font-family: 'Inter-Medium';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-decoration: none;
          padding: 12px 16px 12px 16px;

          img {
            margin-right: 10px;
          }

          &.active {
            background-image: url("../../images/redo/sidebarhover.png");
            background-repeat: no-repeat;
            background-position: -38px;
            color: #fff;
            min-width: 236px;

            img {
              margin-right: 25px;
              animation: pulseAnimation 2s infinite;
              border-radius: 50%;
            }
          }

          &.disabled {
            opacity: 0.5;

            img {
              opacity: 0.5;
            }
          }
        }

        .mode-sec {
          padding-left: 10px;

          img {
            width: 30px;
            margin-right: 5px;
          }

          .mode_type {
            // font-size: 16px;
            // line-height: 24px;
            // color: #92a6ba;
            // text-decoration: none;
            // font-family: 'Inter-SemiBold';
                color: #fff;
                  font-family: "Gilroy-Medium";
                  font-size: 14px;
                  line-height: 20px;
            //font-size: 14px;
            font-style: normal;
            font-weight: 400;
           // line-height: 24px;
            text-decoration: none;
          }

          .mode_switch {
            .hardcore_cb {
              margin-left: 5px;
            }

          }

          .form-switch {
            .form-check-input {
              cursor: pointer;

              &:checked {
                cursor: pointer;
                background-color: #74f33b !important;
                border: #00a881 !important;
              }

              &:focus {
                box-shadow: none;
                border: none;
              }
            }
          }
        }

        .mode_alert {
          font-size: 12px;
          line-height: 18px;
          color: #92a6ba;
          padding-left: 20px;
          font-family: 'Inter-Medium';
        }
      }

      .sub_menu {
        list-style: none;
        display: flex;
        flex-direction: column;

        .sub_menuItem {
          color: #92a6ba;
          font-family: "Gilroy-Medium";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          text-decoration: none;
          padding: 10px 10px 10px 31px;
          cursor: pointer;

          &:hover {
            color: #fbfcfd;
            border-radius: 16px;
            border: 1.5px solid rgba(201, 250, 178, 0.21);
            opacity: var(--Corner-radius-xs, 1);
            background: linear-gradient(270deg,
                rgba(116, 243, 59, 0.32) -50.59%,
                rgba(116, 243, 59, 0) 89.11%);
            border-bottom: none;
            border-top: none;
            border-left: none;
          }
          &.active{
            color: #fbfcfd;
            border-radius: 16px;
            border: 1.5px solid rgba(201, 250, 178, 0.21);
            opacity: var(--Corner-radius-xs, 1);
            background: linear-gradient(270deg,
                rgba(116, 243, 59, 0.32) -50.59%,
                rgba(116, 243, 59, 0) 89.11%);
            border-bottom: none;
            border-top: none;
            border-left: none;

          }

          &.diabled_item {
            opacity: 0.5;

            &:hover {
              opacity: 0.5;
            }
          }

        }

      }

      .tfa-authenticator {
        padding: 0px 16px 0 16px;
        display: flex;
        max-width: 220px;
        width: 100%;
        padding: var(--Spacing-md, 12px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.5);
        background: var(--Background---grey-Grey---500---GB, #181f26);
        background-image: url("../../images/redo/2fa-icon-svg.svg") !important;
        background-repeat: no-repeat;
        background-position: 54% -7%;
        background-size: 90px;

        img {
          // height: 80px;
          width: 64px;
          animation: rotateRight 90s linear infinite;
        }

        .turn-on {
          color: #fff;
          text-align: center;
          font-family: "Gilroy-Bold";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          margin-bottom: 12px;
        }

        a {
          color: var(--Background---grey-Grey---950---GB, #020303);
          font-family: 'Inter-Medium';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
          justify-content: center;
          align-items: center;
          gap: var(--Spacing-sm, 8px);
          align-self: stretch;
          border-radius: var(--Spacing-xl, 20px);
          background: var(--Primary-Green---50---GB, #f1feec);
          box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04),
            0px 24px 48px -8px rgba(0, 210, 160, 0.12);
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }
        }
      }

      .mobile-verify {
        padding: 0px 16px 0 16px;
        display: flex;
        max-width: 220px;
        width: 100%;
        padding: var(--Spacing-md, 12px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.5);
        background: var(--Background---grey-Grey---500---GB, #181f26);
        margin-top: 15px;
        // background-image: url("../../images/redo/warning.svg") !important;
        // background-repeat: no-repeat;
        // background-position: 50% -25%;

        img {
          // height: 80px;
          width: 40px;
          // animation: rotateRight 90s linear infinite;
        }

        .turn-on {
          color: #fff;
          text-align: center;
          font-family: "Gilroy-Bold";
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          margin-bottom: 12px;

          span {
            color: #ff3c35;
          }
        }

        a {
          color: var(--Background---grey-Grey---950---GB, #020303);
          font-family: 'Inter-Medium';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
          justify-content: center;
          align-items: center;
          gap: var(--Spacing-sm, 8px);
          align-self: stretch;
          border-radius: var(--Spacing-xl, 20px);
          background: var(--Primary-Green---50---GB, #f1feec);
          box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04),
            0px 24px 48px -8px rgba(0, 210, 160, 0.12);
          text-decoration: none;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }

}

.rnotification {
  position: absolute;
  width: 100%;
  max-width: 511px;
  border-left: 2px solid #181f26;
  right: 0;
  // height: 100vh;
  z-index: 9;
  background-color: #141a1f;

  .rnotification_section {
    background: linear-gradient(90deg,
        rgba(24, 31, 38, 0.5) 0%,
        rgba(24, 31, 38, 0) 100%),
      linear-gradient(0deg, #181f26, #181f26);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rnotification_title_sec {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      .rnotification_number {
        border-radius: var(--Spacing-md, 10px);
        border: var(--Corner-radius-xs, 1px) solid rgba(255, 255, 255, 0.5);
        background: var(--Color-Error-500, #ff3c35);
        height: 24px;
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 14px;
          line-height: 18px;
          /* 100% */
        }
      }

      .rnotification_title {
        font-family: "Gilroy-Medium";
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
      }
    }

    .rnotification_close {
      max-width: 32px;
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(59, 77, 94, 0.5);
      border-radius: var(--Spacing-lg, 10px);
      background: var(--Background---grey-Grey---500---GB, #181f26);
      cursor: pointer;
    }
  }

  .rnotification_list_sec {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 568px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #181f26;
      border-radius: 9px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #74f33b;
      border-radius: 9px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #74f33b;
    }

    .rnotification_single {
      border-radius: var(--Spacing-2xl, 24px);
      display: flex;
      padding: 14px 12px;
      gap: 15px;
      align-self: stretch;

      .img_section {
        display: flex;
        max-width: 56px;
        width: 100%;
        height: 56px;
        justify-content: center;
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
      }

      .rwarning_img {
        border: 1.5px solid rgba(255, 191, 0, 0.6);
        background: linear-gradient(180deg,
            rgba(255, 191, 0, 0) 0%,
            rgba(255, 191, 0, 0.12) 100%);
      }

      .close_img {
        border: 2px solid rgba(255, 60, 53, 0.6);
        background: linear-gradient(180deg,
            rgba(255, 60, 53, 0) 0%,
            rgba(255, 60, 53, 0.12) 100%);
      }

      .coin_img {
        border: 2px solid rgba(45, 198, 86, 0.6);
        background: linear-gradient(180deg,
            rgba(45, 198, 86, 0) 0%,
            rgba(45, 198, 86, 0.12) 100%);
      }
    }

    .warning {
      border: 1.5px solid rgba(255, 191, 0, 0.6);
      background: linear-gradient(90deg,
          rgba(255, 191, 0, 0.1) 0.1%,
          rgba(255, 191, 0, 0) 99.91%);
      backdrop-filter: blur(32px);
      // -webkit-backdrop-filter: blur(32px);
    }

    .failed {
      border: 1.5px solid rgba(255, 60, 53, 0.6);
      background: linear-gradient(90deg,
          rgba(255, 60, 53, 0.1) 0.1%,
          rgba(255, 60, 53, 0) 99.91%);
    }

    .success {
      border: 1.5px solid rgba(45, 198, 86, 0.6);
      background: linear-gradient(90deg,
          rgba(45, 198, 86, 0.1) 0.1%,
          rgba(45, 198, 86, 0) 99.91%);
    }

    .description_sec {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .rnotification_desc {
        overflow: hidden;
        color: #fff !important;
        text-overflow: ellipsis;
        font-family: "Gilroy-Medium";
        font-size: 12px;
        line-height: 16px;

        a {
          text-decoration: none;
          color: #fff !important;
        }

        /* 112.5% */
      }

      .rnotification_days {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .not_days {
          color: var(--Background---grey-Grey---200---GB, #92a6ba);
          font-family: "Gilroy-Medium";
          font-size: 10px;
          line-height: 18px;
          /* 128.571% */
        }
      }
    }

    .notifListMessage {
      display: flex;
      justify-content: center;
      align-items: center;

      .nomessagetxt {
        color: #fff;
        font-family: "Gilroy-Bold";
      }
    }
  }

  .rnotification_btn_section {
    display: flex;
    gap: 16px;
    padding: 14px 0 14px 16px;
    opacity: var(--Corner-radius-xs, 1);
    background: linear-gradient(180deg,
        rgba(20, 26, 31, 0) 0%,
        #141a1f 52.06%);

    @media only screen and (max-width: 575px) {
      padding: 14px 16px;
    }

    .rnot_button {
      height: 48px;
      border-radius: var(--Spacing-2xl, 24px);
      background: var(--Background---grey-Grey---400---GB, #3b4d5e);
      border: none;
      width: 100%;
      max-width: 249px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      /* Button/$button-medium */
      font-family: "Inter-Medium";
      font-size: 14px;
      line-height: 24px;

      /* 171.429% */
      @media only screen and (max-width: 575px) {
        max-width: 160px;
        gap: 8px;
      }
    }
  }
}

#dropdown-language {
  border: solid 3px #2d2d2d !important;

  &::after {
    display: none !important;
  }

  .menu_lang {
    .menu_lang_dd {
      width: 15px;
      height: 10px;
      margin-left: 10px;
    }
  }
}

.mobNewsFeed {
  display: none !important;

  @media only screen and (max-width: 1024px) {
    display: block !important;
    height: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
    position: relative;
    z-index: 1 !important;
    background-color: #51E20E;
  }

  .newsFeedInner {
    .contentSec {
      cursor: pointer;
      font-size: 14px;
      line-height: 18px;
      font-family: "Gilroy-SemiBold";
      color: #040506;
      text-align: center;
      z-index: 1;

      @media only screen and (max-width: 1024px) {
        font-size: 15px;
      }

      @media only screen and (max-width: 575px) {
        font-size: 11px;
      }

      a {
        color: #040506;
        text-decoration: none;
      }
    }
  }
}

.verify {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  // img {
  //   @media only screen and (max-width: 1360px) {
  //     display: none;
  //   }
  // }

  p {
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Gilroy-SemiBold";
    margin-bottom: 0px;

    span {
      color: #FF3C35;
    }

    @media only screen and (max-width: 1690px) {
      max-width: 225px;
    }

    @media only screen and (max-width: 1460px) {
      max-width: 176px;
      font-size: 11px;
    }

    @media only screen and (max-width: 1360px) {
      display: none;
    }
  }

  .verify-user-button {
    display: flex;
    height: 40px;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: var(--Spacing-xl, 18px);
    border: 1.5px solid var(--Background---grey-Grey---400---GB, #3b4d5e);
    color: #fff;
    font-family: "Gilroy-Medium";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    line-height: 24px;
    background: transparent;
    min-width: 100px;

    @media only screen and (max-width: 1080px) {
      min-width: 80px;
      padding: 10px 10px;
    }

    // @media only screen and (max-width: 1024px) {
    //   display: none;
    // }
  }
}

.header-tooltip {
  display: none;

  p {
    color: #FFFFFF;
    font-size: 14px;
    font-family: "Gilroy-SemiBold";
    margin-bottom: 0px;

    span {
      color: #ff3c35 !important;
    }

    @media only screen and (max-width: 1690px) {
      max-width: 225px;
    }

    @media only screen and (max-width: 1460px) {
      max-width: 176px;
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 1360px) {
    display: block;
  }
}



@keyframes pulseAnimation {
  0% {
    box-shadow: 0 0 0 0px rgba(116, 243, 59, 0.4);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(116, 249, 59, 0);
  }
}

@keyframes rotateRight {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.menu-item-adm {
  padding: 0px 16px 0 16px;
  display: flex;
  align-items: center;
  color: #92a6ba;
  font-family: 'Inter-Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 16px 0;
  cursor: pointer;
      gap: 10px;
}
#dashPopup {
  width: 190px;
  height: 50px;
  background-image: url("../../images/redo/sidebarhover.png");
  // background-color: #0b0e12;
  background-repeat: no-repeat;
  background-position: 100%;
  left: 4px !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .tooltip-inner {
    color: #fff;
    line-height: 24px;
    font-family: 'Inter-Medium';
    font-size: 14px;
    background: transparent;
    text-align: left;
    //background: rgba(116, 243, 59, 0.2);
    // background: rgba(90, 150, 80, 1);


  }

  .tooltip-arrow {
    display: none;
    // &::before {
    //   border-right-color: rgba(116, 243, 59, 0.2) !important;
    //   top: 0 !important;
    // }
  }
}

#verify-button-tooltip{
  .tooltip-inner{
        max-width: 300px;
        .verify-p {
            color: #FFFFFF;
            font-size: 14px;
            font-family: "Gilroy-SemiBold";
        
            span {
              color: #FF3C35;
            }
        
          }
  }

}

  .e-warning-img{
    cursor: pointer;
  }

    .hideMedia {
      padding-left: 3px;
      @media only screen and (min-width: 1101px) and (max-width: 1300px) {
        display: none;
      }
    }

      #verifyPopup {
      width: 190px;
      height: 50px;
      background-image: url("../../images/redo/sidebarhover.png");
      // background-color: #0b0e12;
      background-repeat: no-repeat;
      background-position: 100%;
      left: 10px !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    
      .tooltip-inner {
        color: #fff;
        line-height: 24px;
        font-family: 'Inter-Medium';
        font-size: 14px;
        background: transparent;
        text-align: left;
        //background: rgba(116, 243, 59, 0.2);
        // background: rgba(90, 150, 80, 1);
  
      }
    }