._GB_redoTransaction{
    display: flex;
    background-color: var(--Background---grey-Grey---700---GB, #0E1216);
    position: relative;
    top: 35px;
    @media only screen and (max-width: 1024px){
      top: 0;
    }
    .common_wrapper {
        width: 100%;
        max-width: 100%;
        background-image: url("../../images/redo/transaction_bg.png");
        background-repeat: no-repeat;
        background-position: right  center;
        //animation: rotateRight 90s linear infinite;
        .redoTransaction_wrapper{
            border: 1px solid rgba(59,77,94,0.5);
            border-top-left-radius: 18px ;
            border-bottom: none;
            
            @media only screen and (max-width: 1024px){
                padding: 0 30px;
                border: none;
            }
            @media only screen and (max-width: 767px){
                padding: 0 20px;
            }
            .common_Inner{
                .transac_top{
                    padding: 20px 0;
                    @media only screen and (max-width: 490px){
                      flex-direction: column;
                    }
                    .transac_top_left{
                        max-width: 48%;
                        flex: 0 0 48%;
                        @media only screen and (max-width: 490px){
                          max-width: 100%;
                          flex: 0 0 100%;
                          margin-bottom: 10px;
                        }
                        .secTitle{
                            font-size: 24px;
                            line-height: 30px;
                            color: #fff;
                            font-family: "Gilroy-Medium";
                            @media only screen and (max-width: 767px){
                              font-size: 20px;
                            line-height: 26px;
                            }
                            @media only screen and (max-width: 767px){
                              font-size: 18px;
                              line-height: 24px;
                            }
                        }
                    }
                    .transac_top_right{
                        gap: 10px;
                        max-width: 48%;
                        flex: 0 0 48%;
                        justify-content: flex-end;
                        @media only screen and (max-width: 490px){
                          max-width: 100%;
                          flex: 0 0 100%;
                          width: 100%;
                          justify-content: center;
                        }
                        .gameList{
                            max-width: 200px;
                            width: 100%;
                            .gameDrop{
                                border: 1px solid #3B4D5E;
                                border-radius: 16px;
                                height: 46px;
                                max-width: 200px;
                                width: 100%;
                                background-color: #0E1216;
                                color: #fff;
                                font-size: 14px;
                                line-height: 16px;
                                font-family: "Gilroy-Medium";
                                padding: 5px;
                                cursor: pointer;
                            }
                            select{
                                option {
                                    background: transparent;
                                    color: #fff;
                                    &:hover{
                                        color: #74F33B;
                                    }
                                  }
                            } 
                        }
                        .sortDrop{
                            max-width: 200px;
                            width: 100%;
                            .filterDrop{
                                border: 1px solid #3B4D5E;
                                border-radius: 16px;
                                height: 46px;
                                max-width: 200px;
                                width: 100%;
                                background-color: #0E1216;
                                color: #fff;
                                font-size: 14px;
                                line-height: 16px;
                                font-family: "Gilroy-Medium";
                                padding: 5px;
                                cursor: pointer;
                            }
                        }
                        .trx_swap{
                          cursor: pointer;
                          
                          .currancyConvert{
                            width: 24px;
                            @media only screen and (max-width: 767px){
                              width: 18px;
                            // img{
                             
                            //     width: 24px;
                            //     height: 24px;
                            //   }
                            }
                          }
                        }
                    }
                }
                .transactionTable{
                    #transac_Table{
                        thead {
                            @media only screen and (max-width: 767px) {
                              display: none;
                            }
                            tr {
                                th {
                                  background-color: #141A1F;
                                  font-size: 14px;
                                  line-height: 24px;
                                  font-family: 'Inter-Medium';
                                  font-style: normal;
                                  color: #51E20E;
                                  border: none !important;
                                  &:first-child {
                                    border-top-left-radius: 10px;
                                  }
                                
                                  &:last-child {
                                    border-top-right-radius: 10px;
                                  }
                                  
                                }
                            }
                        }
                        tr{
                          td{
                              color: #DBFFDF;
                          }
                          &.deleted {
                            td {
                                text-decoration: line-through;
                                opacity: 0.5;
                              }
                          }
                         }
                        tbody {
                            border: none;
                            tr {
                              border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
                              background-color: rgba(14, 18, 22, 0.20);
                              &:last-child{
                                border: none;
                                td{
                                  &:first-child{
                                    border-bottom-left-radius: 16px;
                                  }
                                  &:last-child{
                                    border-bottom-right-radius: 16px;
                                  }
                                }
                              }
                              td {
                                color: #DBFFDF;
                                font-family: 'Inter-Regular';
                                font-size: 14px;
                                line-height: 24px;
                                vertical-align: middle;
                                height: 89px;
                                
                                border-bottom: none;
                                &:first-child{
                                    text-align: left;
                                    color: #fff;
                                }
                                &.nodata_inlist{
                                  color: #DBFFDF;
                                }
                                &:last-child{
                                    text-align: center;
                                   
                                }
                                .trx_normal_desc{
                                    color: #DBFFDF;
                                    font-size: 14px;
                                    line-height: 19px;
                                    font-family: 'Inter-SemiBold';
                                }
                                .usergamematches {
                                    text-align: left;
                                    font-size: 14px;
                                    line-height: 19px;
                                    display: block;
                                    font-family: 'Inter-Regular';
                                    text-transform: none;
                                    color: #DBFFDF;
                                    padding-top: 0px;
                                }
                               
                                &.GB_device_td {
                                    background-color: rgba(14, 18, 22, 0.20) !important;
                                    position: relative;
                                    .table-cont{
                                        text-align: left;
                                        .GbplayerCol{
                                            display: flex;
                                            align-items: center;
                                            img{
                                                width: 35px;
                                                margin-right: 10px;
                                            }
                                            .usermatchdetailswrap{
                                                max-width: 445px;
                                                .trx_green_desc{
                                                    color: #74F33B !important;
                                                }
                                            }
                                        }
                                        .rview_ticket{
                                            content: url("../../images/redo/ticket.png");
                                            
                                            top: 26px;
                                            position: absolute;
                                            cursor: pointer;
                                            @media only screen and (min-width: 821px) and (max-width: 1499px){
                                                left: -11px;
                                            }
                                            @media only screen and (min-width: 768px) and (max-width: 820px){
                                              left: -10px;
                                              top: 20px;
                                          }
                                            @media only screen and (min-width: 1500px){
                                              left: -27px;
                                          }
                                          }
                                          .token_greenred_arrow{
                                            margin-right: 5px;
                                            margin-left: 10px;
                                          }
                                          .normal-earnings{
                                            cursor: pointer;
                                          }
                                    }
                                    &::before {
                                        @media only screen and (max-width: 767px) {
                                            content: attr(data-label);
                                            font-weight: bold;
                                            text-transform: uppercase;
                                            top: 10px;
                                            color: #74F33B;
                                            }
                                            @media only screen and (max-width: 479px){
                                            position: relative;
                                            top: 0px;
                                            }
                                        
                                    }
                                }
                                }
                               
                            }
                        }
                    }
                }
            }
        }
    }
}


#redo-popover-adddon {
    background: linear-gradient(180deg, rgba(116, 243, 59, 0) 0%, rgba(116, 243, 59, 0.1) 100%);
    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(38px );
    border: 1px solid #3B4D5E;
    color: #fff;
    width: 100%;
    max-width: 285px !important;
    .popover-arrow {
      &::after {
        border-top-color: #154f09 !important;
      }
    }
  
    .popover-header {
      //background: #181F26;
      background: transparent;
      border-bottom: 1px solid #3B4D5E;
      color: #fff;
      font-family: "Gilroy-medium";
      text-align: center;
      font-size: 14px;
      font-weight:500px;
      padding: 12px;
      margin: 4px 10px;
      &.deleted {
        font-size: 12px;
        opacity: 0.4;
      }
    }
  
    .popover-body {
      color: #fff;
      font-family: "Gilroy-Medium";
      text-align: center;
      font-size: 12px;
      font-weight:500px;
      line-height: 18px;
      padding: 5px 10px;
      .tokenvalue{
        font-family: "Gilroy-Medium";
        font-size: 12px;
        font-weight:500px;
        line-height: 18px;
      }
      div{
        padding-bottom: 6px;
      }
      .total_earning{
        border-top: 1px solid #3B4D5E;
        padding-top: 10px;
        margin-top: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @keyframes rotateRight {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}