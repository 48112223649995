.claimReward_Modal{
    background-color: transparent;
    .modal-dialog{
        max-width: 1159px;
        width: 100%;
        height: 450px;
        border-radius: 20px;
    }
    .modal-content{
        width: 100%;
        border-radius: var(--Spacing-xl, 20px);
        opacity: var(--Corner-radius-xs, 1);
        background: transparent;
        padding: 10px;
        .modal-header{
        border-bottom: none;
        .btn-close{
            background: url("../../images/redo/bet-close-circle.png") no-repeat;
            opacity: unset !important;
        }
        }
        .modal-body{
            .claimpopup_sec{
                background-image: url("../../images/redo/claimPopup_bg.png");
                background-size: cover;
                border-radius: 20px;
                background-repeat: no-repeat;
                .claimpopup_Inner{
                    display: flex;
                    background-image: url("../../images/redo/claimright_bg.png");
                    background-position: bottom right;
                    background-repeat: no-repeat;
                    border-radius: 0 0 20px 0;
                    @media only screen and (max-width: 1024px){
                       background-size: contain;
                    }
                    .claimpopup_left{
                        background-image: url("../../images/redo/claimleft_bg.png");
                        background-position: bottom left;
                        background-repeat: no-repeat;
                        width: 100%;
                        max-width: 50%;
                        flex: 0 0 50%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        @media only screen and (max-width: 767px){
                            max-width: 100%;
                            flex: 0 0 100%;
                        }
                        .left_inner{
                            height: 288px;
                            max-width: 410px;
                            @media only screen and (max-width: 767px){
                                margin: 0 20px;
                            }
                            .secTitle{
                                font-size: 48px;
                                line-height: 64px;
                                font-family: "Gilroy-Bold";
                                color: #51E20E;
                                @media only screen and (max-width: 767px){
                                    font-size: 35px;
                                    line-height: 51px;
                                 }
                                 
                                span{
                                    color: #F1FEEC;
                                }
                            }
                            .subTitle{
                                color: #92A6BA;
                                font-size: 16px;
                                line-height: 24px;
                                max-width: 380px;
                                font-family: "Gilroy-Regular";
                                margin-bottom: 40px;
                            }
                        }
                       
                    }
                    .claimpopup_right{
                        height: 429px;
                        width: 100%;
                        max-width: 50%;
                        flex: 0 0 50%;
                    }
                }
                button{
                    max-width: 236px;
                    width: 100%;
                    height: 50px;
                    font-family: "Inter-Medium";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; 
                    text-transform: none;
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #020303;
                    background-color: #F1FEEC;
                    border-radius: 24px;
                    border: none;
                    &:hover{
                      opacity: 0.7;
                    }
                    img{
                        margin-right: 5px;
                        margin-left: 5px;
                    }
                }
               
            }
    
        
        }
        .modal-footer{
            display: none;
        }
    }
      
}


.kycpopup_Modal{
    background-color: transparent;
    .modal-dialog{
        max-width: 1159px;
        width: 100%;
        height: 450px;
        border-radius: 20px;
    }
    .modal-content{
        width: 100%;
        border-radius: var(--Spacing-xl, 20px);
        opacity: var(--Corner-radius-xs, 1);
        background: transparent;
        padding: 10px;
        .modal-header{
        border-bottom: none;
        .btn-close{
            background: url("../../images/redo/bet-close-circle.png") no-repeat;
            opacity: unset !important;
        }
        }
        .modal-body{
            .betkycpopup_sec{
                background-image: url("../../images/redo/claimPopup_bg.png");
                background-size: cover;
                border-radius: 20px;
                background-repeat: no-repeat;
                .claimpopup_Inner{
                    display: flex;
                    background-image: url("../../images/redo/kycPopup.png");
                    &.claimpopup_InnerBet{
                        background-image: url("../../images/redo/kybet-bg.png");
                    }
                    background-position: 95% bottom;
                    background-repeat: no-repeat;
                    border-radius: 0 0 20px 0;
                    @media only screen and (max-width: 1024px){
                       background-size: contain;
                    }
                    .claimpopup_left{
                        background-image: url("../../images/redo/claimleft_bg.png");
                        background-position: bottom left;
                        background-repeat: no-repeat;
                        width: 100%;
                        max-width: 50%;
                        flex: 0 0 50%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        @media only screen and (max-width: 767px){
                            max-width: 100%;
                            flex: 0 0 100%;
                        }
                        .left_inner{
                            height: 288px;
                            max-width: 410px;
                            @media only screen and (max-width: 767px){
                                margin: 0 20px;
                            }
                            .secTitle{
                                font-size: 48px;
                                line-height: 64px;
                                font-family: "Gilroy-Bold";
                                color: #51E20E;
                                @media only screen and (max-width: 767px){
                                    font-size: 35px;
                                    line-height: 51px;
                                 }
                                    &.secBetTitle {
                                        font-size: 45px;
                                        line-height: 55px;

                                        @media only screen and (max-width: 767px) {
                                            font-size: 30px;
                                            line-height: 45px;
                                        }
                                    }
                                span{
                                    color: #F1FEEC;
                                }
                            }
                            .subTitle{
                                color: #92A6BA;
                                font-size: 16px;
                                line-height: 24px;
                                max-width: 380px;
                                font-family: "Gilroy-Regular";
                                margin-bottom: 40px;
                            }
                        }
                       
                    }
                    .claimpopup_right{
                        height: 429px;
                        width: 100%;
                        max-width: 50%;
                        flex: 0 0 50%;
                    }
                }
                button{
                    max-width: 146px;
                    width: 100%;
                    height: 50px;
                    font-family: "Inter-Medium";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 24px; 
                    text-transform: none;
                    padding: 10px 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #020303;
                    background-color: #F1FEEC;
                    border-radius: 24px;
                    border: none;
                    &:hover{
                      opacity: 0.7;
                    }
                    img{
                        margin-right: 5px;
                        margin-left: 5px;
                    }
                }
               
            }
    
        
        }
        .modal-footer{
            display: none;
        }
    }

      
}
.claimSliderPopup{
    position: relative;
    .claimRewardWrap{
        max-width: 278px;
        height: 191px;
        border: 1.5px solid rgba(59, 77, 94, 0.5);
        border-radius: 15px;
        padding: 14px;
        background: #5a9650;
        position: absolute;
        z-index: 99999999999999 !important;
        @media only screen and (min-width: 768px) {
            left: -242px;
            background: linear-gradient(360deg, rgba(86, 220, 59, 0.32) 5%, rgba(87, 201, 50, 0) 70.11%);
        }
        .closeBtn{
            display: flex;
            justify-content: flex-end;
        }
        .claimSliderHeadtitle{
            font-size: 14px;
                color: #fff;
                line-height: 18px;
                font-family: "Gilroy-SemiBold";
                text-align: center;
                border-bottom: 1px solid rgba(59, 77, 94, 0.5);
                padding-bottom: 10px;
        }
        .slick-slider{
            width: 250px !important;
            .claimRewardItem{
               max-width: 240px !important;
               width: 100%;
               margin: auto;
                .title{
                    font-size: 14px;
                    color: #fff;
                    line-height: 18px;
                    font-family: "Gilroy-SemiBold";
                    text-align: center;
                    border-bottom: 1px solid rgba(59, 77, 94, 0.5);
                    padding-bottom: 10px;
                }
                .content{
                    font-size: 12px;
                    color: #fff;
                    line-height: 18px;
                    font-family: "Gilroy-Medium";
                    text-align: center;
                    margin-top: 15px;
                    padding: 0 15px;
                }
                p{
                    font-size: 14px;
                    color: #fff;
                    text-align: center;
                    margin-top: 20px;
                    span{
                       color: #74F33B; 
                    }
                }
                
            }
            .slick-prev{
                background-image: url("../../images/redo/pagination_arrowL_white.png");
                background-position: 100%;
                background-repeat: no-repeat;
                margin-left: 20px;
                &::before{
                    display: none;
                }
                z-index: 9999 !important;
                                        top: 40px;
            }
            .slick-next{
                background-image: url("../../images/redo/pagination_arrowR_white.png");
                background-position: 100%;
                background-repeat: no-repeat;
                margin-right: 20px;
                &::before{
                    display: none;
                }
                z-index: 9999 !important;
                                        top: 40px;
            }
        }
    }
}