@font-face {
  font-family: "Gilroy-Medium";
  src: url("./Gilroy-Medium.eot");
  src: url("./Gilroy-Medium.eot?#iefix") format("embedded-opentype"),
    url("./Gilroy-Medium.woff2") format("woff2"),
    url("./Gilroy-Medium.woff") format("woff"),
    url("./Gilroy-Medium.ttf") format("truetype"),
    url("./Gilroy-Medium.svg#Gilroy-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Bold";
  src: url("./Gilroy-Bold.eot");
  src: url("./Gilroy-Bold.eot?#iefix") format("embedded-opentype"),
    url("./Gilroy-Bold.woff2") format("woff2"),
    url("./Gilroy-Bold.woff") format("woff"),
    url("./Gilroy-Bold.ttf") format("truetype"),
    url("./Gilroy-Bold.svg#Gilroy-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("./Gilroy-SemiBold.eot");
  src: url("./Gilroy-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./Gilroy-SemiBold.woff2") format("woff2"),
    url("./Gilroy-SemiBold.woff") format("woff"),
    url("./Gilroy-SemiBold.ttf") format("truetype"),
    url("./Gilroy-SemiBold.svg#Gilroy-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy-Regular";
  src: url("./Gilroy-Regular.eot");
  src: url("./Gilroy-Regular.eot?#iefix") format("embedded-opentype"),
    url("./Gilroy-Regular.woff2") format("woff2"),
    url("./Gilroy-Regular.woff") format("woff"),
    url("./Gilroy-Regular.ttf") format("truetype"),
    url("./Gilroy-Regular.svg#Gilroy-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("./Poppins-Bold.eot");
  src: url("./Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("./Poppins-Bold.woff2") format("woff2"),
    url("./Poppins-Bold.woff") format("woff"),
    url("./Poppins-Bold.ttf") format("truetype"),
    url("./Poppins-Bold.svg#Poppins-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: url("./Poppins-SemiBold.eot");
  src: url("./Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("./Poppins-SemiBold.woff2") format("woff2"),
    url("./Poppins-SemiBold.woff") format("woff"),
    url("./Poppins-SemiBold.ttf") format("truetype"),
    url("./Poppins-SemiBold.svg#Poppins-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("./Poppins-Medium.eot");
  src: url("./Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("./Poppins-Medium.woff2") format("woff2"),
    url("./Poppins-Medium.woff") format("woff"),
    url("./Poppins-Medium.ttf") format("truetype"),
    url("./Poppins-Medium.svg#Poppins-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./Poppins-Regular.eot");
  src: url("./Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("./Poppins-Regular.woff2") format("woff2"),
    url("./Poppins-Regular.woff") format("woff"),
    url("./Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Inter-Regular';
  src: url('./Inter-Regular.woff2') format('woff2'),
      url('./Inter-Regular.woff') format('woff'),
      url('./Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('./Inter-Medium.woff2') format('woff2'),
      url('./Inter-Medium.woff') format('woff'),
      url('./Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('./Inter-Bold.woff2') format('woff2'),
      url('./Inter-Bold.woff') format('woff'),
      url('./Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./Inter-SemiBold.woff2') format('woff2'),
      url('./Inter-SemiBold.woff') format('woff'),
      url('./Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

