._GB_dashboard {
    display: flex;
    position: relative;
    top: 35px;
    overflow-x: hidden;
    @media only screen and (max-width: 1024px) {
        top: 0;
    }

    .rdashboard_wrap {
        border: 1px solid rgba(59, 77, 94, 0.5);
        border-top-left-radius: 18px;

        @media only screen and (max-width: 1024px) {
            padding: 0 30px;
        }

        @media only screen and (max-width: 1024px) {
            padding: 0 20px;
            border: none;
         }
        &.starcraft2 {
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/starcraft_banner.png");
            }
        }

        &.pubg {
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/pubg_banner.png");
            }

        }

        &.dota2 {
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/dota_banner.png");
            }

        }

        &.chess {
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/chess_banner.png");
            }

        }

        &.tf2 {
            background-image: url("../../images/redo/tft_banner.png");
        }

        &.aoe4 {
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/aoeII_banner.png");
            }

        }

        &.aoe2 {
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/aoeIV_banner.png");
            }

        }
        &.cs2{
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/cs2_banner.png");
            }
        }

        &.dbdl {
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/dbdl_banner.png");
            }
        }

        background-repeat: no-repeat;
        background-position: top right;
        border-bottom: none;
    }

    .common_wrapper {
        width: 100%;
        max-width: 100%;

        @media only screen and (min-width: 768px) {
            background-image: url("../../images/redo/dashboard_wave.png");
            background-color: var(--Background---grey-Grey---700---GB, #0E1216);
            background-repeat: no-repeat;
            // background-position: top center;
            background-size: 110%;
        }

        @media only screen and (min-width: 1025px) {
            animation: wave 10s linear infinite alternate;
        }

        .rdashboard_inner {
            overflow-y: hidden;
            @media only screen and (min-width: 768px) {
                background-image: url("../../images/redo/dashboard_bg.png");
                background-repeat: no-repeat;
                // background-position: 100% 98%;
                background-size: cover;
                padding-bottom: 100px;
            }

            @media only screen and (min-width: 1920px) {
                padding-bottom: 100px;
            }

            .common_Inner {

                ._GB_rbettingsummarytop {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 16px 25px 0px 25px;

                    @media only screen and (max-width: 1024px) {
                        padding: 10px 0px 0px;
                        align-items: unset;
                    }

                    @media only screen and (min-width: 1920px) {
                        padding: 16px 0px 0px 0px;
                    }

                    @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                        padding: 16px 10px 0px;
                    }

                    gap: 20px;

                    @media only screen and (max-width: 1300px) {
                        flex-direction: column;
                    }

                    .rbettingsummary_topleft {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #fff;
                        backdrop-filter: blur(32px);
                        border: 1.5px solid rgba(59, 77, 94, 0.5);
                        border-radius: 24px;
                        width: 100%;
                        padding: 3px 6px;
                        margin-bottom: 0px;

                        @media only screen and (max-width: 1300px) {
                            width: 100%;
                        }

                        @media only screen and (max-width: 1024px) {
                            // display: block;
                            text-align: left;
                            // max-width: 768px;
                            padding: 6px;
                            width: 100% !important;
                            max-width: 100%;
                            margin: auto;
                        }

                        @media only screen and (max-width: 475px) {
                            padding: 10px;
                        }

                        @media only screen and (max-width: 400px) {
                            display: block;
                            padding: 10px 5px;
                        }

                        @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                            padding: 5px 6px;
                            // margin-bottom: 0px;
                        }

                        &.rtopleft_success {
                            //background: #52dd21;
                            backdrop-filter: blur(32px);
                            padding: 9px;
                            border: 1.5px solid #51E20E !important;

                            @media only screen and (max-width: 400px) {
                                padding: 12px 9px 15px;
                            }
                        }

                        .rbettingsummary_topleftonemain {
                            @media only screen and (min-width: 401px) and (max-width: 490px) {
                                max-width: 50%;
                                flex: 0 0 50%;
                            }

                            @media only screen and (min-width: 491px) and (max-width: 575px) {
                                max-width: 60%;
                                flex: 0 0 60%;
                            }

                            .rbettingsummary_topleftone {
                                font-family: "Gilroy-SemiBold";
                                font-size: 16px;
                                line-height: 18px;
                                color: rgba(201, 250, 178, 1);

                                @media only screen and (min-width: 992px) and (max-width: 1260px) {
                                    font-size: 14px;
                                }

                                @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                                    font-size: 14px;
                                    line-height: 15px;
                                }

                                @media only screen and (max-width: 991px) {
                                    font-size: 12px;
                                }

                                // &.rone_success {
                                //     color: #000000;
                                // }

                                .rbettingsummary_topleftonesub {
                                    display: flex;
                                    gap: 10px;
                                    align-items: center;

                                    @media only screen and (max-width: 991px) {
                                        justify-content: left;
                                        padding: 0px;
                                        max-width: 100%;
                                        margin: auto;
                                    }

                                    img {
                                        @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                                            height: 26px;
                                        }
                                    }

                                    p {
                                        margin: 0;

                                        @media only screen and (max-width: 991px) {
                                            line-height: 12px;
                                            font-size: 12px;
                                        }

                                        span {
                                            font-family: 'Inter-Regular';
                                            font-size: 12px;
                                            line-height: 12px;
                                            color: #DBFFDF;

                                            @media only screen and (max-width: 991px) {
                                                justify-content: left;
                                                padding: 0px;
                                                font-size: 10px;
                                                //max-width: 350px;
                                                //margin: auto;
                                            }

                                            @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                                                font-size: 10px;
                                                line-height: 12px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .rbettingsummary_toplefttwomain {
                            display: flex;
                            // justify-content: space-between;
                            align-items: center;
                            gap: 5px;
                            justify-content: flex-end;
                            width: 42%;

                            @media only screen and (min-width: 1550px) {
                                width: 50%;
                            }

                            @media only screen and (min-width: 1366px) and (max-width: 1549px) {
                                width: 57%;
                            }

                            @media only screen and (min-width: 1024px) and (max-width: 1365px) {
                                width: 75%;
                            }

                            @media only screen and (min-width: 991px) and (max-width: 1024px) {
                                width: 100%;
                            }

                            @media only screen and (max-width: 1024px) {
                                // display: block;
                                // width: 100%;
                                // max-width: 100%;
                                text-align: center;
                            }

                            @media only screen and (max-width: 767px) {
                                margin-top: 0px;
                            }

                            @media only screen and (min-width: 491px) and (max-width: 575px) {
                                max-width: 40%;
                                flex: 0 0 40%;
                            }

                            @media only screen and (min-width: 401px) and (max-width: 490px) {
                                max-width: 50%;
                                flex: 0 0 50%;
                            }

                            @media only screen and (max-width: 400px) {
                                justify-content: center;
                                margin-top: 10px;
                                width: 100%;
                            }


                            .rbettingsummary_toplefttwo {
                                font-family: "Poppins-SemiBold";
                                font-size: 15px;
                                line-height: 15px;

                                @media only screen and (max-width: 991px) {
                                    font-size: 10px;
                                    line-height: 10px;
                                    font-family: "Gilroy-Bold";
                                }

                                &.rtwo_success {
                                    color: #000000;
                                }

                                @media only screen and (min-width: 992px) and (max-width: 1260px) {
                                    font-size: 12px;
                                }

                                @media only screen and (min-width: 1301px) and (max-width: 1600px) {
                                    font-size: 12px;
                                }
                            }

                            .rbettingsummary_topleftthree {
                                // font-family: "Gilroy-Bold";
                                // font-size: 16px;
                                // line-height: 17px;
                                // background-color: #000000;
                                // padding: 5px 10px 5px 7px;
                                // border-radius: 15px;
                                font-family: "Gilroy-semibold";
                                font-size: 14px;
                                line-height: 16px;
                                background-color: #000000;
                                padding: 5px 15px 5px 15px;
                                border-radius: 9px;
                                min-height: 43px;

                                @media only screen and (max-width: 1024px) {
                                    max-width: 23%;
                                    flex: 0 0 23%;
                                    margin: 10px auto;
                                }

                                @media only screen and (max-width: 990px) {
                                    font-size: 12px;
                                }

                                @media only screen and (max-width: 475px) {
                                    font-size: 10px;
                                    max-width: 22%;
                                    flex: 0 0 22%;
                                }

                                &.three_success {
                                    background: rgba(0, 0, 0, 0.6);
                                }

                                @media only screen and (min-width: 991px) and (max-width: 1260px) {
                                    font-size: 10px;
                                }

                                @media only screen and (min-width: 1301px) and (max-width: 1400px) {
                                    font-size: 12px;
                                }

                                img {
                                    margin-right: 6px;
                                    width: 16px;
                                }

                                @media only screen and (max-width: 991px) {
                                    margin: 0px auto;
                                }

                                @media only screen and (max-width: 767px) {
                                    padding: 5px;
                                }
                            }

                            .rbettingsummary_topleftfour {
                                .rresult_btn {
                                    width: 159px;
                                    color: #020303;
                                    background-color: #74F33B;
                                    border-radius: 16px;
                                    font-family: "Gilroy-Medium";
                                    font-size: 13px;
                                    line-height: 24px;
                                    text-transform: capitalize;
                                    padding: 8px;
                                    text-decoration: none;
                                    margin-left: 15px;
                                    border:none;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    @media only screen and (max-width: 991px) {
                                        //font-size: 14px;
                                        padding: 11px 6px;
                                        margin-left: 0px;
                                    }

                                    @media only screen and (max-width: 475px) {
                                        font-size: 14px;
                                        padding: 11px 4px;
                                    }

                                    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                                        font-size: 11px;
                                        padding: 10px 12px;
                                    }

                                    &::after {
                                        content: url("../../images/redo/arrow-up-redo.png");
                                        padding-left: 10px;
                                        position: relative;
                                        top: 4px;
                                        @media only screen and (max-width: 991px) {
                                            //top: 2px;
                                        }
                                    }

                                    &:hover {
                                        opacity: 0.6;
                                    }

                                    &.rfour_success {
                                        width: 175px;
                                        color: #020303;
                                        background-color: #74F33B;
                                        border-radius: 16px;
                                        font-family: "Gilroy-Medium";
                                        font-size: 13px;
                                        line-height: 24px;
                                        text-transform: capitalize;
                                        padding: 8px;
                                        text-decoration: none;
                                        text-align: center;
                                        cursor: pointer;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        &:hover {
                                            opacity: 0.6;
                                        }

                                        @media only screen and (min-width: 992px) and (max-width: 1919px) {
                                            font-size: 11px !important;
                                            padding: 11px 9px !important;
                                        }
                                    }

                                    @media only screen and (min-width: 992px) and (max-width: 1260px) {
                                        font-size: 11px;
                                        padding: 11px 8px;
                                    }

                                    @media only screen and (min-width: 1301px) and (max-width: 1400px) {
                                        font-size: 11px;
                                        padding: 11px 12px;
                                    }
                                }
                            }

                            &.rright_zero {
                                justify-content: flex-end;

                                @media only screen and (max-width: 400px) {
                                    justify-content: center;
                                }
                            }
                        }
                    }

                    &.rsp-summarymob {
                        @media only screen and (min-width: 1025px) {
                            display: none;
                        }
                    }

                    // &.rsummarytop_desk {
                    //     @media only screen and (max-width: 1024px) {
                    //         display: none;
                    //     }
                    // }

                    &.rfetching-challenge-data {
                        border-radius: 24px;
                        margin-top: 20px;
                        border: 1.5px solid rgba(59, 77, 94, 0.5);
                        backdrop-filter: blur(32px);
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 16px 0px 16px 0px;
                    }
                }


            }
        }
    }
}

.rbetting_seconds {
    border: 1px solid rgba(59, 77, 94, 0.5);
    opacity: var(--Corner-radius-xs, 1);
    background: var(--Background---grey-Grey---500---GB, #181F26);
    border-radius: 12px;
    width: 100%;
    max-width: 114px;
    margin: 25px auto;
    padding: 17px 15px;

    .rbetting_number {
        font-family: "Gilroy-Bold";
        font-size: 68px;
        line-height: 68px;
        text-align: center;
        text-transform: capitalize;
        color: #ffffff;
    }

    .rbetting_secondstext {
        font-family: "Gilroy-Bold";
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 1);
    }
}

._GB_rdDashboardBanner {
    padding: 25px 0px 0px;

    .banner_inner {
        .rdBanner_top {
            @media only screen and (max-width: 1024px) {
                flex-direction: column;
                gap: 20px;
            }

            &.align-items-start {
                @media only screen and (max-width: 767px) {
                    align-items: normal !important;
                }
            }

            .top_left {
                flex: 0 0 50%;
                max-width: 50%;
                // background-image: url("../../images/redo/graph_img.png");
                // background-repeat: no-repeat;
                // background-position: right 0%;

                @media only screen and (max-width: 1400px) {
                    flex: 0 0 40%;
                    max-width: 40%;
                }

                @media only screen and (max-width: 1024px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                @media only screen and (max-width: 767px) {
                    order: 1;
                }

                .balanceSec {
                    color: #5D7893;
                    font-size: 16px;
                    line-height: 24px;
                    font-family: "Gilroy-Medium";
                    margin-bottom: 10px;

                    @media only screen and (max-width: 767px) {
                        order: 2;
                        line-height: 16.8px;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    span {
                        font-family: "Gilroy-Bold";
                    }
                }

                .balanceAmount {
                    color: #FFF;
                    font-family: "Gilroy-SemiBold";
                    font-size: 32px;
                    font-style: normal;
                    line-height: 48px;
                    cursor: pointer;

                    // @media only screen and (max-width: 1400px){
                    //     font-size: 35px;
                    //     line-height: 43px;
                    // } 
                    img {
                        margin-left: 12px;
                    }
                }

                .convertAmount {
                    margin-top: 16px;

                    .amountInSolano {
                        color: #FFF;
                        font-family: "Gilroy-Medium";
                        font-size: 12px;
                        line-height: 22px;

                        @media only screen and (max-width: 767px) {
                            font-size: 16px;
                            line-height: 22.4px;
                        }
                    }

                    .upOrdown {
                        span {
                            color: #B2F35F;
                            font-family: "Gilroy-Medium";
                            font-size: 12px;
                            line-height: 22px;

                            @media only screen and (max-width: 767px) {
                                font-size: 16px;
                                line-height: 22.4px;
                            }
                        }
                    }
                }
            }

            .top_right {
                gap: 12px;

                @media only screen and (max-width: 1024px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }

                @media only screen and (max-width: 767px) {
                    gap: 5px;
                }

                img {
                    cursor: pointer;
                }

                .topBtnSec {
                    gap: 12px;

                    @media only screen and (max-width: 490px) {
                        flex-wrap: wrap;
                        justify-content: center;
                    }

                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        gap: 5px;
                    }

                    // .info-circle {
                    //     @media only screen and (max-width: 767px) {
                    //         display: none;
                    //     }
                    // }

                    .claimBtn {
                        max-width: 224px;
                        height: 45px;
                        border: 1px solid rgba(59, 77, 94, 0.5);
                        border-radius: 20px;
                        backdrop-filter: blur(32px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        padding: 15px;
                        color: var(--Background---grey-Grey---50---GB, #E3E8ED);
                        font-family: "Gilroy-Medium";
                        font-size: 12px;
                        line-height: 24px;
                        position: relative;
                        @media only screen and (min-width: 1025px) and (max-width:1599px){
                            height: 55px;
                        }
                        @media only screen and (max-width: 400px) {
                            padding: 10px;
                        }
                        img{
                            width: 20px;
                            height: 19px;
                            margin-left: 5px;
                            @media only screen and (max-width: 767px){
                                margin-left: 0;
                            }
                        }
                        .boostTop{
                            position: absolute;
                            top: -16px;
                            right: 15px;
                            background-color: #51E20E;
                            width: 24px;
                            height: 24px;
                            border-radius: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #000;
                            font-size: 14px;
                            font-family: "Gilroy-Bold";
                            z-index: 1;
                            @media only screen and (max-width: 767px){
                                right: -2px;
                            }
                        }
                        &:nth-child(3) {
                            @media only screen and (max-width: 767px) {
                                font-size: 0;
                                background-blend-mode: overlay;
                                background: #74F33B33;
                                border: 1.5px solid #74F33B;
                                border-radius: 40px !important;
                                padding: 8px;
                                order: 4;
                            }
                        }

                        &:hover {
                            border-color: #74F33B;
                            background-color: rgba(116, 243, 59, 0.20);
                        }

                        &.noclaim {
                            background: #e20400;

                            &:hover {
                                opacity: 0.5;
                                border: 1px solid rgba(59, 77, 94, 0.5) !important;
                            }
                        }

                        &.yellowBtn {
                            background: #ffebb7;
                            color: #000;

                            &:hover {
                                opacity: 0.5;
                                border: 1px solid #ffebb7 !important;
                            }
                        }
                        &.boostedClaimBtn{
                            border-color: #74F33B;
                            background-color: rgba(116, 243, 59, 0.20);
                        }
                    }

                    #gamedrop,
                    #rankdrop {
                        max-width: 223px;
                        height: 40px;
                        border: 1px solid rgba(59, 77, 94, 0.5);
                        border-radius: 20px;
                        backdrop-filter: blur(32px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        padding: 15px;
                        color: var(--Background---grey-Grey---50---GB, #E3E8ED);
                        font-family: "Gilroy-Medium";
                        font-size: 12px;
                        line-height: 24px;
                        background: transparent !important;

                        @media only screen and (max-width: 400px) {
                            padding: 10px;
                        }
                        @media only screen and (min-width: 1025px) and (max-width:1600px){
                            height: 55px;
                        }

                        &:hover {
                            border-color: #74F33B;
                            background: rgba(116, 243, 59, 0.20);
                        }

                        &::after {
                            border-top: none !important;
                            border-right: none !important;
                            content: "";
                            background-image: url("../../images/redo/whitearrow-down.png");
                            background-repeat: no-repeat;
                            width: 24px;
                            height: 24px;
                        }

                    }

                    .dropdown-menu {
                        background: rgba(4, 5, 6, 0.6);
                        width: 100%;
                        .dropdown-toggle {

                            &::after {
                                border-top: none !important;
                                border-right: none !important;
                                content: "";
                                background-image: url("../../images/redo/whitearrow-down.png");
                                background-repeat: no-repeat;
                                width: 24px;
                                height: 24px;
                            }
                        }

                        .dropdown-item {
                            color: #fff;
                            font-family: "Gilroy-Medium";
                            background-color: transparent;

                            &:hover {
                                color: #74F33B;
                            }
                        }
                    }
                }
            }

        }
        .gamelist{
            .dropdown-menu {
                margin-left: -65px !important;    
            }
            &.gameModelist{
                .dropdown-menu {
                    margin-left: -15px !important;    
                }
            }
        }
       
        .rdBanner_middle {
            margin-top: 15px;
            gap: 15px;

            .middleBtn {
                max-width: 315px;
                width: 100%;
                height: 45px;
                background-color: #181F26;
                color: #F1FEEC;
                display: flex;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-family: "Gilroy-Medium";
                font-size: 14px;
                line-height: 24px;

                &:hover {
                    border-color: #74F33B;
                    background-color: rgba(116, 243, 59, 0.20);
                }
            }
        }

    }

}

._GB_dashboardDetails {
    // background: linear-gradient(356deg, #0E1216 3.49%, rgba(14, 18, 22, 0.02) 71.81%, rgba(14, 18, 22, 0.00) 71.81%);
    margin-bottom: 100px;

    .details_inner {
        .details_top {
            flex-wrap: wrap;
            margin-top: 24px;
            // padding: 0px 32px;

            .top_left {
                max-width: 80%;
                flex: 0 0 80%;

                @media only screen and (max-width: 767px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                .tab_sec {
                    max-width: 550px;
                    height: 66px;
                    border: 1.5px solid rgba(59, 77, 94, 0.5);
                    border-radius: 20px;
                    background: rgba(14, 18, 22, 0.10);
                    backdrop-filter: blur(32px);

                    .tab_item {
                        padding: 16px 30px;
                        font-size: 16px;
                        font-family: "Gilroy-Bold";
                        line-height: 24px;
                        color: #5D7893;
                        cursor: pointer;
                        text-transform: capitalize;

                        @media only screen and (max-width: 767px) {
                            padding: 16px 22px;
                            font-size: 14px;
                            line-height: 18px;
                        }

                        @media only screen and (max-width: 374px) {
                            padding: 16px;
                        }

                        &:hover {
                            color: #fff;
                            text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.40);
                        }

                        &.selected_tab {
                            color: #fff;
                            text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.40);
                            position: relative;

                            &::before {
                                content: url("../../images/redo/tab_sel-icon.png");
                                bottom: -26px;
                                left: 35%;
                                position: absolute;
                                animation: pulseAnimation 2s infinite;
                                border-radius: 50%;
                                height: 32px
                            }

                            @media only screen and (max-width: 767px) {
                                padding: 16px 22px;
                            }
                        }

                        &.desktop {
                            @media only screen and (max-width: 767px) {
                                display: none;
                            }
                        }

                        &.mobile {
                            @media only screen and (min-width: 768px) {
                                display: none;
                            }
                        }

                        span {
                            &.desktop {
                                @media only screen and (max-width: 767px) {
                                    display: none;
                                }
                            }

                            &.mobile {
                                @media only screen and (min-width: 768px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            .top_right {
                max-width: 20%;
                flex: 0 0 20%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media only screen and (max-width: 767px) {
                    display: none;
                }
            }
        }

        .details_middle {
            padding: 24px 0px;

            .filterList {
                max-width: 96px;
                width: 100%;
            }

            .bottomTitle {
                color: #FFF;
                font-family: "Gilroy-Medium";
                font-size: 20px;
                line-height: 28px;
                padding-bottom: 15px;
            }

            .gamePerfoList {
                gap: 12px;

                @media only screen and (min-width: 768px) {
                    width: 100%;
                }

                @media only screen and (max-width: 1024px) {
                    flex-direction: column;
                }

                .gamePerfoListBox {
                    max-width: 240px;
                    width: 100%;
                    padding: 16px 32px;
                    border-radius: var(--Spacing-xl, 20px);
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    opacity: var(--Corner-radius-xs, 1);
                    background: rgba(14, 18, 22, 0.4);
                    backdrop-filter: blur(32px);
                    -webkit-backdrop-filter: blur(32px);

                    @media only screen and (max-width: 767px) {
                        padding: 7px;
                        max-width: 98px;
                    }

                    @media only screen and (max-width: 1600px) and (min-width: 768px) {
                        padding: 15px;
                    }

                    .gameDetails_title {
                        color: #92A6BA;
                        font-size: 16px;
                        line-height: 24px;
                        font-family: "Gilroy-Medium";
                        @media only screen and (max-width: 767px){
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                    .earnCoin {
                        font-size: 16px;
                        line-height: 0;
                        color: var(--Primary-Green---200---GB, #fff);
                        margin-top: 12px;
font-family: "Gilroy-Medium";
                        @media only screen and (max-width: 1400px) {
                            font-size: 14px;
                            line-height: 0;
                        }
                        visibility: hidden;
                    }

                    .totalPerfo {
                        color: #fff;
                        font-size: 14px;
                        line-height: 24px;
                        font-family: "Gilroy-Medium";
                    }

                    &.nobordergm {
                        border: none !important;
                    }

                    &.todayBox {
                        width: 100%;
                        max-width: 48%;
                    }

                    &.playtime {
                        width: 100%;
                        max-width: 48%;
                    }
                }
            }

            .gamePerfoList_left {
                gap: 12px;
                max-width: 100%;
                flex: 0 0 100%;

                @media only screen and (max-width: 1024px) {
                    flex-wrap: wrap;
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                @media only screen and (max-width: 534px) {
                    justify-content: center;
                }

                .bottomTitle {
                    color: #FFF;
                    font-family: "Gilroy-Medium";
                    font-size: 14px;
                    line-height: 24px;
                    padding-bottom: 15px;
                }

                .totalTime {
                    font-size: 18px;
                    line-height: 24px;
                    font-family: "Gilroy-Bold";
                    color: var(--Primary-Green---200---GB, #C9FAB2);
                    margin-top: 12px;
                    text-align: center;

                    @media only screen and (max-width: 767px) {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    span {
                        font-family: "Gilroy-Regular";
                    }
                }

                .filterList {
                    max-width: 96px;
                    width: 100%;

                    // &.dashboard_filter_desktop{
                    //     @media only screen and (max-width: 767px){
                    //         display: none;
                    //     }
                    // }
                    #filterDrop {
                        max-width: 96px;
                        width: 100%;
                        margin-right: 0;
                        height: 93px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1.5px solid rgba(59, 77, 94, 0.5);
                        border-radius: var(--Spacing-lg, 16px);
                        background: rgba(14, 18, 22, 0.4);
                        backdrop-filter: blur(32px);

                        @media only screen and (max-width: 767px) {
                            max-width: 56px;
                            height: 56px;
                        }

                        &:hover {
                            border-color: #74F33B;
                            background: rgba(116, 243, 59, 0.20);
                        }

                        &::after {
                            content: url("../../images/redo/filter.png") !important;
                            border-top: none !important;
                            border-right: none !important;
                            border-left: none !important;
                            @media only screen and (max-width: 767px){
                                margin-top: 8px;
                            }
                            @media only screen and (min-width: 768px){
                                position: absolute;
                                top: 33px;
                            }
                        }

                    }

                    .dropdown-menu {
                        background: rgba(4, 5, 6, 0.6);
                        width: 100%;

                        .dash-gm-filter-inner {
                            height: 300px;
                            overflow-y: scroll;

                            &::-webkit-scrollbar {
                                width: 5px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #181F26;
                                border-radius: 9px;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #74F33B;
                                border-radius: 9px;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #74F33B;
                            }

                            &.hideScroll {
                                height: auto;
                                overflow: hidden;
                            }
                        }


                        .dropdown-item {
                            color: #fff;
                            font-family: "Gilroy-Medium";
                            background-color: transparent;

                            &:hover {
                                color: #74F33B;
                            }
                        }
                    }

                }
            }


            .gamePerfoList_betting {
                gap: 1%;
                @media only screen and (max-width: 1024px) {
                    flex-direction: column;
                }
                @media only screen and (max-width: 767px){
                    display: none !important;
                }

                .gamePerfoListBox_betting {
                    max-width: 24%;
                    flex: 0 0 24%;
                    width: 100%;
                    padding: 18px 24px;
                    border-radius: var(--Spacing-xl, 20px);
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    opacity: var(--Corner-radius-xs, 1);
                    background: linear-gradient(282deg, rgba(116, 243, 59, 0) -57%, rgba(24, 31, 38, 1) 100%);
                    //backdrop-filter: blur(32px);
                    @media only screen and (max-width: 940px) {
                        padding: 7px;
                        max-width: 98px;
                        max-width: 22%;
                        flex: 0 0 22%;
                    }
                    @media only screen and (max-width: 767px) {
                        padding: 7px;
                        max-width: 47%;
                        flex: 0 0 47%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    @media only screen and (max-width: 1600px) and (min-width: 768px) {
                        padding: 15px;
                    }
                    .image_betting{
                        min-height: 80px;
                        background-repeat: no-repeat;
                        background-position: center;
                        max-width: 80px;
                        margin-bottom: 10px;
                        
                        img{
                            animation: rotateRight 90s linear infinite;

                        }
                        &.earnings{
                            background-image: url("../../images/redo/earning-icon-svg.svg") !important;

                            
                        }
                        &.betmode{
                            background-image: url("../../images/redo/betmode-icon-svg.svg") !important;
                        }
                        &.sumbets{
                            background-image: url("../../images/redo/sumbets-icon-svg.svg") !important;
                        }
                        &.pots{
                            background-image: url("../../images/redo/pots-icon-svg.svg") !important;
                        }
                        img{
                            margin-bottom: 0px;
                        }
                    }
                    .gameDetails_title {
                        color: #92A6BA;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 500;
                        font-family: "Gilroy-Medium";
                        @media only screen and (max-width: 767px){
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                    .earnCoin {
                        font-size: 16px;
                        line-height: 0;
                        color: var(--Primary-Green---200---GB, #ffffff);
                        margin-top: 5px;
                        font-family: "Gilroy-Bold";

                        @media only screen and (max-width: 1400px) {
                            font-size: 14px;
                            line-height: 0;
                        }
                        visibility: hidden;
                    }

                    .totalPerfo {
                        color: #fff;
                        font-size: 14px;
                        line-height: 24px;
                        font-family: "Gilroy-Medium";
                    }

                    &.nobordergm {
                        border: none !important;
                    }
                    &.todayBox{
                        width: 100%;
                        max-width: 48%;
                    }
                    &.playtime {
                        width: 100%;
                        max-width: 48%;
                    }
                }
            }
            .gamePerfoList_Mobbetting {
                display: block;
                margin-top: 10px;
                @media only screen and (min-width: 768px){
                    display: none !important;
                }
                .gamePerfoListBox_betting {
                    max-width: 24%;
                    flex: 0 0 24%;
                    width: 100%;
                    padding: 18px 24px;
                    border-radius: var(--Spacing-xl, 20px);
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    opacity: var(--Corner-radius-xs, 1);
                    background: linear-gradient(282deg, rgba(116, 243, 59, 0) -57%, rgba(24, 31, 38, 1) 100%);

                    //backdrop-filter: blur(32px);
                    @media only screen and (max-width: 940px) {
                        padding: 7px;
                        max-width: 98px;
                        max-width: 22%;
                        flex: 0 0 22%;
                    }

                    @media only screen and (max-width: 767px) {
                        padding: 7px;
                        max-width: 47%;
                        flex: 0 0 47%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    @media only screen and (max-width: 1600px) and (min-width: 768px) {
                        padding: 15px;
                    }

                    .image_betting {
                        min-height: 80px;
                        background-repeat: no-repeat;
                        background-position: center;
                        max-width: 80px;
                        margin-bottom: 10px;

                        img {
                            animation: rotateRight 90s linear infinite;

                        }

                        &.earnings{
                            background-image: url("../../images/redo/earning-icon-svg.svg") !important;

                            
                        }
                        &.betmode{
                            background-image: url("../../images/redo/betmode-icon-svg.svg") !important;
                        }
                        &.sumbets{
                            background-image: url("../../images/redo/sumbets-icon-svg.svg") !important;
                        }
                        &.pots{
                            background-image: url("../../images/redo/pots-icon-svg.svg") !important;
                        }

                        img {
                            margin-bottom: 0px;
                        }
                    }

                    .gameDetails_title {
                        color: #92A6BA;
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: 500;
                        font-family: "Gilroy-Medium";
                    }

                    .earnCoin {
                        font-size: 24px;
                        line-height: 0;
                        color: var(--Primary-Green---200---GB, #ffffff);
                        margin-top: 5px;
                        font-family: "Gilroy-Bold";

                        @media only screen and (max-width: 1400px) {
                            font-size: 14px;
                            line-height: 0;
                        }
                        visibility: hidden;
                    }

                    .totalPerfo {
                        color: #fff;
                        font-size: 14px;
                        line-height: 24px;
                        font-family: "Gilroy-Medium";
                    }

                    &.nobordergm {
                        border: none !important;
                    }

                    &.todayBox {
                        width: 100%;
                        max-width: 48%;
                    }

                    &.playtime {
                        width: 100%;
                        max-width: 48%;
                    }
                }
                
                
            }

            .gamePerfoList_left_betting {
                gap: 12px;
                max-width: 100%;
                flex: 0 0 100%;

                @media only screen and (max-width: 1024px) {
                    flex-wrap: wrap;
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                @media only screen and (max-width: 940px) {
                    //justify-content: center;
                }

                .bottomTitle {
                    color: #FFF;
                    font-family: "Gilroy-Medium";
                    font-size: 14px;
                    line-height: 24px;
                    padding-bottom: 15px;
                }

                .totalTime {
                    font-size: 18px;
                    line-height: 24px;
                    font-family: "Gilroy-Bold";
                    color: var(--Primary-Green---200---GB, #C9FAB2);
                    margin-top: 12px;
                    text-align: center;

                    @media only screen and (max-width: 767px) {
                        font-size: 14px;
                        line-height: 20px;
                    }

                    span {
                        font-family: "Gilroy-Regular";
                    }
                }

                .filterList {
                    max-width: 96px;
                    width: 100%;

                    &.dashboard_filter_desktop {
                        @media only screen and (max-width: 767px) {
                            display: none;
                        }
                    }

                    #filterDrop {
                        max-width: 96px;
                        width: 100%;
                        margin-right: 0;
                        height: 93px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1.5px solid rgba(59, 77, 94, 0.5);
                        border-radius: var(--Spacing-lg, 16px);
                        background: rgba(14, 18, 22, 0.10);
                        backdrop-filter: blur(32px);

                        @media only screen and (max-width: 767px) {
                            max-width: 56px;
                            height: 56px;
                        }

                        &:hover {
                            border-color: #74F33B;
                            background: rgba(116, 243, 59, 0.20);
                        }

                        &::after {
                            content: url("../../images/redo/filter.png") !important;
                            border-top: none !important;
                            border-right: none !important;
                        }

                    }

                    .dropdown-menu {
                        background: rgba(4, 5, 6, 0.6);
                        width: 100%;

                        .dash-gm-filter-inner {
                            height: 300px;
                            overflow-y: scroll;

                            &::-webkit-scrollbar {
                                width: 5px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #181F26;
                                border-radius: 9px;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #74F33B;
                                border-radius: 9px;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #74F33B;
                            }

                            &.hideScroll {
                                height: auto;
                                overflow: hidden;
                            }
                        }


                        .dropdown-item {
                            color: #fff;
                            font-family: "Gilroy-Medium";
                            background-color: transparent;

                            &:hover {
                                color: #74F33B;
                            }
                        }
                    }

                }

            }

            // .gamePerfoList_right{
            //     max-width: 10%;
            //     flex: 0 0 10%;
            //     gap: 12px;
            //     @media only screen and (max-width: 1024px){
            //         max-width: 100%;
            //         flex: 0 0 100%;
            //         justify-content: flex-start !important;
            //     }
            //     .bottomTitle{
            //         color: #FFF;
            //         font-family: "Gilroy-Medium";
            //         font-size: 14px;
            //         line-height:  24px;
            //         padding-bottom: 15px;
            //     }
            //     .totalTime{
            //         font-size: 18px;
            //         line-height: 24px;
            //         font-family: "Gilroy-Bold";
            //         color: var(--Primary-Green---200---GB, #C9FAB2);
            //         margin-top: 12px;
            //         text-align: center;
            //         span{
            //             font-family: "Gilroy-Regular";
            //         }
            //     }
            //     .filterList{  
            //         max-width: 96px;
            //         width: 100%;  

            //         #filterDrop{
            //             max-width: 96px;
            //             width: 100%;
            //             margin-right: 0;
            //             height: 93px;
            //             display: flex;
            //             align-items: center;
            //             justify-content: center;
            //             border: 1.5px solid rgba(59,77,94,0.5);
            //             border-radius: var(--Spacing-lg, 16px);
            //             background: rgba(14, 18, 22, 0.10);
            //             backdrop-filter: blur(32px);
            //             &:hover{
            //                 border-color: #74F33B;
            //                 background:  rgba(116, 243, 59, 0.20);
            //             }
            //             &::after{
            //                 content : url("../../images/redo/filter.png") !important;
            //                 border-top: none !important;
            //                 border-right: none !important;
            //             }

            //         }
            //         .dropdown-menu{
            //             background: rgba(4, 5, 6, 0.6);
            //             width: 100%;
            //             .dash-gm-filter-inner{
            //                 height: 300px;
            //                 overflow-y: scroll;

            //                 &::-webkit-scrollbar {
            //                     width: 5px;
            //                 }

            //                 /* Track */
            //                 &::-webkit-scrollbar-track {
            //                     background: #181F26;
            //                     border-radius: 9px;
            //                 }

            //                 /* Handle */
            //                 &::-webkit-scrollbar-thumb {
            //                     background: #74F33B;
            //                     border-radius: 9px;
            //                 }

            //                 /* Handle on hover */
            //                 &::-webkit-scrollbar-thumb:hover {
            //                     background: #74F33B;
            //                 }
            //                 &.hideScroll {
            //                     height: auto;
            //                     overflow: hidden;
            //                 }
            //               }


            //             .dropdown-item{
            //                 color: #fff;
            //                 font-family: "Gilroy-Medium";
            //                 background-color: transparent;
            //                 &:hover{
            //                     color:#74F33B;
            //                 }
            //             }
            //         }
            //     }
            // }
        }

        .details_bottom {

            // padding: 0px 32px;
            // background-image: url("../../images/redo/dashboardDetails_bg.png");
            // background-repeat: no-repeat;
            // background-size: 100% 100%;
            #score_Table {
                border-bottom: 0 !important;
                border-radius: 20px;

                &:not(caption) {
                    padding: 0.5rem 1rem;
                }


                thead {
                    background: #141A1F;
                    border-bottom: 0 !important;

                    @media only screen and (max-width: 767px) {
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                    }

                    tr {
                        padding: 12px 0px;

                        th {
                            color: #51E20E;
                            font-size: 14px;
                            line-height: 24px;
                            font-family: 'Inter-Regular';
                            border-bottom: 0 !important;
                            vertical-align: middle;
                            text-transform: capitalize;

                            &:first-child {
                                border-top-left-radius: 20px;
                                padding-left: 30px;

                                //text-align: center;
                                @media only screen and (max-width: 767px) {
                                    padding-left: 15px;
                                }
                            }

                            &:last-child {
                                border-top-right-radius: 20px;
                            }
                            &.matchAttDesktop{
                                @media only screen and (max-width: 767px) {
                                    display: none;
                                }

                            }
                            &.matchAttMobile{
                                @media only screen and (min-width: 768px) {
                                    display: none;
                                }

                            }

                            &.hide {
                                @media only screen and (max-width: 767px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                tbody {
                    tr {
                        .GB_device_td {
                            text-align: left;

                            &::before {
                                position: relative;
                                color: #51E20E;
                                top: 0;
                            }

                            &:first-child {
                                padding-left: 30px;

                                @media only screen and (max-width: 767px) {
                                    border-top: 1px solid rgba(59, 77, 94, 0.5);
                                }

                            }
                            
                        }

                        td {
                            // height: 79px;
                            color: #DBFFDF;
                            font-size: 13px;
                            line-height: 24px;
                            font-family: 'Inter-Regular';
                            border-bottom: 0 !important;
                            vertical-align: middle;
                            opacity: var(--Corner-radius-xs, 1);
                            background: rgba(14, 18, 22, 0.4);
                            backdrop-filter: blur(32px);

                            @media only screen and (max-width: 767px) {
                                height: 60px;
                            }

                            &:first-child {
                                @media only screen and (max-width: 767px){
                                    padding-left: 15px;
                                }
                            }

                            img {
                                margin-right: 8px;
                            }
                            &.dashNodataCol{
                                padding:10px;
                                text-align: center;
                            }


                            .dashNoData {
                                    display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        height: 80px;
                            }

                            &.hide {
                                @media only screen and (max-width: 767px) {
                                    display: none;
                                }
                            }
                            .dash_btn_td{
                                @media only screen and (min-width: 768px) and (max-width: 1365px){
                                    flex-direction: column;
                                    align-items: flex-start !important;
                                }
                                @media only screen and (max-width: 575px){
                                    flex-direction: column !important;
                                }
                                
                                .rd_disputeButton {
                                    margin-left: 10px;
                                    @media only screen and (min-width: 768px) and (max-width: 1365px){
                                        margin-left: 0;
                                        text-align: center;
                                    }
                                    @media only screen and (max-width: 575px){
                                        margin-left: 0;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            td {
                                &:first-child {
                                    border-bottom-left-radius: 20px;
                                }

                                &:last-child {
                                    border-bottom-right-radius: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .dash_p2e_tab {
                #score_Table {
                    thead {
                        @media only screen and (max-width: 767px) {
                            border-top-left-radius: 20px;
                            border-top-right-radius: 20px;
                        }
                        th{
                            &.matchAttDesktop {
                                border-top-right-radius: 20px;
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                height: auto;

                                &:first-child {
                                    @media only screen and (min-width: 768px) {
                                        padding-left: 30px;
                                        }
                                }
                            }
                        }

                        .GB_device_td {

                            text-align: left;

                            &:first-child {
                                //text-align: center !important;
                                padding-left: 35px;
                            }

                            &:nth-child(2) {
                                @media only screen and (max-width: 767px) {
                                    text-align: right;
                                }
                            }

                            &::before {
                                @media only screen and (max-width: 490px) {
                                    text-align: left;
                                }
                            }

                            &:nth-child(3) {
                                span {
                                    cursor: pointer;
                                }
                            }

                            .win_lost {
                                color: #92A6BA;
                                font-size: 12px;
                                line-height: 24px;
                                font-family: "Gilroy-Medium";
                            }
                        }
                    }

                }
            }
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.dash-p2e-attr {
    display: block;

    &.dash-p2emid {
        color: #92A6BA;
        font-size: 12px;
        line-height: 24px;
        font-family: "Gilroy-Medium";
    }
}

.dash_no_games {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.5);
    background: var(--Background---grey-Grey---500---GB, #181f26);
    //background: rgba(14, 18, 22, 0.1);
    margin: 25px 0;
    padding: 30px;
    gap: 20px;

    @media only screen and (max-width: 767px) {
        padding: 20px;
        gap: 15px;
    }

    @media only screen and (max-width: 575px) {
        flex-direction: column;
        justify-content: center;
    }

    .dash_no_title {
        color: #fff;
        font-family: "Gilroy-Medium";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
    }

    .dash_no_sub_title {
        font-size: 14px;
        font-family: "Gilroy-Bold";
        line-height: 18px;
        color: #5D7893;
        margin-top: 15px;
    }

    button {
        color: var(--Background---grey-Grey---950---GB, #020303);
        font-family: 'Gilroy-Bold';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        padding: var(--Spacing-md, 12px) var(--Spacing-xl, 20px);
        justify-content: center;
        align-items: center;
        gap: var(--Spacing-sm, 8px);
        align-self: stretch;
        border-radius: var(--Spacing-xl, 20px);
        background: var(--Primary-Green---50---GB, #f1feec);
        box-shadow: 0px 2px 4px 0px rgba(0, 210, 160, 0.04), 0px 24px 48px -8px rgba(0, 210, 160, 0.12);
        text-decoration: none;
        border:none;
        &:hover{
            opacity: 0.5;
        }

        @media only screen and (max-width: 767px) {
            padding: var(--Spacing-md, 12px) var(--Spacing-xl, 15px);
        }
    }
}

@keyframes wave {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 0;
    }
}

#popover-adddon {
    background: #0e1514;
    border: 1px solid rgba(59, 77, 94, 0.5);
    width: 250px !important;

    .popover-body {
        font-family: 'Inter-Regular';
        font-size: 13px !important;
    }
}

@keyframes pulseAnimation {
    0% {
        box-shadow: 0 0 0 0px rgba(116, 243, 59, 0.4);
    }

    100% {
        box-shadow: 0 0 0 15px rgba(116, 249, 59, 0);
    }
}

@keyframes rotateRight {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.dash_bay_tab {
    #score_Table {
        thead {
            @media only screen and (max-width: 767px) {
                border-top-left-radius: 20px !important;
                border-top-right-radius: 20px !important;
            }

            tr {
                th {
                    &:nth-child(2) {
                        @media only screen and (max-width: 767px) {
                            border-top-left-radius: 20px !important;
                        }
                    }

                    .betCol {
                        display: block;

                        @media only screen and (max-width: 767px) {
                            display: none;
                        }
                    }

                    .betColMob {
                        display: none;

                        @media only screen and (max-width: 767px) {
                            display: block;
                        }
                    }
                }
            }
        }

        tbody {
            td {
                &:first-child {
                    padding-left: 30px;
                }
            }
        }
    }
}


.filterMenuMob {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .menuFilter {
        max-width: 56px;
        width: 100%;
        margin-right: 0;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1.5px solid rgba(59, 77, 94, 0.5);
        border-radius: var(--Spacing-lg, 16px);
        background: rgba(14, 18, 22, 0.1);
        -webkit-backdrop-filter: blur(32px);
        backdrop-filter: blur(32px);

        @media only screen and (min-width: 768px) {
            display: none;
        }
    }

    .todayBox,
    .killBox,
    .winBox,
    .gamePerfoListBox {
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

}

.menuFilterMob {
    margin-top: 10px;

    @media only screen and (min-width: 768px) {
        display: none;
    }

    .gamePerfoList_left {
        gap: 10px;
        flex-wrap: wrap !important;
        width: 100%;

        // justify-content: flex-start !important;
        .gamePerfoListBox {
            flex: 0 0 48%;
            max-width: 48%;
            padding: 16px;
            border-radius: var(--Spacing-xl, 20px);
            border: 1px solid rgba(59, 77, 94, 0.5);
            opacity: var(--Corner-radius-xs, 1);
            background: rgba(14, 18, 22, 0.10);
            backdrop-filter: blur(32px);

            @media only screen and (max-width: 400px) {
                flex: 0 0 47%;
                max-width: 47%;
            }

            .gameDetails_title {
                color: #92A6BA;
                font-size: 14px;
                line-height: 24px;
                font-family: "Gilroy-Medium";
            }

            .earnCoin {
                font-size: 16px;
                line-height: 0;
                color: var(--Primary-Green---200---GB, #fff);
                margin-top: 12px;
font-family: "Gilroy-Medium";
                @media only screen and (max-width: 1400px) {
                    font-size: 14px;
                    line-height: 0;
                }
                visibility: hidden;
            }

            .totalPerfo {
                color: #fff;
                font-size: 14px;
                line-height: 24px;
                font-family: "Gilroy-Medium";
            }

            &.nobordergm {
                border: none !important;
            }
        }
    }
}


#ad-container {
    @media only screen and (max-width: 1024px) {
        width: 600px !important;
    }

    @media only screen and (max-width: 767px) {
        width: 400px !important;
    }

    @media only screen and (max-width: 375px) {
        width: 320px !important;
    }

    div {
        @media only screen and (max-width: 1024px) {
            width: 100% !important;
        }
    }
}

.main-dash-loader {
    @media only screen and (max-width: 1024px) {
        padding: 20px;
    }
    .main-dash-loader-cover{
        font-size: 18px;
        line-height: 24px;
        color: #5D7893;
        font-family: "Gilroy-Bold";
        width: 100%;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media only screen and (min-width: 1025px) {
            height: 300px;
        }
        padding: var(--Spacing-md, 50px);
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.5);
        background: var(--Background---grey-Grey---500---GB, #181f26);
        @media only screen and (min-width: 1025px) {
            margin: 50px 0;
        }
        .main-dash-loader-inner {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    } 
}

.crypto-flex {
    display: flex;
    @media only screen and (min-width: 768px) {
        align-items: flex-start;
        justify-content: space-between;
    }
    @media only screen and (max-width: 414px) {
       flex-direction: column;
    }
    .crypto-left {
        // flex: 0 0 60%;
        // max-width: 60%;
        @media only screen and (min-width: 768px) {
            flex: 0 0 30%;
                max-width: 30%;
        }
    }

    .crypto-right {
        // flex: 0 0 40%;
        // max-width: 40%;
        @media only screen and (min-width: 768px) {
            flex: 0 0 70%;
            max-width: 70%;
        }
        .redo-cryto-chart {
            display: flex;
            align-items: center;
            .redo-cryto-chart-graph {
                @media only screen and (max-width: 767px) {
                   flex-direction: column;
                }
            }

            .apexcharts-toolbar {
                display: none;
            }

            .apexcharts-gridline {
                display: none;
            }

            .apexcharts-gridlines-horizontal line {
                display: none;
            }

            .apexcharts-xaxis {
                display: none;
            }

            .apexcharts-yaxis {
                display: none;
            }

            .apexcharts-xaxis-tick {
                display: none;
            }

            .apexcharts-grid-borders {
                display: none;
            }

            .apexcharts-xaxistooltip {
                display: none;
            }

            .apexcharts-svg {
                    opacity: 0.1;
            }

            .apexcharts-tooltip-title{
                border: none; 
                background: #5a9650;
                                font-size: 12px !important;
                                    font-family: "Gilroy-Regular" !important;
            }

                        .apexcharts-tooltip-text {
                            font-size: 12px !important;
                                font-family: "Gilroy-Regular" !important;
                        }

            .apexcharts-tooltip {

                border: none;
                color: #fff;

                background: #5a9650;
                -webkit-backdrop-filter: blur(32px);
                backdrop-filter: blur(32px);
            }
            
            .upOrdown {
                @media only screen and (min-width: 1025px) and (max-width:1599px){
                    flex: 0 0 48%;
                    justify-content: flex-start !important;
                }
                span {
                   
                    font-family: "Gilroy-Bold";
                    font-size: 12px;
                    line-height: 22px;

                    @media only screen and (max-width: 767px) {
                        font-size: 16px;
                        line-height: 22.4px;
                    }
                    &.green{
                        color: #B2F35F;
                    }
                    &.red {
                        color: red;
                    }
                }
            }
        }

    }
}
.dashSep {
    padding: 15px;
}
.earnUSD {
    color: #c9fab2;
    // font-size: 18px;
    // line-height: 28px;
    // font-family: "Gilroy-Medium";
    font-size: 18px;
        line-height: 24px;
        font-family: "Gilroy-SemiBold";
}
.dashGameBoard{
    flex-direction: column;
    justify-content: center;
    @media only screen and (min-width: 1025px) {
        align-items: flex-end;
    }
}

.dash-gm-filter-inner-v1 {
        height: 300px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #181F26;
            border-radius: 9px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #74F33B;
            border-radius: 9px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #74F33B;
        }

        &.hideScroll {
            height: auto;
            overflow: hidden;
        }
}
.filterList {
    position: relative;
}
.mtimeposition {
    position: absolute;
    top: -30px;
    right: 8px;
    @media only screen and (max-width: 767px) {
        top: 15px;
            left: -60px;
    }
    color: #92A6BA;
    font-size: 14px;
    line-height: 24px;
    font-family: "Gilroy-Medium";
}
.dash-gm-filter{
    .dropdown-menu{
        margin-left: -65px !important;
    }
}
.betAttrVal {
    font-family: "Gilroy-SemiBold";
    color: #DBFFDF;
    font-size: 13px;
    line-height: 24px;
    @media only screen and (min-width: 1366px) {
        font-size: 18px;
        line-height: 24px;
    }
  
}

.kycWrap {
    margin: 10px 0;
    .v_kyc_btn {
            width: 175px;
            height: 48px;
            color: #0E1216;
            background-color: #F1FEEC !important;
            border-radius: 16px;
            font-family: "Gilroy-SemiBold";
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            text-decoration: none;
            text-align: center;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
}

.veriff-description {
    color: #FFF !important;
    text-align: center;
    font-family: "Gilroy-Medium";
    font-size: 15px;
    a{
        color: #FFF !important;
        text-align: center;
        font-family: "Gilroy-Medium";
        font-size: 15px !important;
    }
}

.kycmodal{
    .modal-dialog {
        @media only screen and (min-width: 1366px) {
            max-width: 1200px !important;
        }
    }  
    .betPopCloseBtn {
        width: 200px !important;
        margin: auto;
    }
}
.hc-banner{
    padding: 20px 0;
    img{
        width: 100%;
        cursor: pointer;
    }
}
.refreshOuter {
    display: flex;
    align-items: center;
    justify-content: center;

    .hc-refresh {
        margin-left: 10px;
    }
}
.rotateRefresh {
    animation: rotateRight 2s linear infinite;
}