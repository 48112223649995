@import "../fonts/stylesheet.css";

.footer {
  background-color: #000;

  .footer_row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 25px 0px;

    @media only screen and(min-width: 1024px) {
      padding: 50px 0px;
    }

    .col_2 {
      @media only screen and (max-width: 1200px) {
        width: 35%;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      h5 {
        font-family: "Poppins-Medium";
        font-weight: 500;
        letter-spacing: 0.08em;
        color: rgba(235, 235, 245, 0.6);
        font-size: 14px;
        margin-bottom: 30px;

        a {
          color: rgba(235, 235, 245, 0.6);
          font-family: "Poppins-Medium";
          font-weight: 500;
          letter-spacing: 0.08em;
          text-decoration: none;
        }
      }

      .footer_menu {
        padding-left: 0px;

        @media only screen and (max-width: 767px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        li {
          list-style: none;

          @media only screen and (max-width: 767px) {
            max-width: 48%;
            flex: 0 0 48%;
          }

          &._gb_desc {
            @media only screen and (max-width: 767px) {
              max-width: 100%;
              flex: 0 0 100%;
            }

            img {
              width: 200px;
            }
          }
        }

        p {
          font-family: "Gilroy-Medium";
          font-size: 15px;
          max-width: 356px;
          margin-top: 20px;
          color: #fff;
          line-height: 25px;
        }

        .menu_item {
          list-style: none;

          a {
            font-family: "Poppins-Medium";
            font-size: 15px;
            color: #fff;
            line-height: 28px;
            text-decoration: none;
          }
        }
      }

      .social_icons {
        display: flex;
        flex-direction: column;
        padding-left: 0px;

        li {
          list-style: none;
          height: 56px;
          min-width: 212px;
          border: 2px solid #2d2d2d;
          border-radius: 10px;
          text-align: center;
          margin-bottom: 10px;
          padding: 6px;
          position: relative;

          .app_tooltip {
            display: none;
          }

          &.app_store {
            opacity: 0.5;

            &:hover {
              .app_tooltip {
                display: block;
                position: absolute;
                color: #fff;
                top: 12px;
                right: -112px;
              }
            }
          }

          &.play_store {
            opacity: 0.5;

            &:hover {
              .app_tooltip {
                display: block;
                position: absolute;
                color: #fff;
                top: 12px;
                right: -112px;
              }
            }
          }
        }
      }
    }
  }
}

#store-popover {
  background: #181818;
  color: #fff;

  .popover-arrow {
    &::after {
      border-bottom-color: #181818 !important;
      border-top-color: #181818 !important;
    }
  }

  .popover-header {
    background: #181818;
    color: #fff;
    font-family: "Gilroy-SemiBold";
    text-align: center;

    &::before {
      border-bottom: #181818 !important;
    }
  }
}

#comingsoon-popover {
  background: #181818;
  color: #fff;

  .popover-arrow {
    &::after {
      border-bottom-color: #000 !important;
      border-top-color: #000 !important;
    }
  }

  .popover-header {
    background: #000;
    color: #fff;
    font-family: "Gilroy-SemiBold";
    text-align: center;
    font-size: 15px;
    border-radius: 6px !important;

    &::before {
      border-bottom: #181818 !important;
    }
  }
}

#comingsoon-right-popover {
  background: #181818;
  color: #fff;

  .popover-arrow {
    &::after {
      border-bottom-color: transparent !important;
      border-top-color: transparent !important;
      border-right-color: #000 !important;
    }
  }

  .popover-header {
    background: #000;
    color: #fff;
    font-family: "Gilroy-SemiBold";
    font-size: 15px;
    text-align: center;
    border-radius: 6px !important;

    &::before {
      border-bottom: #181818 !important;
    }
  }
}

/****************************copyright section start ************************/
.rights_reserved {
  background-color: #000;
  min-height: 150px;
  display: flex;
  align-items: center;
  border-top: 2px solid #2d2d2d;

  @media only screen and(max-width: 990px) {
    padding-bottom: 70px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @media only screen and(max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }

    .col_8 {
      flex: 0 0 75%;
      max-width: 75%;

      @media only screen and(max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      @media only screen and(min-width: 768px) and (max-width: 991px) {
        flex: 0 0 65%;
        max-width: 65%;
      }

      p {
        font-family: "Gilroy-Medium";
        font-size: 15px;
        line-height: 18px;
        color: rgba(235, 235, 245, 0.6);
        margin-bottom: 0 !important;
      }
    }

    .col_4 {
      flex: 0 0 25%;
      max-width: 25%;

      @media only screen and(max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      @media only screen and(min-width: 768px) and (max-width: 991px) {
        flex: 0 0 35%;
        max-width: 35%;
      }

      a {
        font-family: "Gilroy-Medium";
        font-size: 15px;
        line-height: 18px;
        text-decoration: none;
        color: rgba(235, 235, 245, 0.6);

        &:hover {
          opacity: 0.5;
        }
      }

      .privacy {
        margin-right: 10px;
      }
    }
  }
}

/****************************copyright section start ************************/

/****************************forPlayer section start ************************/
.for_players {
  @media only screen and (min-width: 1366px) {
    background-size: 52%;
  }

  @media only screen and (min-width: 1400px) {
    background-image: url("../images/gb-mobile-screens.png");
    background-repeat: no-repeat;
    height: 924px;
    background-position: right;
    display: flex;
    align-items: center;
  }

  background-color: #000 !important;

  //   min-height: 740px;
  //   padding: 70px 0px;
  .forplayer-row {
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .left {

      //margin-left: 104px;
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-top: 35px;
        margin-bottom: 35px;
        text-align: center;
      }

      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        padding: 50px 0;
      }

      .title {
        font-family: "Poppins-Bold";
        color: #ffffff;
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 30px;

        @media only screen and (min-width: 768px) {
          font-size: 40px;
          line-height: 50px;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 58px;
          line-height: 70px;
        }
      }

      .sub_title {
        color: #fff;
        font-family: "Gilroy-Medium";
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 35px;
        max-width: 525px;
      }

      .register_btn {
        height: 56px;
        max-width: 456px;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border: 2px solid #fff;
        border-radius: 10px;
        padding: 17px 50px;
        text-decoration: none;
        color: #fff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;

        @media only screen and (max-width: 1024px) {
          padding: 12px 9px;
        }

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .right {
      padding-right: 0px;

      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-top: 30px;
      }

      img {
        // height: 740px;
        width: 100%;

        &.mobile-only {
          display: block;

          @media only screen and (min-width: 1400px) {
            display: none;
          }
        }
      }
    }
  }
}

/****************************forPlayers section end ************************/

/****************************forStreamers section start ************************/
.for_streamers {
  // background: linear-gradient(
  //   221.85deg,
  //   #c80000 -61.37%,
  //   rgba(200, 0, 0, 0) 39.62%
  // );
  background-color: #000;
  padding: 150px 0px;

  @media only screen and(max-width: 1023px) {
    padding: 50px 0px;
  }

  .forstreamers-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 991px) {
      flex-direction: column;
    }

    .left {
      position: relative;

      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      img {
        height: 493px;

        @media only screen and (max-width: 767px) {
          width: 100%;
          height: auto;
        }
      }

      .streamer_content {
        position: absolute;
        // border: 1px solid #e52420;
        background: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        max-width: 350px;
        border-radius: 12px;
        color: #fff;
        font-family: "Gilroy-Medium";
        font-size: 21px;
        line-height: 31px;
        margin: auto;
        height: 150px;
        bottom: 31px;
        left: -23px;
        padding: 25px;

        @media only screen and (max-width: 374px) {
          font-size: 18px;
          line-height: 23px;
          height: 102px;
          bottom: -8px;
          left: 2px;
        }
      }
    }

    .right {
      padding-left: 20px;

      @media only screen and (max-width: 991px) {
        width: 100%;
        margin-top: 20px;
        padding-left: 0px;
      }

      @media only screen and (max-width: 767px) {
        text-align: center;
      }

      .title {
        font-family: "Poppins-Bold";
        color: #ffffff;
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 30px;

        @media only screen and (min-width: 768px) {
          font-size: 38px;
          line-height: 50px;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 58px;
          line-height: 70px;
        }
      }

      .sub_title {
        font-family: "Gilroy-Medium";
        font-size: 18px;
        line-height: 28px;
        color: #fff;
        max-width: 549px;
        padding-bottom: 50px;

        @media only screen and (max-width: 767px) {
          padding-bottom: 20px;
        }
      }

      .register_btn {
        height: 56px;
        max-width: 456px;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border: 2px solid #fff;
        border-radius: 10px;
        padding: 17px 50px;
        text-decoration: none;
        color: #fff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;

        @media only screen and (max-width: 767px) {
          padding: 12px 9px;
        }

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

/****************************for_streamers section end ************************/

/****************************video-section end ************************/
.video_section {
  padding: 100px 0px;

  @media only screen and(max-width: 1023px) {
    padding: 50px 0px;
  }

  background-color: #000;

  .container {
    //background-image: url("../images/video.png");
    //height: 593px;
    position: relative;

    .embed-sect {
      text-align: center;

      iframe {
        height: 200px;

        @media only screen and (min-width: 768px) {
          height: 400px;
        }

        @media only screen and (min-width: 992px) {
          height: 593px;
        }

        @media only screen and (min-width: 1700px) {
          height: 800px;
        }
      }
    }

    .video_content {
      text-align: center;
      margin-bottom: 35px;

      .title {
        font-family: "Poppins-Bold";
        color: #ffffff;
        font-size: 28px;
        line-height: 38px;

        @media only screen and (min-width: 768px) {
          font-size: 35px;
          line-height: 50px;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 50px;
          line-height: 65px;
        }
      }

      .sub_title {
        font-family: "Gilroy-Medium";
        font-size: 18px;
        line-height: 28px;
        color: #fff;
      }
    }

    &::after {
      // content: url("../images/play_button.png");
      // position: relative;
      // left: 90%;
      // top: 350px;
      // height: 109px;
      // width: 109px;
      // @media only screen and (max-width: 767px) {
      //   left: 40%;
      //   top: 180px;
      // }
      // @media only screen and (min-width: 768px) and (max-width: 989px) {
      //   left: 82%;
      // }
    }
  }
}

/****************************video-section end ************************/

/****************************subcribeSection section Start ************************/
.subcribe_section {
  background-color: #161515;
  min-height: 218px;

  .error {
    font-family: "Gilroy-Medium";
    color: #c8211d;
    padding: 5px;
    font-size: 14px;
  }

  .sucsess-msg {
    font-family: "Gilroy-Medium";
    color: #00a900;
    padding: 5px;
    font-size: 15px;
  }

  .error-msg {
    font-family: "Gilroy-Medium";
    color: #c8211d;
    padding: 5px;
    font-size: 15px;
  }

  .subcribe_section_inner {
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      margin: 15px;
    }

    .left {
      @media only screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
      }

      .title {
        font-family: "Poppins-Medium";
        font-size: 23px;
        color: #fff;
      }

      .sub_title {
        font-family: "Gilroy-Medium";
        font-size: 14px;
        line-height: 25px;
        color: #fff;
        margin-bottom: 10px;
      }

      .subscribe_form {
        @media only screen and (max-width: 767px) {
          align-items: center;
        }

        @media only screen and (max-width: 992px) {
          flex-direction: column;
        }

        @media only screen and (min-width: 768px) and (max-width: 992px) {
          align-items: left;
        }

        input {
          background: #000000;
          border: 2px solid #262627;
          border-radius: 20px;
          max-width: 530px;
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 19px;

          @media only screen and (min-width: 768px) and (max-width: 1200px) {
            max-width: 475px;
          }
        }

        .subscribe_btn {
          max-width: 232px;
          height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 45px;
          text-decoration: none;
          margin-left: 15px;

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }

          @media only screen and (max-width: 992px) {
            margin-top: 15px;
          }

          @media only screen and (max-width: 767px) {
            margin-left: 0px;
          }

          @media only screen and (min-width: 768px) and (max-width: 1200px) {
            padding: 18px 35px;
          }

          @media only screen and (min-width: 1366px) and (max-width: 1600px) {
            min-width: 232px !important;
          }
        }
      }
    }

    .right {
      @media only screen and (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin-top: 15px;
      }

      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
        margin-left: 20px;
      }

      .join_title {
        font-family: "Poppins-Medium";
        font-size: 23px;
        color: #fff;
        margin-bottom: 40px;
        padding-top: 4px;

        @media only screen and (min-width: 1600px) {
          margin-bottom: 60px;
        }

        @media only screen and (min-width: 1366px) and (max-width: 1600px) {
          font-size: 20px;
        }

        @media only screen and (max-width: 1200px) {
          font-size: 20px;
        }

        @media only screen and (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      img {
        margin-right: 20px;

        @media only screen and(min-width: 768px) and (max-width: 990px) {
          margin-bottom: 15px;
        }
      }

      .forum {
        color: #fff;
        font-family: "Gilroy-SemiBold";
        font-size: 13px;
        border: 1.5px solid #e52420;
        box-shadow: 0px 0px 4px #e52420;
        background: #000;
        border-radius: 7px;
        text-decoration: none;
        padding: 6px 15px;

        &:hover {
          opacity: 0.5;
        }
      }

      .discord_cls {
        img {
          width: 40px;
        }
      }

      .twitter_cls {
        img {
          width: 40px;
        }
      }
    }
  }
}

/****************************subcribeSection section end ************************/

/****************************about section start ************************/
// ._GB_About {
//   padding: 70px 0px !important;
//   .row {
//     @media only screen and (max-width: 767px) {
//       flex-direction: column;
//     }
//     .col-3 {
//       @media only screen and (max-width: 767px) {
//         width: 100%;
//       }
//       ._Title {
//         font-family: "Poppins-Bold";
//         line-height: 55px;
//         color: #ffffff;
//         font-size: 44px;
//         min-width: 470px;
//       }
//     }
//     .col-7 {
//       padding-left: 50px;
//       @media only screen and(max-width: 767px) {
//         width: 100%;
//         padding-left: 0px;
//       }
//       ._SubTitle {
//         color: #fff;
//         font-family: "Gilroy-Medium";
//         font-size: 18px;
//         line-height: 28px;
//         max-width: 580px;
//         margin-bottom: 15px;
//       }
//       button {
//         min-width: 232px;
//         height: 56px;
//         color: #fff;
//         background-color: #e52420;
//         background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
//         border-radius: 10px;
//         border: 2px solid #ffffff;
//         font-family: "Poppins-SemiBold";
//         font-size: 15px;
//         line-height: 17px;
//         text-transform: uppercase;
//         letter-spacing: 0.04em;
//         padding: 15px 50px;
//         text-decoration: none;

//         &::after {
//           content: url("../images/arrow.png");
//           padding-left: 10px;
//         }
//         &:hover {
//           opacity: 0.5;
//         }
//       }
//     }
//   }
// }
/****************************about section start ************************/
/****************************HomeBanner start ************************/
// ._GB_Home {
//   ._GB_Home_Banner {
//     color: #fff;
//     ._Title {
//       font-family: "Gilroy-Bold";
//       font-size: 30px;
//       line-height: 40px;
//       margin-bottom: 20px;
//       @media only screen and (min-width: 991px) {
//         font-size: 40px;
//         line-height: 50px;
//       }
//       @media only screen and (min-width: 1366px) {
//         font-size: 65px;
//         line-height: 65px;
//       }
//     }
//     ._SubTitle {
//       font-family: "Gilroy-Medium";
//       font-size: 19px;
//       line-height: 27px;
//       @media only screen and (min-width: 1366px) {
//         margin-bottom: 20px;
//       }
//     }
//   }
// }

/****************************HomeBanner end ************************/

/****************************dashboardBanner start ************************/
._GB_Dashboard {
  ._GB_Header {
    background-color: #000;
  }

  ._GB_Dashboard_Banner {
    background-color: #000;
    padding: 20px 0px;

    @media only screen and(max-width: 767px) {
      padding: 20px 0px;
    }

    @media only screen and(max-width: 490px) {
      padding: 20px 15px;
    }

    // .container{
    //   @media only screen and(max-width: 767px){
    //     max-width: 100%;
    //     padding-right: 0;
    //   }
    // }
    ._GB_Dashboard_Inner {
      .profile {
        margin-bottom: 20px;

        @media only screen and(max-width: 991px) {
          justify-content: flex-end;
        }

        .left_section {
          display: flex;
          align-items: center;

          @media only screen and(max-width: 574px) {
            flex-direction: column;
            justify-content: center;
          }

          .profile_image {
            img {
              border: 4.5px solid #c80000;
              border-radius: 100%;
              width: 105px;
              height: 105px;
              object-fit: cover;

              @media only screen and(max-width: 767px) {
                width: 80px;
                height: 80px;
              }
            }
          }

          ._Title {
            color: #fff;
            font-family: "Gilroy-Bold";
            font-size: 25px;
            line-height: 40px;
            margin-left: 10px;

            @media only screen and (min-width: 991px) {
              font-size: 40px;
              line-height: 50px;
            }

            @media only screen and (min-width: 1366px) {
              font-size: 50px;
              line-height: 55px;
            }

            @media only screen and (max-width: 574px) {
              text-align: center;
            }
          }
        }
      }

      .profile_details {
        @media only screen and(max-width: 1300px) {
          flex-direction: column;
        }

        .left_section {
          @media only screen and(max-width: 1300px) {
            width: 100%;
          }

          .game_details {
            padding-top: 10px;

            @media only screen and(max-width: 767px) {
              flex-direction: column;
              // width: 100%;
              // overflow-x: scroll;
            }

            @media only screen and(min-width: 768px) {
              justify-content: space-between;
            }

            // @media only screen and(max-width: 1423px){
            //   justify-content: center!important;
            // }
            .total {

              // padding: 10px 15px 10px 0px;
              @media only screen and (max-width: 767px) {
                margin-bottom: 15px;
                // max-width: 70%;
                // flex: 0 0 70%;
                // margin-right: 15px;
              }

              &:not(:last-child) {
                @media only screen and (min-width: 768px) and (max-width: 1300px) {
                  margin-right: 20px;
                }
              }

              .items_inner {
                background: #181818;
                border: 2px solid #2d2d2d;
                border-radius: 20px;
                width: 100%;
                padding: 15px;

                @media only screen and (min-width: 768px) {
                  min-height: 293px;
                  max-width: 516px;
                }

                @media only screen and (max-width: 400px) {
                  padding: 10px;
                }

                @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                  min-height: 152px;
                }

                @media only screen and (min-width: 1300px) and (max-width: 1440px) {
                  width: 100%;
                }

                @media only screen and (min-width: 1441px) and (max-width: 1600px) {
                  // width: 210px;
                  padding: 10px;
                }

                @media only screen and (max-width: 767px) {
                  margin: auto;
                  flex-direction: row !important;
                  height: 84px;
                  max-width: 98%;
                }

                @media only screen and (max-width: 490px) {
                  margin: auto;
                  max-width: 100%;
                }

                .desc-sect {
                  @media only screen and (max-width: 767px) {
                    display: flex;
                    align-items: center;
                    flex: 0 0 60%;
                    max-width: 60%;
                    gap: 13px;
                  }

                  @media only screen and (max-width: 400px) {
                    gap: 10px;
                  }

                  @media only screen and (min-width: 1024px) and (max-width: 1919px) {
                    min-height: 152px;
                    text-align: center;
                  }

                  @media only screen and (min-width: 768px) {
                    min-height: 208px;
                    text-align: center;
                  }

                  img {
                    height: 62px;
                  }

                  .title {
                    color: #fff;
                    font-family: "Gilroy-Bold";
                    font-size: 18px;
                    line-height: 20px;
                    margin-top: 15px;
                    text-align: center;

                    @media only screen and (max-width: 767px) {
                      margin-top: 0px;
                      font-size: 12px;
                      line-height: 14px;
                    }

                    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                      font-size: 14px;
                      line-height: 16px;
                    }

                    @media only screen and (min-width: 1601px) {
                      font-size: 20px;
                      line-height: 23px;
                    }
                  }

                  .sub_content {
                    color: #fff;
                    font-family: "Gilroy-Bold";
                    font-size: 20px;
                    line-height: 20px;
                    margin-top: 20px;

                    @media only screen and (max-width: 767px) {
                      margin-top: 5px;
                    }

                    @media only screen and (min-width: 1601px) {
                      font-size: 31px;
                      line-height: 31px;
                    }

                    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                      font-size: 22px;
                      line-height: 25px;
                      margin-top: 5px;
                    }

                    &.gameplaytime {
                      color: #ffc01f;
                      font-size: 14px;
                      line-height: 20px;
                      margin-top: 20px;

                      @media only screen and (max-width: 767px) {
                        margin-top: 5px;
                      }

                      @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                        font-size: 14px;
                        line-height: 16px;
                      }

                      @media only screen and (min-width: 1601px) {
                        font-size: 20px;
                        line-height: 24px;
                      }
                    }
                  }

                  .token_dollar {
                    color: #ffc01f;
                    // font-family: "Gilroy-Bold";
                    font-family: "Poppins-Medium";
                    font-size: 16px;
                    line-height: 16px;
                    margin-top: 10px;

                    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                      font-size: 14px;
                      line-height: 16px;
                    }

                    @media only screen and (min-width: 1601px) {
                      font-size: 22px;
                      line-height: 25px;
                    }
                  }
                }
              }

              @media only screen and (min-width: 1601px) {
                flex: 0 0 31%;
                max-width: 31%;
              }

              .game_drop {
                background-color: #050505;
                border-radius: 10px;
                border-color: #050505;
                color: #fff;
                min-width: 150px;
                padding: 5px 15px;
                margin-top: 15px;

                @media only screen and (min-width: 768px) {
                  margin-top: 0px;
                }

                @media only screen and (min-width: 1200px) and (max-width: 1600px) {
                  min-width: 116px;
                  margin-top: 0px;
                  font-size: 12px !important;
                }

                @media only screen and (min-width: 1601px) {
                  margin-top: 15px;
                }

                @media only screen and (max-width: 767px) {
                  margin-top: 0px;
                  flex: 0 0 39%;
                  max-width: 39%;
                }

                @media only screen and (max-width: 400px) {
                  min-width: 110px;
                }

                &:hover {
                  border-color: #050505;
                }

                option {
                  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                    font-size: 12px;
                    line-height: 12px;
                  }
                }
              }
            }

            .total_time {
              .game_drop {
                //margin-top: 50px;
              }
            }
          }

          ._GB_News_Inner {
            padding-top: 25px;

            // @media only screen and (max-width: 767px) {
            //   padding-top: 25px;
            // }
            // @media only screen and (max-width: 767px){
            //   padding-right: var(--bs-gutter-x, 0.75rem);
            // }
            .news_right {
              @media only screen and (max-width: 1300px) {
                margin: auto;
                width: 100%;
              }

              .newstitle {
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 16px;

                @media only screen and (min-width: 1366px) {
                  font-size: 20px;
                  line-height: 23px;
                }
              }

              .slider_arrows {
                img {
                  height: 23px;
                }

                .slider_arrow {
                  padding-left: 10px;
                  cursor: pointer;
                }
              }

              .news_slider {
                margin-top: 10px;

                @media only screen and (min-width: 768px) and(max-width: 1365px) {
                  flex: 0 0 100%;
                  max-width: 100%;
                }

                @media only screen and (max-width: 767px) {
                  flex-direction: column;
                }

                .news_slider_item {
                  width: 48%;

                  @media only screen and (max-width: 767px) {
                    width: 100%;
                  }

                  @media only screen and (min-width: 768px) and (max-width: 1300px) {
                    width: 49%;
                  }

                  .item_inner {
                    background: #151515;
                    border-radius: 20px;
                    padding: 10px 20px;
                    height: 188px;

                    @media only screen and (max-width: 767px) {
                      margin-bottom: 15px;
                    }

                    @media only screen and (min-width: 1424px) and (max-width: 1600px) {
                      padding: 10px;
                    }

                    @media only screen and(max-width: 374px) {
                      padding: 10px 15px;
                    }

                    .new_slider_details {
                      padding: 15px;

                      @media only screen and(max-width: 374px) {
                        padding: 10px;
                      }

                      .slider_news_title {
                        font-family: "Poppins-Bold";
                        color: #fff;
                      }

                      .slider_news_content {
                        font-family: "Gilroy-Medium";
                        color: #fff;
                        padding: 10px 0;
                      }

                      .slider_news_date {
                        font-family: "Gilroy-Medium";
                        font-size: 15px;
                        line-height: 100%;
                        color: #ffffff;
                        opacity: 0.5;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .right_section {
          padding-left: 20px;

          @media only screen and(max-width: 1300px) {
            width: 100%;
            padding-left: 0;
            padding-top: 15px;
          }

          @media only screen and (max-width: 767px) {
            padding-top: 0px;
          }

          // @media only screen and(max-width: 767px){
          //   padding-right: var(--bs-gutter-x, 0.75rem);
          // }
          .most_played {
            position: relative;

            .game_mode_dd {
              position: absolute;
              top: 30px;
              left: 30px;

              @media only screen and (max-width: 767px) {
                top: 20px;
                left: 10px;
              }

              #dropdown-gmode-button {
                background: #181818;
                border: 2px solid #2d2d2d;
                border-radius: 15px;
                color: #fff;
                font-family: "Gilroy-Bold";
                outline: none;

                @media only screen and (max-width: 767px) {
                  font-size: 14px;
                  line-height: 14px;
                }

                @media only screen and (max-width: 400px) {
                  font-size: 10px;
                  line-height: 10px;
                }

                @media only screen and (min-width: 1366px) and (max-width: 1919px) {
                  font-size: 12px;
                }

                &:focus {
                  box-shadow: none;
                }
              }

              .dropdown-menu.show {
                background-color: #181818 !important;

                a {
                  color: #fff;
                  font-family: "Gilroy-Bold";
                  background-color: #181818 !important;
                }
              }
            }

            img {
              width: 100%;
              padding: 10px 0px;
              border-radius: 25px;

              @media only screen and(max-width: 1300px) {
                object-fit: contain;
              }

              // @media only screen and(min-width: 1366px) and (max-width: 1600px) {
              //   height: 261px !important;
              // }
              @media only screen and (min-width: 1601px) {
                height: 435px;
              }
            }

            .game_title {
              position: absolute;
              bottom: 38px;
              left: 20px;
              font-size: 24px;
              line-height: 28px;
              font-family: "Gilroy-Bold";
              color: #fff;

              @media only screen and (min-width: 575px) {
                font-size: 35px;
                line-height: 35px;
                bottom: 40px;
                left: 41px;
                bottom: 21px;
              }

              @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                font-size: 35px;
                line-height: 41px;
              }

              @media only screen and (min-width: 1601px) {
                font-size: 51px;
                line-height: 70px;
              }
            }
          }

          .dashboard_rewards {
            @media only screen and (max-width: 767px) {
              flex-direction: row;
            }

            .reward_items {
              background: #181818;
              border: 2px solid #2d2d2d;
              box-sizing: border-box;
              border-radius: 20px;
              padding: 20px 16px;
              margin: 23px 8px 0px;
              align-items: center;
              width: 210px;

              &:hover {
                border-color: #18a0fb;
              }

              @media only screen and (max-width: 767px) {
                margin: 5px 5px 15px;
                width: 100%;
                flex-direction: column;
                padding: 5px;
              }

              @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                padding: 15px 5px;
                margin: 1px 8px 0px;
              }

              .rewards_image {
                img {
                  width: 50px;

                  @media only screen and (max-width: 767px) {
                    width: 35px;
                  }

                  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                    width: 35px;
                  }
                }
              }

              .rewards_data {
                padding-left: 15px;

                @media only screen and (max-width: 767px) {
                  padding-left: 0;
                  text-align: center;
                }

                @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                  padding-left: 10px;
                }

                .reward_head {
                  font-family: "Gilroy-Medium";
                  font-size: 16px;
                  color: #fff;

                  @media only screen and (max-width: 767px) {
                    font-size: 12px;
                  }

                  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                    font-size: 14px;
                    line-height: 16px;
                  }
                }

                .reward_score {
                  font-family: "Gilroy-Medium";
                  font-size: 15px;
                  color: #fff;
                  opacity: 0.5;

                  @media only screen and (max-width: 767px) {
                    font-size: 12px;
                  }

                  @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                    font-size: 11px;
                    line-height: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .no_game_banner {
    background-color: #000;
    padding: 50px 0 70px 0;
  }

  &.lang_es {
    ._GB_Dashboard_Banner {
      ._GB_Dashboard_Inner {
        .profile_details {
          .left_section {
            .game_details {
              .total {
                .items_inner {
                  .desc-sect {
                    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                      min-height: 142px;
                    }

                    @media only screen and (min-width: 1601px) {
                      min-height: 210px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.token_tooltip {
  opacity: 1 !important;
  padding: 9px 0 !important;

  .tooltip-arrow {
    color: #181818 !important;

    &::before {
      top: -4.5px;
      left: -25px;
      content: "";
      background-image: url("../images/arrow-black.png");
      background-repeat: no-repeat;
      border-width: 25px;
      border-top: none;
    }
  }

  .tooltip-inner {
    background-color: #181818 !important;
    border: 2px solid #2d2d2d !important;
    font-family: "Gilroy-Bold";
    border-radius: 10px !important;
  }
}

#dropdown-profile {
  background-color: #000;
  border-color: #000;
  box-shadow: none;
  align-items: center;
  display: flex;
}

.profile_list_dd {
  .dropdown-item {
    background: none;

    &:hover {
      background: none;
    }

    &.mode_type {
      &:hover {
        background-color: #181818;
      }
    }

    &.community_tab {
      color: #979797 !important;

      &:hover {
        background-color: #181818 !important;
        color: #fff !important;
      }
    }

    .profile_dd_each {
      a {
        color: #979797 !important;
        text-decoration: none;

        &:hover {
          color: #fff !important;
        }
      }
    }
  }

  hr {
    color: #2d2d2d;
    opacity: 1;
  }
}

#dropdown-language {
  background-color: #000;
  border-color: #000;
  box-shadow: none;
  font-family: "Gilroy-Bold";
  border: 2px solid #2d2d2d;
}

.dropdown-menu {
  background: #181818;
  border: 2px solid #2d2d2d;
  border-radius: 14px;
  padding: 10px;

  .dropdown-item {
    color: #fff;
    font-family: "Gilroy-Bold";
    font-size: 14px;
    padding: 8px 0px;

    &:hover {
      background: #000000;
      border-radius: 14px;
      color: #e52420;
    }

    .lang_dd_each {
      text-align: center;
    }
  }
}

/****************************dashboardBanner end ************************/

/****************************Dashboard_Fortnite_Stats start************************/
._Dashboard_Fortnite_Stats {
  background-color: #000;
  padding: 50px 0px;

  @media only screen and (max-width: 767px) {
    padding: 0px;
  }

  @media only screen and (max-width: 490px) {
    padding: 0px 15px;
  }

  ._Fortnite_Stats_inner {
    .container {
      @media only screen and (max-width: 767px) {
        max-width: 100%;
      }
    }

    ._Fortnite_Stats_top {
      margin-bottom: 40px;

      ._Title {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 28px;
        line-height: 40px;
        margin-left: 10px;

        @media only screen and (min-width: 991px) {
          font-size: 38px;
          line-height: 50px;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 58px;
          line-height: 58px;
        }
      }

      .change_game {
        .change {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 0px;
        }

        .games_list {
          cursor: pointer;

          img {
            background: #000000;
            border: 2px solid #484848;
            //border-radius: 100%;
            border-radius: 10px;
            height: 65px;
            width: 65px;
            padding: 6px;

            @media only screen and (min-width: 576px) {
              padding: 12px;
            }

            &:hover {
              background: rgba(200, 0, 0, 0.5);
              border-color: #c80000;
            }
          }

          &.active {
            img {
              background: rgba(200, 0, 0, 0.5);
              border-color: #c80000;
            }
          }
        }
      }
    }

    ._Fortnite_Stats_content {
      @media only screen and (max-width: 1300px) {
        flex-direction: column;
      }

      .left_section {
        @media only screen and (max-width: 1300px) {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .today_session {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px;
          margin-bottom: 20px;

          @media only screen and (max-width: 1424px) {
            width: 48%;
            display: flex;
            margin-bottom: 20px;
          }

          @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 15px 10px;
          }

          @media only screen and (min-width: 1366px) and (max-width: 1600px) {
            padding: 15px 10px;
            height: 140px;
            margin-bottom: 5px;
          }

          .title {
            .title_left {
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 20px;
              line-height: 20px;
            }

            .title_right {
              margin-left: 10px;

              @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                margin-left: 0px;
              }

              @media only screen and (max-width: 767px) {
                margin-left: 0;
              }

              img {
                height: 24px;
                cursor: pointer;

                @media only screen and (min-width: 391px) and (max-width: 767px) {
                  height: 15px;
                  width: 15px;
                }

                &:hover {
                  opacity: 0.5;
                }
              }

              p {
                font-family: "Gilroy-Bold";
                color: rgba(235, 235, 245, 0.6);
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 0px;
                margin-left: 10px;

                @media only screen and (max-width: 767px) {
                  margin-left: 0px;
                  font-size: 11px !important;
                }
              }
            }
          }

          .second-section {
            margin-top: 15px;

            @media only screen and (max-width: 374px) {
              flex-direction: column;
              align-items: center;
            }

            @media only screen and (max-width: 767px) {
              margin-top: 10px;
            }

            @media only screen and (min-width: 1366px) and (max-width: 1600px) {
              margin-top: 10px;
            }

            .second-left {
              img {
                height: 44px;

                @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                  height: 33px;
                }

                @media only screen and (max-width: 767px) {
                  height: 21px;
                }
              }

              h3 {
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 0px;
                margin-left: 10px;

                @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                  font-size: 22px;
                  line-height: 25px;
                }

                @media only screen and (min-width: 1601px) {
                  font-size: 35px;
                  line-height: 35px;
                }
              }
            }

            .second-right {
              margin: auto 0px;
              // .earning_btn {
              //   background-color: #e52420;
              //   background-image: linear-gradient(
              //     to right,
              //     #6d1513,
              //     #e52420,
              //     #6d1513
              //   );
              //   border: 2px solid #fff;
              //   border-radius: 10px;
              //   padding: 12px 20px;
              //   text-decoration: none;
              //   color: #fff;
              //   font-family: "Poppins-SemiBold";
              //   font-size: 15px;
              //   line-height: 17px;
              //   text-transform: uppercase;
              //   letter-spacing: 0.04em;
              //   &:hover {
              //     opacity: 0.5;
              //   }
              // }
            }
          }

          .last-section {
            .token_dollar {
              color: #ffc01f;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 16px;
              margin-top: 10px;

              @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                font-size: 16px;
                line-height: 16px;
              }

              @media only screen and (min-width: 1601px) {
                font-size: 22px;
                line-height: 25px;
              }
            }

            .menu-points {
              color: #40ab00;

              img {
                height: 15px;
              }
            }
          }
        }

        .reward_session {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px;

          @media only screen and (max-width: 1424px) {
            width: 48%;
            display: flex;
            //margin-bottom: 20px;
          }

          @media only screen and (max-width: 767px) {
            margin-bottom: 10px;
            width: 100%;
            padding: 15px 10px;
          }

          @media only screen and (min-width: 768px) {
            min-height: 160px;
          }

          @media only screen and (min-width: 1366px) and (max-width: 1600px) {
            min-height: 140px;
            margin-bottom: 5px;
          }

          .title-section {
            margin-bottom: 20px;

            @media only screen and (min-width: 1366px) and (max-width: 1600px) {
              margin-bottom: 10px;
            }

            @media only screen and (max-width: 767px) {
              margin-bottom: 10px;
            }

            ._title {
              color: #fff;

              @media only screen and (max-width: 374px) {
                align-items: center;
              }

              a {
                color: #fff;
                text-decoration: none;
              }

              font-family: "Gilroy-Bold";
              font-size: 20px;
              line-height: 20px;
            }
          }

          .second-section {
            @media only screen and (max-width: 374px) {
              flex-direction: column;
              align-items: center;
            }

            .token-section {
              img {
                height: 51px;

                @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                  height: 33px;
                }

                @media only screen and (max-width: 767px) {
                  height: 25px;
                }
              }

              p {
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 0px;
                margin-left: 10px;
                max-width: 166px;
              }
            }

            // .right-section {
            //   .go_btn {
            //     a {
            //       text-decoration: none;
            //       color: #fff;
            //     }
            //     background-color: #e52420;
            //     background-image: linear-gradient(
            //       to right,
            //       #6d1513,
            //       #e52420,
            //       #6d1513
            //     );
            //     border: 2px solid #fff;
            //     border-radius: 10px;
            //     padding: 12px 20px;
            //     text-decoration: none;
            //     color: #fff;
            //     font-family: "Poppins-SemiBold";
            //     font-size: 15px;
            //     line-height: 17px;
            //     text-transform: uppercase;
            //     letter-spacing: 0.04em;
            //     min-width: 106px;
            //     &:hover {
            //       opacity: 0.5;
            //     }
            //   }
            // }
          }

          .earning_btn {
            background-color: #e52420;
            background-image: linear-gradient(to right,
                #6d1513,
                #e52420,
                #6d1513);
            border: 2px solid #fff;
            border-radius: 10px;
            padding: 12px 40px;
            text-decoration: none;
            color: #fff;
            font-family: "Poppins-SemiBold";
            font-size: 15px;
            line-height: 17px;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            max-width: 170px;

            @media only screen and (min-width: 1366px) and (max-width: 1600px) {
              padding: 12px 20px;
              font-size: 11px;
            }

            @media only screen and (min-width: 391px) and (max-width: 767px) {
              font-size: 11px;
              line-height: 11px;
            }

            &:hover {
              opacity: 0.5;
            }
          }
        }
      }

      .right_section {
        padding-left: 15px;

        @media only screen and (max-width: 1300px) {
          width: 100%;
          padding-left: 0px;
        }

        .daily_challenge {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 25px;

          .title_section {
            padding-bottom: 10px;

            @media only screen and (max-width: 767px) {
              flex-direction: column;
            }

            .left {
              @media only screen and (max-width: 767px) {
                text-align: center;
              }

              h3 {
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 20px;
                line-height: 23px;
              }
            }

            .right {
              @media only screen and (max-width: 767px) {
                flex-direction: column;
                text-align: center;
              }

              p {
                color: rgba(235, 235, 245, 0.6);
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 18px;
                margin-bottom: 0px;
                margin-left: 10px;

                @media only screen and (max-width: 767px) {
                  margin-left: 0px;
                }

                &:hover {
                  color: #83d7ff;
                }
              }

              .active {
                color: #83d7ff;
              }

              .upgrade {
                a {
                  color: rgba(235, 235, 245, 0.6);
                  text-decoration: none;

                  &:hover {
                    color: #83d7ff;
                  }
                }
              }

              .view_all {
                margin-left: 20px;

                @media only screen and (max-width: 767px) {
                  margin-left: 0px;
                }

                a {
                  color: rgba(235, 235, 245, 0.6);
                  text-decoration: none;

                  &:hover {
                    color: #83d7ff;
                  }
                }
              }
            }
          }

          .daily_status {
            justify-content: space-between;
            flex-wrap: wrap;

            @media only screen and (max-width: 425px) {
              justify-content: center;
            }

            .game-status {
              @media only screen and(min-width: 768px) {
                flex: 0 0 19%;
                max-width: 19%;
              }

              @media only screen and (max-width: 415px) {
                margin: auto;
              }

              img {
                padding-bottom: 15px;

                // padding-right: 9px;
                @media only screen and (max-width: 374px) {
                  padding-right: 0;
                }

                @media only screen and (min-width: 768px) and (max-width: 992px) {
                  padding-top: 15px;
                }
              }

              .fax_box {
                background: #050505;
                border: 2px solid #050505;
                box-sizing: border-box;
                border-radius: 16px;
                padding: 13px 15px;
                text-align: center;
                max-width: 150px;

                @media only screen and(max-width: 767px) {
                  margin: auto auto 15px;
                }

                &:hover {
                  border-color: #83d7ff;
                }

                p {
                  color: #fff;
                  font-family: "Gilroy-Bold";
                  font-size: 16px;
                  line-height: 18px;
                  margin-bottom: 0px;
                }

                h3 {
                  color: #fff;
                  font-family: "Gilroy-Bold";
                  font-size: 18px;
                  line-height: 18px;
                  margin-bottom: 0px;

                  @media only screen and (min-width: 1366px) {
                    font-size: 30px;
                    line-height: 35px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .leaderboard-section {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;

      .leaderboard-wrap {
        padding: 30px;

        @media only screen and(max-width: 767px) {
          padding: 20px;
        }

        @media only screen and(min-width: 1366px) and (max-width: 1600px) {
          padding: 10px 20px;
        }

        .title-section {
          @media only screen and (max-width: 767px) {
            flex-direction: row !important;
            // text-align: center;
            margin-bottom: 20px;
          }

          .left {
            @media only screen and (max-width: 767px) {
              flex-direction: column;
              max-width: 45%;
            }

            h3 {
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 20px;
              line-height: 20px;
              margin-bottom: 0px;

              @media only screen and (max-width: 767px) {
                font-size: 16px;
              }
            }

            .event {
              margin-left: 20px;

              // img {
              //   height: 15px;
              // }
              @media only screen and (max-width: 767px) {
                margin-left: 0;
                width: 100%;
                margin-top: 5px;
              }

              p {
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 18px;
                color: rgba(235, 235, 245, 0.6);
                margin-bottom: 0px;

                // @media only screen and(max-width: 767px) {
                //   display: none;
                // }
              }

              img {
                // @media only screen and(max-width: 767px) {
                //   display: none;
                // }
              }
            }
          }

          .right {

            // @media only screen and (max-width: 479px) {
            //   display: none;
            // }
            p {
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 18px;
              color: rgba(235, 235, 245, 0.6);
              margin-bottom: 0px;
            }

            a {
              font-family: "Gilroy-Bold";
              color: rgba(235, 235, 245, 0.6);
              text-decoration: none;

              &:hover {
                color: #83d7ff;
              }
            }
          }
        }

        .image-section {
          margin-top: 20px;

          @media only screen and (max-width: 767px) {
            // flex-direction: row;
            // overflow-x: scroll;
            // flex-wrap: wrap;
            // gap: 45px;
            // justify-content: center;
            // display: none !important;
            margin-top: 0px;
          }

          @media only screen and (min-width: 1366px) and (max-width: 1600px) {
            margin-top: 12px;
          }

          @media only screen and (min-width: 768px) and (max-width: 1700px) {
            // flex-wrap: wrap;
            // gap: 65px;
          }

          .rank_section {
            text-align: center;
            padding: 0 5px;
            flex: 0 0 10%;
            max-width: 10%;

            @media only screen and (min-width: 1920px) {
              flex: 0 0 10%;
              max-width: 10%;
            }

            // @media only screen and (min-width: 1366px) {
            //   flex: 0 0 16.6666666667%;
            //   max-width: 16.6666666667%;
            // }

            .rank_section_inner {
              position: relative;
              padding: 0px 0px;

              @media only screen and (min-width: 1366px) and (max-width: 1600px) {
                width: 90px;
              }


              @media only screen and (max-width: 415px) {
                margin: auto;
              }

              @media only screen and (max-width: 1300px) {
                justify-content: center;
                flex-wrap: wrap;
                margin-bottom: 10px;
              }

              // @media only screen and (min-width: 1301px) {
              //   flex: 0 0 18%;
              //   max-width: 18%;
              // }

              .rank {
                position: absolute;
                top: 0px;
                left: 0px;
              }

              .rank_holder {
                color: #fff;
                text-align: center;
                font-family: "Gilroy-Medium";
                display: block;
                font-size: 16px;

                @media only screen and (max-width: 1600px) {
                  font-size: 14px;
                }

                @media only screen and (max-width: 767px) {
                  font-size: 13px;
                }

                line-height: 1.5;
                height: 24px;
                /* Fallback for non-webkit */
                margin: 0 auto;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &.rank_section.hideonmobile {
              @media only screen and (max-width: 767px) {
                display: none;
              }
            }
          }
        }

        .view-all {
          text-align: center;
          margin-top: 15px;

          @media only screen and (min-width: 480px) {
            display: none;
          }

          p {
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 18px;
            color: rgba(235, 235, 245, 0.6);
            margin-bottom: 0px;
          }

          a {
            font-family: "Gilroy-Bold";
            color: rgba(235, 235, 245, 0.6);
            text-decoration: none;

            &:hover {
              color: #83d7ff;
            }
          }
        }
      }
    }
  }
}

._Dashboard_Game_Stats {
  background-color: #000;

  ._Game_Stats_inner {
    ._Game_Stats_top {
      @media only screen and (max-width: 574px) {
        flex-direction: column;
      }

      ._Title {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 25px;
        line-height: 40px;
        margin-left: 10px;

        @media only screen and (min-width: 991px) {
          font-size: 38px;
          line-height: 50px;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 58px;
          line-height: 58px;
        }

        @media only screen and (max-width: 574px) {
          margin: auto;
        }
      }

      .change_game {
        justify-content: space-between;

        @media only screen and (max-width: 574px) {
          margin: auto;
        }

        .change_gameleft {
          display: flex;
          align-items: center;
        }

        .change {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 0px;
          margin-right: 20px;
        }

        a {
          &.gamer_tag {
            display: block;
          }
        }

        .games_list {
          padding-right: 5px;
          cursor: pointer;

          img {
            background: #000000;
            border: 2px solid #484848;
            //border-radius: 100%;
            border-radius: 8px;
            height: 65px;
            width: 65px;
            padding: 6px;
            object-fit: contain;

            @media only screen and (min-width: 576px) {
              padding: 5px;
            }

            @media only screen and (max-width: 574px) {
              height: 50px;
              width: 50px;
            }

            &:hover {
              background: rgba(200, 0, 0, 0.5);
              border-color: #c80000;
            }
          }

          &.active {
            img {
              background: rgba(200, 0, 0, 0.5);
              border-color: #c80000;
            }
          }
        }
      }
    }

    @media only screen and (max-width: 767px) {
      padding: 15px 0;
    }
  }
}

// .game_tooltip {
//   .tooltip-arrow {
//     color: #c80000 !important;
//     &::before {
//       top: -2px;
//       border-top-color: #c80000 !important;
//       border-width: 10px;
//     }
//   }
//   .tooltip-inner {
//     background: rgba(200, 0, 0, 0.5);
//     border: 2px solid #c80000 !important;
//     font-family: "Gilroy-Bold";
//     border-radius: 8px !important;
//   }
// }
.game_tag_popover {
  background: rgba(200, 0, 0, 0.5);
  border: 2px solid #c80000 !important;
  border-radius: 15px;
  height: 46px;
  //transform: translate(1443px, -75px)!important;
  z-index: 0;
  margin: auto;
  // position: relative;
  bottom: 7px !important;
  z-index: 999999 !important;

  &:before {
    content: "";
    position: absolute;
    z-index: 999;
    bottom: -13px;
    left: -1px;
    right: 1px;
    width: 26px;
    height: 12px;
    background-image: url("../images/tooltip_arrow.png");
    margin: auto;
  }

  .popover-arrow {
    display: none;
  }

  .popover-body {
    color: #fff;
    font-family: "Gilroy-Bold";
  }
}

/****************************Dashboard_Fortnite_Stats end ************************/

/****************************Dashboard_explore start ************************/
._GB_Explore {
  background-color: #000;
  padding: 100px 0;

  @media only screen and(max-width: 1023px) {
    padding: 50px 0;
  }

  @media only screen and(max-width: 574px) {
    padding: 50px 0 0;
  }

  .featured_title {
    font-family: "Poppins-Bold";
    color: #fff;
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 70px;

    @media only screen and (max-width: 574px) {
      margin-bottom: 20px;
    }

    @media only screen and (min-width: 1366px) {
      font-size: 58px;
      line-height: 70px;
    }
  }

  .featured_slider {
    .indicatorFeatured {
      bottom: -70px !important;
    }

    .featured_slider_inner {
      .featured_slider_image {
        margin-bottom: 40px;

        img {
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }

      .featured_slider_rewards {
        @media only screen and(max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }

        .reward_items {
          background: rgba(0, 0, 0, 0.3);
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 17px 20px;
          margin: 0 15px;
          align-items: center;

          @media only screen and(max-width: 767px) {
            width: 100%;
            margin: 10px 15px;
          }

          .rewards_data {
            padding: 0 15px;

            .reward_head {
              font-family: "Gilroy-Medium";
              font-size: 24px;
              color: #fff;
            }

            .reward_score {
              font-family: "Gilroy-Medium";
              font-size: 18px;
              color: #fff;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  .view_all_games {
    padding: 70px;

    @media only screen and (max-width: 574px) {
      padding: 45px;
    }

    .view_all_games_btn {
      height: 56px;
      color: #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border-radius: 10px;
      border: 2px solid #ffffff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding: 18px 50px;
      text-decoration: none;
      margin-left: 15px;

      @media only screen and (max-width: 574px) {
        width: 100%;
        padding: 18px 15px;
        margin-left: 0px;
      }

      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

/****************************Dashboard_explore end ************************/

/****************************User header start ************************/
.wallet-section {
  .total_coins {
    padding-left: 10px;
    color: #fff;
    position: relative;

    span {
      position: absolute;
      color: #52dd21;
      font-size: 11px;
      top: -12px;
      right: 0;
    }
  }

  .connect_wallet {
    // border: 2px solid #727070;
    box-sizing: border-box;
    border-radius: 7px;
    position: relative;
    padding: 4px 10px 5px;
    cursor: pointer;

    .wallet_selected {
      .wallet {
        color: #fff;
        font-family: "Gilroy-Regular";
        font-size: 14px;
        padding-right: 10px;
      }

      .wallet_dd {
        padding-left: 5px;
      }
    }

    .wallet_list_dd {
      // position: absolute;
      background: #181818;
      // border: 2px solid #2d2d2d;
      box-sizing: border-box;
      // border-radius: 14px;
      width: 300px;
      margin: auto;
      // right: 0;
      // top: 50px;
      padding: 25px 20px;
      z-index: 1;

      .title_sec {
        img {
          padding-top: 10px;
        }

        .title {
          font-family: "Gilroy-Bold";
          font-size: 26px;
          line-height: 30px;
          color: #fff;
          padding: 20px 0px;
        }
      }

      .warning_section {
        text-align: end;
      }

      .wallet_dd_each {
        margin: 5px 0;
        font-family: "Gilroy-Bold";
        color: #979797;
        font-size: 14px;
        padding: 12px 12px 8px;
        border-radius: 14px;
        height: 43px;
        cursor: pointer;

        &:hover {
          color: #fff;
          background: #000000;
          border-radius: 14px;
        }

        &.active {
          background-color: #000;
          color: #fff;

          a {
            color: #fff;
            text-decoration: none;
          }
        }

        &.disable {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}

._GB_User_Header {
  @media only screen and (min-width: 1366px) and (max-width: 1919px) {
    padding: 0px !important;
  }

  .mode {
    text-decoration: none;

    .mode_type {
      color: #979797 !important;
      text-decoration: none;
      padding-right: 5px;

      &:hover {
        color: #fff !important;
      }
    }

    .hardcore_cb {
      .react-switch-bg {
        // width: 31px!important;
        // height: 20px !important;
        background-color: #fff !important;

        &:checked {
          background-color: #e52420 !important;
        }
      }

      .react-switch-handle {
        background: #140101 !important;

        &:checked {
          background-color: #fff !important;
        }
      }
    }

    .active {
      .react-switch-bg {
        // width: 31px!important;
        // height: 20px !important;
        background-color: #e52420 !important;

        &:checked {
          width: 31px !important;
        }
      }

      .react-switch-handle {
        background-color: #fff !important;
        // width: 15px !important;
        // height: 15px !important;
        top: 2px !important;
      }
    }

    .mode_switch {
      .form-check-input {
        width: 31px;
        height: 20px;
        background-image: url("../images/switch_button.png");
        background-size: auto !important;
        background-position: 2px;

        &:checked {
          cursor: pointer;
          background-color: #e52420 !important;
          border: #e52420 !important;
          background-image: url("../images/switch_white_button.png");
          background-size: auto;
          background-position: 13px 2px;
        }

        &:focus {
          box-shadow: none;
          border: none;
        }
      }
    }
  }

  .menu-points {
    img {
      padding-right: 10px;
    }
  }

  .profile {
    .profile_image {
      img {
        border: 4.5px solid #c80000;
        border-radius: 100%;
      }
    }

    .player_name {
      padding-left: 10px;

      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .profile_info {
      // padding-left: 8px;
      // border: 2px solid #727070;
      box-sizing: border-box;
      border-radius: 7px;
      position: relative;
      // padding: 4px 10px 5px;
      cursor: pointer;

      .profile_selected {
        .wallet {
          color: #fff;
          font-family: "Gilroy-Regular";
          font-size: 14px;
          padding-right: 10px;
        }

        .profile_dd {
          padding-left: 5px;
        }
      }

      .profile_list_dd {
        position: absolute;
        background: #181818;
        border: 2px solid #2d2d2d;
        box-sizing: border-box;
        border-radius: 14px;
        right: auto !important;
        top: 50px;
        // padding: 15px 14px;
        z-index: 1;

        @media only screen and (max-width: 1364px) {
          width: 100%;
          // left: -84px !important;
          // top: 45px !important;
          transform: none !important;
          z-index: 99;
        }

        @media only screen and (min-width: 1365px) {
          width: 255px;
          left: -84px !important;
          top: 45px !important;
          transform: none !important;
          z-index: 99;
        }

        .title_sec {
          img {
            padding-top: 10px;
          }

          .title {
            font-family: "Gilroy-Bold";
            font-size: 26px;
            line-height: 30px;
            color: #fff;
            padding: 20px 0px;
          }
        }

        .profile_dd_each {
          // margin: 5px 0;
          font-family: "Gilroy-Bold";

          color: #979797;
          font-size: 15px;
          padding: 8px 12px;
          cursor: pointer;

          &.profile_hc_dd {
            cursor: default;
          }

          // height: 56px;
          &:hover {
            color: #fff;
            background: #000000;
            border-radius: 14px;
          }

          img {
            margin-right: 10px;
            width: 37px;
          }

          a {
            color: #979797;
            text-decoration: none;

            &:hover {
              color: #fff;
            }
          }

          .transaction_image {
            width: 44px;

            @media only screen and (min-width: 1366px) {
              margin-right: 4px;
            }
          }
        }

        .mode {
          &:hover {
            background-color: #181818;
          }
        }

        .mode_alert {
          font-size: 13px !important;
          color: #979797;
          width: 155px;
          margin: auto 50px;
          padding-left: 10px;
        }
      }
    }
  }
}

.wallet_popup {
  .modal-dialog {
    .modal-content {
      @media only screen and(min-width: 768px) {
        max-width: 450px;
        margin: auto;
      }
    }
  }
}

.msg_tooltip {
  opacity: 1 !important;
  padding: 20px 0 !important;
  transform: translate(1450px, 90px);

  .tooltip-arrow {
    color: #181818 !important;

    &::before {
      top: -1px;
      right: 2px;
      border-top-color: #181818 !important;
      border-width: 10px;
    }
  }

  .tooltip-inner {
    background-color: #181818 !important;
    border: 2px solid #2d2d2d !important;
    font-family: "Gilroy-Bold";
    border-radius: 10px !important;
  }
}

.mode_tooltip {
  opacity: 1 !important;
  padding: 20px 0 !important;

  .tooltip-arrow {
    color: #181818 !important;

    &::before {
      bottom: -15px !important;
      border-bottom-color: #181818 !important;
      border-width: 15px;
    }
  }

  .tooltip-inner {
    background-color: #181818 !important;
    border: 2px solid #2d2d2d !important;
    font-family: "Gilroy-Medium";
    border-radius: 10px !important;

    span {
      font-family: "Gilroy-Bold";
    }
  }
}

.mode-popup {
  @media only screen and (max-width: 767px) {
    padding: 0px !important;
  }

  .modal-dialog {
    .modal-content {
      border: 2px solid #454545 !important;
      border-radius: 5px;
      background: #181818;
      padding: 50px 40px;

      @media only screen and (max-width: 767px) {
        padding: 20px;
      }

      .modal-header {
        padding: 0;
        border: none;

        .btn-close {
          color: #727070;
          outline: none;
          background: url(../images/popupclose.png) no-repeat center center;
          outline: none;
          border: none;

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .middle_section .security_update {
        color: rgba(235, 235, 245, 0.6);
        font-family: Gilroy-SemiBold;
        font-size: 15px;
        line-height: 19px;
        margin: 15px auto;
        padding-bottom: 5px;
        max-width: 293px;
      }

      .modal-footer {
        justify-content: center;
        border: none;
        padding: 0;

        button {
          border: 1px solid #292929 !important;
          color: #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          line-height: 17px;
          text-transform: uppercase;
          padding: 22px 35px 22px 65px;
          min-width: 141px;
          box-shadow: none !important;

          &:first-child {
            margin-right: 15px;
            background: #141414 url(../images/tick_green.png) no-repeat 20px center;
          }

          &:last-child {
            background: #141414 url(../images/close_red.png) no-repeat 20px center;
          }

          &:hover {
            opacity: 0.5;
          }

          @media only screen and (max-width: 767px) {
            margin: 15px 5px !important;
          }
        }
      }

      h3 {
        text-align: center;
        color: #fff;
        font-family: "Poppins-SemiBold";
        font-size: 25px;
        margin-bottom: 25px;

        @media only screen and (max-width: 767px) {
          font-size: 19px;
        }
      }
    }
  }
}

/****************************dashboardheader end ************************/

/****************************PlayersBanner-section start ************************/
._GB_Players {
  ._GB_Banner_Top {
    // background: linear-gradient(
    //   213.85deg,
    //   #c80000 -46.37%,
    //   rgba(200, 0, 0, 0) 34.62%
    // );
    background-color: #000;

    ._GB_Players_Banner {
      height: 700px;
      padding: 100px 0px;

      @media only screen and(max-width: 767px) {
        padding: 25px 0px;
        height: auto;
      }

      @media only screen and(min-width: 768px) and (max-width: 1300px) {
        padding: 50px 0px;
        height: auto;
      }

      .title_section {
        .title {
          font-family: "Poppins-Bold";
          color: #fff;
          font-size: 30px;
          line-height: 40px;
          max-width: 829px;
          text-align: center;

          @media only screen and (min-width: 1366px) {
            font-size: 45px;
            line-height: 67px;
          }
        }

        .sub_title {
          font-family: "Poppins";
          font-size: 26px;
          line-height: 39px;
          color: #fff;
          margin-top: 20px;
          border-bottom: 2px solid #e52420;

          @media only screen and(max-width: 767px) {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
          }
        }
      }

      .banner_bottom {
        margin-top: 85px;

        @media only screen and(max-width: 767px) {
          flex-direction: column;
          margin-top: 35px;
        }

        .register_btn {
          max-width: 315px;
          height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 35px;
          text-decoration: none;
          margin-left: 15px;
          display: flex;
          align-items: center;

          @media only screen and(max-width: 767px) {
            padding: 12px 25px;
            font-size: 11px;
            margin: auto;
            margin-bottom: 30px;
          }

          @media only screen and(max-width: 575px) {
            font-size: 11px;
          }

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }

        .join_btn {
          border: 2px solid #292929;
          box-sizing: border-box;
          border-radius: 10px;
          background-color: #000;
          color: #fff;
          font-family: "Poppins-Medium";
          font-size: 17px;
          line-height: 17px;
          position: relative;
          padding: 10px 35px 10px 80px;
          margin-left: 65px;
          text-decoration: none;
          display: flex;
          align-items: center;
          box-shadow: none;
          border-left: none;

          @media only screen and(max-width: 767px) {
            font-size: 15px;
            padding: 10px 20px 10px 60px;
            margin: auto;
          }

          @media only screen and(max-width: 575px) {
            font-size: 11px;
          }

          img {
            height: 64px;
            position: absolute;
            top: -5px;
            left: -11px;

            @media only screen and (max-width: 767px) {
              height: 55px;
              top: -9px;
              left: -2px;
            }
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

/****************************PlayersBanner-section end ************************/
/****************************PlayersAbout-section start ************************/
._GB_Players_About {
  background-color: #000;
  padding: 100px 0;

  @media only screen and(max-width: 767px) {
    padding: 25px 0px;
  }

  @media only screen and(min-width: 768px) and (max-width: 1300px) {
    padding: 50px 0px;
  }

  .about_inner {
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .left-section {
      @media only screen and (max-width: 767px) {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }

    .right-section {
      padding-left: 50px;

      @media only screen and(max-width: 767px) {
        width: 100%;
        text-align: center;
      }

      @media only screen and (max-width: 1023px) {
        padding-left: 0px;
      }

      .title_section {
        font-family: "Poppins-Bold";
        font-size: 30px;
        line-height: 40px;
        color: #fff;
        margin-bottom: 20px;

        @media only screen and (min-width: 1366px) {
          font-size: 48px;
          line-height: 48px;
        }
      }

      .about_section {
        font-family: "Gilroy-Medium";
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        max-width: 588px;
      }

      .about_btn {
        margin-top: 35px;

        .register_btn {
          height: 56px;
          max-width: 456px;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border: 2px solid #fff;
          border-radius: 10px;
          padding: 17px 50px;
          text-decoration: none;
          color: #fff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;

          @media only screen and(max-width: 574px) {
            width: 100%;
            font-size: 15px;
            padding: 12px 9px;
          }

          @media only screen and(min-width: 575px) and (max-width: 992px) {
            padding: 12px 25px;
          }

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

/****************************PlayersAbout-section end ************************/

/****************************PlayersWork-section start ************************/
._GB_Players_How_Work {
  background-color: #000;
  padding: 100px 0px;

  @media only screen and(max-width: 767px) {
    padding: 25px 0px;
  }

  @media only screen and(min-width: 768px) and (max-width: 1300px) {
    padding: 50px 0px;
  }

  .how_work_inner {
    @media only screen and (max-width: 1300px) {
      flex-direction: column;
    }

    .box {
      @media only screen and (max-width: 1300px) {
        width: 100%;
      }

      @media only screen and (max-width: 767px) {
        text-align: center;
      }

      .title {
        font-family: "Poppins-SemiBold";
        font-size: 30px;
        font-weight: 40px;
        color: #fff;
        margin-bottom: 25px;

        @media only screen and (min-width: 1366px) {
          font-size: 48px;
          line-height: 48px;
        }
      }

      .description {
        font-family: "Gilroy-Medium";
        font-size: 18px;
        line-height: 32px;
        color: #fff;
        max-width: 410px;

        @media only screen and (max-width: 1300px) {
          max-width: 100%;
        }

        p {
          margin-top: 10px;
        }
      }

      .btn_section {
        margin-bottom: 40px;

        @media only screen and (max-width: 1300px) {
          margin-bottom: 20px;
          padding-bottom: 15px;
        }

        .view_btn {
          border: 2px solid #292929;
          box-sizing: border-box;
          border-radius: 10px;
          width: 410px;
          height: 62px;
          font-family: "Poppins-SemiBold";
          background-color: #000;
          color: #fff;
          text-transform: uppercase;

          @media only screen and(max-width: 574px) {
            width: 100%;
          }

          a {
            color: #fff;
            text-decoration: none;
          }

          &:hover {
            opacity: 0.5;
          }
        }

        .register_btn {
          height: 56px;
          width: 410px;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border: 2px solid #fff;
          border-radius: 10px;
          padding: 18px 45px;
          text-decoration: none;
          color: #fff;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;

          @media only screen and(max-width: 574px) {
            width: 100%;
            font-size: 15px;
            padding: 15px 9px;
          }

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }

      &.first_box {
        @media only screen and (max-width: 1300px) {
          width: 100%;
          margin-bottom: 15px;
        }
      }

      &.second_box {
        @media only screen and (max-width: 1300px) {
          width: 100%;
          margin-bottom: 15px;
          text-align: center;
        }

        img {
          @media only screen and (max-width: 574px) {
            width: 100%;
          }
        }
      }

      &.third_box {
        align-items: flex-end;

        @media only screen and (max-width: 1300px) {
          width: 100%;
          align-items: center;
        }

        @media only screen and (min-width: 1301px) {
          display: flex;
        }

        .description {
          margin-bottom: 25px;
        }
      }
    }
  }
}

/****************************PlayersWork-section end ************************/

/****************************PlayLeague-section start ************************/
._GB_Play_League {
  background: linear-gradient(231.85deg,
      #c80000 -37.37%,
      rgba(200, 0, 0, 0) 23.62%);
  background-color: #000;
  padding: 100px 0;

  @media only screen and(max-width: 767px) {
    padding: 25px 0px;
  }

  @media only screen and(min-width: 768px) and (max-width: 1300px) {
    padding: 50px 0px;
  }

  .Play_League_inner {
    .title_section {
      margin-bottom: 25px;

      .title {
        font-family: "Poppins-SemiBold";
        font-size: 30px;
        line-height: 40px;
        color: #fff;

        @media only screen and (min-width: 1366px) {
          font-size: 48px;
          line-height: 48px;
        }
      }

      .sub_title {
        font-family: "Gilroy-Medium";
        font-size: 21px;
        line-height: 30px;
        color: #fff;
        opacity: 0.5;
      }
    }

    .graph_section {
      background-image: url("../images/graph_bg.png");
      background-repeat: repeat;
      height: 718px;
      border-radius: 25px;
      background-color: linear-gradient(90deg,
          #1f0000 15.08%,
          rgba(45, 0, 0, 0) 89.87%,
          rgba(45, 0, 0, 0) 89.87%);
      // img{
      //   height: 718px;
      //   width: 100%;
      // }
    }
  }
}

/****************************PlayLeague-section end ************************/

/****************************RewardPopup Start ************************/
.com-popup-modal {
  max-width: 648px;

  //max-height: 584px;
  .modal-header {
    border: none;
    padding-bottom: 35px;

    .btn-close {
      background-image: url("../images/close_button.png");

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  .modal-content {
    background: #181818;
    border: 2px solid #454545;
    box-sizing: border-box;
    border-radius: 25px;
    text-align: center;

    @media only screen and (max-width: 767px) {
      padding: 25px;
    }

    .title {
      font-family: "Poppins-SemiBold";
      font-size: 30px;
      line-height: 30px;
      color: #fff;
      margin-bottom: 20px;

      @media only screen and (min-width: 1366px) {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .sub_title {
      font-family: "Poppins-Medium";
      color: #fff;
      font-size: 16px;
      line-height: 23px;
      max-width: 513px;
      margin: auto;
    }

    .modal-body {
      padding: 10px 0px;
    }

    .modal-footer {
      border: none;
      padding: 30px 45px 50px;

      button {
        height: 49px;
        max-width: 218px;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border: 2px solid #fff;
        border-radius: 10px;
        padding: 12px 25px;
        text-decoration: none;
        color: #fff;
        font-family: "Poppins-SemiBold";
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;

        @media only screen and (max-width: 1024px) {
          padding: 12px 9px;
        }

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &.reward_popup_modal {
    ._SearchArea {
      max-width: 548px;
      padding: 8px 10px;
      border-radius: 15px;

      @media only screen and(min-width: 768px) {
        background: #262627;
      }

      ._SearchArea_wrap {
        @media only screen and(max-width: 767px) {
          flex-wrap: wrap;
          justify-content: center;
        }

        ._GB_Enter_Player {
          background: none;
          border: none;
          font-family: "Gilroy-Medium";
          color: #ffffff;

          @media only screen and(max-width:767px) {
            padding: 15px 20px;
            margin-bottom: 20px;
            background: #262627;
            border-radius: 12px;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .game_dropdown {
              position: relative;
                display: flex;
                align-items: center;
                justify-content: end;
                cursor: pointer;
                max-width: 32%;
                flex: 0 0 32%;
          @media only screen and(max-width: 767px) {
            width: 100%;
            max-width: none;
            margin-bottom: 25px;
            padding: 15px 20px;
            border-radius: 12px;
            background: #262627;
          }

          .game_selected_dd {
            font-family: "Gilroy-Medium";
            padding: 0 10px;

            @media only screen and(max-width: 767px) {
              width: 100%;
              justify-content: space-between !important;
              padding: 0 0px;
            }

            .game_choosed {
              color: #fff;
              padding-right: 5px;

              @media only screen and(max-width: 767px) {
                display: flex;
                align-items: center;
              }

              .img-box {
                display: block;
                padding-right: 20px;

                @media only screen and(min-width: 768px) {
                  display: none;
                }

                img {
                  @media only screen and(max-width: 767px) {
                    max-height: 35px;
                  }
                }
              }
            }
          }

          .game_dropdown_list {
            // background: #f9f9f9;
            // border-radius: 14px;
            padding: 8px 12px;
            width: 275px;
            height: 325px;
            overflow-y: scroll;
            color: #0d0f15;
            position: absolute;
            top: 45px;
            z-index: 1;

                background: #181818;
                  border: 2px solid #454545;
                  color: #fff;

            &::-webkit-scrollbar {
                width: 5px;
              }
            
              /* Track */
              &::-webkit-scrollbar-track {
                background: #181818;
              }
            
              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: #e52420;
              }

            .game_dropdown_each {
              cursor: pointer;
              margin: 4px 0;
              padding: 8px;
              border: 2px solid #181818;
              &:hover {
                //background: #e6e6e6;
                //border-radius: 8px;
                background: #181818;
                border: 2px solid #454545;
                color: #fff;
              }

              .game_dd_image {
                width: 40px;
                text-align: center;

                img {
                  width: 40px;
                  height: 40px;
                  object-fit: contain;
                }

                &.all_game_img {
                  img {
                    width: 25px;
                    height: 25px;
                  }
                }
              }

              .game_dd_name {
                padding-left: 15px;
                font-family: "Gilroy-Medium";
              }
            }
          }
        }

        .spinner-border {
          flex: 0 0 2rem;
          max-width: 2rem;
        }

        ._GB_Select_Game {
          border: none;
          outline: none;
          font-family: "Gilroy-Medium";
          color: #fff !important;
          background: #262627;
          border: none;
        }

        ._GB_Search_Player {
          border: solid 2px #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);

          display: flex;
          align-items: center;
          justify-content: center;

          @media only screen and (min-width: 768px) {
            width: 40px;
            height: 40px;
            margin: 2px 2px 2px 10px;
            border-radius: 10px;
          }

          @media only screen and (max-width: 767px) {
            width: 100%;
            background-color: #e52420;
            background-image: linear-gradient(to right,
                #6d1513,
                #e52420,
                #6d1513);
            border: 2px solid #fff !important;
            border-radius: 10px;
            padding: 17px 50px;
            text-decoration: none;
            color: #fff;
            font-family: "Poppins-SemiBold";
            font-size: 15px;
            line-height: 17px;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            display: inline-block;
          }

          &::after {
            @media only screen and (max-width: 767px) {
              content: url("../images/arrow.png");
              padding-left: 10px;
            }
          }

          &:hover {
            @media only screen and (max-width: 767px) {
              opacity: 0.5;
              color: #fff;
            }
          }

          img {
            @media only screen and (max-width: 767px) {
              display: none;
            }
          }

          span {
            @media only screen and (min-width: 768px) {
              display: none;
            }
          }
        }
      }

      ._GB_Search_List {
        background: #262627;
        border-radius: 15px;
        margin-top: 20px;
        padding: 15px 10px;
        position: absolute;
        width: 780px;

        ._GB_Game_Item {
          color: #fff;
          padding: 8px 15px;
          margin-bottom: 10px;

          &:hover {
            background: #3f3f3f;
            border-radius: 10px;
            padding: 8px 15px;
          }

          ._GB_Game_Item_Name {
            .gameImage {
              display: none;

              img {
                width: 35px;
                height: 35px;
              }
            }

            .gameName {
              color: #ffffff;
              opacity: 0.5;
              font-family: "Gilroy-Regular";
              font-size: 13px;
              // padding-left: 15px;
            }
          }

          ._GB_CB_PlayerName {
            font-family: "Gilroy-Medium";
          }
        }

        hr {
          color: #fff;
        }

        ._GB_Mining_Seleted {
          color: #ffffff;
          opacity: 0.5;
          font-family: "Gilroy-Medium";
          font-size: 14px;
        }

        ._GB_mining_Btn {
          height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 50px;
          text-decoration: none;
          margin-left: 15px;

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .modal-footer {
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }

    .succMsg {
      color: #00a900;
      font-family: "Gilroy-Medium";
      margin-top: 10px;
    }
  }
}

/****************************RewardPopup end ************************/

/****************************Account_Settings start ************************/
._GB_Account_Settings {
  ._GB_Header {
    background-color: #000;
  }

  .account_settings_inner {
    background-color: #000;
    padding: 50px 0 100px;

    .container {
      @media only screen and (max-width: 767px) {
        max-width: 90%;
      }
    }

    .title-section {
      .title {
        font-family: "Poppins-SemiBold";
        font-size: 35px;
        line-height: 35px;
        color: #fff;
        margin-bottom: 20px;

        @media only screen and (min-width: 1366px) {
          font-size: 48px;
          line-height: 90px;
        }

        @media only screen and (min-width: 1366px) and (max-width: 1600px) {
          font-size: 16px !important;
        }
      }
    }

    .account_settings_wrap {
      background: #181818;
      border: 2px solid #2d2d2d;
      border-radius: 20px;
      padding: 35px;

      @media only screen and (max-width: 1200px) {
        padding: 15px;
      }

      @media only screen and (max-width: 991px) {
        flex-direction: column;
      }

      .left_section {
        @media only screen and (max-width: 767px) {
          width: 100% !important;
        }

        @media only screen and (max-width: 991px) {
          width: 50%;
          margin: auto;
        }

        .profile-section {
          height: 449px;
          width: 100%;
          background: #121212;
          border-radius: 15px;
          padding: 20px;

          @media only screen and (max-width: 1400px) {
            width: 240px;
          }

          @media only screen and (max-width: 991px) {
            width: 100%;
            height: auto;
          }

          img {
            width: 244px;
            height: 244px;
            object-fit: cover;
            border: 3px solid rgba(255, 255, 255, 0.5);
            border-radius: 100%;

            @media only screen and(max-width: 1700px) {
              width: 200px;
              height: 200px;
            }
          }

          .change_image {
            color: #fff;
            font-family: "Gilroy-Bold";
            font-size: 18px;
            line-height: 18px;
            padding: 20px 0 10px;
          }

          .upload_btn {
            color: rgba(255, 255, 255, 0.5);
            font-family: "Gilroy-SemiBold";
            border: 1px solid #727070;
            border-radius: 12px;
            background: #222222;
            height: 42px;
            width: 136px;
          }

          .file-up-btn {
            label {
              color: rgba(255, 255, 255, 0.5);
              font-family: "Gilroy-SemiBold";
              border: 1px solid #727070;
              border-radius: 12px;
              background: #222222;
              height: 42px;
              width: 136px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &:hover {
                opacity: 0.5;
              }
            }
          }

          .image_size {
            color: rgba(235, 235, 245, 0.6);
            font-size: 16px;
            line-height: 19px;
            font-family: "Gilroy-Regular";
            font-weight: 600;
            padding-top: 10px;
          }
        }

        .gb-red-btn {
          font-size: 14px;
        }
      }

      .right_section {
        padding-left: 45px;

        @media only screen and (max-width: 989px) {
          padding-top: 15px;
          padding-left: 0px;
          width: 100%;
        }

        ._GB_Left_Section {
          @media only screen and (max-width: 767px) {
            display: none;
          }

          .nav-pills {
            border-radius: 12px;
            border: 2px solid #2d2d2d;
            height: 54px;
            justify-content: space-between;
            ;

            @media only screen and(max-width: 989px) {
              height: auto;
            }

            .nav-item {
              border-radius: 12px;
              font-family: "Gilroy-Bold";
              font-size: 17px;
              line-height: 21px;
              cursor: pointer;

              a {
                color: rgba(235, 235, 245, 0.6);
              }

              .cmn_acclink {
                text-decoration: none;
                padding: 10px 12px;

                @media only screen and (max-width: 1200px) {
                  padding: 6px;
                }
              }

              .verify-gametags {}

              .nav-link {
                &.active {
                  background-color: #000 !important;
                  border: 2px solid #e52420;
                  border-radius: 12px;
                  color: #fff;
                  height: 50px;
                  display: flex;
                  align-items: center;
                }
              }
            }
          }
        }

        ._GB_Right_Section {
          .tab-content {
            .tab-pane {
              @media only screen and (max-width: 767px) {
                display: block;
                padding-top: 15px;
                opacity: 1 !important;
              }

              .tab-link {
                border-radius: 12px;
                font-family: "Gilroy-Bold";
                font-size: 18px;
                line-height: 21px;
                cursor: pointer;
                color: rgba(235, 235, 245, 0.6);
                background-color: #000 !important;
                border: 2px solid #2d2d2d;
                height: 50px;
                display: flex;
                align-items: center;
                text-decoration: none;
                padding: 10px 20px;
                text-align: center;

                @media only screen and (min-width: 768px) {
                  display: none;
                }
              }

              .container {
                .com-inner {
                  @media only screen and (max-width: 767px) {
                    display: none;
                  }
                }
              }

              &.gb-tab-active {
                .tab-link {
                  border: 2px solid #e52420;
                }

                .container {
                  .com-inner {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.warning_tooltip {
  opacity: 1 !important;
  padding: 9px 0 !important;

  //transform: translate(1293.44px, -800.889px)!important;
  .tooltip-arrow {
    color: #181818 !important;
    border-width: 11px;

    &::before {
      top: -4px;
      left: -20px;
      border-width: 25px;
      content: "";
      background-image: url("../images/arrow-black.png");
      background-repeat: no-repeat;
      border-top: none;
    }
  }

  .tooltip-inner {
    max-width: 250px;
    background-color: #181818 !important;
    border: 2px solid #2d2d2d !important;
    font-family: "Gilroy-Bold";
    border-radius: 10px !important;
  }
}

/****************************Account_Settings end ************************/

/****************************My profile Start ************************/
._GB_My_Profile {
  .GB_settings_container {
    width: 100%;
    max-width: 100%;
  }

  .My_Profile_inner {
    .title-section {
      border-bottom: 2px solid #2d2d2d;
      padding-top: 40px;
      width: 100%;

      @media only screen and(min-width: 1366px) {}

      .title {
        font-family: "Gilroy-Bold";
        font-size: 30px;
        color: #fff;
      }
    }

    .form-section {
      max-width: 532px;

      label {
        font-size: 16px;
        color: #9c9ca3;
        line-height: 16px;
        padding-top: 35px;
        padding-bottom: 10px;
        font-family: "Gilroy-Regular";
      }

      .account_form_field {
        background-color: #000;
        border: 2px solid #2d2d2d;
        border-radius: 15px;
        height: 56px;
        width: 100%;
        font-family: "Gilroy-Bold";
        font-size: 19px;
        line-height: 19px;
        color: #fff;
      }

      .save_btn {
        color: #fff;
        font-family: "Poppins-SemiBold";
        border: 1px solid #727070;
        background: #222222;
        height: 52px;
        width: 143px;
        border-radius: 15px;
        margin-top: 25px;
        font-size: 17px;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .details_section {
      .legal_name {
        background: #1b1b1b;
        border: 2px solid #262627;
        border-radius: 15px;
        color: #fff;
      }

      .dob {
        max-width: 532px;

        label {
          font-size: 16px;
          color: #9c9ca3;
          line-height: 16px;
          padding-top: 35px;
          font-family: "Gilroy-Regular";
          padding-bottom: 10px;
        }

        .dob_wrap {
          @media only screen and (max-width: 767px) {
            flex-direction: column;
          }

          .dob_data {
            @media only screen and (max-width: 767px) {
              padding-bottom: 15px;
              width: 100%;
            }

            .dob_drop {
              width: 165px;
              height: 56px;
              background: #1b1b1b;
              border: 2px solid #262627;
              border-radius: 12px;
              color: #9c9ca3;
              padding-left: 10px;

              @media only screen and (max-width: 767px) {
                width: 100%;
                margin: auto;
              }
            }
          }
        }
      }

      .address {
        max-width: 532px;

        @media only screen and (max-width: 767px) {
          flex-direction: column;
        }

        .left {
          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .account_form_field {
          width: 261px;

          @media only screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .country {
          background: #1b1b1b;
          border: 2px solid #262627;
          border-radius: 15px;
          color: #9c9ca3;
          outline: none;
          padding: 10px;

          .prohibited {
            color: #dd1717;
          }
        }

        .prohibited_country {
          color: #dd1717;
          font-family: "Gilroy-Regular";
          font-size: 13px;
          padding-top: 15px;
        }
      }

      .save_btn {
        color: #fff;
        font-family: "Poppins-SemiBold";
        border: 1px solid #727070;
        background: #222222;
        height: 52px;
        width: 143px;
        border-radius: 15px;
        margin-top: 25px;
        font-size: 17px;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    .form-field-error {
      color: #dd1717;
      font-family: "Gilroy-Regular";
      font-size: 13px;
      position: absolute;
    }

    label {
      position: relative;

      .required {
        color: #e70000;
        right: -13px;
        font-size: 24px;
        display: inline-block;
        position: absolute;
        top: 40px;
      }
    }

    .enable_streamer {
      .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 21px;
        width: 21px;
        background-color: #262627;
        border: 2px solid #5c5c5c;
        border-radius: 3px;
      }

      ._GB_st_enable {
        padding-left: 10px;
      }
    }
  }
}

/****************************My profile end ************************/

/****************************Verify end ************************/
._GB_Verify {
  .inner-container {
    width: 100%;
    max-width: 100%;
  }

  .Verify_inner {
    padding-top: 50px;

    .title-section {
      .title {
        font-family: "Poppins-SemiBold";
        font-size: 30px;
        line-height: 30px;
        color: #fff;
        margin-bottom: 20px;

        // @media only screen and (min-width: 1366px) {
        //   font-size: 45px;
        //   line-height: 45px;
        // }
        @media only screen and (max-width: 991px) {
          text-align: center;
        }
      }
    }

    .hide-verify-block {
      display: none !important;
    }

    .gaming_platform {
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }

      .box {
        padding: 15px;

        @media only screen and (max-width: 767px) {
          width: 100%;
        }

        .box_inner {
          background: #121212;
          border: 1px solid #2b2b2b;
          border-radius: 15px;
          width: 315px;
          height: 132px;
          position: relative;

          .delete-popup-outer {
            .delete-link {
              position: absolute;
              top: 10px;
              right: 20px;
              color: #fff;
              cursor: pointer;
            }
          }

          .box_top {
            padding: 0px 15px;

            // img {
            //   height: 45px;
            //   width: 45px;
            // }
            .game_name {
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 20px;
              line-height: 20px;
              padding-left: 10px;
            }
          }

          .box_bottom {
            color: #94949a;
            font-family: "Gilroy-Bold";
            font-size: 12px;
            line-height: 14px;
            padding: 10px 10px 0px 0px;
            width: 100%;

            &.connect {
              // position: relative;
              // bottom: -20px;
              position: absolute;
              bottom: 15px;
              right: 10px;

              .verify-popup {
                .verify-link {
                  cursor: pointer;

                  &:hover {
                    opacity: 0.7;
                  }
                }
              }
            }
          }

          &.verified_game {
            .box_top {
              height: 52px;
              width: 100%;
              background-color: #e52420;
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;

              img {
                height: 23px;
                width: 23px;
                cursor: pointer;
              }

              .game_name {
                padding-left: 0px;
                padding-right: 15px;
              }
            }

            .box_bottom {
              height: 80px;
              background-color: #b41714;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              cursor: auto;

              &:hover {
                opacity: 1;
              }

              .verified_user_name {
                padding-right: 15px;
                color: #fff;
                font-family: "Gilroy-Bold";
                font-size: 20px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    .noGamerTagsSection {
      color: #9c9ca3;
      font-family: "Gilroy-SemiBold";
      font-size: 16px;
      line-height: 16px;
    }
  }
}

/****************************Verify end ************************/
/****************************Security Start ***********************/
._GB_Security {
  .Security_inner {
    .password_section {
      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }

      .left_section {
        max-width: 657px;
        padding: 50px 0;

        @media only screen and (max-width: 767px) {
          padding: 25px 0;
        }

        .title {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 19px;
          line-height: 19px;
          padding-bottom: 10px;
        }

        .sub_title {
          color: #9c9ca3;
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 16px;
        }
      }

      .right_section {
        padding-left: 0px;
        margin-left: 23px;

        @media only screen and (max-width: 767px) {
          margin-left: 0px;
          padding-top: 0px;
          padding-bottom: 15px;
        }

        @media only screen and (max-width: 989px) {
          width: auto;
        }

        @media only screen and (min-width: 990px) and (max-width: 1023px) {
          margin-left: 0;
        }

        .change_pswd_btn {
          border: 2px solid #5b5b5b;
          border-radius: 10px;
          max-width: 250px;
          height: 42px;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 15px;
          color: #fff;
          cursor: pointer;

          @media only screen and (max-width: 1200px) {
            font-size: 13px;
            padding: 15px 8px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }

      .full-section {
        padding: 50px 0px;

        .title {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 19px;
          line-height: 19px;
          padding-bottom: 10px;
        }

        .sub_title {
          color: #9c9ca3;
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 16px;
        }

        .password_requirement {
          margin-top: 30px;
          color: #fff;
          text-align: left;

          .form-field-error {
            color: #f00;
            font-family: "Gilroy-Regular";
            font-size: 14px;
            margin-bottom: 4px;
          }

          .pass_ver_name {
            font-size: 13px;
            font-family: "Gilroy-Regular";
            opacity: 0.5;

            &.pass {
              color: #0bff1a;
            }
          }

          .pass_tick {
            background: url("../images/pass_tick.png");
            background-repeat: no-repeat;
            background-position: 0px 8px;
            padding-left: 20px;
          }

          .pass_untick {
            background: url("../images/pass_untick.png");
            background-repeat: no-repeat;
            background-position: 0px 8px;
            padding-left: 20px;
          }

          @media only screen and(max-width: 767px) {
            max-width: 85%;
            margin: 10px auto;
          }
        }
      }

      .update_form {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and(max-width: 574px) {
          flex-direction: column;
        }

        .form-section {
          width: 100%;
          max-width: 32%;
          position: relative;

          @media only screen and(max-width: 574px) {
            max-width: 85%;
          }
        }

        label {
          font-size: 16px;
          color: #9c9ca3;
          line-height: 16px;
          padding-top: 35px;
          padding-bottom: 10px;
          font-family: "Gilroy-Regular";
        }

        .form-field-error {
          color: #f00;
          font-family: "Gilroy-Regular";
          font-size: 13px;
          position: absolute;
        }

        .account_form_field {
          background-color: #000;
          border: 2px solid #2d2d2d;
          border-radius: 20px;
          height: 56px;
          width: 100%;
          max-width: 100%;
          font-family: "Gilroy-Bold";
          font-size: 19px;
          line-height: 19px;
          color: #fff;
        }
      }

      .add_form {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and(max-width: 767px) {
          flex-direction: column;
        }

        .form-section {
          width: 100%;
          max-width: 48%;
          position: relative;

          @media only screen and(max-width: 767px) {
            max-width: 85%;
          }
        }

        label {
          font-size: 16px;
          color: #9c9ca3;
          line-height: 16px;
          padding-top: 35px;
          padding-bottom: 10px;
          font-family: "Gilroy-Regular";
        }

        .form-field-error {
          color: #f00;
          font-family: "Gilroy-Regular";
          font-size: 13px;
          position: absolute;
        }

        .account_form_field {
          background-color: #000;
          border: 2px solid #2d2d2d;
          border-radius: 20px;
          height: 56px;
          width: 100%;
          max-width: 100%;
          font-family: "Gilroy-Bold";
          font-size: 19px;
          line-height: 19px;
          color: #fff;
        }
      }

      .button-container {
        margin-top: 30px;

        @media only screen and (max-width: 767px) {
          text-align: center;
        }

        .save_btn {
          color: rgba(255, 255, 255, 0.5);
          font-family: "Poppins-SemiBold";
          border: 1px solid #727070;
          background: #222222;
          height: 52px;
          width: 143px;
          border-radius: 15px;
          margin-top: 25px;
          font-size: 17px;
          margin-right: 15px;

          @media only screen and (max-width: 767px) {
            margin-right: 0;
          }

          &:hover {
            opacity: 0.5;
          }
        }

        .cancel_btn {
          color: #fff;
          font-family: "Poppins-SemiBold";
          border: 1px solid #727070;
          background: #222222;
          height: 52px;
          width: 143px;
          border-radius: 15px;
          margin-top: 25px;
          font-size: 17px;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .title_section {
      padding-bottom: 50px;

      .title {
        color: #fff;
        font-family: "Gilroy-Bold";
        border-bottom: 2px solid #2d2d2d;
        font-size: 30px;
        line-height: 30px;
        padding-bottom: 15px;
      }
    }

    .security_box {
      background: #121212;
      border: 1px solid #2b2b2b;
      border-radius: 15px;
      width: 622px;
      padding: 30px 0px;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        text-align: center;
        padding: 15px 10px;
        width: 100%;
        height: auto;
      }

      @media only screen and (min-width: 768px) and (max-width: 989px) {
        padding: 15px 0;
      }

      .full-section {
        padding: 0px 30px;

        @media only screen and(max-width: 767px) {
          padding: 0px 10px !important;
        }
      }

      .update_form {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .form-section {
          width: 100%;
          max-width: 80%;
          position: relative;

          @media only screen and (max-width: 767px) {
            max-width: 100%;
          }
        }

        .react-tel-input {
          .form-control {
            background-color: #000;
            border: 2px solid #2d2d2d;
            border-radius: 20px;
            height: 56px;
            width: 100%;
            max-width: 100%;
            font-family: "Gilroy-Bold";
            font-size: 19px;
            line-height: 19px;
            color: #fff;
          }

          .flag-dropdown {
            border: 2px solid #2d2d2d;
            background-color: #000;
            border-radius: 20px 0 0 20px;

            &.open {
              .selected-flag {
                background: #000;
                border-radius: 20px 0 0 20px;
              }
            }

            .selected-flag {
              &:hover {
                background-color: #000;
                border-radius: 20px 0 0 20px;
              }

              &:focus {
                background-color: #000;
              }
            }
          }

          .country-list {
            background-color: #000;
            width: 310px;

            .search {
              background-color: #000;
            }

            .search-box {
              background: #000;
              border: 2px solid #2d2d2d;
              color: #ccc;
            }

            .country {
              color: #fff !important;
              font-family: "Gilroy-Medium";

              &.highlight {
                color: #fff !important;
                background-color: #181818 !important;
              }

              // &.active {
              //   color: #000 !important;
              // }
              &:hover {
                color: #fff !important;
                background-color: #181818 !important;
              }
            }
          }
        }

        label {
          font-size: 16px;
          color: #9c9ca3;
          line-height: 16px;
          padding-bottom: 10px;
          font-family: "Gilroy-Regular";
        }

        .form-field-error {
          color: #f00;
          font-family: "Gilroy-Regular";
          font-size: 13px;
          position: absolute;
        }

        .account_form_field {
          background-color: #000;
          border: 2px solid #2d2d2d;
          border-radius: 20px;
          height: 56px;
          width: 100%;
          max-width: 100%;
          font-family: "Gilroy-Bold";
          font-size: 19px;
          line-height: 19px;
          color: #fff;
        }
      }

      .button-container {
        margin-top: 30px;

        .save_btn {
          color: rgba(255, 255, 255, 0.5);
          font-family: "Poppins-SemiBold";
          border: 1px solid #727070;
          background: #222222;
          height: 52px;
          width: 143px;
          border-radius: 15px;
          margin-top: 25px;
          font-size: 17px;
          margin-right: 15px;

          @media only screen and(max-width: 767px) {
            margin-right: 0px;
          }

          &:hover {
            opacity: 0.5;
          }
        }

        .cancel_btn {
          color: #fff;
          font-family: "Poppins-SemiBold";
          border: 1px solid #727070;
          background: #222222;
          height: 52px;
          width: 143px;
          border-radius: 15px;
          margin-top: 25px;
          font-size: 17px;

          &:hover {
            opacity: 0.5;
          }
        }
      }

      .full-section {
        padding: 0px 30px;

        label {
          padding: 10px 0px;
        }
      }

      .left_section {
        padding: 0px 15px 0px 20px;
      }

      .middle_section {
        max-width: 345px;

        @media only screen and (min-width: 768px) and (max-width: 989px) {
          width: 100%;
        }

        .data {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 19px;
          line-height: 19px;
          padding-bottom: 5px;
        }

        .security_update {
          color: rgba(235, 235, 245, 0.6);
          font-family: "Gilroy-SemiBold";
          font-size: 15px;
          line-height: 19px;
          padding-bottom: 5px;
          margin: 15px 0px;

          .otp-enter {
            margin: 10px 0px;
          }

          .resend-otp {
            button {
              background-color: transparent;
              color: #e52420;
              border: none;

              &:disabled {
                color: #bd3b3b9c;
              }
            }
          }

          .otp-validation {
            font-family: "Gilroy-Medium";
            font-size: 13px;
            line-height: 19px;
            color: #e52420;
            padding-top: 10px;
          }
        }

        .security_alert {
          color: #83ffc3;
          font-family: "Gilroy-SemiBold";
          font-size: 16px;
          line-height: 19px;
        }
      }

      .right_section {
        .securtiy_btn {
          border: 2px solid #5b5b5b;
          border-radius: 10px;
          width: 136px;
          height: 42px;
          color: #fff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 15px;
          cursor: pointer;

          @media only screen and (max-width: 767px) {
            margin: auto;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .current-section {
      margin-bottom: 50px;

      @media only screen and (max-width: 1360px) {
        flex-direction: column;
      }

      .tip {
        color: #fff;
        font-family: "Gilroy-SemiBold";
        font-size: 16px;
        line-height: 16px;
        max-width: 290px;
        padding-left: 10px;

        @media only screen and (max-width: 1360px) {
          width: 100%;
          padding-top: 10px;
        }

        span {
          opacity: 0.5;
        }
      }
    }

    .two_step_verifi {
      padding-top: 50px;

      .sub_title {
        color: #9c9ca3;
        font-family: "Gilroy-SemiBold";
        font-size: 16px;
        line-height: 16px;
        padding-bottom: 10px;
      }

      .other_option {
        margin-bottom: 30px;
      }

      .left_section {
        img {
          width: 36px;
          // height: 36px;
        }
      }

      .middle_section {
        .moderately_required {
          color: #83d7ff;
        }

        .secure {
          color: #83ffc3;
        }
      }
    }
  }
}

/****************************Security end ************************/

/****************************Notification Start ************************/
._GB_Notification {
  .Notification_inner {
    padding: 50px 0;

    .title_section {
      padding-bottom: 30px;

      .title {
        font-family: "Gilroy-Bold";
        font-size: 30px;
        line-height: 30px;
        color: #fff;
        border-bottom: 2px solid #2d2d2d;
        padding-bottom: 20px;
      }
    }

    .notifi_cb {
      padding-bottom: 20px;

      ._GB_CB_Container_Wrap {
        display: flex;

        ._GB_CB_Container {
          height: 30px;
          width: 30px;
          margin: inherit;
          position: relative;
          padding-left: 21px;
          cursor: pointer;
          font-size: 20px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        /* Hide the browser's default checkbox */
        ._GB_CB_Container input {
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: 0px;
          left: 0;
          height: 30px;
          width: 30px;
          background-color: #262627;
          border: 2px solid #5c5c5c;
          border-radius: 7px;

          @media only screen and(max-width: 767px) {
            height: 25px;
            width: 25px;
          }
        }

        /* On mouse-over, add a grey background color */
        ._GB_CB_Container:hover input~.checkmark {
          background-color: #262627;
        }

        /* When the checkbox is checked, add a blue background */
        ._GB_CB_Container input:checked~.checkmark {
          background-color: #262627;
          border: 2px solid #5c5c5c;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        ._GB_CB_Container:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Style the checkmark/indicator */
        ._GB_CB_Container .checkmark:after {
          content: "";
          top: 1px;
          left: 6px;
          width: 6px;
          height: 12px;
          border: solid #262627;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: absolute;
        }

        /* Show the checkmark when checked */
        ._GB_CB_Container input:checked~.checkmark:after {
          top: 5px;
          left: 10px;
          width: 6px;
          height: 12px;
          border: solid #727070;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          position: absolute;

          @media only screen and (max-width: 767px) {
            top: 3px;
            left: 8px;
          }
        }

        ._GB_notifi_data {
          color: #fff;
          font-family: "Gilroy-SemiBold";
          font-size: 18px;
          line-height: 21px;
          padding-left: 15px;

          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }

    .bottom_section {
      padding: 50px 0px;

      .notifi_btn {
        width: 136px;
        height: 42px;
        border: 2px solid #5b5b5b;
        font-family: "Poppins-SemiBold";
        border-radius: 10px;
        color: #fff;
        margin-right: 20px;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

/****************************Notification end ************************/
/****************************CommonChangeGame start ************************/
.common_change_game {
  background: #181818;
  border: 2px solid #5b5b5b;
  border-radius: 20px 0px 0px 20px;
  position: fixed;
  right: 0px;
  top: 37%;
  width: 70px;
  z-index: 99;
  padding: 10px;

  @media only screen and(max-width: 767px) {
    display: none !important;
  }

  .change {
    color: #fff;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0px;
    margin-right: 20px;
  }

  a {
    display: block;

    .games_list {
      img {
        border-radius: 25%;
        height: 55px;
        width: 55px;
        padding: 12px;

        &:hover {
          background: #000000;
          border: 2px solid #c80000;
        }
      }

      &.active {
        img {
          background: rgba(200, 0, 0, 0.5);
          border-color: #c80000;
        }
      }
    }
  }

  .reward_popup {
    .games_list {
      img {
        background-color: #000;
        border-radius: 10px;
      }
    }
  }
}

/****************************CommonChangeGame end ************************/

/****************************Device-menu start ************************/
/* The sidebar menu */
// .mobile-main {
//   display: block;
//   background-color: #000;
//   height: 90px;
//   @media only screen and (min-width: 992px) {
//     display: none;
//   }
//   padding: 20px 0px;
//   box-shadow: 10px 2px 14px 10px rgba(17, 15, 15, 0.06);

//   .sidebar {
//     height: 100%;
//     width: 0;
//     position: fixed;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     overflow-x: hidden;
//     transition: 0.5s;
//     background-color: #000;
//     background-image: linear-gradient(141deg, #000 0%, #000 51%, #000 75%);
//     a {
//       padding: 12px;
//       text-decoration: none;
//       font-size: 16px;
//       color: #fff;
//       display: block;
//       transition: 0.3s;
//       font-family: "Gilroy-SemiBold";
//       &:hover {
//         color: #f1f1f1;
//       }
//       img{
//         max-width: 265px;
//       }
//     }
//     .closebtn {
//       position: absolute;
//       top: 0;
//       right: 25px;
//       font-size: 36px;
//       margin-left: 50px;
//     }
//     .menu-points {
//       img {
//         width: 25px;
//         height: 25px;
//       }
//     }
//   }
//   .lang_dropdown {
//     font-family: "Gilroy-SemiBold";
//     .dropdown_btn {
//       border: none;
//       background: #000;
//       box-shadow: none;
//       &:hover {
//         border: none;
//         background: #000;
//         box-shadow: none;
//       }
//     }
//   }
//   .dropdown-menu {
//     background-color: #181818;
//     .lang_items {
//       padding: 15px;
//     }
//   }
// }
// @media screen and (max-height: 450px) {
//   .sidebar {
//     padding-top: 15px;
//   }
//   .sidebar a {
//     font-size: 18px;
//   }
// }

/****************************Device-menu End ************************/

/****************************user-Device-menu start ************************/
.user_device_menu {
  .mobile-main {
    display: block;
    background-color: #000;
    height: 90px;

    @media only screen and (min-width: 1366px) {
      display: none;
    }

    transition: margin-left 0.5s;
    /* If you want a transition effect */
    padding: 20px 0px;
    box-shadow: 10px 2px 14px 10px rgba(17, 15, 15, 0.06);

    .tooth-device-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item-logo {
        padding: 0;
      }

      .item-burger {
        .menu-burger {
          width: 40px;
          cursor: pointer;

          span {
            display: block;
            background: #fff;
            height: 5px;
            border-radius: 10px;

            &.middle {
              margin: 5px 0;
            }
          }
        }
      }

      #_GB_LogoSection {
        img {
          // max-width: 40px;
          // image-rendering: -webkit-optimize-contrast;
          // border: 4.5px solid #c80000;
          // border-radius: 100%;
          // @media only screen and (min-width: 375px) {
          //   max-width: 50px;
          // }
          // @media only screen and (min-width: 480px) {
          //   max-width: 60px;
          // }
          // @media only screen and (min-width: 1366px) {
          //   max-width: 265px;
          // }
        }
      }
    }

    /* The sidebar menu */
    #mobileSidebar {
      max-width: 100%;
      display: flex;
      flex-direction: column;
      z-index: 9999;
      padding-top: 40px;

      &.sidebar {
        height: 100%;
        /* 100% Full-height */
        width: 0;
        /* 0 width - change this with JavaScript */
        position: fixed;
        /* Stay in place */
        z-index: 1;
        /* Stay on top */
        top: 0;
        left: 0;
        overflow-x: hidden;
        /* Disable horizontal scroll */
        transition: 0.5s;
        /* 0.5 second transition effect to slide in the sidebar */
        background: #181818;
        z-index: 1000;

        .closebtn {
          position: absolute;
          top: 0;
          right: 25px;
          font-size: 36px;
          margin-left: 50px;
          color: #979797 !important;
          text-decoration: none;
        }

        a.nav-link {
          padding: 12px;
          text-decoration: none;
          font-size: 16px;
          color: #979797 !important;
          display: block;
          transition: 0.3s;
          font-family: "Gilroy-SemiBold";
          width: 100%;

          &:hover {
            color: #f1f1f1;
          }
        }

        .nav-link {
          padding: 12px;
          color: #fff;
          border-bottom: 1px solid #2d2d2d;
          background: #181818;

          &:first-child {
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
          }

          .dropdown {
            #dropdown-language {
              background-color: #181818;
            }
          }

          a {
            // padding: 12px;
            text-decoration: none;
            font-size: 16px;
            color: #979797 !important;
            display: block;
            transition: 0.3s;
            font-family: "Gilroy-SemiBold";
            width: 100%;

            &:hover {
              color: #f1f1f1;
            }

            img {
              max-width: 40px;
              image-rendering: -webkit-optimize-contrast;

              @media only screen and (min-width: 768px) {
                max-width: 265px;
              }
            }

            .text {
              padding-left: 24px;
            }

            &.sp-nav-logo {
              display: flex;

              img {
                padding-right: 30px;
                max-width: 180px;
                object-fit: contain;
              }
            }

            &.mode {
              .mode_type {
                color: #979797 !important;
                text-decoration: none;
              }
            }
          }

          &.profile {
            position: relative;

            .profile_image {
              img {
                width: 45px;
                height: 45px;
                max-width: 100%;
              }
            }

            .profile_info {
              position: static;

              .dropdown {
                position: static;

                #dropdown-profile {
                  background: #181818;
                  border: none;
                  font-size: 16px;
                  color: #979797 !important;
                  font-family: "Gilroy-SemiBold";
                }

                .profile_list_dd {
                  z-index: 1;
                  border-radius: none;
                  border: none;

                  // padding-left: 30px;
                  @media only screen and (max-width: 992px) {
                    transform: none !important;
                    left: 0 !important;
                    // top: 75px !important;
                    right: 0 !important;
                  }

                  hr {
                    display: none;
                  }

                  a {
                    .profile_dd_each {
                      a {
                        @media only screen and (max-width: 992px) {
                          // padding: 12px 0px;
                        }
                      }

                      img {
                        width: 45px;
                      }
                    }

                    .mode_alert {
                      white-space: break-spaces;
                    }
                  }
                }
              }
            }
          }

          .dashboard {
            img {
              width: 50px;
            }
          }

          &.wallet-section {
            a {
              div {
                color: #979797 !important;
              }
            }
          }
        }

        .warning {
          padding: 8px 50px;

          .warning_msg {
            font-size: 10px;
            margin: auto;
          }
        }

        .wallet_list_dd {
          z-index: 1;

          @media only screen and (max-width: 992px) {
            right: -69px;
          }

          .title_sec {
            img {
              @media only screen and(max-width: 574px) {
                width: 25px;
              }
            }
          }

          .active {
            a {
              @media only screen and(max-width: 992px) {
                padding: 0px 12px;
              }
            }
          }
        }

        .wallet_dropdown {
          .title_section {
            text-align: center;
          }
        }
      }

      &.active {
        // padding-left: 30px;
        // padding-right: 30px;
      }
    }

    .dropdown-menu {
      background-color: #181818;

      .lang_items {
        padding: 15px;
      }

      .dropdown-item {
        &:not(:last-child) {
          border-bottom: 1px solid #2d2d2d;
        }
      }
    }
  }
@media screen and (min-width: 1025px) {
    display: none !important;
}
  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {
      padding-top: 15px;
    }

    .sidebar a {
      font-size: 18px;
    }
  }
}

/****************************user-Device-menu start ************************/

/***************************Two factor authentication****************/
.GB_twofactor_authentication {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0px;

  input[type="tel"] {
    background: #000;
    border: none;
    border-bottom: 1px solid #e2e2e2;
    font-family: "Gilroy-Regular";
    font-size: 14px;
    color: #fff;
  }

  .otp-validation {
    font-family: "Gilroy-Medium";
    font-size: 13px;
    line-height: 19px;
    color: #e52420;
    padding-top: 10px;
  }

  .resend-otp {
    margin-top: 20px;
    display: flex;
    align-items: center;

    span {
      font-family: "Gilroy-Medium";
      font-size: 14px;
      line-height: 19px;
      color: #ccc;
    }

    button {
      background-color: transparent;
      color: #e52420;
      border: none;

      &:disabled {
        color: #bd3b3b9c;
      }
    }
  }
}

/***************************End two factor authentication****************/
.GB_device_td {
  text-align: center;

  @media only screen and (max-width: 767px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-left: 10px !important;
    padding-right: 10px !important;
    position: relative;
  }

  &:before {
    @media only screen and (max-width: 767px) {
      content: attr(data-label);
      font-weight: bold;
      text-transform: uppercase;
      top: 10px;
      color: #777;
    }

    @media only screen and (max-width: 479px) {
      position: absolute;
    }
  }

  .table-cont {
    @media only screen and (max-width: 479px) {
      padding-top: 30px;
      font-size: 12px;
    }
  }

  &.td_date {
    @media only screen and(min-width: 768px) {
      text-align: left;
      padding-left: 20px !important;
    }
  }
}

.GB_device_th {
  @media only screen and (max-width: 767px) {
    display: block;
    text-align: center;
  }
}

/***************************Transactions starts****************/

._GB_Transactions {
  ._GB_Header {
    background-color: #000;
  }

  ._GB_Banner_Top {
    ._GB_Transactions_Banner {
      background-color: #000;
      padding: 50px 0px;

      ._GB_Transactions_Inner {
        h2 {
          font-family: "Gilroy-Bold";
          font-size: 48px;
          line-height: 90px;
          color: #ffffff;

          @media only screen and (max-width: 990px) {
            font-size: 40px;
          }

          @media only screen and (max-width: 480px) {
            font-size: 35px;
          }
        }

        .Transactions_Bg {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px 33px 10px 33px;

          @media only screen and (max-width: 7674px) {
            padding: 20px 20px 10px 20px;
          }

          .top-section {
            justify-content: space-between;

            @media only screen and (max-width: 574px) {
              flex-direction: column;
              text-align: center;
            }
          }

          .img-section {
            @media only screen and(max-width: 574px) {
              margin-bottom: 15px;
            }

            .title {
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
              color: rgba(235, 235, 245, 0.6);
            }
          }

          .SelectBoxGrp {
            justify-content: flex-end;

            @media only screen and(max-width: 574px) {
              justify-content: center;
            }

            .fortnite {
              margin-right: 15px;

              @media only screen and (max-width: 767px) {
                margin-right: 0px;
              }
            }

            .Transactions_drop {
              background-color: #050505;
              border-radius: 10px;
              border-color: #050505;
              color: #fff;
              width: 189px;
              padding: 5px 15px;
              margin-bottom: 25px;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
              color: #ffffff;

              @media only screen and (max-width: 574px) {
                margin-bottom: 15px;
              }

              option {
                background-color: #050505;
                border-radius: 10px;
                border-color: #050505;
                color: #fff;
                width: 189px;
                padding: 5px 15px;
                margin-bottom: 15px;
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
              }
            }

            .sortType {
              .sort_drop {
                background-color: #050505;
                border-radius: 10px;
                border-color: #050505;
                color: #fff;
                width: 210px;
                padding: 5px 15px;
                margin-bottom: 25px;
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;

                option {
                  background-color: #050505;
                  border-radius: 10px;
                  border-color: #050505;
                  color: #fff;
                  width: 189px;
                  padding: 5px 15px;
                  margin-bottom: 15px;
                  font-family: "Gilroy-Bold";
                  font-size: 16px;
                  line-height: 19px;
                  color: #ffffff;
                }
              }
            }

            .currancyConvert {
              &::after {
                content: url("../images/wallet_conversion.png");
                top: 3px;
                position: relative;
                cursor: pointer;
              }
            }
          }

          .full_section {
            .Transactions_details {
              // background: #1f1f1f;
              // border-radius: 15px;
              // padding: 18px 18px;
              width: 100%;

              // margin-bottom: 7px;
              .table> :not(:first-child) {
                border-top: 0px solid currentColor;
              }

              table {

                // @media only screen and (max-width: 991px){
                //   display: flex;
                // }
                thead {
                  background: #292929;

                  @media only screen and (max-width: 767px) {
                    display: none;
                  }

                  tr {
                    background: #292929;
                    border-radius: 10px 10px 0px 0px;

                    // @media only screen and (max-width: 991px){
                    //   display: grid;
                    // }
                    th {
                      font-family: "Gilroy-Bold";
                      font-size: 16px;
                      line-height: 100%;
                      color: rgba(235, 235, 245, 0.6);
                      padding: 10px 10px 10px 3px;
                      text-align: center;

                      &:first-child {
                        padding: 10px 10px 10px 20px;
                        border-radius: 10px 0px 0px 0px;
                      }

                      &:last-child {
                        border-top-right-radius: 10px;
                      }

                      &.trans_date {
                        @media only screen and (min-width: 768px) {
                          text-align: left;
                        }
                      }

                      &.trans_action {
                        @media only screen and (min-width: 768px) {
                          text-align: left;
                        }
                      }

                      &.balance_th {
                        @media only screen and (min-width: 768px) {
                          text-align: left;
                        }
                      }
                    }
                  }
                }

                tbody {
                  tr {

                    // @media only screen and (max-width: 991px){
                    //   display: grid;
                    // }
                    &:nth-child(even) {
                      td {
                        border-color: #000;
                        background: #121212;

                        @media only screen and (max-width: 991px) {
                          border-color: #181818;
                          background: #060606e0;
                        }
                      }
                    }

                    &:nth-child(odd) {
                      td {
                        border-color: #000;
                        background: #161616;
                      }
                    }

                    &.transaction1 {
                      &:nth-child(even) {
                        .GB_device_td {
                          @media only screen and (max-width: 767px) {
                            border-color: #000;
                            background: #212529;
                          }

                          .tr_withdraw {
                            width: 60px;
                            margin-right: 10px;
                          }

                          .GbplayerCol {
                            text-align: right;
                          }
                        }
                      }

                      &:nth-child(odd) {
                        .GB_device_td {
                          border-color: #000;
                          background: #161616;

                          .tr_withdraw {
                            width: 60px;
                            margin-right: 10px;
                          }
                        }
                      }
                    }

                    td {
                      font-family: "Gilroy-Bold";
                      font-size: 18px;
                      line-height: 100%;
                      color: #ffffff;
                      vertical-align: middle;
                      padding: 10px 2px;
                      box-shadow: none;
                      position: relative;

                      @media only screen and (max-width: 991px) {
                        font-size: 14px;
                      }

                      .normal-earnings.has-popup {
                        &:hover {
                          cursor: pointer;
                        }
                      }

                      &:nth-child(3) {
                        // // background: #C4C4C4;
                        // opacity: 0.04;
                      }

                      // &:nth-child(2) {
                      //   width: 40%;
                      // }
                      .redBox {
                        height: 32px;
                        width: 32px;
                        background: #e52420;
                        border-radius: 10px;
                        font-family: "Gilroy-Bold";
                        font-size: 18px;
                        line-height: 100%;
                        text-align: center;
                        color: #ffffff;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                      }

                      .GbplayerCol {
                        display: flex;
                        align-items: center;

                        @media only screen and (max-width: 991px) {
                          display: flex;
                          line-height: 21px;
                          justify-content: right;
                          text-align: right;
                        }

                        img {
                          box-sizing: border-box;
                          //border-radius: 100%;
                          margin-right: 20px;

                          @media only screen and(max-width: 767px) {
                            margin-right: 0;
                          }

                          &.credit-game-img {
                            width: 48px;
                          }
                        }
                      }

                      .new_balance {
                        display: flex;
                        align-items: center;

                        @media only screen and (max-width: 991px) {
                          justify-content: right;
                        }

                        img {
                          margin-right: 20px;
                        }
                      }
                    }

                    &.deleted {
                      opacity: 0.4;

                      span,
                      time,
                      div {
                        text-decoration: line-through;
                      }
                    }
                  }
                }
              }
            }
          }

          .pagination-full-section {
            margin: 40px 0px;

            .paginate {
              margin: 0px 13px;
              background: rgba(0, 0, 0, 0.5);
              border: 1px solid #4f4f4f;
              box-sizing: border-box;
              border-radius: 10px;
              padding: 4px 4px;
              justify-content: center;
              align-items: center;

              ul {
                margin-bottom: 0px;
                align-items: center;
                justify-content: center;
                display: flex;
                position: relative;

                li {
                  list-style-type: none;
                  margin-right: 10px;

                  @media only screen and (max-width: 767px) {
                    display: none !important;
                  }

                  &.previous {
                    @media only screen and (max-width: 767px) {
                      display: block !important;
                    }
                  }

                  &.next {
                    @media only screen and (max-width: 767px) {
                      display: block !important;
                    }
                  }

                  a {
                    font-family: "Gilroy-Bold";
                    font-size: 18px;
                    line-height: 100%;
                    text-align: center;
                    color: #ffffff;
                    background-color: transparent;
                    border: none;

                    &:focus {
                      box-shadow: none;
                    }
                  }

                  &.previous {
                    position: absolute;
                    left: -61px;

                    a {
                      background: url(../images/rightarrow.png) 0px 0px no-repeat;
                      height: 50px;
                      width: 50px;
                    }
                  }

                  &.next {
                    position: absolute;
                    right: -61px;

                    a {
                      background: url(../images/leftarrow.png) 0px 0px no-repeat;
                      height: 50px;
                      width: 50px;
                    }
                  }

                  &.active {
                    background: #e52420;
                    border-radius: 8px;
                    height: 42px;
                    width: 42px;
                    vertical-align: middle;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                  }

                  &:last-child {
                    margin-right: 0px;
                  }

                  &:first-child {
                    margin-right: 0px;
                  }

                  &:nth-last-child(2) {
                    margin-right: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/***************************Transactions End****************/

/***************************PageNotFound Star****************/
._GB_PageNotFound {
  background-color: #000;

  .pagenotfound_inner {
    height: 700px;

    img {
      @media only screen and(max-width: 374px) {
        width: 100%;
      }
    }

    .title {
      color: #fff;
      font-family: "Gilroy-Bold";
      font-size: 70px;
      line-height: 70px;

      @media only screen and (max-width: 767px) {
        font-size: 50px;
        line-height: 50px;
      }
    }

    .sub_title {
      color: #c80000;
      font-size: 30px;
      line-height: 30px;
      font-family: "Gilroy-Medium";
      padding-top: 10px;
      padding-bottom: 50px;

      @media only screen and (max-width: 767px) {
        font-size: 25px;
      }
    }

    .go_to {
      color: #fff;
      font-family: "Gilroy-Medium";
      font-size: 21px;
      text-decoration: none;
      border-bottom: 2px solid #fff;
      cursor: pointer;

      &:hover {
        opacity: 0.2;
      }
    }
  }
}

/***************************PageNotFound End****************/
.country-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #3c3939;
}

.country-list::-webkit-scrollbar {
  width: 6px;
  background-color: #3c3939;
}

.country-list::-webkit-scrollbar-thumb {
  background-color: #000000;
}

/***************************wallet_popup   Start****************/
.connect_popup {
  .modal-content {
    @media only screen and (min-width: 768px) {
      max-width: 550px;
      margin: auto;
    }

    .wallet-section {
      .connect_wallet_popup {
        .wallet_list_dd {
          @media only screen and (min-width: 768px) {
            width: 440px;
            margin: auto;
          }
        }

        .title_sec {
          .title {
            font-size: 28px !important;

            @media only screen and (min-width: 768px) {
              font-size: 30px !important;
            }

            @media only screen and (min-width: 1920px) {
              font-size: 40px !important;
            }

            color: #fff;
            font-family: "Poppins-SemiBold" !important;
            text-align: center;
          }

          .sub_title {
            font-family: "Poppins-Medium";
            font-size: 16px !important;
            color: #fff !important;
            line-height: 23px !important;
            padding-bottom: 30px;
            text-align: center;

            @media only screen and (max-width: 374px) {
              padding-bottom: 15px;
            }
          }
        }

        .wallet-wrap {
          // background-color: #262627;
          border-radius: 10px;
          padding: 8px 10px;

          @media only screen and (max-width: 374px) {
            flex-direction: column !important;
            justify-content: center !important;
            align-items: center;
          }

          .walletcotxt {
            color: #fff;
            opacity: 0.5;
          }

          .dropdown-menu {
            background-color: #181818;
            border: 1px solid #2d2d2d;
            border-radius: 14px;
            transform: translate(-41px, 41.2222px) !important;

            .dropdown-item {
              color: #fff !important;
              background-color: #181818;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 18px;
              width: 180px;

              .game_dropdown_each {
                color: #fff;
                padding: 5px;
                border-radius: 10px;

                &:hover {
                  background-color: #000;
                }

                cursor: pointer;

                &:hover,
                &.active {
                  background: #000000;

                  .game_dd_name {
                    color: #fff;
                  }

                  .btn-success {
                    background: transparent;
                    border: none;

                    &:focus {
                      box-shadow: none !important;
                    }
                  }
                }

                &.disable {
                  opacity: 0.5;
                  pointer-events: none;

                  .btn-success {
                    background: transparent;
                    border: none;

                    &:focus {
                      box-shadow: none !important;
                    }
                  }
                }
              }
            }
          }

          .btn-success {
            background-color: #262627;
            border-color: #262627;

            &:focus {
              outline: none;
              box-shadow: none;
            }

            .selconname {
              color: #fff;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 18px;
            }
          }
        }

        ._GB_Connect {
          max-width: 232px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 15px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 45px;
          text-decoration: none;
          margin-top: 35px;
          margin-bottom: 15px;

          @media only screen and (max-width: 374px) {
            padding: 18px 25px;
            height: 56px;
            display: flex;
            align-items: center;
            margin-top: 15px;
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  ._Icon_coin {
    color: #fff;
    font-family: "Gilroy-Bold";
    font-size: 15px;
    line-height: 17px;

    @media only screen and (max-width: 374px) {
      font-size: 12px;
      flex-wrap: wrap;
    }

    img {
      padding-right: 10px;

      @media only screen and (max-width: 374px) {
        width: 30px;
      }
    }

    .token_dollar {
      padding-left: 3px;
    }
  }
}

/***************************wallet_popup   End****************/
.phantom-install {
  text-decoration: none;
  color: #fff;
}

.ver-gametag {
  padding-top: 10px;
  padding-right: 10px;

  @media only screen and(max-width: 767px) {
    padding-right: 0;
  }
}

.register_gb_langmenu {
  position: absolute;
  right: 15px;
  top: 21px;

  @media only screen and(min-width: 480px) {
    right: 42px;
  }
}

.reward_popup .games_list {
  cursor: pointer;
}

.errorMessage {
  font-family: "Gilroy-Medium";
  font-size: 16px;
  color: #fff;
}

.steam_verify_outer {
  background: #000;
  padding: 20px;
}

.login-video-embed-sect {
  iframe {
    padding: 50px 80px;
  }
}

.optloader {
  width: 180px;
  color: #fff;
  font-size: 18px;
  font-family: "Gilroy-Medium";
}

.web-gameselected img {
  width: 40px;
  margin-right: 3px;
}

._verifyPages {
  .register_left_inner {
    max-width: 100% !important;

    .verification-logo {
      img {
        width: 300px;
      }
    }

    a {
      color: #c80000;
    }
  }
}

.webOnlyNG {
  @media only screen and(max-width: 767px) {
    display: none;
  }
}

.mobileOnlyNG {
  @media only screen and(min-width: 768px) {
    display: none;
  }

  ._GB_Enter_Player {
    background: #181818;
    border: 2px solid #2d2d2d;
    border-radius: 14px;
    height: 60px;
    color: #fff;
    font-family: "Poppins-Medium";
    box-shadow: none !important;
  }

  .mobileOnlyGameselect {
    background: #181818;
    border: 2px solid #2d2d2d;
    border-radius: 14px;
    margin-top: 10px;
    justify-content: space-between;

    #dropdown-newgames {
      background: #181818;
      border: none;
      border-radius: 14px;
      padding: 5px 10px;
      height: 60px;
      font-family: "Poppins-Medium";

      img {
        width: 40px;
      }

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }

    .game_dropdown_each {
      cursor: pointer;
      margin: 4px 0;
      padding: 8px;

      &:hover {
        background: #e6e6e6;
        border-radius: 8px;
      }

      .game_dd_image {
        width: 40px;
        text-align: center;

        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }

        &.all_game_img {
          img {
            width: 25px;
            height: 25px;
          }
        }
      }

      .game_dd_name {
        padding-left: 13px;
        font-family: "Poppins-Medium";
      }
    }

    ._GB_Search_Player {
      border: none !important;
      background: none !important;
      box-shadow: none !important;
    }

    .spinner-border {
      margin-right: 10px;
    }
  }

  .mobileANBTN {
    margin-top: 15px;

    button {
      height: 49px;
      width: 100%;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border: 2px solid #fff;
      border-radius: 10px;
      padding: 12px 25px;
      text-decoration: none;
      color: #fff;
      font-family: "Poppins-SemiBold";
      font-size: 15px;
      line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;

      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

._initPage .register_wrap {
  margin-top: 0;
}

.gb_data_loader {
  background-color: #000;
  min-height: 100vh;

  .loader-text {
    font-family: "Poppins-Medium";
    font-size: 22px;
    color: #fff;
  }
}

.whiteText {
  display: contents;
  color: #fff;
}

.loginverifymsg {
  padding: 10px 20px;
  text-align: center;
}

.featured_slider_image {
  @media only screen and(min-width: 768px) {
    max-width: 512px;
    margin: auto;
    margin-bottom: 40px;
  }

  .featured_slider_game_mode {
    @media only screen and(min-width: 768px) {
      padding: 0 0 0 10px;
      text-align: left;
      position: relative;
      top: 50px;
    }
  }
}

.loadmarketcapwarp {
  font-family: "Gilroy-Bold";
  font-size: 20px;
  line-height: 30px;
}

.marketValueNote {
  color: #fe5242;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
  padding: 10px 0;
  text-align: center;
}

.adminformfield {
  background-color: #000;
  border: 2px solid #2d2d2d;
  border-radius: 15px;
  height: 56px;
  width: 100%;
  font-family: "Gilroy-Bold";
  font-size: 19px;
  line-height: 19px;
  color: #fff;
}

.twitchLink {
  width: 100%;
  color: gold !important;
  font-size: 14px;
  text-decoration: none;
}

.isstreatmer {
  color: #e52420;
  font-size: 15px;
}

.streamer-icon {
  border: none !important;
  width: 30px !important;
  height: 32px !important;
  cursor: pointer;
}

.twitch-icon {
  width: 20px;
  margin-right: 5px;
}

.player-twitch {
  display: flex;
  flex-direction: column;
}

.lplayername {
  padding-top: 5px;
}

.twitchLink-wrap {
  padding-top: 5px;
}

.search-admin-form-control {
  background: #000 !important;
  border: 2px solid #2d2d2d;
  border-radius: 15px;
  height: 56px;
  width: 100%;
  font-family: "Gilroy-Bold";
  font-size: 17px;
  line-height: 19px;
  color: #fff !important;
}

.gold-text {
  color: gold;
}

.mobile-bottom-nav img {
  width: 45px;
  height: 45px;
  object-fit: fill;
}

#mobileSidebar .mode_alert {
  width: auto;
}

.nav-gbtokens {
  position: relative;
  left: -45px;
  font-family: "Gilroy-Bold";

  img {
    width: 25px;
  }

  .total_coins.text {
    padding-left: 3px;
    font-size: 13px;
  }
}

.totalRewradDollar {
  font-size: 22px !important;
  padding: 15px 0;
  flex-direction: column;
}

._lb_web_table {
  display: none;

  @media only screen and(min-width: 768px) {
    display: block;
  }
}

._lb_mob_table {
  display: block;

  ._each_lb {
    background: #212529;
    border-radius: 15px;
    padding: 10px;
    margin: 15px 0;
    color: #fff;
    font-family: Gilroy-Bold;

    ._each_lb_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      ._each_lb_left {
        flex: 0 0 60%;
        max-width: 60%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding: 5px 0;

        ._rankBox {
          color: #fff;
          align-items: center;
          border-radius: 10px;
          display: flex;
          font-family: Gilroy-Bold;
          font-size: 18px;
          height: 32px;
          justify-content: center;
          line-height: 100%;
          text-align: center;
          width: 32px;
        }

        ._redBox {
          background: #e52420;
        }

        ._goldBox {
          background: #fda829;
        }

        ._silverBox {
          background: #444444;
        }

        ._bronzeBox {
          background: #d99246;
        }

        ._proimgBox img {
          border: 2px solid #fff;
          border-radius: 100%;
          box-sizing: border-box;
          height: 45px;
          object-fit: cover;
          width: 45px;

          @media only screen and(max-width: 767px) {
            height: 35px;
            width: 40px;
          }
        }
      }

      ._each_lb_right {
        flex: 0 0 40%;
        max-width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;

        ._streamBox img {
          width: 25px;
        }

        ._flagBox {
          img {
            width: 45px;
          }
        }
      }
    }

    ._each_lb_score {
      background: #000;
      border-radius: 8px;
      color: #777;
      text-transform: uppercase;

      ._each_lb_scoreline {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #777;
        padding: 8px 15px;

        &._each_lb_assists {
          border: none;
        }
      }
    }
  }

  @media only screen and(min-width: 768px) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .pagination-full-section {
    margin-top: 30px !important;
  }

  .pagination-full-section .paginate ul li {
    display: none !important;
  }

  .pagination-full-section .paginate ul li.next,
  .pagination-full-section .paginate ul li.previous {
    display: block !important;
  }
}

.ladderopt {
  select {
    background-color: #050505;
    border-radius: 10px;
    border-color: #050505;
    color: #fff;
    width: 189px;
    padding: 5px 15px;
    margin-bottom: 25px;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }

  @media only screen and (max-width: 767px) {
    text-align: center;
  }
}

.dicord2xpopup {
  .modal-content {
    max-width: 700px !important;
    border-radius: 10px !important;
    padding: 30px !important;
    padding-bottom: 50px !important;

    .btn-close {
      opacity: 1 !important;

      &:focus {
        box-shadow: none !important;
      }
    }

    .discordpp-inner {
      text-align: center;

      .discordImage {
        margin-bottom: 20px;
      }

      .title {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 23px;
        padding: 0 20px;

        @media only screen and (min-width: 1920px) {
          font-size: 35px;
        }

        margin-bottom: 20px;

        &.med-title {
          font-size: 20px;

          @media only screen and (min-width: 1920px) {
            font-size: 28px;
          }
        }
      }

      .subTitle {
        color: #fff;
        font-family: "Gilroy-Bold";
        max-width: 420px;
        margin: auto;
        margin-bottom: 20px;
        font-size: 14px;

        @media only screen and (min-width: 1920px) {
          font-size: 18px;
        }
      }
    }
  }
}

.smurfMsg {
  color: #fff;
  font-family: "Gilroy-Bold";
  font-size: 20px;
  background: #121212;
  border: 1px solid #2b2b2b;
  padding: 15px 20px;
  border-radius: 10px;
  margin-top: 30px;
  // @media only screen and (min-width: 1600px) {
  //   min-height:
  // }
  // @media only screen and (min-width: 1700px) {
  //   top: 180px;
  // }
  // @media only screen and (min-width: 1920px) {
  //   top: 200px;
  // }
}

.channeLink a {
  width: 100%;
  color: gold !important;
  font-size: 14px;
  text-decoration: none;
}

.allguidesbtn {
  display: flex;
  margin: 20px 10px;

  // @media only screen and (min-width: 1366px) {
  //   justify-content: end;
  // }
  a {
    font-family: "Gilroy-SemiBold";
    font-size: 14px;
    text-decoration: none;
    background: #000000;
    border: 1.5px solid #e52420;
    box-sizing: border-box;
    box-shadow: 0px 0px 4px #e52420;
    border-radius: 7px;
    padding: 8px 13px 7px;
    color: #fff;

    &:hover {
      opacity: 0.5;
    }
  }
}

.mobcurrddicon #dropdown-curr-wallet img {
  width: 15px !important;
}

.comp-web-nav-item {
  .dropdown-menu {
    width: 260px;
  }
}

.dashjoinviewbtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 767px) {
    justify-content: flex-start;
  }

  .joindashbtn {
    background: #52dd21;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    line-height: 20px;
    padding: 11px 20px;
    border-radius: 10px;
    cursor: pointer;

    @media only screen and (min-width: 1366px) and (max-width: 1600px) {
      font-size: 14px;
      padding: 7px 20px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 11px;
      line-height: 12px;
      padding: 10px;
    }

    &:hover {
      background: #ffc01f;
    }
  }

  .dashlbviewbtn {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}

.mobile-tournament-acc-item {
  border-radius: 0px !important;
  background: #181818 !important;

  .mobile-tournament-acc {
    padding: 0px !important;

    .accordion-item {
      border: none;
    }

    .accordion-header {
      background: #181818;
    }

    .accordion-button {
      background: #181818;
      color: #fff;

      &:hover {
        background: #181818;
      }

      box-shadow: none;
      border-radius: 0 !important;

      &:after {
        background-image: url("../images/dd_down.png") !important;
        background-position: center;
        background-size: auto;
      }
    }

    .accordion-collapse {
      // background: #000;
      background: #181818;
      border-radius: 0 !important;
    }
  }

  &:hover {
    .accordion-button {
      background: #181818;
      box-shadow: none;
    }

    .accordion-header {
      background: #181818;
    }

    .accordion-item {
      background: #000;
    }
  }

  .compnamemob {
    font-size: 16px;
    color: #979797 !important;
  }
}

.Leaderboard_Bg_mob {
  @media only screen and (max-width: 767px) {
    padding: 15px 10px !important;
  }
}

.modal-betting-Result {
  padding: 0 !important;

  .modal-dialog {
    @media only screen and (min-width: 768px) {
      padding: 0px !important;
      max-width: 540px;
    }

    .modal-content {
      background: #181818;
      border: 2px solid #454545;
      border-radius: 25px;

      //margin-bottom: 20%;
      .modal-header {
        border: none;

        .btn-close {
          background: url("../images/close_button.png") no-repeat;
          position: relative;
          top: 10px;

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
}

.betting_Result {
  padding: 10px;

  @media only screen and (min-width: 991px) {
    padding: 1px 5px;
  }

  // @media only screen and (min-width: 991px) and (max-width: 1919px) {
  //   padding: 1px 40px;
  // }
  .betting_Resultimage {
    max-width: 33%;
    width: 100%;
    margin: auto;
    padding-bottom: 20px;

    @media only screen and (min-width: 1500px) and (max-width: 1919px) {
      max-width: 25%;
      padding-bottom: 20px;
    }

    @media only screen and (min-width: 991px) and (max-width: 1499px) {
      max-width: 16%;
    }
  }

  .betting_loader {
    font-family: "Gilroy-SemiBold";
    font-size: 16px;
    line-height: 18.75px;
    color: #ffffff;
    padding-bottom: 20px;

    @media only screen and (min-width: 991px) and (max-width: 1919px) {
      padding-bottom: 20px;
    }
  }

  .betting_Resultmain {
    .betting_Resultlist {
      background: #000000;
      border: 2px solid #2d2d2d;
      border-radius: 12px;
      font-family: "Gilroy-SemiBold";
      font-size: 14px;
      line-height: 16.41px;
      color: #ffffff;
      padding: 10px 20px;
      margin-bottom: 5px;

      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }

      p {
        line-height: 17px;
        position: relative;
        margin: 0;
        margin-left: 17px;
        // &:first-child{
        //   &:before{
        //     content: url("../images/betting/betting_newloader.png");
        //     position: absolute;
        //     top: -3px;
        //     left: -33px;
        //   }
        // }
        // @media only screen and (min-width: 768px) {
        //   line-height: 72px;
        // }
      }

      .betting_Resultlist_sec {
        margin-top: 6px;
        margin-left: 28px;

        .betting_Resultlistsub {
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 5px;

          // @media only screen and (max-width: 767px) {
          //   font-size: 14px;
          // }
          span {
            color: #ffc52e;
          }

          &:before {
            content: none;
          }
        }
      }

      &.betinitload {
        display: flex;
        align-items: center;

        ._gb_circle_spinner {
          border: 4px dotted #2d2d2d;
          border-radius: 50%;
          border-top: 0px solid #ddd;
          width: 27px;
          height: 27px;
          -webkit-animation: spin 2s linear infinite;
          /* Safari */
          animation: spin 2s linear infinite;
        }

        /* Safari */
        @-webkit-keyframes spin {
          0% {
            -webkit-transform: rotate(0deg);
          }

          100% {
            -webkit-transform: rotate(360deg);
          }
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      &.betting_fetching {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        p {
          margin-left: 17px !important;
        }

        &.betting_tick {
          p {
            margin-left: 38px !important;
          }
        }

        .last_match_id {
          background: #ffc52e;
          border-radius: 10px;
          padding: 2px 10px;
          margin-left: 36px;
          margin-top: 5px;
          color: #000;
          line-height: 10px;
          font-family: "Gilroy-SemiBold";
          font-size: 14px;

          @media only screen and (max-width: 575px) {
            padding: 2px 6px;
            font-size: 10px;
            margin-left: 30px;
          }
        }
      }
    }

    .sp_yel {
      color: #ffc52e;
    }

    .betting_tick {
      p {
        &:first-child {
          &:before {
            content: url("../images/betting/betting_newtick.png");
            position: absolute;
            top: 0px;
            left: -34px;
          }
        }
      }
    }
  }

  .betting_Resultbtn {
    margin-top: 12px;

    .result_btn {
      max-width: 232px;
      color: #fff;
      background-color: #e52420;
      background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
      border-radius: 10px;
      border: 2px solid #ffffff;
      font-family: "Poppins-Bold";
      font-size: 12px;
      //line-height: 17px;
      text-transform: uppercase;
      letter-spacing: 0.04em;
      padding: 9px 30px;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;

      .spinner-border {
        margin-right: 5px;
      }

      &::after {
        content: url("../images/arrow.png");
        padding-left: 10px;
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .trans {
    background: #181818 !important;

    &:hover {
      background-color: #e52420 !important;
      opacity: 1 !important;
      background-image: linear-gradient(to right,
          #6d1513,
          #e52420,
          #6d1513) !important;
    }
  }
}

.betinitload {
  display: flex;
  align-items: center;

  ._gb_circle_spinner {
    border: 4px dotted #2d2d2d;
    border-radius: 50%;
    border-top: 0px solid #ddd;
    width: 27px;
    height: 27px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.betting_tick {
  padding: 15px 22px !important;

  p {
    margin-left: 38px !important;
  }

  .betting_Resultlist_sec {
    margin-left: 0px !important;
  }
}

.currency-wallet-main .dropdown-menu {
  @media only screen and (max-width: 767px) {
    max-width: 320px;
  }

  .curr-wallet-wrap .curr-wallet-coins .curr-wallet-coins-inner .curr-wallet-coins-items .item-token .coin-amount {
    @media only screen and (max-width: 767px) {
      margin-left: 10px;
    }
  }

  button.curr-wallet-btn {
    @media only screen and (max-width: 767px) {
      font-size: 12px;
      padding: 15px 0 !important;
    }
  }
}

.nav-gbtokens {
  .curr-wallet-links-items {
    img {
      width: auto !important;
      margin-right: 0 !important;
    }
  }
}

._beta_leader_wrap {
  @media only screen and (max-width: 1024px) {
    padding: 15px !important;
  }

  @media only screen and (max-width: 490px) {
    padding: 0px !important;
  }

  .leaderboard-wrap {
    .title-section {
      @media only screen and (max-width: 1024px) {
        // flex-direction: column !important;
      }
    }
  }

  .dashjoinviewbtn {
    @media only screen and (max-width: 1024px) {
      flex-direction: column;
      margin: 20px;
    }

    @media only screen and (max-width: 767px) {
      margin: 0px;
    }
  }
}

.comingsoonlink {
  cursor: default;
}

.currency-wallet-main #dropdown-curr-wallet img {
  @media only screen and (max-width: 767px) {
    width: 13px;
    position: relative;
    top: -3px;
    left: -5px;
  }
}

.placingBetBtn {
  max-width: 200px;
  width: 100%;
  color: #fff;
  background-color: #e52420;
  background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
  border-radius: 10px;
  border: 2px solid #ffffff;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  margin: 20px auto;

  &::after {
    content: url("../images/arrow.png");
    padding-left: 10px;
  }

  &.blurred {
    cursor: default;
    opacity: 0.3;
  }
}

#switch-popup {
  background: rgba(200, 0, 0, 0.5);
  height: 38px;
  width: 196px;
  border: 2px solid #c80000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  font-size: 14px;
  line-height: 14px;
  font-family: "Gilroy-Bold";
  z-index: 9999;
  color: #ffffff;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  .popover-arrow {
    &::after {
      content: url("../images/tooltip_arrow.png");
      border-top: none;
      top: -2px;
      left: -12px;
    }
  }

  .popover-body {
    padding: 0px;

    .switch-box {
      color: #fff;
    }
  }
}

.gold-text.capitalize {
  text-transform: capitalize;
}

.challhistgameimg img {
  width: 40px;
}

.dash_titlemob {
  font-size: 24px;
  line-height: 30px;
  font-family: "Gilroy-Bold";
  color: #fff;
}

.summarycontainermob {
  .container {
    @media only screen and (max-width: 1024px) {
      max-width: 100% !important;
      padding: 0 !important;
    }
  }

  .betting-top-container {
    // padding: 0 15px;
  }
}

/*---*/
@media only screen and (max-width: 767px) {
  .GbplayerCol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  span.usermatchdetailswrap {
    font-size: 12px;
    line-height: 12px;
  }

  .usergamematches {
    font-size: 10px !important;
    padding-top: 3px !important;
  }
}

.lb_bg_box {
  position: relative;

  img {
    opacity: 0.4;
  }

  .lb_bg_comingsoon {
    position: absolute;
    top: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: "Poppins-Bold";
    font-size: 30px;
    line-height: 30px;
    color: #fff;
    width: 100%;
    text-align: center;
  }
}

.comingsoon_watchlist {
  opacity: 0.5;
  pointer-events: none;
}

._afficonmenu {
  position: relative;

  ._afficon {
    width: 23px;
    height: 23px;
    position: absolute;
    right: 0px;
    top: -3px;
  }
}

#comingsoon-popover {
  background: #181818;
  color: #fff;
  z-index: 9999;

  .popover-arrow {
    &::after {
      border-bottom-color: #000 !important;
      border-top-color: #000 !important;
    }
  }

  .popover-header {
    background: #000;
    color: #fff;
    font-family: "Gilroy-SemiBold";
    text-align: center;
    font-size: 15px;
    border-radius: 6px !important;

    &::before {
      border-bottom: #181818 !important;
    }
  }
}

#comingsoon-right-popover {
  background: #181818;
  color: #fff;
  z-index: 9999;

  .popover-arrow {
    &::after {
      border-bottom-color: transparent !important;
      border-top-color: transparent !important;
      border-right-color: #000 !important;
    }
  }

  .popover-header {
    background: #000;
    color: #fff;
    font-family: "Gilroy-SemiBold";
    font-size: 15px;
    text-align: center;
    border-radius: 6px !important;

    &::before {
      border-bottom: #181818 !important;
    }
  }
}

.trswaparrow {
  img {
    margin-right: 5px !important;
    margin-left: 5px !important;

    @media only screen and (max-width: 767px) {
      width: 15px !important;
    }
  }
}

.messagefailedad {
  padding: 10px;
  text-transform: none !important;
  font-size: 13px;
  color: #777;
}

@media only screen and (min-width: 768px) {
  .lf_playersectd {
    width: 25% !important;
  }
}

.convertion-tooltip {
  background: #181818;
  border: 2px solid #2d2d2d;
  border-radius: 8px;

  //transform: translate(1456px, -248px) !important;
  .tooltip-arrow {
    &::before {
      top: 8px;
    }
  }

  .tooltip-inner {
    background: #181818 !important;
    color: #fff !important;
    font-family: "Poppins-Medium";

    @media only screen and (min-width: 990px) {
      width: 100%;
      max-width: 300px;
    }
  }
}

.currency_icon {
  text-align: center;
  border-radius: 3px;
  border: 1px solid #00000000;
  box-sizing: border-box;
  padding: 2px;
}

.currency_icon.conv {
  border: 1px solid #e52420;
  filter: drop-shadow(0px 0px 4px #e52420);
  padding: 2px;
}