._GBdailyChallenges {
    display: flex;
    position: relative;
    top: 35px;

    @media only screen and (max-width: 1024px) {
        top: 0px;
    }

    .common_wrapper {
        width: 100%;
        max-width: 100%;
        background-color: #0E1216;

        .dailyChallenge_wrap {
            background-image: url("../../images/redo/dailychallenge_bg.png");
            background-repeat: no-repeat;
            background-position: bottom center;

            .dailyChallenge_inner {
                padding: 50px 35px;
                background-image: url("../../images/redo/dc_back_green.png");
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 100%;
                @media only screen and (max-width: 767px){
                    padding: 25px;
                }
                .dailChallengeTop {
                    padding-bottom: 35px;

                    .dcTopLeft {
                        .title {
                            font-family: "Gilroy-Medium";
                            font-size: 18px;
                            color: #fff;
                        }

                        .sub_title {
                            color: #92A6BA;
                        }
                    }
                    .dcTopRight{
                        #gamedrop{
                        max-width: 223px;
                        height: 40px;
                        border: 1px solid rgba(59, 77, 94, 0.5);
                        border-radius: 20px;
                        backdrop-filter: blur(32px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        padding: 15px;
                        color: var(--Background---grey-Grey---50---GB, #E3E8ED);
                        font-family: "Gilroy-Medium";
                        font-size: 12px;
                        line-height: 24px;
                        background: transparent !important;

                        @media only screen and (max-width: 400px) {
                            padding: 10px;
                        }
                        @media only screen and (min-width: 1025px) and (max-width:1600px){
                            height: 55px;
                        }

                        &:hover {
                            border-color: #74F33B;
                            background: rgba(116, 243, 59, 0.20);
                        }

                        &::after {
                            border-top: none !important;
                            border-right: none !important;
                            content: "";
                            background-image: url("../../images/redo/whitearrow-down.png");
                            background-repeat: no-repeat;
                            width: 24px;
                            height: 24px;
                        }

                    }
                    }
                }

                                .dc_title {
                                    font-family: "Gilroy-Medium";
                                    font-size: 14px;
                                    color: #fff;
                                    text-align: center;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;
                                    .dc_title_date {
                                        color: #AEF88C;
                                        font-size: 14px;
                                    }
                                    .dc_title_h{
                                        font-size: 24px;
                                        line-height: 20px;
                                        @media only screen and (max-width: 767px){
                                            font-size: 20px;
                                        }
                                    }
                                    .dc_title_day{
                                        font-size: 18px;
                                        line-height: 20px;
                                        @media only screen and (max-width: 767px){
                                            font-size: 16px;
                                        }
                                    }
                                    .dc_title_count{
                                        font-size: 18px;
                                        line-height: 20px;
                                        @media only screen and (max-width: 767px){
                                            font-size: 16px;
                                        }
                                    }
                                }

                .dailyChallenge_details {
                    background-image: url("../../images/redo/dc_backgroundtext.png");
                    background-repeat: no-repeat;
                    background-position: top center;
                    background-size: 75%;
                    // -webkit-backdrop-filter: blur(5px);
                    // backdrop-filter: blur(5px);
                    // opacity: 0.1;
                    .dailyChallengeBox {
                        max-width: 1194px;
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        justify-content: center;
                        flex-wrap: wrap;
                        gap: 20px;
                        padding-bottom: 24px;
                        opacity: 1;
                        background-image: url("../../images/redo/dc_back_green.png");
                        background-repeat: no-repeat;
                        background-position: top center;
                        background-size: 100%;
                        padding: 50px 0;
                        .dailyChallengeBoxItem {
                            border-radius: 32px;
                            max-width: 30%;
                            flex: 0 0 30%;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            //background-color: #0E1216;
                            cursor: pointer;
                            @media only screen and (max-width: 767px){
                                max-width: 40%;
                                flex: 0 0 40%;
                            }
                            @media only screen and (max-width: 575px){
                                max-width: 75%;
                                flex: 0 0 75%;
                            }
                            &:hover{
                                opacity: 0.7;
                            }
                            background: url("../../images/redo/daily_backimg.png");
                            background-repeat: no-repeat;
                            height: 438px;
                            background-size: cover;
                            background-position: bottom;
                            .boxItemImg {
                                height: 219px;
                                width: 100%;

                                img {
                                    border-top-left-radius: 32px;
                                    border-top-right-radius: 32px;
                                    width: 100%;
                                    height: 219px;
                                    object-position: top;
                                    object-fit: cover;
                                }
                            }

                            .boxItemTag {
                                height: 38px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .boxItemDetails {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                background-image: url("../../images/redo/tour_cont_bg.png");
                                background-repeat: no-repeat;
                                background-position: bottom;
                                padding: 32px;
                                //background-color: rgba(0, 0, 0, 0.4);
                                border-bottom-left-radius: 32px;
                                border-bottom-right-radius: 32px;
                                @media only screen and (max-width: 575px){
                                    padding: 25px;
                                }
                                .nameOfHeero {
                                    font-family: "Gilroy-Bold";
                                    color: #fff;
                                    font-size: 24px;
                                    padding: 0 0 16px;
                                    @media only screen and (max-width: 575px){
                                        font-size: 20px;
                                    }
                                }

                                .earnings {
                                    font-family: "Gilroy-SemiBold";
                                    color: #fff;
                                    font-size: 18px;
                                    margin-bottom: 24px;
                                    @media only screen and (max-width: 575px){
                                        margin-bottom: 15px;
                                        font-size: 16px;
                                    }
                                }

                                .challengeName {
                                    font-size: 18px;
                                    color: #fff;
                                    font-family: "Gilroy-SemiBold";
                                    text-align: center;
                                }
                            }

                            &.boxItemEasy {
                                .boxItemTag {
                                    background-color: #74F33B;
                                    color: #020303;
                                    font-family: "Gilroy-SemiBold";
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }

                            &.boxItemMedium {
                                .boxItemTag {
                                    background-color: #B93BF3;
                                    color: #FFFFFF;
                                    font-family: "Gilroy-SemiBold";
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }

                            &.boxItemHard {
                                .boxItemTag {
                                    background-color: #FF3C35;
                                    color: #FFFFFF;
                                    font-family: "Gilroy-SemiBold";
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }

                            &.active{
                                pointer-events: none;
                            }
                            &.completed{
                                pointer-events: none;
                                .boxItemTag{
                                    background-color: #fff;
                                }
                            }
                            &.cancelled {
                                opacity: 0.6;
                                pointer-events: none;
                                .boxItemTag {
                                        background-color: #fff;
                                            color: #020303;
                                    }
                            }
                            &.disputed {
                                opacity: 0.6;
                                pointer-events: none;
                                .boxItemTag {
                                        background-color: #fff;
                                            color: #020303;
                                    }
                            }
                            &.passed {
                                //opacity: 0.7;
                                background-image: url("../../images/redo/daily_pass_backimg.png");
                                .boxItemTag {
                                    color: #2DC656;
                                }
                            }
                            &.failed {
                                //opacity: 0.7;
                                background-image: url("../../images/redo/daily_fail_backimg.png");
                                .boxItemTag {
                                    color: #FF3C35;
                                }
                            }

                        }
                    }
                    .dcRangeSec{
                        max-width: 420px;
                        width: 100%;
                        margin: 16px auto;
                        padding: 16px 0 ;
                        .dc_range{
                            display: flex;
                            align-items: center;
                            outline: none;
                            box-shadow: none; 
                            height: 20px;
                            -webkit-appearance: none;
                            color: #000;
                            &:focus{
                                outline: none;
                                box-shadow: none; 
                            }
                        }
                        .percent-sec{
                            margin-top: 20px;
                            .percent-common{
                                color: #fff;
                                font-size: 18px;
                                line-height: 20px;
                                font-family: "Gilroy-Medium";
                            }
                        }
                        input[type='range'] {
                            -webkit-appearance: none !important;
                            background: #000;
                            height:7px;
                        }
                        input[type='range']::-webkit-slider-runnable-track {
                            width: 15.5em;
                            height: 20px;
                            border-radius: 10px;
                            background: #000;
                          }
                        .dc_range::-webkit-slider-thumb {
                            -webkit-appearance: none !important;
                            appearance: none;
                            width: 48px;
                            height: 39px;
                            background-image: url("../../images/redo/range_slider_head.png");
                            background-repeat: no-repeat;
                            background-position: 100%;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            background-color: transparent;
                            position: relative;
                            top: -10px;
                            box-shadow: none;
                            border: none;
                            outline: none;
                          }
                    }
                    .dayChallenge_slider{
                        max-width: 1194px;
                        width: 100%;
                        margin: 0px auto;
                        padding: 50px 0;
                        @media only screen and (max-width: 767px){
                            margin: 85px auto;
                        }
                        .slick-prev{
                            background-image: url("../../images/redo/dc_leftArrow.png");
                            background-repeat: no-repeat;
                            height: 48px;
                            width: 49px;
                            position: absolute;
                            top: -40px;
                            left: 2px;
                            &::before{
                                display: none;
                            }
                        }
                        .slick-next{
                            background-image: url("../../images/redo/dc_rightArrow.png");
                            background-repeat: no-repeat;
                            height: 48px;
                            width: 49px;
                            position: absolute;
                            top: -40px;
                            right: 2px;
                            &::before{
                                display: none;
                            }
                        }
                        .challenge_day{
                            padding: 10px;
                            @media only screen and (max-width: 575px){
                                padding: 0;
                            }
                            .challengeDayItem{
                                height: 438px;
                                max-width: 550px;;
                                border-radius: 32px;
                                width: 100%;
                                background-image: url("../../images/redo/challengeDay.png");
                                background-repeat: no-repeat;
                                background-position: center;
                                &.daytwo{
                                    background-image: url("../../images/redo/day3.png");
                                }
                                &.daythree{
                                    background-image: url("../../images/redo/day2.png");
                                }
                                &.dayfour{
                                    background-image: url("../../images/redo/day4.png");
                                }
                                &.dayfive{
                                    background-image: url("../../images/redo/day5.png");
                                }
                                &.daysix{
                                    background-image: url("../../images/redo/day6.png");
                                }
                                &.dayseven{
                                    background-image: url("../../images/redo/day7.png");
                                }
                                .challengeTop{
                                    height: 255px;
                                    border-top-left-radiuS: 32px;
                                    border-top-right-radius: 32px;
                                    .dayHead{
                                        background-color: transparent;
                                        backdrop-filter: blur(32px);
                                        border-top-left-radius: 32px;
                                        border-top-right-radius: 32px;
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;
                                        height: 80px;
                                        border-bottom-left-radius: 15px;
                                        border-bottom-right-radius: 15px;
                                        .dayName{
                                            color: #74F33B;
                                            font-size: 24px;
                                            font-family: "Gilroy-Bold";
                                        }
                                        .timeLeft{
                                            color: #fff;
                                            font-family: "Gilroy-Medium";
                                        }
                                    }
                                }
                                .challengeBottom{
                                    background-image: url("../../images/redo/dayChallenge_bg.png");
                                    background-repeat: no-repeat;
                                    background-position: bottom  center;
                                    height: 183px;
                                    border-bottom-left-radius: 32px;
                                    border-bottom-right-radius: 32px;
                                    text-align: center;
                                    .challengeDayName{
                                        color: #fff;
                                        font-family: "Gilroy-SemiBold";
                                        font-size: 20px;
                                        @media only screen and (max-width: 767px){
                                            font-size: 18px; 
                                        }
                                    }
                                    .challengeModeDetails{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        padding: 16px 0;
                                        .challengeModeItem{
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            padding-right: 10px;
                                            margin-right: 10px;
                                            &:first-child{
                                                border-right: 2px solid #3B4D5E;
                                            }
                                            &:nth-child(2){
                                                border-right: 2px solid #3B4D5E;
                                            }
                                            .modename{
                                                font-family: "Gilroy-Bold";
                                                font-size: 16px;
                                                color: #fff;
                                                text-transform:capitalize;
                                                @media only screen and (max-width: 767px){
                                                    font-size: 14px;
                                                }
                                            }
                                            .modeEarning{
                                                color: #74F33B;
                                            }
                                        }
                                    }
                                    .dc_startbtn_wrap{
                                        width: 100%;
                                        max-width: 276px;
                                        margin: auto;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .dc_startbtn {
                                            border-radius: var(--Spacing-2xl, 16px);
                                            border: none;
                                            background-color: #F1FEEC;
                                            color: #0E1216;
                                            max-width: 200px;
                                            width: 100%;
                                            height: 48px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            gap: 5px;
                                            cursor: pointer;
                                            // color: #fff;
                                            font-size: 14px;
                                            line-height: 24px;
                                            font-family: "Gilroy-SemiBold";
                
                                            @media only screen and (max-width: 767px) {
                                                border-radius: 16px;
                                            }
                
                                            &:hover {
                                                opacity: 0.5;
                                            }
                                            img{
                                                margin-left: 5px;
                                            }
                                            
                
                                        }
                                    }
                                }
                               
                            }
                        }
                    }
                }
                .dc_backbtn_wrap {
                    width: 300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0px auto 75px;
                    .dc_backbtn {
                        border-radius: var(--Spacing-2xl, 16px);
                        border: none;
                        background-color: #F1FEEC;
                        color: #0E1216;
                        max-width: 200px;
                        width: 100%;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        // color: #fff;
                        font-size: 14px;
                        line-height: 24px;
                        font-family: "Gilroy-SemiBold";

                        @media only screen and (max-width: 767px) {
                            border-radius: 16px;
                        }

                        &:hover {
                            opacity: 0.5;
                        }
                        img{
                            margin-right: 5px;
                        }
                        

                    }
                }
                
            }
        }

    }
}
.challengePopup{
    .modal-dialog{
       
        @media only screen and (min-width: 1366px) {
                max-width: 600px;
            }
    }
    .modal-content{
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.50);
        opacity: var(--Corner-radius-xs, 1);
        background: var(--Background---grey-Grey---500---GB, #181F26);
        .modal-header{
            border: none !important;
            padding: 25px;
            border-bottom: none;
            display: flex;
            align-items: flex-start;
            @media only screen and (max-width: 767px) {
            padding: 15px 20px 10px 0px;
            }
                .modal-title{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                max-width: 95%;
                flex: 0 0 95%;
                font-size: 24px;
                color: #fff;
                line-height: 24px;
                font-family: "Gilroy-Medium";
                p{
                  font-size: 16px;
                  line-height: 16px;
                  color: #92A6BA;
                  margin-bottom: 0;
                }
              }
            .btn-close {
                color: #fff;
                outline: none;
                background: url("../../images/redo/popup_close.png") no-repeat center center;
                margin-left: 0;
              }
        }
        .modal-body{
            .challengeDetails{
                border: 1.5px solid;
                border-image-source: linear-gradient(94.62deg, rgba(59, 77, 94, 0.5) 0%, rgba(59, 77, 94, 0.2) 99.68%);
                background-color: #141A1F;
                padding: 16px 20px;
                border-radius: 16px;
                .coin_and_amount{
                    flex-wrap: wrap;
                    .coin_and_amount_left{
                        flex: 0 0 60%;
                        max-width: 60%;
                        flex-direction: column;
                        .secTitle{
                            color: #5D7893;
                            font-size: 16px;
                            line-height: 24px;
                            font-family: "Gilroy-SemiBold";

                        }
                        .coin_sec{
                            padding: 12px 0;
                            #coin-drop {
                                background-color: transparent !important;
                                border: none;
                                max-width: 102px;
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font-size: 16px;
                                line-height: 24px;
                                font-family: "Gilroy-SemiBold";
                                box-shadow: none;
                                padding-left: 0;
                                &:hover {
                                    border-color: #74F33B;
                                    background: rgba(116, 243, 59, 0.20);
                                }

                                img {
                                    margin-right: 5px;
                                }
                            }

                            .dropdown-menu {
                                background: rgba(24, 31, 38, 1);

                                .dropdown-toggle {
                                    &::after {
                                        border: none !important;
                                        border-top: 0 !important;
                                        border-right: 0 !important;
                                        content: url("../../images/redo/whitearrow-down.png");
                                    }
                                }

                                .dropdown-item {
                                    color: #fff;
                                    font-size: 16px;
                                    line-height: 24px;
                                    font-family: "Gilroy-SemiBold";
                                    background-color: transparent;
                                    display: flex;
                                    align-items: center;

                                    img {
                                        width: 24px;
                                        height: 24px;
                                        margin-right: 5px;
                                    }

                                    &:hover {
                                        color: #74F33B;
                                    }
                                }
                            }
                        }
                        .amount_sec{
                            gap: 8px;
                            .amount_percentage{
                                background-color: #3B4D5E;
                                color: #fff;
                                font-family: "Gilroy-SemiBold";
                                padding: 12px;
                                border-radius: 12px;
                                cursor: pointer;
                                &:hover{
                                    background-color: rgba(116, 243, 59, 0.5);;
                                }
                                                                &.active {
                                                                    background: rgba(116, 243, 59, 0.5);
                                                                }
                            }
                        }
                    }
                    .coin_and_amount_right{
                        flex: 0 0 40%;
                        max-width: 40%;
                        flex-direction: column;
                        .balanceSec{
                            color: #5D7893;
                            font-size: 16px;
                            line-height: 24px;
                            font-family: "Gilroy-SemiBold";
                            text-align: right;
                        }
                        // .crypto_amount {
                        //     display: flex;
                        //     align-items: center;
                        //     gap: 10px;
                        //     justify-content: flex-end;

                        //     .bet-crypto-input-field {
                        //         border: none;
                        //         opacity: var(--Corner-radius-xs, 1);
                        //         background: transparent;
                        //         width: 180px;
                        //         padding: 10px;
                        //         color: #fff;
                        //         text-align: center;
                        //         box-shadow: none;
                        //         outline: none;
                        //         font-size: 24px;
                        //         line-height: 29px;
                        //         font-family: "Gilroy-SemiBold";
                        //     }
                        // }
                                                 .selected_amount {
                                                     color: #fff;
                                                     font-family: "Gilroy-SemiBold";
                                                     font-size: 38px;
                                                     line-height: 40px;
                                                     display: flex;
                                                     gap: 10px;
                                                     flex-direction: column;
                        
                                                     .crypto_amount {
                                                         display: flex;
                                                         align-items: center;
                                                         gap: 10px;
                                                         justify-content: flex-end;
                        
                                                         .bet-crypto-input-field {
                                                             border-radius: var(--Spacing-xl, 10px);
                                                             border: 1.5px solid rgba(255, 255, 255, 0.1);
                                                             opacity: var(--Corner-radius-xs, 1);
                                                             background: var(--Background---grey-Grey---500---GB, #181F26);
                                                             width: 180px;
                                                             padding: 10px;
                                                             color: #fff;
                                                             text-align: center;
                                                             box-shadow: none;
                                                             outline: none;
                                                             font-size: 35px;
                                                         }
                                                     }
                        
                                                     .usd_amount {
                                                         display: flex;
                                                         align-items: center;
                                                         gap: 10px;
                                                         justify-content: flex-end;
                                                         color: #74f33b;
                                                         font-size: 14px;
                                                         line-height: 18px;
                                                         font-family: "Gilroy-SemiBold";
                                                     }
                                                 }
                    }
                }
            }
        }
        .modal-footer{
            border-top: none;
            flex-direction: column;
            .start_btn{
                max-width: 422px;
                width: 100%;
                height: 56px;
                background-color: #F1FEEC;
                color: #020303;
                border-radius: 24px;
                box-shadow: none;
                border: none;
                font-size: 18px;
                line-height: 24px;
                font-family: "Gilroy-Medium";
                &:hover{
                    opacity: 0.5;
                }
                &.disabled{
                    opacity: 0.5;
                }
            }
            .cancel_btn{
                max-width: 422px;
                width: 100%;
                height: 56px;
                border-radius: 24px;
                color: #fff;
                background-color: #3B4D5E;
                box-shadow: none;
                border: none;
                font-size: 18px;
                line-height: 24px;
                font-family: "Gilroy-Medium";
                &:hover{
                    opacity: 0.5;
                }
            }
        }
        
        
    }
}

.challengeConfirmModal{
    .modal-dialog.modal-dialog-centered {
      max-width: 450px;
      margin: auto;
  }
  &.challengeThanksModel{
    .challengeParaTag{
      color: var(--Background---grey-Grey---200---GB, #92A6BA);
      text-align: center;
      font-family: "Gilroy-Medium";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      max-width: 350px;
  
    }
    .challengeHeadImg{
      display: flex;
      justify-content: center;
      background-image: url("../../images/redo/tour_thanks_green_svg.svg") !important;
      background-position: 54% 23% !important;
      background-repeat: no-repeat;
      margin-bottom: 24px;
      img{
        animation: rotateRight 90s linear infinite;
      }
  
      .desktopImg{
        display: block;
        margin-left: 15px;
      }
      @media only screen and (max-width: 575px) {
        .mobileImg{
          display: block;
        }
        .desktopImg{
          display: none;
        }
      }
    }
   .challengeFooter button {
    padding: 13px 69px;
    border-radius: 20px;
    font-family: 'Inter-Medium';
    font-size: 14px;
    line-height: 24px;
    border:none;
    color: #0E1216;
    background: #fff;
    max-width: 422px;
    width: 100%;
    text-align: center;
    text-decoration: none;
    
    @media only screen and (max-width: 575px) {
      padding: 10px 69px; ;
      font-size: 12px;
      &.mobileBtn{
        display: block;
      }
      &.desktopBtn{
        display: none;
      }
    }
      &:hover{
        opacity: 0.6;
      }
    }
  }
    .modal-content {
      border-radius: var(--Spacing-xl, 20px);
      border: 1px solid rgba(59, 77, 94, 0.5);
      opacity: var(--Corner-radius-xs, 1);
      background: var(--Background---grey-Grey---500---GB, #181F26);
      @media only screen and (max-width: 575px) {
        margin: 16px;;
      }
  }
  .modal-header {
    justify-content: flex-end;
    padding-bottom: 0px;
    border:none;
  }
  .btn-close {
    box-shadow: none;
    outline: none;
    height: 24px;
    width:24px;
    margin:0px;
    padding: 0px;
    background: url("../../images/redo/bet-close-circle.png") no-repeat;
    opacity: 1;
    &:hover{
      opacity: 0.6;
    }  
  }
  .modal-body {
    padding: 0px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  .challengeHead {
    text-align: center;
    
    .challengeHeadImg.tourRule{
      background-image: url("../../images/redo/tour_warning-svg.svg") !important;
      background-repeat: no-repeat;
      background-position: 53% 36%;
      margin-bottom: 24px;
      img{
        animation: rotateRight 90s linear infinite;
      }
    }
  .challengeHeadTitle {
      color: #FFF;
      text-align: center;
      font-family: "Gilroy-Medium";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 16px;
      max-width: 365px;
      width: 100%;
  }
  .challengeHeadTag {
    color:#92A6BA;
    text-align: center;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media only screen and (max-width: 575px) {
      font-size: 14px;
    }
    
    span{
      color:#92A6BA;
      text-align: center;
      font-family: "Gilroy-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
  
    }
  
  }
  }
  .challengeFooter {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    button{
      padding: 13px 69px;
      border-radius: 20px;
      font-family: 'Inter-Medium';
      font-size: 14px;
      line-height: 24px;
      border:none;
      @media only screen and (max-width: 575px) {
        padding: 10px 35px;
        font-size: 12px;
      }
      &:hover{
        opacity: 0.6;
      }
      &.tourConfirmBtn {
        color: #0E1216;
    }
    &.tourCancelBtn {
      background: #3B4D5E;
      color: #fff;
      text-transform: capitalize;
  }
  
    
    }
  }
  }
  }
  ._GB_rbettingsummarytop {
    &.dc_switcher{
        .rbettingsummary_topleft{
            @media only screen and (max-width: 390px){
                flex-direction: column;
            }
            .rbettingsummary_topleftonemain{
                .rbettingsummary_topleftone{
                    .rbettingsummary_topleftonesub{
                        justify-content: center !important;
                        .topleftonesub_img{
                            // background-image: url("../../images/redo/dc_icon_bg.png");
                            // background-repeat: no-repeat;
                            // background-position: center;
                            // animation: rotateRight 90s linear infinite;
                        }
                        p{
                            font-size: 16px;
                            line-height: 24px;
                            font-family: "Gilroy-Medium";
                            color: #E3E8ED;
                            span{
                                font-size: 20px !important;
                                line-height: 24px !important;
                                font-family: "Gilroy-Bold" !important;
                                color: #E3E8ED !important;
                            }
                        }
                    }
                }
            
            }
            .rbettingsummary_toplefttwomain{
                // @media only screen and (max-width: 1024px){
                //     max-width: 50;
                // }
                .rbettingsummary_topleftfour{
                    display: flex;
                    gap: 10px;
                    .cancel_btn{
                        width: 175px;
                        height: 48px;
                        color: #fff;
                        background-color: #FF3C35 !important;
                        border-radius: 16px;
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        line-height: 24px;
                        text-transform: capitalize;
                        padding: 8px;
                        text-decoration: none;
                        text-align: center;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                
                        &:hover {
                            opacity: 0.6;
                        }
                
                        @media only screen and (min-width: 992px) and (max-width: 1919px) {
                            font-size: 11px !important;
                            padding: 11px 9px !important;
                        }
                    }
                    .refresh_btn{
                        width: 175px;
                        height: 48px;
                        color: #0E1216;
                        background-color: #74F33B;
                        border-radius: 16px;
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        line-height: 24px;
                        text-transform: capitalize;
                        padding: 8px;
                        text-decoration: none;
                        text-align: center;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                
                        &:hover {
                            opacity: 0.6;
                        }
                
                        @media only screen and (min-width: 992px) and (max-width: 1919px) {
                            font-size: 11px !important;
                            padding: 11px 9px !important;
                        }
                        img{
                            margin-right: 5px;
                        }
                    }
                    .start_btn{
                        width: 175px;
                        height: 48px;
                        color: #0E1216;
                        background-color: #F1FEEC !important;
                        border-radius: 16px;
                        font-family: "Gilroy-Medium";
                        font-size: 14px;
                        line-height: 24px;
                        text-transform: capitalize;
                        padding: 8px;
                        text-decoration: none;
                        text-align: center;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                
                        &:hover {
                            opacity: 0.6;
                        }
                                &.disabled {
                                    opacity: 0.5;
                                }
                        @media only screen and (min-width: 992px) and (max-width: 1919px) {
                            font-size: 11px !important;
                            padding: 11px 9px !important;
                        }
                    }
                }
                
            }
            &.rtopleft_success{
                padding: 9px 16px !important;
                @media only screen and (max-width: 767px){
                    flex-wrap: wrap;
                }
                .rbettingsummary_topleftonemain{
                    .rbettingsummary_topleftone{
                        .rbettingsummary_topleftonesub{
                            p{
                                font-size: 16px !important;
                                line-height: 19px !important;
                                font-family: "Gilroy-Medium" !important;
                                span{
                                    font-size: 16px !important;
                                    line-height: 19px !important;
                                    font-family: "Gilroy-Medium" !important;
                                    color: #5D7893 !important;
                                }
                            }
                        }
                    }
                }
                .rbettingsummary_toplefttwomain{
                    justify-content: flex-end;
                    @media only screen and (max-width: 767px){
                        justify-content: center !important;
                    }
                    @media only screen and (max-width: 425px){
                        max-width: 85%;
                        flex: 0 0 85%;
                        margin: auto;
                    }
                    .rbettingsummary_topleftfour{
                        @media only screen and (max-width: 767px){
                            margin-top: 10px;
                            justify-content: center;
                        }
                        @media only screen and (max-width: 425px){
                            flex-direction: column;
                        }
                    }
                }
            }
        } 
      
    }
    
}

.delete-challenge{
    .modal-content {
      background: var(--Background---grey-Grey---500---GB, #181F26);
      border: 1px solid rgba(59, 77, 94, 0.5);
      max-width: 470px;
      width: 100%;
      .modal-header{
          display: flex;
          justify-content: center;
          align-items: flex-start;
        .modal-title{
          max-width: 95%;
          flex: 0 0 95%; 
          background-image: url("../../images/redo/tour_warning-icon.png") !important;
          background-repeat: no-repeat;
          background-position: 50% 38%;
          margin-bottom: 24px;
          img{
              animation: rotateRight 90s linear infinite;
        
          }
        }
        .btn-close {
          color: #fff;
          outline: none;
          background: url("../../images/redo/popup_close.png") no-repeat center center;
          margin-left: 0;
          padding-right: 10px;
        }
      }
      
      .modal-body {
        .title {
          text-align: center;
          color: #fff;
          font-family: "Gilroy-Medium";
          font-size: 24px;
          line-height: 24px;
        }

        .sub-title {
          text-align: center;

          h3 {
            color: var(--Background---grey-Grey---200---GB, #92A6BA);
            font-family: "Gilroy-Regular";
            font-size: 24px;
            line-height: 26px; 
            @media only screen and (max-width: 767px){
                font-size: 16px;
                line-height: 16px;
            }
          }

          
        }
      }

      .modal-footer {
        justify-content: space-between;
        display: flex;
        button{
          max-width: 200px;
          width: 100%;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          text-transform: none;
          margin-right: 0px !important;
          border: none !important;
          &:hover{
            opacity: 0.5;
          }
        }
        .exitBtn{
          border-radius: var(--Spacing-2xl, 24px);
          background: var(--Color-Error-500, #F1FEEC);
          box-shadow: 0px 2px 4px 0px rgba(78, 255, 97, 0.02), 0px 16px 56px -4px rgba(78, 255, 97, 0.08);
          color: #0E1216;
        }
        .noBtn{
          border-radius: var(--Spacing-2xl, 24px);
          background: var(--Background---grey-Grey---400---GB, #3B4D5E);
        }
      }
    }
  }


  @keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.no-dc {
    padding: 30px;
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.5);
    opacity: var(--Corner-radius-xs, 1);
    background: rgba(14, 18, 22, 0.4);
    backdrop-filter: blur(32px);
    -webkit-backdrop-filter: blur(32px);
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-family: "Gilroy-Medium";
}

.dailyLimit {
    padding: 15px;
    border-radius: var(--Spacing-xl, 20px);
    border: 1px solid rgba(59, 77, 94, 0.5);
    opacity: var(--Corner-radius-xs, 1);
    background: rgba(14, 18, 22, 0.4);
    backdrop-filter: blur(32px);
    -webkit-backdrop-filter: blur(32px);
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-family: "Gilroy-Medium";
    @media only screen and (min-width: 1366px){
        max-width: 1194px;
    }
   
    width: 100%;
    margin: 0 auto;
    margin-bottom: 50px;
}
.dc_usdt_reward {
    padding: 15px;
    font-size: 16px;
    color: #fff;
    line-height: 24px;
    font-family: "Gilroy-Medium";
    text-align: center;
    .dc_text{
        color: #74f33b;
    }
    .usdt_text {
        color: #ffc01f;
    }
}