.rbettingdash_wrap {
    padding: 32px;
    border-top: 1px solid #3B4D5E;
    border-left: 1px solid #3B4D5E;
    border-top-left-radius: 40px;
    background: #000;

    h1 {
        color: #FFF;
        font-family: "Gilroy-Medium";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 0px;
    }

    .filterlist {
        margin-top: 32px;
        display: flex;
        flex-wrap: wrap;
        gap: 1%;

        button {
            border-radius: 16px;
            border: 1px solid #3B4D5E;
            background: transparent;
            padding: 20px 16px;
            width: 100%;
            height: 56px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            &:hover {
                border-color: #74F33B;
            }

            &:active {
                background-color: transparent;
            }

            &::after {
                content: url("../../images/redo/arrow-down.png");
                border: none;
            }
        }

        .datelistfirst,
        .gameslist,
        .datelistsecond {
            flex: 24.3%;
            max-width: 24.3%;

            @media only screen and (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 10px;
            }
        }

        .dropdown-menu.show {
            width: 100%;
            background: rgba(4, 5, 6, 0.6);
        }

        .dropdown-menu .dropdown-item:hover {
            color: #74f33b;
        }
    }

    .pricelist {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 1%;

        .singlelist {
            border-radius: 40px;
            border: 2px solid #3B4D5E;
            opacity: 1;
            background: linear-gradient(123deg, #181F26 0%, rgba(24, 31, 38, 0.00) 100%);
            padding: 32px;
            width: 100%;
            flex: 0 0 24.25%;
            max-width: 24.25%;

            @media only screen and (max-width: 767px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 10px;
                padding: 15px;
            }

            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                flex: 0 0 45%;
                max-width: 45%;
                margin-bottom: 10px;
                padding: 15px;
            }


            h3 {
                color: #5D7893;
                font-family: "Gilroy-Medium";
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 16px;
                @media only screen and (max-width: 767px){
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            h4 {
                color: #FFF;
                font-family: "Gilroy-Bold";
                font-size: 36px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

                @media only screen and (min-width: 1025px) and (max-width: 1300px) {
                    font-size: 25px;
                }
            }

            img {
                max-height: 150px;
                max-width: 150px;
                margin-bottom: 32px;

                @media only screen and (min-width: 1025px) and (max-width: 1300px) {
                    max-height: 90px;
                    max-width: 90px;
                }
            }

        }
    }

    .bettinglist {
        margin-top: 60px;

        h2 {
            color: #FFF;
            font-family: "Gilroy-Medium";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 0px;
            @media only screen and (max-width: 767px){
                font-size: 30px;
                line-height: 32px;
            }

        }

        ;

        .titlesection {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            button#gamesfilterdrop {
                color: #4EFF61;
                font-family: 'Inter-Medium';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                border-radius: var(--Spacing-lg, 16px);
                border: 1px solid rgba(78, 255, 97, 0.40);
                background: rgba(78, 255, 97, 0.25);
                padding: 11px 30px;
            }

            .dropdown-menu .dropdown-item:hover {
                color: #4EFF61
            }

            .dropdown-menu.show {
                min-width: 138px;
            }
        }

        .tablesection {
            margin-top: 36px;
            ;

            table {
                thead {
                    background: #141A1F;

                    th {
                        color: var(--Primary-Green---500---GB, #74F33B);
                        font-family: 'Inter-Medium';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        height: 48px;
                        padding: 16px 8px;
                        border-bottom: none;
                        width: 174px;

                        &:first-child {
                            border-top-left-radius: 20px !important;
                            width: 32px
                        }

                        &:last-child {
                            border-top-right-radius: 20px !important;
                        }

                        &:nth-child(2) {
                            text-align: center;
                        }
                    }
                }

                tbody {
                    background: #000;

                    th {
                        border-bottom: var(--Corner-radius-xs, 1px) solid var(--Background---grey-Grey---400---GB, #3B4D5E);
                    }

                    td {
                        color: #DBFFDF;
                        font-family: 'Inter-Regular';
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        border-bottom: var(--Corner-radius-xs, 1px) solid var(--Background---grey-Grey---400---GB, #3B4D5E);
                        padding: 12px 6px;

                        &:first-child {
                            width: 32px
                        }

                        &:nth-child(2) {
                            text-align: center;
                        }


                    }

                    img.img-fluid.dota2 {
                        max-width: 35px;
                    }

                    img.img-fluid.pubg {
                        max-width: 47px;
                    }

                    img.img-fluid.avataritem {
                        max-width: 32px;
                    }

                    .profilesection {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        p {
                            color: var(--Neutral-Gray-400, #9296A1);
                            font-family: 'Inter-Regular';
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            margin-bottom: 0px;
                        }

                    }




                }

                .form-check-input {
                    background-color: #3B4D5E;
                    height: 16px;
                    width: 16px;

                    &:checked {
                        background-color: #74F33B;
                        border-color: #74F33B;
                    }

                    &:focus {
                        outline: none;
                        border: none;
                        box-shadow: none;
                    }
                }

                .form-check-input:checked[type=checkbox] {
                    background-image: url("../../images/redo/tick-black.png");
                    background-size: auto;
                }

            }
        }

    }

}

._GB_redoBetting {
    display: flex;
    position: relative;
    top: 35px;
    @media only screen and (max-width: 1024px){
        top: 0px;
    }
    .common_wrapper {
        width: 100%;
        max-width: 100%;
        background-image: url("../../images/redo/betting_bg.png");
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 33% 100%;
        background-color: var(--Background---grey-Grey---700---GB, #0E1216);
        @media only screen and (max-width: 767px){
            background-image: none;
          }
        .betting_wrap {
            border: 1px solid rgba(59, 77, 94, 0.5);
            border-top-left-radius: 18px;
            border-bottom: none;

            .rBetting_Inner {
                max-width: 100%;
                width: 100%;

                @media only screen and (min-width: 1366px) {
                    max-width: 1276px;
                    width: 100%;
                    margin: auto;
                }
            }
        }
    }
}

._GB_betCreation {
    margin-top: 40px;
    padding-bottom: 60px;
    @media only screen and (max-width: 767px) {
        padding:16px 20px;
        margin-top: 0px;
        }

    .betagainst_left {
        max-width: 207px;
        width: 100%;
        @media only screen and (max-width: 767px) {
            display: none;
           }
           @media only screen and (min-width: 768px) and  (max-width: 1100px){
            max-width: 112px;
           }
    }

    .betagainst_right {
        .betagainst_title {
            font-size: 24px;
            line-height: 26px;
            color: #fff;
            margin-bottom: 15px;
            font-family: "Gilroy-Medium";
            @media only screen and (max-width: 1024px) {
                font-size: 20px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 4px;
            }
        }

        .betagainst_SubTitle {
            color: #92A6BA;
            font-size: 16px;
            line-height: 24px;
            font-family: "Gilroy-Regular";
            @media only screen and (max-width: 767px) {
                font-size: 14px;
               }
        }
    }

    .betagainst_step {
        width: 100%;
        @media only screen and (min-width: 768px) and  (max-width: 1300px){
            padding-right: 10px;
           }
        .stepNum {
            @media only screen and (max-width: 767px) {
              display: none;
             }
        }
        .selection_tab {
            @media only screen and (max-width: 767px) {
              display: none;
             }
          }

        .step_one {
            margin-top: 40px;
            @media only screen and (max-width: 767px) {
                margin-top: 24px;
               }

            .stepNum {
                margin-left: 41px;
                margin-right: 15px;
                padding-right: 19px;
                @media only screen and (min-width: 768px) and  (max-width: 1100px){
                    margin-left: 10px;
                    margin-right: 20px;
                    padding-right: 0px;
                }
            }

            .selection_tab {
                img {
                    margin-top: 41px;
                }
            }

            .betTypeWrap {
                padding: 0 20px;

                .betTypes {
                    gap: 80px;

                    .betOpt {
                        max-width: 83px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        cursor: pointer;

                        input {
                            width: 24px;
                            height: 24px;
                            margin-right: 10px;
                        }

                        .privlabel {
                            color: #E3E8ED;
                            font-size: 14px;
                            line-height: 24px;
                            font-family: 'Inter-Regular';

                        }

                    }
                }
            }

            .step_details {
                border: 1px solid rgba(59, 77, 94, 0.5);
                border-radius: 24px;
                height: 72px;
                //max-width: 731px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 24px 32px;
                color: #fff;
                font-size: 20px;
                line-height: 30px;
                font-family: "Gilroy-Medium";
                @media only screen and (max-width: 767px) {
                    height: 64px;
                    font-size: 16px;
                    line-height: 24px;
                }
  

                &.pointerEV {
                    cursor: pointer;
                }

                // .list_icon{
                //     cursor: pointer;
                // }

            }

        }

        .step_right {
            //max-width: 731px;
            width: 100%;
            margin-left: 46px;
            position: relative;
            @media only screen and (max-width: 767px) {
                margin-left: 0px;
               }
               @media only screen and (min-width: 768px) and  (max-width: 1100px){
                margin-left: 10px;
               }

            &::before {
                border-left: 3px dashed #3B4D5E;
                content: "";
                height: 75%;
                position: absolute;
                left: -59px;
                top: 65px;
                @media only screen and (max-width: 767px) {
                    display: none;
                   }
                   @media only screen and (min-width: 768px) and  (max-width: 1100px){
                    left: -21px;
                   }
            }

            &.activeStep {
                margin-left: 50px;
                @media only screen and (max-width: 767px) {
                    margin-left: 0px;
                   }
                   @media only screen and (min-width: 768px) and  (max-width: 1100px){
                    margin-left: 10px;
                   }
  
                &::before {
                    border-left: 3px dashed #3B4D5E;
                    content: "";
                    height: 78%;
                    position: absolute;
                    left: -61px;
                    top: 75px;
                    @media only screen and (max-width: 767px) {
                        display: none;
                       }
                       @media only screen and (min-width: 768px) and  (max-width: 1100px){
                        left: -23px;
                       }
                }
            }

            .stepOne_details {
                margin: 24px auto;

                .searchSec {
                    max-width: 715px;
                    width: 100%;
                    position: relative;

                    .searchGame {
                        border-radius: 24px;
                        height: 64px;
                        background-color: #181F26;
                        max-width: 715px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        color: #fff !important;
                        font-size: 20px;
                        line-height: 24px;
                        padding: 0px 20px;
                        font-family: "Gilroy-Medium";
                        border: none;
                        margin: auto;
                        cursor: pointer;
                        box-shadow: none;

                        &:active {
                            border: none;
                            box-shadow: none;
                        }

                        &:focus {
                            border: none;
                            box-shadow: none;
                            outline: none;
                        }
                    }

                    img {
                        position: absolute;
                        right: 20px;
                        cursor: pointer;
                    }
                }

                .seclabel {
                    color: #fff;
                    font-size: 18px;
                    line-height: 24px;
                    font-family: "Gilroy-Medium";
                    margin-top: 24px;
                    margin-bottom: 24px;
                }
            }
        }

        .step_two {
            .stepNum {
                margin-left: 35px;
                margin-right: 20px;
                @media only screen and (min-width: 768px) and  (max-width: 1100px){
                    margin-left: 10px;
                    margin-right: 0px;
                }
            }

            .selection_tab {
                img {
                    margin-top: 41px;
                }
            }

            .step_secondRight {
                //max-width: 731px;
                width: 100%;
                margin-left: 50px;
                margin-top: 13px;
                position: relative;
                @media only screen and (max-width: 767px) {
                    margin-left: 0px;
                    margin-top: 16px;
                   }
                   @media only screen and (min-width: 768px) and  (max-width: 1100px){
                    margin-left: 10px;
                   }
  

                &::before {
                    border-left: 3px dashed #3B4D5E;
                    content: "";
                    height: 70%;
                    position: absolute;
                    left: -61px;
                    top: 60px;
                    @media only screen and (max-width: 767px) {
                        display: none;
                       }
                       @media only screen and (min-width: 768px) and  (max-width: 1100px){
                        left: -23px;
                       }
                }

                &.activeStep {
                    &::before {
                        border-left: 3px dashed #3B4D5E;
                        content: "";
                        height: 95%;
                        position: absolute;
                        left: -61px;
                        top: 60px;
                        @media only screen and (max-width: 767px) {
                            display: none;
                           }
                           @media only screen and (min-width: 768px) and  (max-width: 1100px){
                            left: -23px;
                           }
                    }
                }

                .step_details {
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    border-radius: 24px;
                    height: 72px;
                    //max-width: 731px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 24px 32px;
                    color: #fff;
                    font-size: 20px;
                    line-height: 30px;
                    font-family: "Gilroy-Medium";
                    @media only screen and (max-width: 767px) {
                        height: 64px;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &.pointerEV {
                        cursor: pointer;
                    }

                    // .list_icon{
                    //     cursor: pointer;
                    // }
                }

                .stepTwo_details {
                    margin-bottom: 20px;

                    .challengeList {
                        padding: 0px 25px;
                        border: 1px solid rgba(59, 77, 94, 0.5);
                        margin-top: 24px;
                        margin-bottom: 24px;
                        border-radius: 20px;
                        gap: 35px;
                        justify-content: flex-start !important;
                        @media only screen and (max-width: 767px) {
                            padding: 10px 25px;
                            gap: 10px;
                            flex-direction: column;
                            flex-wrap: wrap;
                            align-items: flex-start !important;
                            .tab_border {
                                display: none;
                            }
                           }
                           .tab_border{
                            img{
                                min-height: 17px;
                            }
                         }
                        .challengeType {
                            color: #5D7893;
                            font-size: 14px;
                            line-height: 18px;
                            height: 66px;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            font-family: "Gilroy-Medium";
                            @media only screen and (max-width: 767px) {
                                height: auto;
                               }
                            @media only screen and (min-width: 768px)and (max-width: 1300px){
                               text-align: center;
                               }

                            &.selectType {
                                color: #fff;
                                text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.40);
                                position: relative;

                                &::before {
                                    content: url("../../images/redo/tab_sel-icon.png");
                                    bottom: -16px;
                                    left: 40%;
                                    position: absolute;
                                    width: 32px;
                                    height: 32px;
                                    @media only screen and (max-width: 767px) {
                                        display: none;
                                       }
                                }
                            }
                        }
                    }

                    .challengeDetails {
                        // background: var(--Background---grey-Grey---800---GB, #0A0D10);
                        border-radius: 24px;
                        // padding: 16px;

                        .challengeDetailsInner {
                            padding: 10px 0;
                            height: 460px;
                            overflow-y: scroll;

                            &::-webkit-scrollbar {
                                width: 8px;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #181F26;
                                border-radius: 9px;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #74F33B;
                                border-radius: 9px;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #74F33B;
                            }

                            &.hideScroll {
                                overflow: hidden;
                            }

                            .betting-top-container {
                                z-index: 0;
                            }

                            .challenge_Item {
                                // border: 2px solid rgba(59, 77, 94, 0.5);
                                height: 64px;
                                // border-radius: 24px;
                                // max-width: 720px;
                                margin: auto;
                                padding: 10px 20px;
                                margin-bottom: 12px;
                                margin-right: 16px;
                                border-radius: var(--Spacing-xl, 20px);
                                border: 1.5px solid rgba(255, 255, 255, 0.10);
                                opacity: var(--Corner-radius-xs, 1);
                                background: var(--Background---grey-Grey---500---GB, #181F26);
                                @media only screen and (max-width: 767px) {
                                    height: auto;
                                    flex-direction: column;
                                    
                                   }

                                .unselect_opt {
                                    // margin-right: 21px;
                                    cursor: pointer;
                                    flex: 0 0 25px;
                                    max-width: 25px;
                                    img{
                                        border-radius: 50%;
                                    }
                                }

                                .opt_name {
                                    color: #fff;
                                    font-size: 12px;
                                    line-height: 24px;
                                    font-family: "Gilroy-Medium";
                                    text-transform: uppercase;

                                    img {
                                        margin-left: 12px;
                                        cursor: pointer;
                                    }
                                }

                                .opt_plus {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;

                                    img {
                                        &:first-child {
                                            cursor: pointer;
                                        }
                                    }

                                    .bet-new-text {
                                        border-radius: var(--Spacing-md, 8px);
                                        border: 1.5px solid #FFF;
                                        background: var(--Primary-Green---50---GB, #F1FEEC);
                                        color: var(--Background---grey-Grey---950---GB, #020303);
                                        font-family: Gilroy-Medium;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 24px;
                                        padding: 5px 8px;
                                        margin-left: 10px;
                                        text-transform: none;
                                    }
                                }


                            }
                        }

                    }


                }
            }

        }

        .step_three {
            .stepNum {
                margin-left: 35px;
                margin-right: 20px;
                @media only screen and (min-width: 768px) and  (max-width: 1100px){
                    margin-left: 10px;
                    margin-right: 0px;
                }
            }

            .selection_tab {
                img {
                    margin-top: 41px;
                }
            }

            .step_threeRight {
                //max-width: 731px;
                width: 100%;
                margin-left: 50px;
                margin-top: 20px;
                position: relative;
                @media only screen and (max-width: 767px) {
                    margin-left: 0px;
                    margin-top: 16px;
                   }
                   @media only screen and (min-width: 768px) and  (max-width: 1100px){
                    margin-left: 10px;
                   }
  

                // &::before{
                //     border-left: 3px dashed #3B4D5E;
                //     content: "";
                //     height: 600px;
                //     position: absolute;
                //     left: -64px;
                //     top: 75px;
                // }
                .step_details {
                    border: 1px solid rgba(59, 77, 94, 0.5);
                    border-radius: 24px;
                    height: 72px;
                    //max-width: 731px;
                    width: 100%;
                    align-items: center;
                    padding: 24px 32px;
                    color: #fff;
                    font-size: 20px;
                    line-height: 30px;
                    font-family: "Gilroy-Medium";
                    @media only screen and (max-width: 767px) {
                        height: 64px;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &.pointerEV {
                        cursor: pointer;
                    }

                    // .list_icon{
                    //     cursor: pointer;
                    // }

                }

                .stepthree_details {
                    margin: 24px 0;

                    .coin_and_amount {
                        border-radius: var(--Spacing-lg, 16px);
                        border: 1.5px solid rgba(59, 77, 94, 0.50);
                        opacity: var(--Corner-radius-xs, 1);
                        background: rgba(24, 31, 38, 0.70);

                        /* Blur/xs - b:64 */
                        backdrop-filter: blur(32px);
                        padding: 16px 20px;
                        height: 196px;
                        @media only screen and (max-width: 767px) {
                            height: auto;
                            flex-direction: column;
                            gap: 10px;
                           }
                        

                        .coin_and_amount_left {
                            max-width: 48%;
                            flex: 0 0 48%;
                            flex-direction: column;
                            @media only screen and (max-width: 767px) {
                                flex-direction: column;
                                gap: 10px;
                                max-width: 100%;
                                flex: 0 0 100%;
                               }
                            

                            .secTitle {
                                color: #5D7893;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: "Gilroy-SemiBold";
                            }

                            .coin_sec {
                                #coin-drop {
                                    background-color: transparent !important;
                                    border: none;
                                    max-width: 117px;
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    font-size: 22px;
                                    line-height: 28px;
                                    font-family: "Gilroy-SemiBold";
                                    box-shadow: none;
                                    &:hover {
                                        border-color: #74F33B;
                                        background: rgba(116, 243, 59, 0.20);
                                    }

                                    img {
                                        margin-right: 5px;
                                    }
                                }

                                .dropdown-menu {
                                    background: rgba(24, 31, 38, 1);

                                    .dropdown-toggle {
                                        &::after {
                                            border: none !important;
                                            border-top: 0 !important;
                                            border-right: 0 !important;
                                            content: url("../../images/redo/whitearrow-down.png");
                                        }
                                    }

                                    .dropdown-item {
                                        color: #fff;
                                        font-size: 16px;
                                        line-height: 24px;
                                        font-family: "Gilroy-SemiBold";
                                        background-color: transparent;
                                        display: flex;
                                        align-items: center;

                                        img {
                                            width: 24px;
                                            height: 24px;
                                            margin-right: 5px;
                                        }

                                        &:hover {
                                            color: #74F33B;
                                        }
                                    }
                                }

                            }

                            .amount_sec {
                                gap: 8px;

                                .amount_percentage {
                                    cursor: pointer;
                                    background-color: #3B4D5E;
                                    border-radius: 12px;
                                    height: 36px;
                                    color: #fff;
                                    max-width: 58px;
                                    width: 100%;
                                    font-family: "Gilroy-SemiBold";

                                    &.active {
                                        background: rgba(116, 243, 59, 0.5);
                                    }
                                }
                            }
                        }

                        .coin_and_amount_right {
                            max-width: 48%;
                            flex: 0 0 48%;
                            text-align: right;
                            flex-direction: column;
                            @media only screen and (max-width: 767px) {
                            flex-direction: column;
                            align-items: flex-start;
                            gap: 10px;
                            max-width: 100%;
                            flex: 0 0 100%;
                            }

                            .balanceSec {
                                color: #5D7893;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: "Gilroy-SemiBold";

                            }

                            .selected_amount {
                                color: #fff;
                                font-family: "Gilroy-SemiBold";
                                font-size: 38px;
                                line-height: 40px;
                                display: flex;
                                gap: 10px;
                                flex-direction: column;

                                .crypto_amount {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    justify-content: flex-end;

                                    .bet-crypto-input-field {
                                        border-radius: var(--Spacing-xl, 10px);
                                        border: 1.5px solid rgba(255, 255, 255, 0.1);
                                        opacity: var(--Corner-radius-xs, 1);
                                        background: var(--Background---grey-Grey---500---GB, #181F26);
                                        width: 180px;
                                        padding: 10px;
                                        color: #fff;
                                        text-align: center;
                                        box-shadow: none;
                                        outline: none;
                                        font-size: 35px;
                                    }
                                }

                                .usd_amount {
                                    display: flex;
                                    align-items: center;
                                    gap: 10px;
                                    justify-content: flex-end;
                                    color: #74f33b;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: "Gilroy-SemiBold";
                                }
                            }

                        }
                    }
                }
            }
        }

        .betBtn_sec {
            width: 100%;
            margin-bottom: 40px;
            margin-top: 24px;

            .betBtn_left {
                max-width: 190px;
                width: 100%;
                @media only screen and (max-width: 767px) {
                    display: none;
                   }
                   @media only screen and (min-width: 768px) and  (max-width: 1100px){
                    max-width: 112px;
                   }
                
            } 

            .betBtn_right {
                //max-width: 731px;
                width: 100%;
                margin-left: 0px;
                gap: 8px;

                .betCommonBtn {
                    border-radius: var(--Spacing-2xl, 24px);
                    background: var(--Background---grey-Grey---400---GB, #3B4D5E);
                    // max-width: 360px;
                    width: 100%;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: #fff;
                    font-size: 14px;
                    line-height: 24px;
                    font-family: "Gilroy-SemiBold";
                    @media only screen and (max-width: 767px) {
                        border-radius: 16px;
                       }

                    &:hover {
                        opacity: 0.5;
                    }

                    &.selectedBtn {
                        background-color: #F1FEEC;
                        color: #0E1216;

                        &::after {
                            content: "";
                            background-image: url("../../images/redo/arrow-right.png") !important;
                            background-repeat: no-repeat;
                            height: 26px;
                            width: 32px;
                        }
                    }

                    &.continueBtn {
                        background-color: #F1FEEC;
                        color: #0E1216;
                        

                    }

                    &.hasArrow {

                        &::after {
                            content: "";
                            background-image: url("../../images/redo/arrow-right.png");
                            background-repeat: no-repeat;
                            height: 26px;
                            width: 32px;
                            margin-left: 3px;
                        }

                        &:hover {
                            &::after {
                                content: "";
                                background-image: url("../../images/redo/arrow-right.png");
                                background-repeat: no-repeat;
                                height: 26px;
                                width: 32px;
                            }
                        }
                    }

                    &.btnAjaxLoading {
                        padding:10px;
                        .text-light {
                            color: #181f26 !important;
                            margin-right: 5px !important;
                        }
                    }
                }

            }
        }

    }

    .rdBetgames {
        display: flex;
        gap: 15px;
        padding: 10px 20px;
        flex-wrap: wrap;

        .rdBetgame {
            //border: 1px solid rgba(59, 77, 94, 0.5);
            //border-radius: 8px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media only screen and (max-width: 767px) {
                flex: 0 0 20%;
                max-width: 20%;
               }

            .whiteImg{
                display: block;
              }
              .blackImg{
                display: none;
              }
              
              &:hover,
              &.active {
                //background: rgba(116, 243, 59, 0.5);
                border-radius: var(--Spacing-lg, 8px);
                opacity: var(--Corner-radius-xs, 1);
                background: var(--Primary-Green---600---GB, #51E20E);
                box-shadow: 0px 2px 4px 0px rgba(116, 243, 59, 0.04), 0px 24px 48px -8px rgba(116, 243, 59, 0.12);
    
                .whiteImg{
                  display: none;
                }
                .blackImg{
                  display: block;
                }
                
    
              }
        }
    }

    .challengeAjaxLoader {
        font-size: 16px;
        line-height: 24px;
        color: #5D7893;
        font-family: "Gilroy-SemiBold";
        width: 100%;
        display: flex;
        gap: 10px;
        height: 100%;
        justify-content: center;
        align-items: center;
        border-radius: var(--Spacing-xl, 20px);
        border: 1.5px solid rgba(255, 255, 255, 0.10);
        opacity: var(--Corner-radius-xs, 1);
        background: var(--Background---grey-Grey---500---GB, #181F26);
        height: 300px;
    }

    .bet-no-game {
        color: #e52420;
        font-size: 17px;
        line-height: 26px;
        font-family: "Gilroy-Regular";
    }

    .challenge_Item_Grid_Wrap {
        gap: 20px;

        .challenge_Item_Sel {
            flex: 0 0 72%;
            max-width: 72%;
            display: flex;
            align-items: center;
            gap: 10px;
            @media only screen and (max-width: 767px) {
                justify-content: flex-start;
                flex: 0 0 100%;
                max-width: 100%;
                width: 100%;
                flex-wrap: wrap;
               }
            @media only screen and (min-width: 768px) and  (max-width: 1100px){
                flex: 0 0 69%;
            max-width: 69%;
               }
        }

        .challenge_Item_Det {
            color: #fff;
            flex: 0 0 28%;
            max-width: 28%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
            text-align: center;
            @media only screen and (max-width: 767px) {
                justify-content: flex-start;
                flex: 0 0 100%;
                max-width: 100%;
                width:100%
               }

            .challenge_Item_Grid {
                flex: 0 0 40%;
                max-width: 40%;
                color: var(--Color-Warning-500, #FFBF00);
                font-family: Gilroy-Medium;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: center;
                @media only screen and (max-width: 767px) {
                    justify-content: flex-start;
                    flex: 0 0 auto;
                    max-width: unset;
                   }
            }
        }

        &.challenge_Item_Head {
            @media only screen and (max-width: 767px){
                display: none !important;
            }
            .challenge_Item_Sel {
                .opt_name {
                    color: var(--Background---grey-Grey---200---GB, #92A6BA) !important;
                    font-family: Gilroy-Medium !important;
                    font-size: 12px !important;
                    line-height: 18px !important;
                    text-transform: none !important;
                }
            }

            .challenge_Item_Grid {
                color: var(--Background---grey-Grey---200---GB, #92A6BA);
            }
        }
    }

    .betCmnWidth {
        max-width: 1080px !important;

        .betCmnWidthStep {
            max-width: 1024px !important;
        }
    }

    &._GB_betOnStreamer {
        .challenge_Item_Head {
            display: none !important;
        }

        .challenge_Item_Sel {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .challenge_Item_Det {
            display: none !important;
        }

        .challengeDetailsInner {
            height: 380px !important;
        }
    }
}

.betStepModal {
    backdrop-filter: brightness(40%);
    // .modal-dialog {
    //     @media only screen and (max-width: 767px) {
    //     margin: 16px;
    //     }
    // }

    .modal-content {
        border-radius: var(--Spacing-xl, 20px);
        border: 1px solid rgba(59, 77, 94, 0.50);
        opacity: var(--Corner-radius-xs, 1);
        background: var(--Background---grey-Grey---500---GB, #181F26);
        padding-top: 10px;


        .modal-header {
            border: none !important;
            @media only screen and (max-width: 767px) {
                padding-bottom: 0px;
            }

            .btn-close {
                box-shadow: none;
                outline: none;
                background: url("../../images/redo/bet-close-circle.png") no-repeat;
                opacity: 1;
            }
        }

        .modal-body {
            padding-top: 0;
        }

        .betPopHeadImg {
            display: flex;
            justify-content: center;
            padding: 20px;
            background-image: url("../../images/redo/bet-done-icon-svg.svg") !important;
            background-position: center !important;
            background-repeat: no-repeat;
            margin-bottom: 24px;
            @media only screen and (max-width: 575px) {
                background-image: url("../../images/redo/tour_thanks_green_svg.svg") !important;
                background-position: 54% 23% !important;
              }
              &.rule-bg{
                background-image: url("../../images/redo/rules-head-icon-svg.svg") !important;
                background-position: 50% 42% !important;

              }
            .desktopImg{
                display: block;
              }
              .mobileImg{
                display: none;
              }
              img{
                animation: rotateRight 90s linear infinite;
              }
          
            @media only screen and (max-width: 767px) {
                padding-bottom: 0px;
                padding-top: 0px;
                .mobileImg{
                    display: block;
                  }
                  .desktopImg{
                    display: none;
                  }
            }
            &.betPopWarnHeadImg{
                background-image: url("../../images/redo/tour_warning-svg.svg") !important;
                background-position: 53% 36% !important;
                background-repeat: no-repeat;
                @media only screen and (max-width: 767px){
                    background-position: 50% 35% !important;
                }
            }
            &.betPopDefaultHeadImg{
                background-image: url("../../images/redo/default_sandtimer_svg.svg") !important;
                background-position: center !important;
                background-repeat: no-repeat;
                height: 80%;

                @media only screen and (max-width: 767px){
                    background-size: 20%;
                }
                
            }
        }

        .betPopHeadTitle {
            color: #FFF;
            text-align: center;
            font-family: "Gilroy-Medium";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            @media only screen and (max-width: 767px) {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .betPopHeadTag {
            color: var(--Background---grey-Grey---200---GB, #92A6BA);
            text-align: center;
            font-family: "Gilroy-Regular";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            padding: 15px;
        }

        .betPopContent {
            border-radius: var(--Spacing-lg, 16px);
            opacity: var(--Corner-radius-xs, 1);
            background: var(--Background---grey-Grey---700---GB, #0E1216);
            padding: 20px;
            margin: 20px 10px;
            @media only screen and (max-width: 767px) {
                padding: 24px 16px;
                margin: 24px 0px;
            }

            .betPopContentInner {
                .betPopContentItem {
                    padding: 10px 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    @media only screen and (max-width: 767px) {
                        padding: 0px;
                        gap: 8px;
                        &:first-child{
                            padding-bottom: 32px;
                        }
                        
                    }
                    .betPopContentTag {
                        color: var(--Background---grey-Grey---200---GB, #92A6BA);
                        font-family: "Gilroy-Medium";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        @media only screen and (max-width: 767px) {
                            font-size: 12px;
                        }
                    }

                    .betPopContentTagValue {
                        color: var(--Background---grey-Grey---50---GB, #E3E8ED);
                        font-family: "Gilroy-Medium";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                          img{
                            @media only screen and (max-width: 767px) {
                                height: 24px;
                                width: 24px;
                            }
                          }

                        &.loaderContent {
                            padding: 18px;
                            color: #92A6BA;
                            font-family: "Gilroy-Regular";
                            font-size: 18px;
                            line-height: 24px;
                            text-align: center;
                        }
                    }
                    .betPopOutcomeTag{
                        text-transform: capitalize;
                    }
                }

                .betPopList {
                    li {
                        color: var(--Background---grey-Grey---100---GB, #C7D1DB);
                        font-family: "Gilroy-Medium";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        padding: 10px;
                        border-bottom: 1px solid var(--Background---grey-Grey---400---GB, #3B4D5E);
                    }
                }
            }
        }

        .betPopDisclaimer {
            color: var(--Background---grey-Grey---200---GB, #92A6BA);
            text-align: center;
            font-family: Gilroy-Regular;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            padding: 0 10px 10px 10px;
            @media only screen and (max-width: 767px) {
                padding: 0 0px 30px 0px;
                font-size: 14px;
            }
        }

        .betPopFooter {
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media only screen and (max-width: 767px) {
                padding: 10px 0px;
            }
            

            .betPopNextBtn {
                padding: var(--Spacing-lg, 16px) var(--Spacing-2xl, 24px);
                border-radius: var(--Spacing-xl, 20px);
                background: var(--Primary-Green---50---GB, #F1FEEC);
                box-shadow: 0px 2px 4px 0px rgba(241, 254, 236, 0.04), 0px 24px 48px -8px rgba(241, 254, 236, 0.12);
                width: 100%;
                color: var(--Background---grey-Grey---950---GB, #020303);
                font-family: "Gilroy-Medium";
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: center;
                border: none;
                @media only screen and (max-width: 767px) {
                    font-size: 14px;
                }

                &.disableIt {
                    opacity: 0.5;
                    cursor: default;
                }

                &.btnAjaxLoading {
                    .text-light {
                        color: #181f26 !important;
                        margin-right: 5px !important;
                    }
                }
            }

            .betPopCloseBtn {
                padding: var(--Spacing-lg, 16px) var(--Spacing-2xl, 24px);
                border-radius: var(--Spacing-2xl, 20px);
                opacity: var(--Corner-radius-xs, 1);
                background: var(--Background---grey-Grey---400---GB, #3B4D5E);
                width: 100%;
                color: #FFF;
                font-family: Gilroy-Medium;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                border: none;
                @media only screen and (max-width: 767px) {
                    font-size: 14px;
                }

                &.disableIt {
                    opacity: 0.5;
                    cursor: default;
                }
            }

            &.betPopFooterFlex {
                flex-direction: row !important;
                margin-top: 25px;
            }
        }
    }
}

.bet-abandon-td {
    padding: 10px;

    img {
        cursor: pointer;
    }
}
.bet-ticket-img{
    cursor: pointer;
}
.rd_disputeButton {
    border: solid 1px #fff;
    color: #181f26;
    background: #fff;
    padding: 4px 12px;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;
    @media only screen and (min-width: 1366px) {
        width: 125px;
        text-align: center;
    }
    &.resolved {
        border: solid 1px #31ff1f !important;
        background: #31ff1f;
        color: #000 !important;
        cursor: default;
    }

    &.placed {
        border: solid 1px #ffc01f !important;
        background: #ffc01f;
        color: #000 !important;
        cursor: default;
    }
}
@keyframes rotateRight {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }