._GB_Twofactor{
    background: linear-gradient(355.71deg, #0E1216 3.49%, rgba(14, 18, 22, 0.015625) 71.81%, rgba(14, 18, 22, 0) 71.81%);
        background-image: url("../../images/redo/2fa-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 120%;
        display: flex;

    //    .common_wrapper {
           
    //     }
    .twofactor_outerWrap{
        background-color: #0e1216;
        background-color: var(--Background---grey-Grey---700---GB,#0e1216);
        background-image: url("../../images/redo/newtwofactor2.png");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: contain;
        max-width: 100%;
        width: 100%;
        padding: 130px 15px 250px 15px;

        @media only screen and (max-width: 991px){
            padding: 110px 15px 198px 15px;
        }
        @media only screen and (max-width: 767px){
            padding: 85px 15px 60px 15px;
            background-size: auto;
            background-position: 50% 70%;
        }

    .twofactor_wrap{
        width: 100%;
        max-width: 550px;
        margin: auto;
     
        .twofactor_commonstep{
            z-index: 999999;
            position: relative;
        }
        .twofactor_pattern{
            position: absolute;
            transform-origin: center center;
            animation: rotate 60s linear infinite;
            object-fit: cover;
            left: 23%;
            top: 0%;
            z-index: 0; 
            @media only screen and (max-width: 1024px){
                display: none;
            }
        }
        .twofactor_radio{
            img{
                width: 24px;
                height: 24px;
                border-radius: 50%;
            }

        }

        .twofactor_title{
            font-family: "Gilroy-Medium";
            font-size: 28px;
            line-height: 38px;
            color: #fff;
            margin-bottom: 10px;
            @media only screen and (max-width: 767px){
                font-size: 20px;
                line-height: 24px;
            }
            
            .img_animi_sec{
                background-image: url("../../images/redo/perfect-icon-svg.svg") !important;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 24px;
                img{
                    animation: rotateRight 60s linear infinite;
              
                }
                .mobile-img{
                 display: none;
                }
                @media only screen and (max-width: 767px){
                    background-image: url("../../images/redo/perfect-mobile-svg.svg") !important;

                    .desktop-img{
                        display: none;
                    }
                    .mobile-img{
                        display: inline-block;
                        margin-bottom: 0px;
                       }
                }

            }

        }
        .twofactor_subtitle{
            color: #92A6BA;
            font-size: 16px;
            line-height: 24px;
            font-family: "Gilroy-Regular";
            margin-bottom: 40px;
            max-width: 452px;
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            @media only screen and (max-width: 767px){
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 24px;
            }
            span{
                color: #74F33B;
                font-family: "Gilroy-Bold";
                font-size: 18px;
                @media only screen and (max-width: 767px){
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
        .GB_twofactor_authentication{
            @media only screen and (max-width: 767px){
                padding:0px;
            }
            .otp-enter{
                gap:20px;
                @media only screen and (max-width: 767px){
                    gap: 8px;
                }
                input{
                    border-radius: var(--Spacing-2xl, 24px);
                    background: var(--Background---grey-Grey---600---GB, #141A1F);
                    border: 0 !important;
                    width: 72px !important;
                    height: 72px !important;
                    margin: 0 !important;
                    color: var(--Background---grey-Grey---50---GB, #E3E8ED);
                    font-family: "Gilroy-Bold";
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                     &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: #fff;
                      }
                      &::-moz-placeholder { /* Firefox 19+ */
                        color: #fff;
                      }
                      &:-ms-input-placeholder { /* IE 10+ */
                        color: #fff;
                      }
                      &:-moz-placeholder { /* Firefox 18- */
                        color: #fff;
                      }
                    @media only screen and (max-width: 767px){
                        border-radius: 16px;
                        font-size: 16px;
                        width: 48px !important;
                        height: 48px !important;
                    }
                    @media only screen and (max-width: 359px){
                        max-width: 35px !important;
                        height: 35px !important;
                        border-radius: 10px;
                    }
                }
            }
        }
        .twofactor_firstbuton{
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap:25px;
            height: 62px;
            width: 100%;
            max-width: 394px;
            margin: 10px auto;
            background-color: #181F26;
            margin-bottom: 15px;
            border-radius: 24px;
            padding: 20px;
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-family: 'Gilroy-Medium';

            @media only screen and (max-width: 767px){
                gap:8px;
                font-size: 14px;
                line-height: 24px;

                
            }
            
        }
        .twofactor_secondbuton{
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap:25px;
            height: 62px;
            width: 100%;
            max-width: 394px;
            margin: 10px auto;
            background-color: #181F26;
            margin-bottom: 15px;
            border-radius: 24px;
            padding: 20px;
            color: #fff;
            font-size: 16px;
            line-height: 24px;
            font-family: 'Gilroy-Medium';
            @media only screen and (max-width: 767px){
                gap:8px;
                font-size: 14px;
                line-height: 24px;

                
            }
        }
        .twofactor_thirdbuton{
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 394px;
            width: 100%;
            margin: 10px auto;
            height: 56px;
            border-radius: var(--Spacing-2xl, 24px);
            border: 1.5px solid #F1FEEC;
            background-color: #F1FEEC;
            box-shadow: 0px 2px 4px 0px rgba(78, 255, 97, 0.02), 0px 16px 56px -4px rgba(78, 255, 97, 0.08);
            margin-top: 40px;
            @media only screen and (max-width: 767px){
                margin-top: 125px;
                height: 48px;
                span{
                    font-size: 16px !important;
                }
            }
            &:hover{
                opacity: 0.5;
            }
            span{
                font-family: "Gilroy-SemiBold";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px
            }
            img{
                margin-left: 5px; 
            }
        }
    }

}
}

@keyframes rotateRight {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
