@import "../fonts/stylesheet.css";

.container {

  // @media only screen and (min-width: 1366px) and (max-width: 1919px){
  //   max-width: 940px !important;
  //   margin: unset;
  // }
  @media only screen and (min-width: 1920px) {
    max-width: 1400px !important;
  }
}

// .GB_playToEarn {
//   .container {
//     @media only screen and (min-width: 1366px) and (max-width: 1919px) {
//       max-width: 75% !important;
//     }
//   }
// }

/* Streamers page */
._GB_Stremers {

  // overflow: hidden;
  ._GB_Banner_Top {
    // background: linear-gradient(
    //   231.85deg,
    //   #c80000 -37.37%,
    //   rgba(200, 0, 0, 0) 23.62%
    // );
    background-color: #000;

    ._GB_stremer_Banner {
      height: 650px;
      color: #fff;

      @media only screen and (max-width: 1423px) {
        height: auto;
      }

      ._Banner_content {
        max-width: 952px;
        padding: 8px 10px;
        border-radius: 15px;
        margin: 0 auto;

        ._Title {
          font-family: "Poppins-Bold";
          margin-bottom: 20px;
          font-size: 30px;
          line-height: 40px;
          letter-spacing: 0em;
          text-align: center;

          @media only screen and (min-width: 1366px) {
            font-size: 45px;
            line-height: 67px;
          }
        }

        ._SubTitle {
          font-family: "Poppins";
          font-size: 26px;
          font-style: normal;
          font-weight: 400;
          line-height: 39px;
          letter-spacing: 0em;
          text-align: center;
          max-width: 739px;
          margin: 0 auto;
          width: 100%;
          border-bottom: 2px solid #e52420;

          @media only screen and (min-width: 1366px) {
            margin-bottom: 20px;
          }

          @media only screen and(max-width: 767px) {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
          }
        }
      }

      ._Banner_button {
        padding-top: 27px;
        display: flex;
        justify-content: center;

        @media only screen and(max-width: 767px) {
          flex-direction: column;
          margin-top: 35px;
        }

        ._GB_Register_Btn {
          max-width: 315px;
          // height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 18px 35px;
          text-decoration: none;
          margin-right: 45px;
          outline: none;

          @media only screen and(max-width: 767px) {
            padding: 12px 25px;
            font-size: 11px;
            margin: auto;
            margin-bottom: 30px;
          }

          @media only screen and(max-width: 575px) {
            font-size: 11px;
          }

          &:after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.7;
          }
        }

        ._GB_Join_telegram {
          //max-width: 307px;
          // height: 62px;
          border: 2px solid #292929;
          border-radius: 10px;
          font-size: 17px;
          font-style: normal;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: 0.04em;
          text-align: center;
          background: transparent;
          text-transform: uppercase;
          font-family: "Poppins-SemiBold";
          padding: 21px 25px 21px 70px;
          position: relative;
          box-shadow: none;
          border-left: none;

          @media only screen and(max-width: 767px) {
            font-size: 15px;
            padding: 10px 20px 10px 60px;
            margin: auto;
          }

          @media only screen and(max-width: 575px) {
            font-size: 11px;
          }

          // &:after {
          //   content: url("../images/discord_icon.png");
          //   padding-left: 10px;
          //   width: 68px;
          //   height: 68px;
          //   left: -27px;
          //   position: absolute;
          //   top: -5px;
          //   @media only screen and (max-width: 767px) {
          //     height: 55px;
          //     top: -9px;
          //     left: -2px;
          //   }
          // }
          img {
            height: 64px;
            position: absolute;
            top: -2px;
            left: -14px;

            @media only screen and (max-width: 767px) {
              height: 55px;
              top: -9px;
              left: -3px;
            }
          }

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  ._GB_AboutStreamer {
    background: #000;

    ._GB_LeftStreamerContent {
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    ._GB_RightStreamerContent {
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0%;
        text-align: center;
      }

      padding-left: 2%;

      ._GB_About_Title {
        font-style: normal;
        font-size: 30px;
        line-height: 40px;
        color: #ffffff;
        font-family: "Poppins-SemiBold";
        text-align: center;

        @media only screen and (min-width: 768px) {
          padding-right: 20%;
        }

        @media only screen and (min-width: 1366px) {
          font-size: 48px;
          line-height: 48px;
        }

        @media only screen and (max-width: 767px) {
          text-align: center;
        }
      }

      ._GB_About_Text {
        font-family: "Gilroy-Medium";
        font-size: 18px;
        line-height: 32px;
        color: #ffffff;
        margin-top: 20px;
      }

      ._GB_About_Btn {
        width: 410px;
        height: 56px;
        color: #fff;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 17px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        padding: 12px 45px;
        text-decoration: none;

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }

        @media only screen and(max-width: 574px) {
          width: 100%;
          font-size: 15px;
          padding: 12px 9px;
        }

        @media only screen and(min-width: 575px) and (max-width: 992px) {
          font-size: 15px;
          width: 100%;
          padding: 12px 15px;
        }
      }
    }
  }

  ._GB_WorkStreamer {
    background: #000;

    @media only screen and (min-width: 991px) {
      height: 650px;
    }

    .col-4 {
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
    }

    .Work_col_1 {
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0%;
      }
    }

    .Work_col_2 {
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0%;
        padding-bottom: 30px;
      }

      ._GB_About_Image {
        position: relative;

        ._GB_About_caption {
          position: absolute;
          width: 240px;
          height: 230px;
          left: -30px;
          bottom: -44px;
          background: #e52420;
          border: 1px solid #e52420;
          border-radius: 100%;
          font-family: "Gilroy-Medium";
          font-size: 19px;
          line-height: 26px;
          color: #ffffff;
          padding: 40px;
          text-align: center;

          @media only screen and(max-width: 574px) {
            width: 200px;
            height: 200px;
            padding: 25px 40px;
            font-size: 15px;
            left: -10px;
          }
        }
      }
    }

    .Work_col_3 {
      @media only screen and (max-width: 767px) {
        width: 100%;
        padding-left: 0%;
      }
    }

    ._GB_About_Title {
      padding-right: 20%;
      font-style: normal;
      font-size: 30px;
      line-height: 40px;
      color: #ffffff;
      font-family: "Poppins-SemiBold";

      @media only screen and (min-width: 1366px) {
        font-size: 48px;
        line-height: 48px;
      }

      @media only screen and (max-width: 991px) {
        padding-right: 0%;
      }

      @media only screen and (max-width: 767px) {
        text-align: center;
      }
    }

    ._GB_About_Text {
      font-family: "Gilroy-Medium";
      font-size: 18px;
      line-height: 32px;
      color: #ffffff;
      margin-top: 20px;
      padding-right: 9%;

      @media only screen and (max-width: 991px) {
        padding-right: 0%;
        padding-bottom: 30px;
      }
    }

    ._Button_group {
      padding-left: 66px;

      @media only screen and (max-width: 1023px) {
        padding-left: 0px;
      }

      @media only screen and (max-width: 767px) {
        align-items: center;
      }

      ._GB_Register_Btn {
        max-width: 410px;
        height: 62px;
        color: #fff;
        background-color: #e52420;
        background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
        border-radius: 10px;
        border: 2px solid #ffffff;
        font-family: "Poppins-SemiBold";
        font-size: 17px;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        padding: 18px 40px;
        text-decoration: none;
        outline: none;

        &::after {
          content: url("../images/arrow.png");
          padding-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }

        @media only screen and(min-width: 768px) and (max-width: 1023px) {
          padding: 18px;
        }

        @media only screen and(max-width: 574px) {
          width: 100%;
          font-size: 15px;
          padding: 12px 9px;
        }
      }

      ._GB_Support_Btn {
        max-width: 410px;
        // height: 62px;
        border: 2px solid #292929;
        border-radius: 10px;
        font-family: "Poppins-SemiBold";
        font-size: 17px;
        font-style: normal;
        text-align: center;
        background: transparent;
        line-height: 17px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        padding: 20px 50px;
        text-decoration: none;
        outline: none;

        @media only screen and (max-width: 991px) {
          padding: 20px 20px;
          width: 100%;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  ._GB_gameStreamer {
    @media only screen and (min-width: 991px) {
      height: 745px;
    }

    background: linear-gradient(231.85deg,
      #c80000 -37.37%,
      rgba(200, 0, 0, 0) 23.62%);
    background-color: #000;

    ._GB_LeftStreamerContent {
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }

    ._GB_RightStreamerContent {
      padding-left: 2%;
      margin-left: 3.3%;

      @media only screen and (max-width: 767px) {
        padding-left: 0%;
        margin-left: 0%;
        width: 100%;
        text-align: center;
      }

      ._GB_About_Title {
        padding-right: 20%;
        font-style: normal;
        font-size: 30px;
        line-height: 40px;
        color: #ffffff;
        font-family: "Poppins-SemiBold";

        @media only screen and (min-width: 1366px) {
          font-size: 48px;
          line-height: 48px;
        }

        @media only screen and (max-width: 1200px) {
          padding-right: 0%;
        }

        @media only screen and (max-width: 767px) {
          text-align: center;
        }
      }

      ._GB_About_Text {
        font-family: Gilroy-Medium;
        font-size: 18px;
        line-height: 32px;
        color: #ffffff;
        margin-top: 20px;
        padding-right: 9%;

        @media only screen and (max-width: 991px) {
          padding-right: 0%;
        }
      }

      ._Button_group {
        ._GB_Register_Btn {
          max-width: 410px;
          // height: 56px;
          color: #fff;
          background-color: #e52420;
          background-image: linear-gradient(to right,
              #6d1513,
              #e52420,
              #6d1513);
          border-radius: 10px;
          border: 2px solid #ffffff;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 20px 50px;
          text-decoration: none;
          outline: none;

          @media only screen and (max-width: 991px) {
            padding: 20px;
            width: 100%;
          }

          &::after {
            content: url("../images/arrow.png");
            padding-left: 10px;
          }

          &:hover {
            opacity: 0.5;
          }
        }

        ._GB_Support_Btn {
          max-width: 410px;
          // height: 62px;
          border: 2px solid #292929;
          border-radius: 10px;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          font-style: normal;
          text-align: center;
          background: transparent;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          padding: 20px 35px;
          text-decoration: none;
          outline: none;

          &:hover {
            opacity: 0.5;
          }

          @media only screen and (max-width: 991px) {
            padding: 20px 20px;
            width: 100%;
          }
        }

        @media only screen and (max-width: 767px) {
          align-items: center;
        }
      }

      ._Social_group {
        gap: 30px;

        @media only screen and (max-width: 991px) {
          text-align: center;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        h3 {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #ffffff;
          font-family: "Gilroy-SemiBold";
          margin-bottom: 0;
        }

        .forum {
          color: rgba(255, 255, 255, 0.5);
          font-family: "Gilroy-SemiBold";
          font-size: 13px;
          border: 1px solid #727070;
          background: #222222;
          border-radius: 7px;
          text-decoration: none;
          padding: 6px 15px;
          line-height: 16px;
          align-items: center;
          justify-content: center;
          display: flex;
        }

        .discord_cls {
          img {
            width: 35px;
          }
        }

        .twitter_cls {
          img {
            width: 30px;
          }
        }

        @media only screen and (max-width: 991px) {
          text-align: center;
          flex-direction: column;
        }

        .social_icon {
          a {
            margin: 5px;
          }
        }
      }
    }
  }
}

/* Perk page */
._GB_Perks {
  ._GB_Header {
    background-color: #000;
  }

  ._GB_Banner_Top {
    ._GB_Perk_Banner {
      background-color: #000;
      padding: 50px 0px;

      ._GB_Perk_Inner {
        h2 {
          font-family: "Gilroy-Bold";
          font-size: 48px;
          line-height: 90px;
          color: #ffffff;

          @media only screen and (max-width: 990px) {
            font-size: 40px;
          }

          @media only screen and (max-width: 480px) {
            font-size: 35px;
          }
        }

        .Perk_Bg {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px 33px 60px 33px;

          .fortnite {
            justify-content: flex-end;
          }

          .perk_drop {
            background-color: #050505;
            border-radius: 10px;
            border-color: #050505;
            color: #fff;
            width: 189px;
            padding: 5px 15px;
            margin-bottom: 25px;
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;

            option {
              background-color: #050505;
              border-radius: 10px;
              border-color: #050505;
              color: #fff;
              width: 189px;
              padding: 5px 15px;
              margin-bottom: 15px;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
              color: #ffffff;
            }
          }

          .full_section {
            @media only screen and (max-width: 1600px) {
              justify-content: center;
            }

            @media only screen and (max-width: 1200px) and (min-width: 1025px) {
              justify-content: space-between;
            }

            .perk_details {
              max-width: 417px;
              width: 100%;
              height: 261px;
              background-position: 0px 0px;
              background-repeat: no-repeat;
              background-size: cover !important;
              background: linear-gradient(180deg,
                  rgba(18, 18, 18, 0.99) 18.39%,
                  rgba(18, 18, 18, 0.663043) 70.11%,
                  rgba(18, 18, 18, 0) 100%),
                url("../images/perk_coins.png") #353535;
              padding: 38px;
              margin: 0px 26px 26px 0px;
              border: 1px solid #ffffff;
              box-sizing: border-box;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 25%);
              border-radius: 15px;

              @media only screen and (max-width: 1200px) {
                margin-right: 0px;
              }

              &:nth-child(3n + 3) {
                @media only screen and (min-width: 1600px) {
                  margin-right: 0px;
                }
              }

              &:nth-last-child(-n + 2) {
                @media only screen and (min-width: 1200px) {
                  margin-bottom: 0px;
                }
              }

              .items_inner {
                @media only screen and (max-width: 480px) {
                  flex-direction: column;
                }
              }

              .Right_content {
                @media only screen and (max-width: 480px) {
                  text-align: center;
                }

                .title {
                  font-family: "Poppins-SemiBold";
                  font-style: normal;
                  font-size: 33px;
                  line-height: 100%;
                  color: #ffffff;

                  @media only screen and (max-width: 480px) {
                    font-size: 25px;
                  }
                }

                .sub_title {
                  font-family: "Poppins-SemiBold";
                  font-style: normal;
                  font-size: 33px;
                  line-height: 100%;
                  color: #ffc01f;
                  margin-top: 10px;

                  @media only screen and (max-width: 480px) {
                    font-size: 25px;
                  }
                }
              }

              .Left_content {
                @media only screen and (max-width: 480px) {
                  text-align: center;
                  margin-top: 10px;
                  justify-content: center;
                  display: flex;
                }

                .Yellow_bg {
                  width: 115px;
                  height: 115px;
                  background: linear-gradient(159.63deg,
                      #ffc01f 5.21%,
                      #ffd875 52.42%,
                      #ffc01f 100.46%);
                  border: 3px solid #cca02f;
                  box-sizing: border-box;
                  box-shadow: 0px 0px 5px rgba(255, 192, 31, 0.6);
                  border-radius: 100%;
                  flex-direction: column;
                  justify-content: center;

                  @media only screen and (max-width: 480px) {
                    width: 80px;
                    height: 80px;
                  }

                  .title {
                    font-family: "Poppins-SemiBold";
                    font-style: normal;
                    font-size: 41px;
                    line-height: 100%;
                    text-align: center;
                    color: #121212;

                    @media only screen and (max-width: 480px) {
                      font-size: 30px;
                    }
                  }

                  .sub_title {
                    font-family: "Poppins-SemiBold";
                    font-style: normal;
                    font-size: 20px;
                    line-height: 100%;
                    text-align: center;
                    color: #121212;
                    text-transform: uppercase;

                    @media only screen and (max-width: 480px) {
                      font-size: 15px;
                    }
                  }
                }
              }

              .Button_grp {
                @media only screen and (max-width: 480px) {
                  margin-top: 10px !important;
                  float: none !important;
                  text-align: center;
                }

                ._GB_Redeem_btn {
                  font-family: "Gilroy-Bold";
                  font-size: 17px;
                  line-height: 100%;
                  text-align: center;
                  text-transform: uppercase;
                  color: #ffffff;
                  height: 44px;
                  width: 154px;
                  border-radius: 10px;
                  background: rgba(0, 0, 0, 0.6);
                  border: 2px solid #ffffff;
                  box-sizing: border-box;
                  padding: 12px 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* challenge page */
._GB_Challenges {
  ._GB_Header {
    background-color: #000;
  }

  ._GB_Banner_Top {
    ._GB_Challenge_Banner {
      background-color: #000;
      padding: 50px 0px;

      ._GB_Challenge_Inner {
        h2 {
          font-family: "Gilroy-Bold";
          font-size: 48px;
          line-height: 90px;
          color: #ffffff;

          @media only screen and (max-width: 990px) {
            font-size: 40px;
          }

          @media only screen and (max-width: 480px) {
            font-size: 35px;
          }
        }

        .Challenge_Bg {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px 33px 60px 33px;

          .fortnite {
            justify-content: flex-end;
          }

          .challenge_drop {
            background-color: #050505;
            border-radius: 10px;
            border-color: #050505;
            color: #fff;
            width: 189px;
            padding: 5px 15px;
            margin-bottom: 25px;
            font-family: "Gilroy-Bold";
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;

            option {
              background-color: #050505;
              border-radius: 10px;
              border-color: #050505;
              color: #fff;
              width: 189px;
              padding: 5px 15px;
              margin-bottom: 15px;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
              color: #ffffff;
            }
          }

          .full_section {
            .challenge_details {
              background: #1f1f1f;
              border-radius: 15px;

              padding: 18px 18px;
              width: 100%;
              margin-bottom: 7px;

              .items_inner {
                @media only screen and (max-width: 990px) {
                  flex-direction: column;
                }

                justify-content: space-between;
                align-items: center;

                .left_Content {
                  width: 96px;
                  max-height: 56px;
                  background: #050505;
                  border-radius: 10px;
                  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                  padding: 15px 50px 15px 20px;

                  .title {
                    font-family: "Gilroy-Bold";
                    font-size: 22px;
                    line-height: 100%;
                    color: #ffffff;
                    margin-left: 11px;
                  }
                }

                .middle_Content {
                  // margin-left:20px;

                  @media only screen and (max-width: 990px) {
                    width: 100%;
                  }

                  .title {
                    font-family: "Gilroy-Bold";
                    font-size: 18px;
                    line-height: 100%;
                    color: #ffffff;
                    margin-bottom: 13px;

                    @media only screen and (max-width: 990px) {
                      padding: 20px 0px;
                      text-align: center;
                    }
                  }

                  .ProgressbarSection {
                    .progress {
                      display: flex;
                      height: 10px;
                      overflow: hidden;
                      font-size: 0.75rem;
                      background-color: #000;
                      border-radius: 5px;
                    }
                  }
                }

                .range_Content {
                  align-items: center;
                  display: flex;

                  @media only screen and (max-width: 990px) {
                    width: 100%;
                    justify-content: center;
                  }

                  .sub_title {
                    font-family: "Gilroy-Bold";
                    font-size: 16px;
                    line-height: 100%;
                    margin-top: 18px;
                    color: #ffffff;

                    @media only screen and (max-width: 990px) {
                      padding: 20px 0px;
                      text-align: center;
                    }
                  }
                }

                .right_Content {
                  @media only screen and (max-width: 990px) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                  }

                  .btn_grp {
                    justify-content: flex-end;

                    ._GB_Go {
                      background: #1f1f1f;
                      font-family: Gilroy-Bold;
                      font-size: 17px;
                      line-height: 100%;
                      text-align: center;
                      letter-spacing: 0.04em;
                      text-transform: uppercase;
                      color: #ffff;
                      width: 140px;
                      border-radius: 10px !important;
                      padding: 12px 10px;
                      border: 2px solid #414141;
                      box-sizing: border-box;
                      margin-bottom: 0px !important;

                      &:hover {
                        opacity: 0.6;
                      }
                    }

                    ._GB_Collect {
                      font-family: "Gilroy-Bold";
                      font-size: 17px;
                      line-height: 100%;
                      text-align: center;
                      letter-spacing: 0.04em;
                      text-transform: uppercase;
                      color: #ffffff;
                      background: linear-gradient(90deg,
                          #6d1513 0%,
                          rgba(109, 21, 19, 0.18) 48.67%,
                          #6d1513 100.07%);
                      border-radius: 9px;
                      width: 140px;
                      padding: 12px 7px;
                      border: solid 1px #fff;
                      background-color: #e52420;

                      &:hover {
                        opacity: 0.6;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* Leaderboard page */
._GB_LeaderBoard {
  ._GB_Header {
    background-color: #000;
  }

  ._GB_Banner_Top {
    ._GB_Leaderboard_Banner {
      background-color: #000;
      padding: 50px 0px;

      ._GB_Leaderboard_Inner {
        h2 {
          font-family: "Gilroy-Bold";
          font-size: 40px;
          line-height: 90px;
          color: #ffffff;

          @media only screen and (max-width: 990px) {
            font-size: 30px;
          }

          @media only screen and (max-width: 480px) {
            font-size: 25px;
          }
        }

        .Leaderboard_Bg {
          background: #181818;
          border: 2px solid #2d2d2d;
          box-sizing: border-box;
          border-radius: 20px;
          padding: 20px 33px 10px 33px;

          .top-section {
            justify-content: space-between;

            @media only screen and(max-width: 767px) {
              flex-direction: column;
            }
          }

          .img-section {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and(max-width: 767px) {
              flex-direction: column;
            }

            .GB_countdown {
              padding-left: 30px;
            }

            .title {
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
              color: rgba(235, 235, 245, 0.6);

              @media only screen and (max-width: 767px) {
                padding-bottom: 10px;
              }

              .reward_dollar {
                font-family: "Gilroy-Bold";
                color: #fff;
                margin-bottom: 0px;
                height: 32px;
                width: 32px;
                background: #db9a01;
                border-radius: 10px;
                margin: 0 10px;
                line-height: 100%;
                text-align: center;
                align-items: center;
                justify-content: center;
                display: flex;
              }

              .equals {
                margin-bottom: 0;
                padding-right: 5px;
              }
            }
          }

          .SelectBoxGrp {
            justify-content: flex-end;

            @media only screen and(max-width: 767px) {
              justify-content: center;
            }

            .fortnite {
              margin-right: 15px;

              @media only screen and (max-width: 767px) {
                margin-right: 0px;
              }
            }

            .Leaderboard_drop {
              background-color: #050505;
              border-radius: 10px;
              border-color: #050505;
              color: #fff;
              width: 189px;
              padding: 5px 15px;
              margin-bottom: 25px;
              font-family: "Gilroy-Bold";
              font-size: 16px;
              line-height: 19px;
              color: #ffffff;

              option {
                background-color: #050505;
                border-radius: 10px;
                border-color: #050505;
                color: #fff;
                width: 189px;
                padding: 5px 15px;
                margin-bottom: 15px;
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;
              }
            }

            .sortType {
              .sort_drop {
                background-color: #050505;
                border-radius: 10px;
                border-color: #050505;
                color: #fff;
                width: 189px;
                padding: 5px 15px;
                margin-bottom: 25px;
                font-family: "Gilroy-Bold";
                font-size: 16px;
                line-height: 19px;
                color: #ffffff;

                option {
                  background-color: #050505;
                  border-radius: 10px;
                  border-color: #050505;
                  color: #fff;
                  width: 189px;
                  padding: 5px 15px;
                  margin-bottom: 15px;
                  font-family: "Gilroy-Bold";
                  font-size: 16px;
                  line-height: 19px;
                  color: #ffffff;
                }
              }
            }
          }

          .full_section {
            .Leaderboard_details {
              // background: #1f1f1f;
              // border-radius: 15px;
              // padding: 18px 18px;
              width: 100%;

              // margin-bottom: 7px;
              .table> :not(:first-child) {
                border-top: 0px solid currentColor;
              }

              table {

                // @media only screen and (max-width: 991px){
                //   display: flex;
                // }
                thead {
                  background: #292929;

                  @media only screen and (max-width: 767px) {
                    display: none;
                  }

                  tr {
                    background: #292929;
                    border-radius: 10px 10px 0px 0px;

                    // @media only screen and (max-width: 991px){
                    //   display: grid;
                    // }
                    th {
                      font-family: "Gilroy-Bold";
                      font-size: 16px;
                      line-height: 100%;
                      color: rgba(235, 235, 245, 0.6);
                      padding: 10px 10px 10px 3px;

                      &:first-child {
                        padding: 10px 10px 10px 20px;
                        border-radius: 10px 0px 0px 0px;
                      }

                      &:last-child {
                        padding: 10px 10px 10px 20px;
                        border-top-right-radius: 10px;
                      }

                      &:nth-child(4),
                      &:nth-child(5),
                      &:nth-child(6),
                      &:nth-child(7) {
                        text-align: center;
                      }

                      &.chess_board_th {
                        text-align: center;
                      }
                    }
                  }
                }

                tbody {
                  tr {

                    // @media only screen and (max-width: 991px){
                    //   display: grid;
                    // }
                    &:nth-child(even) {
                      td {
                        border-color: #000;
                        background: #121212;
                      }
                    }

                    &:nth-child(odd) {
                      td {
                        border-color: #000;
                        background: #161616;
                      }
                    }

                    td {
                      font-family: "Gilroy-Bold";
                      font-size: 18px;
                      line-height: 100%;
                      color: #ffffff;
                      vertical-align: middle;
                      padding: 10px 2px;
                      box-shadow: none;
                      text-align: left;

                      @media only screen and (max-width: 991px) {
                        font-size: 14px;
                      }

                      &:nth-child(4),
                      &:nth-child(5),
                      &:nth-child(6) {
                        text-align: center;
                      }

                      &:nth-child(2) {
                        @media only screen and (min-width: 768px) {
                          width: 35%;
                        }
                      }

                      &.playerDetails {
                        .playerInfo {
                          text-align: left;
                        }
                      }

                      .redBox {
                        height: 32px;
                        width: 32px;
                        background: #e52420;
                        border-radius: 10px;
                        font-family: "Gilroy-Bold";
                        font-size: 18px;
                        line-height: 100%;
                        text-align: center;
                        color: #ffffff;
                        align-items: center;
                        justify-content: center;
                        display: flex;
                      }

                      .GbplayerCol {
                        flex-direction: row;
                        display: flex;
                        align-items: center;

                        @media only screen and(max-width: 767px) {
                          display: flex !important;
                        }

                        @media only screen and (min-width: 768px) and (max-width: 991px) {
                          flex-direction: column;
                          display: flex;
                          line-height: 21px;
                        }

                        img {
                          border: 2px solid #ffffff;
                          box-sizing: border-box;
                          width: 50px;
                          height: 50px;
                          object-fit: cover;
                          border-radius: 100%;
                          margin-right: 20px;
                        }
                      }

                      &.match_history {
                        text-align: center;
                      }

                      &.rank_image {
                        padding-left: 15px;
                      }

                      &.player_data {
                        @media only screen and (min-width: 768px) {
                          width: 25%;
                        }
                      }

                      &.streamer_data {
                        // display: flex;
                        // flex-direction: column;
                        // align-items: center;
                        // justify-content: center;
                        text-align: center;

                        @media only screen and(max-width: 767px) {
                          // text-align: left;
                          // display: block;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .pagination-full-section {
            margin: 40px 0px;

            .paginate {
              margin: 0px 13px;
              background: rgba(0, 0, 0, 0.5);
              border: 1px solid #4f4f4f;
              box-sizing: border-box;
              border-radius: 10px;
              padding: 4px 4px;
              justify-content: center;
              align-items: center;

              ul {
                margin-bottom: 0px;
                align-items: center;
                justify-content: center;
                display: flex;
                position: relative;

                li {
                  list-style-type: none;
                  margin-right: 10px;

                  a {
                    font-family: "Gilroy-Bold";
                    font-size: 18px;
                    line-height: 100%;
                    text-align: center;
                    color: #ffffff;
                    background-color: transparent;
                    border: none;

                    &:focus {
                      box-shadow: none;
                    }
                  }

                  &.previous {
                    position: absolute;
                    left: -61px;

                    a {
                      background: url(../images/rightarrow.png) 0px 0px no-repeat;
                      height: 50px;
                      width: 50px;
                    }
                  }

                  &.next {
                    position: absolute;
                    right: -61px;

                    a {
                      background: url(../images/leftarrow.png) 0px 0px no-repeat;
                      height: 50px;
                      width: 50px;
                    }
                  }

                  &.active {
                    background: #e52420;
                    border-radius: 8px;
                    height: 42px;
                    width: 42px;
                    vertical-align: middle;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                  }

                  &:last-child {
                    margin-right: 0px;
                  }

                  &:first-child {
                    margin-right: 0px;
                  }

                  &:nth-last-child(2) {
                    margin-right: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* supported game */
._GB_SupportedGame {

  // overflow: hidden;
  ._GB_Banner_Top {
    // background: linear-gradient(
    //   231.85deg,
    //   #c80000 -37.37%,
    //   rgba(200, 0, 0, 0) 23.62%
    // );
    background-color: #000;

    ._GB_support_Banner {
      color: #fff;
      background-color: #000;
      padding: 100px 0px;

      ._GB_support_Inner {
        h2 {
          font-family: "Gilroy-Bold";
          font-size: 48px;
          line-height: 90px;
          color: #ffffff;

          @media only screen and (max-width: 767px) {
            line-height: 44px;
          }

          @media only screen and (max-width: 990px) {
            font-size: 40px;
          }

          @media only screen and (max-width: 480px) {
            font-size: 35px;
          }
        }

        .support_Bg {
          padding-top: 81px;

          @media only screen and (max-width: 767px) {
            padding-top: 41px;
          }

          .full_section {
            .support_details {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              margin-bottom: 40px;

              a {
                text-decoration: none;
              }

              @media only screen and (max-width: 1365px) {
                flex: 0 0 auto;
                width: 40%;
                margin-right: 30px;

                &:nth-child(2n + 2) {
                  margin-right: 0px !important;
                }
              }

              @media only screen and (max-width: 767px) {
                flex: 0 0 auto;
                width: 100%;
                margin-right: 0px !important;
                justify-content: center;
              }

              &:nth-last-child(-n + 2) {
                @media only screen and (min-width: 1200px) {
                  // margin-bottom: 0px;
                }
              }

              .items_inner {
                border: 2px solid #292929;
                box-sizing: border-box;
                border-radius: 25px;
                padding: 10px;
                max-width: 310px;
                width: 100%;
                display: flex;
                    flex-direction: column;
                      justify-content: space-around;
                &:hover {
                  border: 2px solid #18a0fb;
                }

                .topContent {
                  img {
                    border-radius: 15px;
                    height: 180px;
                    width: 100%;
                  }
                }

                .bottomContent {
                  padding: 20px 32px 30px 30px;

                  .title {
                    font-family: "Gilroy-SemiBold";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 34px;
                    letter-spacing: -0.01em;
                    color: #ffffff;
                  }

                  .sub_text {
                    font-family: "Gilroy-Medium";
                    font-size: 16px;
                    line-height: 22px;
                    color: #ffffff;
                    opacity: 0.5;
                  }
                }
              }
            }
          }

          ._Button_group {
            padding-top: 20px;

            @media only screen and (max-width: 1024px) {
              padding-top: 0px;
              margin-top: 0px;
            }

            ._GB_About_Btn {
              max-width: 403px;
              color: #fff;
              background-color: #e52420;
              background-image: linear-gradient(to right,
                  #6d1513,
                  #e52420,
                  #6d1513);
              border-radius: 10px;
              border: 2px solid #ffffff;
              font-family: "Poppins-SemiBold";
              font-size: 17px;
              line-height: 17px;
              text-transform: uppercase;
              letter-spacing: 0.04em;
              padding: 18px 35px;
              text-decoration: none;
              outline: none;
            }
          }
        }
      }
    }
  }
}

.GB_countdown {
  display: flex;
  align-items: center;

  @media only screen and (max-width: 767px) {
    padding-bottom: 10px;
  }

  .remaining-note {
    color: #fff;
    font-size: 16px;
    font-family: "Gilroy-Bold";
    cursor: pointer;
  }

  .countdown {
    display: none;
    padding-left: 20px;

    &.show {
      display: block;
    }

    &.hide {
      display: none;
    }

    span {
      color: #fff;
      font-size: 16px;
      font-family: "Gilroy-Bold";
    }
  }
}

.timer_popover {
  background: #181818;
  border: 2px solid #454545 !important;
  border-radius: 15px;
  top: 18%;
  left: 35%;

  .popover-arrow {
    left: 110px;
    position: absolute;

    &::before {
      content: "";
      background-image: url("../images/arrow-black.png");
      top: 34px;
      right: -125px;
      background-repeat: no-repeat;
      border-width: 11px;
      border-right: none;
    }

    &::after {
      display: none !important;
    }
  }

  .popover-body {
    color: #fff;
    text-align: center;
    font-family: "Gilroy-Bold";
  }
}

.noleaderdataa {
  font-family: "Gilroy-Medium";
  font-size: 15px;
  max-width: 356px;
  margin-top: 20px;
  color: #fff;
  line-height: 25px;
}

p._LeftText {
  padding-top: 5px;
  font-size: 14px !important;
}

.goldTimer {
  color: #ffc01f !important;
}

.syncwait {
  font-family: "Gilroy-Medium";
  font-size: 16px;
  color: #fff;
  padding-left: 15px;
}

.verify-message {
  font-size: 16px;
  padding-left: 5px;

  &.verified {
    color: #27ff18;
  }

  &.requested {
    color: #ffc01f;
  }
}

.verify-message-admin {
  font-size: 18px;
  padding-left: 5px;

  &.verified {
    color: #27ff18;
  }

  &.requested {
    color: #ffc01f;
  }
}

.steamerrorMsg {
  font-family: "Gilroy-Bold";
  font-size: 16px;
  padding-left: 5px;
  color: red;
}

.playerGame {
  text-transform: uppercase;
}

.days.game_drop {
  cursor: pointer;
}

.accordion-button button {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  color: #fff;
}

.vfs-label {
  font-family: "Gilroy-Bold";
  color: #fff;
  margin-right: 15px;
}

.verify-gamertag-section {
  .top-section {
    justify-content: flex-start !important;
  }
}

// body {
//   &::-webkit-scrollbar {
//     width: 5px;
//   }

//   /* Track */
//   &::-webkit-scrollbar-track {
//     background: #181818;
//   }

//   /* Handle */
//   &::-webkit-scrollbar-thumb {
//     background: #e74c3c;
//   }

//   /* Handle on hover */
//   &::-webkit-scrollbar-thumb:hover {
//     background: #ffc01f;
//   }
// }

.gamemode-list-inner {
  max-height: 250px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #181818;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e74c3c;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #ffc01f;
  }

  .dropdown-item {
    text-transform: uppercase;
  }
}

button#dropdown-gmode-button {
  text-transform: uppercase;
}

.privateStatus {
  padding: 20px;
  font-size: 16px;

  a {
    color: #c80000;
    font-size: 16px;
    padding-left: 5px;
    font-weight: bold;
  }
}

.register_gb_logo img,
#_GB_LogoSection img {
  max-width: 200px;

  @media only screen and (min-width: 768px) {
    max-width: 160px;
  }

  @media only screen and (min-width: 1600px) {
    max-width: 170px;
  }

  @media only screen and (min-width: 1700px) {
    max-width: 200px;
  }
}

._GB_AvailableGames {
  background-color: #000;
  padding: 50px 0 0;

  @media only screen and(max-width: 767px) {
    padding: 50px 0 0;
  }

  @media only screen and(max-width: 575px) {
    padding: 60px 0 0;
  }

  ._GB_available_Inner {
    .available_top {
      @media only screen and(max-width: 991px) {
        flex-direction: column;
      }

      .top_left {
        h2 {
          font-family: "Poppins-Bold";
          font-size: 58px;
          line-height: 84px;
          color: #ffffff;

          @media only screen and(max-width: 767px) {
            font-size: 28px;
            line-height: 38px;
            margin-bottom: 30px;
          }
        }
      }

      .top_right {

        // @media only screen and(max-width: 574px) {
        //   flex-direction: column;
        // }
        .desktop_btn {
          height: 56px;
          max-width: 550px;
          border: 2px solid #484848;
          border-radius: 10px;
          padding: 17px 70px;
          text-decoration: none;
          color: #fff;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          margin-right: 15px;
          cursor: pointer;

          &.activeBtn {
            background: rgba(200, 0, 0, 0.5);
            border-color: #c80000;
          }

          @media only screen and(max-width: 574px) {
            margin-right: 10px;
            padding: 17px 30px;
          }
        }

        .mobile_btn {
          height: 56px;
          max-width: 550px;
          border: 2px solid #484848;
          border-radius: 10px;
          padding: 17px 70px;
          text-decoration: none;
          color: #fff;
          font-family: "Poppins-SemiBold";
          font-size: 17px;
          line-height: 17px;
          text-transform: uppercase;
          letter-spacing: 0.04em;
          cursor: auto;

          @media only screen and(max-width: 574px) {
            padding: 17px 30px;
          }
        }
      }
    }

    .available_Bg {
      padding-top: 50px;

      .full_section {
        .available_details {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          // margin-bottom: 40px;
          margin: 20px auto;
          // @media only screen and (min-width: 1024px) and (max-width: 1200px) {
          //   margin-right: 20px !important;

          //   &:last-child {
          //     margin-right: 0px !important;
          //   }
          // }

          @media only screen and (min-width: 1025px) and (max-width: 1399px) {
            width: 24%;
          }

          @media only screen and (max-width: 1024px) {
            flex: 0 0 auto;
            width: 32%;
            margin: 20px auto;
          }

          @media only screen and (max-width: 767px) {
            flex: 0 0 auto;
            width: 46%;
            justify-content: center;
            margin: 10px auto;
          }

          &:nth-last-child(-n + 2) {
            @media only screen and (min-width: 1200px) {
              // margin-bottom: 0px;
            }
          }

          img {
            &:hover {
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
}

._GB_TopEarners {
  background-color: #000;
  padding: 100px 0;

  @media only screen and(max-width: 767px) {
    padding: 50px 0;
  }

  @media only screen and(max-width: 575px) {
    padding: 35px 0;
  }

  ._GB_topEarners_Banner {
    ._GB_TopEarners_Inner {
      .top_sec {
        .top_left {
          h2 {
            font-family: "Gilroy-Bold";
            font-size: 48px;
            line-height: 90px;
            color: #ffffff;

            @media only screen and(max-width: 574px) {
              font-size: 28px;
              line-height: 38px;
              width: 100%;
              text-align: center;
            }
          }

          .leaderbord-link {
            text-decoration: none;
            font-family: "Gilroy-Bold";
            font-size: 22px;
            line-height: 20px;
            color: #fff;

            @media only screen and(max-width: 767px) {
              font-size: 16px;
              line-height: 18px;
            }

            &:hover {
              color: #83d7ff;
            }
          }

          @media only screen and(max-width: 991px) {
            flex-direction: column;
          }

          .visiblity_sensor {
            width: 220px;
            display: inline-block;

            @media only screen and(max-width: 1365px) {
              width: 100%;
            }
          }
        }
      }

      .TopEarners_Bg {
        padding-top: 50px;

        .full_section {
          .earner-item {
            // padding: 0 15px;
            padding: 0;
            max-width: 100%;
            flex: 0 0 100%;
            display: flex;
            justify-content: center;

            @media only screen and (max-width: 1365px) {
              flex: 0 0 29%;
            }

            .TopEarners_details {
              background-color: #181818;
              border-radius: 20px;
              padding: 5px 0 20px;
              width: 225px;
              border: 1px solid #2d2d2d;

              @media only screen and (min-width: 1366px) {
                // max-width: 225px;
                // width: auto;
                max-width: 100%;
                width: 100%;
                margin: 16px;
              }

              @media only screen and (max-width: 1365px) {
                margin: 20px;
              }

              @media only screen and (max-width: 767px) {
                padding: 0px 5px 15px 5px;
                width: 156px;
                margin: 15px;
              }

              @media only screen and (min-width: 375px) {
                // margin: auto auto 10px;
                flex-direction: column;
                text-align: center;
              }

              @media only screen and (max-width: 374px) {
                width: 225px;
              }

              .items_inner {
                text-align: center;
                padding: 20px 25px;
                border-radius: 10px;

                @media only screen and (max-width: 767px) {
                  padding: 0px 0px;
                }

                @media only screen and (max-width: 374px) {
                  padding: 15px 15px;
                }

                img {
                  border-radius: 20px;
                  height: 178px;
                  width: 100%;
                  object-fit: cover;

                  // @media only screen and (min-width: 768px) {
                  //   object-fit: cover;
                  // }
                  @media only screen and (max-width: 767px) {
                    height: 130px;
                  }

                  @media only screen and (min-width: 1366px) {
                    width: auto;
                  }
                }
              }

              .player_details {
                padding: 0 30px;

                @media only screen and (max-width: 767px) {
                  padding: 15px 5px;
                }

                @media only screen and (max-width: 374px) {
                  padding: 15px 15px;
                }

                .player_name {
                  font-family: "Gilroy-Bold";
                  font-size: 20px;
                  line-height: 23.44px;
                  color: #ffffff;
                  margin: 0px 0 14px 0;
                  height: 24px;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;

                  @media only screen and (max-width: 767px) {
                    text-align: center;
                  }
                }

                .total_earns {
                  font-family: "Gilroy-Bold";
                  font-size: 16px;
                  line-height: 18px;
                  color: #ffffff;

                  @media only screen and (max-width: 767px) {
                    justify-content: space-between;
                  }

                  .earn_coin {
                    img {
                      margin-right: 5px;
                    }
                  }

                  .coin_val {
                    opacity: 1;
                    line-height: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.verifyEmailOTPTitle {
  font-family: "Gilroy-Bold";
  font-size: 22px;
  line-height: 27px;
  color: #fff;
  padding-top: 15px;
}

.verifyEmailOTPInfo {
  font-family: "Gilroy-Medium";
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  padding: 15px 0;
}

.nodatFromAPI {
  font-family: "Gilroy-bold";
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  padding: 15px 0;
  text-align: center;
  width: 100%;
}

.apiloader-section {
  min-height: 200px;
  width: 100%;
}

.GbplayerCol.geimg img {
  border-radius: 0 !important;
  object-fit: contain !important;
  border: none !important;
}

.lge-images {
  padding: 5px;
  width: 45px;
}

.players-list-section table .games-list .game-name .text {
  cursor: pointer;
}

.user-saved-country img {
  border: none !important;
  width: 55px;
  height: 55px;
}

.usergamematches {
  text-align: left;
  font-size: 13px;
  display: block;
  font-family: "Gilroy-Bold";
  text-transform: none;
  color: #52dd21;
  padding-top: 5px;

  &.gold-text {
    color: #ffc01f !important;
  }
}

.usermatchdetailswrap {
  text-align: left !important;
}

.gb-verify-btn {
  background-color: #e52420;
  background-image: linear-gradient(to right, #6d1513, #e52420, #6d1513);
  border: 2px solid #fff !important;
  border-radius: 5px;
  padding: 5px 10px;
  text-decoration: none;
  color: #fff;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
  line-height: 17px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  display: inline-block;
}

.set-VGS img {
  width: 30px;
}

.referralURL {
  color: #e52420 !important;
  font-size: 15px !important;
  font-family: "Gilroy-Bold" !important;
  padding: 5px 0;
}

.refdetails {
  color: gold;
  font-family: "Gilroy-Bold";
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  padding: 15px;
}

._GB_affiliate {
  .affPopup {
    display: flex;
    margin-top: 20px;
    font-family: "Gilroy-Bold";
    font-size: 16px;
    line-height: 28px;
    color: rgba(235, 235, 245, 0.6);
  }

  ._aff_quest {
    font-size: 22px;
    padding-left: 15px;
    cursor: pointer;
    color: gold;
  }

  .aff_top_section {
    display: flex;
    margin-bottom: 30px;

    @media only screen and (max-width: 1359px) {
      flex-wrap: wrap;
    }

    .aff_top_item {
      &.aff_customURL {
        @media only screen and (max-width: 1359px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 30px;
        }
      }

      &.aff_invites {
        margin-right: 30px;
        margin-left: 30px;

        @media only screen and (min-width: 768px) and (max-width: 1359px) {
          flex: 0 0 48%;
          max-width: 48%;
          margin-right: 2%;
          margin-left: 0;
        }

        @media only screen and (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 30px;
          margin-left: 0;
          margin-right: 0;
        }
      }

      &.aff_earned {
        @media only screen and (min-width: 768px) and (max-width: 1359px) {
          flex: 0 0 48%;
          max-width: 48%;
          margin-left: 2%;
          margin-right: 0;
        }

        @media only screen and (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 30px;
          margin-left: 0;
          margin-right: 0;
        }
      }

      .aff_top_inner {
        background: #181818;
        border: 2px solid #2d2d2d;
        box-sizing: border-box;
        border-radius: 20px;
        padding: 20px;

        @media only screen and (min-width: 1360px) {
          min-height: 230px;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;

        .aff_copy_save {
          display: block;

          @media only screen and (min-width: 768px) {
            display: flex;
            flex-wrap: wrap;
          }

          .aff_clip_wrap {
                flex: 0 0 75%;
                  max-width: 75%;
            background: #000 !important;
            border: 2px solid #2d2d2d;
            border-radius: 15px;
            height: 56px;
            width: 100%;
            display: flex;
            align-items: center;

            @media only screen and (max-width: 767px) {
              max-width: 100%;
            }

            input {
              background: none;
              border: none;
              box-shadow: none;
              color: #fff !important;
              font-family: "Gilroy-Bold";
              font-size: 17px;
              line-height: 19px;
            }

            .cpy-img {
              padding: 0 20px;

              img {
                cursor: pointer;
              }
            }
          }

          .aff_copysave {
            flex: 0 0 18%;
            max-width: 18%;
            margin-left: 2%;
            height: 56px;
            padding: 0px;

            &::after {
              display: none;
            }

            @media only screen and (max-width: 767px) {
              max-width: 100%;
              padding: 10px 20px;
              margin-top: 10px;
              margin-left: 0;
            }
          }
        }

        label {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 20px;
          line-height: 32px;
        }

        .aff_bold {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 50px;
          line-height: 55px;
        }

        p {
          color: #fff;
          font-family: "Gilroy-Bold";
          font-size: 16px;
          line-height: 28px;
          opacity: 0.5;
        }

        select {
          background-color: #050505;
          border-radius: 10px;
          border-color: #050505;
          color: #fff;
          min-width: 150px;
          padding: 10px 15px;
          width: 100%;
          cursor: pointer;
        }

        .successMsg {
          color: #40ab00;
          font-family: "Gilroy-Bold";
          font-size: 17px;
          line-height: 19px;
          padding: 10px 0;
          text-align: left;
        }

        .errorMsg {
          color: red;
          font-family: "Gilroy-Bold";
          font-size: 17px;
          line-height: 19px;
          padding: 10px 0;
          text-align: left;
        }
      }
    }
  }

  .aff_th {
    text-align: left !important;
    padding: 15px 20px !important;
  }

  .aff_games {
    .aff-img {
      width: 35px;
      margin-right: 8px;
    }
  }

  .paidnote {
    background: #00a674;
    border-radius: 15px;
    padding: 7px 15px 7px;
    display: inline-block;
    line-height: 15px;
    margin-left: 10px;
    font-size: 15px;
    width: 120px;
    text-align: center;
    text-transform: uppercase;

    &.not-paid {
      background: #c80000;
    }
  }
}

.customAField {
  cursor: pointer;
  font-size: 16px;
  padding: 10px;

  .customPrice {
    color: #00a674;
  }

  .paidStatus {
    color: gold;
    font-size: 24px;
  }
}

.adminmdl {
  max-width: 80% !important;

  .modal-content {
    color: #fff;
    font-family: "Gilroy-Bold";

    .form-item {
      label {
        color: gold;
      }
    }
  }
}

.custprice-btn {
  margin-left: 10px;
}

._GB_affiliate_admin {
  background: none !important;
}

._managePaid {
  .paidnote {
    margin-left: 0;
    margin-right: 15px;
  }
}

.showingcount {
  color: #fff !important;
  font-family: "Gilroy-Bold";
  font-size: 15px;
  margin-bottom: 5px;
  opacity: 0.5;
}

.aff-btn-area {
  margin-top: 25px;
  text-align: center;
}

.Leaderboard_details {
  .apiloader-section {
    background: #121212;
    border: 2px solid #2d2d2d;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.leaderboard-count .title {
  color: #fff !important;
  margin: 15px 0;
}

.leaderboardall-count .title {
  color: #fff !important;
}

._GB_Dashboard {
  .container {
    // @media only screen and (min-width: 1300px) and (max-width: 1440px) {
    //   max-width: 1260px !important;
    // }
  }

  ._GB_Dashboard_Banner {
    @media only screen and (min-width: 1366px) and (max-width: 1440px) {
      padding: 10px 0px !important;
    }
  }
}

._GB_DashboardBeta {
  ._GB_Dashboard_BannerBeta {
    ._GB_Dashboard_Inner {
      ._Dashboard_Fortnite_Stats {
        padding: 50px 0 20px !important;

        @media only screen and (min-width: 1366px) and (max-width: 1600px) {
          padding: 20px 0px 20px !important;
        }

        @media only screen and (max-width: 767px) {
          padding: 20px 0 0px !important;
        }
      }
    }

    ._beta_banner_top {
      @media only screen and (min-width: 1360px) {
        justify-content: space-between;
      }

      .profile_image {
        img {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            height: 75px !important;
            width: 75px !important;
          }
        }
      }

      ._Title {
        @media only screen and (min-width: 1024px) and (max-width: 1600px) {
          font-size: 35px !important;
        }
      }

      ._beta_changegame {
        ._Game_Stats_top {
          ._Title {
            display: none;
          }
        }

        .change_game {
          .change {
            @media only screen and (max-width: 767px) {
              display: none;
            }
          }

          .games_list {
            img {
              @media only screen and (min-width: 1024px) and (max-width: 1600px) {
                height: 55px !important;
                width: 55px !important;
              }
            }
          }
        }
      }
    }

    .game_details {
      .items_inner {
        @media only screen and (min-width: 1024px) and (max-width: 1600px) {
          min-height: 250px !important;
        }

        .desc-sect {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            min-height: 175px !important;
          }

          img {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              height: 45px !important;
            }
          }

          .title {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              font-size: 16px !important;
            }
          }

          .sub_content {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              font-size: 20px !important;
            }
          }

          .gameplaytime {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              font-size: 16px !important;
            }
          }

          .token_dollar {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              font-size: 16px !important;
            }
          }
        }

        .game_drop {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            font-size: 13px !important;
          }
        }
      }
    }

    padding-bottom: 20px;

    @media only screen and (min-width: 1366px) {
      padding-bottom: 0;
    }

    ._betawrap_session_boost {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 768px) {
        align-items: start;
        flex-direction: row;
      }

      .today_session {
        flex: 0 0 46%;
        max-width: 46%;
        margin-right: 2%;

        @media only screen and (min-width: 1024px) and (max-width: 1600px) {
          padding: 15px !important;
        }

        @media only screen and (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 20px;
        }

        .title_left {
          font-size: 16px !important;
        }

        .title_right {
          p {
            font-size: 16px !important;
          }
        }

        .second-section {
          img {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              height: 35px !important;
            }
          }

          h3 {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              font-size: 25px !important;
            }
          }

          .token_dollar {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              font-size: 16px !important;
            }
          }
        }
      }

      .reward_session {
        flex: 0 0 52%;
        max-width: 52%;

        @media only screen and (min-width: 1024px) and (max-width: 1600px) {
          padding: 15px !important;
        }

        @media only screen and (min-width: 1024px) and (max-width: 1600px) {
          min-height: 142px !important;
        }

        @media only screen and (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        ._title {
          font-size: 16px !important;
        }

        .token-section {
          p {
            margin-left: 0px !important;

            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              font-size: 14px !important;
            }

            @media only screen and (min-width: 1601px) {
              font-size: 16px !important;
            }
          }

          img {
            @media only screen and (min-width: 1024px) and (max-width: 1600px) {
              height: 35px !important;
            }
          }
        }
      }
    }

    ._beta_gamemode {
      .most_played {
        img {
          height: 450px !important;
          object-fit: cover !important;
          object-position: top;

          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            height: 335px !important;
          }

          @media only screen and (min-width: 1601px) {
            height: 400px !important;
          }

          @media only screen and (max-width: 767px) {
            height: 350px !important;
          }
        }

        #dropdown-gmode-button {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            font-size: 13px !important;
          }
        }

        a {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            font-size: 13px !important;
          }
        }

        .game_title {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            font-size: 40px !important;
          }
        }
      }

      .dashboard_rewards {
        .reward_head {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            font-size: 14px !important;
          }
        }

        .reward_score {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            font-size: 13px !important;
          }
        }

        .rewards_image {
          @media only screen and (min-width: 1024px) and (max-width: 1600px) {
            width: 40px !important;
          }
        }
      }
    }
  }

  ._GameStatsBeta {
    padding-top: 20px;

    @media only screen and (min-width: 1366px) {
      padding-top: 0;
    }

    ._beta_leader_wrap {
      .rank_section {
        @media only screen and (min-width: 480px) {
          flex: 0 0 33% !important;
          max-width: 33% !important;
        }

        @media only screen and (min-width: 768px) {
          flex: 0 0 25% !important;
          max-width: 25% !important;
        }

        @media only screen and (min-width: 1024px) {
          flex: 0 0 20% !important;
          max-width: 20% !important;
        }

        @media only screen and (min-width: 1360px) {
          flex: auto !important;
          max-width: 10% !important;
        }

        .rank_section_inner {
          padding: 5px !important;

          .rank {
            top: 5px !important;
            left: 5px !important;
          }
        }
      }
    }
  }

  ._Dashboard_Fortnite_Stats {
    @media only screen and (max-width: 767px) {
      padding: 20px 0 0;
    }
  }

  ._GameStatsBeta {
    @media only screen and (max-width: 767px) {
      padding: 0 0 20px;
    }
  }
}

._addicon {
  padding: 10px !important;
  background: #000000;
  border: 2px solid #484848;
  border-radius: 6px;
  cursor: pointer;
  height: 50px;
  width: 50px;

  @media only screen and (min-width: 1024px) and (max-width: 1600px) {
    height: 55px !important;
    width: 55px !important;
  }

  @media only screen and (min-width: 1601px) {
    height: 65px !important;
    width: 65px !important;
  }
}

.refershtimer {
  margin-right: 8px;
  width: 45px;
  text-align: right;
  font-size: 15px !important;
}

.refreshloader .spinner-border {
  width: 1em;
  height: 1em;
  border-style: dotted;
  color: #ffc01f !important;
}

#refresh-tooltip {
  font-family: "Gilroy-Regular";
  font-size: 13px;
  z-index: 9999 !important;

  .tooltip-inner {
    background-color: rgba(0, 0, 0, 0.8);
    max-width: 100%;
    padding: 10px 20px;
  }
}

._boostQwrap {
  justify-content: space-between;

  ._booost_quest {
    color: #ddd;
    font-family: "Gilroy-Bold";
    font-size: 18px;
    cursor: pointer;
  }
}

.boost_tooltip .tooltip-inner {
  @media only screen and (min-width: 320px) {
    max-width: 300px !important;
  }

  @media only screen and (min-width: 768px) {
    max-width: 350px !important;
  }
}

.p2e_tooltip .tooltip-inner {
  font-family: "Gilroy-regular";
  color: #ffffff80;
  font-size: 14px !important;
  line-height: 17px;

  @media only screen and (min-width: 320px) {
    max-width: 200px !important;
  }

  @media only screen and (min-width: 768px) {
    max-width: 230px !important;
  }
}

.total {
  @media only screen and (min-width: 768px) and (max-width: 1299px) {
    flex: 0 0 30%;
    max-width: 30%;
    margin: 1% !important;
  }
}

.refreshImgWrap {

  //z-index: 9999 !important;
  .refrimg {
    opacity: 1 !important;
    // z-index: 9999 !important;
  }
}

.addByCWS {
  margin-top: 10px;

  .orCWS {
    font-family: "Poppins-Medium";
    color: #fff;
    font-size: 16px;
  }

  .connect_steam {
    background: #262627;
    max-width: 280px;
    padding: 10px 10px;
    border-radius: 15px;
    margin: auto;
    margin-top: 10px;
    font-family: "Poppins-Medium";
    color: #fff;
    font-size: 16px;
    cursor: pointer;

    .connect_type {
      padding-left: 10px;
    }
  }
}

.tokenAddress {
  padding: 0 15px;

  a {
    color: #fff;
    text-decoration: none;
    word-break: break-all;
  }
}

.currency-wallet-main {
  .show-wallet {
    display: block;
  }

  .curr-wallet-coins-none {
    margin-bottom: 10px;
  }

  #dropdown-curr-wallet {
    background: none;
    padding: 0;
    border: none;
    margin-left: 10px;
    box-shadow: none;

    &::after {
      display: none;
    }

    img {
      width: 15px;
    }
  }

  .dropdown-menu {
    width: 398px;
    margin-top: 15px;

    .curr-wallet-wrap {
      .curr-wallet-option {
        display: flex;

        .curr-wallet-btn {
          background: #262627;
          flex: 0 0 48%;
          max-width: 48%;
          margin: 1%;
          padding: 15px 20px;
          border-radius: 10px;
          color: #fff;
          border: 1.5px solid #262627;

          &:hover {
            border: 1.5px solid #e52420;
            filter: drop-shadow(0px 0px 4px #e52420);
            background: #000000;
          }

          &.active {
            border: 1.5px solid #e52420;
            filter: drop-shadow(0px 0px 4px #e52420);
            background: #000000;
          }
        }
      }

      .curr-wallet-coins {
        background: #121212;
        padding: 10px 10px;
        margin: 15px 0;
        border-radius: 10px;

        .curr-wallet-coins-inner {
          &.needScroll {
            height: 102px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #181818;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #e74c3c;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #ffc01f;
            }
          }

          .curr-wallet-coins-items {
            margin: 16px 20px;

            &.no-rep {
              justify-content: center;
              height: 60px;
            }

            .item-token {
              .coin-img {
                width: 26px;
                margin-right: 10px;
              }

              .coin-amount {
                color: #fff;
              }
            }

            .item-token-name {
              color: rgba(235, 235, 245, 0.6);
            }

            .item-token-no-rep {
              color: #fff;
            }
          }
        }
      }

      .curr-wallet-links {
        .curr-wallet-links-items {
          background: #000000;
          margin-bottom: 6px;
          padding: 12px 20px;
          border-radius: 10px;

          .item-text {
            color: #fff;
          }

          .item-img {
            img {
              width: 31px;
              height: 31px;
            }
          }
        }
      }
    }
  }
}

.wallet-main-menu {
  margin: 0 0 15px;
  padding: 10px 30px;
  border-bottom: 2px solid #454545 !important;

  @media only screen and (max-width: 400px) {
    padding: 10px 0px;
  }

  .wallet-items-wrap {
    width: 100%;

    .wallet-items {
      .wallet-items-inner {
        background: #262627;
        padding: 10px;
        margin: 10px;
        border-radius: 10px;
        border: 1.5px solid #262627;
        cursor: pointer;

        @media only screen and (max-width: 400px) {
          margin: 7px;
          padding: 7px;
        }

        img {
          width: 31px;
          height: 31px;
          object-fit: scale-down;
        }

        .wallet-items-text {
          color: #fff;
          font-family: "Poppins-Medium";
          font-size: 13px;

          @media only screen and (min-width: 1920px) {
            font-size: 16px;
          }

          margin-top: 10px;
        }
      }

      &.active {
        .wallet-items-inner {
          border: 1.5px solid #e52420;
          filter: drop-shadow(0px 0px 4px #e52420);
          background: #000000;
        }
      }
    }
  }
}

.wallet-swap-inner {
  .title {
    font-size: 30px !important;
    color: #fff;
    font-family: "Poppins-SemiBold" !important;
    text-align: center;

    @media only screen and (min-width: 1920px) {
      font-size: 40px !important;
    }

    @media only screen and (max-width: 767px) {
      font-size: 26px !important;
      line-height: 32px !important;
    }
  }

  .swap-type-buttons {
    margin-bottom: 20px;

    @media only screen and (max-width: 575px) {
      margin-bottom: 15px;
    }

    .swap-type-btn {
      background: #262627;
      margin: 2%;
      padding: 15px 20px;
      border-radius: 10px;
      color: #fff;
      border: 1.5px solid #262627;
      flex: 0 0 40%;
      max-width: 40%;
      font-family: "Gilroy-Bold";

      @media only screen and (max-width: 450px) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        flex: 0 0 48%;
        max-width: 48%;
        padding: 15px 5px;
        font-size: 15px;
      }

      &:hover {
        border: 1.5px solid #e52420;
        filter: drop-shadow(0px 0px 4px #e52420);
        background: #000000;
      }

      &.active {
        border: 1.5px solid #e52420;
        filter: drop-shadow(0px 0px 4px #e52420);
        background: #000000;
      }
    }
  }

  .fee-in-swap {
    position: absolute;
    right: 0px;
    margin-top: 50px;
  }

  .swap-pay-wrap {
    margin: 15px 15px;
  }

  .swap-img-section {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      border-top: 2px solid #262627;
      width: 45%;
      left: 15px;
      top: 25px;

      @media only screen and (max-width: 374px) {
        width: 38%;
        top: 20px;
      }
    }

    &:after {
      content: "";
      position: absolute;
      border-top: 2px solid #262627;
      width: 45%;
      right: 15px;
      top: 25px;

      @media only screen and (max-width: 767px) {
        width: 42%;
      }

      @media only screen and (max-width: 374px) {
        width: 38%;
        top: 20px;
      }
    }

    .swaper {
      width: 50px;
      height: 50px;
      background: #262627;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin: auto;
      cursor: pointer;
      z-index: 99;

      @media only screen and (max-width: 374px) {
        width: 38px;
        height: 38px;
      }
    }
  }

  .swap-receive-wrap {
    margin: 15px 15px;
  }

  .swap-left {
    max-width: 48%;
    flex: 0 0 48%;
    margin: auto;

    .pay-text-sm {
      font-family: "Gilroy-Bold";
      font-size: 20px;
      color: #fff;
    }

    .pay-text-lg {
      input {
        width: 300px;
        border: none;
        outline: none;
        color: #fff;
        background: none;
        font-family: "Gilroy-Bold";
        font-size: 30px;
        color: #fff;
        box-shadow: none;
      }
    }
  }

  .swap-right {
    max-width: 48%;
    flex: 0 0 48%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: end;
    flex-direction: column;

    .swap-max-text {
      cursor: pointer;
      font-family: "Gilroy-Bold";
      color: rgba(235, 235, 245, 0.6);
    }

    #swap-dropdown {
      background: none;
      padding: 0;
      border: none;
      margin-left: 10px;
      box-shadow: none;
      width: 150px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #262627;
      margin: 10px 0;
      border-radius: 10px;
      border: 1.5px solid #262627;
      padding: 13px 10px;

      @media only screen and (max-width: 374px) {
        width: 118px;
        height: 40px;
      }

      &::after {
        display: none;
      }

      .item-token {
        .coin-img {
          width: 26px;
          margin-right: 10px;
        }

        .coin-name {
          color: #fff;
          font-family: "Gilroy-Bold";
        }
      }
    }

    .item-token-list {
      margin: 5px 10px;

      .coin-img {
        width: 26px;
        margin-right: 10px;
      }

      .coin-name {
        color: #fff;
        font-family: "Gilroy-Bold";
      }
    }
  }

  .mb-5 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.on-btn {
      justify-content: space-between;
    }

    @media only screen and (max-width: 400px) {
      flex-direction: column;
      justify-content: center;
      margin-bottom: 15px !important;
    }
  }

  .swapcont-btnwrap {
    text-align: center;
    padding: 0 50px;
    margin-bottom: 10px;

    button {
      max-width: 230px;
    }
  }
}

._GB_Cron .crontestbanner {
  height: 600px !important;
}

.common-currency-popup {
  .modal-content {
    padding: 20px !important;
    border-radius: 15px !important;

    @media only screen and (max-width: 767px) {
      padding: 10px !important;
    }

    .modal-body {
      padding: 0 !important;
      min-height: 500px;

      &.nomh {
        min-height: 100px;
      }
    }
  }
}

.swap-review-wrap {
  margin-bottom: 30px !important;
}

.reviewWrap {
  display: flex;
  margin: 5px 10px 10px;

  .swap-left {
    flex: 0 0 50%;
    max-width: 50%;

    .pay-text-sm {
      font-size: 22px;
    }
  }

  .swap-right {
    flex: 0 0 50%;
    max-width: 50%;

    .review-coins {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .coin-img {
        width: 26px;
        margin-right: 10px;
      }

      .coin-name {
        color: #fff;
        font-family: "Gilroy-Bold";
        font-size: 22px;

        &.red {
          color: #ff0000;
        }

        &.green {
          color: #40ab00;
        }
      }
    }
  }
}

.errorMsg p {
  margin-bottom: 10px;
}

.reward_popup_modal .modal-content {
  padding: 10px 15px;
}

.ad_withd {
  text-align: left !important;
  padding: 15px !important;
}

.emailAdmin {
  font-size: 15px;
  display: block;
  padding: 10px 0;
  color: rgba(235, 235, 245, 0.6);
}

.tokentext {
  color: gold;
  font-family: "Poppins-Bold";

  img {
    margin-right: 6px;
  }
}

.adminName {
  align-items: unset !important;
}

.transfer-type-text {
  color: #fff;
  font-family: "Gilroy-Bold";
  font-size: 16px;
  padding: 0 10px;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.withdraw-type-wrap {
  width: 100%;
  gap: 10px;

  @media only screen and (max-width: 374px) {
    flex-wrap: wrap;
  }

  .withdraw-input {
    flex: 0 0 32%;
    max-width: 32%;

    @media only screen and (max-width: 374px) {
      flex: 0 0 45%;
      max-width: 45%;
    }

    .withdraw-input-inner {
      padding: 5px;

      input {
        background: #000000;
        border: none;
        border-radius: 15px;
        height: 56px;
        color: #fe5242;
        padding: 10px;
        font-size: 40px;
        font-family: "Poppins-SemiBold" !important;
        text-align: center;
        outline: none;
        box-shadow: none;

        @media only screen and (max-width: 374px) {
          height: 50px;
        }
      }
    }
  }

  .withdraw-select {
    flex: 0 0 30%;
    max-width: 30%;

    @media only screen and (max-width: 374px) {
      flex: 0 0 45%;
      max-width: 45%;
    }

    #withdraw-dropdown {
      background: none;
      padding: 0;
      border: none;
      margin-left: 10px;
      box-shadow: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #262627;
      border: 1.5px solid #262627;
      margin: 10px 0;
      border-radius: 10px;
      padding: 13px 10px;

      @media only screen and (max-width: 374px) {
        padding: 10px;
        margin: 0;
      }

      &::after {
        display: none;
      }

      .item-token {
        .coin-img {
          width: 26px;
          margin-right: 10px;
        }

        .coin-name {
          color: #fff;
          font-family: "Gilroy-Bold";
        }
      }
    }

    .item-token-list {
      margin: 5px 10px;

      .coin-img {
        width: 26px;
        margin-right: 10px;
      }

      .coin-name {
        color: #fff;
        font-family: "Gilroy-Bold";
      }
    }
  }

  .withdraw-usd {
    flex: 0 0 35%;
    max-width: 35%;
    // padding: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      img {
        width: 36px;
        padding-bottom: 5px;
      }
    }

    @media only screen and (max-width: 374px) {
      flex: 0 0 45%;
      max-width: 45%;
    }

    .withdraw-usd-value {
      padding-left: 10px;
      font-family: "Poppins-SemiBold";
      color: rgba(235, 235, 245, 0.6);

      @media only screen and (max-width: 767px) {
        font-size: 13px;
      }
    }
  }
}

.withdraw-transfer-sendall {
  button {
    display: block !important;
    width: 150px;
    margin: 10px auto 15px;
    font-family: "Poppins-SemiBold";
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    border-radius: 10px;
    padding: 16px 0px 16px 25px;
    background: url(../images/white_arrow.png) no-repeat 16% center;
    cursor: pointer;
    background-color: #141414;
    border: 2px solid #292929;
    border-radius: 10px;

    &:hover {
      opacity: 0.5;
    }

    @media only screen and (max-width: 767px) {
      padding: 10px 0 10px 30px;
      font-size: 14px;
      line-height: 14px;
      width: 140px;
    }
  }
}

.withdraw-deposit-swap {
  margin: 20px;

  @media only screen and (max-width: 767px) {
    margin: 0px;
  }

  .fromto-swap {
    flex: 0 0 42%;
    max-width: 42%;

    @media only screen and (max-width: 374px) {
      flex: 0 0 43%;
      max-width: 43%;
    }
  }

  .fromto-swap-inner {
    background: #262627;
    border-radius: 15px;
    padding: 10px;
    margin: 10px;
    text-align: center;

    @media only screen and (max-width: 767px) {
      margin: 0 0 10px;
    }

    @media only screen and (max-width: 374px) {
      padding: 10px 5px 5px 5px;
    }

    .fromto-swap-head {
      font-family: "Poppins-SemiBold";
      color: rgba(235, 235, 245, 0.6);
      font-size: 14px;

      @media only screen and (max-width: 767px) {
        font-size: 13px;
      }
    }

    .fromto-select {
      #fromto-dropdown {
        background: none;
        padding: 0;
        border: none;
        margin-left: 10px;
        box-shadow: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #181818;
        margin: 10px 0;
        border-radius: 10px;
        border: 1.5px solid #181818;
        padding: 13px 10px;

        &::after {
          display: none;
        }

        @media only screen and (max-width: 374px) {
          margin: 5px 0;
          padding: 7px;
        }

        .item-token {
          font-family: "Gilroy-Bold";

          @media only screen and (max-width: 767px) {
            font-size: 13px;
          }

          .coin-img {
            width: 26px;
            margin: 0px 5px;

            @media only screen and (max-width: 374px) {
              margin: 0px 2px;
              width: 20px;
            }
          }

          .coin-name {
            color: #fff;
            font-family: "Gilroy-Bold";

            @media only screen and (max-width: 767px) {
              font-size: 13px;
            }
          }
        }
      }

      .item-token-list {
        margin: 5px 10px;

        .coin-img {
          width: 26px;
          margin-right: 10px;
        }

        .coin-name {
          color: #fff;
          font-family: "Gilroy-Bold";
        }
      }
    }
  }

  .from-to-sep {
    img {
      cursor: pointer;

      @media only screen and (max-width: 374px) {
        width: 30px;
      }
    }
  }
}

.gbGoogleAdd {
  margin: 30px;
  width: 100%;
  height: 100vh;
}

.wallet-adapter-modal-list {
  &.wallet-list {
    margin-bottom: 0px;
  }

  .wallet-adapter-button.chooseone {
    &:hover {
      border-radius: 10px;
    }

    span {
      margin-left: revert;
      font-size: 14px;
      opacity: 0.6;

      &.item-text {
        width: 100%;
        text-align: left;
      }

      span {
        float: right;
      }
    }
  }
}

button.gb-red-btn.gb-back {
  padding: 10px;
  position: absolute;
  left: 0px;

  &::after {
    display: none;
  }

  &::before {
    content: url("../images/arrow.png");
    padding-left: 10px;
    transform: rotate(180deg);
    display: inline-block;
  }
}

.inlineSpinner {
  display: inline-block;

  div {
    width: 15px;
    height: 15px;
    font-size: 9px;
  }
}

._GB_HeaderNewsFeed {
  background: #181818;
  padding: 10px 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gilroy-Medium";
  font-size: 12px;

  @media only screen and (min-width: 1366px) {
    font-size: 14px;
    //display: none;
  }

  @media only screen and (min-width: 1920px) {
    font-size: 16px;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  .newText {
    background: #c80000;
    padding: 2px 10px;
    margin-right: 10px;
    border-radius: 4px;
  }
}

.curr-wallet-links.mobile-only {
  display: none;
}

@media only screen and (max-width: 400px) {

  .curr-wallet-links.desk-only,
  .curr-wallet-coins.desk-only {
    display: none;
  }

  .curr-wallet-links.mobile-only {
    display: block;
    margin-top: 10px;
  }
}

._GB_affiliate_admin {
  background: none !important;

  .showing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;

    .mark-all-paid {
      width: 100%;
      max-width: 200px;
      padding: 10px 8px;
      margin-bottom: 20px;
    }
  }
}

._managePaid {
  .paidnote {
    margin-left: 0;
    margin-right: 15px;
  }
}

.wallet_conv {
  margin-left: 4px;
  cursor: pointer;
}

.conv_sel {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 17px;
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
  position: absolute;
  margin: -1px 0px 0px 5px;
}

.conv_sel.sele {
  border: 1px solid #E52420;
  filter: drop-shadow(0px 0px 4px #E52420);
}

.curr-wallet-coins-items .item-token-name .conv_sel img {
  width: 12px;
  height: 12px;
}